import Modal from "react-bootstrap/Modal";
import { useState } from "react";
import checkbox from "../asset/images/Checkbox.svg";
import nigeria from "../asset/images/NG.png";
import euro from "../asset/images/EU.svg";
import pounds from "../asset/images/GB.svg";
import US from "../asset/images/US.svg";

function CurrencyModal(props) {
  const [selectedindex, setselectedindex] = useState(-1);
  const [currencciess] = useState([
    {
      img: pounds,
      name: "GBP",
      title: " British Pounds",
    },
    {
      img: nigeria,
      name: "NGN",
      title: "NG Naira",
    },
    {
      img: euro,
      name: "EUR",
      title: "Euro",
    },
    {
      img: US,
      name: "USD",
      title: "USD Dollar",
    },
  ]);

  const closeModal = () => {
    var datashow = props.show;
    props.setshow(!datashow);
  };
  const handleSubmit = (tenure) => {
    var dataList = props.tenureday;
    if (props?.settenureday) {
      props?.settenureday(tenure.name);
    }
    if (props?.getInvoiceSummary) {
      props?.getInvoiceSummary(tenure.name);
    }
    props.setshow(false);
  };
  return (
    <Modal
      show={props.show}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="border-0 "
      onHide={() => props.setshow(false)}
    >
      <Modal.Body
        style={{
          borderRadius: "16px",
          padding: "0px",
          border: "none",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
          }}
        >
          <div
            className=""
            style={{
              borderBottom: "1px solid #E7E9FB",
              padding: "8px 24px 8px 24px",
            }}
          >
            <span
              className="material-icons"
              style={{ color: "#667085" }}
              onClick={closeModal}
            >
              close
            </span>
          </div>
          <div style={{ padding: "8px 24px 8px 24px" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "4px",
              }}
            >
              <div className="card border-0" style={{ marginBottom: "20px" }}>
                <div className="card-body p-1">
                  {currencciess.map((tenures, index) => (
                    <div
                      key={index}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        background: selectedindex === index ? "#F4F0FF" : "",
                        borderRadius: "8px",
                        gap: "10px",
                        justifyContent: "space-between",
                        padding: "8px",
                      }}
                      onClick={() => {
                        handleSubmit(tenures);
                        setselectedindex(index);
                        if (props?.addseletedcurrency) {
                          props?.addseletedcurrency(tenures);
                        }
                        if (props?.setcurrency) {
                          props?.setcurrency(tenures);
                        }
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          gap: "8px",
                          alignItems: "center",
                        }}
                      >
                        <img
                          src={tenures.img}
                          alt=""
                          style={{
                            height: "40px",
                            width: "40px",
                          }}
                        />
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <div className="title_currency">{tenures.title}</div>
                          <span
                            style={{
                              fontWeight: "400",
                              letterSpacing: "-1%",
                              fontSize: "12px",
                              lineHeight: "24px",
                              color: "#667085",
                            }}
                          >
                            {tenures.name}{" "}
                          </span>
                        </div>
                      </div>
                      {selectedindex === index && (
                        <img
                          src={checkbox}
                          alt=""
                          style={{
                            width: "13.33px",
                            height: "13.33px",
                          }}
                        />
                      )}{" "}
                    </div>
                  ))}{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default CurrencyModal;
