import tradevuLogo from "asset/images/logo.png";
import Avatar from "component/avatar";
import { forwardRef } from "react";
import capitalizeWords from "utilities/capitalizeWords";
import { formatMoney as formatM } from "utilities/formatMoney";
import getInitials from "utilities/getInitials";

const Preview = forwardRef(
    (
        {
            currency,
            user,
            supplier,
            dueDate,
            orderItems,
            subtotal,
            surCharge,
            addTax,
            total,
            paymentTerms,
            splitTerm,
            note,
            preview,
        },
        ref
    ) => {
        const formatMoney = (money, compact, len) =>
            formatM(money, compact, len).replace(/,/g, " ");
        const gridStyle = "grid grid-cols-[1fr,55px,minmax(100px,120px),minmax(100px,115px)]";

        return (
            <div
                className={`bg-white rounded-sm absolute left-0 top-0 w-full ${
                    !supplier && orderItems.length === 1 ? "min-h-[101.5%]" : "h-full"
                } ${preview ? "z-[200]" : "-z-[200]"}`}
                ref={ref}
            >
                <h3 className="flex items-center font-[430] text-[19px] !py-[18px] px-[30px] !border-b-4 border-[#d9cdff]">
                    <img src={tradevuLogo} alt="Tradevu" className="w-8 h-[35px] mr-auto" />{" "}
                    PURCHASE ORDER
                    {/* <span className="text-main-grey text-[15px] !ml-3.5">234353</span> */}
                </h3>

                <div className="grid grid-cols-[43%,1fr] gap-4 px-[30px] mt-[30px]">
                    <div className="flex">
                        <Avatar
                            className={`font-[430] size-[42px] ${
                                getInitials(user?.bizName ?? "t v").length < 4
                                    ? "text-[17px] "
                                    : "text-sm"
                            }`}
                            fullName={user?.bizName ?? "t v"}
                            img={user?.bizImg ?? ""}
                        />
                        <p className="text-sm font-medium">
                            {capitalizeWords(user?.bizName ?? "")}{" "}
                            <span className="block font-normal text-[13px] text-main-grey !mt-1">
                                {capitalizeWords(user?.fullName ?? "")}
                            </span>
                        </p>
                    </div>

                    {supplier && (
                        <p className="text-sm font-[470]">
                            To
                            <span className="block mt-[7px] mb-[5px]">
                                {capitalizeWords(supplier.name)}
                            </span>
                            <span className="block text-main-grey font-normal">
                                {capitalizeWords(supplier.personalName)}
                            </span>
                        </p>
                    )}
                </div>

                <p className="px-[30px] text-main text-sm !mt-[26px] !mb-[42px]">Due: {dueDate}</p>

                <>
                    <div
                        className={`${gridStyle} mx-[30px] !pb-[13px] !mb-[9px] text-sm font-normal text-main-grey border-b *:!bg-transparent`}
                    >
                        <span>Items</span>
                        <span className="translate-x-3">Quantity</span>
                        <span className="text-right">Unit Price</span>
                        <span className="text-right">Total</span>
                    </div>
                    {orderItems?.map(({ id, itemName, pricePerUnit, quantity }) => (
                        <p
                            className={`${gridStyle} px-[30px] text-main font-normal text-[15px] !py-2`}
                            key={id}
                        >
                            <span>{itemName}</span>
                            <span className="translate-x-6">{formatMoney(quantity)}</span>
                            <span className="text-right">
                                {currency}
                                {formatMoney(pricePerUnit, true)}
                            </span>
                            <span className="text-right">
                                {currency}
                                {formatMoney(pricePerUnit * quantity, true)}
                            </span>
                        </p>
                    ))}

                    <div className="grid grid-cols-[47.5%,52.5%] mx-[30px] border-t !mt-1.5">
                        <span />
                        <p className="flex items-center justify-end font-normal !py-[18px] text-[15px] text-main">
                            <span className="text-main-grey text-sm mr-auto">Subtotal</span>
                            {currency}
                            {formatMoney(Number(subtotal).toFixed(2))}
                        </p>
                    </div>
                    <div className="grid grid-cols-[47.5%,52.5%] mx-[30px] border-t">
                        <span />
                        <p className="flex items-center justify-end font-normal !py-[18px] text-[15px] text-main">
                            <span className="text-main-grey text-sm mr-auto">Surcharge</span>
                            {currency}
                            {formatMoney(Number(surCharge).toFixed(2))}
                        </p>
                    </div>
                    <div className="grid grid-cols-[47.5%,52.5%] mx-[30px] border-t">
                        <span />
                        <p className="flex items-center justify-end font-normal !py-[18px] text-[15px] text-main">
                            <span className="text-main-grey text-sm mr-auto">Tax (7.5%)</span>
                            {currency}
                            {formatMoney(Number(addTax).toFixed(2))}
                        </p>
                    </div>
                    <div className="grid grid-cols-[47.5%,52.5%] mx-[30px] border-t">
                        <span />
                        <p className="flex items-center justify-end !py-[18px] text-[15px] font-semibold">
                            <span className="text-main-grey text-sm mr-auto font-normal">
                                Amount due
                            </span>
                            {currency}
                            {formatMoney(Number(total).toFixed(2))}
                        </p>
                    </div>
                </>

                <div className="mx-[30px] !border !border-[#f5f6fd] rounded-lg !px-4 py-3.5 !mt-4">
                    <>
                        <p className="text-[13px] text-main-grey font-normal">Payment terms</p>
                        <p className="text-sm font-medium !mt-1">{paymentTerms}</p>
                        {paymentTerms.startsWith("Split") && (
                            <p className="font-normal text-[13px] text-main !mt-1.5">{splitTerm}</p>
                        )}
                    </>
                    {note && (
                        <>
                            <p className="text-[13px] text-main-grey font-normal !mt-5">Notes</p>
                            <p className="text-sm font-medium !mt-1">{note}</p>
                        </>
                    )}
                </div>

                <p className="text-[#ab6bff] mx-[30px] font-[375] !mt-8 !mb-1.5">Thank you!</p>
                <p className="text-xs leading-[17px] text-main-grey font-[375] mx-[30px] !pb-4">
                    If you have questions for us, please call a representative at{" "}
                    <span className="text-[#ab6bff]">08030303030</span> or send a mail to{" "}
                    <span className="text-[#ab6bff]">me@tradevu.com</span>. Thanks for using Tradevu
                </p>
            </div>
        );
    }
);

export default Preview;
