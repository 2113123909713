//import {useState} from 'react';
import Modal from "react-bootstrap/Modal";
import "../style/rightside.css";
import "../style/main.css";
import { useState } from "react";
import axios from "axios";
import Loader from "./loader";

function DeleteAccounts(props) {
  const [text, setText] = useState("");
  const [loader, setLoader] = useState(false);
  const sanitizeText = (input) => {
    return input.trim().toLowerCase();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoader(true);
    if (sanitizeText(text) === "delete business account") {
      // Your logic when the text equals "delete business account"
      // console.log("Business account deletion requested");
      await axios
        .delete(`/v1/business/${props?.business._id}`)
        .then((res) => {
          //  console.log(res);
          setLoader(false);
          props.setmodal(true);
        })
        .catch((e) => {
          console.log(e);
          setLoader(false);
        });
    } else {
      // Your logic when the text does not match
      console.log("Text does not match the expected phrase");
    }
  };
  return (
    <>
      <Modal
        show={props.show}
        backdrop="false"
        keyboard={false}
        onHide={() => props.setshow(false)}
        className="rightsidemodal"
      >
        <div
          className=""
          style={{
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              height: "calc(100% - 100px)",
              width: "100%",
              padding: "20px",
            }}
          >
            <div
              className="w-100"
              style={{ height: "100%", marginTop: "40px" }}
            >
              <div
                className="w-100 "
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "16px",
                }}
              >
                <div className="d-flex gap-3 align-items-center">
                  <span
                    className="material-icons"
                    style={{
                      color: "#667085",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      props.setshow(false);
                    }}
                  >
                    arrow_back
                  </span>
                  <span
                    style={{
                      fontSize: "14px",
                      color: "#667085",
                      lineHeight: "18.9px",
                      fontWeight: "500",
                      textTransform: "uppercase",
                    }}
                  >
                    back to profile
                  </span>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "8px",
                  }}
                >
                  <div
                    className=""
                    style={{
                      fontSize: "32px",
                      lineHeight: "40px",
                      fontWeight: "500",
                      color: "#101828",
                      width: "90%",
                    }}
                  >
                    We are unhappy to see you leave us.
                  </div>
                  <div
                    style={{
                      width: "90%",
                      maxWidth: "410px",
                    }}
                  >
                    <p
                      style={{
                        color: "#667085",
                        fontWeight: "400",
                        fontSize: "15px",
                        lineHeight: "19.6px",
                        letterSpacing: "-1%",
                      }}
                    >
                      Hello {props?.business?.firstName}, We understatnd that
                      you have number of reasons to want to close your
                      account.Please let us know what they are.
                    </p>
                    <p
                      style={{
                        color: "#667085",
                        fontWeight: "400",
                        fontSize: "15px",
                        lineHeight: "19.6px",
                        letterSpacing: "-1%",
                        marginTop: "20px",
                      }}
                    >
                      For more info, please see our{" "}
                      <a
                        href="https://www.tradevu.co/privacy.html"
                        target="_blank"
                        style={{
                          color: "#6F00FF",
                          textDecoration: "none",
                        }}
                      >
                        Data Privacy and Protection Policy.
                      </a>
                    </p>
                  </div>
                </div>
              </div>

              <div className="w-100  d-flex">
                <form
                  //  onSubmit={(e) => props?.closeAccountDetails(e)}
                  className=""
                  style={{
                    marginTop: "30px",
                    minWidth: "416px",
                    display: "flex",
                    flexDirection: "column",
                    gap: "32px",
                  }}
                >
                  <div className="bankstatement-holder">
                    <div className="form-head-statement">
                      <div className="each-mainsecgemntt">
                        <input
                          type="text"
                          required
                          style={{
                            height: "100%",
                          }}
                          onChange={(e) => props?.setreason(e.target.value)}
                          placeholder="Enter a reason "
                        />
                      </div>
                    </div>
                  </div>
                  <button
                    className="btn-send-request"
                    style={{
                      width: "100%",
                      minWidth: "416px",
                      background: "#F04438",
                      color: "#fff",
                      fontWeight: "700",
                      borderColor: "#FEE4E2",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    onClick={() => props?.setshowModal3(true)}
                    type="button"
                  >
                    {props?.loader ? <Loader /> : "Delete Account"}
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default DeleteAccounts;
