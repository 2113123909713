import {Helmet} from "react-helmet";
// import DashHeader from "../component/dashheader";
// import homeTradeofCreditType from '../asset/im
import SerHeader from "../../component/serviceheader";
import {useState} from "react";
import {useNavigate} from "react-router-dom";
import checkboxbase from '../../asset/images/tradecredit/_Checkbox base.svg'
import visa from '../../asset/images/flex/visa.svg'
import verve from '../../asset/images/flex/verve.svg'
import master from '../../asset/images/flex/master.svg'


const SelectCardtype = () => { // const [selectedIndex, setselectedIndex] = useState(-1)
    const navigate = useNavigate()
    const [selectedindex, setselectedindex] = useState(-1)
    const type = [
        {
            title: 'Start using instantly',
            subject: 'Verve',
            image: verve
        }, {
            title: 'For every other kind of transactions',
            subject: 'Visa',
            image: visa
        }, {
            title: 'For shopping online and subscriptions',
            subject: 'Mastercard',
            image: master
        }
    ]
    const goanotherpage = () => {
        if (selectedindex !== -1) {


            navigate(`/card/create_card/${
                type[selectedindex].subject
            }`)
        }


    }
    return (<div className="h-100 w-100 ">
        <div className="container-xxl">
            <Helmet>
                <title>Flex - cards</title>
            </Helmet>

            <SerHeader header={'Create new card'}/>
        </div>
        <div style={
            {height: '100%'}
        }>
            <div className="conatiner "
                style={
                    {
                        maxWidth: '1250px',
                        margin: '0px auto',
                        paddingLeft: '30px',
                        paddingRight: '30px',
                        paddingTop: '10px',
                        paddingBottom: '10px'
                    }
            }>

                <div style={
                    {
                        height: '100%',
                        width: '100%',
                        display: 'flex',

                        justifyContent: 'center'

                    }
                }>
                    <div style={
                        {
                            maxWidth: '592px',
                            width: '100%',
                            marginTop: '50px',
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '32px'
                        }
                    }>
                        <div className="">
                            <div style={
                                {
                                    color: '#101828',
                                    fontSize: '24px',
                                    lineHeight: '32.4px'
                                }
                            }>
                                Choose card type
                            </div>


                        </div>
                        <div className="">
                            <div className="card border-0">
                                <div className="card-body p-1">
                                    <div style={
                                        {
                                            display: 'flex',
                                            flexDirection: 'column',
                                            cursor: 'pointer'
                                        }
                                    }> {
                                        type.map((item, index) => (<div style={
                                                {
                                                    padding: '8px',
                                                    display: 'flex',
                                                    justifyContent: 'space-between',
                                                    alignItems: 'center',
                                                    position: 'relative',
                                                    background: selectedindex === index ? '#EDFCFE' : '',
                                                    borderRadius: '8px'

                                                }
                                            }
                                            onClick={
                                                () => {

                                                    setselectedindex(index)
                                                }
                                        }>


                                            <div style={
                                                {
                                                    display: 'flex',
                                                    gap: '16px'
                                                }
                                            }>

                                                <img src={
                                                        item.image
                                                    }
                                                    alt=""
                                                    width={48}
                                                    height={48}/>
                                                <div style={
                                                    {
                                                        overflow: 'hidden',
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        gap: '4px'
                                                    }
                                                }>
                                                    <div style={
                                                        {
                                                            color: '#101828',
                                                            fontSize: '16px',
                                                            lineHeight: '24px',
                                                            letterSpacing: '2%',
                                                            fontWeight: '500'
                                                        }
                                                    }> {
                                                        item.subject
                                                    } </div>
                                                    <p style={
                                                        {
                                                            fontSize: '14px',
                                                            fontWeight: '400',
                                                            lineHeight: '19.6px',
                                                            color: '#667085'
                                                        }
                                                    }> {
                                                        item.title
                                                    } </p>

                                                </div>

                                            </div>
                                            {
                                            selectedindex === index ? <div>
                                                <img src={checkboxbase}
                                                    alt=""/>
                                            </div> : <span className="material-icons"
                                                style={
                                                    {color: '#667085'}
                                            }>
                                                chevron_right
                                            </span>
                                        } </div>))
                                    } </div>


                                </div>
                            </div>

                        </div>
                        <div>
                            <button onClick={goanotherpage}
                                style={
                                    {
                                        maxWidth: '278px',
                                        width: '100%',
                                        background: '#6F00FF',
                                        color: '#fff',
                                        padding: '8px 12px 8px 12px',
                                        letterSpacing: '2%',
                                        fontSize: '16px',
                                        fontWeight: '500',
                                        lineHeight: '24px',
                                        borderRadius: '8px',
                                        outline: 'none',
                                        border: 'none'
                                    }
                            }>
                                Continue
                            </button>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>);
}

export default SelectCardtype;
