import { CalenderIcon } from "asset/icons";
import { EMAIL_REGEX } from "constants/global";
import dayjs from "dayjs";
import { useRef } from "react";
import { formatMoney } from "utilities/formatMoney";

/**
 * @param {Object} props
 * @param {function(string):void} props.onChange
 * @param {string} [props.value]
 * @param {("text"|"password"|"email"|"number"|"tel"|"url"|"search"|"date"|"time"|"checkbox"|"radio"|'textarea')} [props.type]
 * @param {boolean} [props.required]
 * @param {string} [props.label]
 * @param {string} [props.labelClassName]
 * @param {string} [props.className]
 * @param {string} [props.placeholder]
 * @param {("integer"|"decimal")} [props.formatType]
 * @param {string} [props.unit]
 * @param {string} [props.unitClassName]
 * @param {string} [props.unitWrapperClassName]
 * @param {string} [props.max]  date max type eg: 2018-12-31
 * @param {string} [props.min]  date min type eg: 2018-12-31
 */
const Input = ({
    onChange,
    value,
    type = "text",
    required = true,
    label,
    labelClassName,
    className,
    placeholder,
    formatType,
    unit,
    unitClassName,
    unitWrapperClassName,
    max,
    min,
}) => {
    const classes = `bg-[#DBDFE5] h-[56px] rounded-lg text-main-dark w-full pointer-events-auto !px-4 placeholder:font-normal placeholder:text-sm placeholder:text-main-grey ${
        className || "!shadow-[inset_0_0_0_1000px_#EAECF0]"
    }`;

    const inputs = () => (
        <>
            {type === "number" ? (
                <NumInput
                    required={required}
                    placeholder={placeholder}
                    className={classes}
                    onChange={onChange}
                    value={value}
                    formatType={formatType}
                    max={max}
                    min={min}
                />
            ) : type === "textarea" ? (
                <textarea
                    className={`${classes} resize-none !h-[96px] !py-2.5`}
                    required={required}
                    placeholder={placeholder}
                    onChange={(evt) => onChange(evt.target.value)}
                    onKeyUp={(evt) => evt.preventDefault()}
                    {...(value && { value })}
                />
            ) : type === "date" ? (
                <DateInput
                    className={className}
                    max={max}
                    min={min}
                    onChange={onChange}
                    placeholder={placeholder}
                    required={required}
                    value={value}
                />
            ) : (
                <input
                    required={required}
                    placeholder={placeholder}
                    className={classes}
                    onChange={(evt) => onChange(evt.target.value)}
                    onKeyUp={(evt) => evt.preventDefault()}
                    pattern={type === "email" ? EMAIL_REGEX : null}
                    title={type === "email" ? "Enter a valid email format" : null}
                    type={type}
                    {...(value && { value })}
                />
            )}
        </>
    );

    return (
        <>
            {label && (
                <p
                    className={`font-medium text-sm text-left text-[#475467] ${
                        labelClassName || "!mb-1 !mt-5"
                    }`}
                >
                    {label}
                </p>
            )}
            {unit ? (
                <div
                    className={`flex bg-[#EAECF0] items-center rounded-lg h-max ${unitWrapperClassName}`}
                >
                    {inputs()}

                    {typeof unit === "object" ? (
                        unit
                    ) : (
                        <p
                            className={`!pl-0.5 !pr-3.5 font-medium text-sm text-main ${unitClassName}`}
                        >
                            {unit}
                        </p>
                    )}
                </div>
            ) : (
                inputs()
            )}
        </>
    );
};

const NumInput = ({
    required,
    onChange,
    placeholder,
    className,
    value,
    formatType,
    max = Infinity,
    min = 0,
}) => (
    <input
        className={className}
        required={required}
        placeholder={placeholder}
        onKeyUp={(evt) => evt.preventDefault()}
        value={formatType ? (value ? formatMoney(value) : "") : value ?? ""}
        onChange={(evt) => {
            const value = evt.target.value.replace(/[,\s]/g, "");
            if (+value > max || +value < min) return;
            if (formatType === "decimal") {
                if (value.includes(".")) {
                    // replace(/\D/g, '')
                    const [wholeNum, decimalNum] = value.split(".");
                    console.log(wholeNum, decimalNum, "inside decimal");
                    //                 !isNaN(+valuesSplit[0]) && !isNaN(+valuesSplit[1]) && onChange(value);
                } else {
                    !isNaN(+value) && onChange(value);
                }
            } else {
                !isNaN(+value) && onChange(value);
            }
        }}
    />
);

const DateInput = ({ placeholder, value, required, onChange, min, max, className }) => {
    const dateRef = useRef();
    return (
        <button
            onClick={() => dateRef?.current?.showPicker()}
            className={`bg-[#EAECF0] relative flex w-full items-center justify-between rounded-lg h-[56px] !px-4 ${
                value ? "text-main-dark" : "text-main-grey text-sm"
            } ${className}`}
            type="button"
        >
            {value ? dayjs(value).format("MMM D, YYYY") : placeholder}
            <CalenderIcon />
            <input
                ref={dateRef}
                type="date"
                name={placeholder}
                value={value}
                min={min ?? dayjs().hour(24).format("YYYY-MM-DD")}
                max={max}
                onChange={(evt) => onChange(evt.target.value)}
                className="w-full absolute left-0 top-[50%] -z-10 opacity-0"
                required={value ? false : required}
            />
        </button>
    );
};

export default Input;
