import nigeria from "asset/images/NG.png";
import euro from "asset/images/EU.svg";
import pounds from "asset/images/GB.svg";
import US from "asset/images/US.png";
export const currencies = [
  {
    image: nigeria,
    title: "NGN",
  },
  {
    title: "EUR",
    image: euro,
  },
  {
    image: pounds,
    title: "GBP",
  },
  {
    image: US,
    title: "USD",
  },
];
