import { CheckCircleIcon, SearchIcon } from "asset/icons";
import Input from "component/form/Input";
import useDebounce from "hooks/useDebounce";
import { Fragment } from "react";
import { Bars } from "react-loader-spinner";
import { useSelector } from "react-redux";
import capitalizeWords from "utilities/capitalizeWords";
import getInitials from "utilities/getInitials";

const UserSearch = ({ selectedUser, searchUsers, searchLoading, users: _users, selectUser }) => {
    const { business } = useSelector((state) => state);
    const searchUsersDebounce = useDebounce((searchString) => {
        if (searchString && !searchLoading) {
            searchUsers(
                `/v1/business/search?keyword=${searchString}`,
                {
                    headers: {
                        "x-business-id": business?._id,
                    },
                },
                false
            );
        }
    }, 700);
    const users = _users?.filter(({ status, user }) => status.toLowerCase() === "approved" && user);
    const alphabets = [...new Set(users?.map(({ name }) => name.charAt(0).toUpperCase()))].sort();
    return (
        <>
            <p className={`font-medium text-sm text-left !text-[#475467] !mb-2 !mt-3 !px-6`}>
                Search Tradevu user
            </p>

            <div className={`flex items-center bg-[#EAECF0] rounded-lg !gap-2 !px-4 !mx-6`}>
                <SearchIcon className="size-5" />
                <Input
                    placeholder="Search by business name"
                    className="!px-0 pointer-events-auto !shadow-[inset_0_0_0_1000px_#EAECF0]"
                    onChange={(value) => searchUsersDebounce(value.toLowerCase())}
                />
            </div>

            <div className={`max-h-[420px] overflow-y-auto rounded-lg !mt-4 !mb-6 !px-6`}>
                {!searchLoading && users?.length ? (
                    <>
                        {alphabets.map((letter, letterIdx) => (
                            <Fragment key={letter}>
                                <p
                                    className={`font-medium  text-left text-xs text-[#667085] ${
                                        letterIdx && "!mt-[15px]"
                                    } !mb-2`}
                                >
                                    {letter}
                                </p>
                                <div
                                    className={`rounded-lg !px-2 bg-white flex flex-col gap-2.5 !py-2.5 border`}
                                >
                                    {users
                                        .filter((user) =>
                                            user.name.toUpperCase().startsWith(letter)
                                        )
                                        .map((user) => (
                                            <div
                                                className={`flex items-center gap-2 !py-1.5 cursor-pointer rounded-lg`}
                                                onClick={() => selectUser(user)}
                                                key={user._id}
                                            >
                                                <span
                                                    className={`!size-10 bg-[#F6F6F6] rounded-full text-[#344054] font-[450] grid place-items-center overflow-hidden ${
                                                        getInitials(user.name).length > 3 &&
                                                        "text-[13px]"
                                                    }`}
                                                >
                                                    {getInitials(user.name)}
                                                </span>
                                                <p className="text-main-dark font-[450]">
                                                    {capitalizeWords(user.name)}
                                                </p>

                                                {selectedUser?._id === user._id && (
                                                    <CheckCircleIcon className="ml-auto !mr-2 !size-5" />
                                                )}
                                            </div>
                                        ))}
                                </div>
                            </Fragment>
                        ))}
                    </>
                ) : (
                    <div className="h-[80px] grid place-items-center">
                        {searchLoading ? (
                            <Bars height="22" width="22" color="#6f00ff" ariaLabel="bars-loading" />
                        ) : (
                            <span className="block text-center text-main">No user found</span>
                        )}
                    </div>
                )}
            </div>
        </>
    );
};

export default UserSearch;
