import Modal from "react-bootstrap/Modal";
import { useState } from "react";
import checkbox from "../asset/images/Checkbox.svg";
import arrowright from "../asset/images/chevron-right.svg";
import newinvoice from "../asset/images/new_invoice_upload.svg";
import newtrade from "../asset/images/new_trade_invoice.svg";

function InvoiceType(props) {
  const [selectedindex, setselectedindex] = useState(-1);

  const closeModal = () => {
    var datashow = props.show;
    props.setshow(!datashow);
  };
  const handleSubmit = (tenure) => {
    var dataList = props.tenureday;

    props.settenureday(
      // eslint-disable-next-line
      (dataList = tenure)
    );

    props.setshow(false);
  };
  return (
    <Modal
      show={props.show}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      style={{
        width: "70%",
        margin: "0px auto",
        inset: "0% -100%",
      }}
      className="border-0 "
      onHide={() => props.setshow(false)}
    >
      <Modal.Body
        style={{
          borderRadius: "16px",
          padding: "0px",
          border: "none",
          maxWidth: "400px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
          }}
        >
          <div
            className=""
            style={{
              borderBottom: "1px solid #E7E9FB",
              padding: "8px 24px 8px 24px",
            }}
          >
            <span
              className="material-icons"
              style={{ color: "#667085" }}
              onClick={closeModal}
            >
              close
            </span>
          </div>
          <div style={{ padding: "8px 24px 8px 24px" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "4px",
              }}
            >
              <h3
                style={{
                  fontWeight: "700",
                  color: "#101828",
                  lineHeight: "24.3px",
                  fontSize: "18px",
                }}
              >
                Choose Invoice Type
              </h3>
              <div
                className="card border-0"
                style={{
                  marginBottom: "20px",
                }}
              >
                <div className="card-body p-1">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "8px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        padding: "8px",
                        cursor: "pointer",
                        borderBottom: "1px solid #E7E9FB",
                      }}
                      onClick={() => props.setshowModal2(true)}
                    >
                      <div
                        style={{
                          display: "flex",
                          gap: "8px",
                          alignItems: "center",
                        }}
                      >
                        <img src={newinvoice} alt="" height={30} />
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <div className="upload_new">Upload New Invoice</div>
                          <span className="sub_new">
                            Upload a physical invoice
                          </span>
                        </div>
                      </div>
                      <img src={arrowright} alt="" />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        padding: "8px",
                        cursor: "pointer",
                      }}
                      onClick={() => props.setsavedinvoice(true)}
                    >
                      <div
                        style={{
                          display: "flex",
                          gap: "8px",
                          alignItems: "center",
                        }}
                      >
                        <img src={newtrade} alt="" height={30} />
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <div className="upload_new">Use Tradevu Invoice</div>
                          <span className="sub_new">
                            Select from your saved invoices.
                          </span>
                        </div>
                      </div>
                      <img src={arrowright} alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default InvoiceType;
