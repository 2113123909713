import { TimerFilledIcon } from "asset/icons";
import useScreenSize from "hooks/useScreenSize";
import { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Tooltip } from "react-tooltip";

const FlexNav = ({ inNav, pathname }) => {
    const tabs = [
        { title: "Procurement", to: "/flex/home" },
        { title: "Shipments", to: null },
        { title: "Network", to: "/flex/home/network" },
    ];
    const inManageFlow = pathname === "/flex/home/manage-po";
    const [active, setActive] = useState(
        inManageFlow ? 0 : tabs.findIndex(({ to }) => pathname === to)
    );
    const { screenWidth } = useScreenSize();

    const comingSoon = (show, className) => show && <TimerFilledIcon className={className} />;

    useEffect(() => {
        inManageFlow && setActive(0);
    }, [inManageFlow]);

    return (inNav && screenWidth < 640) || !inNav ? (
        <>
            {tabs.map(({ title, to }, idx) => (
                <Fragment key={title}>
                    <Link
                        className={`!h-10 flex items-center ${
                            inNav && "justify-end !mr-10"
                        } !pr-4 sm:!pl-4 cursor-pointer transition-all ease-in ${
                            !to && "!cursor-not-allowed"
                        } ${
                            active === idx
                                ? `${
                                      inNav ? "border-r-2" : "border-l-2"
                                  } !text-secondary font-[450] !border-secondary`
                                : `${inNav ? "border-r" : "border-l"} text-[#475467]`
                        }`}
                        to={to ?? "#"}
                        onClick={() => to && setActive(idx)}
                        id={!to && `coming-soon-${title}`}
                    >
                        {comingSoon(inNav && !to, "mr-2.5")} {title}{" "}
                        {comingSoon(!inNav && !to, "ml-2.5")}
                    </Link>

                    {!to && (
                        <Tooltip
                            anchorSelect={`#coming-soon-${title}`}
                            className={`bg-transparent z-50 ${
                                inNav
                                    ? "translate-y-12 translate-x-4"
                                    : "-translate-x-9 -translate-y-[1px]"
                            }`}
                            place="right"
                        >
                            <span className="bg-white !py-2 !px-3 rounded-lg font-medium text-xs text-main shadow">
                                Coming soon 🚧
                            </span>
                        </Tooltip>
                    )}
                </Fragment>
            ))}
        </>
    ) : null;
};

export default FlexNav;
