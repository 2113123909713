import { Helmet } from "react-helmet";

import { useEffect, useState } from "react";

import SerHeader from "../component/serviceheader";

import UploadInvoice from "../component/uploadinvoice";

import InvoiceSuccess from "../component/invoicesuccess";
import Loader from "../component/loader";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import FailHandler from "../component/failhandler";
import TenureModal from "../component/tenure";
import RepaymentStructure from "../component/Repayment";
import CurrencyToggle from "component/currencyToggle";
import Input from "component/form/Input";
import { useSelector } from "react-redux";
import currencyPairCheck from "utilities/currencyPairCheck";
import { formatMoney } from "utilities/formatMoney";
import Dropdown from "component/form/dropDown";

const TradeofCredit = () => {
    const { currency } = useSelector((state) => state);
    // const [selectedIndex, setselectedIndex] = useState(-1)
    const navigate = useNavigate();
    const [slidervalue, setslidervalue] = useState("10");

    const [show, setShow] = useState(false);
    const [selecteditem, setselecteditem] = useState([]);
    const [added, setadded] = useState(false);
    const [businessname, setbusinessname] = useState("");
    const [tenureshow, settenureshow] = useState(false);
    const [tenureday, settenureday] = useState("");
    const [selectedstructure, setstructure] = useState("");
    const [repayshow, setrepayshow] = useState(false);

    const [showModal3, setshowModal3] = useState(false);
    const [loader, setloader] = useState(false);
    const [isdisable, setisdisable] = useState(true);
    const [amount, setAmount] = useState("");
    const [message, setmessage] = useState("");
    const [failhandler, setfailhandler] = useState(false);

    const [showdrop, setisshowdrop] = useState(false);
    const tenures = ["30", "60", "90"];
    const structure = ["Bullet", "Interest First", "Full Repayment"];

    const handleClose = () => setShow(false);

    useEffect(() => {
        setTimeout(() => {
            setfailhandler(false);
        }, 3000);
    });
    const submitHandler = async (e) => {
        e.preventDefault();
        setloader(true);
        const data = {
            requestedAmount: amount,
            currency,
            tenure: tenureday,
            ...selecteditem,
            // repaymentStructure: selectedstructure
        };
        await axios
            .post(`v1/finance/trade_credit/create`, data)
            .then((res) => {
                setloader(false);
                axios.put(`v1/finance/trade_credit/update/${res.data.data._id}`, {
                    repaymentStructure:
                        selectedstructure === "Full Repayment"
                            ? "FullPayment"
                            : selectedstructure === "Intrest First"
                            ? "InterestFirst"
                            : selectedstructure,
                });
                navigate(`/trade_of_credit/type/${res.data.data._id}`, {
                    state: {
                        ...data,
                    },
                });
            })
            .catch((e) => {
                setloader(false);
                setloader(false);
                setfailhandler(true);
                setmessage(e.response?.data ? e.response?.data.message : "An error occur");
            });
    };

    useEffect(() => {
        if (selectedstructure && amount && tenureday) {
            setisdisable(false);
        } else {
            setisdisable(true);
        }
        /*
    const sliderEl = document.querySelector("#myRange");
    const sliderValue = sliderEl.value;
    sliderEl.style.background = `linear-gradient(to right, #6F00FF ${sliderValue}%, #D7DDEA ${sliderValue}%)`;
    sliderEl.style.borderRadius = `50px`;

    */
    }, [setisdisable, selectedstructure, amount, tenureday]);

    return (
        <div
            className="h-100 w-100 "
            style={{
                background: "#f6f6f6",
            }}
        >
            <div
                className=""
                style={{
                    maxWidth: "2000px",
                    width: "100%",
                    margin: "0px auto",
                }}
            >
                <Helmet>
                    <title>Working Capital</title>
                </Helmet>
                <TenureModal
                    show={tenureshow}
                    setshow={settenureshow}
                    tenure={tenures}
                    settenureday={settenureday}
                />
                <RepaymentStructure
                    tenure={structure}
                    setshow={setrepayshow}
                    show={repayshow}
                    settenureday={setstructure}
                />

                <SerHeader header={"Working Capital"} width={20} />
                <InvoiceSuccess
                    show={showModal3}
                    setshow={setshowModal3}
                    title={
                        <>
                            We’ll review your request of{" "}
                            <span className="!text-secondary">
                                {currencyPairCheck(currency)}
                                {formatMoney(amount).replace(/,/g, " ")}
                            </span>{" "}
                            in less than 48 hours. You’ll be notified on how to proceed from here.
                        </>
                    }
                    subject={"Application received! Your request is under review"}
                />
            </div>
            <div style={{ height: "100%" }}>
                <div
                    className=" "
                    style={{
                        maxWidth: "2000px",
                        width: "100%",
                        paddingLeft: "30px",
                        paddingRight: "30px",
                        paddingTop: "10px",
                        paddingBottom: "10px",
                        margin: "0px auto",
                    }}
                >
                    <div
                        style={{
                            height: "100%",
                            width: "100%",
                            display: "flex",

                            justifyContent: "center",
                        }}
                    >
                        <UploadInvoice
                            show={show}
                            handle={handleClose}
                            selecteditem={selecteditem}
                            setselecteditem={setselecteditem}
                            setbusinessname={setbusinessname}
                        />
                        <form
                            onSubmit={submitHandler}
                            className="form-general-layout"
                            style={{
                                position: "relative",
                            }}
                        >
                            <FailHandler success={failhandler} message={message} />

                            <div>
                                <div
                                    style={{
                                        color: "#101828",
                                        fontWeight: "600",
                                        fontSize: "24px",
                                        lineHeight: "32.4px",
                                    }}
                                >
                                    How much do you need?
                                </div>

                                <>
                                    <p
                                        className={`font-medium text-sm text-left text-[#475467] !mb-1 !mt-8`}
                                    >
                                        Amount
                                    </p>
                                    <div className="flex !gap-[5px]">
                                        <CurrencyToggle
                                            hideLabel
                                            className="w-[100px] !rounded-lg"
                                            dropDownClassName="left-0 top-[106%]"
                                        />
                                        <Input
                                            type="number"
                                            onChange={(value) => setAmount(value)}
                                            value={amount}
                                            formatType="integer"
                                            placeholder={currencyPairCheck(currency) + " 0"}
                                        />
                                    </div>
                                </>

                                <Dropdown
                                    label="Tenure"
                                    placeholder="Select Tenure"
                                    value={tenureday}
                                    onChange={(value) => settenureday(value)}
                                    options={["30 Days", "60 Days", "90 Days"].map((option) => ({
                                        label: option,
                                        value: option,
                                    }))}
                                    labelClassName="!text-[#475467]"
                                />

                                <Dropdown
                                    label="Repayment structure"
                                    placeholder="Select repayment structure"
                                    value={selectedstructure}
                                    onChange={(value) => setstructure(value)}
                                    options={[
                                        { label: "Bullet", value: "Bullet" },
                                        { label: "Interest First", value: "InterestFirst" },
                                        { label: "FullPayment", value: "FullPayment" },
                                    ].map(({ label, value }) => ({
                                        label,
                                        value,
                                    }))}
                                    labelClassName="!text-[#475467]"
                                />

                                <div className="!mt-10 !mb-5">
                                    <button
                                        disabled={isdisable}
                                        style={{
                                            maxWidth: "280px",
                                            width: "100%",
                                            background: isdisable ? "#EBE4FF" : "#6F00FF",
                                            padding: "16px 24px 16px 24px",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            border: "none",
                                            outline: "none",
                                            minHeight: "46px",
                                            borderRadius: "8px",
                                            fontSize: "16px",
                                            lineHeight: "24px",
                                            letterSpacing: "2%",
                                            fontWeight: "500",
                                            color: isdisable ? "#BFA6FF" : "#ffff",
                                        }}
                                    >
                                        {loader === true ? <Loader data={loader} /> : "Continue"}
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TradeofCredit;
