import Modal from "react-bootstrap/Modal";
import searchs from "../asset/images/search-normal.svg";
import { countrycodelist } from "../constants/countryList";
import { useState } from "react";
import "../style/main.css";
import "../style/rightside.css";

function AdvisingBank(props) {
    const [search, setsearch] = useState("");
    const [searchbank, setsearchbank] = useState("");

    const [bankshow, setbankshow] = useState(false);
    const [selectedcountry, setselectedcountry] = useState([]);

    const filtercontant = countrycodelist.filter((countrycodelists) =>
        countrycodelists.title.match(search)
    );

    const filterBank = props.banks.filter((bank) => bank.bankName.match(searchbank));

    props.banks.sort((a, b) => a.bankName.localeCompare(b.bankName, "es", { sensitivity: "base" }));
    let data = filterBank.reduce((r, e) => {
        let alphabet = e.bankName[0];

        if (!r[alphabet])
            r[alphabet] = {
                alphabet,
                record: [],
            };

        r[alphabet].record.push(e);
        return r;
    }, {});
    let result = Object.values(data);

    const getselectedcountry = (country) => {
        setselectedcountry(country);
        setbankshow(!bankshow);
        var datashow = props.show;
        props.setshow(!datashow);
    };
    const closeModal = () => {
        // var datashow = props.show
        props.setshow(false);
    };
    const closeModal2 = () => {
        props.setshow(false);
        setbankshow(false);
    };

    return (
        <>
            <Modal
                show={props.show}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="border-0 "
            >
                <Modal.Body
                    style={{
                        borderRadius: "16px",
                        padding: "0px",
                        border: "none",
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "10px",
                        }}
                    >
                        <div
                            className=""
                            style={{
                                borderBottom: "1px solid #E7E9FB",
                                padding: "8px 24px 8px 24px",
                            }}
                        >
                            <span
                                className="material-icons"
                                style={{ color: "#667085" }}
                                onClick={closeModal}
                            >
                                close
                            </span>
                        </div>
                        <div style={{ padding: "8px 24px 8px 24px" }}>
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: "32px",
                                    marginBottom: "10px",
                                }}
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        gap: "24px",
                                    }}
                                >
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            gap: "8px",
                                        }}
                                    >
                                        <h3
                                            style={{
                                                color: "#101828",
                                                fontSize: "20px",
                                                lineHeight: "24px",
                                                fontWeight: "500",
                                                margin: "0px",
                                            }}
                                        >
                                            Change Bank’s location
                                        </h3>
                                    </div>
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            gap: "32px",
                                        }}
                                    >
                                        <div
                                            style={{
                                                background: "#ECEFF3",
                                                padding: "0px 8px 0px 8px",
                                                borderRadius: "8px",
                                                gap: "8px",
                                                display: "flex",
                                            }}
                                        >
                                            <img src={searchs} alt="" />
                                            <input
                                                type="search"
                                                onChange={(e) => setsearch(e.target.value)}
                                                style={{
                                                    width: "100%",
                                                    background: "inherit",
                                                    outline: "none",
                                                    border: "none",
                                                    padding: "0px 8px 0px 8px",
                                                    height: "40px",
                                                }}
                                                placeholder="Search countries"
                                            />
                                        </div>
                                        <div
                                            style={{
                                                background: "#fff",
                                                borderRadius: "8px",
                                                display: "flex",
                                                flexDirection: "column",
                                                gap: "16px",
                                                padding: "8px",
                                                maxHeight: "1260px",
                                                height: "100%",
                                                overflow: "auto",
                                            }}
                                        >
                                            {" "}
                                            {filtercontant.map((country, index) => (
                                                <div
                                                    key={index}
                                                    style={{
                                                        display: "flex",
                                                        gap: "8px",
                                                        alignItems: "center",
                                                        cursor: "pointer",
                                                    }}
                                                    onClick={() => {
                                                        getselectedcountry(country);
                                                    }}
                                                >
                                                    <img
                                                        src={country.image}
                                                        alt=""
                                                        style={{ borderRadius: "100% " }}
                                                    />
                                                    <span
                                                        style={{
                                                            color: "#344054",
                                                            fontWeight: "500",
                                                            lineHeight: "19.6px",
                                                            fontSize: "14px",
                                                        }}
                                                    >
                                                        {" "}
                                                        {country.title}{" "}
                                                    </span>
                                                </div>
                                            ))}{" "}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            <Modal
                show={bankshow}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="border-0 "
            >
                <Modal.Body
                    style={{
                        borderRadius: "16px",
                        padding: "0px",
                        border: "none",
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "10px",
                        }}
                    >
                        <div
                            className=""
                            style={{
                                borderBottom: "1px solid #E7E9FB",
                                padding: "8px 24px 8px 24px",
                            }}
                        >
                            <span
                                className="material-icons"
                                style={{
                                    color: "#667085",
                                    cursor: "pointer",
                                }}
                                onClick={closeModal2}
                            >
                                close
                            </span>
                        </div>
                        <div style={{ padding: "8px 24px 8px 24px" }}>
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: "32px",
                                    marginBottom: "10px",
                                }}
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        gap: "24px",
                                    }}
                                >
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            gap: "8px",
                                        }}
                                    >
                                        <h3
                                            style={{
                                                color: "#101828",
                                                fontSize: "20px",
                                                lineHeight: "24px",
                                                fontWeight: "500",
                                                margin: "0px",
                                            }}
                                        >
                                            Change Bank’s location
                                        </h3>
                                        <span
                                            style={{
                                                color: "#667085",
                                                fontWeight: "400",
                                                fontSize: "14px",
                                                lineHeight: "19.6px",
                                            }}
                                        >
                                            Pick a bank that will confirm the Letter of Credit
                                            (preferably in Supplier’s country)
                                        </span>
                                    </div>
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            gap: "16px",
                                        }}
                                    >
                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                gap: "8px",
                                            }}
                                        >
                                            <label
                                                htmlFor="Bank's country"
                                                style={{
                                                    color: "#344054",
                                                    fontSize: "14px",
                                                    lineHeight: "19.6px",
                                                    fontWeight: "500",
                                                }}
                                            >
                                                Bank’s country
                                            </label>
                                            <div
                                                style={{
                                                    background: "#fff",
                                                    padding: "0px 8px 0px 8px",
                                                    borderRadius: "8px",
                                                    gap: "8px",
                                                    display: "flex",
                                                    justifyContent: "space-between",
                                                    alignItems: "center",
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        gap: "8px",
                                                        alignItems: "center",
                                                        height: "40px",
                                                    }}
                                                >
                                                    <img
                                                        src={selectedcountry.image}
                                                        alt=""
                                                        style={{ borderRadius: "100% " }}
                                                    />
                                                    <span
                                                        style={{
                                                            color: "#101828",
                                                            fontSize: "16px",
                                                            fontWeight: "500",
                                                            lineHeight: "24px",
                                                            letterSpacing: "2%",
                                                        }}
                                                    >
                                                        {" "}
                                                        {selectedcountry.title}{" "}
                                                    </span>
                                                </div>
                                                <div className="material-icons">
                                                    keyboard_arrow_down
                                                </div>
                                            </div>
                                            <div
                                                style={{
                                                    background: "#ECEFF3",
                                                    padding: "0px 8px 0px 8px",
                                                    borderRadius: "8px",
                                                    gap: "8px",
                                                    display: "flex",
                                                }}
                                            >
                                                <img src={searchs} alt="" />
                                                <input
                                                    type="search"
                                                    onChange={(e) => setsearchbank(e.target.value)}
                                                    style={{
                                                        width: "100%",
                                                        background: "inherit",
                                                        textTransform: "capitalize",
                                                        outline: "none",
                                                        border: "none",
                                                        padding: "0px 8px 0px 8px",
                                                        height: "40px",
                                                    }}
                                                    placeholder="Search bank"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            gap: "32px",
                                        }}
                                    >
                                        {" "}
                                        {result.map((contact, index) => (
                                            <div
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    gap: "8",
                                                }}
                                                key={index}
                                            >
                                                <span
                                                    style={{
                                                        color: "#667085",
                                                        fontSize: "12px",
                                                        lineHeight: "16.2px",
                                                        fontWeight: "500",
                                                    }}
                                                >
                                                    {" "}
                                                    {contact.alphabet}
                                                </span>
                                                {contact.record.map((records, index) => (
                                                    <div
                                                        style={{
                                                            background: "#fff",
                                                            borderRadius: "8px",
                                                            display: "flex",
                                                            gap: "16px",
                                                            justifyContent: "space-between",
                                                            padding: "8px",
                                                            alignItems: "center",
                                                        }}
                                                        onClick={() => {
                                                            props.setadvisingbank(records);
                                                            closeModal2();
                                                        }}
                                                        key={index}
                                                    >
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                gap: "8px",
                                                                alignItems: "center",
                                                            }}
                                                        >
                                                            <img src={search} alt="" />
                                                            <div
                                                                style={{
                                                                    color: "#101828",
                                                                    fontSize: "16px",
                                                                    fontWeight: "500",
                                                                    lineHeight: "24px",
                                                                    letterSpacing: "2%",
                                                                    display: "flex",
                                                                    flexDirection: "column",
                                                                    cursor: "pointer",
                                                                }}
                                                            >
                                                                <span
                                                                    style={{
                                                                        color: "#101828",
                                                                        lineHeight: "24px",
                                                                        letterSpacing: "2%",
                                                                        fontSize: "16px",
                                                                        fontWeight: "500",
                                                                    }}
                                                                >
                                                                    {" "}
                                                                    {records.bankName}{" "}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}{" "}
                                            </div>
                                        ))}{" "}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default AdvisingBank;
