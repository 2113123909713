import { Helmet } from "react-helmet";

// import {useState} from 'react';
import SerHeader from "../component/serviceheader";
import arrowleft from "../asset/images/arrowleftcircle.svg";
import a1 from "../asset/images/letterofcredit/a1.svg";

import a8 from "../asset/images/tradecredit/a8.svg";
import Uploaddocumentfinance from "../component/uploadloaddocument";
import Bankstatement from "../component/bankstatement";

import mark from "../asset/images/marksuccess.svg";

import { useEffect, useState } from "react";
import UploadModal from "../component/uploadModal";

import { useLocation, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Loader from "../component/loader";
import Swal from "sweetalert2/dist/sweetalert2";
import ManagementAccount from "../component/managementAccount";
import CustomerAgreement from "../component/customerAggrement";
import InventoryBankStatemement from "../component/inventoryBankStatement";
import FailHandler from "../component/failhandler";

const Cashflow = () => {
  // const [selectedIndex, setselectedIndex] = useState(-1)
  const navigate = useNavigate();
  const [isdisable, setisdisable] = useState(true);
  const { id } = useParams();
  const { state } = useLocation();
  //console.log(state);
  useEffect(() => {
    setisdisable(false);
  }, [setisdisable]);
  const [filename, setfilename] = useState([]);
  const [filesize, setfilesize] = useState(0);
  const [showModal, setshowModal] = useState(false);
  const [aggreement, setagrrement] = useState([]);
  const [aggreementsize, setaggreementsize] = useState(0);
  const [bank, setbank] = useState([]);
  const [banksize, setbanksize] = useState(0);
  const [showModal2, setshowModal2] = useState(false);
  const [showModal3, setshowModal3] = useState(false);
  const [loader, setloader] = useState(false);
  const [message, setmessage] = useState("");
  const [fail, setfail] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setloader(true);
    const formdata = new FormData();
    formdata.append("managementAccount", filename, filename.name);
    formdata.append("agreement", aggreement, aggreement.name);
    formdata.append("bankStatement", bank, bank.name);

    await axios
      .put(
        `/v1/finance/trade_credit/create/${id}?type=cashFlowSupport`,
        formdata
      )
      .then(() => {
        setloader(false);
        navigate("/trade_of_credit/summary", {
          state: {
            ...state,
            managemnent: filename,
            aggreement: aggreement,
            bank: bank,
            type: "cashflow",
            _id: id,
          },
        });
      })
      .catch((e) => {
        setloader(false);

        setmessage(
          e.response?.data?.message
            ? e.response.data.message
            : "An error occurred"
        );
        setfail(true);
      });
  };
  useEffect(() => {
    if (fail === true) {
      setTimeout(() => {
        setfail(false);
      }, 3000);
    }
  });
  return (
    <div className="h-100 w-100 ">
      <div
        className=""
        style={{
          maxWidth: "2000px",
          width: "100%",
          margin: "0px auto",
        }}
      >
        <Helmet>
          <title>Working Capital</title>
        </Helmet>

        <SerHeader header={"Working Capital"} width={80} />
        <ManagementAccount
          filesize={filesize}
          show={showModal}
          setshow={setshowModal}
          filename={filename}
          setfilesize={setfilesize}
          setfilename={setfilename}
        />
        <CustomerAgreement
          show={showModal2}
          setshow={setshowModal2}
          filename={aggreement}
          filesize={aggreementsize}
          setfilesize={setaggreementsize}
          setfilename={setagrrement}
        />

        <InventoryBankStatemement
          show={showModal3}
          setshow={setshowModal3}
          filename={bank}
          filesize={banksize}
          setfilesize={setbanksize}
          setfilename={setbank}
          title={"Bank statement"}
          text={
            "Your bank statement should clearly indicate your name, and dates"
          }
        />
      </div>
      <div style={{ height: "100%" }}>
        <div
          className="conatiner "
          style={{
            maxWidth: "2000px",
            width: "100%",
            margin: "0px auto",
            paddingLeft: "30px",
            paddingRight: "30px",
            paddingTop: "10px",
            paddingBottom: "10px",
          }}
        >
          <div
            style={{
              height: "100%",
              width: "100%",
              display: "flex",

              justifyContent: "center",
            }}
          >
            <form className="form-general-layout mb-5" onSubmit={handleSubmit}>
              <FailHandler success={fail} message={message} />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "32px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "16px",
                  }}
                >
                  <div
                    onClick={() => {
                      navigate(-1);
                    }}
                  >
                    <img src={arrowleft} alt="" />
                  </div>
                  <div>
                    <div
                      style={{
                        color: "#101828",
                        fontWeight: "600",
                        fontSize: "24px",
                        lineHeight: "32.4px",
                      }}
                    >
                      Upload documents
                    </div>
                    <div
                      style={{
                        color: "#667085",
                        fontSize: "16px",
                        lineHeight: "24px",
                        letterSpacing: "2%",
                        fontWeight: "500",
                        maxWidth: "500px",
                        width: "100%",
                      }}
                    >
                      Provide one of each of the following documents to get your
                      loan.
                    </div>
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "16px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "8px",
                  }}
                >
                  <label
                    htmlFor=""
                    style={{
                      color: "#667085",
                      fontWeight: "500",
                      lineHeight: "19.6px",
                      fontSize: "14px",
                    }}
                  >
                    Recommended documents
                  </label>
                  <div
                    style={{
                      background: "#fff",
                      borderRadius: "8px",
                      flexDirection: "column",
                      display: "flex",
                    }}
                  >
                    <div
                      style={{
                        padding: "16px",
                        borderBottom: "1px solid #E7E9FB",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                      onClick={() => {
                        setfilename([]);
                        setfilesize(0);
                        setshowModal(true);
                      }}
                    >
                      <div
                        style={{
                          gap: "8px",
                          display: "flex",
                        }}
                      >
                        <img src={a1} alt="" />
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <span
                            style={{
                              color: "#101828",
                              letterSpacing: "2%",
                              fontWeight: "600",
                              fontSize: "16px",
                              lineHeight: "24px",
                            }}
                          >
                            Management accounts
                          </span>
                          <span
                            style={{
                              color: "#667085",
                              lineHeight: "16.2px",
                              fontSize: "12px",
                              fontWeight: "500",
                              letterSpacing: "2%",
                            }}
                          >
                            From you
                          </span>
                        </div>
                      </div>
                      {filename.length === 0 ? (
                        <span className="material-icons">chevron_right</span>
                      ) : (
                        <img src={mark} alt="" />
                      )}
                    </div>
                    <div
                      style={{
                        padding: "16px",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                      onClick={() => {
                        setshowModal2(true);
                        setaggreementsize(0);
                        setagrrement([]);
                      }}
                    >
                      <div
                        style={{
                          gap: "8px",
                          display: "flex",
                        }}
                      >
                        <img src={a8} alt="" />
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <span
                            style={{
                              color: "#101828",
                              letterSpacing: "2%",
                              fontWeight: "600",
                              fontSize: "16px",
                              lineHeight: "24px",
                            }}
                          >
                            Customer agreement
                          </span>
                          <span
                            style={{
                              color: "#667085",
                              lineHeight: "16.2px",
                              fontSize: "12px",
                              fontWeight: "500",
                              letterSpacing: "2%",
                            }}
                          >
                            From your country
                          </span>
                        </div>
                      </div>
                      {aggreement.length === 0 ? (
                        <span className="material-icons">chevron_right</span>
                      ) : (
                        <img src={mark} alt="" />
                      )}
                    </div>
                    <div
                      style={{
                        padding: "16px",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                      onClick={() => {
                        setbank([]);
                        setbanksize(0);
                        setshowModal3(true);
                      }}
                    >
                      <div
                        style={{
                          gap: "8px",
                          display: "flex",
                        }}
                      >
                        <img src={a8} alt="" />
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <span
                            style={{
                              color: "#101828",
                              letterSpacing: "2%",
                              fontWeight: "600",
                              fontSize: "16px",
                              lineHeight: "24px",
                            }}
                          >
                            Bank statement
                          </span>
                          <span
                            style={{
                              color: "#667085",
                              lineHeight: "16.2px",
                              fontSize: "12px",
                              fontWeight: "500",
                              letterSpacing: "2%",
                            }}
                          >
                            For 6 months
                          </span>
                        </div>
                      </div>
                      {bank.length === 0 ? (
                        <span className="material-icons">chevron_right</span>
                      ) : (
                        <img src={mark} alt="" />
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <button
                type="submit"
                disabled={isdisable}
                variant="primary"
                style={{
                  maxWidth: "280px",
                  width: "100%",
                  background: isdisable ? "#EBE4FF" : "#6F00FF",
                  padding: "16px 24px 16px 24px",
                  border: "none",
                  outline: "none",
                  borderRadius: "8px",
                  fontSize: "16px",
                  lineHeight: "24px",
                  letterSpacing: "2%",
                  fontWeight: "500",
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                  color: isdisable ? "#BFA6FF" : "#ffff",
                }}
              >
                {loader ? <Loader /> : "Submit"}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Cashflow;
