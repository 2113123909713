import NGNFlagImg from "asset/images/Currency.svg";
import GBPFlagImg from "asset/images/GB.svg";
import USFlagImg from "asset/images/US.png";
import EUFlagImg from "asset/images/EU.svg";
import { ArrowIcon, CheckCircleIcon } from "asset/icons";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useGetRequest from "hooks/useGetRequest";
import currencyPairCheck from "../utilities/currencyPairCheck";
import { formatMoney } from "utilities/formatMoney";

const CurrencyToggle = ({
    businessId,
    fetchWallet,
    hideLabel,
    className = "",
    dropDownClassName = "",
}) => {
    const { wallets: storeWallets, currency: curr, isLogin } = useSelector((state) => state);
    const [fetchWallets, stateWallets] = useGetRequest();
    const dispatch = useDispatch();
    const wrapperRef = useRef();
    const [modalShow, setModalShow] = useState(false);
    const wallets = (stateWallets ?? storeWallets)?.filter((wallet) => wallet?.accountNumber);
    const currency = isLogin ? curr : curr ?? "NGN";

    const setSelectedCurrency = (curr) => {
        dispatch({ type: "CURRENCY", payload: curr });
        localStorage.setItem("default", curr);
    };

    const currencyName = (curr) =>
        curr === "NGN" ? "Naira" : curr === "EUR" ? "Euro" : curr === "USD" ? "Dollar" : "Pound";

    useEffect(() => {
        const outsideClickClose = (evt) =>
            wrapperRef.current && !wrapperRef.current.contains(evt.target) && setModalShow(false);

        document.addEventListener("click", outsideClickClose);
        return () => {
            document.removeEventListener("click", outsideClickClose);
        };
    }, []);

    useEffect(() => {
        if (fetchWallet && !wallets?.length) {
            fetchWallets("/v1/wallets/accounts", {
                headers: {
                    "x-business-id": businessId,
                },
            });
        }
        if (!storeWallets?.length && stateWallets) {
            dispatch({ type: "WALLETS", payload: stateWallets });
        }
    }, [wallets, storeWallets, businessId, stateWallets, fetchWallet]);

    return (
        <button
            className={`flex items-center bg-[#EAECF0] rounded-[4px] p-1 max-375:text-sm 420:p-2 font-medium relative ${className}`}
            onClick={() => (wallets?.length || !fetchWallet) && setModalShow((prev) => !prev)}
            ref={wrapperRef}
            type="button"
        >
            <img
                src={
                    currency === "NGN"
                        ? NGNFlagImg
                        : currency === "GBP"
                        ? GBPFlagImg
                        : currency === "USD"
                        ? USFlagImg
                        : EUFlagImg
                }
                alt={currency}
                className={`size-4 mr-1 ${!currency && "opacity-0"}`}
            />{" "}
            {currency}
            <ArrowIcon className={`ml-1.5 ${!currency && "opacity-0"}`} />
            {/* Dropdown */}
            <div
                className={`absolute min-w-[286px] max-w-[373px] rounded-[10px] bg-white pointer-events-none -right-3 top-[48px] !px-2 !py-4 transition-all duration-300 z-50 ${dropDownClassName} ${
                    modalShow
                        ? "translate-y-0 opacity-100"
                        : "translate-y-[50px] opacity-0 invisible"
                }`}
            >
                {!hideLabel && (
                    <h3 className="!text-lg !font-medium text-left !-mt-1 !mb-3.5 pl-2">
                        Switch wallets
                    </h3>
                )}

                <div className="bg-white rounded-lg !p-1.5 flex flex-col gap-2 !border !border-gray-200/70">
                    {(fetchWallet
                        ? wallets
                        : [
                              { _id: "34tgre", currency: "NGN" },
                              { _id: "f43g", currency: "GBP" },
                              { _id: "f43g3454", currency: "EUR" },
                              { _id: "32r4t", currency: "USD" },
                          ]
                    )?.map(({ _id, currency: curr, balance }) => (
                        <div
                            className={`flex gap-2.5 !p-1 rounded-lg cursor-pointer pointer-events-auto items-center ${
                                currency === curr && "bg-[rgb(244,240,255)]"
                            }`}
                            key={_id}
                            onClick={(evt) => {
                                evt.stopPropagation();
                                setSelectedCurrency(curr);
                                setModalShow(false);
                            }}
                        >
                            <img
                                alt={curr}
                                src={
                                    curr === "NGN"
                                        ? NGNFlagImg
                                        : curr === "GBP"
                                        ? GBPFlagImg
                                        : curr === "USD"
                                        ? USFlagImg
                                        : EUFlagImg
                                }
                                className="rounded-full size-10"
                            />
                            <p className="text-left font-semibold">
                                {currencyName(curr)}
                                <span className="block !mt-[3px] font-medium text-[rgb(152,162,179)]">
                                    {currencyPairCheck(curr)}{" "}
                                    {fetchWallet && (
                                        <span className="text-[rgb(102,112,133)]">
                                            {formatMoney(balance.toFixed(2))}
                                        </span>
                                    )}
                                </span>
                            </p>
                            {currency === curr && <CheckCircleIcon className="ml-auto" />}
                        </div>
                    ))}
                </div>
            </div>
        </button>
    );
};

export default CurrencyToggle;
