import { CheckCircleIcon, SearchIcon } from "asset/icons";
import Input from "component/form/Input";
import useGetRequest from "hooks/useGetRequest";
import { Fragment, useEffect, useState } from "react";
import { Bars } from "react-loader-spinner";
import { useSelector } from "react-redux";
import capitalizeWords from "utilities/capitalizeWords";
import getInitials from "utilities/getInitials";

const PartnerSelect = ({ selectedPartner, partners: _partners, selectPartner, setPartners }) => {
    const [searchString, setSearchString] = useState("");
    const [fetchPartners, internalPartners, partnersLoading] = useGetRequest(false);
    const { business } = useSelector((state) => state);

    const partners = _partners?.filter(
        (item) =>
            item?.receiverBusiness?._id !== business?._id &&
            item.status.toLowerCase() === "active" &&
            `${item.businessName ?? item.business.name}`.toLowerCase().includes(searchString)
    );
    partners?.sort((a, b) =>
        (a.businessName ?? a.business.name).localeCompare(b.businessName ?? b.business.name, "es", {
            sensitivity: "base",
        })
    );

    const alphabets = [
        ...new Set(
            partners?.map(({ businessName, business: { name } }) =>
                (businessName ?? name).charAt(0).toUpperCase()
            )
        ),
    ];

    useEffect(() => {
        if (!partners && !internalPartners && !partnersLoading)
            fetchPartners("/v1/flex/users", {
                headers: {
                    "x-business-id": business?._id,
                },
            });
        if (internalPartners) setPartners(internalPartners);
    }, [partners, partnersLoading, internalPartners, business?._id]);

    return (
        <>
            <p className={`font-medium text-sm text-left !text-[#475467] !mb-2 !mt-3`}>
                Your partners
            </p>

            <div className={`flex items-center bg-[#EAECF0] rounded-lg !gap-2 !px-4`}>
                <SearchIcon className="size-5" />
                <Input
                    placeholder="Search by business name"
                    className="!px-0 pointer-events-auto !shadow-[inset_0_0_0_1000px_#EAECF0]"
                    onChange={(value) => setSearchString(value.toLowerCase())}
                />
            </div>

            <div className={`max-h-[420px] overflow-y-auto rounded-lg !mt-4 !mb-6`}>
                {!partnersLoading && partners?.length ? (
                    <>
                        {alphabets.map((letter, letterIdx) => (
                            <Fragment key={letter}>
                                <p
                                    className={`font-medium  text-left text-xs text-[#667085] ${
                                        letterIdx && "!mt-[15px]"
                                    } !mb-2`}
                                >
                                    {letter}
                                </p>
                                <div
                                    className={`rounded-lg !px-2 bg-white flex flex-col gap-2.5 !py-2.5 border`}
                                >
                                    {partners
                                        .filter(({ businessName, business: { name } }) =>
                                            (businessName ?? name).toUpperCase().startsWith(letter)
                                        )
                                        .map((user) => (
                                            <div
                                                className={`flex items-center gap-2 !py-1.5 cursor-pointer rounded-lg`}
                                                onClick={() => selectPartner(user)}
                                                key={user._id}
                                            >
                                                <span
                                                    className={`!size-10 bg-[#F6F6F6] rounded-full text-[#344054] font-[450] grid place-items-center overflow-hidden ${
                                                        getInitials(
                                                            user.businessName ?? user.business.name
                                                        ).length > 3 && "text-[13px]"
                                                    }`}
                                                >
                                                    {getInitials(
                                                        user.businessName ?? user.business.name
                                                    )}
                                                </span>
                                                <p className="text-main-dark font-[450]">
                                                    {capitalizeWords(
                                                        user.businessName ?? user.business.name
                                                    )}
                                                </p>

                                                {selectedPartner?._id === user._id && (
                                                    <CheckCircleIcon className="ml-auto !mr-2 !size-5" />
                                                )}
                                            </div>
                                        ))}
                                </div>
                            </Fragment>
                        ))}
                    </>
                ) : (
                    <div className="h-[80px] grid place-items-center">
                        {partnersLoading ? (
                            <Bars height="22" width="22" color="#6f00ff" ariaLabel="bars-loading" />
                        ) : (
                            <span className="block text-center text-main">No user found</span>
                        )}
                    </div>
                )}
            </div>
        </>
    );
};

export default PartnerSelect;
