import Modal from "react-bootstrap/Modal";
import success from "../asset/images/failed.png";

function FailedTransaction(props) {
  const closeModal = () => {
    var datashow = props.show;
    props.setshow(!datashow);
  };
  return (
    <Modal
      show={props.show}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="border-0 "
    >
      <Modal.Body
        style={{
          borderRadius: "16px",
          padding: "0px",
          border: "none",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
          }}
        >
          <div
            className=""
            style={{
              borderBottom: "1px solid #E7E9FB",
              padding: "8px 24px 8px 24px",
            }}
          >
            <span
              className="material-icons"
              style={{ color: "#667085" }}
              onClick={closeModal}
            >
              close
            </span>
          </div>
          <div style={{ padding: "8px 24px 8px 24px" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "32px",
                marginBottom: "10px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  gap: "24px",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <div>
                  <img src={success} alt="" />
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "8px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <h3
                      style={{
                        fontWeight: "700",
                        lineHeight: "24.3px",
                        fontSize: "18px",
                        textAlign: "center",
                        color:'#F04438'
                      }}
                    >
                      {props.subject ? props.subject : "Funds sent"}
                    </h3>
                    <p
                      style={{
                        textAlign: "center",
                        color: "#667085",
                        fontWeight: "600",
                        letterSpacing: "2%",
                        fontSize: "16px",
                        lineHeight: "24px",
                      }}
                    >
                      {props.title
                        ? props.title
                        : "We’ll review your request of"}

                      <span
                        style={{
                          fontWeight: "600",
                          color: "#6F00FF",
                          marginRight: "4px",
                          marginLeft: "4px",
                        }}
                      >
                        {props.currency}
                        {props.amount}
                      </span>
                      {props.title
                        ? ""
                        : "  in less than 48 hours. You’ll be notified on how to proceed. Tradevu assumes the credit risk on your buyers."}
                    </p>
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "8px",
                }}
              >
                <button
                  style={{
                    background: "#EBE4FF",
                    color: "#6F00FF",
                    padding: "16px 24px 16px 24px",
                    border: "none",
                    outline: "none",
                    borderRadius: "8px",
                    fontWeight: "600",
                  }}
                  onClick={closeModal}

                 
                >
                  Go back
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default FailedTransaction;
