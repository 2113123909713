import Modal from "react-bootstrap/Modal";
import { useState } from "react";
import "../style/main.css";
import "../style/rightside.css";
import axios from "axios";
import { EMAIL_REGEX } from "constants/global";

function BuyerDetail(props) {
  const [firstName, setfname] = useState("");
  const [lastName, setlname] = useState("");
  const [email, setemail] = useState("");
  const [phoneNumber, setphoneNumber] = useState("");
  const [businessName, setbusinessName] = useState("");

  const isdisabled = !firstName || !lastName || !EMAIL_REGEX.test(email) || !phoneNumber || !businessName

  const handleSubmit = async (e) => {
    e.preventDefault();

    var dataList = props.buyerdetail;
    const datas = {
      firstName: firstName,
      lastName: lastName,
      email: email,
      phoneNumber: phoneNumber,
      businessName: businessName,
      flexUserType: "buyer",
    };
    await axios
      .post("/v1/flex/user", datas)
      .then((res) => {
        //   console.log(res);
        const data = {
          firstName: firstName,
          lastName: lastName,
          email: email,
          phoneNumber: phoneNumber,
          _id: res?.data?.data?._id,
          businessName: businessName,
        };
        // console.log(data);
        if (dataList.length > 0) {
          dataList = [];
          props.setbuyerdetailsinfo([
            // eslint-disable-next-line
            ...dataList,
            data,
          ]);
          props.setNewHandle(false);
        } else {
          props.setbuyerdetailsinfo([
            // eslint-disable-next-line
            ...dataList,
            data,
          ]);
        }
        if (props.selecteditem) {
          props.setselecteditem(data);
          props.setNewHandle(false);
        } else {
          props.setselecteditem(data);
        }
      })
      .catch((e) => {
        console.log(e);
      });
    props.setModalShow(false);
  };
  return (
    <Modal
      show={props.show}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="border-0"
      onHide={() => props.setModalShow(false)}
    >
      <Modal.Body
        style={{
          borderRadius: "16px",
          padding: "0px",
          border: "none",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
          }}
        >
          <div
            className=""
            style={{
              borderBottom: "1px solid #E7E9FB",
              padding: "8px 24px 8px 24px",
            }}
          >
            <span
              className="material-icons"
              style={{ color: "#667085" }}
              onClick={() => {
                props.setModalShow(false);
              }}
            >
              arrow_back
            </span>
          </div>
          <div style={{ padding: "8px 24px 8px 24px" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "4px",
              }}
            >
              <h3
                style={{
                  fontWeight: "600",
                  color: "#101828",
                  lineHeight: "24.3px",
                  fontSize: "18px",
                }}
              >
                Partner’s Details
              </h3>
              <p
                style={{
                  color: "#667085",
                  fontSize: "14px",
                  lineHeight: "19.6px",
                  fontWeight: "400",
                }}
              >
                Enter the details of your partner
              </p>
            </div>
            <form
              className=""
              onSubmit={handleSubmit}
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "8px",
              }}
            >
              <div>
                <input
                  type="text"
                  style={{
                    width: "100%",
                    background: "#ECEFF3",
                    padding: "8px 16px 8px 16px",
                    borderRadius: "8px",
                    color: "#667085",
                    fontSize: "16px",
                    fontWeight: "400",
                    letterSpacing: "2%",
                    outline: "none",
                    border: "none",
                    height: "56px",
                  }}
                  value={firstName}
                  onChange={(e) => {
                    setfname(e.target.value);
                  }}
                  placeholder="First name"
                />
              </div>
              <div>
                <input
                  type="text"
                  style={{
                    width: "100%",
                    background: "#ECEFF3",
                    padding: "8px 16px 8px 16px",
                    borderRadius: "8px",
                    color: "#667085",
                    fontSize: "16px",
                    fontWeight: "400",
                    height: "56px",
                    letterSpacing: "2%",
                    outline: "none",
                    border: "none",
                  }}
                  value={lastName}
                  onChange={(e) => {
                    setlname(e.target.value);
                  }}
                  placeholder="Last name"
                />
              </div>
              <div>
                <input
                  type="email"
                  style={{
                    width: "100%",
                    background: "#ECEFF3",
                    padding: "8px 16px 8px 16px",
                    borderRadius: "8px",
                    color: "#667085",
                    fontSize: "16px",
                    fontWeight: "400",
                    height: "56px",
                    letterSpacing: "2%",
                    outline: "none",
                    border: "none",
                  }}
                  value={email}
                  onChange={(e) => {
                    setemail(e.target.value);
                  }}
                  placeholder="Email address"
                />
              </div>
              <div>
                <input
                  type="tel"
                  style={{
                    width: "100%",
                    background: "#ECEFF3",
                    padding: "8px 16px 8px 16px",
                    borderRadius: "8px",
                    color: "#667085",
                    fontSize: "16px",
                    height: "56px",
                    fontWeight: "400",
                    letterSpacing: "2%",
                    outline: "none",
                    border: "none",
                  }}
                  onChange={(e) => {
                    setphoneNumber(e.target.value);
                  }}
                  value={phoneNumber}
                  placeholder="Phone Number"
                />
              </div>
              <div>
                <input
                  type="text"
                  style={{
                    width: "100%",
                    background: "#ECEFF3",
                    padding: "8px 16px 8px 16px",
                    borderRadius: "8px",
                    color: "#667085",
                    fontSize: "16px",
                    height: "56px",
                    fontWeight: "400",
                    letterSpacing: "2%",
                    outline: "none",
                    border: "none",
                  }}
                  onChange={(e) => {
                    setbusinessName(e.target.value);
                  }}
                  value={businessName}
                  placeholder="Businesss Name"
                />
              </div>

              <div className="mb-2">
                <button
                  style={{
                    width: "100%",
                    background: isdisabled ? "#EBE4FF" : "#6F00FF",
                    padding: "16px 24px 16px 24px",
                    border: "none",
                    outline: "none",
                    borderRadius: "8px",
                    fontSize: "16px",
                    lineHeight: "24px",
                    letterSpacing: "2%",
                    fontWeight: "500",
                    color: isdisabled ? "#BFA6FF" : "#fff",
                  }}
                  disabled={isdisabled}
                >
                  Continues
                </button>
              </div>
            </form>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default BuyerDetail;
