import axios from "axios";
export async function checkAutoLogin(dispatch) {
    const tokenDetailString = localStorage.getItem("token");
    if (!tokenDetailString) {
        // dispatch(logout)
        return;
    } else {
        axios.defaults.headers.common["Authorization"] = `Bearer ${localStorage.getItem("token")}`;
        await axios
            .get(`v1/business/current`)
            .then((res) => {
                dispatch({ type: "BUSINESS_RES", payload: res });
                axios.defaults.headers.common["x-business-id"] = res.data._id;

                axios
                    .get(`/v1/business/${res.data._id}`)
                    .then((res) => {
                        dispatch({ type: "BUSINESSES_RES", payload: res });
                    })
                    .catch((e) => {
                        console.log(e);
                    });
            })
            .catch((e) => {
                console.log(e);
            });

        await axios
            .get("v1/users/profile")
            .then((res) => {
                dispatch({ type: "USER_PROFILE", payload: res });
            })
            .catch((e) => {
                console.log(e);
            });
    }
}
