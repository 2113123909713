import LoginHeader from "../component/loginheader";
import "../style/index.css";
import { useState } from "react";
import ArrowBack from "../component/arrowback";
import { useLocation, useNavigate } from "react-router-dom/dist";
import { Helmet } from "react-helmet";
import Loaders from "../component/loader";
import axios from "axios";
//import Swal from "sweetalert2";
import SuccessHodler from "../component/successholder";
import FailHandler from "../component/failhandler";
import { useEffect } from "react";

const New_Email = () => {
  const [vatnumber, setVatNumber] = useState("");
  const [vatnumberp, setVatNumberp] = useState("");

  const { state } = useLocation();

  const [loader, setLoader] = useState(false);
  const [changecolorforlabelemail, setchangecolorforlabelemail] =
    useState(false);
  const [changecolorforlabelemailp, setchangecolorforlabelemailp] =
    useState(false);
  //const [checked, setchecked] = useState(false);

  const [rclabel, setRclabel] = useState(false);
  const [rclabelp, setRclabelp] = useState(false);

  const [success, setsuccess] = useState(false);
  const [fails, setfails] = useState(false);
  const [message, setmessage] = useState("");
  useEffect(() => {
    let timer = setTimeout(() => {
      setfails(false);
    }, 3000);
    return () => clearTimeout(timer);
  }, [fails]);
  useEffect(() => {
    if (success) {
      let timer = setTimeout(() => {
        setsuccess(false);
        navigate("/");
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [success]);
  const navigate = useNavigate();
  const validatePassword = () => {
    const passwordRegex =
      /^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,}$/;

    if (!passwordRegex.test(vatnumber)) {
      setmessage("Your Password is not strong.");
      setfails(true);
      setLoader(false);
    } else {
      setmessage("");
    }
  };

  const validateConfirmPassword = () => {
    // Check if passwords match
    if (vatnumberp !== vatnumber) {
      setmessage("Passwords do not match.");
      setfails(true);
      setLoader(false);
    } else {
      setmessage(" ");
    }
  };
  const submitDocument = async (e) => {
    e.preventDefault();
    setLoader(true);
    validatePassword();
    validateConfirmPassword();
    if (fails == false) {
      const data = {
        password: vatnumber,
        email: state?.email,
        code: state?.code,
      };
      await axios
        .post(`/v1/users/verify-forgot-password`, data)
        .then((resp) => {
          setLoader(false);
          setsuccess(true);

          setmessage(resp.data.message);
        })
        .catch((e) => {
          setfails(true);
          setLoader(false);

          setmessage(
            e.response?.data.message
              ? e.response?.data.message
              : "An error occur"
          );
        });
    }
  };

  return (
    <div
      className=""
      style={{
        background: "inherit",
        paddingRight: "0px",
        maxWidth: "2000px",
        width: "100%",
        margin: "0px auto",
      }}
    >
      <Helmet>
        <title>Recover Password</title>
      </Helmet>

      <LoginHeader width={13} />

      <div className="">
        <div
          className="form-general-layout"
          style={{
            position: "relative",
          }}
        >
          <SuccessHodler message={message} success={success} />
          <FailHandler message={message} success={fails} />
          <div
            className="card-body"
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "32px",
            }}
          >
            <div
              className="d-flex justify-content-between "
              style={{ alignItems: "center" }}
            >
              <ArrowBack />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "4px",
              }}
            >
              <div
                style={{
                  fontSize: "2rem",
                  fontWeight: "500",
                  color: "#101828",
                  lineHeight: "40px",
                }}
              >
                Enter new password
              </div>
              <span
                style={{
                  color: "#667085",
                  fontSize: "16px",
                  lineHeight: "24px",
                  letterSpacing: "2%",
                  fontWeight: "400",
                }}
              >
                Create a strong password
              </span>
            </div>

            <form
              onSubmit={submitDocument}
              className=""
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "32px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "16px",
                }}
              >
                <div
                  className="w-100  position-relative  "
                  style={{
                    borderRadius: "8px",
                    height: "56px",
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    gap: "4px",
                    padding: "8px 16px",
                    background: changecolorforlabelemail
                      ? "#D7DDEA"
                      : "#ECEFF3",
                  }}
                >
                  {rclabel && (
                    <label
                      htmlFor="Email"
                      className=""
                      style={{
                        fontSize: "12px",

                        fontWeight: "500",
                        color: changecolorforlabelemail ? "#6F00FF" : "#667085",
                        lineHeight: "16.2px",
                      }}
                    >
                      Enter Password
                    </label>
                  )}

                  <input
                    type="password"
                    className="w-100 border-0"
                    onKeyPress={() => {
                      setchangecolorforlabelemail(true);
                    }}
                    onBlur={() => {
                      setchangecolorforlabelemail(false);
                      setRclabel(false);
                      validatePassword();
                    }}
                    style={{
                      outline: "none",
                      borderRadius: "8px",
                      color: "#667085",
                      fontSize: "16px",

                      background: changecolorforlabelemail
                        ? "#D7DDEA"
                        : "#ECEFF3",

                      overflow: "hidden",
                    }}
                    value={vatnumber}
                    // required={!checked}
                    required
                    onChange={(e) => {
                      setVatNumber(e.target.value);
                    }}
                    onFocus={() => {
                      setRclabel(true);
                      setfails(false);
                    }}
                    placeholder="********"
                  />
                </div>
                <div
                  className="w-100  position-relative  "
                  style={{
                    borderRadius: "8px",
                    height: "56px",
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    gap: "4px",
                    padding: "8px 16px",
                    background: changecolorforlabelemailp
                      ? "#D7DDEA"
                      : "#ECEFF3",
                  }}
                >
                  {rclabelp && (
                    <label
                      htmlFor="password"
                      className=""
                      style={{
                        fontSize: "12px",

                        fontWeight: "500",
                        color: changecolorforlabelemailp
                          ? "#6F00FF"
                          : "#667085",
                        lineHeight: "16.2px",
                      }}
                    >
                      Confirm Password
                    </label>
                  )}

                  <input
                    type="password"
                    className="w-100 border-0"
                    onKeyPress={() => {
                      setchangecolorforlabelemailp(true);
                    }}
                    onBlur={() => {
                      setchangecolorforlabelemailp(false);
                      setRclabelp(false);
                      validateConfirmPassword();
                    }}
                    style={{
                      outline: "none",
                      borderRadius: "8px",
                      color: "#667085",
                      fontSize: "16px",

                      background: changecolorforlabelemailp
                        ? "#D7DDEA"
                        : "#ECEFF3",

                      overflow: "hidden",
                    }}
                    value={vatnumberp}
                    // required={!checked}
                    required
                    onChange={(e) => {
                      setVatNumberp(e.target.value);
                    }}
                    onFocus={() => {
                      setRclabelp(true);
                      setfails(false);
                    }}
                    placeholder="********"
                  />
                </div>

                <div
                  className=""
                  style={{
                    fontSize: "14px",
                    color: "#344054",
                    fontWeight: "400",
                    lineHeight: "19.6px",
                  }}
                >
                  <span>
                    I don’t have an account,
                    <span
                      type="button"
                      style={{
                        color: "#6F00FF",
                        fontSize: "14px",
                        fontWeight: "400",
                        lineHeight: "19.6px",
                        marginLeft: "5px",
                      }}
                      onClick={() => {
                        navigate("/register");
                      }}
                    >
                      Create One
                    </span>
                  </span>
                </div>
              </div>

              <button
                disabled={loader}
                type="submit"
                className="btn"
                style={{
                  background: "#6F00FF",
                  color: "#fff",
                  fontSize: "1rem",
                  letterSpacing: "2%",
                  maxWidth: "279px",
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "16px 24px 16px 24px",
                }}
              >
                {loader === true ? (
                  <Loaders data={loader} />
                ) : (
                  "Recover password"
                )}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default New_Email;
