import { useState } from "react";
import search from "../asset/images/search-normal.svg";
import invoice from "../asset/images/invoice.svg";
import addrecrecipt from "../asset/images/partner_add.svg";
import InvoiceDetails from "../component/invoicedetails";

import longinvoice from "../asset/images/orderlogom.svg";
import { useNavigate } from "react-router-dom";
import DeleteFlexInvoice from "../component/DeleteFlexInvoice";
import axios from "axios";
import { useEffect } from "react";
import ComponentLoader from "../component/componentloader";
import AddBuyerModal from "./addbuyerModal";
import AddNetwork from "component/addNetwork";
import { useSelector } from "react-redux";
const PartnerInvoice = (props) => {
    const [tab, settab] = useState("buyer");
    const [searchbank, setsearchbank] = useState("");
    const [totalinvoice, settotalinvoice] = useState(0);
    const [totalAmountOfAllInvoices, settotalAmountOfAllInvoices] = useState(0);
    const [totalAmountunpaid, settotalUnpaidAmount] = useState(0);
    const [totalupaid, settotalUnpaid] = useState(0);
    const [inputsearch, setinputsearch] = useState("");
    const [show, setshow] = useState(false);
    const [showModal3, setshowModal3] = useState(false);
    const [loader, setload] = useState(false);
    const navigate = useNavigate();
    const [deletes, setdelete] = useState(false);
    const [addNetworkModal, setAddNetworkModal] = useState(false);
    const selectorbusiness = useSelector((state) => state.business);
    console.log(selectorbusiness._id);

    const filterBuyer = props.invoice.filter((item) => item.paymentMethod?.match(searchbank));

    filterBuyer.sort((a, b) =>
        a.createdAt.localeCompare(b.createdAt, "es", { sensitivity: "base" })
    );
    let data = filterBuyer.reduce((r, e) => {
        let alphabet = new Date(e.createdAt).toDateString();

        if (!r[alphabet])
            r[alphabet] = {
                alphabet,
                record: [],
            };

        r[alphabet].record.push(e);
        return r;
    }, {});
    let result = Object.values(data);
    useEffect(() => {
        const fetchSummary = () => {
            axios
                .get("/v1/invoice/summary")
                .then((res) => {
                    // console.log(res)
                    settotalinvoice(res.data.data[0]?.totalNumberOfInvoices);
                    settotalAmountOfAllInvoices(res.data.data[0]?.totalAmountOfUnpaidInvoices);
                    settotalUnpaidAmount(res.data.data[0]?.totalAmountOfAllInvoices);
                    settotalUnpaid(res.data.data[0]?.totalNumberOfUnpaidInvoices);
                })
                .catch((e) => {
                    console.log(e);
                });
        };
        fetchSummary();
    }, []);

    const [selecteditem, setselecteditem] = useState([]);
    const deleteInvoiceFlex = async () => {
        setload(true);
        await axios
            .delete(`/v1/invoice/${selecteditem._id}`)
            .then((res) => {
                //console.log(res)
                const updatedHero = props.invoice.filter((item) => item._id !== selecteditem._id);
                props.setinvoices(updatedHero);
                setdelete(true);
                setshowModal3(false);
                setload(false);
                setshow(false);
            })
            .catch((e) => {
                console.log(e);
                setload(false);
                setshowModal3(false);
            });
    };

    return (
        <>
            <div
                className="flex-m-invoice"
                style={{
                    display: "flex",
                    gap: "0px",
                }}
            >
                {/**
      <InvoiceDetails
        show={show}
        setshow={setshow}
        selecteditem={selecteditem}
        setshow3={setshowModal3}
        deletes={deletes}
      />
      <DeleteFlexInvoice
        loader={loader}
        show={showModal3}
        setshow={setshowModal3}
        deletedBuyer={deleteInvoiceFlex}
      />
      */}
                <div
                    style={{
                        height: "100%",
                        background: "#fff",
                        minHeight: "977px",
                        padding: "32px",
                        borderRight: "1px solid #E7E9FB",
                        borderRadius: "24px 0px 0px 0px",
                    }}
                >
                    <div
                        className="long-flex-f"
                        style={{
                            height: "100%",
                            display: "flex",
                            flexDirection: "column",
                            gap: "56px",
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "32px",
                            }}
                        >
                            <div className="partner_count_lg">
                                <div className="inner_lg_count_partner">
                                    <div className="count_partner">{props.activePartnersCount}</div>
                                    <h5 className="partner_name">PARTNERS</h5>
                                </div>
                            </div>

                            <div style={{ display: "flex", flexDirection: "column", gap: "24px" }}>
                                <div className="each_count_lg_partner_data">
                                    {/**
            <div className="each_data_partner">
                <div className="info_partner">
                    Buyers
                </div>
                <span className="info_partner_sm">
                    234
                </span>
            </div>
             */}
                                    {/**
            <div className="each_data_partner" style={{
                borderTop:'1px solid #E7E9FB',
                borderBottom:'1px solid #E7E9FB',
                padding:'10px 0px'
            }}>
                <div className="info_partner">
                    Suppliers
                </div>
                <span className="info_partner_sm">
                    234
                </span>
            </div>
            */}
                                    {/**
            <div className="each_data_partner">
                <div className="info_partner">
                    Vendor
                </div>
                <span className="info_partner_sm">
                    234
                </span>
            </div>
            */}
                                </div>
                                {props.active.length > 0 && (
                                    <button
                                        style={{
                                            background: "#6F00FF",
                                            color: "#fff",
                                            borderRadius: "8px",
                                            padding: "16px 24px 16px 24px",
                                            width: "100%",
                                            border: "none",
                                            maxWidth: "176px",
                                            outline: "none",
                                            display: "flex",
                                            gap: "8px",
                                            justifyContent: "center",
                                            alignItems: "center",
                                        }}
                                        className="relative whitespace-nowrap"
                                        onClick={() => {
                                            setAddNetworkModal(true);
                                        }}
                                    >
                                        <img src={addrecrecipt} alt="" /> Add to Network
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    className="main-c-flex"
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "32px",
                        width: "55%",
                        padding: "32px",
                        margin: "0px auto",
                    }}
                >
                    <div
                        className="tab-search"
                        style={{
                            gap: "16px",
                            display: "flex",
                            flexDirection: "column",
                        }}
                    >
                        <div
                            style={{
                                background: "#ECEFF3",
                                borderRadius: "8px",
                                padding: "4px",
                                display: "flex",
                                gap: "8px",
                                justifyContent: "space-between",
                                height: "56px",
                            }}
                        >
                            <div
                                style={{
                                    padding: "6px 8px 6px 8px",
                                    background: tab === "buyer" ? "#fff" : "",
                                    textAlign: "center",
                                    borderRadius: "4px",
                                    width: "50%",
                                    cursor: "pointer",
                                    color: tab === "buyer" ? "#101828" : "#667085",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                                onClick={() => {
                                    settab("buyer");
                                    setsearchbank("");
                                }}
                            >
                                Active
                            </div>
                            <div
                                style={{
                                    background: tab === "unpaid" ? "#fff" : "",

                                    padding: "6px 8px 6px 8px",
                                    textAlign: "center",
                                    borderRadius: "4px",
                                    width: "50%",
                                    cursor: "pointer",
                                    color: tab === "unpaid" ? "#101828" : "#667085",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    display: "flex",
                                }}
                                onClick={() => {
                                    settab("unpaid");
                                    setsearchbank("unpaid");
                                }}
                            >
                                Incoming
                            </div>
                            <div
                                style={{
                                    background: tab === "draft" ? "#fff" : "",

                                    padding: "6px 8px 6px 8px",
                                    textAlign: "center",
                                    borderRadius: "4px",
                                    width: "50%",
                                    cursor: "pointer",
                                    color: tab === "draft" ? "#101828" : "#667085",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    display: "flex",
                                }}
                                onClick={() => {
                                    settab("draft");
                                    setsearchbank("draft");
                                }}
                            >
                                Pending
                            </div>
                        </div>
                        <div
                            style={{
                                background: "#ECEFF3",
                                display: "flex",
                                gap: "8px",
                                height: "48px",
                                borderRadius: "8px",
                                padding: "0px 8px 0px 8px",
                                alignItems: "center",
                            }}
                        >
                            <img src={search} alt="" />
                            <input
                                type="text"
                                style={{
                                    color: "#667085",
                                    fontSize: "14px",
                                    fontWeight: "500",
                                    lineHeight: "19.6px",
                                    width: "100%",
                                    outline: "none",
                                    background: "#ECEFF3",
                                    border: "none",
                                }}
                                onChange={(e) => {
                                    setinputsearch(e.target.value);
                                }}
                                placeholder="Search by name or business"
                            />
                        </div>
                    </div>

                    {props.loaderset === false ? (
                        <>
                            {tab == "buyer" && (
                                <>
                                    {props.active.length === 0 && (
                                        <div
                                            className="no-flex-c"
                                            style={{
                                                height: "460px",
                                                overflow: "auto",
                                                padding: "16px 24px 24px 24px",
                                                borderRadius: "16px",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                background: "#fff",
                                            }}
                                        >
                                            <div
                                                style={{
                                                    width: "100%",
                                                    alignItems: "center",
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    justifyContent: "center",
                                                    gap: "24px",
                                                }}
                                            >
                                                <img src={invoice} alt="" />
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        gap: "8px",
                                                    }}
                                                >
                                                    <h3
                                                        style={{
                                                            fontWeight: "700",
                                                            color: "#101828",
                                                            fontSize: "18px",
                                                            lineHeight: "24.3px",
                                                            textAlign: "center",
                                                        }}
                                                    >
                                                        You don’t have any partners here
                                                    </h3>
                                                    <span
                                                        style={{
                                                            color: "#667085",
                                                            fontSize: "14px",
                                                            lineHeight: "19.6px",
                                                            fontWeight: "400",
                                                            textAlign: "center",
                                                        }}
                                                    >
                                                        Add partners you trade with to manage their
                                                        orders from here.
                                                    </span>
                                                </div>
                                                <button
                                                    style={{
                                                        background: "#6F00FF",
                                                        color: "#fff",
                                                        borderRadius: "8px",
                                                        padding: "16px 24px 16px 24px",
                                                        maxWidth: "229px",
                                                        width: "100%",
                                                        border: "none",
                                                        outline: "none",
                                                        display: "flex",
                                                        gap: "8px",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                    }}
                                                    onClick={() => {
                                                        setAddNetworkModal(true);
                                                    }}
                                                >
                                                    <img src={addrecrecipt} alt="" /> Add to Network
                                                </button>
                                            </div>
                                        </div>
                                    )}
                                </>
                            )}
                            {tab == "unpaid" && (
                                <>
                                    {props.incoming.length === 0 && (
                                        <div
                                            className="no-flex-c"
                                            style={{
                                                height: "460px",
                                                overflow: "auto",
                                                padding: "16px 24px 24px 24px",
                                                borderRadius: "16px",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                background: "#fff",
                                            }}
                                        >
                                            <div
                                                style={{
                                                    width: "100%",
                                                    alignItems: "center",
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    justifyContent: "center",
                                                    gap: "24px",
                                                }}
                                            >
                                                <img src={invoice} alt="" />
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        gap: "8px",
                                                    }}
                                                >
                                                    <h3
                                                        style={{
                                                            fontWeight: "700",
                                                            color: "#101828",
                                                            fontSize: "18px",
                                                            lineHeight: "24.3px",
                                                            textAlign: "center",
                                                        }}
                                                    >
                                                        You don’t have any partners here
                                                    </h3>
                                                    <span
                                                        style={{
                                                            color: "#667085",
                                                            fontSize: "14px",
                                                            lineHeight: "19.6px",
                                                            fontWeight: "400",
                                                            textAlign: "center",
                                                        }}
                                                    >
                                                        Add partners you trade with to manage their
                                                        orders from here.
                                                    </span>
                                                </div>
                                                <button
                                                    style={{
                                                        background: "#6F00FF",
                                                        color: "#fff",
                                                        borderRadius: "8px",
                                                        padding: "16px 24px 16px 24px",
                                                        maxWidth: "229px",
                                                        width: "100%",
                                                        border: "none",
                                                        outline: "none",
                                                        display: "flex",
                                                        gap: "8px",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                    }}
                                                    onClick={() => {
                                                        setAddNetworkModal(true);
                                                    }}
                                                >
                                                    <img src={addrecrecipt} alt="" /> Add to Network
                                                </button>
                                            </div>
                                        </div>
                                    )}
                                </>
                            )}
                            {tab == "draft" && (
                                <>
                                    {props.pending.length === 0 && (
                                        <div
                                            className="no-flex-c"
                                            style={{
                                                height: "460px",
                                                overflow: "auto",
                                                padding: "16px 24px 24px 24px",
                                                borderRadius: "16px",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                background: "#fff",
                                            }}
                                        >
                                            <div
                                                style={{
                                                    width: "100%",
                                                    alignItems: "center",
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    justifyContent: "center",
                                                    gap: "24px",
                                                }}
                                            >
                                                <img src={invoice} alt="" />
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        gap: "8px",
                                                    }}
                                                >
                                                    <h3
                                                        style={{
                                                            fontWeight: "700",
                                                            color: "#101828",
                                                            fontSize: "18px",
                                                            lineHeight: "24.3px",
                                                            textAlign: "center",
                                                        }}
                                                    >
                                                        You don’t have any partners here
                                                    </h3>
                                                    <span
                                                        style={{
                                                            color: "#667085",
                                                            fontSize: "14px",
                                                            lineHeight: "19.6px",
                                                            fontWeight: "400",
                                                            textAlign: "center",
                                                        }}
                                                    >
                                                        Add partners you trade with to manage their
                                                        orders from here.
                                                    </span>
                                                </div>
                                                <button
                                                    style={{
                                                        background: "#6F00FF",
                                                        color: "#fff",
                                                        borderRadius: "8px",
                                                        padding: "16px 24px 16px 24px",
                                                        maxWidth: "229px",
                                                        width: "100%",
                                                        border: "none",
                                                        outline: "none",
                                                        display: "flex",
                                                        gap: "8px",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                    }}
                                                    onClick={() => {
                                                        setAddNetworkModal(true);
                                                    }}
                                                >
                                                    <img src={addrecrecipt} alt="" /> Add to Network
                                                </button>
                                            </div>
                                        </div>
                                    )}
                                </>
                            )}

                            {tab === "buyer" && (
                                <>
                                    {props.active.length > 0 && (
                                        <div
                                            style={{
                                                height: "100%",
                                                background: "#fff",
                                                padding: "16px 24px 24px 24px",
                                                borderRadius: "16px",
                                            }}
                                        >
                                            <div
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    gap: "16px",
                                                }}
                                            >
                                                {props.active
                                                    ?.slice()
                                                    ?.reverse()
                                                    .map((invoce, index) => (
                                                        <>
                                                            <label
                                                                key={index}
                                                                htmlFor=""
                                                                style={{
                                                                    color: "#98A2B3",
                                                                    fontSize: "12px",
                                                                    fontWeight: "500",
                                                                    lineHeight: "16.2px",
                                                                }}
                                                            >
                                                                {invoce.alphabet}
                                                            </label>
                                                            {invoce.record
                                                                ?.slice()
                                                                .reverse()
                                                                ?.map((item, indexs) => (
                                                                    <>
                                                                        <div
                                                                            key={indexs}
                                                                            style={{
                                                                                border: "1px solid #E7E9FB",
                                                                                borderRadius: "8px",
                                                                                padding: "16px",
                                                                                display: "flex",
                                                                                alignItems:
                                                                                    "center",
                                                                                justifyContent:
                                                                                    "space-between",
                                                                                cursor: "pointer",
                                                                            }}
                                                                            onClick={() => {
                                                                                props.setshowdetail(
                                                                                    true
                                                                                );
                                                                                props.sb(item);
                                                                                props.st(tab);
                                                                            }}
                                                                        >
                                                                            <div
                                                                                style={{
                                                                                    display: "flex",
                                                                                    gap: "8px",
                                                                                }}
                                                                            >
                                                                                <div className="intial">
                                                                                    {item.businessName?.slice(
                                                                                        0,
                                                                                        2
                                                                                    )}
                                                                                </div>
                                                                                <div
                                                                                    style={{
                                                                                        display:
                                                                                            "flex",
                                                                                        flexDirection:
                                                                                            "column",
                                                                                        gap: "4px",
                                                                                    }}
                                                                                >
                                                                                    <div
                                                                                        style={{
                                                                                            color: "#101828",
                                                                                            fontWeight:
                                                                                                "600",
                                                                                            fontSize:
                                                                                                "14px",
                                                                                            lineHeight:
                                                                                                "19.6px",
                                                                                        }}
                                                                                    >
                                                                                        {item
                                                                                            .business
                                                                                            ?._id ===
                                                                                        selectorbusiness._id
                                                                                            ? item
                                                                                                  .receiverBusiness
                                                                                                  ?.name
                                                                                                ? item
                                                                                                      .receiverBusiness
                                                                                                      ?.name
                                                                                                : item.businessName
                                                                                            : item
                                                                                                  .business
                                                                                                  ?.name}
                                                                                    </div>
                                                                                    <span
                                                                                        style={{
                                                                                            color: "#667085",
                                                                                            letterSpacing:
                                                                                                "2%",
                                                                                            fontWeight:
                                                                                                "400",
                                                                                            lineHeight:
                                                                                                "19.6px",
                                                                                            fontSize:
                                                                                                "14px",
                                                                                        }}
                                                                                    >
                                                                                        {item
                                                                                            .business
                                                                                            ?._id ===
                                                                                        selectorbusiness._id
                                                                                            ? item
                                                                                                  .receiverBusiness
                                                                                                  ?.email
                                                                                                ? item
                                                                                                      .receiverBusiness
                                                                                                      ?.email
                                                                                                : item?.email
                                                                                            : item
                                                                                                  .business
                                                                                                  ?.email}
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                            <div
                                                                                style={{
                                                                                    display: "flex",
                                                                                    flexDirection:
                                                                                        "column",
                                                                                    gap: "4px",
                                                                                }}
                                                                            >
                                                                                <div
                                                                                    style={{
                                                                                        color: "#101828",
                                                                                        fontWeight:
                                                                                            "500",
                                                                                        fontSize:
                                                                                            "14px",
                                                                                        lineHeight:
                                                                                            "19.6px",
                                                                                        textAlign:
                                                                                            "right",
                                                                                    }}
                                                                                >
                                                                                    {item?.orders
                                                                                        ?.length > 0
                                                                                        ? item
                                                                                              ?.orders
                                                                                              ?.length +
                                                                                          " Order(s)"
                                                                                        : "No Order"}
                                                                                </div>
                                                                                <span
                                                                                    style={{
                                                                                        color:
                                                                                            item.status ===
                                                                                            "PENDING"
                                                                                                ? "#F79009"
                                                                                                : item.status ===
                                                                                                  "ACTIVE"
                                                                                                ? "#12B76A"
                                                                                                : "#667085",
                                                                                        letterSpacing:
                                                                                            "2%",
                                                                                        fontWeight:
                                                                                            "400",
                                                                                        fontSize:
                                                                                            "14px",

                                                                                        lineHeight:
                                                                                            "19.6px",
                                                                                        textAlign:
                                                                                            "right",
                                                                                    }}
                                                                                >
                                                                                    {item.status}
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                ))}
                                                        </>
                                                    ))}
                                            </div>
                                        </div>
                                    )}
                                </>
                            )}
                            {tab === "unpaid" && (
                                <>
                                    {props.incoming.length >= 1 && (
                                        <div
                                            style={{
                                                height: "100%",
                                                background: "#fff",
                                                padding: "16px 24px 24px 24px",
                                                borderRadius: "16px",
                                            }}
                                        >
                                            <div
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    gap: "16px",
                                                }}
                                            >
                                                {props.incoming
                                                    ?.slice()
                                                    ?.reverse()
                                                    .map((invoce, index) => (
                                                        <>
                                                            <label
                                                                key={index}
                                                                htmlFor=""
                                                                style={{
                                                                    color: "#98A2B3",
                                                                    fontSize: "12px",
                                                                    fontWeight: "500",
                                                                    lineHeight: "16.2px",
                                                                }}
                                                            >
                                                                {invoce.alphabet}
                                                            </label>
                                                            {invoce.record
                                                                ?.slice()
                                                                .reverse()
                                                                .map((item, indexs) => (
                                                                    <>
                                                                        <div
                                                                            key={indexs}
                                                                            style={{
                                                                                border: "1px solid #E7E9FB",
                                                                                borderRadius: "8px",
                                                                                padding: "16px",
                                                                                display: "flex",
                                                                                alignItems:
                                                                                    "center",
                                                                                justifyContent:
                                                                                    "space-between",
                                                                                cursor: "pointer",
                                                                            }}
                                                                            onClick={() => {
                                                                                props.setshowdetail(
                                                                                    true
                                                                                );
                                                                                props.sb(item);
                                                                                props.st(tab);
                                                                            }}
                                                                        >
                                                                            <div
                                                                                style={{
                                                                                    display: "flex",
                                                                                    gap: "8px",
                                                                                }}
                                                                            >
                                                                                <div className="intial">
                                                                                    {item?.business?.name.slice(
                                                                                        0,
                                                                                        2
                                                                                    )}
                                                                                </div>
                                                                                <div
                                                                                    style={{
                                                                                        display:
                                                                                            "flex",
                                                                                        flexDirection:
                                                                                            "column",
                                                                                        gap: "4px",
                                                                                    }}
                                                                                >
                                                                                    <div
                                                                                        style={{
                                                                                            color: "#101828",
                                                                                            fontWeight:
                                                                                                "600",
                                                                                            fontSize:
                                                                                                "14px",
                                                                                            lineHeight:
                                                                                                "19.6px",
                                                                                        }}
                                                                                    >
                                                                                        {
                                                                                            item
                                                                                                ?.business
                                                                                                ?.name
                                                                                        }
                                                                                    </div>
                                                                                    <span
                                                                                        style={{
                                                                                            color: "#667085",
                                                                                            letterSpacing:
                                                                                                "2%",
                                                                                            fontWeight:
                                                                                                "400",
                                                                                            lineHeight:
                                                                                                "19.6px",
                                                                                            fontSize:
                                                                                                "14px",
                                                                                        }}
                                                                                    >
                                                                                        {
                                                                                            item?.email
                                                                                        }{" "}
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                            <div
                                                                                style={{
                                                                                    display: "flex",
                                                                                    flexDirection:
                                                                                        "column",
                                                                                    gap: "4px",
                                                                                }}
                                                                            >
                                                                                <div
                                                                                    style={{
                                                                                        color: "#101828",
                                                                                        fontWeight:
                                                                                            "500",
                                                                                        fontSize:
                                                                                            "14px",
                                                                                        lineHeight:
                                                                                            "19.6px",
                                                                                        textAlign:
                                                                                            "right",
                                                                                    }}
                                                                                >
                                                                                    {item?.orders
                                                                                        ?.length > 0
                                                                                        ? item
                                                                                              ?.orders
                                                                                              ?.length
                                                                                        : "No Order"}
                                                                                </div>
                                                                                <span
                                                                                    style={{
                                                                                        color:
                                                                                            item.status ===
                                                                                            "PENDING"
                                                                                                ? "#F79009"
                                                                                                : item.status ===
                                                                                                  "ACTIVE"
                                                                                                ? "#12B76A"
                                                                                                : "#667085",

                                                                                        letterSpacing:
                                                                                            "2%",
                                                                                        fontWeight:
                                                                                            "400",
                                                                                        fontSize:
                                                                                            "14px",

                                                                                        lineHeight:
                                                                                            "19.6px",
                                                                                        textAlign:
                                                                                            "right",
                                                                                    }}
                                                                                >
                                                                                    {item.status}
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                ))}
                                                        </>
                                                    ))}
                                            </div>
                                        </div>
                                    )}
                                </>
                            )}
                            {tab === "draft" && (
                                <>
                                    {props.pending.length >= 1 && (
                                        <div
                                            style={{
                                                height: "100%",
                                                background: "#fff",
                                                padding: "16px 24px 24px 24px",
                                                borderRadius: "16px",
                                            }}
                                        >
                                            <div
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    gap: "16px",
                                                }}
                                            >
                                                {props.pending
                                                    ?.slice()
                                                    ?.reverse()
                                                    .map((invoce, index) => (
                                                        <>
                                                            <label
                                                                key={index}
                                                                htmlFor=""
                                                                style={{
                                                                    color: "#98A2B3",
                                                                    fontSize: "12px",
                                                                    fontWeight: "500",
                                                                    lineHeight: "16.2px",
                                                                }}
                                                            >
                                                                {invoce.alphabet}
                                                            </label>
                                                            {invoce.record
                                                                ?.slice()
                                                                ?.reverse()
                                                                ?.map((item, indexs) => (
                                                                    <>
                                                                        <div
                                                                            key={indexs}
                                                                            style={{
                                                                                border: "1px solid #E7E9FB",
                                                                                borderRadius: "8px",
                                                                                padding: "16px",
                                                                                display: "flex",
                                                                                alignItems:
                                                                                    "center",
                                                                                justifyContent:
                                                                                    "space-between",
                                                                                cursor: "pointer",
                                                                            }}
                                                                            onClick={() => {
                                                                                props.setshowdetail(
                                                                                    true
                                                                                );
                                                                                props.sb(item);
                                                                                props.st(tab);
                                                                            }}
                                                                        >
                                                                            <div
                                                                                style={{
                                                                                    display: "flex",
                                                                                    gap: "8px",
                                                                                }}
                                                                            >
                                                                                <div className="intial">
                                                                                    {item?.firstName?.slice(
                                                                                        0,
                                                                                        1
                                                                                    )}
                                                                                    {item?.lastName?.slice(
                                                                                        0,
                                                                                        1
                                                                                    )}
                                                                                </div>
                                                                                <div
                                                                                    style={{
                                                                                        display:
                                                                                            "flex",
                                                                                        flexDirection:
                                                                                            "column",
                                                                                        gap: "4px",
                                                                                    }}
                                                                                >
                                                                                    <div
                                                                                        style={{
                                                                                            color: "#101828",
                                                                                            fontWeight:
                                                                                                "500",
                                                                                            fontSize:
                                                                                                "14px",
                                                                                            lineHeight:
                                                                                                "19.6px",
                                                                                        }}
                                                                                    >
                                                                                        {
                                                                                            item.firstName
                                                                                        }{" "}
                                                                                        {
                                                                                            item.lastName
                                                                                        }
                                                                                    </div>
                                                                                    <span
                                                                                        style={{
                                                                                            color: "#667085",
                                                                                            letterSpacing:
                                                                                                "2%",
                                                                                            fontWeight:
                                                                                                "400",
                                                                                            lineHeight:
                                                                                                "19.6px",
                                                                                            fontSize:
                                                                                                "14px",
                                                                                        }}
                                                                                    >
                                                                                        {
                                                                                            item.businessName
                                                                                        }
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                            <div
                                                                                style={{
                                                                                    display: "flex",
                                                                                    flexDirection:
                                                                                        "column",
                                                                                    gap: "4px",
                                                                                }}
                                                                            >
                                                                                <div
                                                                                    style={{
                                                                                        color: "#101828",
                                                                                        fontWeight:
                                                                                            "500",
                                                                                        fontSize:
                                                                                            "14px",
                                                                                        lineHeight:
                                                                                            "19.6px",
                                                                                        textAlign:
                                                                                            "right",
                                                                                    }}
                                                                                >
                                                                                    {item?.orders
                                                                                        ?.length > 0
                                                                                        ? item
                                                                                              ?.orders
                                                                                              ?.length
                                                                                        : "No Order"}
                                                                                </div>
                                                                                <span
                                                                                    style={{
                                                                                        color:
                                                                                            item.status ===
                                                                                            "PENDING"
                                                                                                ? "#F79009"
                                                                                                : item.status ===
                                                                                                  "ACTIVE"
                                                                                                ? "#12B76A"
                                                                                                : item.status ===
                                                                                                  "REJECTED"
                                                                                                ? "crimson"
                                                                                                : "#667085",

                                                                                        letterSpacing:
                                                                                            "2%",
                                                                                        fontWeight:
                                                                                            "400",
                                                                                        fontSize:
                                                                                            "14px",

                                                                                        lineHeight:
                                                                                            "19.6px",
                                                                                        textAlign:
                                                                                            "right",
                                                                                    }}
                                                                                >
                                                                                    {item.status}
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                ))}
                                                        </>
                                                    ))}
                                            </div>
                                        </div>
                                    )}
                                </>
                            )}
                        </>
                    ) : (
                        <ComponentLoader />
                    )}
                </div>
            </div>

            <AddNetwork
                show={addNetworkModal}
                close={(manualAddedUser) => {
                    if (manualAddedUser?.business && manualAddedUser?.user) {
                        props.setActive((prev) => [manualAddedUser, ...prev]);
                    }
                    setAddNetworkModal(false);
                }}
            />
        </>
    );
};

export default PartnerInvoice;
