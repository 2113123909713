import Modal from "react-bootstrap/Modal";
// import addbvn from '../asset/images/addbvn.png'
import { useEffect, useState } from "react";
import Loader from "./loader";
import axios from "axios";
import upload from "../asset/images/document-upload.png";

const Director = (props) => {
  const [showForm] = useState(true);
  const [fname, setfname] = useState("");
  const [lname, setlname] = useState("");
  const [dob, setdob] = useState("");
  const [bvn, setbvn] = useState("");
  const [identity, setIndentity] = useState();
  const [showprops, setShowprops] = useState(false);
  const [showfirstnamelabe, setshowFirstnamelabel] = useState(false);
  const [showLastnamelabe, setshowLastnamelabel] = useState(false);
  const [showbvnlabel, setshowbvnlabel] = useState(false);
  const [loader, setloader] = useState(false);
  const [file, setfile] = useState(null);

  const [doblabel, setdoblabel] = useState(false);
  const [catbvnlabel, setcatbvnlabel] = useState(false);

  const [isbtn, setisbtn] = useState(true);
  const indentification = [
    "IdCard",
    "BioMetricResidencePermit",
    "BirthCertificate",
    "currentPassport",
    "driverLicense",
    // "bvnNumber",
  ];

  useEffect(() => {
    setShowprops(props.show);
  }, [props.show]);

  const closeModal = () => {
    props.setshow(false);
  };

  const showFormandHideForm = () => {
    props.setshow(false);
  };
  const handleBvnDirector = async (e) => {
    e.preventDefault();
    setloader(true);
    const dataList = props.directorDetails;
    const formdata = new FormData();

    const data = {
      firstName: fname,
      lastName: lname,
      meansOfIdentification: identity,
      bvnNumber: bvn,
      dateOfBirth: dob,
      file: "",
      type: "director",
    };
    if (identity !== "bvnNumber") {
      formdata.append("firstName", fname);
      formdata.append("lastName", lname);
      formdata.append("meansOfIdentification", identity);
      formdata.append("bvnNumber", bvn);
      formdata.append("dateOfBirth", dob);
      formdata.append("type", "director");
      formdata.append("file", file, file.name);
    }

    await axios
      .post(
        `v1/business/add-people/${props.state.businessId}`,
        identity === "bvnNumber" ? data : formdata
      )
      .then((res) => {
        console.log(res);
        setdob("");
        setfname("");
        setlname("");
        setbvn("");
        setIndentity("");
        setloader(false);
        setfile(null);
      })
      .catch((e) => {
        console.log(e);
      });
    const datas = {
      fname: fname,
      lname: lname,
    };

    props.setDirectorDetails([
      // eslint-disable-next-line
      ...dataList,
      datas,
    ]);

    setisbtn(!isbtn);
    setShowprops(!showprops);
  };

  return (
    <>
      <Modal
        show={showprops}
        centered
        className="myBilltype modal_new_lg"
        onHide={() => setShowprops(false)}
      >
        <Modal.Header>
          {" "}
          {showForm === true ? (
            <span
              className="material-icons"
              style={{
                cursor: "pointer",
              }}
              onClick={showFormandHideForm}
            >
              close
            </span>
          ) : (
            <span
              className="material-icons"
              style={{
                fontSize: "12px",
                cursor: "pointer",
              }}
              onClick={closeModal}
            >
              close
            </span>
          )}{" "}
        </Modal.Header>
        <Modal.Body
          style={{
            // minHeight: "600px",
            // maxHeight: "600px",
            height: "100%",
          }}
        >
          {" "}
          {showForm && (
            <form
              action=""
              onSubmit={handleBvnDirector}
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "32px",
                height: "100%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "4px",
                }}
              >
                <h6
                  style={{
                    fontSize: "18px",
                    color: "#101828",
                    fontWeight: "800",
                    lineHeight: "24.3px",
                    margin: "0px",
                    padding: "0px",
                  }}
                >
                  Add Director
                </h6>
                <span
                  className=""
                  style={{
                    fontSize: "14px",
                    lineHeight: "19.6px",
                    fontWeight: "400",
                    marginTop: "-5px",
                    display: "block",
                    color: "#667085",
                  }}
                >
                  Enter director details
                </span>
              </div>

              <div
                className="card   border-0"
                style={{ background: "inherit" }}
              >
                <div
                  className="card-body "
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "32px",
                    padding: "0px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "16px",
                    }}
                  >
                    <div
                      className=" position-relative"
                      style={{
                        borderRadius: "8px",
                        height: "56px",
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                        gap: "4px",
                        padding: "8px 16px",
                        background: "#ECEFF3",
                      }}
                    >
                      {showfirstnamelabe && (
                        <label
                          htmlFor="firstname "
                          className=""
                          style={{
                            fontSize: "12px",
                            lineHeight: "16.2px",
                            fontWeight: "500",
                            letterSpacing: "2%",
                            color: "#667085",
                          }}
                        >
                          First Name
                        </label>
                      )}
                      <input
                        type="text"
                        className=" border-0"
                        aria-describedby="emailHelp"
                        placeholder="First name"
                        required
                        style={{
                          background: "#ECEFF3",
                          lineHeight: "24px",
                          color: "#667085",
                          fontSize: "16px",
                          border: "none",
                          outline: "none",
                          fontWeight: "500",
                        }}
                        onBlur={() => {
                          setshowFirstnamelabel(false);
                        }}
                        value={fname}
                        onFocus={() => {
                          setshowFirstnamelabel(true);
                        }}
                        onChange={(e) => {
                          setfname(e.target.value);
                        }}
                      />{" "}
                    </div>
                    <div
                      className=" position-relative "
                      style={{
                        borderRadius: "8px",
                        height: "56px",
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                        gap: "4px",
                        padding: "8px 16px",
                        background: "#ECEFF3",
                      }}
                    >
                      {showLastnamelabe && (
                        <label
                          htmlFor="firstname "
                          className=""
                          style={{
                            fontSize: "12px",
                            lineHeight: "16.2px",
                            fontWeight: "400",
                            letterSpacing: "2%",
                            color: "#667085",
                          }}
                        >
                          Last name
                        </label>
                      )}
                      <input
                        type="text"
                        className="  border-0"
                        required
                        aria-describedby="emailHelp"
                        placeholder="Last name"
                        style={{
                          background: "#ECEFF3",
                          lineHeight: "24px",
                          color: "#667085",
                          fontSize: "16px",
                          border: "none",
                          outline: "none",
                        }}
                        value={lname}
                        onFocus={() => {
                          setshowLastnamelabel(true);
                        }}
                        onBlur={() => {
                          setshowLastnamelabel(false);
                        }}
                        onChange={(e) => {
                          setlname(e.target.value);
                        }}
                      />{" "}
                    </div>
                    <div
                      className=" position-relative "
                      style={{
                        borderRadius: "8px",
                        height: "56px",
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                        gap: "4px",
                        padding: "8px 16px",
                        background: "#ECEFF3",
                      }}
                    >
                      {doblabel && (
                        <label
                          htmlFor="firstname "
                          className=""
                          style={{
                            fontSize: "12px",
                            lineHeight: "16.2px",
                            fontWeight: "400",
                            letterSpacing: "2%",
                            color: "#667085",
                          }}
                        >
                          Date of Birth
                        </label>
                      )}
                      <input
                        type="date"
                        className="  border-0"
                        aria-describedby="emailHelp"
                        placeholder="Last name"
                        style={{
                          background: "#ECEFF3",
                          lineHeight: "24px",
                          color: "#667085",
                          fontSize: "16px",
                          width: "100%",
                          border: "none",
                          outline: "none",
                        }}
                        value={dob}
                        onFocus={() => {
                          setdoblabel(true);
                        }}
                        required
                        onBlur={() => {
                          setdoblabel(false);
                        }}
                        onChange={(e) => {
                          setdob(e.target.value);
                        }}
                      />
                    </div>
                    <div
                      className=" position-relative"
                      style={{
                        borderRadius: "8px",
                        height: "56px",
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                        gap: "4px",
                        padding: "8px 16px",
                        background: "#ECEFF3",
                      }}
                    >
                      {catbvnlabel && (
                        <label
                          htmlFor="firstname "
                          className=""
                          style={{
                            fontSize: "12px",
                            lineHeight: "16.2px",
                            fontWeight: "500",
                            letterSpacing: "2%",
                            color: "#667085",
                          }}
                        >
                          Means of identification
                        </label>
                      )}
                      <select
                        type="text"
                        className=" border-0"
                        aria-describedby="emailHelp"
                        placeholder="Select Country"
                        style={{
                          background: "#ECEFF3",
                          lineHeight: "24px",
                          color: "#667085",
                          fontSize: "16px",
                          border: "none",
                          outline: "none",
                        }}
                        value={identity}
                        onFocus={() => {
                          setcatbvnlabel(true);
                        }}
                        onBlur={() => {
                          setcatbvnlabel(true);
                        }}
                        onChange={(e) => {
                          setIndentity(e.target.value);
                        }}
                      >
                        {indentification.map((indentity, index) => (
                          <option value={indentity} key={index}>
                            {indentity}
                          </option>
                        ))}
                      </select>
                    </div>
                    {identity !== "bvnNumber" && file === null && (
                      <div
                        style={{
                          background: "#ECEFF3",
                          minHeight: "80px",
                          padding: "24px",
                          alignItems: "center",
                          justifyContent: "center",
                          display: "flex",
                          borderRadius: "8px",
                          flexDirection: "column",
                          position: "relative",
                        }}
                      >
                        <input
                          type="file"
                          style={{
                            position: "absolute",
                            opacity: "0",
                            height: "80px",
                            width: "100%",
                            cursor: "pointer",
                          }}
                          onChange={(e) => {
                            setfile(e.target.files[0]);
                          }}
                        />
                        <img src={upload} alt="" />
                        <div>Upload file</div>
                      </div>
                    )}
                    {file !== null && (
                      <div
                        style={{
                          background: "#ECEFF3",
                          minHeight: "56px",
                          padding: "24px",
                          alignItems: "center",
                          justifyContent: "center",
                          display: "flex",
                          borderRadius: "8px",
                          flexDirection: "column",
                          position: "relative",
                          color: "rgb(111, 0, 255)",
                          fontSize: "1rem",
                          fontWeight: "500",
                        }}
                      >
                        {file.name} {"-"}{" "}
                        {(parseFloat(file.size) / 1024).toFixed(2)}{" "}
                        {parseFloat(file.size) / 1024 >= 1 ? "MB" : "KB"}
                      </div>
                    )}
                    {identity === "bvnNumber" && (
                      <div className="form-group position-relative ">
                        <input
                          type="text"
                          className="form-control  border-0"
                          aria-describedby="emailHelp"
                          placeholder="Director BVN"
                          style={{
                            background: "#ECEFF3",
                            height: "56px",
                            lineHeight: "24px",
                          }}
                          value={bvn}
                          onFocus={() => {
                            setshowbvnlabel(true);
                          }}
                          onChange={(e) => {
                            setbvn(e.target.value);
                          }}
                        />{" "}
                        {showbvnlabel && (
                          <label
                            htmlFor="BVN "
                            className="mylabel"
                            style={{
                              fontSize: "12px",
                              lineHeight: "16.2px",
                              fontWeight: "400",
                              letterSpacing: "2%",
                              color: "#667085",
                            }}
                          >
                            Director bvn
                          </label>
                        )}{" "}
                      </div>
                    )}
                  </div>

                  <div className="form-group ">
                    <button
                      disabled={loader}
                      className="btn btn-primary w-100"
                      style={{
                        background: "rgb(111, 0, 255)",
                        padding: "16px 24px 16px 24px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        border: "none",
                      }}
                    >
                      {loader === true ? <Loader data={loader} /> : "Save"}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          )}{" "}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Director;
