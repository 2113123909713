import { Helmet } from "react-helmet";
import { useEffect, useState } from "react";
import SerHeader from "../component/serviceheader";
import nigeria from "../asset/images/countries/ngn.svg";
import US from "../asset/images/US.png";
import EU from "../asset/images/EU.svg";
import GB from "../asset/images/GB.svg";
import Loader from "../component/loader";
import { useLocation, useNavigate } from "react-router-dom";
import "../style/slider.css";
import "../style/main.css";
import info from "../asset/images/iconinfo.svg";
import ConfirmLoanRepay from "../component/confirmModalrepay";
import axios from "axios";
import { useSelector } from "react-redux";
import RepaySuccess from "../component/RepaySuccess";

const RepayLoan = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [loader] = useState(false);
  const [isdisable, setisdisable] = useState(true);
  const [amount, setamount] = useState(state[0]?.amount);
  const [show, setshow] = useState(false);
  const [showsuccess, setshowsuccess] = useState(false);

  useEffect(() => {
    if (amount !== "") {
      setisdisable(false);
    } else {
      setisdisable(true);
    }
  }, [setisdisable, amount]);
  const submitHandler = async (e) => {
    e.preventDefault();

    navigate("/transfer/type", {
      state: {
        currency: "NGN",
        amount: amount,
      },
    });
  };
  //console.log(state);

  return (
    <div className="h-100 w-100 ">
      <div
        className=""
        style={{
          maxWidth: "2000px",
          width: "100%",
          margin: "0px auto",
        }}
      >
        <Helmet>
          <title>Loan Repayment</title>
        </Helmet>

        <SerHeader header={"Repayment"} width={25} />

        <ConfirmLoanRepay
          show={show}
          setshow={setshow}
          data={state}
          setshowsuccess={setshowsuccess}
        />
        <RepaySuccess
          show={showsuccess}
          setshow={setshowsuccess}
          data={state}
        />
      </div>
      <div
        style={{
          height: "100%",
          maxWidth: "2000px",
          margin: "0px auto",
          width: "100%",
        }}
      >
        <div
          className=" "
          style={{
            margin: "0px auto",
            paddingLeft: "30px",
            paddingRight: "30px",
            paddingTop: "10px",
            paddingBottom: "10px",
            width: "100%",
          }}
        >
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",

              justifyContent: "center",
            }}
          >
            <form onSubmit={submitHandler} className="form-general-layout">
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "32px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "8px",
                  }}
                >
                  <div
                    style={{
                      color: "#101828",
                      fontWeight: "500",
                      fontSize: "24px",
                      lineHeight: "32.4px",
                    }}
                  >
                    Repay Credit
                  </div>
                  <span
                    style={{
                      color: "#667085",
                      fontWeight: "500",
                      lineHeight: "24px",
                      fontSize: "1rem",
                      letterSpacing: "2%",
                    }}
                  >
                    Confirm amount to pay
                  </span>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "32px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "8px",
                      width: "100%",
                    }}
                  >
                    <label
                      htmlFor=""
                      style={{
                        fontSize: "14px",
                        lineHeight: "19.6px",
                        fontWeight: "500",
                        color: "#101828",
                      }}
                    >
                      Amount
                    </label>
                    <div
                      className=""
                      style={{
                        display: "flex",
                        gap: "8px",
                        flexDirection: "column",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          gap: "8px",
                        }}
                      >
                        <div
                          style={{
                            width: "100%",
                            maxWidth: "110px",
                            borderRadius: "8px",
                            border: "none",
                            outline: "none",
                            background: "",
                            padding: "8px 16px 8px 16px",
                            backgroundColor: "#ECEFF3",
                            letterSpacing: "2%",
                            fontWeight: "500",
                            fontSize: "16px",
                            display: "flex",
                            gap: "4px",
                            alignItems: "center",
                            position: "relative",
                          }}
                        >
                          <img
                            height={30}
                            src={
                              state.currency === "NGN"
                                ? nigeria
                                : state.currency === "USD"
                                ? US
                                : state.currency === "EUR"
                                ? EU
                                : GB
                            }
                            alt=""
                          />{" "}
                          {state.currency}
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            width: "100%",
                            borderRadius: "8px",
                            border: "none",
                            outline: "none",
                            background: "",
                            padding: "8px 16px 8px 16px",
                            backgroundColor: "#ECEFF3",
                            justifyContent: "center",
                          }}
                        >
                          <input
                            type="text"
                            name=""
                            id=""
                            value={amount}
                            onChange={(e) => {
                              setamount(e.target.value);
                            }}
                            style={{
                              letterSpacing: "2%",
                              fontWeight: "500",
                              fontSize: "16px",
                              outline: "none",
                              border: "none",
                              background: "inherit",
                            }}
                            placeholder="Enter amount"
                          />
                          <label htmlFor="" className="amount_remain">
                            Amount left:{" "}
                            {state.currency === "NGN"
                              ? "₦"
                              : state?.currency === "USD "
                              ? "$"
                              : state?.currency === "EUR"
                              ? "€"
                              : "£"}
                            {parseFloat(
                              state.totalAmount - state[0]?.amount
                            ).toLocaleString()}
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      padding: "16px",
                      borderRadius: "8px",
                      background: "#fff",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        gap: "8px",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <img src={info} alt="" />
                      <span
                        style={{
                          color: "#667085",
                          fontWeight: "500",
                          fontSize: "14px",
                          lineHeight: "19.6px",
                        }}
                      >
                        Paying your credit on time would improve your credit
                        rating and open you up to more credits in the future.
                      </span>
                    </div>
                  </div>

                  <div className="">
                    <button
                      type="button"
                      style={{
                        maxWidth: "280px",
                        width: "100%",
                        background: "#6F00FF",
                        padding: "16px 24px 16px 24px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        border: "none",
                        outline: "none",
                        minHeight: "46px",
                        borderRadius: "8px",
                        fontSize: "16px",
                        lineHeight: "24px",
                        letterSpacing: "2%",
                        fontWeight: "700",
                        color: "#ffff",
                      }}
                      onClick={() => setshow(true)}
                    >
                      {loader === true ? (
                        <Loader data={loader} />
                      ) : (
                        `Pay ${
                          state.currency === "NGN"
                            ? "₦"
                            : state?.currency === "USD "
                            ? "$"
                            : state?.currency === "EUR"
                            ? "€"
                            : "£"
                        }${parseFloat(state[0]?.amount).toLocaleString()}`
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RepayLoan;
