import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import successmark from "../asset/images/Checkbox.svg";

const UtitltyType = (props) => {
    const [billType, setBillType] = useState("Utility Bill");
    // const [modalShow, setModalShow] = useState(false);
    const [selected, setSelected] = useState(0);
    const [isChecked, setisChecked] = useState(false);

    const checkclick = (bill, index) => {
        setSelected(index);
        props.setname(bill.name);
        props.setshow(false);

        isChecked ? setisChecked(false) : setisChecked(true);
    };

    const data = [
        {
            name: "Utility Bill",
            id: "1",
        },
        {
            name: "Electricity Bill",
            id: "2",
        },
        {
            name: "Tax Bill",
            id: "3",
        },
    ];

    return (
        <Modal
            show={props.show}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="myBilltype"
        >
            <Modal.Header>
                <span
                    className="material-icons"
                    onClick={() => props.setshow(false)}
                    style={{
                        cursor: "pointer",
                    }}
                >
                    close
                </span>
            </Modal.Header>
            <Modal.Body>
                <div
                    style={{
                        fontWeight: "700",
                        fontSize: "16px",
                        lineHeight: "24px",
                        letterSpacing: "1%",
                        paddingTop: "10px",
                        paddingBottom: "20px",
                    }}
                >
                    Select document type
                </div>
                <div
                    className="card border-0"
                    style={{ background: "#ffff", marginBottom: "30px" }}
                >
                    {data.map((bill, index) => (
                        <div
                            className="card-body p-0 "
                            key={index}
                            onClick={() => {
                                checkclick(bill, index);
                            }}
                        >
                            <div
                                className="p-2  d-flex gap-3"
                                style={{
                                    flexDirection: "row",
                                    flexShrink: "1",
                                    alignItems: "center",
                                }}
                            >
                                <div
                                    className="d-flex justify-content-between w-100"
                                    style={{
                                        overflow: "hidden",
                                        alignItems: "center",
                                        padding: "6px 8px",
                                        borderRadius: "8px",
                                        background: selected === index && "#F4F0FF",
                                    }}
                                >
                                    <div>
                                        <span
                                            style={{
                                                fontWeight: "600",
                                                fontSize: "16px",
                                                letterSpacing: "2%",
                                                lineHeight: "24px",
                                                color: "#101828",
                                            }}
                                        >
                                            {bill.name}
                                        </span>
                                    </div>
                                    {selected === index && <img src={successmark} alt="" />}
                                </div>
                            </div>
                        </div>
                    ))}{" "}
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default UtitltyType;
