import Modal from "react-bootstrap/Modal";
import { useState } from "react";
import checkbox from "../asset/images/Checkbox.svg";

function BusinessTypeModal(props) {
  const [selectedindex, setselectedindex] = useState(0);

  const closeModal = () => {
    var datashow = props.show;
    props.setshow(!datashow);
  };
  const handleSubmit = (tenure) => {
    var dataList = props.tenureday;

    props.settenureday(
      // eslint-disable-next-line
      (dataList = tenure)
    );

    props.setshow(false);
  };
  return (
    <Modal
      show={props.show}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="border-0 "
      onHide={() => props.setshow(false)}
    >
      <Modal.Body
        style={{
          borderRadius: "16px",
          padding: "0px",
          border: "none",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
          }}
        >
          <div
            className=""
            style={{
              borderBottom: "1px solid #E7E9FB",
              padding: "8px 24px 8px 24px",
            }}
          >
            <span
              className="material-icons"
              style={{ color: "#667085" }}
              onClick={closeModal}
            >
              close
            </span>
          </div>
          <div style={{ padding: "8px 24px 8px 24px" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "4px",
              }}
            >
              <h3
                style={{
                  fontWeight: "700",
                  color: "#101828",
                  lineHeight: "24.3px",
                  fontSize: "18px",
                }}
              >
                {props?.title ? props?.title : "Choose Business type"}
              </h3>
              <div
                className="card border-0"
                style={{
                  marginBottom: "20px",
                }}
              >
                <div className="card-body p-1">
                  {" "}
                  {props.tenure.map((tenures, index) => (
                    <div
                      key={index}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        background: selectedindex === index ? "#F4F0FF" : "",
                        borderRadius: "8px",
                        padding: selectedindex === index ? "6px 8px" : "",
                      }}
                      onClick={() => {
                        handleSubmit(tenures.type);
                        setselectedindex(index);
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <span
                          style={{
                            fontWeight: "600",
                            letterSpacing: "2%",
                            fontSize: "16px",
                            lineHeight: "24px",
                            color: "#101828",
                          }}
                        >
                          {tenures.type === "SoleProprietorship"
                            ? "Sole Proprietorship"
                            : "LLC"}
                        </span>
                        <span
                          style={{
                            fontWeight: "400",
                            fontSize: "12px",
                            lineHeight: "16.2px",
                            color: "#667085",
                          }}
                        >
                          {tenures.title}
                        </span>
                      </div>
                      {selectedindex === index && (
                        <img
                          src={checkbox}
                          alt=""
                          style={{
                            width: "13.33px",
                            height: "13.33px",
                          }}
                        />
                      )}{" "}
                    </div>
                  ))}{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default BusinessTypeModal;
