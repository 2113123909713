import loader from "asset/images/l.gif";
import loaderNoBg from "asset/images/loader.gif";

const ComponentLoader = ({ free, className }) => {
    return free ? (
        <img src={loaderNoBg} alt="loader" className={` ${className}`} />
    ) : (
        <div
            className={`emtyp-lg ${className}`}
            style={{
                maxHeight: "400px",
                minHeight: "400px",
                height: "100%",
            }}
        >
            <img src={loader} alt="" />
        </div>
    );
};

export default ComponentLoader;
