//import {useState} from 'react';
import Modal from "react-bootstrap/Modal";
import "../style/rightside.css";
import "../style/main.css";
import edit from "../asset/images/editperson.png";
import deletes from "../asset/images/flex/deletes.svg";
import { useEffect, useState } from "react";
import send from "../asset/images/send-2.svg";
import tag from "../asset/images/user-tag.svg";
import "../style/main.css";
import "../style/rightside.css";
import Loader from "../component/loader";
import axios from "axios";
import SuccessHodler from "../component/successholder";
import FailHandler from "../component/failhandler";
import ComponentLoader from "../component/componentloader";
import currencyPairCheck from "../utilities/currencyPairCheck";
import AmountCheck from "../component/amountCheck";
import { useSelector } from "react-redux";

function BuyerDetailsModa(props) {
    const status = ["All", "Incoming", "Pending", "Completed", "Declined"];
    const [selectedstatus, setselectedstatus] = useState("All");
    const [acceptloade, setacceptloader] = useState(false);

    const [rejectloade, setrejectloader] = useState(false);
    const [success, setsuccess] = useState(false);
    const [failhandler, setfailhandler] = useState(false);
    const [message, setmessage] = useState("");
    const selectorbusiness = useSelector((state) => state.business);
    const [orderStatus, setOrderStatus] = useState("pending");

    useEffect(() => {
        if (deletes || success) {
            const timer = setTimeout(() => {
                setsuccess(false);
                setfailhandler(false);
            });

            return () => {
                clearTimeout(timer);
            };
        }
    });
    // console.log(props.selectedbuyers.status);

    const filterorder = props?.orders?.filter((item) =>
        item.status.match(
            selectedstatus === "All"
                ? ""
                : selectedstatus === "Incoming"
                ? "pending"
                : selectedstatus === "Completed"
                ? "complete"
                : selectedstatus === "Decline"
                ? "declined"
                : "cancelled"
        )
    );
    filterorder.sort((a, b) =>
        a.createdAt.localeCompare(b.createdAt, "es", { sensitivity: "base" })
    );
    let data = filterorder.reduce((r, e) => {
        let alphabet = new Date(e.createdAt).toDateString();

        if (!r[alphabet])
            r[alphabet] = {
                alphabet,
                record: [],
            };

        r[alphabet].record.push(e);
        return r;
    }, {});
    let result = Object.values(data);
    const SubmitAccept = async () => {
        setacceptloader(true);
        await axios
            .put(`/v1/flex/users/incoming/${props.selectedbuyers._id}`)
            .then((res) => {
                // console.log(res);
                setmessage(res.data.message);
                setOrderStatus("done");
                setsuccess(true);
                setacceptloader(false);
                props.setsupplier(...props.supplier, res.data.data);
                props.setshow(false);
            })
            .catch((e) => {
                setmessage(e.response?.data.message ? e.response?.data.message : "An error ocuur");
                setfailhandler(true);
                setacceptloader(false);
            });
    };
    const SubmitReject = async () => {
        setrejectloader(true);
        await axios
            .delete(`/v1/flex/users/incoming/${props.selectedbuyers._id}`)
            .then((res) => {
                // console.log(res);
                setmessage("Partner request  rejected");
                setOrderStatus("done");
                setsuccess(true);
                setrejectloader(false);
                // props.setsupplier(...props.supplier, res.data.data);
                props.setshow(false);
            })
            .catch((e) => {
                setmessage(
                    e.response?.data.message ? e.response?.data.message : "An error ocuurred"
                );
                setfailhandler(true);
                setacceptloader(false);
            });
    };
    return (
        <>
            <Modal
                show={props.show}
                backdrop="false"
                keyboard={false}
                className="rightsidemodal"
                onHide={() => props.setshow(false)}
            >
                <div
                    className=""
                    style={{
                        height: "100%",
                        display: "flex",
                        justifyContent: "center",
                        //  alignItems: "center",
                    }}
                >
                    <div
                        style={{
                            width: "100%",
                            padding: "20px",
                        }}
                    >
                        <SuccessHodler success={success} message={message} />
                        <FailHandler success={failhandler} message={message} />

                        <div className="w-100" style={{ height: "100%" }}>
                            <div
                                className="w-100 "
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: "16px",
                                }}
                            >
                                <div
                                    className="d-flex gap-3 align-items-center"
                                    style={{
                                        marginTop: "40px",
                                    }}
                                >
                                    <span
                                        className="material-icons"
                                        style={{
                                            color: "#667085",
                                            cursor: "pointer",
                                        }}
                                        onClick={() => {
                                            props.setshow(false);
                                        }}
                                    >
                                        arrow_back
                                    </span>
                                    <span
                                        style={{
                                            fontSize: "14px",
                                            color: "#667085",
                                            lineHeight: "18.9px",
                                            fontWeight: "500",
                                            textTransform: "uppercase",
                                        }}
                                    >
                                        back to flex
                                    </span>
                                </div>
                                <div
                                    className=""
                                    style={{
                                        fontSize: "32px",
                                        lineHeight: "40px",
                                        fontWeight: "500",
                                        color: "#101828",
                                        textTransform: "capitalize",
                                    }}
                                >
                                    Partner Details
                                </div>
                            </div>
                            <div className="buyer-details-lg-col">
                                <div className="tag-details-lg">
                                    {selectorbusiness?._id ===
                                        props.selectedbuyers.receiverBusiness?._id && (
                                        <div
                                            className="lg-sie-avater-lg"
                                            style={{
                                                width: "100%",
                                            }}
                                        >
                                            <div className="intial">
                                                {props.selectedbuyers?.business?.name?.slice(0, 2)}
                                                {/***  {props.selectedbuyers?.lastName?.slice(0, 1)}*/}
                                            </div>
                                            <div
                                                className="top-tier-lg"
                                                style={{
                                                    width: "100%",
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        justifyContent: "space-between",
                                                        alignItems: "center",
                                                        width: "100%",
                                                    }}
                                                >
                                                    <h4
                                                        style={{
                                                            fontWeight: "700",
                                                        }}
                                                    >
                                                        {props.selectedbuyers.business?.name}{" "}
                                                        {/**  {props.selectedbuyers.lastName}*/}
                                                    </h4>
                                                    <span>
                                                        {props?.orders.length > 0
                                                            ? props?.orders.length +
                                                              " " +
                                                              "Order(s)"
                                                            : "No Order"}
                                                    </span>
                                                </div>

                                                <span
                                                    style={{
                                                        fontWeight: "400",
                                                    }}
                                                >
                                                    {props.selectedbuyers.business?.email}
                                                </span>
                                            </div>
                                        </div>
                                    )}
                                    {selectorbusiness?._id !==
                                        props.selectedbuyers.receiverBusiness?._id && (
                                        <div
                                            className="lg-sie-avater-lg"
                                            style={{
                                                width: "100%",
                                            }}
                                        >
                                            <div className="intial">
                                                {props.selectedbuyers?.businessName?.slice(0, 2)}
                                                {/***  {props.selectedbuyers?.lastName?.slice(0, 1)}*/}
                                            </div>
                                            <div
                                                className="top-tier-lg"
                                                style={{
                                                    width: "100%",
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        justifyContent: "space-between",
                                                        alignItems: "center",
                                                        width: "100%",
                                                    }}
                                                >
                                                    <h4
                                                        style={{
                                                            fontWeight: "700",
                                                        }}
                                                    >
                                                        {props.selectedbuyers?.businessName}{" "}
                                                        {/**  {props.selectedbuyers.lastName}*/}
                                                    </h4>
                                                    <span>
                                                        {props?.orders.length > 0
                                                            ? props?.orders.length +
                                                              " " +
                                                              "Order(s)"
                                                            : "No Order"}
                                                    </span>
                                                </div>

                                                <span
                                                    style={{
                                                        fontWeight: "400",
                                                    }}
                                                >
                                                    {props.selectedbuyers?.email}
                                                </span>
                                            </div>
                                        </div>
                                    )}
                                    <div
                                        style={{
                                            marginTop: "20px",
                                            width: "100%",
                                            background: "#F4F0FF",
                                            padding: "8px",
                                            borderRadius: "6px",
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                        }}
                                    >
                                        <div
                                            style={{
                                                display: "flex",
                                                gap: "4px",
                                                alignItems: "center",
                                                fontWeight: "500",
                                                fontSize: "14px",
                                                lineHeight: "19.6px",
                                                color: "#6F00FF",
                                                textTransform: "capitalize",
                                            }}
                                        >
                                            <img src={tag} alt="" />
                                            {props?.selectedbuyers?.flexUserType}
                                        </div>
                                        {/**
                    <span
                      style={{
                        color: "#344054",
                      }}
                    >
                      {props.tab}
                      {props.tab === "active"
                        ? "Active"
                        : props.tab === "unpaid"
                        ? "Incoming"
                        : "Pending"}
                    </span>
                    */}
                                    </div>
                                </div>
                                {props.tab !== "unpaid" && props.tab !== "draft" && (
                                    <>
                                        <div
                                            className=""
                                            style={{
                                                borderTop: "1px solid #E7E9FB",
                                                width: "90%",
                                                display: "flex",
                                                padding: "8px 0px 8px 0px",
                                                gap: "16px",
                                                alignItems: "center",
                                                borderBottom: "1px solid #E7E9FB",
                                            }}
                                        >
                                            <div
                                                style={{
                                                    display: "flex",
                                                    gap: "4px",
                                                    padding: "8px",
                                                }}
                                            >
                                                <img src={edit} alt="" />
                                                <span
                                                    style={{
                                                        color: "#6F00FF",
                                                        fontWeight: "500",
                                                        fontSize: "14px",
                                                        lineHeight: "19.6px",
                                                        cursor: "pointer",
                                                    }}
                                                    onClick={() => props.setshowedit(true)}
                                                >
                                                    Edit Partner
                                                </span>
                                            </div>

                                            <div
                                                style={{
                                                    display: "flex",
                                                    gap: "4px",
                                                    padding: "8px",
                                                }}
                                                onClick={() => {
                                                    props.setshowModal3(true);
                                                    props.setshow(false);
                                                }}
                                            >
                                                <img src={deletes} alt="" />
                                                <span
                                                    style={{
                                                        color: "#D92D20",
                                                        fontWeight: "500",
                                                        fontSize: "14px",
                                                        lineHeight: "19.6px",
                                                        cursor: "pointer",
                                                    }}
                                                >
                                                    Delete partner
                                                </span>
                                            </div>
                                        </div>

                                        <div className="item-details-action">
                                            {status.map((stat, index) => (
                                                <span
                                                    className="each-item-actions"
                                                    key={index}
                                                    onClick={() => setselectedstatus(stat)}
                                                    style={{
                                                        fontWeight: "500",
                                                        color:
                                                            selectedstatus === stat
                                                                ? "#7C1E12"
                                                                : "#667085",
                                                        background:
                                                            selectedstatus === stat
                                                                ? "#FECBAA"
                                                                : "",
                                                    }}
                                                >
                                                    {stat}
                                                </span>
                                            ))}
                                        </div>
                                        {props.loaderset2 && <ComponentLoader />}
                                        <div className="order-details-list-lg">
                                            <label htmlFor="" style={{ fontWeight: "500" }}>
                                                Orders
                                            </label>
                                            {filterorder.length === 0 && (
                                                <div className="Noorder">No order</div>
                                            )}
                                            {filterorder.length > 0 &&
                                                result?.reverse().map((order, index) => (
                                                    <>
                                                        <div className="days-lg-line" key={index}>
                                                            <span
                                                                style={{
                                                                    fontWeight: "500",
                                                                    fontSize: "12px",
                                                                    lineHeight: "16.2px",
                                                                    color: "#98A2B3",
                                                                }}
                                                            >
                                                                {new Date(order.alphabet) ===
                                                                new Date().getDate()
                                                                    ? "Today"
                                                                    : new Date(order.alphabet) ===
                                                                      new Date(
                                                                          new Date(
                                                                              order.alphabet
                                                                          ).getTime()
                                                                      ).setDate(
                                                                          new Date(
                                                                              order.alphabet
                                                                          ).getDate() - 1
                                                                      )
                                                                    ? "yesterday"
                                                                    : new Date(
                                                                          order.alphabet
                                                                      ).toLocaleDateString()}
                                                            </span>
                                                            <div className="line"></div>
                                                        </div>
                                                        {order.record.map((record, index) => (
                                                            <div
                                                                className="buyer-details-lg-col"
                                                                style={{
                                                                    maxWidth: "100%",
                                                                    marginTop: "-10px",
                                                                    cursor: "pointer",
                                                                }}
                                                                onClick={() => {
                                                                    props.setshow(false);
                                                                    props.setorderdetails(record);

                                                                    props.setshoworderdetails(true);
                                                                }}
                                                                key={index}
                                                            >
                                                                <div
                                                                    className="tag-details-lg"
                                                                    style={{
                                                                        maxWidth: "100%",
                                                                        width: "100%",
                                                                        display: "flex",
                                                                        justifyContent:
                                                                            "space-between",
                                                                        alignItems: "center",
                                                                    }}
                                                                >
                                                                    <div className="lg-sie-avater-lg">
                                                                        <img src={order} alt="" />

                                                                        <div className="top-tier-lg">
                                                                            <div
                                                                                style={{
                                                                                    fontWeight:
                                                                                        "600",
                                                                                    color: "#101828",
                                                                                    fontSize:
                                                                                        "14px",
                                                                                    lineHeight:
                                                                                        "19.6px",
                                                                                }}
                                                                            >
                                                                                {record?.title}
                                                                            </div>
                                                                            <span>
                                                                                {record.status}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="lg-sie-avater-lg">
                                                                        <div className="top-tier-lg">
                                                                            <div
                                                                                style={{
                                                                                    fontWeight:
                                                                                        "500",
                                                                                    color: "#101828",
                                                                                    fontSize:
                                                                                        "14px",
                                                                                    lineHeight:
                                                                                        "19.6px",
                                                                                    textAlign:
                                                                                        "right",
                                                                                }}
                                                                            >
                                                                                {currencyPairCheck(
                                                                                    record?.currency
                                                                                )}
                                                                                {AmountCheck(
                                                                                    record?.totalAmount
                                                                                )}
                                                                            </div>
                                                                            <span>
                                                                                {new Date(
                                                                                    record.createdAt
                                                                                ).toDateString()}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </>
                                                ))}
                                        </div>
                                        <button
                                            className="order-btn"
                                            style={{
                                                marginRight: "40px",
                                                bottom: "20px",
                                            }}
                                            onClick={() => {
                                                props.setshoworder(true);
                                            }}
                                        >
                                            <img src={send} alt="" /> Send Orders
                                        </button>
                                    </>
                                )}
                            </div>
                        </div>
                        {props.tab === "unpaid" &&
                            props.selectedbuyers.status === "PENDING" &&
                            orderStatus === "pending" && (
                                <div
                                    className="button-lg-con"
                                    style={{
                                        width: "85%",
                                        position: "absolute",
                                        bottom: "20px",
                                    }}
                                    disabled={rejectloade}
                                    onClick={SubmitReject}
                                >
                                    <button className="btn-accept-decline decline" type="button">
                                        {rejectloade ? <Loader /> : "Decline"}
                                    </button>
                                    <button
                                        className="btn-accept-decline accept"
                                        type="button"
                                        disabled={acceptloade}
                                        onClick={SubmitAccept}
                                    >
                                        {acceptloade ? <Loader /> : "Accept"}
                                    </button>
                                </div>
                            )}
                    </div>
                </div>
            </Modal>
        </>
    );
}

export default BuyerDetailsModa;
