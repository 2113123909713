// import {useState} from 'react';
import Modal from "react-bootstrap/Modal";

import "../style/rightside.css";

import share from "../asset/images/flex/share.svg";
import edit from "../asset/images/flex/edits.svg";
//import resend from "../asset/images/flex/resend.svg";
import deletes from "../asset/images/flex/deletes.svg";
import RecordPayment from "./Recordpayment";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import currencyPairCheck from "../utilities/currencyPairCheck";
import AmountCheck from "./amountCheck";

function InvoiceDetails(props) {
    const [show, setshow] = useState();
    const [eachinvoice, seteachinvoice] = useState([]);
    const [invoices, setinvoices] = useState([]);
    const selectorbusiness = useSelector((state) => state.business);
    const selector = useSelector((state) => state.users);

    //console.log(props?.selecteditem);
    useEffect(() => {
        const fetchinoice = async () => {
            await axios
                .get(`/v1/invoice/${props?.selecteditem._id}`)
                .then((res) => {
                    console.log(res);
                    seteachinvoice(res.data.data.invoiceItems);
                    setinvoices(res.data.data.recordPayments);
                })
                .catch((e) => {
                    console.log(e);
                });
        };
        if (props?.selecteditem?._id) {
            fetchinoice();
        }
    }, [props.selecteditem._id, seteachinvoice]);

    const navigate = useNavigate();
    return (
        <>
            <RecordPayment
                show={show}
                setshow={setshow}
                selectedinvoice={props.selecteditem}
                record={invoices}
                setinvoices={setinvoices}
                currency={props.selecteditem.currency}
            />
            <Modal
                show={props.show}
                //  onHide={handleClose}
                backdrop="false"
                onHide={() => props.setshow(false)}
                keyboard={false}
                className=" mysidedetail"
            >
                <div
                    className=""
                    style={{
                        height: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        position: "relative",
                    }}
                >
                    <div
                        style={{
                            height: "calc(100% - 150px)",
                            width: "100%",
                            padding: "20px",
                        }}
                    >
                        <div className="w-100" style={{ height: "100%", marginTop: "40px" }}>
                            <div
                                className="w-100 "
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: "16px",
                                    position: "relative",
                                }}
                            >
                                {props.deletes && (
                                    <div
                                        className="success"
                                        style={{
                                            background: "#FCFCFD",
                                            border: " 1px solid #D0D5DD",
                                        }}
                                    >
                                        <div className="lg-success">
                                            <div
                                                className="ech-lg-hm"
                                                style={{
                                                    background: "#F9FAFB",
                                                }}
                                            >
                                                <div
                                                    className="main-sucees"
                                                    style={{
                                                        color: "#344054",
                                                    }}
                                                >
                                                    Deleted
                                                </div>
                                                <span
                                                    style={{
                                                        color: "#344054",
                                                    }}
                                                >
                                                    You’ve deleted an invoice
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                )}

                                <div
                                    className="d-flex gap-3 align-items-center"
                                    onClick={() => {
                                        props.setshow(false);
                                    }}
                                >
                                    <span
                                        className="material-icons"
                                        style={{
                                            color: "#667085",
                                            cursor: "pointer",
                                        }}
                                    >
                                        arrow_back
                                    </span>
                                    <span
                                        style={{
                                            fontSize: "14px",
                                            color: "#667085",
                                            lineHeight: "18.9px",
                                            fontWeight: "500",
                                            textTransform: "uppercase",
                                        }}
                                    >
                                        back to pay
                                    </span>
                                </div>

                                <div
                                    className=""
                                    style={{
                                        fontSize: "2rem",
                                        lineHeight: "40px",
                                        fontWeight: "500",
                                        color: "#101828",
                                    }}
                                >
                                    {props.selecteditem.invoiceTitle}
                                </div>
                            </div>
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: "32px",
                                    marginTop: "30px",
                                }}
                            >
                                <div
                                    className=""
                                    style={{
                                        border: "1px solid #E7E9FB",
                                        maxWidth: "90%",
                                        borderRadius: "8px",
                                        width: "100%",
                                        padding: "16px",
                                        display: "flex",
                                        gap: "24px",
                                        flexDirection: "column",
                                    }}
                                >
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            gap: "4px",
                                        }}
                                    >
                                        <span
                                            style={{
                                                color: "#6F00FF",
                                                fontWeight: "500",
                                                fontSize: "14px",
                                                lineHeight: "19.6px",
                                            }}
                                        >
                                            Amount due
                                        </span>
                                        <span
                                            style={{
                                                color: "#101828",
                                                fontWeight: "500",
                                                lineHeight: "32.4px",
                                                fontSize: "24px",
                                            }}
                                        >
                                            {currencyPairCheck(props.selecteditem.currency)}
                                            {AmountCheck(
                                                props.selecteditem?.totalAmount
                                                    ? props.selecteditem?.totalAmount
                                                    : 0
                                            )}
                                        </span>
                                    </div>
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                        }}
                                    >
                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                gap: "4px",
                                            }}
                                        >
                                            <span
                                                style={{
                                                    color: "#667085",
                                                    fontWeight: "500",
                                                    fontSize: "14px",
                                                    lineHeight: "19.6px",
                                                }}
                                            >
                                                Issue date
                                            </span>
                                            <span
                                                style={{
                                                    color: "#101828",
                                                    fontWeight: "500",
                                                    lineHeight: "19.6px",
                                                    fontSize: "14px",
                                                }}
                                            >
                                                {new Date(
                                                    props.selecteditem.issueDate
                                                ).toDateString()}
                                            </span>
                                        </div>
                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                gap: "4px",
                                            }}
                                        >
                                            <span
                                                style={{
                                                    color: "#667085",
                                                    fontWeight: "500",
                                                    fontSize: "14px",
                                                    lineHeight: "19.6px",
                                                }}
                                            >
                                                Due date
                                            </span>
                                            <span
                                                style={{
                                                    color: "#101828",
                                                    fontWeight: "500",
                                                    lineHeight: "19.6px",
                                                    fontSize: "14px",
                                                }}
                                            >
                                                {new Date(
                                                    props.selecteditem.dueDate
                                                ).toDateString()}
                                            </span>
                                        </div>
                                    </div>
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            gap: "8px",
                                            maxWidth: "100%",
                                            width: "100%",
                                        }}
                                    >
                                        <label
                                            htmlFor="Invoice"
                                            style={{
                                                color: "#344054",
                                                fontWeight: "500",
                                                fontSize: "14px",
                                                lineHeight: "19.6px",
                                            }}
                                        >
                                            Payment Link
                                        </label>
                                        <div
                                            style={{
                                                gap: "8px",
                                                display: "flex",
                                                alignItems: "center",
                                                background: "#F4F0FF",
                                                padding: "8px 8px 8px 16px",
                                                borderRadius: "6px",
                                                position: "relative",
                                                minHeight: "44px",
                                            }}
                                        >
                                            <span
                                                style={{
                                                    color: "#101828",
                                                    fontSize: "14px",
                                                    lineHeight: "19.6px",
                                                    fontWeight: "400",
                                                    color: "#6F00FF",
                                                    cursor: "pointer",
                                                }}
                                            >
                                                <a
                                                    style={{
                                                        color: "#101828",
                                                        fontSize: "14px",
                                                        lineHeight: "19.6px",
                                                        fontWeight: "500",
                                                        color: "#6F00FF",
                                                        cursor: "pointer",
                                                        textDecoration: "none",
                                                    }}
                                                    href={props.selecteditem.paymentLink}
                                                    target="_blank"
                                                >
                                                    {props.selecteditem.paymentLink}
                                                </a>
                                            </span>

                                            <span
                                                style={{
                                                    color: "#6F00FF",
                                                    fontSize: "14px",
                                                    borderRadius: "8px",
                                                    padding: "4px 8px",
                                                    background: "#D9CDFF",
                                                    borderRadius: "4px",
                                                    color: "#5E01D6",
                                                    fontWeight: "500",
                                                    position: "absolute",
                                                    right: "8px",
                                                    zIndex: "9999",
                                                    cursor: "pointer",
                                                }}
                                            >
                                                share
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className=""
                                    style={{
                                        borderTop: "1px solid #E7E9FB",
                                        maxWidth: "90%",
                                        width: "100%",
                                        display: "flex",
                                        padding: "8px 0px 8px 0px",
                                        gap: "16px",
                                        alignItems: "center",
                                        borderBottom: "1px solid #E7E9FB",
                                    }}
                                >
                                    <div
                                        style={{
                                            display: "flex",
                                            gap: "4px",
                                            padding: "8px",
                                        }}
                                        onClick={() =>
                                            navigate("/flex/shareInvoice", {
                                                state: {
                                                    ...props.selecteditem,
                                                },
                                            })
                                        }
                                    >
                                        <img src={share} alt="" />
                                        <span
                                            style={{
                                                color: "#6F00FF",
                                                fontWeight: "500",
                                                fontSize: "14px",
                                                lineHeight: "19.6px",
                                                cursor: "pointer",
                                            }}
                                        >
                                            Share
                                        </span>
                                    </div>
                                    {/**
                  <div
                    style={{
                      display: "flex",
                      gap: "4px",
                      padding: "8px",
                    }}
                  >
                    <img src={resend} alt="" />
                    <span
                      style={{
                        color: "#6F00FF",
                        fontWeight: "500",
                        fontSize: "14px",
                        lineHeight: "19.6px",
                        cursor: "pointer",
                      }}
                    >
                      Resend
                    </span>
                  </div>
                    */}
                                    {/**
                  <div
                    style={{
                      display: "flex",
                      gap: "4px",
                      padding: "8px",
                    }}
                    onClick={() => {
                      navigate("/flex/edit", {
                        state: {
                          ...props.selecteditem,
                        },
                      });
                    }}
                  >
                    <img src={edit} alt="" />
                    <span
                      style={{
                        color: "#6F00FF",
                        fontWeight: "500",
                        fontSize: "14px",
                        lineHeight: "19.6px",
                        cursor: "pointer",
                      }}
                    >
                      Edit
                    </span>
                  </div>
                  */}

                                    <div
                                        style={{
                                            display: "flex",
                                            gap: "4px",
                                            padding: "8px",
                                        }}
                                        onClick={() => props.setshow3(true)}
                                    >
                                        <img src={deletes} alt="" />
                                        <span
                                            style={{
                                                color: "#D92D20",
                                                fontWeight: "500",
                                                fontSize: "14px",
                                                lineHeight: "19.6px",
                                                cursor: "pointer",
                                            }}
                                        >
                                            Delete
                                        </span>
                                    </div>
                                    <div></div>
                                </div>
                                {props.selecteditem?.business === selectorbusiness?._id ? (
                                    <>
                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                gap: "8px",
                                                maxWidth: "90%",
                                                width: "100%",
                                            }}
                                        >
                                            <label
                                                htmlFor="Invoice"
                                                style={{
                                                    color: "#344054",
                                                    fontWeight: "500",
                                                    fontSize: "14px",
                                                    lineHeight: "19.6px",
                                                }}
                                            >
                                                Bill To
                                            </label>
                                            <div
                                                style={{
                                                    gap: "8px",
                                                    display: "flex",
                                                    flexDirection: "column",
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        border: "1px solid #E7E9FB",
                                                        borderRadius: "8px",
                                                        padding: "16px",
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent: "space-between",
                                                        cursor: "pointer",
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            gap: "8px",
                                                        }}
                                                    >
                                                        <div className="intial">
                                                            {props?.selecteditem?.firstName?.slice(
                                                                0,
                                                                1
                                                            )}
                                                            {props?.selecteditem?.lastName?.slice(
                                                                0,
                                                                1
                                                            )}
                                                        </div>
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                flexDirection: "column",
                                                                gap: "4px",
                                                            }}
                                                        >
                                                            <span
                                                                style={{
                                                                    color: "#101828",
                                                                    fontWeight: "500",
                                                                    fontSize: "14px",
                                                                    lineHeight: "19.6px",
                                                                }}
                                                            >
                                                                {props?.selecteditem?.firstName}{" "}
                                                                {props?.selecteditem?.lastName}
                                                            </span>
                                                            <span
                                                                style={{
                                                                    color: "#667085",
                                                                    letterSpacing: "2%",
                                                                    fontWeight: "500",
                                                                    lineHeight: "16.2px",
                                                                }}
                                                            >
                                                                {props?.selecteditem?.email}.{" "}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                gap: "8px",
                                                maxWidth: "90%",
                                                width: "100%",
                                            }}
                                        >
                                            <label
                                                htmlFor="Invoice"
                                                style={{
                                                    color: "#344054",
                                                    fontWeight: "500",
                                                    fontSize: "14px",
                                                    lineHeight: "19.6px",
                                                }}
                                            >
                                                Bill From
                                            </label>
                                            <div
                                                style={{
                                                    gap: "8px",
                                                    display: "flex",
                                                    flexDirection: "column",
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        border: "1px solid #E7E9FB",
                                                        borderRadius: "8px",
                                                        padding: "16px",
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent: "space-between",
                                                        cursor: "pointer",
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            gap: "8px",
                                                        }}
                                                    >
                                                        <div className="intial">
                                                            {selector?.firstName?.slice(0, 1)}
                                                            {selector?.lastName?.slice(0, 1)}
                                                        </div>
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                flexDirection: "column",
                                                                gap: "4px",
                                                            }}
                                                        >
                                                            <span
                                                                style={{
                                                                    color: "#101828",
                                                                    fontWeight: "500",
                                                                    fontSize: "14px",
                                                                    lineHeight: "19.6px",
                                                                }}
                                                            >
                                                                {selector?.firstName}{" "}
                                                                {selector?.lastName}
                                                            </span>
                                                            <span
                                                                style={{
                                                                    color: "#667085",
                                                                    letterSpacing: "2%",
                                                                    fontWeight: "500",
                                                                    lineHeight: "16.2px",
                                                                }}
                                                            >
                                                                {selectorbusiness?.name}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )}
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        gap: "8px",
                                        maxWidth: "90%",
                                        width: "100%",
                                    }}
                                >
                                    <label
                                        htmlFor="Invoice"
                                        style={{
                                            color: "#344054",
                                            fontWeight: "500",
                                            fontSize: "14px",
                                            lineHeight: "19.6px",
                                        }}
                                    >
                                        Invoice Items
                                    </label>
                                    <div
                                        style={{
                                            gap: "8px",
                                            display: "flex",
                                            flexDirection: "column",
                                        }}
                                    >
                                        {props?.selecteditem?.invoiceItems?.map((item, index) => (
                                            <div
                                                key={index}
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "space-between",
                                                    border: "1px solid #E7E9FB",
                                                    padding: "8px",
                                                    borderRadius: "8px",
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        gap: "8px",
                                                    }}
                                                >
                                                    <span
                                                        style={{
                                                            color: "#101828",
                                                            fontSize: "1rem",
                                                            lineHeight: "24px",
                                                            letterSpacing: "2%",
                                                            fontWeight: "400",
                                                        }}
                                                    >
                                                        {item.itemName}
                                                    </span>
                                                    <span
                                                        style={{
                                                            color: "#667085",
                                                            fontWeight: "400",
                                                            fontSize: "14px",
                                                            lineHeight: "19.6px",
                                                        }}
                                                    >
                                                        Quantity {item.quantity}, Unit Price{" "}
                                                        <span
                                                            style={{
                                                                color: "#667085",
                                                                fontWeight: "400",
                                                                fontSize: "14px",
                                                                lineHeight: "19.6px",
                                                            }}
                                                        >
                                                            {currencyPairCheck(
                                                                props.selecteditem.currency
                                                            )}
                                                            {AmountCheck(item.pricePerUnit)}
                                                        </span>
                                                    </span>
                                                </div>
                                                <span
                                                    style={{
                                                        color: "#101828",
                                                        fontSize: "1rem",
                                                        lineHeight: "24px",
                                                        letterSpacing: "2%",
                                                    }}
                                                >
                                                    {currencyPairCheck(props.selecteditem.currency)}
                                                    {AmountCheck(
                                                        item.pricePerUnit * item.quantity +
                                                            props.selecteditem.surCharge +
                                                            props.selecteditem.vat
                                                    )}
                                                </span>
                                            </div>
                                        ))}
                                    </div>
                                </div>

                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        gap: "16px",
                                        maxWidth: "90%",
                                        width: "100%",
                                    }}
                                >
                                    <label
                                        htmlFor="Recorded payments"
                                        style={{
                                            color: "#344054",
                                            fontWeight: "500",
                                            fontSize: "14px",
                                            lineHeight: "19.6px",
                                        }}
                                    >
                                        Recorded payments
                                    </label>
                                    <div
                                        style={{
                                            maxHeight: "508px",
                                            minHeight: "150px",
                                            border: "1px solid #E7E9FB",
                                            padding: "16px",
                                            borderRadius: "8px",
                                            lineHeight: "24px",
                                            overflow: "auto",
                                            position: "relative",
                                        }}
                                    >
                                        <button
                                            style={{
                                                position: "absolute",
                                                maxWidth: "208px",
                                                padding: "16px",
                                                borderRadius: "8px",
                                                background: "#EBE4FF",
                                                color: "#6F00FF",
                                                bottom: "0",
                                                right: "0px",
                                                fontWeight: "700",
                                                fontSize: "16px",
                                                letterSpacing: "1%",
                                                lineHeight: "24px",
                                                outline: "none",
                                                border: "0px",
                                                width: "100%",
                                                boxShadow: " 0px 24px 48px -24px #1018282E",
                                            }}
                                            onClick={() => {
                                                setshow(true);
                                            }}
                                        >
                                            Record payment
                                        </button>
                                        {invoices.length === 0 && (
                                            <div className="no-record">No records payemnt</div>
                                        )}

                                        {invoices?.map((res, index) => (
                                            <div
                                                style={{
                                                    borderBottom: "1px solid #E7E9FB",
                                                    paddingBottom: "16px",
                                                    paddingTop: "8px",
                                                }}
                                                key={index}
                                            >
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        gap: "8px",
                                                    }}
                                                >
                                                    <span
                                                        style={{
                                                            color: "#101828",
                                                            fontSize: "14px",
                                                            lineHeight: "19.6px",
                                                            fontWeight: "400",
                                                        }}
                                                    >
                                                        You recorded{" "}
                                                        {currencyPairCheck(res.currency)}
                                                        {AmountCheck(res.amount)} for this invoice.
                                                    </span>
                                                    <span
                                                        style={{
                                                            color: "#667085",
                                                            lineHeight: "16.2px",
                                                            fontWeight: "400",
                                                            fontSize: "12px",
                                                            letterSpacing: "2%",
                                                        }}
                                                    >
                                                        {new Date(res.paymentDate).toDateString()}{" "}
                                                    </span>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    );
}

export default InvoiceDetails;
