import checkbox from "../asset/images/account/checkbox.png";
import Loading from "../component/loading";
import Loader from "../component/loader";
import { useEffect, useRef, useState } from "react";
import personal from "../asset/images/changepin.svg";
import { useSelector } from "react-redux";
import business from "../asset/images/resetpassword.svg";
import beneficiary from "../asset/images/Notification-alert.svg";
import SuccessHodler from "../component/successholder";
//import Loader from "../component/loader";
import FailHandler from "../component/failhandler";
import balance from "../asset/images/requeststatmement.svg";
import ConfrimPin from "./confirmModal";
import axios from "axios";
import chat from "../asset/images/messages-3.svg";
import messages from "../asset/images/message.svg";
import call from "../asset/images/call.svg";
import whatsapp from "../asset/images/whatsapp.svg";
import invite from "../asset/images/invite.svg";
import share from "../asset/images/share.svg";

const Help = () => {
  const [profiletab, setprofiletab] = useState(0);
  const toggledetail = [
    "Help and Support",
    "Term and Privacy",
    "Invite contacts",
  ];
  const [selectedtoggle, setselectedtoggle] = useState(0);
  const [message, setmessage] = useState("");
  const [password, setpassword] = useState("");
  const [currentpassword, setcurrentpassword] = useState("");

  const selector = useSelector((state) => state.users);
  const [pins, setPins] = useState(["", "", "", ""]);
  const [newpin, setNewPins] = useState("");

  //const [loaderupdate, setloaderupdate] = useState(false);
  const [failhandler, setfailhandler] = useState(false);
  //const selectorbusiness = useSelector((state) => state.business);
  const [successholder, setsuccessholder] = useState(false);
  const [show, setShow] = useState(false);
  const [loader, setloader] = useState(false);
  const [isPushednotificationupdate, setisPushednotificationupdate] =
    useState(false);
  const [isEmailupdate, setisEmailupdate] = useState(false);

  const inputs = useRef([]);

  useEffect(() => {
    inputs.current[0]?.focus();
  }, []);
  useEffect(() => {
    if (successholder || failhandler) {
      let timer = setTimeout(() => {
        setfailhandler(false);
        setsuccessholder(false);
      }, 3000);
      return () => {
        clearTimeout(timer);
      };
    }
  });
  const copyContent = async (url) => {
    try {
      await navigator.clipboard.writeText(url);
      setsuccessholder(true);
      setmessage("Copied to clipboard");

      setTimeout(() => {
        setsuccessholder(false);
        setfailhandler(false);
      }, 3000);
    } catch ($e) {
      console.log($e);
      setfailhandler(true);
      setmessage("Failed to copy.");
      setTimeout(() => {
        setfailhandler(false);
        setsuccessholder(false);
      }, 3000);
    }
  };

  const changePassword = async (e) => {
    e.preventDefault();

    setloader(true);
    const data = {
      password: password,
      newPassword: currentpassword,
    };
    axios
      .put("/v1/users/update-password", data)
      .then((res) => {
        setmessage(res.data.message);
        setsuccessholder(true);
        setloader(false);
        setpassword("");
        setcurrentpassword("");
      })
      .catch((e) => {
        setmessage(
          e.response?.data?.message
            ? e.response?.data?.message
            : "An error Ocuur"
        );
        setsuccessholder(false);
        setfailhandler(true);
        setloader(false);
      });
  };
  const changeUpdate = async (e) => {
    // e.preventDefault();

    setloader(true);
    const data = {
      emailUpdate: isEmailupdate.toString(),
      pushNotification: isPushednotificationupdate.toString(),
    };
    axios
      .put("/v1/users/update-notification", data)
      .then((res) => {
        setmessage(res.data.message);
        setsuccessholder(true);
        setloader(false);
        setpassword("");
        setcurrentpassword("");
      })
      .catch((e) => {
        setmessage(
          e.response?.data?.message
            ? e.response?.data?.message
            : "An error Ocuur"
        );
        setsuccessholder(false);
        setfailhandler(true);
        setloader(false);
      });
  };

  const sendRequest = async (pin) => {
    setShow(false);
    setloader(true);
    const [pin1, pin2, pin3, pin4] = pin;
    const numericPin = pin1 + "" + pin2 + "" + pin3 + "" + pin4;
    const pinsdata = {
      pin: numericPin,
    };
    const [newpin1, newpin2, newpin3, newpin4] = pins;
    const allnewpin = newpin1 + "" + newpin2 + "" + newpin3 + "" + newpin4;
    const newpin = {
      pin: allnewpin,
      password: password,
    };

    await axios
      .post("/v1/users/verify-pin", pinsdata)
      .then((res) => {
        axios
          .post("/v1/users/update-pin", newpin)
          .then((res) => {
            setmessage(res.data.message);
            setsuccessholder(true);
            setloader(false);
            setPins(["", "", "", ""]);
            setpassword("");
          })
          .catch((e) => {
            setmessage(
              e.response?.data?.message
                ? e.response?.data?.message
                : "An error Ocuur"
            );
            setsuccessholder(false);
            setfailhandler(true);
            setloader(false);
          });
      })
      .catch((e) => {
        setmessage(
          e.response?.data?.message
            ? e.response?.data?.message
            : "An error Ocuur"
        );
        setsuccessholder(false);
        setfailhandler(true);
        setloader(false);
      });
  };
  return (
    <>
      <ConfrimPin
        pincode={pins}
        show={show}
        setshow={setShow}
        setPins={setNewPins}
        sendRequest={sendRequest}
      />
      <div
        className="card-body p-0"
        style={{
          borderRadius: "24px 24px 0px 0px",

          minHeight: "977px",
          display: "flex",
          overflowY: "auto",
        }}
      >
        <div className="left-col-static-nav">
          <div className="interrior-lg-sm">
            {toggledetail.map((toggle, index) => (
              <div
                className="each-scale-ratio"
                key={index}
                style={{
                  border: selectedtoggle === index && "0.5px solid #6F00FF",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setselectedtoggle(index);

                  setprofiletab(index);
                }}
              >
                <div
                  className="inner-lg-mf"
                  style={{
                    background: selectedtoggle === index && "#F4F0FF",
                  }}
                >
                  <div className="img-lg-text">
                    <img
                      src={
                        index === 0
                          ? personal
                          : index === 1
                          ? business
                          : index === 2
                          ? beneficiary
                          : balance
                      }
                      alt=""
                    />
                    <span>{toggle}</span>
                  </div>
                  {selectedtoggle === index && <img src={checkbox} alt="" />}
                </div>
              </div>
            ))}
          </div>
        </div>
        {profiletab === 0 && (
          <form className="right-side-account-details">
            <div className="miid-side-details">
              <SuccessHodler success={successholder} message={message} />
              <FailHandler success={failhandler} message={message} />

              <div
                className="top-details-lg-mgph"
                style={{
                  gap: "32px",
                }}
              >
                <div className="lower_layer_layout">
                  <h2
                    style={{
                      padding: "0px",
                      margin: "0px",
                    }}
                  >
                    Say hello to us
                  </h2>
                  <span>Reach us via any of these channels below</span>
                </div>
                <div className="lg_support">
                  <div className="lg-support">
                    <div className="support_lg_chat">
                      <div className="img-chat-text">
                        <img src={chat} alt="" />
                        <div className="text-hr">
                          <div>Chat with us</div>
                          <span>Send a message now</span>
                        </div>
                      </div>
                      <span className="material-icons">chevron_right</span>
                    </div>
                    <div className="support_lg_chat">
                      <div className="img-chat-text">
                        <img src={call} alt="" />
                        <div className="text-hr">
                          <div>Call or text us</div>
                          <span>07073183321</span>
                        </div>
                      </div>
                      <span
                        className="material-icons"
                        onClick={() => copyContent("hello@tradevu.co")}
                      >
                        content_copy
                      </span>
                    </div>
                    <div className="support_lg_chat">
                      <div className="img-chat-text">
                        <img src={messages} alt="" />
                        <div className="text-hr">
                          <div>Send a mail</div>
                          <span>hello@tradevu.co</span>
                        </div>
                      </div>
                      <span
                        className="material-icons"
                        onClick={() => copyContent("hello@tradevu.co")}
                      >
                        content_copy
                      </span>
                    </div>
                  </div>
                  <div
                    className="support_lg_chat"
                    style={{
                      padding: "16px",
                    }}
                  >
                    <div className="img-chat-text">
                      <img src={whatsapp} alt="" />
                      <div className="text-hr">
                        <div>Chat with us on WhatsApp</div>
                      </div>
                    </div>
                    <span className="material-icons">chevron_right</span>
                  </div>
                </div>
              </div>
            </div>
          </form>
        )}
        {profiletab === 1 && (
          <form
            className="right-side-account-details"
            onSubmit={changePassword}
          >
            <div className="miid-side-details">
              <SuccessHodler success={successholder} message={message} />
              <FailHandler success={failhandler} message={message} />

              <div
                className="top-details-lg-mgph"
                style={{
                  gap: "32px",
                }}
              >
                <div className="lower_layer_layout">
                  <h2
                    style={{
                      padding: "0px",
                      margin: "0px",
                    }}
                  >
                    Terms and Privacy
                  </h2>
                </div>

                <div className="each_notify_col">
                  <a
                    className="main-each-layout"
                    style={{
                      width: "100%",
                      maxWidth: "100%",
                      textDecoration: "none",
                    }}
                    href="https://www.tradevu.co/term_and_condition.html"
                    target="_blank"
                  >
                    <span>Terms and conditions</span>

                    <span className="material-icons">chevron_right</span>
                  </a>
                  <a
                    className="main-each-layout"
                    style={{
                      width: "100%",
                      maxWidth: "100%",
                      textDecoration: "none",
                    }}
                    target="_blank"
                    href="https://www.tradevu.co/privacy.html"
                  >
                    <span>Privacy</span>
                    <span className="material-icons">chevron_right</span>
                  </a>
                </div>
              </div>
            </div>
          </form>
        )}
        {profiletab === 2 && (
          <form
            className="right-side-account-details"
            onSubmit={changePassword}
          >
            <div className="miid-side-details">
              <SuccessHodler success={successholder} message={message} />
              <FailHandler success={failhandler} message={message} />

              <div
                className="top-details-lg-mgph"
                style={{
                  gap: "32px",
                }}
              >
                <div className="lower_layer_layout">
                  <h2
                    style={{
                      padding: "0px",
                      margin: "0px",
                    }}
                  >
                    Invite friends
                  </h2>
                  <div
                    className="invite-lg-col"
                    style={{
                      marginTop: "100px",
                    }}
                  >
                    <div className="img_invite_lg_col">
                      <img
                        src={invite}
                        alt=""
                        style={{
                          width: "160px",
                          margin: "0px auto",
                        }}
                      />
                      <div className="lg-comon-fl">
                        <h3
                          style={{
                            padding: "0px",
                            margin: "0px",
                          }}
                        >
                          Get $50 with every new contact
                        </h3>
                        <span>
                          Share your code or link with a friend to earn extra.
                        </span>
                      </div>
                    </div>
                    <div className="btn-lg-lg-col">
                      <div className="btn-lg-gm">
                        <div>TR0GU7UE</div>
                        <span>Referral code</span>
                      </div>
                    </div>
                    <button
                      className="confirmButtonPin"
                      style={{
                        margin: "0px auto",
                        display: "flex",
                        alignItems: "center",
                        gap: "8px",
                        width: "100%",
                        maxWidth: "327px",
                      }}
                    >
                      <img src={share} alt="" />
                      Share your invite link
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        )}
        {profiletab === 3 && (
          <form
            className="right-side-account-details"
            onSubmit={changePassword}
          >
            <div className="miid-side-details">
              <SuccessHodler success={successholder} message={message} />
              <FailHandler success={failhandler} message={message} />

              <div
                className="top-details-lg-mgph"
                style={{
                  gap: "32px",
                }}
              >
                <div className="lower_layer_layout">
                  <h2
                    style={{
                      padding: "0px",
                      margin: "0px",
                    }}
                  >
                    Request statement
                  </h2>
                  <span>Request a bank statement for any of your wallets</span>
                </div>

                <div className="form_statement_lg">
                  <div className="form_lg_statementlg">
                    <div className="each-lg-form-doubled">
                      <div
                        className="each-profile-input"
                        style={{
                          width: "100%",
                        }}
                      >
                        <label htmlFor="">Issued</label>
                        <input
                          type="date"
                          value={password}
                          onChange={(e) => setpassword(e.target.value)}
                          style={{
                            width: "100%",
                          }}
                          placeholder="Enter password"
                        />
                      </div>
                      <div
                        className="each-profile-input"
                        style={{
                          width: "100%",
                        }}
                      >
                        <label htmlFor="">Due</label>
                        <input
                          type="Date"
                          value={password}
                          onChange={(e) => setpassword(e.target.value)}
                          style={{
                            width: "100%",
                          }}
                          placeholder="Enter password"
                        />
                      </div>
                    </div>
                    <div
                      className="each-profile-input"
                      style={{
                        width: "100%",
                      }}
                    >
                      <label htmlFor="">Wallet</label>
                      <select type="text" placeholder="Choose wallet"></select>
                    </div>
                    <div
                      className="each-profile-input"
                      style={{
                        width: "100%",
                      }}
                    >
                      <label htmlFor="">Email</label>
                      <input
                        disabled
                        type="email"
                        value={selector.email}
                        placeholder="Enter email"
                      />
                    </div>
                  </div>
                  <button className="confirmButtonPin">
                    {loader ? <Loader /> : "Send Request"}
                  </button>
                </div>
              </div>
            </div>
          </form>
        )}
      </div>
    </>
  );
};

export default Help;
