import Modal from "react-bootstrap/Modal";
import person from "../asset/images/usertradevu.png";

import { useEffect, useState } from "react";
import BuyerDetail from "../component/buyerdetail";
import axios from "axios";
import Loader from "../component/loader";
import edit from "../asset/images/edit-2.svg";
import "../style/main.css";
import ActivePartner from "../component/ActivePartner";
function AddBuyerModal(props) {
    const [modalShow, setModalShow] = useState(false);
    const [buyerdetailsinfo, setbuyerdetailsinfo] = useState([]);
    const [firstName, setfirstName] = useState("");
    const [lasttName, setlastName] = useState("");
    const [businessName, setBusinesssName] = useState("");
    const [email, setEmail] = useState("");
    const [phoneNumber, setphoneNumber] = useState("");
    const [means, setmeans] = useState("buyer");
    const [loader, setloader] = useState(false);
    const types = ["buyer", "supplier", "vendor"];
    useEffect(() => {
        if (props.selecteditems.length !== 0) {
            setfirstName(props?.selecteditems[0]?.user.firstName);
            setlastName(props?.selecteditems[0]?.user.lastName);
            setEmail(props?.selecteditems[0]?.user.email);
            setphoneNumber(props?.selecteditems[0]?.user.phoneNumber);
            setBusinesssName(props.businessname);
        }
    });
    const submitBuyerDetails = async (e) => {
        e.preventDefault();
        setloader(true);
        const data = {
            firstName:
                props.selecteditems.length === 0 ? firstName : props.selecteditems[0].firstName,
            lastName:
                props.selecteditems.length === 0 ? lasttName : props.selecteditems[0].lastName,
            email: props.selecteditems.length === 0 ? email : props.selecteditems[0].email,
            phoneNumber:
                props.selecteditems.length === 0 ? phoneNumber : props.selecteditems[0].firstName,
            businessName: props.selecteditems.length === 0 ? businessName : props.businessname,
            flexUserType: props.selecteditem.length === 0 ? means : props.means,

            // receiverBusiness : props.selecteditem.length === 0 ?  " " : props.selecteditems[0].business._id,
        };
        const datas = {
            firstName:
                props.selecteditems.length === 0
                    ? firstName
                    : props.selecteditems[0].user?.firstName,
            lastName:
                props.selecteditems.length === 0
                    ? lasttName
                    : props.selecteditems[0].user?.lastName,
            email: props.selecteditems.length === 0 ? email : props.selecteditems[0].user?.email,
            phoneNumber:
                props.selecteditems.length === 0
                    ? phoneNumber
                    : props.selecteditems[0].user?.phoneNumber,
            businessName: props.selecteditems.length === 0 ? businessName : props.businessname,
            flexUserType: means,
            receiverBusiness: props.selecteditems.length !== 0 ? props?.selecteditems[0]._id : "",
        };

        await axios
            .post("/v1/flex/user", props.selecteditems.length === 0 ? data : datas)
            .then((res) => {
                props.selecteditems.length === 0
                    ? props.setsuppliers([...props.suppliers, res.data.data])
                    : props.setbuyers([...props.buyers, res.data.data]);

                setfirstName("");
                setEmail("");
                setBusinesssName("");
                setphoneNumber("");
                setlastName("");
            })
            .catch((e) => {
                console.log(e);
            });
        setloader(false);
        props.setsuccess(true);
        props.setshow(false);
    };

    return (
        <>
            <ActivePartner
                show={modalShow}
                onHide={() => setModalShow(false)}
                buyerdetail={buyerdetailsinfo}
                setbuyerdetailsinfo={setbuyerdetailsinfo}
                selecteditem={props.selecteditem}
                setselecteditem={props.setselecteditem}
            />

            <Modal
                show={props.show}
                backdrop="false"
                keyboard={false}
                onHide={() => props.setshow(false)}
            >
                <Modal.Body
                    className="p-0"
                    style={{
                        height: "100vh",
                        maxHeight: "760px",
                        minHeight: "780px",
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "24px",
                        }}
                    >
                        <div
                            className=""
                            style={{
                                borderBottom: "1px solid #E7E9FB",
                                padding: "8px 24px 8px 24px",
                            }}
                        >
                            <span
                                className="material-icons"
                                style={{
                                    color: "#667085",
                                    cursor: "pointer",
                                }}
                                onClick={() => {
                                    props.setshow(false);
                                }}
                            >
                                close
                            </span>
                        </div>
                        <div
                            style={{
                                padding: "8px 24px 8px 24px",
                            }}
                        >
                            <form
                                onSubmit={submitBuyerDetails}
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: "16px",
                                }}
                            >
                                <div className="top-buyer-add">
                                    <h3
                                        className="my-titile-add-new-buyer"
                                        style={{
                                            fontWeight: "500",
                                        }}
                                    >
                                        {" "}
                                        Add new partner
                                    </h3>
                                    <span
                                        style={{
                                            fontWeight: "400",
                                        }}
                                    >
                                        Start receiving orders from patner directly on Tradevu.
                                    </span>
                                </div>
                                <div className="form-select-group">
                                    <input
                                        type="text"
                                        className="each-form-group"
                                        style={{
                                            textTransform: "none",
                                        }}
                                        placeholder="First name"
                                        value={firstName}
                                        required={props.selecteditems.length === 0}
                                        onChange={(e) => {
                                            setfirstName(e.target.value);
                                        }}
                                    />
                                    <input
                                        type="text"
                                        className="each-form-group"
                                        placeholder="Last name"
                                        value={lasttName}
                                        style={{
                                            textTransform: "none",
                                        }}
                                        required={props.selecteditems.length === 0}
                                        onChange={(e) => {
                                            setlastName(e.target.value);
                                        }}
                                    />

                                    <input
                                        type="text"
                                        className="each-form-group"
                                        placeholder="Business name"
                                        value={businessName}
                                        required={props.selecteditems.length === 0}
                                        onChange={(e) => {
                                            setBusinesssName(e.target.value);
                                        }}
                                        style={{
                                            textTransform: "none",
                                        }}
                                    />
                                    <input
                                        type="email"
                                        className="each-form-group"
                                        placeholder="Email address"
                                        value={email}
                                        required={props.selecteditems.length === 0}
                                        onChange={(e) => {
                                            setEmail(e.target.value);
                                        }}
                                        style={{
                                            textTransform: "none",
                                        }}
                                    />
                                    <input
                                        type="tel"
                                        className="each-form-group"
                                        placeholder="Phone Number"
                                        value={phoneNumber}
                                        required={props.selecteditems.length === 0}
                                        onChange={(e) => {
                                            setphoneNumber(e.target.value);
                                        }}
                                        style={{
                                            textTransform: "none",
                                        }}
                                    />
                                    <select
                                        className="each-form-group"
                                        placeholder="Select type"
                                        value={means}
                                        required={props.selecteditems.length === 0}
                                        onChange={(e) => {
                                            setmeans(e.target.value);
                                        }}
                                        style={{
                                            textTransform: "none",
                                        }}
                                    >
                                        {types.map((typ, index) => (
                                            <option value={typ} key={index}>
                                                {typ}
                                            </option>
                                        ))}
                                    </select>
                                    <div className="search-tradevu-user">
                                        <label htmlFor="">or search from Tradevu</label>
                                        {props.selecteditems.length === 0 && (
                                            <div
                                                className="bord-trade-log"
                                                style={{
                                                    minHeight: "56px",
                                                }}
                                                onClick={() => {
                                                    //alert()
                                                    props.setupload(true);
                                                }}
                                            >
                                                <div className="lg-mod-gl">
                                                    <div className="lg-mf-lg">
                                                        <img
                                                            src={person}
                                                            alt=""
                                                            style={{
                                                                width: "32px",
                                                                height: "32px",
                                                            }}
                                                        />
                                                        <span>Tradevu User</span>
                                                    </div>

                                                    <span className="material-icons">
                                                        chevron_right
                                                    </span>
                                                </div>
                                            </div>
                                        )}
                                        {props.selecteditems.length > 0 && (
                                            <div
                                                className="main-each-lg"
                                                style={{
                                                    background: "#fff",
                                                    alignItems: "center",
                                                }}
                                                onClick={() => {
                                                    props.setupload(true);
                                                }}
                                            >
                                                <div className="lg-sie-avater-lg">
                                                    <div className="intial">
                                                        {props.selecteditems[0].user?.firstName.slice(
                                                            0,
                                                            1
                                                        )}
                                                        {props.selecteditems[0].user?.lastName.slice(
                                                            0,
                                                            1
                                                        )}
                                                    </div>
                                                    <div
                                                        className="top-tier-lg"
                                                        style={{
                                                            gap: "0px",
                                                        }}
                                                    >
                                                        <h4>{props.businessname}</h4>
                                                        <span>
                                                            {" "}
                                                            {
                                                                props.selecteditems[0].user
                                                                    ?.firstName
                                                            }{" "}
                                                            {props.selecteditems[0].user?.lastName}
                                                        </span>
                                                    </div>
                                                </div>
                                                <img src={edit} alt="" />
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <button
                                    type="submit"
                                    className="btn-send-request"
                                    disabled={loader}
                                    style={{
                                        width: "100%",
                                        maxWidth: "100%",
                                        justifyContent: "center",
                                        display: "flex",
                                        alignItems: "center",
                                    }}
                                >
                                    {loader ? <Loader /> : "Save buyer"}
                                </button>
                            </form>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default AddBuyerModal;
