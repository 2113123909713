import Modal from "react-bootstrap/Modal";
import person from "../asset/images/person.svg";
import searchs from "../asset/images/search-normal.svg";
import edit from "../asset/images/edit-2.svg";
import { useState } from "react";
import BuyerDetail from "./buyerdetail";
import axios from "axios";
import { useEffect } from "react";
import loaders from "../asset/images/l.gif";
import { useSelector } from "react-redux";

function FlexBuyer(props) {
  const [contacts, setContact] = useState([]);
  const [search, setSearch] = useState("");
  const [modalShow, setModalShow] = useState(false);
  const [buyerdetailsinfo, setbuyerdetailsinfo] = useState([]);
  const [loadersetting, setloadersetting] = useState(true);
  const [message, setmessage] = useState("");
  const selectorbusinesss = useSelector((state) => state.business);

  const businessIdHeaderConfig = {
    headers: { "x-business-id": selectorbusinesss?._id },
};

  useEffect(() => {
    const fetchUser = async () => {
      await axios
        .get("/v1/flex/users",businessIdHeaderConfig)
        .then((res) => {
          // console.log(res);
          setContact(res.data.data);
          setloadersetting(false);
        })
        .catch((e) => {
          console.log(e);
          setloadersetting(false);
          setmessage("Please try again.");
        });
    };
    selectorbusinesss?._id && fetchUser();
  }, [selectorbusinesss]);

  const filtercontant = contacts.filter((user) =>
    user.businessName?.toLowerCase()?.includes(search?.toLowerCase())
  );

  filtercontant.sort((a, b) =>
    a.lastName.localeCompare(b.lastName, "es", { sensitivity: "base" })
  );
  let data = filtercontant.reduce((r, e) => {
    let alphabet = e.firstName[0];

    if (!r[alphabet])
      r[alphabet] = {
        alphabet,
        record: [],
      };
    r[alphabet].record.push(e);
    return r;
  }, {});

  let result = Object.values(data);
  return (
    <>
      <BuyerDetail
        show={modalShow}
        setModalShow={setModalShow}
        buyerdetail={buyerdetailsinfo}
        setbuyerdetailsinfo={setbuyerdetailsinfo}
        selecteditem={props.selecteditem}
        setNewHandle={props.handle}
        setselecteditem={props.setselecteditem}
      />

      <Modal
        show={props.show}
        backdrop="false"
        keyboard={false}
        onHide={() => props.handle(false)}
      >
        <Modal.Body className="p-0">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "24px",
            }}
          >
            <div
              className=""
              style={{
                borderBottom: "1px solid #E7E9FB",
                padding: "8px 24px 8px 24px",
              }}
            >
              <span
                className="material-icons"
                style={{
                  color: "#667085",
                  cursor: "pointer",
                }}
                onClick={() => {
                  props.handle(false);
                }}
              >
                close
              </span>
            </div>
            <div
              style={{
                padding: "8px 24px 8px 24px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "16px",
                }}
              >
                <h3
                  style={{
                    color: "#101828",
                    fontWeight: "600",
                    fontSize: "20px",
                    lineHeight: "24px",
                  }}
                >
                  Choose Partner
                </h3>
                {buyerdetailsinfo.length > 0 &&
                  buyerdetailsinfo.map((info, index) => (
                    <div
                      style={{
                        background: "#fff",
                        borderRadius: "8px",
                        display: "flex",
                        gap: "16px",
                        justifyContent: "space-between",
                        padding: "8px",
                        alignItems: "center",
                      }}
                      key={index}
                      onClick={() => setModalShow(true)}
                    >
                      <div
                        style={{
                          display: "flex",
                          gap: "8px",
                          alignItems: "center",
                        }}
                      >
                        <div
                          style={{
                            width: "40px",
                            height: "40px",
                            borderRadius: "250px",
                            alignItems: "center",
                            justifyContent: "ceneter",
                            background: "#F4F0FF",
                            textAlign: "center",
                            display: "flex",
                          }}
                        >
                          <div
                            style={{
                              color: "#7F56D9",
                              fontWeight: "600",
                              fontSize: "14px",
                              lineHeight: "20px",
                              textAlign: "center",
                              textTransform: "uppercase",
                              width: "100%",
                            }}
                          >
                            {" "}
                            {info.firstName.charAt(0)}
                            {info.lastName.charAt(0)}{" "}
                          </div>
                        </div>
                        <div
                          style={{
                            color: "#101828",
                            fontSize: "16px",
                            fontWeight: "600",
                            lineHeight: "24px",
                            letterSpacing: "2%",
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <span
                            style={{
                              color: "#101828",
                              lineHeight: "24px",
                              letterSpacing: "2%",
                              fontSize: "16px",
                              fontWeight: "600",
                            }}
                          >
                            {info.firstName} {info.lastName}
                          </span>
                          <span
                            style={{
                              color: "#667085",
                              lineHeight: "16.2px",
                              letterSpacing: "2%",
                              fontSize: "12px",
                              fontWeight: "500",
                            }}
                          >
                            {" "}
                            {info.email}
                          </span>
                        </div>
                      </div>
                      <img src={edit} alt="" />
                    </div>
                  ))}

                {buyerdetailsinfo.length === 0 && (
                  <div
                    style={{
                      background: "#fff",
                      borderRadius: "8px",
                      display: "flex",
                      gap: "16px",
                      justifyContent: "space-between",
                      padding: "8px",
                      alignItems: "center",
                      height: "56px",
                      cursor: "pointer",
                    }}
                    variant="primary"
                    onClick={() => {
                      setModalShow(true);
                      props.handle(false);
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        gap: "8px",
                        alignItems: "center",
                      }}
                    >
                      <img src={person} alt="" />
                      <div
                        style={{
                          color: "#101828",
                          fontSize: "16px",
                          fontWeight: "500",
                          lineHeight: "24px",
                          letterSpacing: "2%",
                        }}
                      >
                        Someone new
                      </div>
                    </div>
                    <span className="material-icons">chevron_right</span>
                  </div>
                )}

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "8px",
                  }}
                >
                  <label
                    htmlFor=""
                    style={{
                      color: "#344054",
                      fontWeight: "400",
                      lineHeight: "19.6px",
                      fontSize: "14px",
                    }}
                  >
                    Your Tradevu Network
                  </label>
                  <div
                    className="w-100 d-flex"
                    style={{
                      gap: "10px",
                      borderRadius: "8px",
                      background: "#ECEFF3",
                      lineHeight: "19.6px",
                      padding: "5px",
                      minHeight: "46px",
                    }}
                  >
                    <img src={searchs} alt="" className="search_icon" />
                    <input
                      type="search"
                      name=""
                      id=""
                      className="border-0"
                      onChange={(e) => {
                        setSearch(e.target.value);
                      }}
                      style={{
                        padding: "0px 8px 0px 8px !important",
                        width: "100%",
                        outline: "none",
                        fontSize: "14px",
                        fontWeight: "500",
                        background: "inherit",
                        color: "#667085",
                        lineHeight: "19.6px",
                        height: "45px",
                      }}
                      placeholder="Name or email"
                    />
                  </div>
                </div>
                <div
                  style={{
                    overflow: "auto",
                    height: "100%",
                    maxHeight: "1000px",
                    display: "flex",
                    flexDirection: "column",
                    gap: "16px",
                  }}
                >
                  {loadersetting && (
                    <div className="emtyp-lg">
                      <img src={loaders} alt="" />
                      {message !== "" && (
                        <div className="text-lg">{message}</div>
                      )}
                      <div></div>
                    </div>
                  )}
                  {result.map((contact, index) => (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                      key={index}
                    >
                      <span
                        style={{
                          color: "#667085",
                          fontSize: "12px",
                          lineHeight: "16.2px",
                          fontWeight: "600",
                          marginBottom: "10px",
                        }}
                      >
                        {" "}
                        {contact.alphabet}
                      </span>
                      {contact.record.map((records, index) => (
                        <div
                          className="invoicmodal"
                          style={{
                            background: "#fff",
                            display: "flex",
                            gap: "16px",
                            justifyContent: "space-between",
                            padding: "16px",
                            alignItems: "center",
                            cursor: "pointer",
                            fontWeight: "500",
                          }}
                          key={index}
                          onClick={() => {
                            if (records.user) {
                              props.handle(false);
                              props.setselecteditem([records]);
                              props.setbusinessname(
                                records?.receiverBusiness
                                  ? records?.receiverBusiness?._id
                                  : records?._id
                              );
                            }
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              gap: "8px",
                              alignItems: "center",
                            }}
                          >
                            <div className="intial">
                              {records?.receiverBusiness
                                ? records?.receiverBusiness?.name.slice(0, 2)
                                : records?.businessName.slice(0, 2)}
                            </div>
                            <div
                              style={{
                                color: "#101828",
                                fontSize: "16px",
                                fontWeight: "500",
                                lineHeight: "24px",
                                letterSpacing: "2%",
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <span
                                style={{
                                  color: "#101828",
                                  lineHeight: "24px",
                                  letterSpacing: "2%",
                                  fontSize: "16px",
                                  fontWeight: "600",
                                }}
                              >
                                {" "}
                                {records?.businessName}
                              </span>
                              <span
                                style={{
                                  color: "#667085",
                                  lineHeight: "16.2px",
                                  letterSpacing: "2%",
                                  fontSize: "12px",
                                  fontWeight: "500",
                                }}
                              >
                                {records.firstName} {records.lastName}
                              </span>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  ))}{" "}
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default FlexBuyer;
