import { Helmet } from "react-helmet";
// import DashHeader from "../component/dashheader";
// import homeTradeofCreditType from '../asset/im
import SerHeader from "../component/serviceheader";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import BankModal from "../component/bankmodal";

import ArrowBack from "../component/arrowback";
import Loader from "../component/loader";
import "../style/main.css";
import edit from "../asset/images/edit-2.svg";
//import searchicon from '../asset/images/search-normal.svg'
//import BankModal from "../component/bankmodal";
import bankicon from "../asset/images/bankicon.svg";
//import axios from "axios";
import "../style/main.css";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { ThreeDots } from "react-loader-spinner";
import TransferRecipientType from "../component/TransferRecipientType";
const Bankdetails = () => {
  const navigate = useNavigate();

  const { state } = useLocation();
  const [accountNumber, setaccountNumber] = useState(state.accountNumber);
  const [accountName, setaccountName] = useState("");
  const [isdisable, setisdisable] = useState(true);
  const [checked, setchecked] = useState(false);
  const [bank, setbank] = useState([]);
  const [loader, setloader] = useState(false);
  const [selectedbank, setselectedbank] = useState([]);
  const [showbank, setshowbank] = useState(false);
  const [sessid, setssid] = useState("");
  const [narration, setnarrattion] = useState("");
  const [showaccountNumber, setshowaccountNumber] = useState(false);
  const [loadingbank, setloadingbank] = useState(false);
  const [message, setmessage] = useState("");
  const [accountNames, setaccountNames] = useState("");
  const [swiftCode, setswiftCode] = useState("");
  const [sortCode, setSortCode] = useState("");
  const [routingNumber, setroutingNumber] = useState("");
  const [RecipientType, setRecipientType] = useState("Individual");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [businessName, setBusinessName] = useState("");
  const [typeshow, settypeshow] = useState(false);
  const [iban, setiban] = useState("");
  const tenure = ["Individual", "Business"];

  const getRecipientName = async () => {
    setmessage("");

    if (accountNumber.length === 10 && state.currency === "NGN") {
      setloadingbank(true);

      const data = {
        accountNumber: accountNumber,
        bankCode: selectedbank.bankCode,
      };
      await axios
        .post("/v1/wallets/verifyBankDetail", data)
        .then((res) => {
          if (res?.data?.data?.ResponseCode === "-2") {
            setmessage(
              "Invalid account. Please check the recipient account information and try again."
            );
            setshowaccountNumber(false);
          } else {
            setshowaccountNumber(true);
          }
          setaccountName(res.data.data.accountName);
          setssid(res.data.data.sessionId);
          setloadingbank(false);
        })
        .catch((e) => {
          setshowaccountNumber(false);
          setloadingbank(false);
          setmessage(
            e.response?.data ? e.response?.data?.message : "An error occurred"
          );
        });
    } else {
      setshowaccountNumber(false);
      setloadingbank(false);
    }
  };
  
  useEffect(() => {
    const getBusinessId = async () => {
      if (state.currency === "NGN") {
        await axios
          .get("/v1/wallets/get-banks")
          .then((res) => {
            setbank(res.data.data);
          })
          .catch((e) => {
            console.log(e);
          });
      } else {
        await axios
          .get(`/v1/wallets/get_all_banks/all?currency=${state.currency}`)
          .then((res) => {
            setbank(res.data.data);
          })
          .catch((e) => {
            console.log(e);
          });
      }
    };

    getBusinessId();
  }, []);
  useEffect(() => {
    if (accountName !== "" && accountNumber !== "") {
      setisdisable(false);
    } else {
      setisdisable(true);
    }
  }, [accountName, accountNumber]);
  const getValueUpdated = async (e) => {
    setloader(true);
    setisdisable(true);
    e.preventDefault();

    if (checked === true) {
      const data = {
        //  accountName: accountName,
        accountNumber: accountNumber,
        name: accountName,
        currency: state.currency,

        name: selectedbank.length === 0 ? state.name : selectedbank.name,
        // sessid: sessid,
        //  bankCode: state.bankCode,
      };
      await axios
        .post("/v1/beneficiary", data)
        .then(() => {
          console.log(1, state);
          navigate("/transfer/details", {
            state: {
              accountNumber: accountNumber,
              accountName: accountName,
              currency: state.currency,
              bankCode: state.bankCode,
              amount: state.amount,
              sesid: "Transfer",
              narration: narration,
              name: selectedbank.length === 0 ? state.name : selectedbank.name,
            },
          });
        })
        .catch((e) => {
          console.log(e);
          setloader(false);
          setisdisable(false);
        });
    } else if (state.currency === "NGN") {
      console.log(2, state);
      navigate("/transfer/details", {
        state: {
          accountNumber: accountNumber,
          accountName: accountName,
          currency: state.currency,
          bankCode: state.selectedbank.bankCode,
          amount: state.amount,
          sesid: "Transfer",
          narration: narration,

          name: state.selectedbank.bankName,
        },
      });
    } else {
      console.log(3, state);
      navigate("/transfer/details", {
        state: {
          AccountNumber: accountNumber,
          AccountName:
            firstName.length > 0 ? firstName + " " + lastName : businessName,
          currency: state.currency,
          SortCode: sortCode,
          RoutingNumber: routingNumber,
          SWIFT: swiftCode,
          recipientType: RecipientType,

          //  bankCode: state.bankCode,
          amount: state.amount,
          IBAN: iban,
          // sesid: sessid,
          naration: narration,

          BankName: selectedbank.length === 0 ? state.name : selectedbank.name,
        },
      });
    }
  };

  useEffect(() => {
    setselectedbank(state.selectedbank);
  }, [state.selectedbank]);

  return (
    <div className="h-100 w-100 ">
      <div
        className=""
        style={{
          maxWidth: "2000px",
          width: "100%",
          margin: "0px auto",
        }}
      >
        <Helmet>
          <title>Bank transfer</title>
        </Helmet>

        <SerHeader header={"Local transfer"} width={60} />
        <BankModal
          show={showbank}
          setshow={setshowbank}
          currency={state.currency}
          banks={bank}
          bank={true}
          selectedbank={selectedbank}
          setselectedbank={setselectedbank}
        />
        <TransferRecipientType
          show={typeshow}
          setshow={settypeshow}
          tenure={tenure}
          settenureday={setRecipientType}
        />
      </div>
      <div style={{ height: "100%" }}>
        <div
          className="conatiner "
          style={{
            maxWidth: "2000px",
            width: "100%",

            margin: "0px auto",
            paddingLeft: "30px",
            paddingRight: "30px",
            paddingTop: "10px",
            paddingBottom: "10px",
          }}
        >
          <div
            style={{
              height: "100%",
              width: "100%",
              display: "flex",

              justifyContent: "center",
            }}
          >
            <form onSubmit={getValueUpdated} className="form-general-layout">
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "16px",
                }}
              >
                <ArrowBack />
                <div className="">
                  <div
                    style={{
                      color: "#101828",
                      fontSize: "24px",
                      lineHeight: "32.4px",
                      fontWeight: "600",
                    }}
                  >
                    Send funds
                  </div>
                  <p
                    style={{
                      color: "#667085",
                      lineHeight: "24px",
                      letterSpacing: "2%",
                      fontSize: "16px",
                      fontWeight: "400",
                    }}
                  >
                    Enter recipient details
                  </p>
                </div>
              </div>
              <div className="">
                <div className="">
                  <div className="">
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "24px",
                      }}
                    >
                      <div
                        className="white-bh-lg"
                        style={{
                          minHeight: "56px",
                        }}
                        onClick={() => setshowbank(true)}
                      >
                        <div className="transfer-bank-logo">
                          <img
                            src={bankicon}
                            alt=""
                            style={{ height: "20px", width: "20px" }}
                          />
                          <span
                            className="choose_bank"
                            style={{
                              fontWeight: "500",
                            }}
                          >
                            {" "}
                            {selectedbank.length === 0 &&
                            state.currency === "NGN"
                              ? state.name
                              : state.currency !== "NGN" &&
                                selectedbank.length === 0
                              ? state.name
                              : state.currency !== "NGN" &&
                                selectedbank.length > 0
                              ? selectedbank.name
                              : selectedbank.bankName}
                          </span>
                        </div>
                        <img src={edit} alt="" />
                      </div>
                      {state.currency !== "GBP" && (
                        <input
                          type="text"
                          className="input-settings"
                          placeholder="Account Number"
                          value={accountNumber}
                          onChange={(e) => {
                            setaccountNumber(e.target.value);
                          }}
                          onKeyUp={getRecipientName}
                        />
                      )}
                      {state.currency !== "NGN" && (
                        <>
                          {/**
                          <input
                            type="text"
                            className="input-settings"
                            placeholder="Account Name"
                            value={accountNames}
                            onChange={(e) => {
                              setaccountNames(e.target.value);
                            }}
                          />
                          */}
                          {state.currency == "GBP" && (
                            <input
                              type="text"
                              className="input-settings"
                              placeholder="Sort Code"
                              value={sortCode}
                              onChange={(e) => {
                                setSortCode(e.target.value);
                              }}
                            />
                          )}
                          {state.currency === "USD" && (
                            <input
                              type="text"
                              className="input-settings"
                              placeholder="Routing Number"
                              value={routingNumber}
                              onChange={(e) => {
                                setroutingNumber(e.target.value);
                              }}
                            />
                          )}
                          {state.currency === "EUR" && (
                            <input
                              type="text"
                              className="input-settings"
                              placeholder="IBAN"
                              value={iban}
                              onChange={(e) => {
                                setiban(e.target.value);
                              }}
                            />
                          )}
                          {state.currency === "GBP" && (
                            <input
                              type="text"
                              className="input-settings"
                              placeholder="SwiftCode"
                              value={swiftCode}
                              onChange={(e) => {
                                setswiftCode(e.target.value);
                              }}
                            />
                          )}
                          <textarea
                            type="text"
                            className="input-settings"
                            placeholder="Narration"
                            value={narration}
                            onChange={(e) => {
                              setnarrattion(e.target.value);
                            }}
                          ></textarea>

                          <div className="receipient_bank_details">
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "8px",
                              }}
                            >
                              <span className="recipientDetails">
                                Recipeint
                              </span>
                              <div
                                className="input-settings"
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  color: "#667085",
                                  position: "relative",
                                }}
                                onClick={() => {
                                  settypeshow(true);
                                }}
                              >
                                {RecipientType}
                                <span
                                  className="material-icons"
                                  style={{
                                    position: "absolute",
                                    right: "10px",
                                  }}
                                >
                                  expand_more
                                </span>
                              </div>
                              {RecipientType === "Individual" && (
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: "18px",
                                  }}
                                >
                                  <input
                                    type="text"
                                    className="input-settings"
                                    placeholder="First Name"
                                    style={{
                                      marginTop: "18px",
                                    }}
                                    value={firstName}
                                    onChange={(e) => {
                                      setFirstName(e.target.value);
                                    }}
                                  />
                                  <input
                                    type="text"
                                    className="input-settings"
                                    placeholder="Last Name"
                                    value={lastName}
                                    onChange={(e) => {
                                      setLastName(e.target.value);
                                    }}
                                  />
                                </div>
                              )}
                              {RecipientType === "Business" && (
                                <>
                                  <input
                                    type="text"
                                    className="input-settings"
                                    placeholder="Business Name"
                                    style={{
                                      marginTop: "18px",
                                    }}
                                    value={businessName}
                                    onChange={(e) => {
                                      setBusinessName(e.target.value);
                                    }}
                                  />
                                </>
                              )}
                            </div>
                          </div>
                        </>
                      )}
                      {loadingbank && (
                        <ThreeDots
                          height="30"
                          width="30"
                          radius="9"
                          color="#6F00FF"
                          ariaLabel="three-dots-loading"
                          wrapperStyle={{
                            alignItems: "center",
                            justifyContent: "center",
                            display: "flex",
                            marginTop: "-20px",
                          }}
                          wrapperClassName=""
                          visible={true}
                        />
                      )}
                      {message !== "" && (
                        <span
                          style={{
                            fontSize: "14px",
                            letterSpacing: "2%",
                            lineHeight: "19.6px",
                            color: "crimson",
                            fontWeight: "500",
                            textAlign: "center",
                          }}
                        >
                          {message}
                        </span>
                      )}

                      <input
                        type="text"
                        className="input-settings"
                        placeholder="Account Name"
                        readOnly
                        value={accountName}
                      />
                      <input
                        type="text"
                        className="input-settings"
                        placeholder="Narration"
                        onChange={(e) => setnarrattion(e.target.value)}
                      />

                      <div className="toggle-slider-switch">
                        <small className="save">Save as beneficiary </small>
                        <label className="switch">
                          <input
                            type="checkbox"
                            checked={checked}
                            onChange={() => {
                              setchecked(!checked);
                            }}
                          />
                          <span className="slider round"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <button
                  disabled={isdisable}
                  type="submit"
                  style={{
                    maxWidth: "278px",
                    width: "100%",
                    background: isdisable ? "#EBE4FF" : "#6F00FF",
                    color: isdisable ? "#344054" : "#fff",
                    padding: "16px 24px 16px 24px",
                    letterSpacing: "2%",
                    fontSize: "16px",
                    fontWeight: "500",
                    lineHeight: "24px",
                    borderRadius: "8px",
                    outline: "none",
                    border: "none",
                    minHeight: "46px",
                    justifyContent: "center",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {loader ? <Loader /> : "Continue"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Bankdetails;
