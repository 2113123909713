import "./App.css";
import "./style/rightside.css";
import "./style/index.css";
import "./style/login.css";
import "./style/main.css";
import "./style/slider.css";
import { Route, Navigate, Outlet, Routes } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import Register from "./Auth/registerss";
import Registers from "./Auth/mainregister";
import Verify from "./Auth/verifyemail";
import VerifyNumber from "./Auth/number";
import Businessform from "./businessform/document";
import ProofofAddress from "./businessform/proofofAddress";
import BusinessType from "./businessform/type";
import SetPin from "./Auth/setpin";
import { Suspense, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { checkAutoLogin } from "./store/authServices";
import UploadDocuments from "./businessform/documents";
import ManyOtherDocument from "./businessform/document-upload";
import MoreAboutBusiness from "./oversea/moreaboutbusiness";
import VatNumber from "./oversea/vatnumber";
import TaxIndentificationNumber from "./oversea/tax";
import OtherBusinessInformation from "./oversea/otherbusiness";
import Login from "./Auth/login";
import Dashboard from "./dashboard";
import SoleProperietorship from "./businessform/soleproprietorship";
import FinanceHome from "./finance/home";
import Finance from "./finance/invoice";
import CreateInvoice from "./finance/createinvoice";
import SetupInvoice from "./finance/setupinvoice";
import UploadInoiceCreate from "./finance/uploadinvoicecreate";
import LetterOfcreditType from "./letter_of_credit/home";
import TransactionDetails from "./letter_of_credit/ansaction_detail";
import ChooseBank from "./letter_of_credit/bank";
import Summary from "./letter_of_credit/summary";
import TradeofCredit from "./trade_of_credit/home";
import TradeofCreditType from "./trade_of_credit/type";
import TradeDocument from "./trade_of_credit/document";
import TradeSummary from "./trade_of_credit/summary";
import TradeInventory from "./trade_of_credit/inventory";
import Goods from "./trade_of_credit/goods";
import Cashflow from "./trade_of_credit/cashflow";
import CustomerDetails from "./trade_of_credit/customers";
import FlexHome from "./flex/home";
import SelectCardtype from "./flex/card/type";
import CreateCard from "./flex/card/create_card";
import Swal from "sweetalert2";
import Transfer from "./transfer/home";
import TransferType from "./transfer/type";
import BankTransfer from "./transfer/banktransfer";
import Bankdetails from "./transfer/bankdetails";
import Highlight from "./transfer/highlight";
import TransferSummary from "./transfer/summary";
import ShareInvoice from "./flex/share";
import FlexInvoiceFlex from "./flex/invoice";
import FlexInvoceSuccess from "./flex/flexinvoicesuccess";
import AccountHome from "./account/home";
import TopUp from "./topup/home";
import BusinessProfile from "./profile/business";
import PurchaseOrder from "./trade_of_credit/purchaseorder";
import PurchaseOrderDocument from "./trade_of_credit/purchaseorderdocument";
import CreditLine from "./letter_of_credit/newhome";
import CreditLineDocument from "./letter_of_credit/newdocument";
import EditFlexInvoice from "./flex/editinvoice";
import RepayLoan from "./finance/repay";
import FxTransfer from "./fx/home";
import BankTransferForeign from "./transfer/foreignbank";
import TopupType from "./topup/mainhome";
import CardPaymentTopUp from "./topup/card";
import InternalTransfer from "./transfer/users";
import InvicePreview from "./finance/InvoicePreview";
import InvicePreviewDocument from "./finance/InvoicePreviewDocument";
import Verify_emal from "./recover/verify_email";
import Verify_Code_Sent from "./recover/verify_email_code";
import New_Email from "./recover/new_email";
import PaymentHome from "./payment/home";
import PaymentAmount from "./payment/amount";
import SameCurrency from "./payment/samecurrency";
import DocumentAdded from "./payment/document";
import PaymentSummary from "./payment/summary";
import TradevuWallet from "./payment/tradevu";
import CrossBorder from "./payment/crossborder";
import Amount from "./topup/amount";
import CreatePurchaseOrder from "flex/createPurchaseOrder";
import Loading from "component/loading";
import CreateShipment from "flex/shipments/createShipment";
import Flex from "flex";

function App() {
    const { isLogin } = useSelector((state) => state);

    useEffect(() => {
        if (!navigator.onLine) {
            Swal.fire({
                text: "Please check your internet connection",
                icon: "error",
                confirmButtonText: "Cancel",
                width: "280px",
                confirmButtonColor: "crimson",
            });
        }
    }, []);
    const dispatch = useDispatch();

    useEffect(() => {
        checkAutoLogin(dispatch);
    }, []);

    return (
        <Suspense fallback={<Loading />}>
            <Routes>
                <Route element={<PrivateRoutes loggedIn={isLogin} />} path="/">
                    <Route element={<FinanceHome />} path="finance/home" />
                    <Route element={<Finance />} path="finance/invoice" />
                    <Route element={<SetupInvoice />} path="finance/setupinvoice" />
                    <Route element={<UploadInoiceCreate />} path="finance/uploadinvoice/:id" />
                    <Route element={<CreateInvoice />} path="finance/CreateInvoice" />
                    <Route element={<LetterOfcreditType />} path="letter_of_credit/type" />
                    <Route element={<CreditLine />} path="creditline/home" />

                    <Route
                        element={<TransactionDetails />}
                        path="letter_of_credit/transaction_details/:id"
                    />
                    <Route element={<ChooseBank />} path="letter_of_credit/bank" />
                    <Route element={<TradeofCredit />} path="trade_of_credit/home" />
                    <Route element={<TradeofCreditType />} path="trade_of_credit/type/:id" />
                    <Route element={<TradeDocument />} path="trade_of_credit/shipping/:id" />

                    <Route element={<TradeSummary />} path="trade_of_credit/summary" />

                    <Route element={<TradeInventory />} path="trade_of_credit/inventory/:id" />
                    <Route element={<Goods />} path="trade_of_credit/goods/:id" />
                    <Route element={<PurchaseOrder />} path="trade_of_credit/purchase/:id" />
                    <Route element={<PurchaseOrderDocument />} path="purchase/document" />
                    <Route element={<Cashflow />} path="trade_of_credit/cashflow/:id" />
                    <Route element={<EditFlexInvoice />} path="flex/edit" />
                    <Route element={<PaymentHome />} path="payment/home" />

                    <Route element={<CreditLineDocument />} path="letter_of_credit/document" />
                    <Route element={<Summary />} path="letter_of_credit/summary" />
                    <Route element={<SameCurrency />} path="payment/transfer" />
                    <Route element={<CrossBorder />} path="payment/crossborder" />

                    <Route element={<CustomerDetails />} path="trade_of_credit/customer" />
                    <Route element={<Flex />} path="flex/home" />
                    <Route element={<Flex />} path="flex/home/:tab" />
                    <Route element={<FlexHome />} path="flex/home/old" />
                    <Route element={<SelectCardtype />} path="card/get_started" />
                    <Route element={<CreateCard />} path="card/create_card/:id" />
                    <Route element={<Businessform />} path="business/document" />
                    <Route element={<ProofofAddress />} path="business/address" />
                    <Route element={<BusinessType />} path="business/type" />
                    <Route element={<ManyOtherDocument />} path="business/document-form" />
                    <Route element={<MoreAboutBusiness />} path="UK-EU/business" />
                    <Route element={<VatNumber />} path="business/vatnumber" />
                    <Route element={<RepayLoan />} path="loan/repayment" />
                    <Route element={<FxTransfer />} path="foreign/home" />
                    <Route element={<PaymentAmount />} path="payment/amount" />

                    <Route element={<BankTransferForeign />} path="transfer/foreignbank" />

                    <Route element={<TaxIndentificationNumber />} path="business/tax-number" />
                    <Route
                        element={<OtherBusinessInformation />}
                        path="business/other-information"
                    />
                    <Route element={<UploadDocuments />} path="business/documents" />
                    <Route element={<SetPin />} path="setpin" />
                    <Route element={<Dashboard />} path="dashboard" />
                    <Route element={<SoleProperietorship />} path="business/soleproprietor" />
                    <Route element={<Transfer />} path="transfer/home" />
                    <Route element={<TransferType />} path="transfer/type" />
                    <Route element={<BankTransfer />} path="transfer/bank" />
                    <Route element={<Bankdetails />} path="transfer/bankdetails" />
                    <Route element={<Highlight />} path="transfer/details" />
                    <Route element={<TransferSummary />} path="transfer/summary" />
                    <Route element={<ShareInvoice />} path="flex/shareInvoice" />
                    <Route element={<FlexInvoiceFlex />} path="flex/invoice" />
                    <Route element={<FlexInvoceSuccess />} path="flex/success" />
                    <Route element={<AccountHome />} path="account/home" />
                    <Route element={<BusinessProfile />} path="profile/switch" />
                    <Route element={<TopUp />} path="topup/payment_link" />
                    <Route element={<TopupType />} path="topup/home" />
                    <Route element={<CardPaymentTopUp />} path="topup/card" />
                    <Route element={<InternalTransfer />} path="transfer/tradevu" />
                    <Route element={<InvicePreview />} path="invoice/preview" />
                    <Route element={<DocumentAdded />} path="payment/document" />
                    <Route element={<PaymentSummary />} path="payment/summary" />
                    <Route element={<TradevuWallet />} path="payment/wallet" />
                    <Route element={<Amount />} path="topup/fund" />

                    <Route element={<InvicePreviewDocument />} path="invoice/preview/doucument" />
                    <Route element={<CreatePurchaseOrder />} path="flex/create-po" />

                    <Route element={<CreateShipment />} path="flex/shipments/create" />
                </Route>

                <Route element={<PublicRoutes loggedIn={isLogin} />}>
                    <Route index element={<Login />} />
                    <Route element={<Register />} path="/register" />
                    <Route element={<Verify />} path="/verify" />
                    <Route element={<Registers />} path="/register/details" />
                    <Route element={<VerifyNumber />} path="/number" />
                    <Route element={<Verify_emal />} path="/recovery/email_verify" />
                    <Route element={<Verify_Code_Sent />} path="/recovery/email_verification" />
                    <Route element={<New_Email />} path="/recovery/new_password" />

                    <Route element={<Register />} path="/register" />
                </Route>

                <Route element={<CreatePurchaseOrder />} path="/create-po/:poId" />

                <Route path={"*"} element={<Navigate to="/dashboard" replace />} />
            </Routes>
        </Suspense>
    );
}

const PrivateRoutes = ({ loggedIn }) => (loggedIn ? <Outlet /> : <Navigate to={"/"} replace />);
const PublicRoutes = ({ loggedIn }) =>
    !loggedIn ? <Outlet /> : <Navigate to={"/dashboard"} replace />;

export default App;
