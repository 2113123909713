import Modal from "react-bootstrap/Modal";
import success from "../asset/images/successicon.svg";
import { useNavigate } from "react-router-dom";

function CloseAccountSuccess(props) {
  const closeModal = () => {
    var datashow = props.show;
    props.setshow(!datashow);
  };
  const navigate = useNavigate();
  return (
    <Modal
      show={props.show}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="border-0 "
      style={{
        width: "70%",
        margin: "0px auto",
        inset: "0% -100%",
      }}
      onHide={() => {
        props.setshow(false);
      }}
    >
      <Modal.Body
        style={{
          borderRadius: "16px",
          padding: "0px",
          border: "none",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
          }}
        >
          <div
            className=""
            style={{
              borderBottom: "1px solid #E7E9FB",
              padding: "8px 24px 8px 24px",
            }}
          >
            <span
              className="material-icons"
              style={{ color: "#667085" }}
              onClick={closeModal}
            >
              close
            </span>
          </div>
          <div style={{ padding: "8px 24px 8px 24px" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "32px",
                marginBottom: "10px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  gap: "24px",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <div>
                  <img src={success} alt="" />
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "8px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <h3
                      style={{
                        color: "#101828",
                        fontWeight: "700",
                        lineHeight: "24.3px",
                        fontSize: "18px",
                        textAlign: "center",
                      }}
                    >
                      Business account deleted
                    </h3>
                    <p
                      style={{
                        textAlign: "center",
                        color: "#667085",
                        fontWeight: "400",
                        letterSpacing: "2%",
                        fontSize: "16px",
                        lineHeight: "24px",
                      }}
                    >
                      We have removed ‘{props?.business?.name}’ from your list.
                      You can switch to another account, or create a new one
                      here.
                    </p>
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "8px",
                }}
              >
                <button
                  style={{
                    background: "#6F00FF",
                    color: "#fff",
                    padding: "16px 24px 16px 24px",
                    border: "none",
                    outline: "none",
                    borderRadius: "8px",
                    fontWeight: "600",
                  }}
                  onClick={() => {
                    window.location.reload();
                  }}
                >
                  Done
                </button>
                {/***
                {props.method === "transfer" && (
                  <button
                    style={{
                      background: "#6F00FF",
                      color: "#fff",
                      padding: "16px 24px 16px 24px",
                      fontWeight: "600",
                      border: "none",
                      outline: "none",
                      borderRadius: "8px",
                    }}
                  >
                    Track transaction
                  </button>
                )}
                */}
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default CloseAccountSuccess;
