import Modal from "react-bootstrap/Modal";
import addbvn from "../asset/images/addbvn.png";
import { useState } from "react";
import axios from "axios";
import Loader from "./loader";
import { ThreeDots } from "react-loader-spinner";

const DirectorModalBvn = (props) => {
    const [showForm, setShowForm] = useState(false);
    const [name, setname] = useState("");
    const [lastName, setlastName] = useState("");
    const [firstName, setfirstName] = useState("");
    const [bvn, setbvn] = useState("");
    const [isbtn, setisbtn] = useState(true);
    const [means, setmeans] = useState("bvnNumber");
    const [dob, setdob] = useState(new Date());
    const [errorBVNMessage, setErrorBVNMessage] = useState("");
    const [loader, setloader] = useState(false);
    const [loaderrc, setloaderrc] = useState(false);
    const [otp, setotp] = useState("");
    const [loaderSuccess, setloaderSuccess] = useState(false);
    const [email, setEmail] = useState("");
    const [id, setId] = useState("");
    const [phone, setPhone] = useState("");
    const [errorMessage2, setErrorMessage] = useState("");
    // const [isClose, setisClose] = useState(props.sh)
    // console.log(props.director);
    const indentification = [
        "IdCard",
        "BioMetricResidencePermit",
        "BirthCertificate",
        "currentPassport",
        "driverLicense",
        "bvnNumber",
    ];

    const closeModal = () => {
        props.setShowModal(false);
    };
    // console.log(new Date(dob).toLocaleDateString().replaceAll("/", "-"));
    const arroeback = () => {
        setShowForm(!showForm);
        setotp("");
        setbvn("");
        setErrorMessage("");
        setErrorBVNMessage("");
    };
    const dataList = props?.director ? props.director : [];

    const showFormandHideForm = () => {
        setShowForm(!showForm);
    };
    const removeByIndex = (index) => {
        props.setDirector((prevArray) => {
            const newArray = [...prevArray];
            newArray.splice(index, 1);
            return newArray;
        });
        handleBVNtodata();
    };
    const getDirectorBVN = async () => {
        if (bvn?.length === 11) {
            setloaderrc(true);
            await axios
                .post("/v1/utility/get-bvn-verification", {
                    Bvn: bvn,
                })
                .then((res) => {
                    //  console.log(res);
                    setloaderrc(false);
                    setname(res.data.data.firstName);
                    setlastName(res.data.data.lastName);
                    setdob(res.data.data.dateOfBirth);
                })
                .catch((e) => {
                    console.log(e);
                });
        } else {
            setloaderrc(false);
        }
    };
    // console.log(firstName);
    const currentUrl = window.location.href;
    // console.log(currentUrl);

    // import axios from 'axios';
    // console.log(props.director);
    const handleBvnDirector = async (e) => {
        setErrorBVNMessage("");
        setErrorMessage("");
        e.preventDefault();
        setloader(true);

        try {
            if (!loaderSuccess) {
                const res = await axios.post(
                    `/v1/business/send_bvn_request/${props.state.businessId}`,
                    { number: bvn }
                );
                //  console.log(res);

                if (res.data.statusCode === 404) {
                    setloaderSuccess(false);
                    setErrorBVNMessage("Incorrect BVN, please check again");
                } else if (res.data.statusCode === 500) {
                    setloaderSuccess(false);
                    setErrorBVNMessage("Error verifying. Please try again.");
                } else {
                    if (res.data.data.providerResponse) {
                        const jsonResponse = JSON.parse(res.data.data.providerResponse);
                        //  console.log(jsonResponse);
                        setfirstName(jsonResponse.firstName);
                        setlastName(jsonResponse.lastName);
                        setEmail(jsonResponse.email);
                        setPhone(jsonResponse.phoneNumber1);

                        props.setDirector([
                            ...dataList,
                            {
                                bvnNumber: bvn,
                                lastName: jsonResponse.lastName,
                                firstName: jsonResponse.firstName,
                                email: jsonResponse.email,
                                phoneNumber: jsonResponse.phoneNumber1,
                                _id: res?.data.data._id,
                            },
                        ]);
                    } else {
                        const resp = await axios.get(`https://api.tapit.ng/api/getbvn?bvn=${bvn}`);
                        setfirstName(resp.data.entity.first_name);
                        setlastName(resp.data.entity.last_name);
                        setEmail(resp.data.entity.email);
                        setPhone(resp.data.entity.phone_number1);
                        props.setDirector([
                            ...dataList,
                            {
                                bvnNumber: bvn,
                                lastName: resp.data.entity.last_name,
                                firstName: resp.data.entity.first_name,
                                email: resp.data.entity.email,
                                phoneNumber: resp.data.entity.phone_number1,
                                _id: res?.data.data._id,
                            },
                        ]);
                    }
                    setloaderSuccess(true);
                }

                setId(res?.data.data._id);
            } else {
                const res = await axios.post(
                    `/v1/business/verify_bvn_request/${props.state.businessId}`,
                    {
                        otp: otp,
                        identityId: id,
                    }
                );
                //  console.log(res);
                if (res.data.message === "Incorrect OTP.") {
                    setErrorMessage(res.data.message);
                } else if (res.data.statusCode === 400 || res.data.statusCode === 200) {
                    handleBVNtodata();

                    // setbvn("");
                    //setfirstName("");
                    //  setlastName("");
                    setShowForm(false);
                    setisbtn(!isbtn);
                    setloaderSuccess(false);
                    setotp("");
                    //  setEmail("");
                    //setPhone("");
                }
            }
        } catch (error) {
            console.log(error);
        } finally {
            setloader(false);
        }
    };

    const handleBVNtodata = async (data) => {
        // console.log(data);
        setloaderrc(true);
        const formData = new FormData();
        props.director.forEach((obj, index) => {
            Object.entries(obj).forEach(([key, value]) => {
                if (props.type === "LLC") {
                    formData.append(`BVN_OF_DIRECTOR[${index}][${key}]`, value);
                } else {
                    formData.append(`BVN_OF_PARTNER[${index}][${key}]`, value);
                }
            });
        });
        await axios
            .post(
                `/v1/business/upload-business-documents/${props.state.businessId}?businessType=${props.type}`,
                formData
            )
            .then(() => {
                // console.log(res);
                setloaderrc(false);
                setbvn("");
                setfirstName("");
                setlastName("");
                props.setfileUploadedBvn(true);
                //  props.setShowModal(false);
                //props?.setisUploaded3(true);
            })
            .catch((e) => {
                console.log(e);
            });
        setShowForm(false);
        setisbtn(!isbtn);
        // props.show = true

        // console.log(directorBvnDetails)
    };
    return (
        <>
            <Modal
                show={props.show}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="myBilltype"
                onHide={() => props.setShowModal(false)}
            >
                <Modal.Header className="border-0 py-1">
                    {showForm === true ? (
                        <span
                            className="material-icons"
                            style={{
                                fontSize: "30px",
                                cursor: "pointer",
                                color: "#292D32",
                            }}
                            onClick={() => {
                                setloaderSuccess(false);
                                arroeback();
                                setErrorMessage("");
                            }}
                        >
                            keyboard_backspace
                        </span>
                    ) : (
                        <span
                            className="material-icons"
                            style={{
                                fontSize: "24px",
                                cursor: "pointer",
                            }}
                            onClick={closeModal}
                        >
                            close
                        </span>
                    )}{" "}
                </Modal.Header>
                <Modal.Body>
                    {" "}
                    {showForm === false && (
                        <>
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: "16px",
                                }}
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                    }}
                                >
                                    <div
                                        style={{
                                            fontSize: "1rem",
                                            lineHeight: "24px",
                                            letterSpacing: "2%",
                                            color: "#101828",
                                            fontWeight: "700",
                                        }}
                                    >
                                        Add Directors
                                    </div>
                                    <span
                                        style={{
                                            color: "#667085",
                                            lineHeight: "19.6px",
                                            fontSize: "14px",
                                            fontWeight: "400",
                                        }}
                                    >
                                        Add BVN of the directors of the company
                                    </span>
                                </div>
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        gap: "16px",
                                    }}
                                >
                                    {props?.director?.length === 0 && (
                                        <div
                                            className="card  border-0 "
                                            style={{
                                                background: "#ffff",
                                                cursor: "pointer",
                                            }}
                                            onClick={showFormandHideForm}
                                        >
                                            <div
                                                className="card-body d-flex gap-2 "
                                                style={{ alignItems: "center" }}
                                            >
                                                <img src={addbvn} alt="" />
                                                <div className="d-flex justify-content-between align-items-center w-100">
                                                    <span
                                                        style={{
                                                            fontSize: "1rem",
                                                            fontWeight: "500",
                                                            lineHeight: "24px",
                                                            letterSpacing: "2%",
                                                        }}
                                                    >
                                                        Add Director
                                                    </span>
                                                    <span className="material-icons" style={{}}>
                                                        chevron_right
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    {props?.director?.length === undefined && (
                                        <div
                                            className="card  border-0 "
                                            style={{
                                                background: "#ffff",
                                                cursor: "pointer",
                                            }}
                                            onClick={showFormandHideForm}
                                        >
                                            <div
                                                className="card-body d-flex gap-2 "
                                                style={{ alignItems: "center" }}
                                            >
                                                <img src={addbvn} alt="" />
                                                <div className="d-flex justify-content-between align-items-center w-100">
                                                    <span
                                                        style={{
                                                            fontSize: "1rem",
                                                            fontWeight: "500",
                                                            lineHeight: "24px",
                                                            letterSpacing: "2%",
                                                        }}
                                                    >
                                                        Add Director
                                                    </span>
                                                    <span className="material-icons" style={{}}>
                                                        chevron_right
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    {props?.director?.length !== 0 &&
                                        props?.director?.map((details, index) => (
                                            <div
                                                className="card  border-0 "
                                                style={{
                                                    background: "#ffff",
                                                    cursor: "pointer",
                                                }}
                                                //   onClick={props.die showFormandHideForm}
                                                key={index}
                                            >
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        gap: "8px",
                                                    }}
                                                >
                                                    <div
                                                        className="card-body  d-flex gap-2 "
                                                        style={{ alignItems: "center" }}
                                                    >
                                                        <div
                                                            style={{
                                                                backgroundColor: "#F4F0FF",
                                                                padding: "5px",
                                                                borderRadius: "100%",
                                                                height: "32px",
                                                                width: "32px",
                                                                textAlign: "center",
                                                                display: "flex",
                                                                justifyContent: "center",
                                                            }}
                                                        >
                                                            <span
                                                                style={{
                                                                    textTransform: "uppercase",
                                                                    fontSize: "14px",
                                                                    lineHeight: "20px",
                                                                    fontWeight: "500",
                                                                    textAlign: "center",
                                                                    color: "#7F56D9",
                                                                }}
                                                            >
                                                                {details.bvnNumber?.slice(0, 2)}{" "}
                                                            </span>
                                                        </div>
                                                        <div className="d-flex justify-content-between align-items-center w-100">
                                                            <div>
                                                                <span
                                                                    style={{
                                                                        color: "#101828",
                                                                        fontSize: "1rem",
                                                                        letterSpacing: "2%",
                                                                        fontWeight: "600",
                                                                        lineHeight: "24px",
                                                                    }}
                                                                >
                                                                    {details.firstName}{" "}
                                                                    {details.lastName}
                                                                </span>

                                                                <div
                                                                    className=""
                                                                    style={{
                                                                        color: "#667085",
                                                                        fontSize: "12px",
                                                                        letterSpacing: "2%",
                                                                        fontWeight: "500%",
                                                                        lineHeight: "16.2px",
                                                                    }}
                                                                >
                                                                    {details.bvnNumber}
                                                                </div>
                                                            </div>
                                                            <span
                                                                className="material-icons"
                                                                style={{
                                                                    color: "crimson",
                                                                    cursor: "pointer",
                                                                }}
                                                                onClick={() => removeByIndex(index)}
                                                            >
                                                                delete
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}

                                    {props?.director?.length !== 0 && (
                                        <div
                                            className="d-flex align-items-center"
                                            onClick={showFormandHideForm}
                                            style={{
                                                marginTop: "20px",
                                            }}
                                        >
                                            <span
                                                className="material-icons"
                                                style={{
                                                    color: "#6F00FF",
                                                    fontSize: "20px",
                                                    marginRight: "8px",
                                                }}
                                            >
                                                add
                                            </span>
                                            <span
                                                style={{
                                                    color: "#6F00FF",
                                                    fontWeight: "800",
                                                    cursor: "pointer",
                                                    lineHeight: "24px",
                                                    letterSpacing: "1%",
                                                    fontSize: "1rem",
                                                }}
                                            >
                                                Add another
                                            </span>
                                        </div>
                                    )}
                                    {/**

                  <button
                    className="btn  btn-primary w-100  border-0"
                    onClick={handleBVNtodata}
                    style={{
                      background:
                        props?.director?.length === 0
                          ? "#EBE4FF"
                          : "rgb(111, 0, 255)",
                      color: props?.director?.length === 0 ? "#BFA6FF" : "#fff",
                      fontSize: "1rem",
                      lineHeight: "24px",
                      fontWeight: "700",
                      padding: "16px 24px 16px 24px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    disabled={
                      props?.director?.length === 0 ||
                      props?.director?.length === undefined
                    }
                  >
                    {loaderrc ? <Loader /> : "Save"}
                  </button>
                  */}
                                </div>
                            </div>
                        </>
                    )}
                    {showForm && (
                        <form
                            action=""
                            onSubmit={handleBvnDirector}
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "16px",
                            }}
                        >
                            <h6
                                style={{
                                    fontSize: "1rem",
                                    color: "#101828",
                                    lineHeight: "24px",
                                    fontWeight: "700",
                                    letterSpacing: "1%",
                                }}
                            >
                                BVN of the Directors of the Company
                            </h6>
                            <div className="card  border-0" style={{ background: "inherit" }}>
                                <div
                                    className="card-body p-0  "
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        gap: "32px",
                                    }}
                                >
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            gap: "16px",
                                        }}
                                    >
                                        <>
                                            <div
                                                className="form-group "
                                                style={{
                                                    background: "#ECEFF3",
                                                    borderRadius: "8px",
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    gap: "8px",
                                                    padding: "8px 16px 8px 16px",
                                                }}
                                            >
                                                <label
                                                    htmlFor=""
                                                    style={{
                                                        fontSize: "12px",
                                                        lineHeight: "16.2px",
                                                        fontWeight: "400",
                                                        letterSpacing: "2%",
                                                        color: "#667085",
                                                    }}
                                                >
                                                    Bank Verification Numbers (BVN)
                                                </label>
                                                <input
                                                    type="tel"
                                                    maxLength="11"
                                                    className="form-control border-0"
                                                    placeholder="Enter BVN"
                                                    required
                                                    style={{
                                                        background: "#ECEFF3",
                                                        color: "#667085",
                                                        letterSpacing: "2%",
                                                        lineHeight: "24px",
                                                        fontWeight: "400",
                                                        fontSize: "1rem",
                                                        padding: "0px",
                                                    }}
                                                    minLength="11"
                                                    onChange={(e) => {
                                                        setbvn(e.target.value);
                                                    }}
                                                    onKeyDown={(e) => {
                                                        const allowedKeys = [
                                                            "Backspace",
                                                            "Delete",
                                                            "ArrowLeft",
                                                            "ArrowRight",
                                                            "Tab",
                                                        ];
                                                        if (
                                                            !/^[0-9]$/.test(e.key) &&
                                                            !allowedKeys.includes(e.key)
                                                        ) {
                                                            e.preventDefault();
                                                        }
                                                    }}
                                                />
                                            </div>
                                            {errorBVNMessage !== "" && (
                                                <span
                                                    style={{
                                                        color: "crimson",
                                                        fontSize: ".8rem",
                                                    }}
                                                >
                                                    {" "}
                                                    {errorBVNMessage}
                                                </span>
                                            )}
                                            {loaderSuccess && (
                                                <>
                                                    <div
                                                        className="form-group "
                                                        style={{
                                                            background: "#ECEFF3",
                                                            borderRadius: "8px",
                                                            display: "flex",
                                                            flexDirection: "column",
                                                            gap: "8px",
                                                            padding: "8px 16px 8px 16px",
                                                        }}
                                                    >
                                                        <label
                                                            htmlFor=""
                                                            style={{
                                                                fontSize: "12px",
                                                                lineHeight: "16.2px",
                                                                fontWeight: "400",
                                                                letterSpacing: "2%",
                                                                color: "#667085",
                                                            }}
                                                        >
                                                            First name
                                                        </label>
                                                        <input
                                                            type="text"
                                                            name=""
                                                            id=""
                                                            placeholder="First name"
                                                            value={firstName}
                                                            readOnly
                                                            className="form-control border-0"
                                                            onChange={(e) =>
                                                                setfirstName(e.target.value)
                                                            }
                                                            style={{
                                                                background: "#ECEFF3",
                                                                color: "#667085",
                                                                letterSpacing: "2%",
                                                                lineHeight: "24px",
                                                                fontWeight: "400",
                                                                fontSize: "1rem",
                                                                padding: "0px",
                                                            }}
                                                        />
                                                    </div>
                                                    <div
                                                        className="form-group "
                                                        style={{
                                                            background: "#ECEFF3",
                                                            borderRadius: "8px",
                                                            display: "flex",
                                                            flexDirection: "column",
                                                            gap: "8px",
                                                            padding: "8px 16px 8px 16px",
                                                        }}
                                                    >
                                                        <label
                                                            htmlFor=""
                                                            style={{
                                                                fontSize: "12px",
                                                                lineHeight: "16.2px",
                                                                fontWeight: "400",
                                                                letterSpacing: "2%",
                                                                color: "#667085",
                                                            }}
                                                        >
                                                            Last name
                                                        </label>
                                                        <input
                                                            type="text"
                                                            name=""
                                                            id=""
                                                            readOnly
                                                            placeholder="Last name"
                                                            value={lastName}
                                                            onChange={(e) =>
                                                                setlastName(e.target.value)
                                                            }
                                                            className="form-control border-0"
                                                            style={{
                                                                background: "#ECEFF3",
                                                                color: "#667085",
                                                                letterSpacing: "2%",
                                                                lineHeight: "24px",
                                                                fontWeight: "400",
                                                                fontSize: "1rem",
                                                                padding: "0px",
                                                            }}
                                                        />
                                                    </div>
                                                    <div
                                                        className="form-group "
                                                        style={{
                                                            background: "#ECEFF3",
                                                            borderRadius: "8px",
                                                            display: "flex",
                                                            flexDirection: "column",
                                                            gap: "8px",
                                                            padding: "8px 16px 8px 16px",
                                                        }}
                                                    >
                                                        <label
                                                            htmlFor=""
                                                            style={{
                                                                fontSize: "12px",
                                                                lineHeight: "16.2px",
                                                                fontWeight: "400",
                                                                letterSpacing: "2%",
                                                                color: "#667085",
                                                            }}
                                                        >
                                                            Phone number
                                                        </label>
                                                        <input
                                                            type="tel"
                                                            name=""
                                                            id=""
                                                            placeholder="Phone number"
                                                            value={phone}
                                                            readOnly
                                                            onChange={(e) =>
                                                                setPhone(e.target.value)
                                                            }
                                                            className="form-control border-0"
                                                            style={{
                                                                background: "#ECEFF3",
                                                                color: "#667085",
                                                                letterSpacing: "2%",
                                                                lineHeight: "24px",
                                                                fontWeight: "400",
                                                                fontSize: "1rem",
                                                                padding: "0px",
                                                            }}
                                                        />
                                                    </div>

                                                    <div
                                                        className="form-group "
                                                        style={{
                                                            background: "#ECEFF3",
                                                            borderRadius: "8px",
                                                            display: "flex",
                                                            flexDirection: "column",
                                                            gap: "8px",
                                                            padding: "8px 16px 8px 16px",
                                                        }}
                                                    >
                                                        <label
                                                            htmlFor=""
                                                            style={{
                                                                fontSize: "12px",
                                                                lineHeight: "16.2px",
                                                                fontWeight: "400",
                                                                letterSpacing: "2%",
                                                                color: "#667085",
                                                            }}
                                                        >
                                                            OTP (One Time Password)
                                                        </label>
                                                        <input
                                                            type="tel"
                                                            name=""
                                                            maxLength="6"
                                                            required
                                                            id=""
                                                            placeholder="OTP"
                                                            value={otp}
                                                            onChange={(e) => setotp(e.target.value)}
                                                            className="form-control border-0"
                                                            style={{
                                                                background: "#ECEFF3",
                                                                color: "#667085",
                                                                letterSpacing: "2%",
                                                                lineHeight: "24px",
                                                                fontWeight: "400",
                                                                fontSize: "1rem",
                                                                padding: "0px",
                                                            }}
                                                            onKeyDown={(e) => {
                                                                const allowedKeys = [
                                                                    "Backspace",
                                                                    "Delete",
                                                                    "ArrowLeft",
                                                                    "ArrowRight",
                                                                    "Tab",
                                                                ];
                                                                if (
                                                                    !/^[0-9]$/.test(e.key) &&
                                                                    !allowedKeys.includes(e.key)
                                                                ) {
                                                                    e.preventDefault();
                                                                }
                                                            }}
                                                        />
                                                    </div>
                                                    {errorMessage2 !== "" && (
                                                        <span
                                                            style={{
                                                                color: "crimson",
                                                                fontSize: ".8rem",
                                                            }}
                                                        >
                                                            {errorMessage2}
                                                        </span>
                                                    )}
                                                </>
                                            )}
                                        </>

                                        {/**
                    <div
                      className="form-group "
                      style={{
                        background: "#ECEFF3",
                        borderRadius: "8px",
                        display: "flex",
                        flexDirection: "column",
                        gap: "8px",
                        padding: "8px 16px 8px 16px",
                      }}
                    >
                      <label
                        htmlFor=""
                        style={{
                          fontSize: "12px",
                          lineHeight: "16.2px",
                          fontWeight: "400",
                          letterSpacing: "2%",
                          color: "#667085",
                        }}
                      >
                        Means of identification
                      </label>
                      <select
                        type="text"
                        name=""
                        id=""
                        value={means}
                        className="form-control border-0"
                        placeholder="Directior Name"
                        style={{
                          background: "#ECEFF3",
                          color: "#667085",
                          letterSpacing: "2%",
                          lineHeight: "24px",
                          fontWeight: "400",
                          fontSize: "1rem",
                          padding: "0px",
                        }}
                        onChange={(e) => {
                          setmeans(e.target.value);
                        }}
                      >
                        {indentification.map((indentity, index) => (
                          <option
                            value={indentity}
                            key={index}
                            style={{
                              textTransform: "uppercase",
                              wordBreak: "break-word",
                              wordSpacing: "30px",
                            }}
                          >
                            {indentity}
                          </option>
                        ))}
                      </select>
                    </div>
                    */}
                                        {/**   {means === "bvnNumber" && (
                                         */}

                                        {/***
                    {loaderrc && (
                      <ThreeDots
                        height="30"
                        width="30"
                        radius="9"
                        color="#6F00FF"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{
                          alignItems: "center",
                          justifyContent: "center",
                          display: "flex",
                          marginTop: "-20px",
                        }}
                        wrapperClassName=""
                        visible={true}
                      />
                    )}
                    {/** )}
                     * */}

                                        {/***
                      <>
                        <div
                          className="form-group "
                          style={{
                            background: "#ECEFF3",
                            borderRadius: "8px",
                            display: "flex",
                            flexDirection: "column",
                            gap: "8px",
                            padding: "8px 16px 8px 16px",
                          }}
                        >
                          <label
                            htmlFor=""
                            style={{
                              fontSize: "12px",
                              lineHeight: "16.2px",
                              fontWeight: "400",
                              letterSpacing: "2%",
                              color: "#667085",
                            }}
                          >
                            Date of birth
                          </label>
                          <input
                            type="date"
                            name=""
                            id=""
                            value={new Date(dob).toISOString().slice(0, 10)}
                            className="form-control border-0"
                            placeholder="Directior Name"
                            style={{
                              background: "#ECEFF3",
                              color: "#667085",
                              letterSpacing: "2%",
                              lineHeight: "24px",
                              fontWeight: "400",
                              fontSize: "1rem",
                              padding: "0px",
                            }}
                          />
                        </div>
                      </>
                    */}
                                    </div>

                                    <div className="form-group ">
                                        {loaderSuccess === false && (
                                            <button
                                                className="btn btn-primary w-100 border-0"
                                                type="submit"
                                                style={{
                                                    background: "rgb(111, 0, 255)",
                                                    padding: "16px 24px 16px 24px",
                                                    borderRadius: "8px",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                }}
                                            >
                                                {loader ? <Loader /> : "Verify to proceed"}
                                            </button>
                                        )}

                                        {loaderSuccess && (
                                            <button
                                                className="btn btn-primary w-100 border-0"
                                                type="submit"
                                                style={{
                                                    background: "rgb(111, 0, 255)",
                                                    padding: "16px 24px 16px 24px",
                                                    borderRadius: "8px",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                }}
                                            >
                                                {loader ? <Loader /> : "Verify"}
                                            </button>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </form>
                    )}{" "}
                </Modal.Body>
            </Modal>
        </>
    );
};

export default DirectorModalBvn;
