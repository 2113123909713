import { ArrowSquaredIcon, SearchIcon, TruckIcon } from "asset/icons";
import Input from "component/form/Input";
import Table from "component/table";
import dayjs from "dayjs";
import useGetRequest from "hooks/useGetRequest";
import useScreenSize from "hooks/useScreenSize";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import currencyPairCheck from "utilities/currencyPairCheck";
import { formatMoney } from "utilities/formatMoney";
import Status from "./component/status";
import MoreActions from "./component/moreActions";
import { Bars } from "react-loader-spinner";
import notFoundImg from "asset/images/not-found.svg";
import capitalizeWords from "utilities/capitalizeWords";
import capitalizeFirstLetter from "utilities/capitalizeFirstLetter";
import { businessToUse } from "flex/utils";
import useDebounce from "hooks/useDebounce";
import CurrencyToggle from "component/currencyToggle";
import FilterBtn from "./component/filterBtn";

// TODO
// CLEAR FILTER

const Procurement = ({ loadedPoDataRef }) => {
    const [activeTab, setActiveTab] = useState("Purchase orders");
    const { business, currency } = useSelector((state) => state);
    const [fetchStats, stats_] = useGetRequest();
    const stats = stats_ ?? loadedPoDataRef.current?.stats;

    useEffect(() => {
        business?._id && !stats_ && fetchStats(`/v1/flex/order-stat`, {}, false);
        return () => {
            loadedPoDataRef.current = { ...loadedPoDataRef.current, stats };
        };
    }, [business?._id, stats, stats_]);

    return (
        <>
            <div className="flex max-800:flex-col !gap-6 800:h-[88px] 800:items-center !px-6 sm:!px-7 max-800:mb-10">
                <h2 className="font-[450] text-[22px] text-main-dark max-800:mt-6 translate-y-2.5">
                    Procurement
                </h2>
                <div className="800:ml-auto flex">
                    {/* <button className="!py-2 !px-4 rounded-lg bg-[#EBE4FF] text-[#5E01D6] max-375:text-sm !mr-2 350:!mr-3 375:!mr-4">
                        Get a quote
                    </button> */}
                    <Link
                        className="!py-2 !px-4 rounded-lg !text-[#FBFAFF] bg-[#5E01D6] max-375:text-sm"
                        to="/flex/create-po"
                    >
                        New purchase order
                    </Link>
                </div>
            </div>

            <>
                <p className="font-[550] !text-[17px] text-main flex items-center !mt-3 !mb-3 !px-6 sm:!px-7">
                    Overview{" "}
                    <CurrencyToggle
                        className="!bg-[#DBDFE5] !ml-2.5"
                        dropDownClassName="shadow-dropdown2 !left-0 !top-[109%]"
                        hideLabel
                    />
                </p>
                <div className="grid grid-cols-[280px,304px] 900:grid-cols-[repeat(2,280px)] !gap-6 !ml-6 sm:!ml-7 overflow-x-auto lg:!pr-7 [scrollbar-width:none]">
                    <Metrics
                        title="All orders"
                        value={formatMoney(stats?.allOrders?.totalOrders ?? 0)}
                        meta={
                            <p className="text-main-grey !text-sm">
                                {currencyPairCheck(currency)}{" "}
                                <span className="text-main !mr-0.5 text-base">
                                    {formatMoney(stats?.allOrders?.totalPaidAmount[currency] ?? 0)}
                                </span>{" "}
                                spent so far
                            </p>
                        }
                    />
                    <Metrics
                        className="max-900:mr-6"
                        title="Open orders"
                        value={formatMoney(stats?.openOrders?.totalOpenOrder ?? 0)}
                        meta={
                            <p className="flex items-center text-main-grey !text-sm">
                                <TruckIcon className="!size-[18px]" />{" "}
                                <span className="text-main !ml-2 !mr-1 text-base">
                                    {formatMoney(stats?.openOrders?.inTransit ?? 0)}
                                </span>{" "}
                                In transit
                            </p>
                        }
                    />
                    {/* <Metrics
                        title="Active quotes"
                        value={9}
                        meta={
                            <p className="text-main-grey !text-sm">
                                <span className="text-main !mr-0.5 text-base">4</span> Awaiting
                                response
                            </p>
                        }
                        className="max-sm:mr-6 max-lg:mr-7"
                    /> */}
                </div>
            </>

            <>
                <div className="flex !gap-8 !mt-[34px] !px-6 sm:!px-[52px]">
                    {["Purchase orders"].map((tab) => (
                        <p
                            key={tab}
                            onClick={() => setActiveTab(tab)}
                            className={`border-b-2 cursor-pointer !pb-2 transition-all ${
                                activeTab === tab
                                    ? "font-[450] text-main-dark border-b-secondary"
                                    : "text-main-grey border-b-transparent"
                            }`}
                        >
                            {tab}
                        </p>
                    ))}
                </div>
                <Tables
                    quotesTab={activeTab === "Quotes"}
                    business={business}
                    currency={currency}
                    loadedPoDataRef={loadedPoDataRef}
                />
            </>
        </>
    );
};

const Metrics = ({ title, value, meta, className }) => (
    <div className={`bg-white !border !border-[#F4F0FF] rounded-lg !p-4 ${className}`}>
        <p className="font-medium text-sm text-main-grey">{title}</p>
        <h3 className="text-[32px] font-normal !mt-[11px] !mb-2 !text-main">{value}</h3>
        {meta}
    </div>
);

const Tables = ({ quotesTab, business, currency, loadedPoDataRef }) => {
    const [fetchInboundOrders, inboundOrders, inboundOrdersLoading] = useGetRequest();
    const [fetchOutboundOrders, outboundOrders, outboundOrdersLoading] = useGetRequest(false);
    const [fetchCompletedOrders, completedOrders, completedOrdersLoading] = useGetRequest(false);
    const [activeTab, setActiveTab] = useState("Inbound");
    const [searchTerm, setSearchTerm] = useState("");
    const [statusTypes, setStatusTypes] = useState([]);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [updatedStatusOrders, setUpdatedStatusOrders] = useState({});
    const navigate = useNavigate();
    const [fetchWallets, wallets] = useGetRequest();
    const tabsArr = quotesTab ? ["Inbound", "Outbound"] : ["Inbound", "Outbound", "Completed"];

    const sortByDate = (arr, key = "deliveryDate") =>
        arr?.sort((a, b) => {
            let dateA = new Date(a[key]),
                dateB = new Date(b[key]);
            return dateA - dateB;
        });

    const tabsData = useMemo(() => {
        const loaded = loadedPoDataRef.current;
        return {
            Inbound: sortByDate(inboundOrders?.inbound) ?? loaded?.Inbound ?? null,
            Outbound: sortByDate(outboundOrders?.outBound) ?? loaded?.Outbound ?? null,
            Completed: sortByDate(completedOrders) ?? loaded?.Completed ?? null,
        };
    }, [inboundOrders?.inbound, outboundOrders?.outBound, completedOrders]);

    const loading =
        (searchTerm || statusTypes.length || startDate || endDate || !tabsData[activeTab]) &&
        (inboundOrdersLoading || outboundOrdersLoading || completedOrdersLoading);

    const completedType = (order) => {
        const type = businessToUse(order, business).createdByCurrBiz ? "outbound" : "inbound";
        return (
            <span
                className={`text-sm !px-2 rounded-[13px] font-normal ${
                    type === "inbound"
                        ? "bg-[#FFEFC6] text-[#B64607]"
                        : "bg-[#DCFDDB] text-[#107C10]"
                }`}
            >
                {capitalizeFirstLetter(type)}
            </span>
        );
    };

    const viewOrder = (orderId) =>
        navigate(`/flex/home/manage-po?orderId=${orderId}&type=${activeTab.toLowerCase()}`);

    useEffect(() => {
        business?._id && fetchWallets("/v1/wallets/accounts");
    }, [business?._id]);

    useEffect(() => {
        if (business?._id) {
            const queryString = `searchTerm=${searchTerm}&limit=10&page=1&currency=${currency}&startDate=${startDate}&endDate=${endDate}${statusTypes
                .map((type) => `&statuses=${type}`)
                .join("")}`;
            if (activeTab === "Inbound")
                fetchInboundOrders(`/v1/flex/inbounds-orders?${queryString}`, {}, false);

            if (activeTab === "Outbound")
                fetchOutboundOrders(`/v1/flex/outbounds-orders?${queryString}`, {}, false);

            if (activeTab === "Completed")
                fetchCompletedOrders(`/v1/flex/completed-orders?${queryString}`, {}, false);
        }
    }, [business?._id, activeTab, searchTerm, currency, statusTypes, startDate, endDate]);

    useEffect(() => {
        return () => {
            loadedPoDataRef.current = { ...loadedPoDataRef.current, ...tabsData };
        };
    }, [tabsData]);

    useEffect(() => {
        quotesTab && activeTab === "Completed" && setActiveTab("Inbound");
    }, [activeTab, quotesTab]);

    const updateSearchString = useDebounce(async (searchString) => {
        !loading && setSearchTerm(searchString.trim().toLowerCase());
    }, 700);

    return (
        <div className="bg-white max-sm:!border-x-0 h-min overflow-hidden !border !border-[#E4E7EC] sm:rounded-xl sm:!mx-7 !mb-10">
            <div className="flex xl:items-center max-xl:flex-col !px-6 sm:!px-4 !py-6">
                <div className="bg-[#EAECF0] rounded-lg !p-1 flex !gap-2 w-full 820:w-max">
                    {tabsArr.map((tab) => (
                        <button
                            className={`rounded w-[166px] h-[34px] font-medium text-[13px] 330:text-sm ${
                                activeTab === tab ? "bg-white text-main-dark" : "text-main-grey"
                            }`}
                            key={tab}
                            onClick={() => {
                                setSearchTerm("");
                                setActiveTab(tab);
                            }}
                        >
                            {tab}
                        </button>
                    ))}
                </div>
                <div className="flex max-xl:mt-4 max-xl:mr-auto xl:ml-auto">
                    <Input
                        onChange={updateSearchString}
                        unit={<SearchIcon className="-order-2 size-6" />}
                        unitWrapperClassName="bg-[#F2F4F7] !mr-2 375:!mr-3 !pl-4 w-auto 400:w-[240px] md:w-[264px]"
                        className="!h-10 !pl-2 !shadow-[inset_0_0_0_1000px_#F2F4F7]"
                        placeholder="Search"
                        key={activeTab}
                    />
                    <FilterBtn
                        {...{
                            endDate,
                            startDate,
                            statusTypes,
                            setEndDate,
                            setStartDate,
                            setStatusTypes,
                        }}
                    />
                </div>
            </div>

            {loading ? (
                <Bars
                    wrapperClass="w-max mx-auto mt-[100px] mb-[160px]"
                    height={30}
                    width={30}
                    color="#6F00FF"
                />
            ) : (
                <>
                    {!tabsData[activeTab]?.length ? (
                        <div className="flex flex-col items-center !mt-9 !mb-11">
                            <img
                                src={notFoundImg}
                                alt={`No ${activeTab} Orders`}
                                className="w-[144px] h-[107.39px]"
                            />
                            <p className="text-main-dark font-medium text-sm !mt-5 !mb-[22px]">
                                You don’t have any {activeTab.toLowerCase()} purchase orders
                            </p>
                            <Link
                                to="/flex/create-po"
                                className="bg-[#5E01D6] !py-2 !px-4 rounded-lg !text-[#FBFAFF]"
                            >
                                Create a purchase order
                            </Link>
                        </div>
                    ) : (
                        <>
                            <Table
                                className="overflow-hidden"
                                titles={[
                                    { title: "Due date", width: "156px" },
                                    { title: "Order number", width: "184px" },
                                    {
                                        title:
                                            activeTab === "Completed"
                                                ? "Partner"
                                                : activeTab === "Inbound"
                                                ? "Buyer"
                                                : "Supplier",
                                        width: "316px",
                                    },
                                    { title: "Value", width: "220px" },
                                    {
                                        title: activeTab === "Completed" ? "Type" : "Status",
                                        width: "183px",
                                    },
                                    { title: "", width: "minmax(100px, 1fr)" },
                                ]}
                                rows={tabsData[activeTab].map((order) => ({
                                    columns: [
                                        {
                                            value: dayjs(order.deliveryDate).format("MMM M, YYYY"),
                                            className: "text-[#475467] font-normal",
                                        },
                                        {
                                            value: (
                                                <span className="text-main-dark truncate w-full">
                                                    {order?.title}
                                                </span>
                                            ),
                                        },
                                        {
                                            value: (
                                                <>
                                                    {capitalizeWords(
                                                        businessToUse(
                                                            order,
                                                            business,
                                                            activeTab.toLowerCase()
                                                        ).bizName
                                                    )}
                                                    <span className="block text-[#475467] font-normal">
                                                        {capitalizeFirstLetter(
                                                            businessToUse(
                                                                order,
                                                                business,
                                                                activeTab.toLowerCase()
                                                            ).name ??
                                                                businessToUse(
                                                                    order,
                                                                    business,
                                                                    activeTab.toLowerCase()
                                                                ).email
                                                        )}
                                                    </span>
                                                </>
                                            ),
                                            className: "flex flex-col items-start text-main-dark",
                                        },
                                        {
                                            value: `${currencyPairCheck(
                                                order.currency
                                            )} ${formatMoney(order.totalAmount)}`,
                                            className: "text-[#475467] font-normal",
                                        },
                                        {
                                            value:
                                                activeTab === "Completed" ? (
                                                    completedType(order)
                                                ) : (
                                                    <Status
                                                        status={
                                                            updatedStatusOrders[order._id]?.tab ===
                                                            activeTab
                                                                ? updatedStatusOrders[order._id]
                                                                      .status
                                                                : order.orderStatus.toLowerCase()
                                                        }
                                                    />
                                                ),
                                        },
                                        {
                                            value: (
                                                <>
                                                    <span
                                                        onClick={(evt) => {
                                                            evt.stopPropagation();
                                                            viewOrder(order._id);
                                                        }}
                                                    >
                                                        View
                                                    </span>

                                                    <MoreActions
                                                        orderId={order._id}
                                                        tab={activeTab.toLowerCase()}
                                                        updateOrder={(status) =>
                                                            setUpdatedStatusOrders((prev) => ({
                                                                ...prev,
                                                                [order._id]: {
                                                                    tab: activeTab,
                                                                    status: status.toLowerCase(),
                                                                },
                                                            }))
                                                        }
                                                        status={order?.orderStatus?.toLowerCase()}
                                                        disableCancel={
                                                            order?.orderStatus === "CANCELLED" ||
                                                            activeTab !== "Outbound" ||
                                                            updatedStatusOrders[order._id]
                                                                ?.status === "cancelled"
                                                        }
                                                        currency={order.currency}
                                                        wallets={wallets}
                                                        totalAmount={order?.totalAmount}
                                                    />
                                                </>
                                            ),
                                            className:
                                                "flex text-[#1D2939] font-[450] text-sm !gap-3",
                                        },
                                    ],
                                    rowId: order._id,
                                    onRowClick: () => viewOrder(order._id),
                                }))}
                            />
                            <Pagination />
                        </>
                    )}
                </>
            )}
        </div>
    );
};

const Pagination = () => {
    const { screenWidth } = useScreenSize();
    const [active, setActive] = useState(2);
    const midScreen = screenWidth < 768;
    const pagesArr = midScreen ? [1, 2, "...", 9, 10] : [1, 2, 3, "...", 8, 9, 10];

    return (
        <div className="h-10 rounded-lg overflow-hidden grid grid-cols-[repeat(7,40px)] md:grid-cols-[74px,repeat(7,40px),50px] !border !border-[#D0D5DD] w-max mx-auto !mt-3 !mb-4 !text-main text-sm">
            <span className="!border-r grid place-items-center cursor-pointer">
                {midScreen ? <ArrowSquaredIcon /> : "Previous"}
            </span>
            {pagesArr.map((page) => (
                <span
                    key={page}
                    className={`!border-r grid place-items-center ${
                        page === "..." ? "cursor-default pointer-events-none" : "cursor-pointer"
                    } ${active === page && "bg-grey-bg"}`}
                >
                    {page}
                </span>
            ))}
            <span className="grid place-items-center cursor-pointer">
                {midScreen ? <ArrowSquaredIcon className="rotate-180" /> : "Next"}
            </span>
        </div>
    );
};

export default Procurement;
