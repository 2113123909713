import { Link } from "react-router-dom";
import successImg from "asset/images/success-check.svg";
import { CloseIcon } from "asset/icons";

export const SuccessModal = ({ title, text, close, show, link }) => {
    return (
        <div
            className={`bg-white rounded-2xl w-[560px]  transition-all duration-300 cursor-default ${
                show ? "translate-y-0 opacity-100 visible" : "translate-y-[30%] opacity-0 invisible"
            } shadow-[0px_8px_5px_0px_#00000014,0px_20px_13px_0px_#00000008]`}
            onClick={(evt) => evt.stopPropagation()}
        >
            <CloseIcon
                className="*:stroke-main-grey size-[23px] absolute right-6 sm:right-5 top-7 cursor-pointer rounded-full"
                onClick={close}
            />

            <div className="flex max-sm:flex-col max-sm:items-center !p-4 gap-2 !mt-2.5">
                <img
                    alt="Created order"
                    className="text-xs size-16 sm:self-start"
                    src={successImg}
                />
                <h3 className="font-semibold text-[22px] text-left text-[#1F2937] !w-[76%] leading-7">
                    {title}
                    <span className="block text-[#6B7280] !mt-2 font-[350] text-[15px] leading-[23px]">
                        {text}
                    </span>
                </h3>
            </div>

            <div className="flex max-475:flex-col-reverse max-sm:*:w-full !p-5 border-t !border-[#E7E9FB] !mt-4 gap-3">
                <Link
                    className="!bg-secondary ml-auto rounded-lg !py-2 !px-6 !text-[#FBFAFF] font-[450]"
                    to={link}
                    onClick={close}
                >
                    Go to Network
                </Link>
            </div>
        </div>
    );
};

export default SuccessModal;
