import Modal from "react-bootstrap/Modal";
import success from "../asset/images/successicon.svg";
import { useNavigate } from "react-router-dom";
import copy from "../asset/images/copy.svg";
import iconbank2 from "../asset/images/iconinfo.svg";
import { useState } from "react";

function ApiModalSuccess(props) {
  const closeModal = () => {
    var datashow = props.show;
    props.setshow(!datashow);
  };
  const [message, setmessage] = useState("");
  const [showsuccess, setshowsuccess] = useState(false);

  const copyURL1 = async (mytext, key) => {
    try {
      await navigator.clipboard.writeText(mytext);
      setshowsuccess(true);
      setmessage(`${key} Copied successfully`);

      setTimeout(() => {
        setshowsuccess(false);
      }, 2000);
    } catch ($e) {
      console.log($e);
    }
  };
  const navigate = useNavigate();
  return (
    <Modal
      show={props.show}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="border-0 "
      style={{
        width: "70%",
        margin: "0px auto",
        inset: "0% -100%",
      }}
      onHide={() => {
        if (props?.set === "payment") {
          navigate("/payment/home");
        }
        props.setshow(false);
      }}
    >
      <Modal.Body
        style={{
          borderRadius: "16px",
          padding: "0px",
          border: "none",
          background: "#fff",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
          }}
        >
          <div
            className=""
            style={{
              borderBottom: "1px solid #E7E9FB",
              padding: "8px 24px 8px 24px",
            }}
          >
            <span
              className="material-icons"
              style={{ color: "#667085" }}
              onClick={closeModal}
            >
              close
            </span>
          </div>
          <div style={{ padding: "8px 24px 8px 24px" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "32px",
                marginBottom: "10px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  gap: "24px",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <div>
                  <img src={success} alt="" />
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "8px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <h3
                      style={{
                        color: "#101828",
                        fontWeight: "700",
                        lineHeight: "24.3px",
                        fontSize: "18px",
                        textAlign: "center",
                      }}
                    >
                      Token Generated
                    </h3>
                    <div className="w-100  d-flex">
                      <div
                        className=""
                        style={{
                          border: "1px solid #E7E9FB",
                          marginTop: "20px",
                          width: "384px",
                          borderRadius: "8px",
                          padding: "16px",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-between",
                          gap: "24px",
                        }}
                      >
                        <div
                          className="d-flex"
                          style={{
                            flexDirection: "column",
                            gap: "8px",
                          }}
                        >
                          <span
                            style={{
                              color: "#667085",
                              fontSize: "14px",
                              fontWeight: "400",
                              lineHeight: "19.6px",
                            }}
                          >
                            API Token
                          </span>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            <span
                              style={{
                                color: "#101828",
                                fontSize: "16px",
                                fontWeight: "600",
                                lineHeight: "24px",
                                letterSpacing: "2%",
                                textTransform: "capitalize",
                                textAlign: "left",
                              }}
                            >
                              {props.data}
                            </span>
                            <div
                              onClick={() => {
                                copyURL1(`${props.data}`, "API Key");
                              }}
                              style={{ cursor: "pointer" }}
                            >
                              <img src={copy} alt="" />
                            </div>
                          </div>
                        </div>
                        {/**
                  {filteredTransfers.map((transfer, index) => (
                    <TransferDetails
                      key={"EUR"}
                      currency={"EUR"}
                      bankDetails={transfer.bankDetails}
                    />
                  ))}
                   */}
                      </div>
                    </div>
                    <div
                      className="my_account_details"
                      style={{
                        alignItems: "start",
                        background: "#FEE4E2",
                      }}
                    >
                      <img src={iconbank2} alt="" />
                      <div className="text_details_check">
                        <div className="info_imp">Info</div>
                        <span
                          style={{
                            fontSize: "14px",
                          }}
                        >
                          Here is your new API Secret Key. Be sure not to share
                          it with unauthorised persons, online or anywhere else.
                          You can generate a new API Key at any time.
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "8px",
                }}
              >
                <button
                  style={{
                    background: "#6F00FF",
                    color: "#fff",
                    padding: "16px 24px 16px 24px",
                    border: "none",
                    outline: "none",
                    borderRadius: "8px",
                    fontWeight: "600",
                  }}
                  onClick={() => {
                    props.setshow(false);
                  }}
                >
                  Save
                </button>
                {props.method === "transfer" && (
                  <button
                    style={{
                      background: "#6F00FF",
                      color: "#fff",
                      padding: "16px 24px 16px 24px",
                      fontWeight: "600",
                      border: "none",
                      outline: "none",
                      borderRadius: "8px",
                    }}
                  >
                    Track transaction
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default ApiModalSuccess;
