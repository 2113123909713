import { useNavigate } from "react-router-dom";
import empty from "../asset/images/Empty.svg";
const FlexInvoceSuccess = () => {
  const navigates = useNavigate();
  return (
    <div className="suceessinvoice">
      <div className="invoice-drag-empty">
        <div className="main-drag-lg">
          <img
            src={empty}
            alt=""
            style={{
              width: "144px",
              height: "150px",
            }}
          />
          <div className="text-lg-main">
            <h3>Invoice sent!</h3>
            <span>You’ll receive payment updates soon.</span>
          </div>
        </div>
        <button className="goto" onClick={() => navigates("/payment/home")}>
          Go to invoices
        </button>
      </div>
    </div>
  );
};

export default FlexInvoceSuccess;
