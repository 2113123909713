import generateId from "utilities/generateId";

const prod = {
    API_URL: "https://api.tradevu.co",
    APP_URL: "https://app.tradevu.co",
    LOGIN_STORE_NAME: generateId(7),
};

const dev = {
    API_URL: "https://data.tradevu.co",
    APP_URL: "https://dev-app.tradevu.co",
    LOGIN_STORE_NAME: "isLogin",
};

const config = process.env.REACT_APP_STAGE === "production" ? prod : dev;

export default config;
