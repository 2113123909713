import { Helmet } from "react-helmet";

// import {useState} from 'react';
import SerHeader from "../component/serviceheader";
import arrowleft from "../asset/images/arrowleftcircle.svg";
import { useEffect, useState } from "react";
import UploadModal from "../component/uploadModal";
import InvoiceSuccess from "../component/invoicesuccess";

import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { INTEREST } from "../utilities/ut";
import currencyPairCheck from "../utilities/currencyPairCheck";
import AmountCheck from "../component/amountCheck";
import FailHandler from "../component/failhandler";
import Loader from "../component/loader";

const TradeSummary = () => {
    // const [selectedIndex, setselectedIndex] = useState(-1)
    const navigate = useNavigate();
    const [isdisable, setisdisable] = useState(true);
    const { state } = useLocation();
    const [message, setmessage] = useState("");

    const today = new Date();
    useEffect(() => {
        setisdisable(false);
    }, [setisdisable]);
    const [filename, setfilename] = useState(null);
    const [filesize, setfilesize] = useState(0);
    const [showModal, setshowModal] = useState(false);
    const [showModal3, setshowModal3] = useState(false);
    const [tfess, settess] = useState(0);
    const [failhandler, setfailhandler] = useState(false);
    const [loader, setloader] = useState(false);

    useEffect(() => {
        if (loader) {
            setTimeout(() => {
                setloader(false);
            }, 3000);
        }
    }, [loader]);

    const SubmitDocument = async () => {
        axios
            .post(`/v1/finance/request_trade_credit/verification/${state._id}`)
            .then(() => {
                setshowModal3(true);
            })
            .catch((e) => {
                console.log(e);
                setloader(false);

                if (!e.response) {
                    // Network error
                    setmessage("Network error: Please check your internet connection.");
                } else {
                    // Server error
                    setmessage(e.response.data.message);
                }

                setfailhandler(true);
            });
        //setloader(false);
        //setshowModal3(true);
    };
    INTEREST()
        .then((res) => {
            //  console.log(res);
            settess(res.data.data.financeWorkingCapitalInterest);
        })
        .catch((e) => {
            console.log(e);
        });
    return (
        <div className="h-100 w-100 ">
            <div
                className=""
                style={{
                    maxWidth: "2000px",
                    width: "100%",
                    margin: "0px auto",
                }}
            >
                <Helmet>
                    <title>Trade of credit</title>
                </Helmet>

                <SerHeader header={"Trade Credit"} width={100} />
                <UploadModal
                    filesize={filesize}
                    show={showModal}
                    setshow={setshowModal}
                    filename={filename}
                    setfilesize={setfilesize}
                    setfilename={setfilename}
                />
                <InvoiceSuccess
                    show={showModal3}
                    set={"finance"}
                    currency={state.currency}
                    amount={state.requestedAmount}
                    setshow={setshowModal3}
                />
            </div>
            <div style={{ height: "100%" }}>
                <div
                    className="conatiner "
                    style={{
                        maxWidth: "2000px",
                        margin: "0px auto",
                        paddingLeft: "30px",
                        paddingRight: "30px",
                        paddingTop: "10px",
                        paddingBottom: "10px",
                    }}
                >
                    <div
                        style={{
                            height: "100%",
                            width: "100%",
                            display: "flex",

                            justifyContent: "center",
                        }}
                    >
                        <div className="form-general-layout">
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: "32px",
                                    maxWidth: "528px",
                                }}
                            >
                                <FailHandler success={failhandler} message={message} />

                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        gap: "16px",
                                    }}
                                >
                                    <div
                                        onClick={() => {
                                            navigate(-1);
                                        }}
                                    >
                                        <img src={arrowleft} alt="" />
                                    </div>
                                    <div>
                                        <div
                                            style={{
                                                color: "#101828",
                                                fontWeight: "600",
                                                fontSize: "24px",
                                                lineHeight: "32.4px",
                                            }}
                                        >
                                            Summary
                                        </div>
                                        <div
                                            style={{
                                                color: "#667085",
                                                fontSize: "16px",
                                                lineHeight: "24px",
                                                letterSpacing: "2%",
                                                fontWeight: "500",
                                                maxWidth: "600px",
                                                width: "100%",
                                            }}
                                        >
                                            Please review your details before you continue.
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: "16px",
                                }}
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        gap: "8px",
                                    }}
                                >
                                    <label
                                        htmlFor=""
                                        style={{
                                            color: "#667085",
                                            fontWeight: "500",
                                            lineHeight: "19.6px",
                                            fontSize: "14px",
                                        }}
                                    >
                                        Loan details
                                    </label>
                                    <div
                                        style={{
                                            background: "#fff",
                                            borderRadius: "8px",
                                            flexDirection: "column",
                                            display: "flex",
                                        }}
                                    >
                                        <div style={{ padding: "16px" }}>
                                            <div
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    gap: "4px",
                                                }}
                                            >
                                                <span
                                                    style={{
                                                        style: "#6F00FF",
                                                        fontWeight: "500",
                                                        fontSize: "12px",
                                                        lineHeight: "16.2px",
                                                        letterSpacing: "2%",
                                                    }}
                                                >
                                                    Credit amount
                                                </span>
                                                <span
                                                    style={{
                                                        color: "#344054",
                                                        fontSize: "20px",
                                                        lineHeight: "24px",
                                                        fontWeight: "600",
                                                    }}
                                                >
                                                    {currencyPairCheck(state.currency)}
                                                    {AmountCheck(state.requestedAmount)}
                                                </span>
                                            </div>
                                        </div>

                                        <div
                                            style={{
                                                padding: "16px",
                                                display: "flex",
                                                justifyContent: "space-between",
                                                alignItems: "center",
                                            }}
                                        >
                                            <div
                                                style={{
                                                    gap: "8px",
                                                    display: "flex",
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        gap: "4px",
                                                    }}
                                                >
                                                    <span
                                                        style={{
                                                            style: "#667085",
                                                            fontWeight: "500",
                                                            fontSize: "14px",
                                                            lineHeight: "19.6px",
                                                        }}
                                                    >
                                                        Interest ({tfess}%)
                                                    </span>
                                                    <span
                                                        style={{
                                                            color: "#344054",
                                                            fontSize: "14px",
                                                            lineHeight: "19.6px",
                                                            fontWeight: "600",
                                                        }}
                                                    >
                                                        {currencyPairCheck(state.currency)}
                                                        {tfess * parseInt(state.requestedAmount)}
                                                    </span>
                                                </div>
                                            </div>
                                            <div
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    gap: "4px",
                                                }}
                                            >
                                                <span
                                                    style={{
                                                        style: "#667085",
                                                        fontWeight: "500",
                                                        fontSize: "12px",
                                                        lineHeight: "19.6px",
                                                    }}
                                                >
                                                    Repay date
                                                </span>
                                                <span
                                                    style={{
                                                        color: "#101828",
                                                        fontSize: "14px",
                                                        lineHeight: "19.6px",
                                                        fontWeight: "600",
                                                    }}
                                                >
                                                    {new Date(
                                                        today.setDate(
                                                            today.getDate() + parseInt(state.tenure)
                                                        )
                                                    ).toDateString()}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        gap: "8px",
                                    }}
                                >
                                    <label
                                        htmlFor=""
                                        style={{
                                            color: "#667085",
                                            fontWeight: "500",
                                            lineHeight: "19.6px",
                                            fontSize: "14px",
                                        }}
                                    >
                                        Documents
                                    </label>
                                    <div
                                        style={{
                                            background: "#fff",
                                            borderRadius: "8px",
                                            flexDirection: "column",
                                            display: "flex",
                                        }}
                                    >
                                        {state.type !== "cashflow" && (
                                            <div
                                                style={{
                                                    padding: "16px",
                                                    display: "flex",
                                                    justifyContent: "space-between",
                                                    alignItems: "center",
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        gap: "8px",
                                                        display: "flex",
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            flexDirection: "column",
                                                        }}
                                                    >
                                                        <span
                                                            style={{
                                                                style: "#667085",
                                                                fontWeight: "500",
                                                                fontSize: "14px",
                                                                lineHeight: "19.6px",
                                                            }}
                                                        >
                                                            Invoice
                                                        </span>
                                                    </div>
                                                </div>
                                                <div
                                                    style={{
                                                        color: "#6F00FF",
                                                        letterSpacing: "2%",
                                                        fontWeight: "600",
                                                        fontSize: "16px",
                                                        lineHeight: "24px",
                                                        display: "flex",
                                                        alignItems: "center",
                                                        gap: "8px",
                                                        textAlign: "right",
                                                        maxWidth: "180px",
                                                        overflow: "hidden",
                                                        textOverflow: "ellipsis",
                                                        whiteSpace: "nowrap",
                                                    }}
                                                >
                                                    {state.invoice.name}
                                                </div>
                                            </div>
                                        )}
                                        {state.type === "cashflow" && (
                                            <div
                                                style={{
                                                    padding: "16px",
                                                    display: "flex",
                                                    justifyContent: "space-between",
                                                    alignItems: "center",
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        gap: "8px",
                                                        display: "flex",
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            flexDirection: "column",
                                                        }}
                                                    >
                                                        <span
                                                            style={{
                                                                style: "#667085",
                                                                fontWeight: "500",
                                                                fontSize: "14px",
                                                                lineHeight: "19.6px",
                                                            }}
                                                        >
                                                            Management account
                                                        </span>
                                                    </div>
                                                </div>
                                                <div
                                                    style={{
                                                        color: "#6F00FF",
                                                        letterSpacing: "2%",
                                                        fontWeight: "600",
                                                        fontSize: "16px",
                                                        lineHeight: "24px",
                                                        display: "flex",
                                                        alignItems: "center",
                                                        gap: "8px",
                                                        textAlign: "right",
                                                        maxWidth: "180px",
                                                        overflow: "hidden",
                                                        textOverflow: "ellipsis",
                                                        whiteSpace: "nowrap",
                                                    }}
                                                >
                                                    {state.managemnent.name}
                                                </div>
                                            </div>
                                        )}
                                        {state.type === "cashflow" && (
                                            <div
                                                style={{
                                                    padding: "16px",
                                                    display: "flex",
                                                    justifyContent: "space-between",
                                                    alignItems: "center",
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        gap: "8px",
                                                        display: "flex",
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            flexDirection: "column",
                                                        }}
                                                    >
                                                        <span
                                                            style={{
                                                                style: "#667085",
                                                                fontWeight: "500",
                                                                fontSize: "14px",
                                                                lineHeight: "19.6px",
                                                            }}
                                                        >
                                                            Bank Statement
                                                        </span>
                                                    </div>
                                                </div>
                                                <div
                                                    style={{
                                                        color: "#6F00FF",
                                                        letterSpacing: "2%",
                                                        fontWeight: "600",
                                                        fontSize: "16px",
                                                        lineHeight: "24px",
                                                        display: "flex",
                                                        alignItems: "center",
                                                        gap: "8px",
                                                        textAlign: "right",
                                                        maxWidth: "180px",
                                                        overflow: "hidden",
                                                        textOverflow: "ellipsis",
                                                        whiteSpace: "nowrap",
                                                    }}
                                                >
                                                    {state.bank.name}
                                                </div>
                                            </div>
                                        )}

                                        <div
                                            style={{
                                                padding: "16px",
                                                display: "flex",
                                                justifyContent: "space-between",
                                                alignItems: "center",
                                            }}
                                        >
                                            <div
                                                style={{
                                                    gap: "8px",
                                                    display: "flex",
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                    }}
                                                >
                                                    <span
                                                        style={{
                                                            style: "#667085",
                                                            fontWeight: "500",
                                                            fontSize: "14px",
                                                            lineHeight: "19.6px",
                                                        }}
                                                    >
                                                        Supplier aggreement
                                                    </span>
                                                </div>
                                            </div>
                                            <div
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    gap: "4px",
                                                }}
                                            >
                                                <span
                                                    style={{
                                                        color: "#6F00FF",
                                                        letterSpacing: "2%",
                                                        fontWeight: "600",
                                                        fontSize: "16px",
                                                        lineHeight: "24px",
                                                        display: "flex",
                                                        alignItems: "center",
                                                        gap: "8px",
                                                        textAlign: "right",
                                                        maxWidth: "180px",
                                                        overflow: "hidden",
                                                        textOverflow: "ellipsis",
                                                        whiteSpace: "nowrap",
                                                    }}
                                                >
                                                    {state.aggreement.name}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <button
                                disabled={isdisable}
                                onClick={() => {
                                    SubmitDocument();
                                }}
                                variant="primary"
                                style={{
                                    maxWidth: "280px",
                                    width: "100%",
                                    background: isdisable ? "#EBE4FF" : "#6F00FF",
                                    padding: "16px 24px 16px 24px",
                                    border: "none",
                                    outline: "none",
                                    borderRadius: "8px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    fontSize: "16px",
                                    lineHeight: "24px",
                                    letterSpacing: "2%",
                                    fontWeight: "600",
                                    color: isdisable ? "#BFA6FF" : "#ffff",
                                }}
                            >
                                {loader ? <Loader /> : "Submit"}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default TradeSummary;
