const Transaction = ({
    title,
    status,
    time,
    amount,
    balance,
    balanceType,
    onClick,
    invoiceType,
    type,
}) => {
    return (
        <div className="flex justify-between !py-5 cursor-pointer" onClick={onClick}>
            <p className="font-medium text-main-dark capitalize flex-1 mr-3">
                {title}
                <span className="flex mt-0.5 text-[#475467] font-normal text-sm items-center">
                    {time}
                    {status && (
                        <>
                            <span className="rounded-full size-[3px] bg-main-grey mx-[6px]" />
                            <span
                                className={`capitalize ${
                                    invoiceType
                                        ? `${
                                              status === "declined"
                                                  ? "text-[#F04438]"
                                                  : status === "unpaid"
                                                  ? "text-[#F79009]"
                                                  : "text-[#12B76A]"
                                          }`
                                        : `${
                                              status === "pending"
                                                  ? "text-[#E4B200]"
                                                  : status === "success"
                                                  ? "text-[#039855]"
                                                  : "text-[#F73C3C]"
                                          }`
                                }`}
                            >
                                {status}
                            </span>
                        </>
                    )}
                </span>
            </p>

            <p
                className={`text-right font-medium !w-max ${
                    !invoiceType &&
                    `${
                        balanceType === "+"
                            ? "text-[#11A811]"
                            : balanceType === "-"
                            ? "text-[#F73C3C]"
                            : ""
                    }`
                }`}
            >
                {invoiceType ? (
                    <>{amount}</>
                ) : (
                    <>
                        {balanceType} {amount}{" "}
                    </>
                )}
                <span className="block text-main-grey font-normal">
                    {invoiceType ? type : balance}
                </span>
            </p>
        </div>
    );
};

export default Transaction;
