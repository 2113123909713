const { CheckIcon } = require("asset/icons");

const DropdownCheckItem = ({ text, checked, className, onClick }) => (
    <p
        className={`!px-4 !py-3 w-full text-left flex items-center gap-2 ${className}`}
        onClick={(evt) => {
            evt.stopPropagation();
            onClick();
        }}
    >
        <span
            className={`!border !border-[#D0D5DD] rounded-[3px] size-3.5 ${
                checked && "grid place-items-center"
            }`}
        >
            {checked && <CheckIcon />}
        </span>
        {text}
    </p>
);

export default DropdownCheckItem;
