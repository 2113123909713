import { Helmet } from "react-helmet";
// import DashHeader from "../component/dashheader";
// import homefinance from '../asset/im
import { useEffect, useState } from "react";

import SerHeader from "../component/serviceheader";
import arrowleft from "../asset/images/arrowleftcircle.svg";
import edit from "../asset/images/edit-2.svg";
import person from "../asset/images/person.svg";
import copy from "../asset/images/document-upload.png";
import UploadInvoice from "../component/uploadinvoice";
import nigeria from "../asset/images/countries/ngn.svg";
import { currencies } from "../constants/currency";
import UploadModal from "../component/uploadModal";
import Uploaddocumentfinance from "../component/uploadloaddocument";
import InvoiceSuccess from "../component/invoicesuccess";
import { useLocation, useNavigate } from "react-router-dom";
//import Swal from "sweetalert2/dist/sweetalert2.js";
import SuccessHodler from "../component/successholder";
import FailHandler from "../component/failhandler";

import axios from "axios";
import Loaders from "../component/loader";
import UploadInvoiceCreateFile from "../component/uploadinvoicefilecreate";
import Uploadagrremtfile from "../component/uploadcreateinvoicefile";

const InvicePreviewDocument = () => {
    // const [selectedIndex, setselectedIndex] = useState(-1)
    const navigate = useNavigate();

    const [show, setShow] = useState(false);
    const [loader, setloader] = useState(false);
    const [selecteditem, setselecteditem] = useState([]);
    const [selectedcurrency, setselectedcurrency] = useState([]);
    const [added, setadded] = useState(false);
    const [filename, setfilename] = useState([]);
    const [filesize, setfilesize] = useState(0);
    const [aggreement, setagrrement] = useState([]);
    const [aggreementsize, setaggreementsize] = useState(0);
    const [showModal, setshowModal] = useState(false);
    const [showModal2, setshowModal2] = useState(false);
    const [showModal3, setshowModal3] = useState(false);
    const [defaultcurrency] = useState("NGN");
    const [businessname, setbusinessname] = useState("");
    const [buyer, setbuyers] = useState([]);
    const [supplier, setsuppliers] = useState([]);
    const [isdisable, setisdisable] = useState(true);
    const [amount, setamount] = useState("");
    const [tab, settab] = useState("buyer");
    const [success, setsuccess] = useState(false);
    const [failhandler, setfailhandler] = useState(false);
    const [message, setmessage] = useState(" ");
    const { state } = useLocation();

    const [showdrop, setisshowdrop] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const addseletedcurrency = (currency) => {
        setadded(true);

        setselectedcurrency(currency);
    };
    useEffect(() => {
        const fetchId = async () => {};
        fetchId();
    }, []);
    /*
  useEffect(() => {
    const fetchbuyer = async () => {
      await axios
        .get("v1/flex/buyers")

        .then((res) => {
          setbuyers(res.data.data);
        })
        .catch((e) => {
          console.log(e);
        });
    };
    fetchbuyer();
  }, []);
  */
    /*
  useEffect(() => {
    const fetchsupplier = async () => {
      await axios
        .get("v1/flex/suppliers")

        .then((res) => {
          setsuppliers(res.data.data);
        })
        .catch((e) => {
          console.log(e);
        });
    };
    fetchsupplier();
  }, []);
  */
    useEffect(() => {
        let timer = setTimeout(() => {
            setfailhandler(false);
            setsuccess(false);
        }, 3000);
        return () => {
            clearTimeout(timer);
        };
    });
    useEffect(() => {
        if (aggreement && filename) {
            setisdisable(false);
        } else {
            setisdisable(true);
        }
    }, [setisdisable, selectedcurrency, selecteditem, amount, aggreement, filename]);
    //console.log(state);

    useEffect(() => {
        const getBusinessId = async () => {
            await axios
                .get(`/v1/business/${state?.business}`)
                .then((res) => {
                    // console.log(res);
                    setbusinessname(res.data.data.business.name);
                })
                .catch((e) => {
                    console.log(e);
                });
        };
        getBusinessId();
    }, []);
    // console.log(aggreement.length);
    const submitHandler = async (e) => {
        e.preventDefault();
        if (aggreement.length === undefined) {
            e.preventDefault();
            setloader(true);
            // console.log(selecteditem);
            const formdata = new FormData();
            formdata.append("agreement", aggreement, aggreement.name);
            // formdata.append("invoiceDocument", filename, filename.name);
            await axios
                .post(`/v1/finance/finance_invoice/upload`, formdata)
                .then((res) => {
                    //  console.log(res);
                    axios
                        .post(`/v1/finance/finance_invoice/create/${res.data.data._id}`, {
                            firstName: state?.firstName,
                            lastName: state?.lastName,
                            phoneNumber: state?.phoneNumber,
                            email: state?.email,
                            userType: state.userType,
                            requestedAmount: state.amount,
                            currency: state?.currency,
                            businessName: businessname,
                        })
                        .then(() => {
                            axios.post(
                                `/v1/finance/request_invoice/verification/${res.data.data._id}`
                            );
                            setloader(false);
                            setshowModal3(true);
                        });
                })
                .catch((e) => {
                    setloader(false);

                    setmessage(
                        e.response?.data.message ? e.response?.data.message : "An error ocurred"
                    );
                    setfailhandler(true);
                });
        } else {
            setfailhandler(true);
            setmessage("Please upload the agreement details");
        }
    };
    return (
        <div
            className="h-100 w-100 "
            style={{
                background: "#f6f6f6",
            }}
        >
            <div
                className=""
                style={{
                    maxWidth: "2000px",
                    width: "100%",
                    margin: "0px auto",
                }}
            >
                <Helmet>
                    <title>Invoice</title>
                </Helmet>

                <SerHeader header={"Invoice Factoring"} />
                <InvoiceSuccess
                    set={"finance"}
                    show={showModal3}
                    setshow={setshowModal3}
                    amount={amount}
                    currency={selectedcurrency.length === 0 ? "NGN" : selectedcurrency.title}
                />
                <Uploadagrremtfile
                    show={showModal2}
                    setshow={setshowModal2}
                    filename={aggreement}
                    filesize={aggreementsize}
                    setfilesize={setaggreementsize}
                    setfilename={setagrrement}
                />
                <UploadInvoiceCreateFile
                    filesize={filesize}
                    show={showModal}
                    setshow={setshowModal}
                    filename={filename}
                    setfilesize={setfilesize}
                    setfilename={setfilename}
                />
                <UploadInvoice
                    show={show}
                    handle={handleClose}
                    selecteditem={selecteditem}
                    setbusinessname={setbusinessname}
                    setselecteditem={setselecteditem}
                />
            </div>
            <div style={{ height: "100%" }}>
                <form
                    className="conatiner "
                    onSubmit={submitHandler}
                    style={{
                        maxWidth: "2000px",
                        margin: "0px auto",
                        paddingLeft: "30px",
                        paddingRight: "30px",
                        paddingTop: "10px",
                        paddingBottom: "10px",
                        width: "100%",
                    }}
                >
                    <div
                        style={{
                            height: "100%",
                            width: "100%",
                            display: "flex",

                            justifyContent: "center",
                        }}
                    >
                        <div
                            className="form-general-layout"
                            style={{
                                position: "relative",
                            }}
                        >
                            <SuccessHodler success={success} message={message} />
                            <FailHandler success={failhandler} message={message} />
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: "32px",
                                }}
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        gap: "16px",
                                    }}
                                >
                                    <div
                                        onClick={() => {
                                            navigate(-1);
                                        }}
                                    >
                                        <img src={arrowleft} alt="" />
                                    </div>
                                    <div
                                        style={{
                                            color: "#101828",
                                            fontWeight: "600",
                                            fontSize: "24px",
                                            lineHeight: "32.4px",
                                        }}
                                    >
                                        Other Documents
                                    </div>
                                </div>
                                <div
                                    className=""
                                    style={{
                                        display: "flex",
                                        gap: "24px",
                                        flexDirection: "column",
                                    }}
                                >
                                    {tab === "buyer" && (
                                        <>
                                            {/**
                      <div
                        className=""
                        style={{
                          display: "flex",
                          gap: "8px",
                          flexDirection: "column",
                        }}
                      >
                        <label
                          htmlFor=""
                          style={{
                            color: "#344054",
                            fontWeight: "500",
                            lineHeight: "19.6px",
                            fontSize: "14px",
                          }}
                        >
                          Upload invoice
                        </label>
                        <div style={{ position: "relative" }}>
                          <div
                            style={{
                              width: "100%",
                              borderRadius: "8px",
                              border: "none",
                              outline: "none",
                              background: "",
                              minHeight: "56px",
                              padding: "8px 16px 8px 16px",
                              backgroundColor: "#ECEFF3",
                              letterSpacing: "2%",
                              fontWeight: "400",
                              fontSize: "16px",
                              position: "relative",
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                            variant="primary"
                            onClick={() => {
                              setfilename([]);
                              setfilesize(0);
                              setshowModal(true);
                            }}
                          >
                            <span
                              style={{
                                color: "#667085",
                                fontSize: "16px",
                                lineHeight: "24px",
                                letterSpacing: "2%",
                              }}
                            >
                              {" "}
                              {filename?.length !== 0 && filename?.name}{" "}
                              {filename?.length !== 0 &&
                                parseFloat(filesize / 1024).toFixed(2)}
                              {filename?.length !== 0 && (
                                <>
                                  {filesize !== 0 && filesize >= 1024
                                    ? "MB"
                                    : "KB"}
                                </>
                              )}
                              {filename?.length === 0 &&
                                "Tap to upload invoice"}{" "}
                            </span>
                            <img src={copy} alt="" height={20} width={20} />
                          </div>
                        </div>
                      </div>
                      */}
                                            <div
                                                className=""
                                                style={{
                                                    display: "flex",
                                                    gap: "8px",
                                                    flexDirection: "column",
                                                }}
                                            >
                                                <label
                                                    htmlFor=""
                                                    style={{
                                                        color: "#344054",
                                                        fontWeight: "600",
                                                        lineHeight: "19.6px",
                                                        fontSize: "14px",
                                                    }}
                                                >
                                                    Upload customer agreement
                                                </label>
                                                <div style={{ position: "relative" }}>
                                                    <div
                                                        style={{
                                                            width: "100%",
                                                            borderRadius: "8px",
                                                            border: "none",
                                                            outline: "none",
                                                            background: "",
                                                            minHeight: "56px",
                                                            padding: "8px 16px 8px 16px",
                                                            backgroundColor: "#ECEFF3",
                                                            letterSpacing: "2%",
                                                            fontWeight: "400",
                                                            fontSize: "16px",
                                                            position: "relative",
                                                            display: "flex",
                                                            justifyContent: "space-between",
                                                            alignItems: "center",
                                                        }}
                                                        variant="primary"
                                                        onClick={() => {
                                                            setaggreementsize(0);
                                                            setagrrement([]);

                                                            setshowModal2(true);
                                                        }}
                                                    >
                                                        <span
                                                            style={{
                                                                color: "#667085",
                                                                fontSize: "16px",
                                                                lineHeight: "24px",
                                                                letterSpacing: "2%",
                                                            }}
                                                        >
                                                            {" "}
                                                            {aggreement.length !== 0 &&
                                                                aggreement.name}
                                                            {aggreement.length !== 0 &&
                                                                parseFloat(
                                                                    aggreementsize / 1024
                                                                ).toFixed(2)}
                                                            {aggreement.length !== 0 && (
                                                                <>
                                                                    {aggreementsize !== 0 &&
                                                                    aggreementsize >= 1024
                                                                        ? "MB"
                                                                        : "KB"}
                                                                </>
                                                            )}
                                                            {aggreement.length === 0 &&
                                                                "Upload document"}{" "}
                                                        </span>
                                                        <img
                                                            src={copy}
                                                            alt=""
                                                            height={20}
                                                            width={20}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="mb-5">
                                                <button
                                                    disabled={isdisable}
                                                    type="submit"
                                                    style={{
                                                        width: "280px",
                                                        background: isdisable
                                                            ? "#EBE4FF"
                                                            : "#6F00FF",
                                                        height: "48px",
                                                        padding: "16px 24px 16px 24px",

                                                        border: "none",
                                                        outline: "none",
                                                        borderRadius: "8px",
                                                        fontSize: "16px",
                                                        lineHeight: "24px",
                                                        letterSpacing: "2%",
                                                        fontWeight: "700",
                                                        color: isdisable ? "#BFA6FF" : "#ffff",
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    {loader === true ? (
                                                        <Loaders data={loader} />
                                                    ) : (
                                                        "Submit"
                                                    )}
                                                </button>
                                            </div>
                                        </>
                                    )}
                                    {tab === "supplier" && (
                                        <>
                                            <>
                                                <div
                                                    className=""
                                                    style={{
                                                        display: "flex",
                                                        gap: "8px",
                                                        flexDirection: "column",
                                                    }}
                                                >
                                                    <label
                                                        htmlFor=""
                                                        style={{
                                                            color: "#344054",
                                                            fontWeight: "500",
                                                            lineHeight: "19.6px",
                                                            fontSize: "14px",
                                                        }}
                                                    >
                                                        Supplier
                                                    </label>
                                                    {selecteditem.length === 0 && (
                                                        <div
                                                            style={{
                                                                background: "#fff",
                                                                display: "flex",
                                                                justifyContent: "space-between",
                                                                padding: "8px",
                                                                borderRadius: "8px",
                                                                alignItems: "center",
                                                                minHeight: "56px",
                                                            }}
                                                            variant="primary"
                                                            onClick={handleShow}
                                                        >
                                                            <div
                                                                style={{
                                                                    display: "flex",
                                                                    gap: "8px",
                                                                    alignItems: "center",
                                                                }}
                                                            >
                                                                <img src={person} alt="" />
                                                                <span
                                                                    style={{
                                                                        color: "#101828",
                                                                        letterSpacing: "2%",
                                                                        lineHeight: "24px",
                                                                        fontSize: "16px",
                                                                        fontWeight: 400,
                                                                    }}
                                                                >
                                                                    Enter supplier's details
                                                                </span>
                                                            </div>
                                                            <img src={edit} alt="" />
                                                        </div>
                                                    )}
                                                    {selecteditem.length > 0 &&
                                                        selecteditem.map((items, index) => (
                                                            <div
                                                                style={{
                                                                    background: "#fff",
                                                                    display: "flex",
                                                                    justifyContent: "space-between",
                                                                    padding: "8px",
                                                                    borderRadius: "8px",
                                                                    alignItems: "center",
                                                                }}
                                                                key={index}
                                                                variant="primary"
                                                                onClick={handleShow}
                                                            >
                                                                <div
                                                                    style={{
                                                                        display: "flex",
                                                                        gap: "8px",
                                                                    }}
                                                                >
                                                                    <div
                                                                        style={{
                                                                            width: "40px",
                                                                            height: "40px",
                                                                            borderRadius: "250px",
                                                                            alignItems: "center",
                                                                            justifyContent:
                                                                                "ceneter",
                                                                            background: "#F4F0FF",
                                                                            textAlign: "center",
                                                                            display: "flex",
                                                                        }}
                                                                    >
                                                                        <div
                                                                            style={{
                                                                                color: "#7F56D9",
                                                                                fontWeight: "500",
                                                                                fontSize: "14px",
                                                                                lineHeight: "20px",
                                                                                textAlign: "center",
                                                                                textTransform:
                                                                                    "uppercase",
                                                                                width: "100%",
                                                                            }}
                                                                        >
                                                                            {" "}
                                                                            {items.firstName.charAt(
                                                                                0
                                                                            )}
                                                                            {items.lastName.charAt(
                                                                                0
                                                                            )}{" "}
                                                                        </div>
                                                                    </div>
                                                                    <div
                                                                        style={{
                                                                            display: "flex",
                                                                            flexDirection: "column",
                                                                        }}
                                                                    >
                                                                        <span
                                                                            style={{
                                                                                color: "#101828",
                                                                                letterSpacing: "2%",
                                                                                lineHeight: "18px",
                                                                                fontSize: "16px",
                                                                                fontWeight: 400,
                                                                            }}
                                                                        >
                                                                            {" "}
                                                                            {items.firstName}
                                                                            {items.lastName}{" "}
                                                                        </span>
                                                                        <span
                                                                            style={{
                                                                                color: "#667085",
                                                                                fontSize: "14px",
                                                                                fontWeight: "400",
                                                                                lineHeight:
                                                                                    "19.6px",
                                                                            }}
                                                                        >
                                                                            {" "}
                                                                            {items.email}{" "}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <img src={edit} alt="" />
                                                            </div>
                                                        ))}{" "}
                                                </div>
                                                <div
                                                    className=""
                                                    style={{
                                                        display: "flex",
                                                        gap: "8px",
                                                        flexDirection: "column",
                                                    }}
                                                >
                                                    <label
                                                        htmlFor=""
                                                        style={{
                                                            color: "#344054",
                                                            fontWeight: "500",
                                                            lineHeight: "19.6px",
                                                            fontSize: "14px",
                                                        }}
                                                    >
                                                        Requested amount
                                                    </label>
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            gap: "8px",
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                maxWidth: "96px",
                                                                width: "100%",
                                                                borderRadius: "8px",
                                                                border: "none",
                                                                outline: "none",
                                                                background: "",
                                                                padding: "8px 16px 8px 16px",
                                                                backgroundColor: "#ECEFF3",
                                                                letterSpacing: "2%",
                                                                fontWeight: "400",
                                                                fontSize: "16px",
                                                                display: "flex",
                                                                gap: "4px",
                                                                alignItems: "center",
                                                                position: "relative",
                                                            }}
                                                            onClick={() => {
                                                                setisshowdrop(!showdrop);
                                                            }}
                                                        >
                                                            {" "}
                                                            {showdrop === true && (
                                                                <div
                                                                    style={{
                                                                        position: "absolute",
                                                                        background: "#fff",
                                                                        padding: "8px",
                                                                        width: "100%",
                                                                        top: "40px",
                                                                        left: "-2px",
                                                                        borderRadius: "4px",
                                                                        zIndex: "2",
                                                                    }}
                                                                >
                                                                    <div
                                                                        style={{
                                                                            display: "flex",
                                                                            flexDirection: "column",
                                                                            gap: "16px",
                                                                        }}
                                                                    >
                                                                        {" "}
                                                                        {currencies.map(
                                                                            (currency, index) => (
                                                                                <div
                                                                                    style={{
                                                                                        display:
                                                                                            "flex",
                                                                                        gap: "8px",
                                                                                        lineHeight:
                                                                                            "19.6px",
                                                                                        cursor: "pointer",
                                                                                    }}
                                                                                    key={index}
                                                                                    onClick={() => {
                                                                                        addseletedcurrency(
                                                                                            currency
                                                                                        );
                                                                                    }}
                                                                                >
                                                                                    <img
                                                                                        src={
                                                                                            currency.image
                                                                                        }
                                                                                        alt=""
                                                                                        height={24}
                                                                                        width={24}
                                                                                    />
                                                                                    <span
                                                                                        style={{
                                                                                            color: "#667085",
                                                                                            letterSpacing:
                                                                                                "2%",
                                                                                            lineHeight:
                                                                                                "24px",
                                                                                            fontSize:
                                                                                                "16px",
                                                                                        }}
                                                                                    >
                                                                                        {
                                                                                            currency.title
                                                                                        }
                                                                                    </span>
                                                                                </div>
                                                                            )
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            )}
                                                            <img
                                                                src={
                                                                    added
                                                                        ? selectedcurrency.image
                                                                        : nigeria
                                                                }
                                                                alt=""
                                                                height={24}
                                                                width={24}
                                                            />
                                                            <span
                                                                style={{
                                                                    color: "#667085",
                                                                    letterSpacing: "2%",
                                                                    lineHeight: "24px",
                                                                    fontSize: "16px",
                                                                }}
                                                            >
                                                                {added === true
                                                                    ? selectedcurrency.title
                                                                    : "NGN"}
                                                            </span>
                                                        </div>
                                                        <input
                                                            type="text"
                                                            name=""
                                                            id=""
                                                            onChange={(e) => {
                                                                setamount(e.target.value);
                                                            }}
                                                            style={{
                                                                width: "85%",
                                                                borderRadius: "8px",
                                                                border: "none",
                                                                outline: "none",
                                                                height: "56px",
                                                                background: "",
                                                                padding: "8px 16px 8px 16px",
                                                                backgroundColor: "#ECEFF3",
                                                                letterSpacing: "2%",
                                                                fontWeight: "400",
                                                                fontSize: "16px",
                                                            }}
                                                            placeholder="Enter amount"
                                                        />
                                                    </div>
                                                </div>
                                                <div
                                                    className=""
                                                    style={{
                                                        display: "flex",
                                                        gap: "8px",
                                                        flexDirection: "column",
                                                    }}
                                                >
                                                    <label
                                                        htmlFor=""
                                                        style={{
                                                            color: "#344054",
                                                            fontWeight: "600",
                                                            lineHeight: "19.6px",
                                                            fontSize: "14px",
                                                        }}
                                                    >
                                                        Other Documents
                                                    </label>
                                                    <div style={{ position: "relative" }}>
                                                        <div
                                                            style={{
                                                                width: "100%",
                                                                borderRadius: "8px",
                                                                border: "none",
                                                                outline: "none",
                                                                background: "",
                                                                minHeight: "56px",
                                                                padding: "8px 16px 8px 16px",
                                                                backgroundColor: "#ECEFF3",
                                                                letterSpacing: "2%",
                                                                fontWeight: "400",
                                                                fontSize: "16px",
                                                                position: "relative",
                                                                display: "flex",
                                                                justifyContent: "space-between",
                                                                alignItems: "center",
                                                            }}
                                                            variant="primary"
                                                            onClick={() => {
                                                                setfilename(null);
                                                                setfilesize(0);

                                                                setshowModal(true);
                                                            }}
                                                        >
                                                            <span
                                                                style={{
                                                                    color: "#667085",
                                                                    fontSize: "16px",
                                                                    lineHeight: "24px",
                                                                    letterSpacing: "2%",
                                                                }}
                                                            >
                                                                {" "}
                                                                {filename !== null &&
                                                                    filename}-{" "}
                                                                {filename !== null &&
                                                                    parseFloat(
                                                                        filesize / 1024
                                                                    ).toFixed(2)}
                                                                {filesize !== 0 &&
                                                                parseFloat(filesize / 1024) >= 1024
                                                                    ? "MB"
                                                                    : "KB"}
                                                                {filename === null &&
                                                                    "Tap to upload invoice"}{" "}
                                                            </span>
                                                            <img
                                                                src={copy}
                                                                alt=""
                                                                height={20}
                                                                width={20}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                    className=""
                                                    style={{
                                                        display: "flex",
                                                        gap: "8px",
                                                        flexDirection: "column",
                                                    }}
                                                >
                                                    <label
                                                        htmlFor=""
                                                        style={{
                                                            color: "#344054",
                                                            fontWeight: "600",
                                                            lineHeight: "19.6px",
                                                            fontSize: "14px",
                                                        }}
                                                    >
                                                        Upload customer agreement
                                                    </label>
                                                    <div style={{ position: "relative" }}>
                                                        <div
                                                            style={{
                                                                width: "100%",
                                                                borderRadius: "8px",
                                                                border: "none",
                                                                outline: "none",
                                                                background: "",
                                                                minHeight: "56px",
                                                                padding: "8px 16px 8px 16px",
                                                                backgroundColor: "#ECEFF3",
                                                                letterSpacing: "2%",
                                                                fontWeight: "400",
                                                                fontSize: "16px",
                                                                position: "relative",
                                                                display: "flex",
                                                                justifyContent: "space-between",
                                                                alignItems: "center",
                                                            }}
                                                            variant="primary"
                                                            onClick={() => {
                                                                setaggreementsize(0);
                                                                setagrrement(null);
                                                                setshowModal2(true);
                                                            }}
                                                        >
                                                            <span
                                                                style={{
                                                                    color: "#667085",
                                                                    fontSize: "16px",
                                                                    lineHeight: "24px",
                                                                    letterSpacing: "2%",
                                                                }}
                                                            >
                                                                {aggreement !== null && aggreement}
                                                                {aggreement !== null &&
                                                                    parseFloat(
                                                                        aggreementsize / 1024
                                                                    ).toFixed(2)}
                                                                {aggreementsize !== 0 &&
                                                                parseFloat(aggreementsize / 1024) >=
                                                                    1024
                                                                    ? "MB"
                                                                    : "KB"}
                                                                {aggreement === null &&
                                                                    "Upload document"}
                                                            </span>
                                                            <img
                                                                src={copy}
                                                                alt=""
                                                                height={20}
                                                                width={20}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="mb-5">
                                                    <button
                                                        disabled={isdisable}
                                                        type="submit"
                                                        style={{
                                                            width: "280px",
                                                            background: isdisable
                                                                ? "#EBE4FF"
                                                                : "#6F00FF",
                                                            height: "48px",
                                                            padding: "16px 24px 16px 24px",
                                                            border: "none",
                                                            outline: "none",
                                                            borderRadius: "8px",
                                                            fontSize: "16px",
                                                            lineHeight: "24px",
                                                            letterSpacing: "2%",
                                                            fontWeight: "500",
                                                            color: isdisable ? "#BFA6FF" : "#ffff",
                                                            justifyContent: "center",
                                                            display: "flex",
                                                            alignItems: "center",
                                                        }}
                                                    >
                                                        {loader === true ? (
                                                            <Loaders data={loader} />
                                                        ) : (
                                                            "Submit"
                                                        )}
                                                    </button>
                                                </div>
                                            </>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default InvicePreviewDocument;
