export const businessToUse = (order, business, type) => {
    if (type === "inbound") {
        return {
            name: order?.receiverBusiness?.name,
            bizName: order?.receiverName ?? order?.receiverBusiness?.name,
            email: order?.receiverEmail,
            id: order?.receiverBusiness?._id,
        };
    } else if (type === "outbound") {
        return {
            name: "",
            bizName: order?.businessName ?? order?.business?.name,
            email: order?.receiverEmail,
            id: order?.business?._id,
        };
    } else {
        return order?.business?._id === business?._id
            ? {
                  type: order?.flexUserType ?? "supplier",
                  createdByCurrBiz: true,
                  name: "",
                  bizName: order?.businessName ?? order?.business?.name,
                  email: order?.receiverEmail,
                  id: order?.business?._id,
              }
            : {
                  type: order?.flexUserType === "supplier" ? "buyer" : "supplier",
                  createdByCurrBiz: false,
                  name: order?.receiverBusiness?.name,
                  bizName: order?.receiverName ?? order?.receiverBusiness?.name,
                  email: order?.receiverEmail,
                  id: order?.receiverBusiness?._id,
              };
    }
};
