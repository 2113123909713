import Modal from "react-bootstrap/Modal";

import { useState } from "react";
import BuyerDetail from "../component/buyerdetail";
import axios from "axios";
import Loader from "../component/loader";
import circle from "../asset/images/add-circle.svg";
import upload from "../asset/images/document-upload.png";
import { currencies } from "../constants/currency";
//import UploadModal from "./uploadModal";
import success from "../asset/images/success.svg";
import trash from "../asset/images/trash.png";
//import UploadInoiceCreate from "../finance/uploadinvoicecreate";
import UploadInvoiceCreateFile from "../component/uploadinvoicefilecreate";
import UploadInvoice from "../component/uploadinvoice";
import edit from "../asset/images/edit-2.svg";
import person from "../asset/images/person.svg";
import TenureModal from "../component/tenure";
import RepaymentStructure from "../component/Repayment";
import FlexBuyer from "../component/flexBuyer";
import CurrencyModal from "../component/currencyModal";

function SendOrder(props) {
    const [modalShow, setModalShow] = useState(false);
    const [buyerdetailsinfo, setbuyerdetailsinfo] = useState([]);
    const [ordertitle, setordertitle] = useState("");
    const [term, setterm] = useState("Immediately on delivery");
    const [means, setmethod] = useState("Cash");
    const [exdate, setexdate] = useState("");
    const [note, setnote] = useState("");
    const [loader, setloader] = useState(false);
    const [selectedcurrency, setselectedcurrency] = useState([]);
    const [, setcurrency] = useState("");
    const [showcurrency, setshowcurrency] = useState(false);
    const [showitem, setshowitem] = useState(false);
    const [selecteditem, setselecteditem] = useState([]);
    const [show, setShow] = useState(false);
    const [businessname, setbusinessname] = useState("");
    const tenure = ["Immediately on delivery", "7", "14", "30", "45"];
    const [tenureshow, settenureshow] = useState(false);
    const [repayshow, setrepayshow] = useState(false);

    const [items, setitems] = useState([
        {
            itemName: "",

            quantity: 0,

            pricePerUnit: 0,

            // amount: 0,
            // currency: "",
        },
    ]);

    const [filename, setfilename] = useState([]);
    const [filesize, setfilesize] = useState(0);
    const [uploadsuccess] = useState(false);
    const [showModal, setshowModal] = useState(false);
    const [isloading] = useState(false);
    const methods = ["Cash", "Credit Line", "Invoice factoring"];
    //const terms = ["Immediately on delivery", "7", "14", "30", "45"];

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const delitems = (index) => {
        setitems([...items.slice(0, index)]);
    };

    //  console.log(selecteditem);
    const addanothersection = () => {
        const data = {
            itemName: "",
            quantity: 0,
            pricePerUnit: 0,
            // amount: 0,
            //  currency: selectedcurrency.name,
        };
        setitems((current) => [...current, data]);
    };
    const addseletedcurrency = (currency) => {
        const newState = items.map((obj) => {
            return {
                ...obj,
                //  currency: currency.title,
            };
        });

        setitems(newState);

        setshowcurrency(!showcurrency);

        setselectedcurrency(currency);
    };
    /*
  const addanothersection = () => {
    const data = {
      itemName: "",
      quantity: 0,
      pricePerUnit: 0,
      amount: 0,
      currency: selectedcurrency.name,
    };
    setitems((current) => [...current, data]);
  };
  */
    const updateitemitems = (e, indexs) => {
        // setitems([...items.indexOf()])

        const newState = items.map((obj, index) => {
            if (index === indexs) {
                return {
                    ...obj,
                    itemName: e.target.value,
                };
            }
            return obj;
        });

        setitems(newState);
    };
    const updateitemquantity = (e, indexs, p) => {
        // setitems([...items.indexOf()])

        const newState = items.map((obj, index) => {
            if (index === indexs) {
                return {
                    ...obj,
                    quantity: e.target.value,
                };
            }
            return obj;
        });

        setitems(newState);
    };

    const updateitemprice = (e, indexs, q) => {
        const newState = items.map((obj, index) => {
            if (index === indexs) {
                return {
                    ...obj,
                    pricePerUnit: e.target.value,
                };
            }
            return obj;
        });
        setitems(newState);
    };
    const submitBuyerDetails = async (e) => {
        e.preventDefault();
        setloader(true);
        const formdata = new FormData();

        const data = {
            title: ordertitle,
            deliveryDate: exdate,
            paymentMethod: means.toLocaleLowerCase(),
            paymentTerms: term,
            note: note,
            currency: selectedcurrency.name,
            orderItems: [...items],
            flexUser: props?.selectedbuyers?._id ? props?.selectedbuyers?._id : selecteditem[0]._id,
        };

        await axios
            .post("/v1/flex/order", data)
            .then((res) => {
                if (filename.length !== 0) {
                    formdata.append("file", filename, filename.name);
                    formdata.append("orderId", res.data.data?._id);
                    axios
                        .post(`/v1/flex/order/purchaseOrder`, formdata)
                        .then(() => {
                            axios
                                .get(`/v1/flex/order/${res.data.data._id}`, data)
                                .then((res) => {
                                    props.setorders([...props.orders, res.data.data]);
                                    setordertitle("");
                                    setexdate("");
                                    setnote("");
                                    setterm("");
                                    setmethod("");
                                    setloader(false);
                                    props.setshow(false);
                                })
                                .catch((e) => {
                                    console.log(e);
                                });
                        })
                        .catch((e) => {
                            console.log(e);
                        });
                } else {
                    axios
                        .get(`/v1/flex/order/${res.data.data._id}`, data)
                        .then((res) => {
                            props.setorders([...props.orders, res.data.data]);
                            setordertitle("");
                            setexdate("");
                            setnote("");
                            setterm("");
                            setmethod("");
                            props.setshow(false);
                        })
                        .catch((e) => {
                            console.log(e);
                        });

                    setloader(false);
                    props.setsuccess(true);
                    props.setshow(false);
                }
            })

            .catch((e) => {
                console.log(e);
            });
    };

    return (
        <>
            <CurrencyModal
                setshow={setshowcurrency}
                show={showcurrency}
                setcurrency={setcurrency}
                addseletedcurrency={addseletedcurrency}
            />
            <BuyerDetail
                show={modalShow}
                onHide={() => setModalShow(false)}
                buyerdetail={buyerdetailsinfo}
                setbuyerdetailsinfo={setbuyerdetailsinfo}
                selecteditem={props.selecteditem}
                setselecteditem={props.setselecteditem}
            />
            <UploadInvoiceCreateFile
                filesize={filesize}
                show={showModal}
                text={" "}
                title={"Purchase Order"}
                setshow={setshowModal}
                filename={filename}
                setfilesize={setfilesize}
                setfilename={setfilename}
            />
            <FlexBuyer
                show={show}
                handle={handleClose}
                selecteditem={selecteditem}
                setselecteditem={setselecteditem}
                setbusinessname={setbusinessname}
            />
            <TenureModal
                show={tenureshow}
                setshow={settenureshow}
                tenure={tenure}
                settenureday={setterm}
                title={"Payment Term"}
            />
            <RepaymentStructure
                tenure={methods}
                setshow={setrepayshow}
                show={repayshow}
                title={"Payment Method"}
                settenureday={setmethod}
            />

            <Modal
                show={props.show}
                backdrop="false"
                keyboard={false}
                onHide={() => props.setshow(false)}
            >
                <Modal.Body
                    className="p-0"
                    style={{
                        minHeight: "100%",
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "24px",
                        }}
                    >
                        <div
                            className=""
                            style={{
                                borderBottom: "1px solid #E7E9FB",
                                padding: "8px 24px 8px 24px",
                            }}
                        >
                            <span
                                className="material-icons"
                                style={{
                                    color: "#667085",
                                    cursor: "pointer",
                                }}
                                onClick={() => {
                                    props.setshow(false);
                                }}
                            >
                                close
                            </span>
                        </div>
                        <div
                            style={{
                                padding: "8px 24px 8px 24px",
                            }}
                        >
                            <form
                                onSubmit={submitBuyerDetails}
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: "16px",
                                }}
                            >
                                <div className="top-buyer-add">
                                    <h3
                                        className="my-titile-add-new-buyer"
                                        style={{
                                            fontSize: "20px",
                                            fontWeight: "500",
                                            color: "#101828",
                                        }}
                                    >
                                        Create Order
                                    </h3>
                                </div>
                                <div className="form-select-group ">
                                    <div className="label-order-title">
                                        <label
                                            htmlFor="Order Title"
                                            style={{
                                                fontWeight: "500",
                                                color: "#101828",
                                            }}
                                        >
                                            Order Title
                                        </label>
                                        <input
                                            type="text"
                                            className="each-form-group"
                                            placeholder="Order Title"
                                            value={ordertitle}
                                            style={{
                                                textTransform: "none",
                                            }}
                                            required
                                            onChange={(e) => {
                                                setordertitle(e.target.value);
                                            }}
                                        />
                                    </div>

                                    <div
                                        className=""
                                        style={{
                                            display: "flex",
                                            gap: "8px",
                                            flexDirection: "column",
                                        }}
                                    >
                                        <label
                                            htmlFor=""
                                            style={{
                                                color: "#101828",
                                                fontWeight: "500",
                                                lineHeight: "19.6px",
                                                fontSize: "14px",
                                            }}
                                        >
                                            Send To
                                        </label>
                                        {selecteditem.length === 0 && (
                                            <div
                                                style={{
                                                    background: "#fff",
                                                    display: "flex",
                                                    justifyContent: "space-between",
                                                    padding: "8px",
                                                    borderRadius: "8px",
                                                    alignItems: "center",
                                                    minHeight: "56px",
                                                }}
                                                variant="primary"
                                                onClick={handleShow}
                                            >
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        gap: "8px",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <img src={person} alt="" />
                                                    <span
                                                        style={{
                                                            color: "#101828",
                                                            letterSpacing: "2%",
                                                            lineHeight: "24px",
                                                            fontSize: "16px",
                                                            fontWeight: 400,
                                                        }}
                                                    >
                                                        Enter partner’s details
                                                    </span>
                                                </div>
                                                <img src={edit} alt="" />
                                            </div>
                                        )}
                                        {selecteditem.length > 0 &&
                                            selecteditem.map((items, index) => (
                                                <div
                                                    style={{
                                                        background: "#fff",
                                                        display: "flex",
                                                        justifyContent: "space-between",
                                                        padding: "8px",
                                                        borderRadius: "8px",
                                                        alignItems: "center",
                                                    }}
                                                    key={index}
                                                    variant="primary"
                                                    onClick={handleShow}
                                                >
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            gap: "8px",
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                width: "40px",
                                                                height: "40px",
                                                                borderRadius: "250px",
                                                                alignItems: "center",
                                                                justifyContent: "ceneter",
                                                                background: "#F4F0FF",
                                                                textAlign: "center",
                                                                display: "flex",
                                                            }}
                                                        >
                                                            <div
                                                                style={{
                                                                    color: "#7F56D9",
                                                                    fontWeight: "500",
                                                                    fontSize: "14px",
                                                                    lineHeight: "20px",
                                                                    textAlign: "center",
                                                                    textTransform: "uppercase",
                                                                    width: "100%",
                                                                }}
                                                            >
                                                                {items?.businessName.slice(0, 2)}
                                                            </div>
                                                        </div>
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                flexDirection: "column",
                                                            }}
                                                        >
                                                            <span
                                                                style={{
                                                                    color: "#101828",
                                                                    letterSpacing: "2%",
                                                                    lineHeight: "18px",
                                                                    fontSize: "16px",
                                                                    fontWeight: "500",
                                                                }}
                                                            >
                                                                {items?.businessName}
                                                            </span>
                                                            <span
                                                                style={{
                                                                    color: "#667085",
                                                                    fontSize: "14px",
                                                                    fontWeight: "400",
                                                                    lineHeight: "19.6px",
                                                                }}
                                                            >
                                                                {items.email}{" "}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <img src={edit} alt="" />
                                                </div>
                                            ))}{" "}
                                    </div>
                                    <div className="label-order-title">
                                        <label
                                            htmlFor="Order Title"
                                            style={{
                                                fontWeight: "500",
                                                color: "#101828",
                                                fontSize: "14px",
                                            }}
                                        >
                                            Expected Delivery Date
                                        </label>
                                        <input
                                            type="date"
                                            className="each-form-group"
                                            placeholder="Order title"
                                            style={{
                                                textTransform: "none",
                                            }}
                                            value={exdate}
                                            required
                                            onChange={(e) => {
                                                setexdate(e.target.value);
                                            }}
                                        />
                                    </div>
                                    <div className="label-order-title">
                                        <label
                                            htmlFor="Order Title"
                                            style={{
                                                fontWeight: "500",
                                            }}
                                        >
                                            Payment Method
                                        </label>
                                        <input
                                            type="text"
                                            className="each-form-group"
                                            placeholder="Order title"
                                            value={means}
                                            required
                                            readOnly
                                            onClick={() => setrepayshow(true)}
                                        />
                                    </div>
                                    <div className="label-order-title">
                                        <label
                                            htmlFor="Order Title"
                                            style={{
                                                fontWeight: "500",
                                            }}
                                        >
                                            Payment Terms
                                        </label>
                                        <input
                                            type="text"
                                            className="each-form-group"
                                            placeholder="Payment term"
                                            required
                                            readOnly
                                            value={term + " " + "Days"}
                                            onClick={() => {
                                                settenureshow(true);
                                            }}
                                        />
                                    </div>
                                    <div className="label-order-title">
                                        <label
                                            htmlFor=""
                                            style={{
                                                fontWeight: "500",
                                            }}
                                        >
                                            Currency
                                        </label>

                                        <div style={{ gap: "8px" }}>
                                            <div
                                                onClick={() => {
                                                    setshowcurrency(!showcurrency);
                                                }}
                                                style={{
                                                    width: "100%",

                                                    borderRadius: "8px",

                                                    border: "none",
                                                    outline: "none",
                                                    background: "",
                                                    padding: "8px 16px 8px 16px",
                                                    display: "flex",
                                                    backgroundColor: "#ECEFF3",
                                                    letterSpacing: "2%",
                                                    fontWeight: "500",
                                                    fontSize: "16px",
                                                    height: "56px",
                                                    justifyContent: "space-between",
                                                    position: "relative",
                                                    alignItems: "center",
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        gap: "4px",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <img
                                                        src={selectedcurrency.img}
                                                        alt=""
                                                        style={{
                                                            width: "25px",
                                                        }}
                                                    />
                                                    <span
                                                        style={{
                                                            color: "#667085",
                                                            fontSize: "16px",
                                                            letterSpacing: "2%",
                                                            lineHeight: "24px",
                                                            fontWeight: "400",
                                                        }}
                                                    >
                                                        {" "}
                                                        {selectedcurrency.name}{" "}
                                                    </span>
                                                </div>
                                                <span
                                                    className="material-icons"
                                                    style={{ color: "#101828" }}
                                                >
                                                    keyboard_arrow_down
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    {items[0].itemName !== "" &&
                                        items[0].quantity !== 0 &&
                                        items[0].pricePerUnit !== 0 && (
                                            <div
                                                className="main-each-lg"
                                                style={{
                                                    background: "#fff",
                                                    border: "1px solid #fff",
                                                }}
                                            >
                                                <div className="lg-sie-avater-lg">
                                                    <div className="top-tier-lg">
                                                        <h4>{items[0].itemName}</h4>
                                                        <span>
                                                            Quantity {items[0].quantity} Unit price{" "}
                                                            {items[0].currency}
                                                            {selectedcurrency.name === "NGN"
                                                                ? "₦"
                                                                : selectedcurrency.name === "EUR"
                                                                ? "€"
                                                                : selectedcurrency.name === "USD"
                                                                ? "$"
                                                                : "£"}
                                                            {parseFloat(
                                                                items[0].pricePerUnit
                                                            ).toLocaleString()}
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="top-tier-lg">
                                                    <h4>
                                                        {selectedcurrency.name === "NGN"
                                                            ? "₦"
                                                            : selectedcurrency.name === "EUR"
                                                            ? "€"
                                                            : selectedcurrency.name === "USD"
                                                            ? "$"
                                                            : "£"}
                                                        {items[0].pricePerUnit * items[0].quantity}
                                                    </h4>
                                                </div>
                                            </div>
                                        )}
                                    {items[0].itemName === "" &&
                                        items[0].quantity === 0 &&
                                        items[0].pricePerUnit === 0 && (
                                            <div
                                                className="label-order-title"
                                                onClick={() => setshowitem(!showitem)}
                                            >
                                                <label
                                                    htmlFor="Order Title"
                                                    style={{
                                                        fontWeight: "500",
                                                    }}
                                                >
                                                    Order Items
                                                </label>
                                                <div className="order-items-col">
                                                    <div className="tile-col-lg-kl">
                                                        <img src={circle} alt="" />
                                                        <span>Add item</span>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    {showitem &&
                                        items.map((item, index) => (
                                            <div
                                                key={index}
                                                style={{
                                                    display: "flex",
                                                    gap: "8px",
                                                }}
                                            >
                                                <div
                                                    className=""
                                                    style={{
                                                        display: "flex",
                                                        gap: "8px",
                                                        flexDirection: "column",
                                                        width: "100%",
                                                    }}
                                                >
                                                    <label
                                                        htmlFor=""
                                                        style={{
                                                            color: "#344054",
                                                            fontWeight: "500",
                                                            lineHeight: "19.6px",
                                                            fontSize: "14px",
                                                        }}
                                                    >
                                                        Items
                                                    </label>
                                                    <div style={{ gap: "8px" }}>
                                                        <input
                                                            type="text"
                                                            name=""
                                                            id=""
                                                            required
                                                            onChange={(e) =>
                                                                updateitemitems(e, index)
                                                            }
                                                            style={{
                                                                width: "100%",
                                                                borderRadius: "8px",
                                                                border: "none",
                                                                outline: "none",
                                                                textTransform: "none",

                                                                height: "56px",
                                                                background: "",
                                                                padding: "8px 16px 8px 16px",
                                                                backgroundColor: "#ECEFF3",
                                                                letterSpacing: "2%",
                                                                fontWeight: "400",
                                                                fontSize: "16px",
                                                            }}
                                                            placeholder="Add item name"
                                                        />
                                                    </div>
                                                </div>
                                                <div
                                                    className=""
                                                    style={{
                                                        display: "flex",
                                                        gap: "8px",
                                                        flexDirection: "column",
                                                        width: "100%",
                                                    }}
                                                >
                                                    <label
                                                        htmlFor=""
                                                        style={{
                                                            color: "#344054",
                                                            fontWeight: "400",
                                                            lineHeight: "19.6px",
                                                            fontSize: "14px",
                                                        }}
                                                    >
                                                        Quantity
                                                    </label>
                                                    <div style={{ gap: "8px" }}>
                                                        <input
                                                            type="number"
                                                            name=""
                                                            id=""
                                                            required
                                                            onChange={(e) =>
                                                                updateitemquantity(
                                                                    e,
                                                                    index,
                                                                    item.pricePerUnit
                                                                )
                                                            }
                                                            style={{
                                                                width: "100%",
                                                                borderRadius: "8px",
                                                                textTransform: "none",

                                                                border: "none",
                                                                height: "56px",
                                                                outline: "none",
                                                                background: "",
                                                                padding: "8px 16px 8px 16px",
                                                                backgroundColor: "#ECEFF3",
                                                                letterSpacing: "2%",
                                                                fontWeight: "400",
                                                                fontSize: "16px",
                                                            }}
                                                            placeholder="1"
                                                        />
                                                    </div>
                                                </div>
                                                <div
                                                    className=""
                                                    style={{
                                                        display: "flex",
                                                        gap: "8px",
                                                        flexDirection: "column",
                                                        width: "100%",
                                                    }}
                                                >
                                                    <label
                                                        htmlFor=""
                                                        style={{
                                                            color: "#344054",
                                                            fontWeight: "500",
                                                            lineHeight: "19.6px",
                                                            fontSize: "14px",
                                                        }}
                                                    >
                                                        Unit Price
                                                    </label>
                                                    <div style={{ gap: "8px" }}>
                                                        <input
                                                            type="number"
                                                            name=""
                                                            id=""
                                                            onChange={(e) =>
                                                                updateitemprice(
                                                                    e,
                                                                    index,
                                                                    item.quantity
                                                                )
                                                            }
                                                            style={{
                                                                width: "100%",
                                                                borderRadius: "8px",
                                                                border: "none",
                                                                height: "56px",
                                                                textTransform: "none",
                                                                outline: "none",
                                                                background: "",
                                                                padding: "8px 16px 8px 16px",
                                                                backgroundColor: "#ECEFF3",
                                                                letterSpacing: "2%",
                                                                fontWeight: "400",
                                                                fontSize: "16px",
                                                            }}
                                                            required
                                                            placeholder="Unit price"
                                                        />
                                                    </div>
                                                </div>
                                                {items.length > 1 && (
                                                    <div style={{ padding: "30px 0px 30px 0px" }}>
                                                        <img
                                                            src={trash}
                                                            alt=""
                                                            style={{ cursor: "pointer" }}
                                                            onClick={() => {
                                                                delitems(index);
                                                            }}
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                        ))}
                                    <div
                                        style={{
                                            display: "flex",
                                            color: "#6F00FF",
                                            fontWeight: "500",
                                            lineHeight: "19.6px",
                                            fontSize: "14px",
                                            letterSpacing: "2%",
                                            alignItems: "center",
                                            gap: "8px",
                                            cursor: "pointer",
                                        }}
                                        onClick={addanothersection}
                                    >
                                        <span className="material-icons">add</span>
                                        Add another item
                                    </div>

                                    <div
                                        className="label-order-title"
                                        onClick={() => {
                                            setshowModal(true);
                                            setfilename([]);
                                            setfilesize(0);
                                        }}
                                    >
                                        <label
                                            htmlFor="Order Title"
                                            style={{
                                                fontWeight: "500",
                                            }}
                                        >
                                            Purchase Order (Optional){" "}
                                        </label>
                                        <div
                                            className="order-items-col"
                                            style={{
                                                background: "#ECEFF3",
                                                display: "flex",
                                                justifyContent: "space-between",
                                                alignItems: "center",
                                            }}
                                        >
                                            <span className="uploadpurchaseorder">
                                                {filename.length !== 0
                                                    ? filename.name
                                                    : "Upload purchase order"}{" "}
                                                {"-"}{" "}
                                                {filesize !== 0
                                                    ? parseFloat(filesize / 1024).toFixed(2)
                                                    : ""}
                                                {filesize !== 0 &&
                                                parseFloat(filesize / 1024) >= 1024
                                                    ? "MB"
                                                    : "KB"}
                                            </span>
                                            {uploadsuccess ? (
                                                <img src={success} alt="" />
                                            ) : isloading === true ? (
                                                "..."
                                            ) : (
                                                <img
                                                    src={upload}
                                                    alt=""
                                                    style={{ width: "25px" }}
                                                />
                                            )}
                                        </div>
                                    </div>

                                    <div className="label-order-title">
                                        <label
                                            htmlFor="Order Title"
                                            style={{
                                                fontWeight: "500",
                                            }}
                                        >
                                            Add Note{" "}
                                        </label>
                                        <textarea
                                            type="text"
                                            className="each-form-group"
                                            placeholder="Write a note here..."
                                            value={note}
                                            required
                                            style={{
                                                height: "96px",
                                            }}
                                            onChange={(e) => {
                                                setnote(e.target.value);
                                            }}
                                        ></textarea>
                                    </div>
                                </div>
                                <button
                                    type="submit"
                                    className="btn-send-request"
                                    disabled={loader}
                                    style={{
                                        width: "100%",
                                        maxWidth: "100%",
                                        justifyContent: "center",
                                        display: "flex",
                                        alignItems: "center",
                                        marginBottom: "10px",
                                    }}
                                >
                                    {loader ? <Loader /> : "Create Order"}
                                </button>
                            </form>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default SendOrder;
