import {Helmet} from "react-helmet";
// import DashHeader from "../component/dashheader";
// import homefinance from '../asset/im
import { useEffect, useState} from 'react';

import SerHeader from "../../component/serviceheader";
import arrowleft from '../../asset/images/arrowleftcircle.svg'

import UploadInvoice from "../../component/uploadinvoice";
import nigeria from '../../asset/images/countries/ngn.svg'
import pounds from '../../asset/images/countries/Italy.svg'
import UploadModal from "../../component/uploadModal";
import Uploaddocumentfinance from "../../component/uploadloaddocument";
import InvoiceSuccess from "../../component/notice";
import us from '../../asset/images/US.svg'
import {useNavigate,useParams} from "react-router-dom";


const CreateCard = () => {
    // const [selectedIndex, setselectedIndex] = useState(-1)
     const navigate = useNavigate()
    const currencies = [
        {
            image: nigeria,
            title: 'NGN'
        }, {
            title: 'USD',
            image: us


        }, {
            image: pounds,
            title: 'EU'


        }
    ]
    const [show, setShow] = useState(false);
    const [selecteditem, setselecteditem] = useState([])
    const [selectedcurrency, setselectedcurrency] = useState([])
    const [added, setadded] = useState(false)
    const [filename, setfilename] = useState(null)
    const [filesize, setfilesize] = useState(0)
    const [aggreement, setagrrement] = useState(null)
    const [aggreementsize, setaggreementsize] = useState(0)
    const [showModal, setshowModal] = useState(false)
    const [showModal2, setshowModal2] = useState(false)
    const [showModal3, setshowModal3] = useState(false)

    const [isdisable, setisdisable] = useState(true)
    const [isload, setisload] = useState(false)
    const [amount, setamount] = useState('')
    const [tab, settab] = useState('buyer')
    const {id} = useParams()



    const [showdrop, setisshowdrop] = useState(false)
    const handleClose = () => setShow(false);
    const addseletedcurrency = (currency) => {
        setadded(true)

        setselectedcurrency(currency)
    }
   
    useEffect(() => {
        if ( amount  ) {
            setisdisable(false)
        } else {
            setisdisable(true)
        }
        if(isload === false){
            setshowModal3(true)
            setisload(true)

        }
        
    }, [
        setisdisable,
        amount,
        isload
        
        
    ])
   
    return (<div className="h-100 w-100 ">
        <div className="container-xxl">
            <Helmet>
                <title>Create card</title>
            </Helmet>

            <SerHeader header={'Create new card'}/>
            <InvoiceSuccess show={showModal3}
                setshow={setshowModal3}/>
            <Uploaddocumentfinance show={showModal2}
                setshow={setshowModal2}

                aggreement={aggreement}
                aggreementsize={aggreementsize}
                setaggreementsize={setaggreementsize}
                setagrrement={setagrrement}/>
            <UploadModal filesize={filesize}
                show={showModal}
                setshow={setshowModal}
                filename={filename}
                setfilesize={setfilesize}
                setfilename={setfilename}/>
        </div>
        <div style={
            {height: '100%'}
        }>
            <div className="conatiner "
                style={
                    {
                        maxWidth: '1250px',
                        margin: '0px auto',
                        paddingLeft: '30px',
                        paddingRight: '30px',
                        paddingTop: '10px',
                        paddingBottom: '10px'
                    }
            }>

                <div style={
                    {
                        height: '100%',
                        width: '100%',
                        display: 'flex',

                        justifyContent: 'center'

                    }
                }>
                    <UploadInvoice show={show}
                        handle={handleClose}
                        selecteditem={selecteditem}
                        setselecteditem={setselecteditem}/>
                    <div style={
                        {
                            maxWidth: '592px',
                            width: '100%',
                            marginTop: '50px',
                            display: 'flex',
                            justifyContent: 'space-between',
                            flexDirection: 'column',
                            gap: '32px'
                        }
                    }>
                        <div style={
                            {
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '32px'

                            }
                        }>
                            <div style={
                                {
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: '16px'
                                }
                            }>
                                <div   onClick={
                                    ()=>{
                                        navigate(-1)
                                    }
                                }>
                                    <img src={arrowleft}
                                        alt=""/>
                                </div>
                                <div style={
                                    {
                                        color: '#101828',
                                        fontWeight: '500',
                                        fontSize: '24px',
                                        lineHeight: '32.4px'
                                    }
                                }>
                                   Create {id} card
                                </div>

                            </div>
                            <div className=""
                                style={
                                    {
                                        display: 'flex',
                                        gap: '24px',
                                        flexDirection: 'column'
                                    }
                            }>
                                <div style={
                                    {
                                        background: '#ECEFF3',
                                        borderRadius: '8px',
                                        padding: '4px',
                                        display: 'flex',
                                        gap: '8px',
                                        justifyContent: 'space-between'
                                    }
                                }>
                                    <div style={
                                        {
                                            padding: '6px 8px 6px 8px',
                                            background: tab === 'buyer' ? '#fff' : '',
                                            textAlign: 'center',
                                            borderRadius: '4px',
                                            width: '50%',
                                            cursor: 'pointer'
                                        }
                                    } onClick={()=>{
                                        settab('buyer')
                                        setamount('')
                                        setagrrement(null)
                                        setaggreementsize(0)
                                        setfilename(null)
                                        setfilesize(0)
                                        setselecteditem([])
                                        setshowModal(false)
                                        setshowModal2(false)
                                        setshowModal3(false)
                                        setShow(false)

                                    }}>Virtual</div>
                                    <div style={
                                        {
                                            background: tab === 'supplier' ? '#fff' : '',

                                            padding: '6px 8px 6px 8px',
                                            textAlign: 'center',
                                            borderRadius: '4px',
                                            width: '50%',
                                            cursor: 'pointer'
                                        }
                                    }onClick={()=>{
                                        settab('supplier')
                                        setamount('')
                                        setagrrement(null)
                                        setaggreementsize(0)
                                        setfilename(null)
                                        setfilesize(0)
                                        setselecteditem([])
                                        setshowModal(false)
                                        setshowModal2(false)
                                        setshowModal3(false)
                                        setShow(false)

                                    }}>Physical</div>
                                </div>
                                {
                                    tab === 'buyer' &&  <>

                                    
                                
                              
                                <div className=""
                                    style={
                                        {
                                            display: 'flex',
                                            gap: '8px',
                                            flexDirection: 'column'
                                        }
                                }>
                                    <label htmlFor=""
                                        style={
                                            {
                                                color: '#344054',
                                                fontWeight: '500',
                                                lineHeight: '19.6px',
                                                fontSize: '14px'
                                            }
                                    }>Enter amount for your new card account</label>
                                    <div style={
                                        {
                                            display: 'flex',
                                            gap: '8px'
                                        }
                                    }>
                                        <div style={
                                                {
                                                    width: '100%',
                                                    maxWidth:'96px',
                                                    borderRadius: '8px',
                                                    border: 'none',
                                                    outline: 'none',
                                                    background: '',
                                                    padding: '8px 16px 8px 16px',
                                                    backgroundColor: '#ECEFF3',
                                                    letterSpacing: '2%',
                                                    fontWeight: '400',
                                                    fontSize: '16px',
                                                    display: 'flex',
                                                    gap: '4px',
                                                    alignItems: 'center',
                                                    position: 'relative'

                                                }
                                            }
                                            onClick={
                                                () => {
                                                    setisshowdrop(!showdrop)
                                                }
                                        }> {
                                            showdrop === true && <div style={
                                                {
                                                    position: 'absolute',
                                                    background: '#fff',
                                                    padding: '8px',
                                                    width: '100%',
                                                    top: '40px',
                                                    left: '-2px',
                                                    borderRadius: '4px',
                                                    zIndex: '2'

                                                }
                                            }>
                                                <div style={
                                                    {
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        gap: '16px'
                                                    }
                                                }> {
                                                    currencies.map((currency, index) => (<div style={
                                                            {
                                                                display: 'flex',
                                                                gap: '8px',
                                                                lineHeight: '19.6px',
                                                                cursor: 'pointer'
                                                            }
                                                        }
                                                        key={index}
                                                        onClick={
                                                            () => {
                                                                addseletedcurrency(currency)
                                                            }
                                                    }>
                                                        <img src={
                                                                currency.image
                                                            }
                                                            alt=""
                                                            height={24}
                                                            width={24}/>
                                                        <span style={
                                                            {
                                                                color: '#667085',
                                                                letterSpacing: '2%',
                                                                lineHeight: '24px',
                                                                fontSize: '16px'
                                                            }
                                                        }> {
                                                            currency.title
                                                        }</span>

                                                    </div>))
                                                } </div>
                                            </div>
                                        }


                                            <img src={
                                                    added ? selectedcurrency.image : nigeria
                                                }
                                                alt=""
                                                height={24}
                                                width={24}/>
                                            <span style={
                                                {
                                                    color: '#667085',
                                                    letterSpacing: '2%',
                                                    lineHeight: '24px',
                                                    fontSize: '16px'
                                                }
                                            }> {
                                                added === true ? selectedcurrency.title : 'NGN'
                                            }</span>
                                        </div>
                                        <div style={{
                                            width:'100%',
                                            display:'flex',
                                            flexDirection:'column',
                                            gap:'8px',
                                            backgroundColor: '#ECEFF3',
                                            padding: '4px 8px 4px 8px',
                                            borderRadius: '8px',


                                        }}>
                                        <input type="text" name="" id=""
                                            onChange={
                                                (e) => {
                                                    setamount(e.target.value)
                                                }
                                            }
                                            style={
                                                { 
                                                 backgroundColor: '#ECEFF3',

                                                    width: '85%',
                                                    height:'20px',
                                                    border: 'none',
                                                    outline: 'none',
                                                    color:'#101828',
                                                    fontSize:'16px',
                                                    lineHeight:'24px',
                                                    letterSpacing: '2%',
                                                    fontWeight: '400',

                                                }
                                            }
                                            placeholder="Enter amount"/>

                                            <span style={{
                                                color:'#98A2B3',
                                                fontSize:'12px',
                                                fontWeight:'400',
                                                lineHeight:'16.2px',
                                                marginLeft:'5px'
                                            }}>
                                            Balance $500.22
                                            </span>

                                        </div>
                                        

                                    </div>
                            </div>
                           
                           
                            <div className="mb-5">
                                <button disabled={isdisable}

                                    variant="primary"
                                   
                                    style={
                                        {
                                            width: '280px',
                                            background: isdisable ? '#EBE4FF' : '#6F00FF',
                                            padding: '8px 12px 8px 12px',
                                            border: 'none',
                                            outline: 'none',
                                            borderRadius: '8px',
                                            fontSize: '16px',
                                            lineHeight: '24px',
                                            letterSpacing: '2%',
                                            fontWeight: '500',
                                            color: isdisable ? '#BFA6FF' : '#ffff'

                                        }
                                }>
                                    Create card
                                </button>

                            </div>
                                    
                                    </>
                                }{
                                    tab === 'supplier' &&  (
                                        <>
                                   
                                <div className=""
                                style={
                                    {
                                        display: 'flex',
                                        gap: '8px',
                                        flexDirection: 'column'
                                    }
                            }>
                                <label htmlFor=""
                                    style={
                                        {
                                            color: '#344054',
                                            fontWeight: '500',
                                            lineHeight: '19.6px',
                                            fontSize: '14px'
                                        }
                                }>Enter amount for your new card account</label>
                                <div style={
                                    {
                                        display: 'flex',
                                        gap: '8px'
                                    }
                                }>
                                    <div style={
                                            {
                                                width: '100%',
                                                maxWidth:'96px',
                                                borderRadius: '8px',
                                                border: 'none',
                                                outline: 'none',
                                                background: '',
                                                padding: '8px 16px 8px 16px',
                                                backgroundColor: '#ECEFF3',
                                                letterSpacing: '2%',
                                                fontWeight: '400',
                                                fontSize: '16px',
                                                display: 'flex',
                                                gap: '4px',
                                                alignItems: 'center',
                                                position: 'relative'

                                            }
                                        }
                                        onClick={
                                            () => {
                                                setisshowdrop(!showdrop)
                                            }
                                    }> {
                                        showdrop === true && <div style={
                                            {
                                                position: 'absolute',
                                                background: '#fff',
                                                padding: '8px',
                                                width: '100%',
                                                top: '40px',
                                                left: '-2px',
                                                borderRadius: '4px',
                                                zIndex: '2'

                                            }
                                        }>
                                            <div style={
                                                {
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    gap: '16px'
                                                }
                                            }> {
                                                currencies.map((currency, index) => (<div style={
                                                        {
                                                            display: 'flex',
                                                            gap: '8px',
                                                            lineHeight: '19.6px',
                                                            cursor: 'pointer'
                                                        }
                                                    }
                                                    key={index}
                                                    onClick={
                                                        () => {
                                                            addseletedcurrency(currency)
                                                        }
                                                }>
                                                    <img src={
                                                            currency.image
                                                        }
                                                        alt=""
                                                        height={24}
                                                        width={24}/>
                                                    <span style={
                                                        {
                                                            color: '#667085',
                                                            letterSpacing: '2%',
                                                            lineHeight: '24px',
                                                            fontSize: '16px'
                                                        }
                                                    }> {
                                                        currency.title
                                                    }</span>

                                                </div>))
                                            } </div>
                                        </div>
                                    }


                                        <img src={
                                                added ? selectedcurrency.image : nigeria
                                            }
                                            alt=""
                                            height={24}
                                            width={24}/>
                                        <span style={
                                            {
                                                color: '#667085',
                                                letterSpacing: '2%',
                                                lineHeight: '24px',
                                                fontSize: '16px'
                                            }
                                        }> {
                                            added === true ? selectedcurrency.title : 'NGN'
                                        }</span>
                                    </div>
                                    <div style={{
                                        width:'100%',
                                        display:'flex',
                                        flexDirection:'column',
                                        gap:'8px',
                                        backgroundColor: '#ECEFF3',
                                        padding: '4px 8px 4px 8px',
                                        borderRadius: '8px',


                                    }}>
                                    <input type="text" name="" id=""
                                        onChange={
                                            (e) => {
                                                setamount(e.target.value)
                                            }
                                        }
                                        style={
                                            {  backgroundColor: '#ECEFF3',

                                                width: '85%',
                                                height:'20px',
                                                border: 'none',
                                                outline: 'none',
                                                color:'#101828',
                                                fontSize:'16px',
                                                lineHeight:'24px',
                                                letterSpacing: '2%',
                                                fontWeight: '400',

                                            }
                                        }
                                        placeholder="Enter amount"/>

                                        <span style={{
                                            color:'#98A2B3',
                                            fontSize:'12px',
                                            fontWeight:'400',
                                            lineHeight:'16.2px',
                                            marginLeft:'5px'
                                        }}>
                                        Balance $500.22
                                        </span>

                                    </div>
                                    

                                </div>
                        </div>
                       
                       
                        <div className="mb-5">
                            <button disabled={isdisable}

                                variant="primary"
                                
                                style={
                                    {
                                        width: '280px',
                                        background: isdisable ? '#EBE4FF' : '#6F00FF',
                                        padding: '8px 12px 8px 12px',
                                        border: 'none',
                                        outline: 'none',
                                        borderRadius: '8px',
                                        fontSize: '16px',
                                        lineHeight: '24px',
                                        letterSpacing: '2%',
                                        fontWeight: '500',
                                        color: isdisable ? '#BFA6FF' : '#ffff'

                                    }
                            }>
                                Create card
                            </button>

                        </div>
                                
                                </>
                                    )
                                    
                                    
                                    
                                    
                                   
                                }

                        </div>


                    </div>


                </div>

            </div>
        </div>
    </div>
</div>);
}

export default CreateCard;
