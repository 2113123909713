import Modal from "react-bootstrap/Modal";
import currencys from "../asset/images/GB.svg";
import currencyss from "../asset/images/EU.svg";
import NGNcurrency from "../asset/images/Currency.svg";
import US from "../asset/images/US.png";
import search from "../asset/images/search-normal.svg";

import checkbos from "../asset/images/Checkbox.svg";
import currencyPairCheck from "../utilities/currencyPairCheck";
function MyVerticallyCenteredModal(props) {
    //  console.log(props.wallet);
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="mycenteredlarge"
        >
            <Modal.Header closeButton className="border-0"></Modal.Header>
            <div
                style={{
                    paddingLeft: "20px",
                    paddingRight: "20px",
                    paddingBottom: "20px",
                }}
            >
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "16px",
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "16px",
                        }}
                    >
                        <span
                            style={{
                                color: "#101828",
                                fontSize: "20px",
                                lineHeight: "24px",
                                fontWeight: "600",
                            }}
                        >
                            Switch wallets
                        </span>
                        <div
                            style={{
                                position: "relative",
                                height: "40px",
                                width: "100%",
                                boxSizing: "border-box",
                            }}
                        >
                            <input
                                type="text"
                                style={{
                                    position: "relative",
                                    height: "100%",
                                    width: "100%",
                                    padding: "0px 40px",
                                    borderRadius: "8px",
                                    background: "#ECEFF3",
                                    outline: "none",
                                    border: "none",
                                    fontWeight: "500",

                                    fontSize: "14px",
                                    lineHeight: "19.6px",
                                    color: "#667085",
                                }}
                                placeholder="Search countries"
                            />
                            <img
                                src={search}
                                alt=""
                                style={{
                                    position: "absolute",
                                    top: "10px",
                                    left: "8px",
                                }}
                            />
                        </div>
                    </div>

                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            background: "#ffff",
                            borderRadius: "8px",
                        }}
                    >
                        {props?.wallet?.map((wallet, index) => (
                            <div
                                className="col"
                                key={`${index + wallet.currency + index}`}
                                onClick={() => {
                                    props.setdefault(wallet.currency);
                                    props.setshow(false);
                                    // sessionStorage.setItem("default", wallet.currency);
                                    localStorage.setItem("default", wallet.currency);
                                }}
                                style={{
                                    cursor: "pointer",
                                }}
                            >
                                <div
                                    className=""
                                    style={{
                                        padding: "4px",
                                        borderRadius: "8px",
                                    }}
                                >
                                    <div
                                        className=""
                                        style={{
                                            padding: "8px",
                                            position: "relative",
                                            background:
                                                props.default === wallet.currency && "#F4F0FF",
                                            borderRadius: "8px",
                                        }}
                                    >
                                        <div
                                            className="d-flex"
                                            style={{
                                                gap: "10px",
                                                position: "relative",
                                                alignItems: "center",
                                            }}
                                        >
                                            <img
                                                src={
                                                    wallet.currency === "NGN"
                                                        ? NGNcurrency
                                                        : wallet.currency === "EUR"
                                                        ? currencyss
                                                        : wallet.currency === "USD"
                                                        ? US
                                                        : currencys
                                                }
                                                alt=""
                                                style={{
                                                    height: "40px",
                                                }}
                                            />
                                            <div
                                                className="d-flex"
                                                style={{
                                                    flexDirection: "column",
                                                }}
                                            >
                                                <div className="d-flex" style={{ gap: "8px" }}>
                                                    <span
                                                        style={{
                                                            color: "#101828",
                                                            lineHeight: "24px",
                                                            fontWeight: "700",
                                                            textAlign: "center",
                                                            fontSize: "16px",
                                                        }}
                                                    >
                                                        {wallet.currency}
                                                        {wallet.currency === "EUR"
                                                            ? "Euro"
                                                            : wallet.currency === "NGN"
                                                            ? "Naira"
                                                            : wallet.currency === "USD"
                                                            ? "Dollar"
                                                            : "Pounds"}
                                                    </span>
                                                    <span
                                                        style={{
                                                            color: "#98A2B3",
                                                            lineHeight: "24px",
                                                            fontWeight: "400",
                                                            textAlign: "center",
                                                            letterSpacing: "2%",
                                                        }}
                                                    >
                                                        {wallet.currency}
                                                    </span>
                                                </div>
                                                <div>
                                                    <span
                                                        style={{
                                                            color: "#98A2B3",
                                                            fontWeight: "500",
                                                            fontSize: "16px",
                                                            lineHeight: "24px",
                                                        }}
                                                    >
                                                        {" "}
                                                        {currencyPairCheck(wallet.currency)}
                                                    </span>
                                                    <span
                                                        style={{
                                                            color: "#667085",
                                                            fontWeight: "500",
                                                            fontSize: "16px",
                                                            lineHeight: "32.4px",
                                                        }}
                                                    >
                                                        {parseFloat(wallet.balance).toFixed(2)}
                                                    </span>
                                                </div>
                                                {props.default === wallet.currency && (
                                                    <img
                                                        src={checkbos}
                                                        alt=""
                                                        style={{
                                                            position: "absolute",
                                                            top: "20px",
                                                            right: "0",
                                                            bottom: "auto",
                                                        }}
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                        {/**
                    <div className="col-md-4 col-lg-4">
                        <div className="card border-0"
                            style={
                                {padding: '5px'}
                        }>
                            <div className="card-body"
                                style={
                                    {
                                        padding: '8px !important',
                                        position: 'relative'
                                    }
                            }>
                                <div className="d-flex"
                                    style={
                                        {
                                            gap: '10px',
                                            position: 'relative'
                                        }
                                }>
                                    <img src={currencys}
                                        alt=""/>
                                    <div className="d-flex"
                                        style={
                                            {
                                                flexDirection: 'column',
                                                gap: '8px'
                                            }
                                    }>
                                        <div className="d-flex"
                                            style={
                                                {gap: '8px'}
                                        }>
                                            <span style={
                                                {
                                                    color: '#101828',
                                                    lineHeight: '24.3px',
                                                    fontWeight: '700px',
                                                    textAlign: 'center'

                                                }
                                            }>US Dollar</span>
                                            <span style={
                                                {
                                                    color: '#98A2B3',
                                                    lineHeight: '24px',
                                                    fontWeight: '500px',
                                                    textAlign: 'center',
                                                    letterSpacing: '2%'

                                                }
                                            }>USD</span>
                                        </div>
                                        <div>
                                            <sub style={
                                                {
                                                    color: '#101828',
                                                    fontWeight: '500',
                                                    fontSize: '24px',
                                                    lineHeight: '32.4px'
                                                }
                                            }>$</sub>
                                            <span style={
                                                {
                                                    color: '#101828',
                                                    fontWeight: '500',
                                                    fontSize: '24px',
                                                    lineHeight: '32.4px'
                                                }
                                            }>3 545.</span>
                                            <sub style={
                                                {
                                                    color: '#667085',
                                                    fontWeight: '500',
                                                    fontSize: '14px',
                                                    lineHeight: '19.6px'
                                                }
                                            }>22</sub>


                                        </div>

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-4 col-lg-4">
                        <div className="card border-0"
                            style={
                                {padding: '5px'}
                        }>
                            <div className="card-body"
                                style={
                                    {
                                        padding: '8px !important',
                                        position: 'relative'
                                    }
                            }>
                                <div className="d-flex"
                                    style={
                                        {
                                            gap: '10px',
                                            position: 'relative'
                                        }
                                }>
                                    <img src={currencyss}
                                        alt=""/>
                                    <div className="d-flex"
                                        style={
                                            {
                                                flexDirection: 'column',
                                                gap: '8px'
                                            }
                                    }>
                                        <div className="d-flex"
                                            style={
                                                {gap: '8px'}
                                        }>
                                            <span style={
                                                {
                                                    color: '#101828',
                                                    lineHeight: '24.3px',
                                                    fontWeight: '700px',
                                                    textAlign: 'center'

                                                }
                                            }>US Dollar</span>
                                            <span style={
                                                {
                                                    color: '#98A2B3',
                                                    lineHeight: '24px',
                                                    fontWeight: '500px',
                                                    textAlign: 'center',
                                                    letterSpacing: '2%'

                                                }
                                            }>USD</span>
                                        </div>
                                        <div>
                                            <sub style={
                                                {
                                                    color: '#101828',
                                                    fontWeight: '500',
                                                    fontSize: '24px',
                                                    lineHeight: '32.4px'
                                                }
                                            }>$</sub>
                                            <span style={
                                                {
                                                    color: '#101828',
                                                    fontWeight: '500',
                                                    fontSize: '24px',
                                                    lineHeight: '32.4px'
                                                }
                                            }>3 545.</span>
                                            <sub style={
                                                {
                                                    color: '#667085',
                                                    fontWeight: '500',
                                                    fontSize: '14px',
                                                    lineHeight: '19.6px'
                                                }
                                            }>22</sub>


                                        </div>

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    */}
                    </div>
                </div>
            </div>
        </Modal>
    );
}

export default MyVerticallyCenteredModal;
