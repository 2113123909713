import { CloseIcon, CopyIcon, LocationIcon, ReceiptEditIcon, UserAddIcon } from "asset/icons";
import successImg from "asset/images/success-check.svg";
import config from "constants/config";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import externalSuccessImg from "asset/images/external-success-header.svg";

export const BuyerSuccessModal = ({ open, downloadPdf }) => {
    const navigate = useNavigate();
    return (
        <div
            className={`h-screen w-screen bg-[#1D29394D]  grid place-items-center !px-6 transition-all ${
                open ? "fixed" : "hidden"
            } left-0 top-0 z-50`}
        >
            <div className="bg-white rounded-2xl w-full sm:w-[520px] relative transition-all">
                <CloseIcon
                    className="*:stroke-main-grey size-[25px] absolute right-6 sm:right-5 top-[34px] cursor-pointer rounded-full"
                    onClick={() => navigate(-1)}
                />

                <div className="flex max-sm:flex-col max-sm:items-center !p-4 gap-2 !mt-2.5">
                    <img
                        alt="Created order"
                        className="text-xs size-16 sm:self-start"
                        src={successImg}
                    />
                    <h3 className="font-semibold text-2xl text-center">
                        Order created successfully
                        <span className="block !mt-2 font-[350] text-base">
                            You can manage this order from{" "}
                            <Link to={"/flex/home"} className="!text-[#3B82F6] max-sm:block">
                                incoming orders
                            </Link>
                        </span>
                    </h3>
                </div>

                <div className="flex max-475:flex-col-reverse max-sm:*:w-full !p-4 !pb-7 border-t !border-[#E7E9FB] !mt-4 gap-3">
                    {/* <button className="rounded-lg !py-2.5 !px-6 font-[450] text-[#475467]">
                        Download PDF
                    </button> */}
                    <button
                        className="!bg-secondary ml-auto rounded-lg !py-2.5 !px-6 text-[#FBFAFF] font-[450]"
                        onClick={downloadPdf}
                    >
                        {/* Ship this order */}
                        Download PDF
                    </button>
                </div>
            </div>
        </div>
    );
};

export const CreateLinkSuccessModal = ({ poId }) => {
    const [copied, setCopied] = useState(false);
    const navigate = useNavigate();
    const link = config.APP_URL + "/create-po/" + poId;

    useEffect(() => {
        if (copied) {
            setTimeout(() => {
                setCopied(false);
            }, 3000);
        }
    }, [copied]);

    return (
        <div
            className={`h-screen w-screen bg-[#1D29394D] grid place-items-center !px-6 transition-all ${
                poId ? "fixed" : "hidden"
            } left-0 top-0 z-50`}
        >
            <div className="bg-white rounded-2xl w-full sm:w-[520px] relative transition-all">
                <CloseIcon
                    className={`*:stroke-main-grey size-[25px] absolute right-6 sm:right-5 top-[34px] cursor-pointer rounded-full`}
                    onClick={() => navigate(-1)}
                />

                <div className="flex max-sm:flex-col max-sm:items-center !p-4 !pb-2.5 gap-2 !mt-2.5 border-b !mb-8 !border-[#E7E9FB]">
                    <img
                        alt="Created order"
                        className="text-xs size-12 sm:self-start"
                        src={successImg}
                    />
                    <h3 className="font-semibold text-2xl !text-center sm:!text-left">
                        Link sent successfully
                        <span className="block !mt-[7px] font-[350] text-base text-[#6B7280]">
                            You'll be notified once your buyer fills the form
                        </span>
                    </h3>
                </div>

                <>
                    <p className="font-medium text-sm text-[#475467] !mx-4 475:!mx-6 !mb-1">
                        P.O. Creation Link
                    </p>
                    <p className="flex max-475:flex-col 475:items-center !border !border-[#EBE4FF] rounded-lg !py-3.5 !px-3 !gap-1 !mx-4 475:!mx-6 text-[13px] 475:text-sm justify-between">
                        {link}

                        {copied ? (
                            <span className="ml-auto 475:!ml-2 text-[#475467]">Copied!</span>
                        ) : (
                            <CopyIcon
                                className="!ml-auto 475:!ml-3 cursor-pointer size-[18px] *:stroke-secondary"
                                onClick={(evt) => {
                                    navigator.clipboard.writeText(link);
                                    setCopied(true);
                                }}
                            />
                        )}
                    </p>
                </>

                <div className="flex max-475:flex-col-reverse max-sm:*:w-full !p-4 !pb-7 border-t !border-[#E7E9FB] !mt-9 gap-3">
                    <button
                        className="!bg-secondary ml-auto rounded-lg !py-2.5 !px-6 text-[#FBFAFF] font-[450]"
                        onClick={() => navigate("/flex/home")}
                    >
                        Back to orders
                    </button>
                </div>
            </div>
        </div>
    );
};

export const ExternalSuccessModal = ({ open }) => {
    const navigate = useNavigate();
    return (
        <div
            className={`h-screen w-screen bg-[#1D29394D]  grid place-items-center !px-6 transition-all ${
                open ? "fixed" : "hidden"
            } left-0 top-0 z-50`}
        >
            <div className="bg-white rounded-2xl w-full sm:w-[520px] relative transition-all">
                <div className="flex flex-col items-center !pt-3 !pb-[5px] !mb-11 !px-4 gap-2 border-b !border-[#E7E9FB]">
                    <img
                        alt="Created order"
                        className="text-xs w-[150px] h-[120px]"
                        src={externalSuccessImg}
                    />
                    <h3 className="font-semibold text-2xl text-center">
                        Your order has been sent
                        <span className="block !mt-[7px] font-[350] text-base text-[#6B7280]">
                            Join Tradevu and do more with purchase orders
                        </span>
                    </h3>
                </div>

                <>
                    <p className="flex gap-3 520:gap-4 font-[450] text-[#475467] !px-3 475:!px-6 sm:!px-14">
                        <ReceiptEditIcon className="translate-y-1 size-6" /> Create and manage
                        unlimited purchase orders
                    </p>
                    <p className="flex items-center gap-3 520:gap-4 font-[450] text-[#475467] !px-3 475:!px-6 sm:!px-14 !my-7">
                        <UserAddIcon className="translate-y-1 size-6" /> Assign and Manage your 3PL
                        Partners
                    </p>
                    <p className="flex items-center gap-3 520:gap-4 font-[450] text-[#475467] !px-3 475:!px-6 sm:!px-14">
                        <LocationIcon className="translate-y-1 size-6" /> Track your shipments with
                        real-time feedback
                    </p>
                </>

                <div className="flex !p-4 !pb-7 border-t !border-[#E7E9FB] !mt-11 gap-3">
                    <button
                        className="!bg-secondary ml-auto rounded-lg !py-2.5 !px-6 text-[#FBFAFF] font-[450]"
                        onClick={() => navigate("/register")}
                    >
                        Start today
                    </button>
                </div>
            </div>
        </div>
    );
};
