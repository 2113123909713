import Modal from "react-bootstrap/Modal";
import success from "../asset/images/realwarning.png";
import "../style/main.css";
import "../style/rightside.css";
import Loader from "./loader";

function ConfirmOrder(props) {
  const closeModal = () => {
    var datashow = props.show;
    props.setshow(!datashow);
  };
  // console.log(props.orderdetails)

  return (
    <Modal
      show={props.show}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      onHide={() => props.setshow(false)}
      centered
      className="border-0 "
    >
      <Modal.Body
        style={{
          borderRadius: "16px",
          padding: "0px",
          border: "none",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
          }}
        >
          <div
            className=""
            style={{
              borderBottom: "1px solid #E7E9FB",
              padding: "8px 24px 8px 24px",
            }}
          >
            <span
              className="material-icons"
              style={{ color: "#667085" }}
              onClick={closeModal}
            >
              close
            </span>
          </div>
          <div style={{ padding: "8px 24px 8px 24px" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "32px",
                marginBottom: "10px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  gap: "24px",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <div>
                  <img src={success} alt="" />
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "8px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <h3
                      style={{
                        color: "#101828",
                        fontWeight: "700",
                        lineHeight: "24.3px",
                        fontSize: "18px",
                        textAlign: "center",
                      }}
                    >
                      Accept order
                    </h3>
                    <span
                      style={{
                        textAlign: "center",
                        color: "#667085",
                        fontWeight: "400",
                        letterSpacing: "2%",
                        fontSize: "16px",
                        lineHeight: "24px",
                      }}
                    >
                      You’re about to accept this purchase order from{" "}
                      {props?.orderdetails.business?.name}
                    </span>
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "8px",
                }}
              >
                <button
                  type="submit"
                  onClick={() => props.ChangeStatus("accepted")}
                  style={{
                    background: "#12B76A",
                    color: "#fff",
                    padding: "16px 24px 16px 24px",
                    fontWeight: "600",
                    border: "none",
                    outline: "none",
                    borderRadius: "8px",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  {props.loaderaccept ? <Loader /> : "Accept Order"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default ConfirmOrder;
