import PartnerInvoice from "flex/PartnerInvoice";
import { useState } from "react";
import { useSelector } from "react-redux";

const Network = () => {
    const [invoices, setinvoices] = useState([]);
    const [showupload, setshowupload] = useState(false);
    const [supplierloader, setsupplierloader] = useState(true);
    const selectorbusiness = useSelector((state) => state.businessid);
    const [active, setactive] = useState([]);

    const newarray = active.filter(
        (item) => item?.receiverBusiness?._id !== selectorbusiness
    );
    newarray.sort((a, b) => a.firstName.localeCompare(b.firstName, "es", { sensitivity: "base" }));

    let datas = newarray.reduce((r, e) => {
        let alphabet = e.firstName[0];

        if (!r[alphabet])
            r[alphabet] = {
                alphabet,
                record: [],
            };

        r[alphabet].record.push(e);
        return r;
    }, {});
    let results = Object.values(datas);


    return (
        <div className="!px-7">
            <h2 className="font-[450] text-[22px] text-main-dark !mt-9">Network</h2>
            {/* <PartnerInvoice
                invoice={invoices}
                setinvoices={setinvoices}
                s={showupload}
                ss={setshowupload}
                loaderset={supplierloader}
                active={results}
                setActive={setactive}
                setshowdetail={setshowrequestStatement}
                sb={setselectedbuyer}
                pending={resultspending}
                incoming={resultsincoming}
                st={settab}
                activePartnersCount={newarray.length || ""}
            /> */}
        </div>
    );
};

export default Network;
