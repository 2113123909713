import { ArrowIcon, CheckCircleIcon } from "asset/icons";
import { forwardRef, isValidElement, useState } from "react";

const Dropdown = forwardRef(
    (
        {
            value,
            options,
            onChange,
            className = "",
            label = "",
            labelClassName = "",
            placeholder = "",
            dropdownClassName = "",
            required = true,
        },
        ref
    ) => {
        const [show, setShow] = useState(false);
        const selectedOption = options.find((option) => option.value === value);
        const selectedValue = (val) => value === val;

        return (
            <>
                {label && (
                    <p
                        //text-[#475467]
                        className={`font-medium text-sm text-main-dark !mb-1 !mt-5 ${labelClassName}`}
                    >
                        {label}
                    </p>
                )}
                <div
                    className={`bg-[#EAECF0] cursor-pointer relative flex w-full items-center justify-between rounded-lg !pr-4 h-[56px] ${className}`}
                    ref={ref}
                    onClick={() => setShow((prev) => !prev)}
                >
                    <input
                        required={required}
                        className="w-full !pl-4 bg-transparent placeholder:font-normal text-main-dark placeholder:text-[15px] placeholder:text-main-grey pointer-events-none truncate !shadow-[inset_0_0_0_1000px_#EAECF0]"
                        placeholder={placeholder}
                        defaultValue={selectedOption?.label ?? value}
                    />
                    <ArrowIcon className="*:stroke-main-grey ml-2 size-3.5" />

                    {/* Dropdown */}
                    <div
                        className={`absolute left-0 top-[108%] z-10 bg-white max-h-[180px] min-w-[270px] w-full overflow-y-auto !border !border-[#E6E8F9] rounded-xl shadow-[-16px_-16px_16px_-4px_#10182808,16px_16px_32px_-4px_#10182814] transition-all duration-200 ${dropdownClassName} ${
                            show
                                ? "translate-y-0 opacity-100 visible z-20"
                                : "translate-y-[15%] opacity-0 invisible"
                        }`}
                    >
                        {options.map(({ label, value, disabled, action, className }) => (
                            <p
                                //bg-[#EBE4FF]
                                className={`font-medium text-sm py-[12px] px-[16px] cursor-pointer flex items-center gap-[16px] text-main rounded-lg transition-colors duration-300 ${
                                    disabled && "pointer-events-none !text-[#BCC2CC]"
                                } ${className}`}
                                onClick={() => (onChange ? onChange(value) : action())}
                                key={value}
                            >
                                {isValidElement(label) ? (
                                    label
                                ) : (
                                    <>
                                        {label}
                                        {selectedValue(value) && (
                                            <CheckCircleIcon className="ml-auto" />
                                        )}
                                    </>
                                )}
                            </p>
                        ))}
                    </div>
                </div>
            </>
        );
    }
);

export default Dropdown;
