import { Helmet } from "react-helmet";
import DashHeader from "../component/dashheader";
import homefinance from "../asset/images/flexlogo.svg";
import invoice from "../asset/images/invoice.svg";

import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import CardDetails from "../component/carddetails";
import Card from "../component/cards";
import FlexInvoice from "../component/flexinvoice";
import axios from "axios";

import "../style/main.css";
import AddBuyerModal from "./addbuyerModal";
import BuyerDetailsModa from "./buyersdetailsModal";
import DeleteModal from "./deleteModal";
import SendOrder from "./sendOrder";
import OrderDetails from "./orderDetails";
import AddFlexInvoice from "./addflexinvoice";
import EditBuyerDetails from "./editbuyerdetails";
import ActivePartner from "../component/ActivePartner";
import { useSelector } from "react-redux";
import OrderInvoice from "./OrderInvoice";
import PartnerInvoice from "./PartnerInvoice";
import OrderInPartner from "./orderinpartner";
import incompleteprofile from "../asset/images/incomplete_profile.svg";
import reviewprofile from "../asset/images/ReviewProgress.svg";
import WareHousing from "./wareHousing";
import Logistics from "./logistics";
import ReasonModalOrder from "./ReasonModalOrder";
import Navbar from "component/layout/navbar";

const FlexHome = () => {
    // const navigate = useNavigate()
    const [searchParams] = useSearchParams();
    const [mydate, setmydate] = useState(new Date());
    const [isDashboard, setisDashboard] = useState(false);
    const [isActivities, setisActivities] = useState(true);
    const [isPartner, setisPartner] = useState(false);
    const [isInvoice, setisInvoice] = useState(false);
    const [invoices, setinvoices] = useState([]);
    const [buyers, setbuyers] = useState([]);
    const [orders, setorders] = useState([]);
    const [flexinvoice, setflexinvoice] = useState([]);
    const [suppliers, setsuppliers] = useState([]);
    const [showupload, setshowupload] = useState(false);
    const [showorder, setshoworder] = useState(searchParams.get("createOrder"));
    const [showorder2, setshoworder2] = useState(false);

    const selectorbusiness = useSelector((state) => state.businessid);
    const [searchbanks, setsearchbanks] = useState("");
    const [selecteditem, setselecteditem] = useState([]);
    const [success, setsucess] = useState(false);
    const [showrequeststatment, setshowrequestStatement] = useState(false);
    const [selectedbuyers, setselectedbuyer] = useState([]);
    const [loader, setloader] = useState(false);
    const [deletes, setdelete] = useState(false);
    const [showorderdetails, setshoworderdetails] = useState(false);
    const [orderdetails, setorderdetails] = useState([]);
    const [showModal3, setshowModal3] = useState(false);
    const [showModalInvoce, setshowModalInvoice] = useState(false);
    const [mode, setmode] = useState("");
    const [loaderset, setloaderflex] = useState(true);
    const [buyerloader, setbuyerloader] = useState(true);
    const [supplierloader, setsupplierloader] = useState(true);
    const [selecteditems, setselecteditems] = useState([]);
    const [businessname, setbusinessname] = useState("");
    const [showUpload, setupload] = useState(false);
    const [show, setshow] = useState(false);
    const [pending, setpending] = useState([]);
    const [incoming, setincoming] = useState([]);
    const [active, setactive] = useState([]);
    const [showedit, setshowedit] = useState(false);
    const [loaderset2, setloaderset2] = useState(true);
    const [message, setmessage] = useState("");
    const [tab, settab] = useState("active");
    const [fail, setfail] = useState(false);
    const [t, sett] = useState("");
    const [orderInvoice, selectedorderinvoice] = useState([]);
    const [orderDocument, selectedorderdocument] = useState([]);
    const [firstName, setfirstName] = useState("");
    const [lastName, setlastName] = useState("");
    const [email, setEmail] = useState("");
    const [phoneNumber, setphoneNumber] = useState("");
    const [loaderorder, setloaderorder] = useState(true);
    const selectorbusinesss = useSelector((state) => state.business);
    const [showorderreason, setshowordereason] = useState(false);
    const [reason, setreason] = useState("");
    const [orderStatus, setOrderStatus] = useState("Active");

    const businessIdHeaderConfig = {
        headers: { "x-business-id": selectorbusinesss?._id },
    };

    useEffect(() => {
        if (selectedbuyers.length !== 0 && selectorbusinesss?._id) {
            const getUsersOrders = async () => {
                await axios
                    .get(`/v1/flex/orders/user/${selectedbuyers._id}`, businessIdHeaderConfig)
                    .then((res) => {
                        setorders(res.data.data);
                        setloaderorder(false);
                    })
                    .catch((e) => {
                        setorders([]);
                        setloaderorder(false);

                        console.log(e);
                    });
            };
            getUsersOrders();
        }
    }, [selectedbuyers, selectorbusinesss]);

    useEffect(() => {
        const fetchaccount = async () => {
            await axios
                .get("/v1/invoice/all?type=flex", businessIdHeaderConfig)
                .then((res) => {
                    setinvoices(res.data.data);
                })
                .catch((e) => {
                    setinvoices([]);
                    console.log(e);
                });
        };
        selectorbusinesss?._id && fetchaccount();
    }, [selectorbusinesss]);
    useEffect(() => {
        const fetchbuyer = async () => {
            await axios
                .get("/v1/flex/orders", businessIdHeaderConfig)
                .then((res) => {
                    setbuyers(res.data.data.reverse()?.slice(0, 10));
                    setbuyerloader(false);
                    setloaderset2(false);
                })
                .catch(() => {
                    // console.log(e);
                    setbuyers([]);
                    setbuyerloader(false);
                    setloaderset2(false);
                });
        };
        selectorbusinesss?._id && fetchbuyer();
    }, [selectorbusinesss]);
    useEffect(() => {
        const flexInvoice = async () => {
            await axios
                .get("/v1/invoice/all?type=flex", businessIdHeaderConfig)
                .then((res) => {
                    setloaderflex(false);
                    setflexinvoice(res.data.data.reverse());
                })
                .catch((e) => {
                    console.log(e);
                    setflexinvoice([]);
                    setloaderflex(false);
                });
        };
        selectorbusinesss?._id && flexInvoice();
    }, [selectorbusinesss]);
    const reloadOrder = async () => {
        await axios
            .get("/v1/flex/orders", businessIdHeaderConfig)
            .then((res) => {
                setbuyers(res.data.data);
                setbuyerloader(false);
                setloaderset2(false);
            })
            .catch(() => {
                // console.log(e);
                setloaderset2(false);
                setbuyers([]);
                setbuyerloader(false);
            });
    };
    useEffect(() => {
        const fetchactiveuser = async () => {
            await axios
                .get("/v1/flex/users", businessIdHeaderConfig)
                .then((res) => {
                    setactive(res.data.data);
                    setsupplierloader(false);
                })
                .catch(() => {
                    setactive([]);
                    setsupplierloader(false);

                    // console.log(e);
                });
        };
        selectorbusinesss?._id && fetchactiveuser();
    }, [selectorbusinesss]);
    const restartOrder = async () => {
        await axios
            .get(`/v1/flex/order/${orderdetails._id}`, businessIdHeaderConfig)
            .then((res) => {
                setorderdetails(res.data.data);
            })
            .catch((e) => {
                console.log(e);
                setorderdetails([]);
            });
    };

    useEffect(() => {
        const fetchincominguser = async () => {
            await axios
                .get("/v1/flex/users/incoming", businessIdHeaderConfig)
                .then((res) => {
                    //console.log(res);
                    setincoming(res.data.data);
                    setsupplierloader(false);
                })
                .catch((e) => {
                    setsupplierloader(false);
                    setincoming([]);

                    console.log(e);
                });
        };
        selectorbusinesss?._id && fetchincominguser();
    }, [selectorbusinesss]);
    useEffect(() => {
        const fetchpendinguser = async () => {
            await axios
                .get("/v1/flex/users/pending", businessIdHeaderConfig)
                .then((res) => {
                    setpending(res.data.data);
                    setsupplierloader(false);
                })
                .catch((e) => {
                    setsupplierloader(false);
                    setpending([]);

                    console.log(e);
                });
        };
        selectorbusinesss?._id && fetchpendinguser();
    }, [selectorbusinesss]);

    /**const filterBuyer = buyers.filter((item) =>
    item.paymentMethod?.match(searchbank)
  );
  **/

    const filterActive = active.filter(
        (item) =>
            item.firstName?.match(searchbanks) ||
            item.lastName?.match(searchbanks) ||
            item.businessName?.match(searchbanks)
    );

    const newarray = filterActive;
    newarray.sort((a, b) => a.firstName.localeCompare(b.firstName, "es", { sensitivity: "base" }));

    let datas = newarray.reduce((r, e) => {
        let alphabet = e.firstName[0];

        if (!r[alphabet])
            r[alphabet] = {
                alphabet,
                record: [],
            };

        r[alphabet].record.push(e);
        return r;
    }, {});
    let results = Object.values(datas);

    const filterIncoming = incoming.filter(
        (item) =>
            item.firstName?.match(searchbanks) ||
            item.lastName?.match(searchbanks) ||
            item.businessName?.match(searchbanks)
    );

    filterIncoming.sort((a, b) =>
        a.firstName.localeCompare(b.firstName, "es", { sensitivity: "base" })
    );
    let datasincoming = filterIncoming.reduce((r, e) => {
        let alphabet = e.firstName[0];

        if (!r[alphabet])
            r[alphabet] = {
                alphabet,
                record: [],
            };

        r[alphabet].record.push(e);
        return r;
    }, {});
    let resultsincoming = Object.values(datasincoming);

    const filterPending = pending.filter(
        (item) =>
            item.firstName?.match(searchbanks) ||
            item.lastName?.match(searchbanks) ||
            item.businessName?.match(searchbanks)
    );

    filterPending.sort((a, b) =>
        a.firstName.localeCompare(b.firstName, "es", { sensitivity: "base" })
    );
    let datapending = filterPending.reduce((r, e) => {
        let alphabet = e.firstName[0];

        if (!r[alphabet])
            r[alphabet] = {
                alphabet,
                record: [],
            };

        r[alphabet].record.push(e);
        return r;
    }, {});
    let resultspending = Object.values(datapending);
    // console.log(resultspending)
    const [cards] = useState([]);
    const navigate = useNavigate();

    const weekday = ["Sun", "Mon", "Tue", "Wed", "Thur", "Fri", "Sat"];
    let day = weekday[mydate.getDay()];

    const month = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "december",
    ];

    useEffect(() => {
        const timer = setInterval(() => {
            setmydate(new Date());
        }, 60000);
        return () => {
            clearInterval(timer);
        };
    });

    let months = month[mydate.getMonth()];
    let year = mydate.getFullYear();
    let today = mydate.getDate();
    let hrs = mydate.getHours();
    let min = mydate.getMinutes();
    useEffect(() => {
        if (success) {
            var timeout = setTimeout(() => {
                setsucess(false);
            }, 3000);
        }
        return () => {
            clearTimeout(timeout);
        };
    });
    useEffect(() => {
        if (deletes) {
            var timeout = setTimeout(() => {
                setdelete(false);
            }, 3000);
        }
        return () => {
            clearTimeout(timeout);
        };
    });
    const deletedBuyer = async () => {
        setloader(true);
        await axios
            .delete(`/v1/flex/user/${selectedbuyers._id}`)
            .then(() => {
                setloader(false);
                setshowModal3(false);
                if (mode === "buyer") {
                    const updatedHero = buyers.filter((item) => item._id !== selectedbuyers._id);
                    setbuyers(updatedHero);
                    setdelete(true);
                } else {
                    const updatedHeros = suppliers.filter(
                        (item) => item._id !== selectedbuyers._id
                    );
                    setsuppliers(updatedHeros);
                    setdelete(true);
                }
                setactive((prv) => {
                    const prev = [...prv];
                    prev.splice(
                        prev.findIndex(({ _id }) => _id === selectedbuyers._id),
                        1
                    );
                    return prev;
                });
            })
            .catch((e) => {
                console.log(e);
                setloader(false);
                setbuyers([]);
                setshowModal3(false);
            });
    };

    return (
        <div className="h-100 w-100 ">
            <div
                className=""
                style={{
                    maxWidth: "2000px",
                    margin: "0px auto",
                }}
            >
                <Helmet>
                    <title>Tradevu — flex</title>
                </Helmet>

                <AddFlexInvoice
                    show={showModalInvoce}
                    setshow={setshowModalInvoice}
                    invoice={flexinvoice}
                    setflexinvoice={setflexinvoice}
                    s={orderdetails}
                    success={success}
                    setsuccess={setsucess}
                    setmessage={setmessage}
                    message={message}
                    t={t}
                    sett={sett}
                    setfail={setfail}
                    selectedbuyers={selectedbuyers}
                    fail={fail}
                    setorderdetails={setorderdetails}
                    orderInvoice={orderInvoice}
                    selectedorderinvoice={selectedorderinvoice}
                    restartOrder={restartOrder}
                />

                <ActivePartner
                    show={showUpload}
                    handle={setupload}
                    selecteditem={selecteditems}
                    setbusinessname={setbusinessname}
                    setselecteditem={setselecteditems}
                    setfirstName={setfirstName}
                    setlastName={setlastName}
                    setEmail={setEmail}
                    setphoneNumber={setphoneNumber}
                    setfail={setfail}
                    fail={fail}
                />
                <EditBuyerDetails
                    setsuccess={setsucess}
                    selectedbuyers={selectedbuyers}
                    setselectedbuyer={setselectedbuyer}
                    show={showupload}
                    setshow={setshowupload}
                    setshowedit={setshowedit}
                    showedit={showedit}
                />
                <AddBuyerModal
                    setsuccess={setsucess}
                    mode={mode}
                    show={showupload}
                    setshow={setshowupload}
                    selecteditem={selecteditem}
                    setselecteditem={setselecteditem}
                    contacts={[]}
                    buyers={pending}
                    suppliers={active}
                    setsuppliers={setactive}
                    setbuyers={setpending}
                    setupload={setupload}
                    businessname={businessname}
                    firstName={firstName}
                    lastName={lastName}
                    email={email}
                    phoneNumber={phoneNumber}
                    selecteditems={selecteditems}
                    setselecteditems={setselecteditems}
                />
                <SendOrder
                    setsuccess={setsucess}
                    show={showorder}
                    setshowbuyer={setshowrequestStatement}
                    setshow={setshoworder}
                    selectedbuyers={selectedbuyers}
                    selecteditem={selecteditem}
                    setselecteditem={setselecteditem}
                    orders={buyers}
                    setorders={setbuyers}
                />
                <OrderInPartner
                    setsuccess={setsucess}
                    show={showorder2}
                    setshowbuyer={setshowrequestStatement}
                    setshow={setshoworder2}
                    selectedbuyers={selectedbuyers}
                    selecteditem={selecteditem}
                    setselecteditem={setselecteditem}
                    orders={buyers}
                    setorders={setbuyers}
                    reloadOrder={reloadOrder}
                />

                <Navbar />

                <BuyerDetailsModa
                    setshoworder={setshoworder2}
                    setshowModal3={setshowModal3}
                    mode={mode}
                    show={showrequeststatment}
                    setshow={setshowrequestStatement}
                    selectedbuyers={selectedbuyers}
                    orders={orders}
                    setorderdetails={setorderdetails}
                    setorders={setorders}
                    setshoworderdetails={setshoworderdetails}
                    shows={setshowupload}
                    loaderset2={loaderorder}
                    orderStatus={orderStatus}
                    setOrderStatus={setOrderStatus}
                    setshowedit={setshowedit}
                    tab={tab}
                    setsuppliers={setactive}
                    suppliers={active}
                />
                <OrderDetails
                    setshoworder={setshoworder}
                    orderdetails={orderdetails}
                    orderInvoice={orderInvoice}
                    setreason={setreason}
                    setshowModal3={setshowModal3}
                    show={showorderdetails}
                    setshowordereason={setshowordereason}
                    setshow={setshoworderdetails}
                    selectedbuyers={selectedbuyers}
                    orders={orders}
                    setorders={setorders}
                    success={success}
                    fail={fail}
                    message={message}
                    t={t}
                    setmessage={setmessage}
                    sett={sett}
                    setfail={setfail}
                    setsuccess={setsucess}
                    setshowModalInvoice={setshowModalInvoice}
                    selectedorderdocument={selectedorderdocument}
                    orderDocument={orderDocument}
                />
                <DeleteModal
                    loader={loader}
                    show={showModal3}
                    selectedbuyers={selectedbuyers}
                    setshow={setshowModal3}
                    mode={mode}
                    deletedBuyer={deletedBuyer}
                    subject={mode === "buyer" ? "Delete buyer" : "Delete Supplier"}
                    title={`You would lose access to all orders from this ${mode}. Do you want to continue?`}
                />
                <CardDetails show={show} setshow={setshow} />
            </div>
            <div
                style={{
                    height: "100%",
                    maxWidth: "2000px",
                    margin: "0px auto",
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    gap: "32px",
                    marginTop: "40px",
                }}
            >
                <div
                    className=" "
                    style={{
                        margin: "0px auto",

                        width: "100%",
                        padding: "0px 80px",
                    }}
                >
                    <div className="row align-items-center">
                        <div className="col-lg-7 col-md-7 ">
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    flexDirection: "column",
                                    padding: "21px",
                                    gap: "8px",
                                }}
                            >
                                <h1
                                    style={{
                                        fontSize: "32px",
                                        lineHeight: "40px",
                                        color: "#344054",
                                        fontWeight: "500",
                                        padding: "0px",
                                        margin: "0px",
                                    }}
                                >
                                    Flex
                                </h1>
                                <span
                                    style={{
                                        lineHeight: "24px",
                                        fontSize: "16px",
                                        fontWeight: "400",
                                        color: "#101828",
                                    }}
                                >
                                    Manage your supply chain transactions and internal business
                                    operations here
                                </span>
                            </div>
                        </div>
                        <div className="col-lg-5 col-md-5 ">
                            <div
                                className="d-flex"
                                style={{
                                    flexDirection: "column",
                                    justifyContent: "end",
                                    alignItems: "end",
                                    paddingRight: "30px",
                                }}
                            >
                                <span
                                    style={{
                                        color: "#667085",
                                        lineHeight: "24px",
                                        fontWeight: "500",
                                        letterSpacing: "2%",
                                    }}
                                >
                                    {" "}
                                    {hrs}:{min}{" "}
                                </span>
                                <span
                                    style={{
                                        color: "#667085",
                                        lineHeight: "24px",
                                        fontWeight: "500",
                                        letterSpacing: "2%",
                                    }}
                                >
                                    {" "}
                                    {day},<span> {today}</span>
                                    <span> {months} </span>
                                    {year}{" "}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                {selectorbusinesss.status === "PENDING" || selectorbusinesss?.length === 0 ? (
                    <>
                        <div className="incompletebusiness">
                            <div className="closure_up_absolute">
                                <div className="ineer_incomplete_business">
                                    <div className="inner_business_content">
                                        <img src={incompleteprofile} alt="" />
                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "column",
                                            }}
                                        >
                                            <div
                                                style={{
                                                    color: "#fff",
                                                    fontSize: "16px",
                                                    lineHeight: "24px",
                                                    fontWeight: "600",
                                                }}
                                            >
                                                Complete your profile
                                            </div>
                                            <span
                                                style={{
                                                    color: "#D9CDFF",
                                                    fontWeight: "400",
                                                    fontSize: "14px",
                                                    letterSpacing: "-1%",
                                                }}
                                            >
                                                Complete your profile to access trade finance
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                ) : (
                    ""
                )}
                {selectorbusinesss.status === "SUBMITTED" && (
                    <>
                        <div className="incompletebusiness">
                            <div className="closure_up_absolute">
                                <div className="ineer_incomplete_business">
                                    <div
                                        className="inner_business_content"
                                        style={{
                                            background: "#FEDF89",
                                        }}
                                    >
                                        <img src={reviewprofile} alt="" />
                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "column",
                                            }}
                                        >
                                            <div
                                                style={{
                                                    color: "#000",
                                                    fontSize: "16px",
                                                    lineHeight: "24px",
                                                    fontWeight: "600",
                                                }}
                                            >
                                                Your Business is under review
                                            </div>
                                            <span
                                                style={{
                                                    color: "#7A2E0E",
                                                    fontWeight: "400",
                                                    fontSize: "14px",
                                                    letterSpacing: "-1%",
                                                }}
                                            >
                                                Please wait while we confirm your documents
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )}
                {selectorbusinesss.status === "PROCESSING" && (
                    <>
                        <div className="incompletebusiness">
                            <div
                                className="closure_up_absolute"
                                style={{
                                    background: "#FEDF89",
                                }}
                            >
                                <div className="ineer_incomplete_business">
                                    <div className="inner_business_content">
                                        <img src={incompleteprofile} alt="" />
                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "column",
                                            }}
                                        >
                                            <div
                                                style={{
                                                    color: "#fff",
                                                    fontSize: "16px",
                                                    lineHeight: "24px",
                                                    fontWeight: "600",
                                                }}
                                            >
                                                Your Business is under review
                                            </div>
                                            <span
                                                style={{
                                                    color: "#D9CDFF",
                                                    fontWeight: "400",
                                                    fontSize: "14px",
                                                    letterSpacing: "-1%",
                                                }}
                                            >
                                                Your business is under review.
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )}
                {selectorbusinesss.status === "DEACTIVATE" && (
                    <>
                        <div
                            className="incompletebusiness"
                            style={{
                                background: "crimson",
                            }}
                        >
                            <div className="closure_up_absolute">
                                <div className="ineer_incomplete_business">
                                    <div className="inner_business_content">
                                        <img src={incompleteprofile} alt="" />
                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "column",
                                            }}
                                        >
                                            <div
                                                style={{
                                                    color: "#fff",
                                                    fontSize: "16px",
                                                    lineHeight: "24px",
                                                    fontWeight: "600",
                                                }}
                                            >
                                                Your Business is deactivated
                                            </div>
                                            <span
                                                style={{
                                                    color: "#D9CDFF",
                                                    fontWeight: "400",
                                                    fontSize: "14px",
                                                    letterSpacing: "-1%",
                                                }}
                                            >
                                                Your business is deactivated.
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )}
                <div>
                    <div
                        className="container"
                        style={{
                            maxWidth: "2000px",
                            margin: "0px auto",
                            display: "flex",
                            padding: "0px 80px",
                            marginTop:
                                selectorbusinesss.length === 0
                                    ? "70px"
                                    : selectorbusinesss.status === "PENDING"
                                    ? "70px"
                                    : selectorbusinesss.status === "SUBMITTED"
                                    ? "70px"
                                    : selectorbusinesss.status === "PROCESSING"
                                    ? "70px"
                                    : selectorbusinesss.status === "DEACTIVATE"
                                    ? "70px"
                                    : "",
                        }}
                    >
                        <ul
                            className="d-flex"
                            style={{
                                justifyContent: "flex-start",
                                listStyle: "none",
                                gap: "24px",
                                marginBottom: "0px",
                                width: "100%",
                                margin: "0px auto",
                                paddingLeft: "25px",
                            }}
                        >
                            {/**
              <li
                onClick={() => {
                  setisDashboard(false);
                  setisInvoice(true);
                  setisActivities(false);
                  setisPartner(false);
                }}
                style={{
                  color: isInvoice ? "#101828" : "#667085",
                  fontSize: "1rem",
                  lineHeight: "32px",
                  fontWeight: isInvoice ? "500" : "400",
                  letterSpacing: "2%",
                  cursor: "pointer",
                  borderBottom: isInvoice ? "2px solid #6F00FF" : "",
                }}
              >
                Invoices
              </li>
              */}
                            <li
                                onClick={() => {
                                    setisDashboard(false);
                                    setisInvoice(false);
                                    setisActivities(true);
                                    setisPartner(false);
                                }}
                                style={{
                                    color: isActivities ? "#101828" : "#667085",
                                    fontSize: "1rem",
                                    lineHeight: "32px",
                                    fontWeight: isActivities ? "700" : "400",
                                    letterSpacing: "2%",
                                    display: "flex",
                                    gap: "8px",
                                    cursor: "pointer",
                                    borderBottom: isActivities ? "3px solid #6F00FF" : "",
                                }}
                            >
                                Procurement
                            </li>
                            <li
                                onClick={() => {
                                    setisDashboard(false);
                                    setisInvoice(false);
                                    setisActivities(false);
                                    setisPartner(true);
                                }}
                                style={{
                                    color: isPartner ? "#101828" : "#667085",
                                    fontSize: "1rem",
                                    lineHeight: "32px",
                                    fontWeight: isPartner ? "700" : "400",
                                    letterSpacing: "2%",
                                    display: "flex",
                                    gap: "8px",
                                    cursor: "pointer",
                                    borderBottom: isPartner ? "3px solid #6F00FF" : "",
                                }}
                            >
                                Network
                            </li>
                            <li
                                onClick={() => {
                                    setisDashboard(true);
                                    setisInvoice(false);
                                    setisActivities(false);
                                    setisPartner(false);
                                }}
                                style={{
                                    color: isDashboard ? "#101828" : "#667085",
                                    fontSize: "1rem",
                                    lineHeight: "32px",
                                    fontWeight: isDashboard ? "700" : "400",
                                    letterSpacing: "2%",
                                    display: "flex",
                                    gap: "8px",
                                    cursor: "pointer",
                                    borderBottom: isDashboard ? "3px solid #6F00FF" : "",
                                }}
                            >
                                Inventory
                            </li>
                            <li
                                onClick={() => {
                                    setisDashboard(false);
                                    setisInvoice(true);
                                    setisActivities(false);
                                    setisPartner(false);
                                }}
                                style={{
                                    color: isInvoice ? "#101828" : "#667085",
                                    fontSize: "1rem",
                                    lineHeight: "32px",
                                    fontWeight: isInvoice ? "700" : "400",
                                    letterSpacing: "2%",
                                    display: "flex",
                                    gap: "8px",
                                    cursor: "pointer",
                                    borderBottom: isInvoice ? "3px solid #6F00FF" : "",
                                }}
                            >
                                Logistics
                            </li>
                        </ul>
                    </div>

                    <div
                        className=" "
                        style={{
                            margin: "0px auto",
                            width: "100%",
                            padding: "0px 80px 0px 80px",
                        }}
                    >
                        <div
                            className="card border-0"
                            style={{
                                borderRadius: "24px 24px 0px 0px",
                                background: "#FCFCFD",
                            }}
                        >
                            {" "}
                            {isInvoice && (
                                <div
                                    className="card-body"
                                    style={{
                                        minHeight: "977px",
                                        display: "flex",
                                        flexDirection: "column",
                                        gap: "16px",
                                        borderRadius: "16px",
                                        overflowY: "auto",
                                        padding: "0px",
                                    }}
                                >
                                    <Logistics
                                        invoice={buyers}
                                        show={showModalInvoce}
                                        setshow={setshowModalInvoice}
                                        s={showorder}
                                        ss={setshoworder}
                                        loaderset={loaderset2}
                                        os={setshoworderdetails}
                                        setselectedbuyer={setorderdetails}
                                        selectedorderinvoice={selectedorderinvoice}
                                        selectedorderdocument={selectedorderdocument}
                                    />
                                </div>
                            )}
                            {isPartner && (
                                <div
                                    className="card-body"
                                    style={{
                                        minHeight: "977px",
                                        display: "flex",
                                        flexDirection: "column",
                                        gap: "16px",
                                        borderRadius: "16px",
                                        overflowY: "auto",
                                        padding: "0px",
                                    }}
                                >
                                    <PartnerInvoice
                                        invoice={invoices}
                                        setinvoices={setinvoices}
                                        s={showupload}
                                        ss={setshowupload}
                                        loaderset={supplierloader}
                                        active={results}
                                        setActive={setactive}
                                        setshowdetail={setshowrequestStatement}
                                        sb={setselectedbuyer}
                                        pending={resultspending}
                                        incoming={resultsincoming}
                                        st={settab}
                                        activePartnersCount={newarray.length || ""}
                                    />
                                </div>
                            )}
                            {isActivities && (
                                <div
                                    className=""
                                    style={{
                                        minHeight: "977px",
                                        height: "100%",
                                    }}
                                >
                                    <OrderInvoice
                                        invoice={buyers}
                                        show={showModalInvoce}
                                        setshow={setshowModalInvoice}
                                        s={showorder}
                                        ss={setshoworder}
                                        loaderset={loaderset2}
                                        os={setshoworderdetails}
                                        setselectedbuyer={setorderdetails}
                                        selectedorderinvoice={selectedorderinvoice}
                                        selectedorderdocument={selectedorderdocument}
                                    />
                                    <ReasonModalOrder
                                        show={showorderreason}
                                        setshow={setshowordereason}
                                        reason={reason}
                                    />
                                </div>
                            )}
                            {isDashboard && (
                                <div
                                    className=""
                                    style={{
                                        minHeight: "977px",
                                        height: "100%",
                                    }}
                                >
                                    <WareHousing
                                        invoice={buyers}
                                        show={showModalInvoce}
                                        setshow={setshowModalInvoice}
                                        s={showorder}
                                        ss={setshoworder}
                                        loaderset={loaderset2}
                                        os={setshoworderdetails}
                                        setselectedbuyer={setorderdetails}
                                        selectedorderinvoice={selectedorderinvoice}
                                        selectedorderdocument={selectedorderdocument}
                                    />
                                </div>
                            )}{" "}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FlexHome;
