const Divider = ({ date }) =>
    date ? (
        <p className={`flex items-center text-[#98A2B3] font-medium text-sm mt-2`}>
            {date} <span className="h-[1px] flex-1 bg-[#E7E9FB] ml-2" />
        </p>
    ) : (
        <div className={`h-[1px] w-full bg-[#E7E9FB]`} />
    );

export default Divider;
