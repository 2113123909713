import { Helmet } from "react-helmet";
import ShareInvoiceHeader from "../../component/ShareHeader";
import top from "../../asset/images/top stuff.png";
import tradevuesvg from "../../asset/images/tradevusvg.png";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { useRef } from "react";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";

const ShareInvoice = () => {
  const { state } = useLocation();
  const selector = useSelector((state) => state.users);
  const imageRef = useRef(null);
  const downloadinvoice = () => {
    const input = imageRef.current;
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4", true);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();
      const imgWidth = canvas.width;
      const imgHeight = canvas.height;
      const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight);
      const imgX = (pdfWidth - imgWidth * ratio) / 2;
      const imgY = 30;
      pdf.addImage(
        imgData,
        "PNG",
        imgX,
        imgY,
        imgWidth * ratio,
        imgHeight * ratio
      );
      pdf.save("invoice.pdf");
    });
  };
  /*
    const handleShare = async () => {
        const newFile = await toBlob(imageRef.current);
        const data = {
          files: [
            new File([newFile], "invoice.png", {
              type: newFile.type
            })
          ],
          title: "Invoice",
          text: "Invoice"
        };
    
        try {
          if (!navigator.canShare(data)) {
            console.error("Can't share");
          }
          await navigator.share(data);
        } catch (err) {
          console.error(err);
        }
      };
*/
  return (
    <div className="h-100 w-100 ">
      <div
        className=""
        style={{
          maxWidth: "2000px",
          width: "100%",
          margin: "0px auto",
        }}
      >
        <Helmet>
          <title>Invoice</title>
        </Helmet>
        <ShareInvoiceHeader
          header={"Share Invoice"}
          isbtn={true}
          downloadinvoice={downloadinvoice}
        />
        <div className="shareClass">
          <div
            ref={imageRef}
            className="form-general-layout"
            style={{
              gap: "0px",
              maxWidth: "591px",
            }}
          >
            <div
              style={{
                position: "relative",
              }}
            >
              <img
                src={top}
                alt=""
                style={{
                  height: "96px",
                  width: "100%",
                  borderTopRightRadius: "8px",
                  borderTopLeftRadius: "8px",
                }}
              />
              <img src={tradevuesvg} alt="" className="absolute-img" />
            </div>
            <div className="invoice-body">
              <h3>Invoice</h3>
              <div className="bodey-details">
                <span className="shaare-deails">Details</span>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "4px",
                  }}
                >
                  <span className="shaare-deails">{state.invoiceTitle}</span>
                  <span className="invoice-date-lg">
                    Issued: {new Date(state.issueDate).toDateString()}
                  </span>
                  <span className="invoice-date-lg">
                    Due: {new Date(state.dueDate).toDateString()}
                  </span>
                </div>
              </div>
              <div className="to-from-lg-mg">
                <div className="to-details">
                  <span className="shaare-deails">To</span>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "4px",
                    }}
                  >
                    <span
                      className="shaare-deails"
                      style={{
                        color: "#344054",
                      }}
                    >
                      {state.firstName} {state.lastName}
                    </span>
                    <span
                      className="invoice-date-lg"
                      style={{
                        maxWidth: "135px",
                      }}
                    >
                      01, Neverland highway Tradevu Way Nigeria
                    </span>
                  </div>
                </div>
                <div className="to-details">
                  <span className="shaare-deails">From</span>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "4px",
                    }}
                  >
                    <span
                      className="shaare-deails"
                      style={{
                        color: "#344054",
                      }}
                    >
                      {selector.firstName} {selector.lastName}
                    </span>
                    <span
                      className="invoice-date-lg"
                      style={{
                        maxWidth: "135px",
                      }}
                    >
                      01, Neverland highway Tradevu Way Nigeria
                    </span>
                  </div>
                </div>
              </div>
              <table className="table mt-5">
                <thead>
                  <tr
                    style={{
                      borderTop: "1px solid #D7DDEA",
                      borderBottom: "1px solid #D7DDEA",
                    }}
                  >
                    <th scope="col" className="my-share-table">
                      Items
                    </th>
                    <th
                      scope="col"
                      className="my-share-table"
                      style={{
                        textAlign: "right",
                      }}
                    >
                      Quantity
                    </th>
                    <th
                      scope="col"
                      className="my-share-table"
                      style={{
                        textAlign: "right",
                      }}
                    >
                      Unit price
                    </th>
                    <th
                      scope="col"
                      className="my-share-table"
                      style={{
                        textAlign: "right",
                      }}
                    >
                      Total
                    </th>
                  </tr>
                </thead>
                <tbody
                  style={{
                    border: "none",
                  }}
                >
                  {state.invoiceItems.map((item, index) => (
                    <tr
                      style={{
                        borderTop: "none",
                        borderBottom: "none",
                        borderStyle: "none",
                        borderBottomStyle: "none",
                      }}
                      key={index}
                    >
                      <th
                        scope="row"
                        style={{
                          borderStyle: "none",
                        }}
                      >
                        {item.itemName}
                      </th>
                      <td
                        style={{
                          borderStyle: "none",
                          textAlign: "right",
                        }}
                      >
                        {item.quantity}
                      </td>
                      <td
                        style={{
                          borderStyle: "none",
                          textAlign: "right",
                        }}
                      >
                        {state.currency === "NGN"
                          ? "₦"
                          : state.currency === "GBP"
                          ? "£"
                          : "€"}
                        {parseFloat(item.pricePerUnit).toLocaleString()}
                      </td>
                      <td
                        style={{
                          borderStyle: "none",
                          textAlign: "right",
                        }}
                      >
                        {state.currency === "NGN"
                          ? "₦"
                          : state.currency === "GBP"
                          ? "£"
                          : "€"}{" "}
                        {parseFloat(
                          item.quantity * item.pricePerUnit
                        ).toLocaleString()}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="total-sub-lg">
                <div className="each-item-sub-lg">
                  <span className="regular-span">Subtotal</span>
                  <span className="regular-totla">
                    {" "}
                    {state.currency === "NGN"
                      ? "₦"
                      : state.currency === "GBP"
                      ? "£"
                      : "€"}
                    {parseFloat(state.totalAmount).toLocaleString()}{" "}
                  </span>
                </div>
                <div className="each-item-sub-lg">
                  <span className="regular-span">VAT (3%)</span>
                  <span className="regular-totla">
                    {state.currency === "NGN"
                      ? "₦"
                      : state.currency === "GBP"
                      ? "£"
                      : "€"}
                    25 000
                  </span>
                </div>
                <div className="each-item-sub-lg">
                  <span className="regular-span">Paid</span>
                  <span className="regular-totla">
                    {state.currency === "NGN"
                      ? "₦"
                      : state.currency === "GBP"
                      ? "£"
                      : "€"}
                    {parseFloat(
                      state.totalAmount + state.totalAmount * 0.3
                    ).toLocaleString()}{" "}
                  </span>
                </div>
              </div>
              <div className="text-lg-mrbottom">
                If you have questions for us, please call a representative at{" "}
                <span
                  style={{
                    color: "#6F00FF",
                  }}
                >
                  08030303030
                </span>{" "}
                or send a mail to{" "}
                <span
                  style={{
                    color: "#6F00FF",
                  }}
                >
                  me@tradevu.com
                </span>
                Thanks for using Tradevu
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShareInvoice;
