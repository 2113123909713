import Modal from "react-bootstrap/Modal";
import "../style/rightside.css";
import PaymentMethod from "./paymentmethod";
// import addbvn from '../asset/images/addbvn.png'
import { useState } from "react";

const RecordPayment = (props) => {
  const [show, setshow] = useState(false);
  const [tenureday, settenureday] = useState(["Tradevu Account", "Cash"]);
  const [, setselectedmethod] = useState("");

  return (
    <>
      <PaymentMethod
        show={show}
        setshow={setshow}
        tenure={tenureday}
        selectedinvoice={props.selectedinvoice}
        setselectedmethod={setselectedmethod}
        settenureday={settenureday}
        record={props.record}
        setinvoices={props.setinvoices}
        currency={props.currency}
      />
      <Modal
        show={props.show}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="addpaymentmodal"
        onHide={() => props.setshow(false)}
      >
        <Modal.Header>
          <span
            className="material-icons"
            style={{ cursor: "pointer" }}
            onClick={() => {
              props.setshow(false);
            }}
          >
            close
          </span>
        </Modal.Header>
        <Modal.Body className="border-0 ">
          <form action="">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "32px",
              }}
            >
              <h4
                style={{
                  color: "#101828",
                  fontWeight: "700",
                  fontSize: "18px",
                  lineHeight: "24.3px",
                }}
              >
                Record a new payment
              </h4>

              <select
                type="text"
                placeholder="Method of payment"
                readonly
                style={{
                  border: "none",
                  outline: "none",
                  padding: "8px 16px 8px 16px",
                  borderRadius: "8px",
                  gap: "8px",
                  display: "flex",
                  height: "50px",
                  background: "#ECEFF3",
                  color: "#667085",
                  letterSpacing: "2%",
                  lineHeight: "24px",
                  fontWeight: "400",
                  fontSize: "16px",
                }}
                onFocus={() => {
                  props.setshow(false);
                  setshow(true);
                }}
              ></select>
              <button
                style={{
                  background: "#6F00FF",
                  padding: "16px 24px 16px 24px",
                  gap: "8px",
                  height: "50px",
                  outline: "none",
                  border: "none",
                  borderRadius: "8px",
                  color: "#fff",
                  textAlign: "center",
                  fontSize: "1rem",
                  lineHeight: "24px",
                  letterSpacing: "2%",
                  fontWeight: "500",
                }}
              >
                Record payment
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default RecordPayment;
