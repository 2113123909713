import { useState } from "react";
import search from "../asset/images/search-normal.svg";
import invoice from "../asset/images/invoice.svg";
import addrecrecipt from "../asset/images/flex/receipt-add.svg";
import InvoiceDetails from "./invoicedetails";

import longinvoice from "../asset/images/flex/longinvoice.svg";
import { useNavigate } from "react-router-dom";
import DeleteFlexInvoice from "./DeleteFlexInvoice";
import US from "../asset/images/US.png";
import axios from "axios";
import { useEffect } from "react";
import ComponentLoader from "./componentloader";
import NGNcurrency from "../asset/images/Currency.svg";
import CurrencyModal from "./currencyModal";
import euro from "../asset/images/EU.svg";
import pounds from "../asset/images/GB.svg";
import point from "../asset/images/point.svg";
import currencyPairCheck from "../utilities/currencyPairCheck";
import AmountCheck from "./amountCheck";

const FlexInvoice = (props) => {
    const [tab, settab] = useState("buyer");
    const [searchbank, setsearchbank] = useState("");
    const [totalinvoice, settotalinvoice] = useState(0);
    const [totalAmountOfAllInvoices, settotalAmountOfAllInvoices] = useState(0);
    const [totalAmountunpaid, settotalUnpaidAmount] = useState(0);
    const [totalupaid, settotalUnpaid] = useState(0);
    const [inputSearch, setinputsearch] = useState("");
    const [show, setshow] = useState(false);
    const [showModal3, setshowModal3] = useState(false);
    const [loader, setload] = useState(false);
    const [showcurrencymodal, setshowcurrencymodal] = useState(false);
    const navigate = useNavigate();
    const [deletes, setdelete] = useState(false);
    const [tenureday, settenureday] = useState("NGN");
    // console.log(props.invoice);

    //console.log(inputSearch);

    const filteredData = props.invoice.filter(
        (item) =>
            (item.totalAmount && item.totalAmount.toString().includes(inputSearch)) ||
            (item.invoiceTitle &&
                item.invoiceTitle.toLowerCase().includes(inputSearch.toLowerCase())) ||
            (item.email && item.email.toLowerCase().includes(inputSearch.toLowerCase())) ||
            (item.firstName && item.firstName.toLowerCase().includes(inputSearch.toLowerCase())) ||
            (item.lastName && item.lastName.toLowerCase().includes(inputSearch.toLowerCase())) ||
            (item.phoneNumber && item.phoneNumber.toLowerCase().includes(inputSearch.toLowerCase()))
    );
    // console.log(filteredData);
    const filterBank = filteredData.filter((item) =>
        item.paymentStatus?.toLowerCase()?.includes(searchbank?.toLowerCase())
    );
    /*
  const filterBanks = props.invoice.filter(
    (item) => item.paymentStatus.match(searchbank || inputsearch)
  );
*/
    props.invoice.sort((a, b) =>
        a.createdAt.localeCompare(b.createdAt, "es", { sensitivity: "base" })
    );
    let data = filterBank.reduce((r, e) => {
        let alphabet = new Date(e.createdAt).toDateString();
        if (!r[alphabet])
            r[alphabet] = {
                alphabet,
                record: [],
            };

        r[alphabet].record.push(e);
        return r;
    }, {});
    let result = Object.values(data);
    // console.log(result);
    useEffect(() => {
        const fetchSummary = async () => {
            await axios
                .get(`/v1/invoice/summary?currency=${tenureday}`)
                .then((res) => {
                    settotalinvoice(res.data.data[0]?.totalNumberOfInvoices);
                    settotalAmountOfAllInvoices(res.data.data[0]?.totalAmountOfUnpaidInvoices);
                    settotalUnpaidAmount(res.data.data[0]?.totalAmountOfAllInvoices);
                    settotalUnpaid(res.data.data[0]?.totalNumberOfUnpaidInvoices);
                })
                .catch((e) => {
                    console.log(e);
                });
        };
        fetchSummary();
    }, [tenureday]);
    const getInvoiceSummary = async (value) => {
        await axios
            .get(`/v1/invoice/summary?currency=${value}`)
            .then((res) => {
                settotalinvoice(res.data.data[0]?.totalNumberOfInvoices);
                settotalAmountOfAllInvoices(res.data.data[0]?.totalAmountOfUnpaidInvoices);
                settotalUnpaidAmount(res.data.data[0]?.totalAmountOfAllInvoices);
                settotalUnpaid(res.data.data[0]?.totalNumberOfUnpaidInvoices);
            })
            .catch((e) => {
                console.log(e);
            });
    };
    const [selecteditem, setselecteditem] = useState([]);
    const deleteInvoiceFlex = async () => {
        setload(true);
        await axios
            .delete(`/v1/invoice/${selecteditem._id}`)
            .then((res) => {
                // console.log(res)
                const updatedHero = props.invoice.filter((item) => item._id !== selecteditem._id);
                props.setinvoices(updatedHero);
                setdelete(true);
                setshowModal3(false);
                setload(false);
                setshow(false);
            })
            .catch((e) => {
                console.log(e);
                setload(false);
                setshowModal3(false);
            });
    };

    return (
        <div
            className="flex-m-invoice"
            style={{
                display: "flex",
                gap: "136px",
            }}
        >
            <InvoiceDetails
                show={show}
                setshow={setshow}
                selecteditem={selecteditem}
                setshow3={setshowModal3}
                deletes={deletes}
            />
            <DeleteFlexInvoice
                loader={loader}
                show={showModal3}
                setshow={setshowModal3}
                deletedBuyer={deleteInvoiceFlex}
            />
            <CurrencyModal
                show={showcurrencymodal}
                setshow={setshowcurrencymodal}
                tenureday={tenureday}
                settenureday={settenureday}
                getInvoiceSummary={getInvoiceSummary}
            />
            <div
                style={{
                    height: "100%",
                    background: "#fff",
                    minHeight: "977px",
                    padding: "32px",
                    borderRight: "1px solid #E7E9FB",
                }}
            >
                <div
                    className="long-flex-f"
                    style={{
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                        gap: "56px",
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "24px",
                        }}
                    >
                        <div className="currency_switch" onClick={() => setshowcurrencymodal(true)}>
                            <div className="currency_board">
                                <img
                                    src={
                                        tenureday === "NGN"
                                            ? NGNcurrency
                                            : tenureday === "GBP"
                                            ? pounds
                                            : tenureday === "USD"
                                            ? US
                                            : euro
                                    }
                                    alt=""
                                    style={{
                                        width: "20px",
                                        height: "20%",
                                    }}
                                />
                                <span className="currency_title">{tenureday}</span>
                                <span className="currency_title">
                                    {tenureday === "NGN"
                                        ? "Naira"
                                        : tenureday === "GBP"
                                        ? "British Pounds"
                                        : "Euro"}
                                </span>
                            </div>
                            <span className="material-icons">expand_more</span>
                        </div>
                        <div
                            style={{
                                borderRadius: "8px",
                                padding: "10px",
                                display: "flex",
                                background: "#EBE4FF",
                                justifyContent: "space-between",
                                flexDirection: "column",
                                height: "144px",
                                minWidth: "384px",
                            }}
                        >
                            <div
                                className="text-c-flex"
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: "4px",
                                }}
                            >
                                <span
                                    className="small-c"
                                    style={{
                                        color: "#7A7A87",
                                        fontSize: "14px",
                                        fontWeight: "500",
                                        lineHeight: "19.6px",
                                        letterSpacing: "2%",
                                    }}
                                >
                                    Total
                                </span>
                                <h1
                                    style={{
                                        fontWeight: "700",
                                        fontSize: "2rem",
                                        lineHeight: "40px",
                                    }}
                                >
                                    {currencyPairCheck(tenureday)}
                                    {AmountCheck(
                                        totalAmountOfAllInvoices !== undefined
                                            ? totalAmountOfAllInvoices
                                            : 0
                                    )}
                                </h1>
                            </div>
                            <span
                                style={{
                                    color: "#344054",
                                    fontWeight: "500",
                                    lineHeight: "19.6px",
                                    fontSize: "14px",
                                }}
                            >
                                {totalinvoice === undefined
                                    ? "No invoice"
                                    : totalinvoice + "  invoices"}{" "}
                            </span>
                        </div>
                        <div
                            style={{
                                borderRadius: "8px",
                                padding: "10px",
                                display: "flex",
                                background: "#FFE7D5",
                                justifyContent: "space-between",
                                flexDirection: "column",
                                height: "144px",
                                minWidth: "384px",
                            }}
                        >
                            <div
                                className="text-c-flex"
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: "4px",
                                }}
                            >
                                <span
                                    className="small-c"
                                    style={{
                                        color: "#7A7A87",
                                        fontSize: "14px",
                                        fontWeight: "500",
                                        lineHeight: "19.6px",
                                        letterSpacing: "2%",
                                    }}
                                >
                                    Total
                                </span>
                                <h1
                                    style={{
                                        fontWeight: "700",
                                        fontSize: "2rem",
                                        lineHeight: "40px",
                                    }}
                                >
                                    {currencyPairCheck(tenureday)}
                                    {AmountCheck(
                                        totalAmountunpaid !== undefined ? totalAmountunpaid : 0
                                    )}
                                </h1>
                            </div>
                            <span
                                style={{
                                    color: "#344054",
                                    fontWeight: "500",
                                    lineHeight: "19.6px",
                                    fontSize: "14px",
                                }}
                            >
                                {totalupaid === undefined
                                    ? "No unpaid invoice"
                                    : totalupaid + " Unpaid invoice"}{" "}
                            </span>
                        </div>
                    </div>
                    {filterBank.length > 0 && (
                        <button
                            style={{
                                background: "#6F00FF",
                                color: "#fff",
                                borderRadius: "8px",
                                padding: "16px 24px 16px 24px",
                                width: "100%",
                                border: "none",
                                maxWidth: "176px",
                                outline: "none",
                                display: "flex",
                                gap: "8px",
                                fontWeight: "600",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                            onClick={() => {
                                navigate("/flex/invoice");
                            }}
                        >
                            <img src={addrecrecipt} alt="" />
                            Add Invoice
                        </button>
                    )}{" "}
                </div>
            </div>

            <div
                className="main-c-flex"
                style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "32px",
                    width: "70%",
                    padding: "32px",
                }}
            >
                <div
                    className="tab-search"
                    style={{
                        gap: "16px",
                        display: "flex",
                        flexDirection: "column",
                    }}
                >
                    <div
                        style={{
                            background: "#ECEFF3",
                            borderRadius: "8px",
                            padding: "4px",
                            display: "flex",
                            gap: "8px",
                            justifyContent: "space-between",
                            height: "56px",
                        }}
                    >
                        <div
                            style={{
                                padding: "6px 8px 6px 8px",
                                background: tab === "buyer" ? "#fff" : "",
                                textAlign: "center",
                                borderRadius: "4px",
                                width: "50%",
                                cursor: "pointer",
                                color: tab === "buyer" ? "#101828" : "#667085",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                            onClick={() => {
                                settab("buyer");
                                setsearchbank("");
                            }}
                        >
                            Total
                        </div>
                        <div
                            style={{
                                background: tab === "unpaid" ? "#fff" : "",

                                padding: "6px 8px 6px 8px",
                                textAlign: "center",
                                borderRadius: "4px",
                                width: "50%",
                                cursor: "pointer",
                                color: tab === "unpaid" ? "#101828" : "#667085",
                                justifyContent: "center",
                                alignItems: "center",
                                display: "flex",
                            }}
                            onClick={() => {
                                settab("unpaid");
                                setsearchbank("unpaid");
                            }}
                        >
                            Unpaid
                        </div>
                        <div
                            style={{
                                background: tab === "draft" ? "#fff" : "",

                                padding: "6px 8px 6px 8px",
                                textAlign: "center",
                                borderRadius: "4px",
                                width: "50%",
                                cursor: "pointer",
                                color: tab === "draft" ? "#101828" : "#667085",
                                justifyContent: "center",
                                alignItems: "center",
                                display: "flex",
                            }}
                            onClick={() => {
                                settab("draft");
                                setsearchbank("draft");
                            }}
                        >
                            Draft
                        </div>
                    </div>
                    <div
                        style={{
                            background: "#ECEFF3",
                            display: "flex",
                            gap: "8px",
                            height: "48px",
                            borderRadius: "8px",
                            padding: "0px 8px 0px 8px",
                            alignItems: "center",
                        }}
                    >
                        <img src={search} alt="" />
                        <input
                            type="text"
                            style={{
                                color: "#667085",
                                fontSize: "14px",
                                fontWeight: "500",
                                lineHeight: "19.6px",
                                width: "100%",
                                outline: "none",
                                background: "#ECEFF3",
                                border: "none",
                            }}
                            onChange={(e) => {
                                setinputsearch(e.target.value);
                            }}
                            placeholder="Search finance type, amount and more"
                        />
                    </div>
                </div>
                {props.loaderset === false ? (
                    <>
                        {" "}
                        {filterBank.length === 0 && (
                            <div
                                className="no-flex-c"
                                style={{
                                    minHeight: "460px",
                                    overflow: "auto",
                                    padding: "16px 24px 24px 24px",
                                    borderRadius: "16px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}
                            >
                                <div
                                    style={{
                                        width: "100%",
                                        alignItems: "center",
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        gap: "24px",
                                    }}
                                >
                                    <img src={invoice} alt="" />
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            gap: "8px",
                                        }}
                                    >
                                        <h3
                                            style={{
                                                fontWeight: "700",
                                                color: "#101828",
                                                fontSize: "18px",
                                                lineHeight: "24.3px",
                                                textAlign: "center",
                                            }}
                                        >
                                            There are no invoices yet
                                        </h3>
                                        <span
                                            style={{
                                                color: "#667085",
                                                fontSize: "14px",
                                                lineHeight: "19.6px",
                                                fontWeight: "500",
                                                textAlign: "center",
                                                maxWidth: "229px",
                                            }}
                                        >
                                            Start adding your invoices to Tradevu to see them here.
                                        </span>
                                    </div>
                                    <button
                                        style={{
                                            background: "#6F00FF",
                                            color: "#fff",
                                            borderRadius: "8px",
                                            padding: "16px 24px 16px 24px",
                                            maxWidth: "229px",
                                            width: "100%",
                                            border: "none",
                                            outline: "none",
                                            display: "flex",
                                            gap: "8px",
                                            justifyContent: "center",
                                            alignItems: "center",
                                        }}
                                        onClick={() => {
                                            navigate("/flex/invoice");
                                        }}
                                    >
                                        <img src={addrecrecipt} alt="" />
                                        Add Invoice
                                    </button>
                                </div>
                            </div>
                        )}
                        {props.invoice.length >= 1 && (
                            <div style={{ height: "100%" }}>
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        gap: "16px",
                                    }}
                                >
                                    {result.reverse()?.map((invoce, index) => (
                                        <>
                                            <label
                                                key={index}
                                                htmlFor=""
                                                style={{
                                                    color: "#98A2B3",
                                                    fontSize: "12px",
                                                    fontWeight: "500",
                                                    lineHeight: "16.2px",
                                                    textTransform: "uppercase",
                                                }}
                                            >
                                                {new Date(invoce.alphabet).toDateString() ===
                                                new Date().toDateString()
                                                    ? "Today"
                                                    : new Date().getMonth() - 1 ===
                                                      new Date(invoce.alphabet).getMonth()
                                                    ? "Last Month"
                                                    : new Date(invoce.alphabet).toDateString()}{" "}
                                            </label>
                                            {invoce.record?.reverse().map((item, indexs) => (
                                                <>
                                                    {" "}
                                                    {item.invoiceTitle !== "" &&
                                                        item.invoiceTitle !== undefined && (
                                                            <div
                                                                key={`ll${indexs}`}
                                                                style={{
                                                                    border: "1px solid #E7E9FB",
                                                                    borderRadius: "8px",
                                                                    padding: "16px",
                                                                    display: "flex",
                                                                    alignItems: "center",
                                                                    justifyContent: "space-between",
                                                                    cursor: "pointer",
                                                                }}
                                                                onClick={() => {
                                                                    setshow(true);
                                                                    setselecteditem(item);
                                                                }}
                                                            >
                                                                <div
                                                                    style={{
                                                                        display: "flex",
                                                                        gap: "8px",
                                                                    }}
                                                                >
                                                                    <img src={longinvoice} alt="" />
                                                                    <div
                                                                        style={{
                                                                            display: "flex",
                                                                            flexDirection: "column",
                                                                            gap: "4px",
                                                                        }}
                                                                    >
                                                                        <span
                                                                            style={{
                                                                                color: "#101828",
                                                                                fontWeight: "600",
                                                                                fontSize: "14px",
                                                                                lineHeight:
                                                                                    "19.6px",
                                                                            }}
                                                                        >
                                                                            {item.invoiceTitle}{" "}
                                                                        </span>
                                                                        <span
                                                                            style={{
                                                                                color: "#667085",
                                                                                letterSpacing: "2%",
                                                                                fontWeight: "500",
                                                                                lineHeight:
                                                                                    "16.2px",
                                                                            }}
                                                                        >
                                                                            {new Date(
                                                                                item.createdAt
                                                                            )
                                                                                .toLocaleTimeString()
                                                                                .replace(
                                                                                    /,/,
                                                                                    " "
                                                                                )}{" "}
                                                                            <img
                                                                                src={point}
                                                                                alt=""
                                                                            />{" "}
                                                                            <span
                                                                                style={{
                                                                                    color:
                                                                                        item.paymentStatus ===
                                                                                        "PAID"
                                                                                            ? "#12B76A"
                                                                                            : "rgb(247, 144, 9)",
                                                                                }}
                                                                            >
                                                                                {item.paymentStatus}
                                                                            </span>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    style={{
                                                                        display: "flex",
                                                                        flexDirection: "column",
                                                                        gap: "4px",
                                                                    }}
                                                                >
                                                                    <span
                                                                        style={{
                                                                            color: "#344054",
                                                                            fontWeight: "500",
                                                                            fontSize: "14px",
                                                                            lineHeight: "19.6px",
                                                                            textAlign: "right",
                                                                        }}
                                                                    >
                                                                        {currencyPairCheck(
                                                                            item.currency
                                                                        )}
                                                                        {AmountCheck(
                                                                            item?.totalAmount
                                                                                ? item.totalAmount
                                                                                : 0
                                                                        )}
                                                                    </span>
                                                                    <span
                                                                        style={{
                                                                            color: "#667085",
                                                                            letterSpacing: "2%",
                                                                            fontWeight: "500",
                                                                            lineHeight: "16.2px",
                                                                        }}
                                                                    >
                                                                        {item.invoiceItems.length}{" "}
                                                                        Item(s)
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        )}{" "}
                                                </>
                                            ))}{" "}
                                        </>
                                    ))}{" "}
                                </div>
                            </div>
                        )}{" "}
                    </>
                ) : (
                    <ComponentLoader />
                )}{" "}
            </div>
        </div>
    );
};

export default FlexInvoice;
