import { useNavigate } from "react-router-dom";
import tradevulogo from "../asset/images/worldbusiness.svg";
import tradebusiness from "../asset/images/newbusinesslogo.svg";
import person from "../asset/images/profile-add-new.png";
import logout from "../asset/images/logoutaccount.png";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import success from "../asset/images/marksuccess.svg";
import { useDispatch, useSelector } from "react-redux";
import { LOGOUT_USER } from "../store/action";
import classes from "../style/newcss.module.css";
import ComponentLoader from "../component/componentloader";

const BusinessProfile = () => {
  const [business, setBusiness] = useState([]);
  const selector = useSelector((state) => state.users);
  const dispatch = useDispatch();
  const [loaderset, setloaderset] = useState(true);
  useEffect(() => {
    const fetchBusiness = async () => {
      await axios
        .get("/v1/business/user")
        .then((res) => {
          setBusiness(res.data);
          setloaderset(false);
        })
        .catch((e) => {
          console.log(e);
          setloaderset(false);
        });
    };
    fetchBusiness();
  }, []);
  const changebusiness = async (value) => {
    await axios
      .put(`/v1/business/current/${value}`)
      .then((res) => {
        window.location.replace("/dashboard");
        localStorage.setItem("defaults", "");
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const Logout = async (value) => {
    LOGOUT_USER("", dispatch)
      .then(() => {
        navigate("/");
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const navigate = useNavigate();
  return (
    <div
      // className={classes.businessswitch}
      style={{
        width: "100%",
        height: "100%",
        maxWidth: "1800px",
        margin: "0px auto",
        position: "relative",
        minHeight: "100vh",
        backgroundColor: "#6F00FF",
      }}
    >
      <img
        src={tradevulogo}
        alt=""
        className={classes.imgworld_account_business}
        style={{
          boxSizing: "border-box",
          position: "relative",
          overflow: "hidden",
          width: "100%",
          height: "100%",
          marginTop: "-250px",
        }}
      />
      <div
        className={classes.layout_top_after}
        style={{
          position: "absolute",
          width: "100%",
          top: "0",
        }}
      >
        <header
          className={`${classes.businessheader}  myborder_slixe`}
          style={{
            padding: "10px 80px 10px 80px",
            border: "0px 0px 1px 0px",
            gap: "10px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            className={classes.business_navbar}
            style={{
              width: "100%",
              height: "100%",
              justifyContent: "space-between",
              alignItems: "center",
              display: "flex",
            }}
          >
            <div
              className={classes.back_to_dashboard}
              style={{
                display: "flex",
                padding: "8px 0px 8px 0px",
                gap: "24px",
                alignItems: "center",
              }}
            >
              <div
                className={classes.inner_back_header_naigate}
                onClick={() => navigate("/dashboard")}
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "4px",
                  cursor: "pointer",
                }}
              >
                <span
                  className={`material-icons  ${classes.key_back}`}
                  style={{
                    color: "#F4F0FF",
                  }}
                >
                  keyboard_backspace
                </span>
                <h3
                  className={classes.h3_top_l}
                  style={{
                    fontSize: "20px",
                    fontWeight: "600",
                    lineHeight: "24px",
                    letterSpacing: "0em",
                    textAlign: "left",
                    color: "#F4F0FF",
                    padding: "0px",
                    margin: "0px",
                  }}
                >
                  Back to Dashboard
                </h3>
              </div>
            </div>
            <img src={tradebusiness} alt="" />
          </div>
        </header>
        <div
          className={classes.table_trade_box_layout}
          style={{
            marginTop: "100px",
            padding: "10px 80px 10px 80px",
          }}
        >
          <div
            className={classes.mid_layout_account}
            style={{
              display: "flex",
              justifyContent: "space-between",
              gap: "136px",
            }}
          >
            <div
              className={classes.left_layout_account}
              style={{
                flexDirection: "column",
                display: "flex",
                gap: "32px",
                maxWidth: "384px",
                width: "100%",
              }}
            >
              <h1
                style={{
                  fontSize: "32px",
                  fontWeight: "600",
                  lineHeight: " 40px",
                  letterSpacing: "0em",
                  textAlign: "left",
                  color: "#FCFCFD",
                  padding: "0px",
                  margin: "0px",
                }}
              >
                Hi there,
              </h1>
              <div
                className={classes.layout_wide_below_account}
                style={{
                  backgroundColor: "#FFFFFF",
                  width: "100%",
                  borderRadius: "8px",
                  padding: "24px",
                }}
              >
                <div
                  className={classes.inner_layout_wide_below_account}
                  style={{
                    height: "100%",
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    gap: "56px",
                  }}
                >
                  <div
                    className={classes.top_inner_mid_below_layout}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "32px",
                    }}
                  >
                    <div
                      className={classes.avater_layout_lg}
                      style={{
                        display: "flex",
                        gap: "16px",
                        alignItems: "center",
                      }}
                    >
                      <div
                        className={classes.intial}
                        style={{
                          width: "40px",
                          height: "40px",
                          borderRadius: "50%",
                          backgroundColor: "#F4F0FF",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          textTransform: "uppercase",
                          fontSize: "16px",
                          fontWeight: 500,
                          lineHeight: "24px",
                          letterSpacing: "0.02em",
                          textAlign: "center",
                          color: "#7F56D9",
                        }}
                      >
                        {selector.firstName.slice(0, 1)}{" "}
                        {selector.lastName.slice(0, 1)}
                      </div>
                      <div
                        className={classes.avater_name_layout}
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "4px",
                        }}
                      >
                        <h2
                          style={{
                            fontSize: "24px",
                            fontWeight: 600,
                            lineHeight: "32px",
                            letterSpacing: "0em",
                            textAlign: "left",

                            color: "#101828",
                            padding: "0px",
                            margin: "0px",
                          }}
                        >
                          {selector.firstName} {selector.lastName}
                        </h2>
                        <span
                          style={{
                            fontSize: "14px",
                            fontWeight: 500,
                            lineHeight: "20px",
                            letterSpacing: " 0em",
                            textAlign: "left",
                            color: "#344054",
                          }}
                        >
                          Account owner
                        </span>
                      </div>
                    </div>
                    <div
                      className={classes.layout_details_account}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "24px",
                      }}
                    >
                      <div
                        className={classes.each_details_layou}
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <span
                          style={{
                            fontSize: "14px",
                            fontWeight: 500,
                            lineHeight: "20px",
                            letterSpacing: "0em",
                            textAlign: "left",
                            color: "#667085",
                          }}
                        >
                          Email address{" "}
                        </span>
                        <span
                          style={{
                            fontSize: "16px",
                            fontWeight: 600,
                            lineHeight: "24px",
                            letterSpacing: "0.02em",
                            textAlign: "left",
                            color: "#344054",
                          }}
                        >
                          {selector.email}
                        </span>
                      </div>
                      <div
                        className={classes.each_details_layou}
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <span
                          style={{
                            fontSize: "14px",
                            fontWeight: 500,
                            lineHeight: "20px",
                            letterSpacing: "0em",
                            textAlign: "left",
                            color: "#667085",
                          }}
                        >
                          Phone number{" "}
                        </span>
                        <span
                          style={{
                            fontSize: "16px",
                            fontWeight: 600,
                            lineHeight: "24px",
                            letterSpacing: "0.02em",
                            textAlign: "left",
                            color: "#344054",
                          }}
                        >
                          {selector.phoneNumber}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div
                    className={classes.inner_bottom_layout}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <button
                      className={classes.btn_layout_account}
                      style={{
                        padding: "16px 24px 16px 24px",
                        borderRadius: "8px",
                        gap: "8px",
                        display: "flex",
                        alignItems: "center",
                        backgroundColor: "#F4F0FF",
                        outline: "none",
                        border: "none",
                        cursor: "pointer",
                      }}
                      onClick={() => navigate("/business/document")}
                    >
                      <img src={person} alt="" />
                      <span>Add account</span>
                    </button>
                    <button
                      className={classes.btn_layout_account}
                      style={{
                        padding: "16px 24px 16px 24px",
                        borderRadius: "8px",
                        gap: "8px",
                        display: "flex",
                        alignItems: "center",
                        backgroundColor: "#F4F0FF",
                        outline: "none",
                        border: "none",
                        cursor: "pointer",
                      }}
                      onClick={Logout}
                    >
                      <img src={logout} alt="" />
                      <span>Log out</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div
              className={classes.right_layout_side}
              style={{
                padding: "56px",
                borderRadius: "8px",
                display: "flex",
                gap: "8px",
                width: "100%",
                backgroundColor: "#F4F0FF",
              }}
            >
              <div
                className={classes.mid_bg_lg_layout}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "48px",
                  height: "100%",
                  width: "100%",
                }}
              >
                <div
                  className={classes.h1_layout}
                  style={{
                    fontSize: "48px",
                    fontWeight: 600,
                    lineHeight: "52px",
                    letterSpacing: "0em",
                    textAlign: "left",
                    color: "#4E03AF",
                  }}
                >
                  Switch account
                </div>
                <div
                  className={classes.sm_form_not_form}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "16px",
                  }}
                >
                  <h3
                    className={classes.title_layout}
                    style={{
                      fontSize: "20px",
                      fontWeight: 600,
                      lineHeight: " 24px",
                      letterSpacing: "0em",
                      textAlign: "left",
                      color: "#272627",
                      padding: "0px",
                      margin: "0px",
                    }}
                  >
                    All accounts
                  </h3>
                  <div
                    className="BusinessLoaderContianer"
                    style={{
                      borderRadius: "8px",
                      display: "flex",
                      flexDirection: "column",
                      overflow: "auto",
                      height: "calc(100vh - 500px)",
                    }}
                  >
                    {loaderset && <ComponentLoader />}
                    {business.map((resut, index) => (
                      <>
                        {resut?.name || resut?.tradename ? (
                          <div
                            className="eachBusinessBoxContainer"
                            key={index}
                            style={{
                              padding: "16px",
                              border: "1px solid #D9CDFF",

                              gap: "16px",
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              if (!resut.isCurrent) {
                                changebusiness(resut._id);
                              }
                            }}
                          >
                            <div
                              className={classes.avater_layout_lg}
                              style={{
                                display: "flex",
                                gap: "16px",
                                alignItems: "center",
                              }}
                            >
                              <div
                                className={classes.intial}
                                style={{
                                  width: "40px",
                                  height: "40px",
                                  borderRadius: "50%",
                                  backgroundColor: "#F4F0FF",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  textTransform: "uppercase",
                                  fontSize: "16px",
                                  fontWeight: 500,
                                  lineHeight: "24px",
                                  letterSpacing: "0.02em",
                                  textAlign: "center",
                                  color: "#7F56D9",
                                }}
                              >
                                {resut.name?.slice(0, 2)}
                              </div>
                              <div
                                className={classes.avater_name_layout}
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: "4px",
                                }}
                              >
                                <span
                                  className={classes.usinessname}
                                  style={{
                                    fontSize: "18px",
                                    fontWeight: "600",
                                    lineHeight: "24px",
                                    letterSpacing: "0em",
                                    textAlign: "left",
                                    color: "#101828",
                                  }}
                                >
                                  {resut.name}
                                </span>
                                <span
                                  className={classes.single_buttom_lg}
                                  style={{
                                    fontSize: "14px",
                                    fontWeight: 500,
                                    lineHeight: "20px",
                                    letterSpacing: "0em",
                                    textAlign: "left",
                                    color: "#667085",
                                  }}
                                >
                                  {resut._id}
                                </span>
                              </div>
                            </div>
                            {resut.isCurrent ? (
                              <img src={success} alt="" />
                            ) : (
                              <span
                                className="material-icons"
                                style={{ color: "#667085", fontSize: "16px" }}
                              >
                                chevron_right
                              </span>
                            )}
                          </div>
                        ) : (
                          ""
                        )}
                      </>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BusinessProfile;
