import { AddIcon, EditUnderlineIcon, ProfileFilledIcon, ProfileIcon } from "asset/icons";
import capitalizeWords from "utilities/capitalizeWords";

const PartnerSelectBtn = ({ handleShow, label, partner, subLabel }) => {
    return (
        <div>
            <p className="font-medium text-sm text-main mb-2">{label}</p>
            <div
                className="bg-white rounded-lg !p-4 flex items-center relative cursor-pointer"
                onClick={handleShow}
            >
                <input
                    required
                    defaultValue={partner?._id}
                    className="absolute left-0 top-0 h-full -z-30"
                />

                {partner ? (
                    <ProfileFilledIcon className="rounded-full mr-1.5 -ml-1 -my-0.5" />
                ) : (
                    <ProfileIcon className="bg-[#F9F5FF] rounded-full mr-2 p-[5px] size-8" />
                )}
                <span className="font-[450] mr-auto text-main-dark">
                    {partner ? (
                        capitalizeWords(`${partner.businessName ?? partner.business.name}`)
                    ) : (
                        <>{subLabel} </>
                    )}
                </span>

                {partner ? <EditUnderlineIcon className="size-[22px]" /> : <AddIcon />}
            </div>
        </div> 
    );
};

export default PartnerSelectBtn;
