import { useState } from "react";
import search from "../asset/images/search-normal.svg";
import invoice from "../asset/images/invoice.svg";
import addrecrecipt from "../asset/images/partner_add.svg";
import InvoiceDetails from "./invoicedetails";

import longinvoice from "../asset/images/orderlogom.svg";
import { useNavigate } from "react-router-dom";
import DeleteFlexInvoice from "./DeleteFlexInvoice";
import axios from "axios";
import { useEffect } from "react";
import ComponentLoader from "./componentloader";
import AddBuyerModal from "../flex/addbuyerModal";
import wareHouse from "../asset/images/cashflow.svg";
import euro from "../asset/images/EU.svg";
import pounds from "../asset/images/GB.svg";
import NGNcurrency from "../asset/images/Currency.svg";
import US from "../asset/images/US.png";

const CashFlow = (props) => {
    const [tab, settab] = useState("buyer");
    const [tenureday, settenureday] = useState("NGN");
    const [showcurrencymodal, setshowcurrencymodal] = useState(false);

    const [searchbank, setsearchbank] = useState("");
    const [totalinvoice, settotalinvoice] = useState(0);
    const [totalAmountOfAllInvoices, settotalAmountOfAllInvoices] = useState(0);
    const [totalAmountunpaid, settotalUnpaidAmount] = useState(0);
    const [totalupaid, settotalUnpaid] = useState(0);
    const [inputsearch, setinputsearch] = useState("");
    const [show, setshow] = useState(false);
    const [showModal3, setshowModal3] = useState(false);
    const [loader, setload] = useState(false);
    const navigate = useNavigate();
    const [deletes, setdelete] = useState(false);

    {
        /**
  const filterBuyer = props.invoice?.filter((item) =>
    item.paymentMethod?.match(searchbank)
  );

  filterBuyer?.sort((a, b) =>
    a.createdAt?.localeCompare(b.createdAt, "es", { sensitivity: "base" })
  );
  let data = filterBuyer?.reduce((r, e) => {
    let alphabet = new Date(e.createdAt).toDateString();

    if (!r[alphabet])
      r[alphabet] = {
        alphabet,
        record: [],
      };

    r[alphabet].record.push(e);
    return r;
  }, {});
  let result = Object.values(data);
  useEffect(() => {
    const fetchSummary = () => {
      axios
        .get("/v1/invoice/summary")
        .then((res) => {
          // console.log(res)
          settotalinvoice(res.data.data[0]?.totalNumberOfInvoices);
          settotalAmountOfAllInvoices(
            res.data.data[0]?.totalAmountOfUnpaidInvoices
          );
          settotalUnpaidAmount(res.data.data[0]?.totalAmountOfAllInvoices);
          settotalUnpaid(res.data.data[0]?.totalNumberOfUnpaidInvoices);
        })
        .catch((e) => {
          console.log(e);
        });
    };
    fetchSummary();
  }, []);

  const [selecteditem, setselecteditem] = useState([]);
  const deleteInvoiceFlex = async () => {
    setload(true);
    await axios
      .delete(`/v1/invoice/${selecteditem._id}`)
      .then((res) => {
        //console.log(res)
        const updatedHero = props.invoice.filter(
          (item) => item._id !== selecteditem._id
        );
        props.setinvoices(updatedHero);
        setdelete(true);
        setshowModal3(false);
        setload(false);
        setshow(false);
      })
      .catch((e) => {
        console.log(e);
        setload(false);
        setshowModal3(false);
      });
  };
*/
    }

    return (
        <div
            className="flex-m-invoice"
            style={{
                display: "flex",
                gap: "0px",
            }}
        >
            {/**
      <InvoiceDetails
        show={show}
        setshow={setshow}
        selecteditem={selecteditem}
        setshow3={setshowModal3}
        deletes={deletes}
      />
      <DeleteFlexInvoice
        loader={loader}
        show={showModal3}
        setshow={setshowModal3}
        deletedBuyer={deleteInvoiceFlex}
      />
      */}
            <div
                style={{
                    height: "100%",
                    background: "#fff",
                    minHeight: "977px",
                    padding: "32px",
                    borderRight: "1px solid #E7E9FB",
                    borderRadius: "24px 0px 0px 0px",
                }}
            >
                <div
                    className="long-flex-f"
                    style={{
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                        gap: "56px",
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "32px",
                        }}
                    >
                        <div
                            className="currency_switch"
                            style={{
                                width: "352px",
                            }}
                            onClick={() => setshowcurrencymodal(true)}
                        >
                            <div className="currency_board">
                                <img
                                    src={
                                        tenureday === "NGN"
                                            ? NGNcurrency
                                            : tenureday === "GBP"
                                            ? pounds
                                            : tenureday === "USD"
                                            ? US
                                            : euro
                                    }
                                    alt=""
                                    style={{
                                        width: "20px",
                                        height: "20%",
                                    }}
                                />
                                <span className="currency_title">{tenureday}</span>
                                <span className="currency_title">
                                    {tenureday === "NGN"
                                        ? "Naira"
                                        : tenureday === "GBP"
                                        ? "British Pounds"
                                        : "Euro"}
                                </span>
                            </div>
                            <span className="material-icons">expand_more</span>
                        </div>
                        {/**
            <div
              style={{ display: "flex", flexDirection: "column", gap: "24px" }}
            >
              <div className="each_count_lg_partner_data">
                <div className="each_data_partner">
                  <div className="info_partner">Current Value</div>
                  <span className="info_partner_sm">0</span>
                </div>

                <div
                  className="each_data_partner"
                  style={{
                    borderTop: "1px solid #E7E9FB",
                    borderBottom: "1px solid #E7E9FB",
                    padding: "10px 0px",
                  }}
                >
                  <div className="info_partner">Insured</div>
                  <span className="info_partner_sm">0%</span>
                </div>

                <div className="each_data_partner">
                  <div className="info_partner">Operational</div>
                  <span className="info_partner_sm">0%</span>
                </div>
              </div>
            </div>
            */}
                    </div>
                </div>
            </div>

            <div
                className="main-c-flex"
                style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "32px",
                    width: "55%",
                    padding: "32px",
                    margin: "0px auto",
                }}
            >
                <div
                    style={{
                        maxWidth: "384px",
                        width: "100%",
                        margin: "0px auto",
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "8px",
                        }}
                    >
                        <h1
                            style={{
                                textAlign: "center",
                                fontWeight: "700",
                                color: "#101828",
                                fontSize: "32px",
                                lineHeight: "40px",
                            }}
                        >
                            Coming soon!
                        </h1>
                        <span
                            style={{
                                textAlign: "center",
                                fontSize: "16px",
                                lineHeight: "24px",
                                fontWeight: "400",
                                color: "#344054",
                            }}
                        >
                            Manage your cashflow and budget to monitor real-time spendings on the
                            go.
                        </span>
                    </div>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <img
                            src={wareHouse}
                            alt=""
                            style={{
                                margin: "0px auto",
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CashFlow;
