import Modal from "react-bootstrap/Modal";
import { countrycodelist } from "../constants/countryList";
import successmark from "../asset/images/Checkbox.svg";
import searchIcon from "../asset/images/search-normal.svg";

import { useState } from "react";

const CountryModal = (props) => {
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [search, setSearch] = useState("");
    const filterCountry = countrycodelist.filter(
        (countrycodelists) =>
            countrycodelists.title.match(search) || countrycodelists.num.match(search)
    );
    const checkclick = (data, index) => {
        props.setselectedcounty(data.title);
        setSelectedIndex(index);
        props.setshow(false);
    };
    return (
        <Modal
            show={props.show}
            animation={false}
            className="modal_new_lg"
            onHide={() => props.setshow(false)}
        >
            <Modal.Header className="border-0">
                <span
                    className="material-icons"
                    onClick={() => props.setshow(false)}
                    style={{
                        cursor: "pointer",
                    }}
                >
                    close
                </span>
            </Modal.Header>
            <Modal.Body style={{ height: "calc(100vh - 100px)" }}>
                <div className="h-100 ">
                    <h4 style={{ fontSize: "1rem" }}>Choose Business Country</h4>
                    <div
                        className=" position-relative mt-4"
                        style={{
                            display: "flex",
                            gap: "8px",
                            alignItems: "center",
                            padding: "8px 16px",
                            background: "#ECEFF3",
                            borderRadius: "8px",
                            height: "56px",
                        }}
                    >
                        <img src={searchIcon} alt="" width={24} height={24} />
                        <input
                            type="search"
                            className="  border-0"
                            style={{
                                fontSize: "14px",
                                fontWeight: "600",
                                lineHeight: "19.6px",
                                color: "#667085",
                                borderRadius: "8px",
                                background: "inherit",
                                width: "100%",
                                outline: "none",
                            }}
                            value={search}
                            onChange={(e) => {
                                setSearch(e.target.value);
                            }}
                            placeholder="Search countries"
                        />
                    </div>
                    <div
                        className="card  my-3 border-0 mycardscroll "
                        style={{
                            background: "#ffff",
                            overflow: "auto",
                            height: "calc(100vh - 250px)",
                        }}
                    >
                        {" "}
                        {filterCountry.map((countrycode, index) => (
                            <div className="card-body p-1 " key={index}>
                                <div
                                    className="p-2  d-flex gap-3 align-items-center"
                                    onClick={() => checkclick(countrycode, index)}
                                    style={{
                                        flexDirection: "row",
                                        flexShrink: "1",
                                        alignItems: "center",
                                        background: selectedIndex === index ? "#F4F0FF" : "",
                                        width: "100%",
                                        borderRadius: "8px",
                                    }}
                                >
                                    <img
                                        src={countrycode.image}
                                        style={{ borderRadius: "100%" }}
                                        alt=""
                                        width={20}
                                    />
                                    <div
                                        className="d-flex justify-content-between w-100"
                                        style={{
                                            overflow: "hidden",
                                            alignItems: "center",
                                        }}
                                    >
                                        <div className="d-grid">
                                            <span
                                                style={{
                                                    fontSize: "16px",
                                                    fontWeight: "600px",
                                                    lineHeight: "24px",
                                                    letterSpacing: "2%",
                                                }}
                                            >
                                                {" "}
                                                {countrycode.title}
                                            </span>
                                        </div>
                                        {selectedIndex === index && (
                                            <img src={successmark} alt="" />
                                        )}
                                    </div>
                                </div>
                            </div>
                        ))}{" "}
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default CountryModal;
