import { useRef, useState } from "react";
import ArrowBack from "../component/arrowback";
import LoginHeader from "../component/loginheader";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { LOGIN_USER } from "../store/action";
import Swal from "sweetalert2/dist/sweetalert2.js";
import Loaders from "../component/loader";
import { useDispatch } from "react-redux";
import SuccessHodler from "../component/successholder";
import FailHandler from "../component/failhandler";
import { useEffect } from "react";

const Verify_Code_Sent = () => {
  const [pins, setPins] = useState(["", "", "", ""]);
  const { state } = useLocation();

  const inputs = useRef([]);
  const [loader, setLoader] = useState(false);
  const [success, setsuccess] = useState(false);
  const [fails, setfail] = useState(false);
  const [message, setmessage] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    let timer = setTimeout(() => {
      setfail(false);
      setsuccess(false);
    }, 3000);
    return () => clearTimeout(timer);
  }, [fails, success]);
  const handleInputChange = (event, index) => {
    const currentInput = event.target.value.trim();

    if (/^\d$/.test(currentInput)) {
      const newPins = [...pins];
      newPins[index] = currentInput;

      setPins(newPins);

      if (index < inputs.current.length - 1 && currentInput) {
        inputs.current[index + 1].focus();
      }
    }
  };

  const handleInputBackspace = (event, index) => {
    const currentInput = event.target.value.trim();
    // console.log(currentInput);

    if (event.key === "Backspace" && !currentInput && index > 0) {
      const newPins = [...pins];
      newPins[index - 1] = "";

      setPins(newPins);
      inputs.current[index - 1].focus();
    }
  };
  const reDirect = async () => {
    const data = {
      email: state.email,
      password: state.password,
    };
    LOGIN_USER(data, dispatch)
      .then(() => {
        navigate("/setpin", {
          state: {
            ...state,
          },
        });
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const verifyPinHandler = async (e) => {
    e.preventDefault();
    setLoader(true);
    const data = {
      email: state.vatnumber,
      code: pins.join(""),
    };
    await axios
      .post("/v1/users/confirm-forgot-password-token", data)
      .then((resp) => {
        // reDirect();
        navigate("/recovery/new_password", {
          state: {
            email: state?.vatnumber,
            code: pins.join(""),
          },
        });
        setLoader(false);

        setfail(false);
        setmessage(resp.data.message);
        setsuccess(true);
      })
      .catch((e) => {
        setLoader(false);
        setmessage(
          e.response?.data ? e.response?.data.message : "An error occur"
        );
        setsuccess(false);
        setfail(true);
      });
  };

  return (
    <div
      className=""
      style={{
        background: "#F6F8FA",
        paddingRight: "0px",
        maxWidth: "2000px",
        width: "100%",
        margin: "0px auto",
      }}
    >
      <LoginHeader width={55} />
      <div
        className="p-2  justify-self-center align-items-center d-flex"
        style={{ height: "calc(100% - 200px)" }}
      >
        <div
          className="form-general-layout"
          style={{
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            position: "relative",
          }}
        >
          <SuccessHodler message={message} success={success} />
          <FailHandler message={message} success={fails} />
          <div className="card-body">
            <ArrowBack />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "4px",
              }}
            >
              <div
                style={{
                  fontWeight: "500",
                  color: "#101828",
                  fontSize: "32px",
                  lineHeight: "40px",
                }}
                className="mt-3 "
              >
                Enter the OTP
              </div>
              <div
                style={{
                  fontSize: "16px",
                  color: "#667085",
                  letterSpacing: "2%",
                  lineHeight: "24px",
                  fontWeight: "400",
                }}
              >
                Enter the 4 digit code sent to:
                <span
                  style={{
                    color: "#6F00FF",
                    fontWeight: "400",
                    letterSpacing: "2%",
                    lineHeight: "24px",
                  }}
                >
                  {state?.vatnumber}
                </span>
              </div>
            </div>

            <form
              action=""
              onSubmit={verifyPinHandler}
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "32px",
              }}
            >
              <div
                className="row mt-4"
                style={{
                  maxWidth: "350px",
                  width: "100%",
                }}
              >
                {pins.map((pin, index) => (
                  <div className="col" key={index}>
                    <div className="form-group">
                      <input
                        key={index}
                        type="tel"
                        className="form-control border-0"
                        style={{
                          background: "#ECEFF3",
                          color: "#667085",
                          padding: "8px 16px 8px 16px",
                          textAlign: "center",
                          height: "53px",
                          minWidth: "56px",
                          fontWeight: "400",
                        }}
                        maxLength="1"
                        placeholder="-"
                        value={pin}
                        ref={(element) => (inputs.current[index] = element)}
                        onChange={(event) => handleInputChange(event, index)}
                        onKeyDown={(event) =>
                          handleInputBackspace(event, index)
                        }
                      />
                    </div>
                  </div>
                ))}{" "}
              </div>
              <div
                className=""
                style={{
                  fontSize: "14px",
                  color: "#344054",
                  fontWeight: "400",
                  lineHeight: "19.6px",
                }}
              >
                <span>
                  I don’t have an account,
                  <span
                    type="button"
                    style={{
                      color: "#6F00FF",
                      fontSize: "14px",
                      fontWeight: "400",
                      lineHeight: "19.6px",
                      marginLeft: "5px",
                    }}
                    onClick={() => {
                      navigate("/register");
                    }}
                  >
                    Create One
                  </span>
                </span>
              </div>
              <button
                disabled={loader}
                type="submit"
                className="btn   mr-2"
                style={{
                  background: "#6F00FF",
                  color: "#fff",
                  maxWidth: "156px",
                  width: "100%",
                  fontSize: "1rem",
                  borderRadius: "8px",
                  fontWeight: "400px",
                  lineHeight: "24px",
                  letterSpacing: "2%",
                  padding: "16px 24px 16px 24px",
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                }}
              >
                {loader === true ? <Loaders data={loader} /> : "Verify Number"}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Verify_Code_Sent;
