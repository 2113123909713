import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import mark from "../asset/images/Checkbox.svg";
import axios from "axios";
import Loader from "./loader";
import Swal from "sweetalert2/dist/sweetalert2.js";
import ReasonModal from "./reasonModal";


const UpdateStatus = (props) => {
  const data = ["Delayed", "Processing", "Completed"];
  const [selected, setselected] = useState(0);
  const [loader, setloader] = useState(false)
  const [modalreasonshow, setmodalreasonshow] = useState(false)
  const [reason, setreason] = useState("")

  const updateorderstatus = async (e) => {
    e.preventDefault();
    if (selected === 0) {
      if (reason !== "") {
        setloader(true)
        const datas = {
          orderStatus: 'DELAYED',
          reason: reason
        }
        await axios.patch(`/v1/flex/order/${props.orderdetails?._id}`, datas).then((res) => {
          setloader(false)
          //   console.log(res)
          props.restartOrder()
          props.setsuccess(true)
          props.setmessage(res.data.message)
          props.setfail(false)
          props.t('Success')
          props.setupdateshow(false)
          setmodalreasonshow(false)
          props.setorderstatus('DELAYED')


        }).catch((e) => {
          console.log(e)
          setloader(false)
          props.setsuccess(false)
          props.t('Failed')
          props.setmessage(e.response?.data ? e.response?.data.message : 'An error occur')
          props.setfail(true)
          props.setupdateshow(false)
          setmodalreasonshow(false)




        })

      } else {
        setmodalreasonshow(true)

      }
    } else {




      setloader(true)
      const datas = {
        orderStatus: data[selected].toLocaleUpperCase()
      }
      await axios.patch(`/v1/flex/order/${props.orderdetails._id}`, datas).then((res) => {
        setloader(false)
        //   console.log(res)
        props.restartOrder()

        props.setsuccess(true)
        props.setmessage(res.data.message)
        props.setfail(false)
        props.t('Success')
        props.setupdateshow(false)
        setmodalreasonshow(false)
        props.setorderstatus(data[selected].toLocaleUpperCase())




      }).catch((e) => {
        setloader(false)
        props.setsuccess(false)
        props.t('Failed')
        props.setmessage(e.response?.data ? e.response?.data.message : 'An error occur')
        props.setfail(true)
        props.setupdateshow(false)

        setmodalreasonshow(false)



      })
    }

  };
  return (
    <Modal
      show={props.updateshow}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="myBilltype"
    >
      <span className="material-icons" style={{
        padding: '10px 20px ',
        cursor: 'pointer'
      }} onClick={() => props.setupdateshow(false)}>close</span>
      <Modal.Body>
        <ReasonModal updateshow={modalreasonshow} setupdateshow={setmodalreasonshow} setreason={setreason} updateorderstatus={updateorderstatus} loader={loader} />

        <form onSubmit={updateorderstatus}>
          <h6>Update Order Status</h6>
          <div
            className="card p-1 "
            style={{ background: "#ffff" }}
            onSubmit={updateorderstatus}
          >
            {data.map((bill, index) => (
              <div
                className="card-body p-0 "
                style={{
                  cursor: 'pointer',
                  borderRadius: '8px',
                  background: selected === index ? '#F4F0FF' : ''
                }}
                key={index}
                onClick={() => setselected(index)}
              >
                <div
                  className="p-2  d-flex gap-3"
                  style={{
                    flexDirection: "row",
                    flexShrink: "1",
                    alignItems: "center",
                  }}
                >
                  <div
                    className="d-flex justify-content-between w-100"
                    style={{
                      overflow: "hidden",
                      alignItems: "center",
                    }}
                  >
                    <div>
                      <div style={{ fontSize: "14px", textTransform: 'capitalize', fontWeight: '500' }}>{bill}</div>
                    </div>
                    {selected === index && <img src={mark} alt="" />}
                  </div>
                </div>
              </div>
            ))}{" "}
          </div>
          <button
            disabled={loader}
            className="btn-send-request"
            type="submit"
            style={{
              width: "100%",
              marginTop: "20px",
              maxWidth: "100%",
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            {
              loader ? <Loader /> : 'Update'
            }
          </button>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default UpdateStatus;
