import axios from "axios";
export const LOGIN_ACTION = "[Login Action]";
export const Confirm_Login_Action = "[Confirm Login Action]";

export function createLoginAction() {
    return { type: LOGIN_ACTION };
}

export function login(payload) {
    const data = {
        email: payload.email,
        password: payload.password,
    };
    console.log(data);
    axios
        .post("v1/users/login", data)
        .then((res) => {
            console.log(res);
            // store.dispatch(confirmloginAction(res))
        })
        .catch((e) => {
            console.log(e);
        });
}
export function loginAction(payload) {
    return (dispatch) => {
        axios.post("v1/users/login", payload).then((res) => {});
        login(payload).then((res) => {
            console.log(res);
            dispatch(confirmloginAction(res.data.data));
        });
    };
}
export function confirmloginAction(response) {
    return { type: Confirm_Login_Action, payload: response };
}
export function LOGIN_USER(payload, dispatch) {
    return new Promise((resolve, reject) => {
        axios
            .post("v1/users/login", payload)
            .then((res) => {
                resolve(res);
                localStorage.setItem("token", res.data.data.token);
                dispatch({ type: "LOGIN", payload: res.data.data });
            })
            .catch((e) => {
                reject(e);
                console.log(e);
            });
    });
}
export function LOGOUT_USER(payload, dispatch) {
    return new Promise((resolve, reject) => {
        axios
            .post("/v1/users/logout", payload)
            .then((res) => {
                localStorage.removeItem("token");
                resolve(res);
                dispatch({ type: "LOGOUT", payload: res.data });
            })
            .catch((e) => {
                reject(e);
                // console.log(e)
            });
    });
}
export function CLOSE_ACCOUNT(payload, dispatch) {
    return new Promise((resolve, reject) => {
        // delete('', payload
        axios({
            url: "/v1/users/delete-account",
            data: payload,
            method: "delete",
        })
            .then((res) => {
                localStorage.removeItem("token");
                resolve(res);
                dispatch({ type: "CLOSE_ACCOUNT", payload: res.data });
            })
            .catch((e) => {
                reject(e);
                console.log(e);
            });
    });
}
