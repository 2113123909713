import React from "react";

const FormSteps = ({ className }) => {
    return (
        <div className={`hidden md:flex gap-4 min-w-[177px]`}>
            <div className="flex flex-col items-center">
                <span className="!bg-secondary rounded-full size-6 font-[450] text-base text-center text-[#F4F1FF]">
                    1
                </span>
                <span className="!bg-[#C0CAD8] w-[2px] h-5" />
                <span className="!bg-[#98A2B3] rounded-full size-6 font-[450] text-base text-center text-[#F4F1FF]">
                    2
                </span>
            </div>
            <div className="flex flex-col !gap-5">
                <p className={`font-medium !text-main`}>Choose your role</p>
                <p className={`font-medium !text-secondary`}>Fill order details</p>
            </div>
        </div>
    );
};

export default FormSteps;
