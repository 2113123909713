import LoginHeader from "../component/loginheader";
import "../style/index.css";
import "../style/remove.css";

import { useState } from "react";
import ArrowBack from "../component/arrowback";
import { useLocation } from "react-router-dom/dist";
import { Helmet } from "react-helmet";
import Loaders from "../component/loader";
import axios from "axios";
import Swal from "sweetalert2";
import avater from "../asset/images/addbvn.png";
import ShareHolder from "../component/shareholder";
import Director from "../component/director";
import addbvn from "../asset/images/addbvn.png";
import FailHandler from "../component/failhandler";

const OtherBusinessInformation = () => {
  const [category, setCategory] = useState("");
  const [loader, setLoader] = useState(false);
  const [showshareholder, setShareHolder] = useState(false);
  const [showdirector, setshowDirector] = useState(false);
  const [directorDetails, setDirectorDetails] = useState([]);
  const [shareholderDetails, setShareholderDetails] = useState([]);
  const [fail, setfail] = useState(false);
  const [message, setmessage] = useState(false);
  const { state } = useLocation();

  // const navigate = useNavigate();
  const showDirector = () => {
    setshowDirector(!showdirector);
  };
  const showShareholder = () => {
    setShareHolder(!showshareholder);
  };

  const submitDocument = async (e) => {
    e.preventDefault();
    setLoader(true);

    const data = {
      Category: category,
    };
    await axios
      .post(`/v1/business/update/${state.businessId}`, data)
      .then(() => {
        axios.put(`/v1/business/current/${state.businessId}`).then((res) => {
          // console.log(res)
          axios.defaults.headers.common["x-business-id"] =
            res.data.business._id;
          axios
            .post(`/v1/business/request-verification`)
            .then(() => {
              if (localStorage.getItem("default") !== null) {
                localStorage.removeItem("default");
              }
              localStorage.setItem("hide", false);
              window.location.replace("/dashboard");
            })
            .catch((e) => {
              console.log(e);
            });
        });
      })
      .catch((e) => {
        setfail(true);
        setmessage(
          e.response?.data.message ? e.response.data.message : "An error occur"
        );
        setLoader(false);
      });
  };

  return (
    <div
      className=""
      style={{
        background: "inherit",
        paddingRight: "0px",
        maxWidth: "2000px",
        width: "100%",
        margin: "0px auto",
      }}
    >
      <Helmet>
        <title>Business — Document</title>
      </Helmet>

      <LoginHeader width={45} />
      <Director
        show={showdirector}
        directorDetails={directorDetails}
        setDirectorDetails={setDirectorDetails}
        setshow={setshowDirector}
        state={state}
      />

      <ShareHolder
        setshow={setShareHolder}
        show={showshareholder}
        state={state}
        shareholderDetails={shareholderDetails}
        setShareholderDetails={setShareholderDetails}
      />

      <div className=" ">
        <div className="form-general-layout">
          <FailHandler success={fail} message={message} />
          <div
            className="card-body"
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "32px",
            }}
          >
            <div
              className="d-flex justify-content-between"
              style={{ alignItems: "center" }}
            >
              <ArrowBack />
              <span
                style={{
                  fontSize: "13px",
                  fontWeight: "600",
                  color: "#344054",
                  cursor: "pointer",
                }}
              >
                Skip
              </span>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "4px",
              }}
            >
              <h3
                style={{
                  fontSize: "2rem",
                  fontWeight: "600",
                  color: "#101828",
                  lineHeight: "40px",
                }}
              >
                Other Business information
              </h3>
              <span
                style={{
                  color: "#667085",
                  fontSize: "1rem",
                  lineHeight: "24px",
                  letterSpacing: "2%",
                }}
              >
                Category, business structure, etc...
              </span>
            </div>

            <form
              onSubmit={submitDocument}
              className=""
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "32px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "16px",
                }}
              >
                <div className="form-group position-relative">
                  <label
                    htmlFor=""
                    style={{
                      fontWeight: "500",
                      fontSize: "14px",
                      lineHeight: "19.6px",
                      color: "#101828",
                    }}
                  >
                    Category
                  </label>
                  <select
                    type="text"
                    className="form-control border-0"
                    aria-describedby="emailHelp"
                    placeholder="Select Country"
                    style={{
                      background: "#ECEFF3",
                      height: "58px",
                      lineHeight: "24px",
                    }}
                    value={category}
                    onChange={(e) => {
                      setCategory(e.target.value);
                    }}
                  >
                    <option value="Import -Export">Import -Export</option>
                  </select>
                </div>

                <div
                  className="form-group"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "8px",
                  }}
                >
                  <label
                    htmlFor="Directors"
                    className=""
                    style={{
                      fontWeight: "500",
                      fontSize: "14px",
                      lineHeight: "19.6px",
                      color: "#101828",
                    }}
                  >
                    Directors
                  </label>
                  {directorDetails.length === 0 && (
                    <div className="card border-0" onClick={showDirector}>
                      <div className="card-body">
                        <div className="d-flex align-items-center gap-2">
                          <img src={avater} alt="" width={25} />
                          <div className="w-100 d-flex justify-content-between align-items-center">
                            <span
                              style={{
                                color: "#101828",
                                fontSize: "1rem",
                                fontWeight: "600",
                                letterSpacing: "2%",
                              }}
                            >
                              Add Director
                            </span>
                            <span className="material-icons">
                              chevron_right
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}{" "}
                </div>
                {directorDetails &&
                  directorDetails.map((details) => (
                    <div
                      className="card  shadow-sm border-0 "
                      style={{
                        background: "#ffff",
                        cursor: "pointer",
                      }}
                      //onClick={showFormandHideForm}
                      key={details}
                    >
                      <div
                        className="card-body d-flex gap-2 "
                        style={{ alignItems: "center" }}
                      >
                        <img src={addbvn} alt="" />
                        <div className="d-flex justify-content-between align-items-center w-100">
                          <div className="d-flex gap-2">
                            <span
                              style={{
                                fontWeight: "600",
                                color: "#101828",
                                fontSize: "16px",
                                lineHeight: "24px",
                                letterSpacing: "2%",
                              }}
                            >
                              {details.fname}
                            </span>
                            <span
                              style={{
                                fontWeight: "600",
                                color: "#101828",
                                fontSize: "16px",
                                lineHeight: "24px",
                                letterSpacing: "2%",
                              }}
                            >
                              {details.lname}
                            </span>
                          </div>
                          <span className="material-icons">chevron_right</span>
                        </div>
                      </div>
                    </div>
                  ))}
                {directorDetails.length > 0 && (
                  <div
                    className="d-flex gap-2  align-items-center"
                    onClick={showDirector}
                    style={{ cursor: "pointer" }}
                  >
                    <span
                      className="material-icons"
                      style={{
                        color: "#6F00FF",
                        fontSize: "1rem",
                      }}
                    >
                      add
                    </span>
                    <span
                      style={{
                        color: "#6F00FF",
                        fontSize: "1rem",
                        fontWeight: "700",
                        letterSpacing: "1%",
                      }}
                    >
                      Add Director
                    </span>
                  </div>
                )}

                <div
                  className="form-group"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "8px",
                  }}
                >
                  <label
                    htmlFor="Directors"
                    className="mb-1"
                    style={{
                      fontWeight: "500",
                      fontSize: "14px",
                      lineHeight: "19.6px",
                      color: "#101828",
                    }}
                  >
                    Shareholders
                  </label>
                  {shareholderDetails.length === 0 && (
                    <div className="card border-0" onClick={showShareholder}>
                      <div className="card-body">
                        <div className="d-flex align-items-center gap-2">
                          <img src={avater} alt="" width={25} />
                          <div className="w-100 d-flex justify-content-between align-items-center">
                            <span
                              style={{
                                color: "#101828",
                                fontSize: "1rem",
                                fontWeight: "600",
                                letterSpacing: "2%",
                              }}
                            >
                              Add Shareholders
                            </span>
                            <span className="material-icons">
                              chevron_right
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {shareholderDetails &&
                    shareholderDetails.map((details) => (
                      <div
                        className="card  shadow-sm border-0 "
                        style={{
                          background: "#ffff",
                          cursor: "pointer",
                        }}
                        //onClick={showFormandHideForm}
                        key={details}
                      >
                        <div
                          className="card-body  d-flex gap-2 "
                          style={{ alignItems: "center" }}
                        >
                          <img src={addbvn} alt="" />
                          <div className="d-flex justify-content-between align-items-center w-100">
                            <div className="d-flex gap-2">
                              <span
                                style={{
                                  fontWeight: "600",
                                  color: "#101828",
                                  fontSize: "16px",
                                  lineHeight: "24px",
                                  letterSpacing: "2%",
                                }}
                              >
                                {details.fname}
                              </span>
                              <span
                                style={{
                                  fontWeight: "600",
                                  color: "#101828",
                                  fontSize: "16px",
                                  lineHeight: "24px",
                                  letterSpacing: "2%",
                                }}
                              >
                                {details.lname}
                              </span>
                            </div>
                            <span className="material-icons">
                              chevron_right
                            </span>
                          </div>
                        </div>
                      </div>
                    ))}
                  {shareholderDetails.length > 0 && (
                    <div
                      className="d-flex gap-2  align-items-center"
                      onClick={showShareholder}
                      style={{ cursor: "pointer" }}
                    >
                      <span
                        className="material-icons"
                        style={{
                          color: "#6F00FF",
                          fontSize: "1rem",
                        }}
                      >
                        add
                      </span>
                      <span
                        style={{
                          color: "#6F00FF",
                          fontSize: "1rem",
                          fontWeight: "700",
                          letterSpacing: "1%",
                        }}
                      >
                        Add Shareholder
                      </span>
                    </div>
                  )}{" "}
                </div>
              </div>

              <button
                disabled={loader}
                type="submit"
                className="btn"
                style={{
                  background: "#6F00FF",
                  color: "#fff",
                  fontSize: "1rem",
                  letterSpacing: "2%",
                  padding: "16px 24px 16px 24px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  maxWidth: "279px",
                  width: "100%",
                }}
              >
                {loader === true ? <Loaders data={loader} /> : "Continue"}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OtherBusinessInformation;
