import LoginHeader from "../component/loginheader";
import "../style/index.css";
// import {useState} from 'react';
import ArrowBack from "../component/arrowback";
import { Helmet } from "react-helmet";
import { useLocation, useNavigate } from "react-router-dom";
import document from "../asset/images/document.png";
import { useEffect, useState } from "react";
import axios from "axios";
import marksuccess from "../asset/images/marksuccess.svg";
import FailHandler from "../component/failhandler";
import { Circles } from "react-loader-spinner";

// import {useNavigate} from "react-router-dom";

const UploadDocuments = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  //console.log(state);

  // const [documentOne, setDocumentOne] = useState('')
  const [documentTwo, setDocumentTwo] = useState();
  const [documentThree, setDocumentThree] = useState();
  const [doucumentFour, setDocumentFour] = useState();
  // const [fileUploadedOne, setFileUpladedOne] = useState(false)
  const [fileUploadedTwo, setFileUpladedTwo] = useState(false);
  const [fileUploadedThree, setFileUploadedThree] = useState(false);
  const [fileUploadedFour, setFileUploadedFour] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [loader1, setloader1] = useState(false);
  const [loader2, setloader2] = useState(false);
  const [loader3, setloader3] = useState(false);
  const [fails, setfails] = useState("");
  //  const [message, setmessage] = useState("");
  const [isUploaded1, setisUploaded1] = useState(false);
  const [isUploaded2, setisUploaded2] = useState(false);

  useEffect(() => {
    if (fails) {
      let timerr = setTimeout(() => {
        setfails(false);
      }, 3000);

      return () => {
        clearTimeout(timerr);
      };
    }
  });
  const formData = new FormData();

  const handleFileTwo = async (e) => {
    setDocumentTwo(e.target.files[0]);
    setError(true);
    setloader1(true);
    formData.append("CAC", e.target.files[0], e.target.files[0].name);
    await axios
      .post(
        `v1/business/upload-business-documents/${state.businessId}?businessType=${state.businessType}`,
        formData
      )
      .then((res) => {
        setError(false);
        setloader1(false);

        // console.log(res);
        setFileUpladedTwo(true);
        setisUploaded1(true);
      })
      .catch((e) => {
        setfails(true);
        setloader1(false);
        setErrorMessage(
          e?.response?.data.message
            ? e?.response?.data.message
            : "An error occur"
        );

        console.log(e);
      });
  };
  const handleFileThree = async (e) => {
    setError(true);
    //setErrorMessage("Uploading");
    setDocumentThree(e.target.files[0]);
    setloader2(true);
    // setDocumentOne('kfkf')
    //  console.log(documentThree);

    formData.append(
      "CAC_STATUS_REPORT",
      e.target.files[0],
      e.target.files[0].name
    );
    await axios
      .post(
        `/v1/business/upload-business-documents/${state.businessId}?businessType=${state.businessType}`,
        formData
      )
      .then((res) => {
        setError(false);
        setloader2(false);

        setFileUploadedThree(true);
      })
      .catch((e) => {
        setfails(true);
        setErrorMessage(
          e?.response?.data.message
            ? e?.response?.data.message
            : "An error occur"
        );
        setloader2(false);

        console.log(e);
      });
  };
  const handleFileFour = async (e) => {
    setDocumentFour(e.target.files[0]);
    setError(true);
    // setErrorMessage("Uploading");
    setloader3(true);

    // setDocumentOne('kfkf')
    // console.log(doucumentFour);
    formData.append("VALID_ID", e.target.files[0], e.target.files[0].name);
    await axios
      .post(
        `/v1/business/upload-business-documents/${state.businessId}?businessType=${state.businessType}`,
        formData
      )
      .then((res) => {
        setError(false);

        setFileUploadedFour(true);
        setloader3(false);
        setisUploaded2(true);
      })
      .catch((e) => {
        setfails(true);
        setErrorMessage(
          e?.response?.data.message
            ? e?.response?.data.message
            : "An error occur"
        );
        setloader3(false);

        console.log(e);
      });
  };
  const skipNextPage = () => {
    if (state.businessType == "SoleProprietorship") {
      navigate("/business/soleproprietor", {
        state: {
          ...state,
        },
      });
    } else {
      navigate(`/business/document-form`, {
        state: {
          ...state,
        },
      });
    }
  };
  const goNextPage = () => {
    if (isUploaded1 && isUploaded2) {
      if (state.businessType == "SoleProprietorship") {
        navigate("/business/soleproprietor", {
          state: {
            ...state,
          },
        });
      } else {
        navigate(`/business/document-form`, {
          state: {
            ...state,
          },
        });
      }
    } else {
      setfails(true);
      setErrorMessage("Upload the required documents");
    }
  };
  return (
    <div
      className="container-xxl"
      style={{
        background: "#F6F8FA",
        paddingRight: "0px",
        maxWidth: "2000px",
        width: "100%",
        margin: "0px auto",
      }}
    >
      <Helmet>
        <title>Business Document</title>
      </Helmet>

      <LoginHeader width={55} />

      <div
        className="p-2 align-items-center d-flex"
        style={{
          height: "100vh",
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
        }}
      >
        <div
          className="form-general-layout"
          style={{
            maxWidth: "591px",
            width: "100%",
          }}
        >
          <div
            className=""
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "32px",
            }}
          >
            <div
              className="d-flex justify-content-between "
              style={{ alignItems: "center" }}
            >
              <ArrowBack />
              <span
                style={{
                  fontSize: "14px",
                  fontWeight: "400",
                  color: "#344054",
                  cursor: "pointer",
                  lineHeight: "19.6px",
                }}
                onClick={skipNextPage}
              >
                Skip
              </span>
            </div>
            <span
              className="text-muted d-block"
              style={{
                fontSize: "14px",
                color: "#667085",
                lineHeight: "19.6px",
                fontWeight: "400",
                position: "relative",
              }}
            >
              1 of 2
            </span>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "4px",
                position: "relative",
              }}
            >
              <FailHandler success={fails} message={errorMessage} />

              <div
                style={{
                  fontSize: "32px",
                  fontWeight: "400",
                  color: "#101828",
                  lineHeight: "40px",
                }}
              >
                Upload business documents
              </div>
              <span
                style={{
                  color: "#667085",
                  fontSize: "16px",
                  lineHeight: "24px",
                  fontWeight: "400",
                  letterSpacing: "2%",
                }}
              >
                We’ll take some time to verify your information.
              </span>
            </div>

            <div
              className=" bg-white  "
              style={{
                borderRadius: "8px",
              }}
            >
              <div
                className="position-relative  "
                style={{
                  //  height: "60px",
                  padding: "16px",
                  borderTop: "none",
                  borderRight: "none",
                  borderLeft: "none",
                  borderBottom: "1px solid #E7E9FB ",
                }}
              >
                <input
                  type="file"
                  name="file1"
                  id="file1"
                  onChange={handleFileTwo}
                  style={{
                    position: "absolute",
                    zIndex: 2,
                    opacity: 0,
                    width: "100%",
                  }}
                />
                <div
                  className="d-flex   "
                  style={{
                    alignItems: "center",
                    cursor: "pointer",
                    top: 0,
                    width: "100%",
                    gap: "8px",
                  }}
                >
                  <img src={document} alt="" />
                  <div
                    className="d-flex justify-content-between  w-100"
                    style={{ alignItems: "center" }}
                  >
                    <div
                      className=""
                      style={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <span
                        style={{
                          fontWeight: "600",
                          fontSize: "16px",
                          color: "#101828",
                          letterSpacing: "2%",
                          lineHeight: "24px",
                        }}
                      >
                        Certificate of Incorporation
                      </span>
                      <span
                        style={{
                          fontSize: "12px",
                          color: "#667085 ",
                          lineHeight: "16.2px",
                          fontWeight: "500",
                        }}
                        className=""
                      >
                        From your country
                      </span>
                    </div>
                    {loader1 === false ? (
                      <span
                        className="material-icons"
                        style={{
                          color: "#98A2B3",
                        }}
                      >
                        {fileUploadedTwo ? (
                          <img src={marksuccess} alt="" />
                        ) : (
                          "chevron_right"
                        )}
                      </span>
                    ) : (
                      <Circles
                        height="15"
                        width="15"
                        color="rgb(111, 0, 255)"
                        ariaLabel="circles-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                      />
                    )}
                  </div>
                </div>
              </div>
              <div
                className="position-relative  "
                style={{
                  borderTop: "none",
                  padding: "16px",
                  borderRight: "none",
                  borderLeft: "none",
                  borderBottom: "1px solid #E7E9FB ",
                  borderBottomLeftRadius: "0px",
                  justifyContent: "center",
                  display: "flex",
                  borderBottomRightRadius: "0px",
                }}
              >
                <input
                  type="file"
                  name="file1"
                  id="file1"
                  onChange={handleFileThree}
                  style={{
                    position: "absolute",
                    width: "100%",
                    zIndex: 2,
                    opacity: 0,
                  }}
                />
                <div
                  className="d-flex "
                  style={{
                    alignItems: "center",
                    cursor: "pointer",
                    top: 0,
                    gap: "8px",
                    width: "100%",
                  }}
                >
                  <img src={document} alt="" />
                  <div
                    className="d-flex justify-content-between  w-100"
                    style={{ alignItems: "center" }}
                  >
                    <div className="flex">
                      <span
                        style={{
                          fontWeight: "600",
                          fontSize: "16px",
                          color: "#101828",
                          letterSpacing: "2%",
                          lineHeight: "24px",
                        }}
                      >
                        CAC Status Report (Optional)
                      </span>
                      <div
                        style={{
                          display: "block",
                          fontSize: "12px",
                          color: "#667085 ",
                          lineHeight: "16.2px",
                          fontWeight: "500",
                          marginTop: "0px",
                        }}
                        className=""
                      >
                        Within the last 3 years
                      </div>
                    </div>
                    {loader2 === false ? (
                      <span
                        className="material-icons "
                        style={{
                          color: "#98A2B3",
                        }}
                      >
                        {fileUploadedThree ? (
                          <img src={marksuccess} alt="" />
                        ) : (
                          "chevron_right"
                        )}
                      </span>
                    ) : (
                      <Circles
                        height="15"
                        width="15"
                        color="rgb(111, 0, 255)"
                        ariaLabel="circles-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                      />
                    )}
                  </div>
                </div>
              </div>
              <div
                className="position-relative  "
                style={{
                  borderTop: "none",
                  padding: "16px",
                  borderRight: "none",
                  borderLeft: "none",
                  display: "flex",
                  justifyContent: "center",
                  borderBottom: "none",
                  borderBottomLeftRadius: "0px",
                  borderBottomRightRadius: "0px",
                }}
              >
                <input
                  type="file"
                  name="file1"
                  id="file1"
                  onChange={handleFileFour}
                  style={{
                    position: "absolute",
                    width: "100%",
                    //  height: "50px",
                    zIndex: 2,
                    opacity: 0,
                  }}
                />
                <div
                  className="d-flex  "
                  style={{
                    alignItems: "center",
                    cursor: "pointer",
                    gap: "8px",
                    width: "100%",
                  }}
                >
                  <img src={document} alt="" />
                  <div
                    className="d-flex justify-content-between  w-100"
                    style={{ alignItems: "center" }}
                  >
                    <div className="flex">
                      <span
                        style={{
                          fontWeight: "600",
                          fontSize: "16px",
                          color: "#101828",
                          letterSpacing: "2%",
                          lineHeight: "24px",
                        }}
                      >
                        Valid means of ID
                      </span>
                      <div
                        style={{
                          display: "block",
                          fontSize: "12px",
                          color: "#667085 ",
                          lineHeight: "16.2px",
                          fontWeight: "500",
                          marginTop: "0px",
                        }}
                        className=""
                      >
                        Government-issued e.g. NIN
                      </div>
                    </div>
                    {loader3 === false ? (
                      <span
                        className="material-icons"
                        style={{
                          color: "#98A2B3",
                        }}
                      >
                        {fileUploadedFour ? (
                          <img src={marksuccess} alt="" />
                        ) : (
                          "chevron_right"
                        )}
                      </span>
                    ) : (
                      <Circles
                        height="15"
                        width="15"
                        color="rgb(111, 0, 255)"
                        ariaLabel="circles-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>

            <button
              className="btn mt-4"
              onClick={goNextPage}
              style={{
                background: "#6F00FF",
                color: "#fff",
                fontSize: "16px",
                lineHeight: "24px",
                fontWeight: "400",
                letterSpacing: "2%",
                maxWidth: "279px",
                width: "100%",
                padding: "16px 24px 16px 24px",
                height: "48px",
                alignItems: "center",
                display: "flex",
                justifyContent: "center",
              }}
            >
              Continue
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UploadDocuments;
