import LoginHeader from "../component/loginheader";
import "../style/index.css";
// import {useState} from 'react';
import ArrowBack from "../component/arrowback";
import { Helmet } from "react-helmet";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import document from "../asset/images/document.png";
import { useState } from "react";
import axios from "axios";
import DirectorModalBvn from "../component/directorbvn";
import marksuccess from "../asset/images/marksuccess.svg";
import Loader from "../component/loader";
import { Circles } from "react-loader-spinner";
import FailHandler from "../component/failhandler";
import { useEffect } from "react";
import Congratulations from "../component/congratulation";

// import {useNavigate} from "react-router-dom";

const SoleProperietorship = () => {
  const navigate = useNavigate();
  const { state } = useLocation();

  //  console.log(state);

  // const [documentOne, setDocumentOne] = useState('')
  const [documentTwo, setDocumentTwo] = useState();
  // const [documentThree, setDocumentThree] = useState()
  const [doucumentFour, setDocumentFour] = useState();
  // const [fileUploadedOne, setFileUpladedOne] = useState(false)
  const [fileUploadedTwo, setFileUpladedTwo] = useState(false);
  const [fileUploadedFour, setFileUploadedFour] = useState(false);
  const [error, setError] = useState(false);
  const [director, setDirector] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [loader1, setloader1] = useState(false);
  const [loader2, setloader2] = useState(false);
  const [loader3, setloader3] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [fileUploadedBvn, setfileUploadedBvn] = useState(false);
  const [loader, setloader] = useState(false);
  const [showcongrats, setshowcongrats] = useState(false);
  const [isUploaded1, setisUploaded1] = useState(false);
  const [isUploaded3, setisUploaded3] = useState(false);

  const { id } = useParams();

  const formData = new FormData();

  const showModalHandle = () => {
    setShowModal(true);
  };
  useEffect(() => {
    if (error) {
      let timerr = setTimeout(() => {
        setError(false);
      }, 3000);

      return () => {
        clearTimeout(timerr);
      };
    }
  });

  const handleFileTwo = async (e) => {
    setDocumentTwo(e.target.files[0]);
    setError(false);
    setloader1(true);

    // setDocumentOne('kfkf')

    formData.append("CAC", e.target.files[0], e.target.files[0].name);
    await axios
      .post(
        `v1/business/upload-business-documents/${state.businessId}?businessType=${state.businessType}`,
        formData
      )
      .then(() => {
        setloader1(false);
        setFileUpladedTwo(true);
      })
      .catch((e) => {
        setError(true);
        setloader1(false);
        setErrorMessage(
          e.response?.data?.message
            ? e.response?.data?.message
            : "An error occur"
        );

        console.log(e);
      });
  };
  /*

    const handleFileThree = async (e) => {
        setDocumentThree(e.target.files[0])


        // setDocumentOne('kfkf')
        console.log(documentThree)
        formData.append('businessId', id)
        formData.append('businessType', 'LLC')
        formData.append('CAC_STATUS_REPORT', e.target.files[0], e.target.files[0].name)
        await axios.post('/v1/business/upload-business-documents', formData).then((res) => {
            console.log(res)
            setFileUploadedThree(true)

        }).catch((e) => {
            setError(true)
            setErrorMessage(e.response.data.message)

            console.log(e)
        })
    }
    */
  const handleFileFour = async (e) => {
    setDocumentFour(e.target.files[0]);
    setError(false);
    setloader3(true);

    // setDocumentOne('kfkf')
    formData.append(
      "APPLICATION_LETTER",
      e.target.files[0],
      e.target.files[0].name
    );
    await axios
      .post(
        `v1/business/upload-business-documents/${state.businessId}?businessType=${state.businessType}`,
        formData
      )
      .then(() => {
        setloader3(false);
        setFileUploadedFour(true);
        setisUploaded1(true);
      })
      .catch((e) => {
        setError(true);
        setloader3(false);
        setErrorMessage(
          e.response?.data?.message
            ? e.response?.data?.message
            : "An error occur"
        );

        console.log(e);
      });
  };

  const gotoNextPage = () => {
    window.location.replace("/dashboard");
  };
  const goNextPage = async () => {
    if (isUploaded1 && isUploaded3) {
      setloader(true);
      await axios
        .put(`/v1/business/current/${state.businessId}`)
        .then((res) => {
          // console.log(res)
          axios.defaults.headers.common["x-business-id"] =
            res.data.business._id;
          axios
            .post(`/v1/business/request-verification`)
            .then((res) => {
              if (localStorage.getItem("default") !== null) {
                localStorage.removeItem("default");
              }
              localStorage.setItem("hide", false);
              setshowcongrats(true);
            })
            .catch((e) => {
              console.log(e);
            });
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      setError(true);
      setErrorMessage("Upload the required documents");
    }
  };
  return (
    <div
      className=""
      style={{
        background: "#F6F8FA",
        height: "100vh",
        paddingRight: "0px",
        maxWidth: "2000px",
        width: "100%",
        margin: "0px auto",
      }}
    >
      <Helmet>
        <title>Business Document</title>
      </Helmet>

      <LoginHeader width={15} />
      <DirectorModalBvn
        show={showModal}
        director={director}
        setDirector={setDirector}
        setShowModal={setShowModal}
        setfileUploadedBvn={setfileUploadedBvn}
        state={state}
        type={state.businessType}
        setisUploaded3={setisUploaded3}
      />
      <Congratulations
        show={showcongrats}
        setshow={setshowcongrats}
        goNextPage={gotoNextPage}
      />

      <div
        className="p-2 align-items-center d-flex"
        style={{
          justifySelf: "center",
          display: "flex",
          background: "#F6F8FA",
        }}
      >
        <div
          className="form-general-layout"
          style={{
            position: "relative",
          }}
        >
          <FailHandler success={error} message={errorMessage} />

          <div className="card-body">
            <div
              className="d-flex justify-content-between mb-4"
              style={{ alignItems: "center" }}
            >
              <ArrowBack />
              <span
                style={{
                  fontSize: "14px",
                  fontWeight: "400",
                  color: "#344054",
                  cursor: "pointer",
                  lineHeight: "19.6px",
                }}
                onClick={() => {
                  navigate(`/business/document-form/${id}`);
                }}
              >
                Skip
              </span>
            </div>
            <span
              className="text-muted mb-3 d-block"
              style={{
                fontSize: "14px",
                color: "#667085",
                lineHeight: "19.6px",
                fontWeight: "400",
              }}
            >
              2 of 2
            </span>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "4px",
                marginBottom: "30px",
              }}
            >
              <div
                style={{
                  fontSize: "2rem",
                  fontWeight: "400",
                  color: "#101828",
                  lineHeight: "40px",
                }}
              >
                Upload business documents
              </div>
              <span
                style={{
                  color: "#667085",
                  fontSize: "16px",
                  lineHeight: "24px",
                  fontWeight: "400",
                  letterSpacing: "2%",
                }}
              >
                Provide your sole proprietorship documents
              </span>
            </div>

            <div
              className=" bg-white "
              style={{
                borderRadius: "8px",
              }}
            >
              <div
                className="position-relative "
                style={{
                  borderTop: "none",
                  borderRight: "none",
                  borderLeft: "none",
                  borderBottom: "1px solid #E7E9FB ",
                  borderBottomLeftRadius: "0px",
                  borderBottomRightRadius: "0px",
                  padding: "16px",
                }}
              >
                <input
                  type="file"
                  name="file1"
                  id="file1"
                  onChange={handleFileTwo}
                  style={{
                    position: "absolute",
                    width: "100%",
                    height: "50px",
                    zIndex: 2,
                    opacity: 0,
                  }}
                />
                <div
                  className="d-flex   "
                  style={{
                    alignItems: "center",
                    cursor: "pointer",
                    top: 0,
                    width: "100%",
                    gap: "8px",
                  }}
                >
                  <img src={document} alt="" />
                  <div
                    className="d-flex justify-content-between  w-100"
                    style={{ alignItems: "center" }}
                  >
                    <div className="flex">
                      <div
                        style={{
                          fontWeight: "600",
                          fontSize: "1rem",
                          color: "#101828",
                          letterSpacing: "2%",
                          lineHeight: "24px",
                        }}
                      >
                        CAC BN Form 1 (Optional)
                      </div>
                      <div
                        style={{
                          display: "block",
                          fontSize: "14px",
                          color: "#667085 ",
                          lineHeight: "16.2px",
                          fontWeight: "500",
                        }}
                        className=""
                      >
                        Statement of share capital and return of allotment of
                        shares
                      </div>
                    </div>

                    <span className="material-icons text-muted">
                      {loader1 === false ? (
                        <span
                          className="material-icons"
                          style={{
                            color: "#98A2B3",
                          }}
                        >
                          {fileUploadedTwo ? (
                            <img src={marksuccess} alt="" />
                          ) : (
                            "chevron_right"
                          )}
                        </span>
                      ) : (
                        <Circles
                          height="15"
                          width="15"
                          color="rgb(111, 0, 255)"
                          ariaLabel="circles-loading"
                          wrapperStyle={{}}
                          wrapperClass=""
                          visible={true}
                        />
                      )}
                    </span>
                  </div>
                </div>
              </div>
              <div
                className="position-relative "
                style={{
                  borderTop: "none",
                  padding: "18px",
                  borderRight: "none",
                  borderLeft: "none",
                  borderBottom: "1px solid #E7E9FB ",
                  borderBottomLeftRadius: "0px",
                  borderBottomRightRadius: "0px",
                }}
                onClick={showModalHandle}
              >
                <div
                  className="d-flex   "
                  style={{
                    alignItems: "center",
                    cursor: "pointer",
                    top: 0,
                    width: "100%",
                    gap: "8px",
                  }}
                >
                  <img src={document} alt="" />
                  <div
                    className="d-flex justify-content-between  w-100"
                    style={{ alignItems: "center" }}
                  >
                    <div className="flex">
                      <div
                        style={{
                          fontWeight: "600",
                          fontSize: "1rem",
                          color: "#101828",
                          letterSpacing: "2%",
                          lineHeight: "24px",
                        }}
                      >
                        BVN of the Sole Proprietors or Partners
                      </div>
                    </div>

                    <span className="material-icons text-muted">
                      {fileUploadedBvn ? (
                        <img src={marksuccess} alt="" />
                      ) : (
                        "chevron_right"
                      )}
                    </span>
                  </div>
                </div>
              </div>
              <div
                className="position-relative my-2 card border-0"
                style={{
                  height: "60px",
                  borderTop: "none",
                  borderRight: "none",
                  borderLeft: "none",
                  borderBottom: "none",
                  borderBottomLeftRadius: "10px",
                  borderBottomRightRadius: "10px",
                }}
              >
                <input
                  type="file"
                  name="file1"
                  id="file1"
                  onChange={handleFileFour}
                  style={{
                    position: "relative",
                    width: "100%",
                    height: "50px",
                    zIndex: 2,
                    opacity: 0,
                  }}
                />
                <div
                  className="d-flex  gap-2 card-body  py-2 position-absolute "
                  style={{
                    alignItems: "center",
                    cursor: "pointer",
                    top: 0,
                    width: "100%",
                  }}
                >
                  <img src={document} alt="" />
                  <div
                    className="d-flex justify-content-between  w-100"
                    style={{ alignItems: "center" }}
                  >
                    <div
                      className="flex"
                      style={{
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          fontWeight: "600",
                          fontSize: "1rem",
                          color: "#101828",
                          letterSpacing: "2%",
                          lineHeight: "24px",
                        }}
                      >
                        Application letter to open an account with Tradevu
                      </div>
                    </div>

                    <span className="material-icons text-muted">
                      {loader3 === false ? (
                        <span
                          className="material-icons "
                          style={{
                            color: "#98A2B3",
                          }}
                        >
                          {fileUploadedFour ? (
                            <img src={marksuccess} alt="" />
                          ) : (
                            "chevron_right"
                          )}
                        </span>
                      ) : (
                        <Circles
                          height="15"
                          width="15"
                          color="rgb(111, 0, 255)"
                          ariaLabel="circles-loading"
                          wrapperStyle={{}}
                          wrapperClass=""
                          visible={true}
                        />
                      )}
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <button
              disabled={loader}
              className="btn mt-4"
              onClick={() => goNextPage()}
              style={{
                background: "#6F00FF",
                color: "#fff",
                fontSize: "1rem",
                lineHeight: "24px",
                fontWeight: "400",
                letterSpacing: "2%",
                maxWidth: "279px",
                width: "100%",
                padding: "16px 24px 16px 24px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {loader ? <Loader /> : "Continue"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SoleProperietorship;
