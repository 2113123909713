import Swal from "sweetalert2";

export const errorAlert = (error) => {
    const err = error?.response?.data?.message ?? error?.message ?? error ?? "";
    Swal.fire({
        text: err,
        icon: "error",
        confirmButtonText: "Cancel",
        width: "300px",
        confirmButtonColor: "crimson",
    });
};

export const successAlert = (text) =>
    Swal.fire({
        text,
        icon: "success",
        width: "300px",
        confirmButtonColor: "#6f00ff",
        // confirmButtonText: "YEAAHH",
    });
