const Toggler = ({ toggle, setToggle }) => (
    <div
        onClick={() => setToggle((prev) => !prev)}
        className={`w-[35px] h-[15.93px] rounded-[33.33px] cursor-pointer transition-colors ease-in-out duration-200 ${
            toggle ? "bg-[#f4f0ff]" : "bg-[#D9DBE9]"
        }`}
    >
        <div
            className={`size-5 rounded-full border transition-all ease-in-out duration-200  ${
                toggle
                    ? "bg-[#7f56d9] translate-x-4"
                    : "!border-[#E7E9FB] bg-[#F6F8FA] -translate-x-[1px]"
            } -translate-y-0.5 !shadow-[0px_3.33px_5px_-3.33px_rgba(16,24,40,0.1),0px_0px_16.67px_-2.5px_rgba(16,24,40,0.38)]`}
        />
    </div>
);
export default Toggler;
