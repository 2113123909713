import { ArrowIcon, CheckCircleIcon } from "asset/icons";
import Dropdown from "component/form/dropDown";
import Input from "component/form/Input";
import { countrycodelist } from "constants/countryList";
import { Fragment, useEffect, useRef, useState } from "react";
import { Bars } from "react-loader-spinner";
import capitalizeWords from "utilities/capitalizeWords";
import getInitials from "utilities/getInitials";

const BankSelect = ({
    fetchNGNBanks,
    NGNBanks: _NGNBanks,
    NGNBanksLoading,
    bankName,
    setBank,
    close,
    country,
    setCountry,
}) => {
    const dropDownRef = useRef();
    const [searchString, setSearchString] = useState("");

    const NGNBanks = _NGNBanks?.filter(({ name }) =>
        name.toLowerCase().includes(searchString.toLowerCase())
    );
    const alphabets = [
        ...new Set(NGNBanks?.map(({ name }) => name.charAt(0).toUpperCase())),
    ].sort();

    const isNigeria = country.title === "Nigeria";

    useEffect(() => {
        if (isNigeria && !NGNBanks) {
            fetchNGNBanks("/v1/wallets/get_all_banks/all?currency=NGN");
        }
    }, [isNigeria, NGNBanks]);

    return (
        <div className="relative bg-page-bg rounded-xl w-full !p-4 z-30 shadow-[0px_8px_5px_0px_#00000014,0px_20px_13px_0px_#00000008]">
            <p className={`font-medium text-sm text-left text-[#475467] !mb-2`}>
                Recipient's country
            </p>

            <>
                <p
                    className="bg-white rounded-lg h-12 !px-2 font-[450] text-main-dark flex items-center cursor-pointer relative z-20"
                    onClick={() => dropDownRef?.current?.click()}
                >
                    <img
                        src={country.image}
                        alt={country.title}
                        className="size-8 rounded-full !mr-2"
                    />
                    {country.title}
                    <ArrowIcon className="*:stroke-main-grey size-4 ml-auto" />
                </p>

                <Dropdown
                    ref={dropDownRef}
                    options={countrycodelist.map(({ image, title, ...rest }, idx) => ({
                        label: (
                            <p
                                className={`font-[450] text-main-dark w-full flex items-center cursor-pointer pointer-events-auto !px-4 !py-2.5 ${
                                    idx && "!mt-1.5"
                                }`}
                            >
                                <img
                                    src={image}
                                    alt={title}
                                    className="size-[26px] rounded-full !mr-2"
                                />
                                {title}
                                {country.title === title && <CheckCircleIcon className="ml-auto" />}
                            </p>
                        ),
                        value: title,
                        action: () => {
                            if (country.title !== title) {
                                setCountry({ title, image, ...rest });
                                setBank(null, null);
                            }
                        },
                        className: "!p-0",
                    }))}
                    className="!h-12 -translate-y-12 z-10 !pointer-events-none"
                />
            </>

            <>
                {!isNigeria && (
                    <p
                        className={`font-medium text-sm text-left -translate-y-[30px] text-[#475467]`}
                    >
                        Bank name
                    </p>
                )}
                <Input
                    onChange={(value) =>
                        isNigeria ? setSearchString(value) : setBank(value, null)
                    }
                    value={isNigeria ? searchString : bankName}
                    placeholder={isNigeria ? "Search banks" : "Enter bank name"}
                    className="-translate-y-12 !shadow-[inset_0_0_0_1000px_#EAECF0]"
                />

                {!isNigeria && (
                    <button
                        className={`${
                            bankName
                                ? "!bg-secondary pointer-events-auto"
                                : "!bg-[#D9CDFF] pointer-events-none"
                        } text-white cursor-pointer -translate-y-7 rounded-lg !py-2 px-7 mr-auto !mb-[134px]`}
                        onClick={close}
                    >
                        Ok
                    </button>
                )}

                {isNigeria && (
                    <div
                        className={`mt-3 -translate-y-12 rounded-lg max-h-[280px] -mb-11 overflow-y-auto`}
                    >
                        {NGNBanksLoading ? (
                            <div className="grid place-items-center h-56">
                                <Bars
                                    height="22"
                                    width="22"
                                    color="#6f00ff"
                                    ariaLabel="bars-loading"
                                    wrapperClass="w-max"
                                />
                            </div>
                        ) : (
                            <>
                                {alphabets.map((letter, letterIdx) => (
                                    <Fragment key={letter}>
                                        <p
                                            className={`font-medium  text-left text-xs text-[#667085] ${
                                                letterIdx && "!mt-[15px]"
                                            } !mb-2`}
                                        >
                                            {letter}
                                        </p>
                                        <div
                                            className={`rounded-lg !px-2 bg-white flex flex-col gap-2.5 !py-2.5`}
                                        >
                                            {NGNBanks?.filter(({ name }) =>
                                                name.toUpperCase().startsWith(letter)
                                            ).map(({ name, code }) => (
                                                <div
                                                    className={`flex items-center gap-2 !py-1.5 cursor-pointer rounded-lg`}
                                                    onClick={() => {
                                                        setBank(name, code);
                                                        close();
                                                    }}
                                                    key={name + code}
                                                >
                                                    <span
                                                        className={`!size-10 bg-[#F6F6F6] rounded-full text-[#344054] font-[450] grid place-items-center overflow-hidden ${
                                                            getInitials(name).length > 3 &&
                                                            "text-[13px]"
                                                        }`}
                                                    >
                                                        {getInitials(name)}
                                                    </span>
                                                    <p className="text-main-dark font-[450]">
                                                        {capitalizeWords(name)}
                                                    </p>

                                                    {bankName === name && (
                                                        <CheckCircleIcon className="ml-auto !mr-2 !size-5" />
                                                    )}
                                                </div>
                                            ))}
                                        </div>
                                    </Fragment>
                                ))}
                            </>
                        )}
                    </div>
                )}
            </>
        </div>
    );
};

export default BankSelect;
