import Modal from "react-bootstrap/Modal";
import "../style/rightside.css";
import { useState } from "react";
import checkbox from "../asset/images/Checkbox.svg";
import RecordPaymentform from "./recoredpaymentform";

function PaymentMethod(props) {
  const [selectedindex, setselectedindex] = useState(-1);
  const [selectedmethod, setselectedmethod] = useState("");
  const [show, setshow] = useState(false);

  const closeModal = () => {
    props.setshow(false);
  };

  return (
    <>
      <RecordPaymentform
        show={show}
        setshow={setshow}
        selectedinvoice={props.selectedinvoice}
        selectedmethods={selectedmethod}
        record={props.record}
        setinvoices={props.setinvoices}
        currency={props.currency}
      />
      <Modal
        show={props.show}
        className="addpaymentmodal"
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={() => props.setshow(false)}
      >
        <Modal.Body
          style={{
            borderRadius: "16px",
            padding: "0px",
            border: "none",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "14px",
            }}
          >
            <div
              className=""
              style={{
                borderBottom: "1px solid #E7E9FB",
                padding: "8px 24px 8px 24px",
              }}
            >
              <span
                className="material-icons"
                style={{ color: "#667085" }}
                onClick={closeModal}
              >
                close
              </span>
            </div>
            <div style={{ padding: "8px 24px 8px 24px" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "4px",
                }}
              >
                <h3
                  style={{
                    fontWeight: "700",
                    color: "#101828",
                    lineHeight: "24.3px",
                    fontSize: "18px",
                  }}
                >
                  Choose payment type
                </h3>
                <div className="card border-0 mb-3">
                  <div className="card-body p-1">
                    {props.tenure.map((tenures, index) => (
                      <div
                        key={index}
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          background: selectedindex === index ? "#F4F0FF" : "",
                          borderRadius: "8px",
                        }}
                        onClick={() => {
                          setselectedindex(index);
                          props.setselectedmethod(tenures);
                          setselectedmethod(tenures);
                          setshow(true);
                          props.setshow(false);
                        }}
                      >
                        <span
                          style={{
                            fontWeight: "500",
                            letterSpacing: "2%",
                            fontSize: "16px",
                            lineHeight: "24px",
                            color: "#101828",
                            padding: "6px 8px 6px 8px",
                          }}
                        >
                          {tenures}
                        </span>
                        {selectedindex === index && (
                          <img
                            src={checkbox}
                            alt=""
                            style={{
                              width: "13.33px",
                              height: "13.33px",
                              marginRight: "10px",
                            }}
                          />
                        )}{" "}
                      </div>
                    ))}{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default PaymentMethod;
