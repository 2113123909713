import { Helmet } from "react-helmet";
import { useEffect, useState } from "react";
import SerHeader from "../component/serviceheader";
import nigeria from "../asset/images/countries/ngn.svg";
import Loader from "../component/loader";
import { useLocation, useNavigate } from "react-router-dom";
import "../style/slider.css";
import "../style/main.css";
import info from "../asset/images/iconinfo.svg";
import currencys from "../asset/images/GB.svg";
import currencyss from "../asset/images/EU.svg";
import US from "../asset/images/US.png";
import FailHandler from "../component/failhandler";
import axios from "axios";
import edit from "../asset/images/edit-2.svg";
import LoaderView from "../component/loadingview";
import { useSelector } from "react-redux";

const TradevuWallet = () => {
  const selectorwallet = useSelector((state) => state.wallet);

  const navigate = useNavigate();
  const { state } = useLocation();
  const [loader] = useState(false);
  const [isdisable, setisdisable] = useState(true);
  const [amount, setamount] = useState("");
  const [fails, setfail] = useState(false);
  const [message, setmessage] = useState("");
  const [details, setDetails] = useState([]);
  const [isloadingdatas, setisloadingdata] = useState(false);
  const [isError, setIsError] = useState(false);

  const [isSuccess, setISSuccess] = useState(false);

  // console.log(selectorwallet);
  function hasCurrency(currency) {
    return selectorwallet.some((account) => account.accountNumber === currency);
  }
  //const isCurrencyPresent = hasCurrency(amount);
  //console.log(isCurrencyPresent);

  useEffect(() => {
    if (details.length !== 0) {
      setisdisable(false);
    } else {
      setisdisable(true);
    }
  }, [setisdisable, amount]);

  useEffect(() => {
    let timer = setTimeout(() => {
      setfail(false);
    }, 3000);
    clearTimeout(timer);
  }, []);
  useEffect(() => {
    const getAccountDetails = async () => {
      if (state.default && amount.length === 10) {
        if (hasCurrency(amount) === false) {
          setisloadingdata(true);

          await axios
            .get(
              `/v1/wallets/get_wallet_by_account_number/${amount}?currency=${state.quoteCurrency}`
            )
            .then((res) => {
              setisloadingdata(false);
              setDetails(res.data.data);
              setISSuccess(true);
              setIsError(false);
              //  console.log(res);
            })
            .catch((e) => {
              // console.log(e);
              setmessage("No wallet found");
              setIsError(true);
              setisloadingdata(false);
              setISSuccess(false);
            });
        } else {
          setmessage("Sorry, You cant send to your own account Number");
          // console.log("Sorry, You cant send to your own account Number");
          setIsError(true);
          setisloadingdata(false);
          setISSuccess(false);
        }
      }
    };
    getAccountDetails();
  }, [state.default, amount]);
  // console.log(details.length);
  const submitHandler = async (e) => {
    e.preventDefault();
    // console.log("clicked");

    if (isSuccess === true) {
      navigate(`/payment/document`, {
        state: {
          ...state,
          type: "bankuser",
          //amount: amount,
          payType: state.payType,
          quoteCurrency: state.quoteCurrency,
          currency: state.default,
          accountName: details.business.name,
          accountNumber: amount,
          recipientType: "Business",
          bankName: state.default === "NGN" ? "Kuda" : "Tradevu Wallet",
        },
      });
    } else {
      setfail(true);
      setmessage("Invalid Amount");
    }
  };

  return (
    <div className="h-100 w-100 ">
      <div
        className=""
        style={{
          maxWidth: "2000px",
          width: "100%",
          margin: "0px auto",
        }}
      >
        <Helmet>
          <title>
            {state.payType === "crossBorderFx"
              ? "Cross-border Payment"
              : "Domestic Payment"}
          </title>
        </Helmet>

        <SerHeader
          header={`${
            state.payType === "crossBorderFx"
              ? "Cross-border Payment"
              : "Domestic Payment"
          }`}
          width={25}
        />
      </div>
      <div
        style={{
          height: "100%",
          maxWidth: "2000px",
          margin: "0px auto",
          width: "100%",
        }}
      >
        <div
          className=" "
          style={{
            margin: "0px auto",
            paddingLeft: "30px",
            paddingRight: "30px",
            paddingTop: "10px",
            paddingBottom: "10px",
            width: "100%",
          }}
        >
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",

              justifyContent: "center",
            }}
          >
            <form
              onSubmit={submitHandler}
              className="form-general-layout"
              style={{
                position: "relative",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "32px",
                  position: "relative",
                }}
              >
                <FailHandler success={fails} message={message} />

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "8px",
                  }}
                >
                  <div
                    style={{
                      color: "#101828",
                      fontWeight: "600",
                      fontSize: "24px",
                      lineHeight: "32.4px",
                    }}
                  >
                    Send Funds?
                  </div>
                  <span
                    style={{
                      color: "#667085",
                      fontWeight: "500",
                      lineHeight: "24px",
                      fontSize: "1rem",
                      letterSpacing: "2%",
                    }}
                  >
                    Enter Tradevu {state.quoteCurrency} wallet below
                  </span>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "32px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "8px",
                      width: "100%",
                    }}
                  >
                    <label
                      htmlFor=""
                      style={{
                        fontSize: "14px",
                        lineHeight: "19.6px",
                        fontWeight: "500",
                        color: "#101828",
                      }}
                    >
                      Tradevu Wallet
                    </label>
                    <div
                      className=""
                      style={{
                        display: "flex",
                        gap: "8px",
                        flexDirection: "column",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          gap: "8px",
                        }}
                      >
                        <input
                          type="text"
                          name=""
                          id=""
                          minLength={10}
                          maxLength={10}
                          onChange={(e) => {
                            setamount(e.target.value);
                          }}
                          style={{
                            width: "100%",
                            borderRadius: "8px",
                            border: "none",
                            height: "56px",
                            outline: "none",
                            background: "",
                            padding: "8px 16px 8px 16px",
                            backgroundColor: "#ECEFF3",
                            letterSpacing: "2%",
                            fontWeight: "500",
                            fontSize: "16px",
                          }}
                          placeholder="Enter Tradevu Wallet"
                        />
                      </div>
                    </div>
                  </div>
                  {isloadingdatas === true && (
                    <LoaderView data={isloadingdatas} />
                  )}
                  {isError === true && (
                    <span
                      style={{
                        color: "crimson",
                        fontSize: "14px",
                        fontWeight: "600",
                        lineHeight: "24.6px",
                        marginTop: "-30px",
                      }}
                    >
                      {message}
                    </span>
                  )}

                  {isError === false && isSuccess === true && (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "8px",
                      }}
                    >
                      <label
                        htmlFor=""
                        className="mysummarytitle"
                        style={{
                          fontWeight: "500",
                        }}
                      >
                        Recipient’s Details
                      </label>
                      <div
                        className="white-bh-lg"
                        style={{
                          minHeight: "56px",
                        }}
                      >
                        <div className="transfer-bank-logo">
                          <div className="intial">
                            {details.business.name
                              .match(/(\b\S)?/g)
                              .join("")
                              .match(/(^\S|\S$)?/g)
                              .join("")
                              .toUpperCase()}
                          </div>
                          <div className="name-bank">
                            <span
                              className="name-link"
                              style={{
                                fontWeight: "400",
                              }}
                            >
                              {details.business.name}
                            </span>
                            <span
                              className="bank-link"
                              style={{
                                fontWeight: "400",
                              }}
                            >
                              {state.default === "NGN"
                                ? "Kuda"
                                : "Tradevu Wallet"}{" "}
                              . {details.accountNumber}
                            </span>
                          </div>
                        </div>
                        <img src={edit} alt="" />
                      </div>
                    </div>
                  )}
                  {state.payType === "sameCurrency" && (
                    <div
                      style={{
                        padding: "16px",
                        borderRadius: "8px",
                        background: "#fff",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          gap: "8px",
                          alignItems: "center",
                          width: "100%",
                        }}
                      >
                        <img src={info} alt="" />
                        <span
                          style={{
                            color: "#667085",
                            fontWeight: "500",
                            fontSize: "14px",
                            lineHeight: "19.6px",
                          }}
                        >
                          These are regular and instant transfers between the
                          same currencies.
                        </span>
                      </div>
                    </div>
                  )}

                  <div className="">
                    <button
                      //  disabled={details.length !== 0}
                      style={{
                        maxWidth: "280px",
                        width: "100%",
                        background:
                          details.length === 0 ? "#EBE4FF" : "#6F00FF",
                        padding: "16px 24px 16px 24px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        border: "none",
                        outline: "none",
                        minHeight: "46px",
                        borderRadius: "8px",
                        fontSize: "16px",
                        lineHeight: "24px",
                        letterSpacing: "2%",
                        fontWeight: "600",
                        color: details.length === 0 ? "#BFA6FF" : "#ffff",
                      }}
                    >
                      {loader === true ? <Loader data={loader} /> : "Continue"}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TradevuWallet;
