import tradeofcredit from "../asset/images/tradeofcredit.svg";
import factoring from "../asset/images/factoring.svg";
import letterofcredit from "../asset/images/letter_of_creditspng.svg";
import fxtransfer from "../asset/images/newtradevu.svg";
import PaymentLink from "../asset/images/paymentLink.svg";
import BankTransfer from "../asset/images/BankTransfer.svg";
import transact from "../asset/images/transact.svg";

export default function ImageController(props) {
  // console.log(props);

  return props === "LetterOfCredit"
    ? letterofcredit
    : props === "Invoice"
    ? factoring
    : props === "FxTransfer"
    ? fxtransfer
    : props === "crossBorderFx"
    ? fxtransfer
    : props === "sameCurrency"
    ? fxtransfer
    : props === "PaymentLink"
    ? PaymentLink
    : props === "BankTransfer"
    ? BankTransfer
    : props === "WalletDebit"
    ? transact
    : props === "WalletCredit"
    ? transact
    : tradeofcredit;
}
