import notFoundImg from "asset/images/not-found.svg";

const EmptyList = ({ message, alt, btnText, onClick }) => {
    return (
        <div className="flex flex-col items-center !mt-9 !mb-11">
            <img src={notFoundImg} alt={alt} className="w-[144px] h-[107.39px]" />
            <p className="text-main-dark font-medium text-sm !mt-5 ">{message}</p>
            {btnText && (
                <button
                    className="bg-[#5E01D6] !py-2 !px-4 rounded-lg !text-[#FBFAFF] !mt-[22px]"
                    onClick={onClick}
                >
                    {btnText}
                </button>
            )}
        </div>
    );
};

export default EmptyList;
