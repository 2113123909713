import { AddIcon, DeleteIcon } from "asset/icons";
import { useState } from "react";
import { formatMoney } from "utilities/formatMoney";

const OrderItemRow = ({
    itemName,
    pricePerUnit,
    quantity,
    currency,
    id,
    updateItem,
    deleteItem,
    showDelete,
}) => {
    const [qty, setQty] = useState(quantity);
    const amount = qty ? pricePerUnit * qty : 0;

    return (
        <div className="grid grid-cols-5 520:grid-cols-4 !gap-x-1 475:!gap-x-2 sm:!gap-x-4 md:!gap-x-2 820:!gap-x-4 !mt-6 relative">
            <div className="max-520:col-span-2">
                <p className="font-medium text-[11px] 375:text-xs 475:text-sm !mb-1">Items</p>
                <input
                    className="h-[56px] bg-[#EAECF0] w-full !px-4 placeholder:text-main-grey rounded-lg !shadow-[inset_0_0_0_1000px_#EAECF0]"
                    placeholder="Item name"
                    value={itemName || ""}
                    onChange={(evt) => updateItem(id, "itemName", evt.target.value)}
                    required
                />
            </div>

            <div className="">
                <p className="font-medium text-[11px] 375:text-xs 475:text-sm !mb-1">Unit price</p>
                <div
                    className={`flex h-[56px] items-center ${
                        pricePerUnit && !isNaN(pricePerUnit) ? "text-main-dark" : "text-main-grey"
                    } rounded-lg overflow-hidden !px-4 bg-[#EAECF0]`}
                >
                    {currency}
                    <input
                        className="h-full w-full bg-inherit !ml-1 text-main placeholder:text-main-grey !shadow-[inset_0_0_0_1000px_#EAECF0]"
                        placeholder="0"
                        pattern="\d{1,3}(,\d{3})*(?:\.\d{1,2})?"
                        title="Only numbers allowed"
                        required
                        value={pricePerUnit ? formatMoney(pricePerUnit) : ""}
                        onChange={(evt) => {
                            const value = evt.target.value.replace(/,/g, "");
                            !isNaN(+value) && updateItem(id, "pricePerUnit", value);
                        }}
                    />
                </div>
            </div>

            <div className="">
                <p className="font-medium text-[11px] 375:text-xs 475:text-sm !mb-1">Quantity</p>
                <div className="flex h-[56px] items-center text-main-grey rounded-lg overflow-hidden !px-4 bg-[#EAECF0]">
                    <input
                        className="h-full w-full bg-inherit !mr-1 text-main placeholder:text-main-grey !shadow-[inset_0_0_0_1000px_#EAECF0]"
                        placeholder="0"
                        pattern="\d{1,3}(,\d{3})*"
                        title="Only numbers allowed"
                        required
                        value={qty ? formatMoney(qty) : ""}
                        onChange={(evt) => {
                            const value = +evt.target.value.replace(/,/g, "");
                            if (!isNaN(value)) {
                                updateItem(id, "quantity", value);
                                setQty(value);
                            }
                        }}
                    />
                    <AddIcon
                        className="cursor-pointer text-main-grey !size-7 rounded-full *:fill-main-grey"
                        onClick={() =>
                            setQty((prev) => {
                                const newNum = prev + 1;
                                updateItem(id, "quantity", newNum);
                                return newNum;
                            })
                        }
                    />
                </div>
            </div>

            <div className="">
                <p className="font-medium text-[11px] 375:text-xs 475:text-sm !mb-1 !pl-2.5">Amount</p>
                <p
                    className={`${
                        amount ? "text-main-dark" : "text-main-grey"
                    } h-[56px] !px-4 flex items-center`}
                >
                    {currency} {formatMoney(amount, true)}
                </p>
            </div>

            {showDelete && (
                <DeleteIcon
                    className="absolute -right-4 520:right-0 top-9 cursor-pointer max-520:size-5"
                    onClick={deleteItem}
                />
            )}
        </div>
    );
};

export default OrderItemRow;
