import { Helmet } from "react-helmet";
// import DashHeader from "../component/dashheader";
// import homefinance from '../asset/im
import SerHeader from "../component/serviceheader";

import uploadinvoice from "../asset/images/uploadinvoice.svg";
import createinvoice from "../asset/images/createinvoice.svg";
import { useNavigate } from "react-router-dom";
import TenureModal from "../component/tenure";
import { useState } from "react";
import FinanceInvoice from "../component/FinanceInvoice";

const Finance = () => {
  // const [selectedIndex, setselectedIndex] = useState(-1)
  const navigate = useNavigate();
  const [show, setshow] = useState(false);

  return (
    <div
      style={{
        background: "#F6F6F6",
        height: "100vh",
        width: "100%",
      }}
    >
      <div
        className=""
        style={{
          maxWidth: "2000px",
          width: "100%",
          margin: "0px auto",
        }}
      >
        <Helmet>
          <title>Invoice</title>
        </Helmet>

        <SerHeader header={"Invoice Factoring"} />
        <FinanceInvoice show={show} setshow={setshow} />
      </div>
      <div style={{ height: "100%" }}>
        <div
          className=" "
          style={{
            maxWidth: "2000px",
            margin: "0px auto",
            paddingLeft: "30px",
            paddingRight: "30px",
            paddingTop: "10px",
            paddingBottom: "10px",
            width: "100%",
          }}
        >
          <div
            style={{
              height: "100%",
              width: "100%",
              display: "flex",

              justifyContent: "center",
            }}
          >
            <div className="form-general-layout">
              <div className="">
                <div
                  style={{
                    color: "#101828",
                    fontSize: "24px",
                    lineHeight: "32.4px",
                    fontWeight: "500",
                  }}
                >
                  Choose an invoice type
                </div>
                <span
                  style={{
                    color: "#667085",
                    fontSize: "16px",
                    lineHeight: "24px",
                    letterSpacing: "2%",
                    fontWeight: "400",
                  }}
                >
                  Our invoice factoring service unlocks cash that’s tied up in
                  your account receivables by paying your invoices as soon as
                  you ship. Tradevu assumes the credit risk on your buyers.
                </span>
              </div>
              <div className="">
                <div
                  className="card border-0"
                  style={{
                    borderRadius: "8px",
                  }}
                >
                  <div className="card-body p-0">
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        cursor: "pointer",
                      }}
                    >
                      <div
                        style={{
                          borderBottom: "1px solid #E7E9FB",
                          padding: "16px",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          position: "relative",
                        }}
                        onClick={() => navigate("/finance/createinvoice")}
                      >
                        <div
                          style={{
                            display: "flex",
                            gap: "16px",
                          }}
                        >
                          <img
                            src={uploadinvoice}
                            alt=""
                            width={48}
                            height={48}
                          />
                          <div
                            style={{
                              overflow: "hidden",
                              display: "flex",
                              flexDirection: "column",
                              gap: "4px",
                            }}
                          >
                            <div
                              style={{
                                color: "#101828",
                                fontSize: "16px",
                                lineHeight: "24px",
                                letterSpacing: "2%",
                                fontWeight: "600",
                              }}
                            >
                              Upload invoice
                            </div>
                            <span
                              style={{
                                fontSize: "14px",
                                fontWeight: "400",
                                lineHeight: "19.6px",
                                color: "#667085",
                              }}
                            >
                              Use an existing invoice
                            </span>
                          </div>
                        </div>
                        <span
                          className="material-icons"
                          style={{ color: "#667085" }}
                        >
                          chevron_right
                        </span>
                      </div>
                      <div
                        style={{
                          padding: "16px",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setshow(true);
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            gap: "16px",
                          }}
                        >
                          <img
                            src={createinvoice}
                            alt=""
                            width={48}
                            height={48}
                          />
                          <div
                            style={{
                              overflow: "hidden",
                              display: "flex",
                              flexDirection: "column",
                              gap: "4px",
                            }}
                          >
                            <div
                              style={{
                                color: "#101828",
                                fontSize: "16px",
                                lineHeight: "24px",
                                letterSpacing: "2%",
                                fontWeight: "600",
                              }}
                            >
                              Use Tradevu Invoice
                            </div>
                            <p
                              style={{
                                fontSize: "14px",
                                fontWeight: "400",
                                lineHeight: "19.6px",
                                color: "#667085",
                              }}
                            >
                              Choose from your saved invoices.
                            </p>
                          </div>
                        </div>
                        <span
                          className="material-icons"
                          style={{ color: "#667085" }}
                        >
                          chevron_right
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Finance;
