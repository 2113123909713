import { Bars } from "react-loader-spinner";
const Loader = (props) => {
  return (
    <>
      <Bars
        height="20"
        width="20"
        color="#fff"
        ariaLabel="bars-loading"
        wrapperStyle={{
          margin: "0px auto",
        }}
        wrapperClass=""
        visible={props}
      />
    </>
  );
};

export default Loader;
