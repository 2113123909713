import config from "constants/config";
import { createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";

const loginStoreName = config.LOGIN_STORE_NAME;

const initialState = {
    users: [],
    token: "",
    isLogin: !!localStorage.getItem(loginStoreName),
    businessid: "",
    business: [],
    isLoading: true,
    wallets: [],
    currency: localStorage.getItem("default") ?? "NGN",
};

const AuthReducer = (state = initialState, action) => {
    if (action.type === "LOGIN") {
        console.log(action.payload);
        localStorage.setItem(loginStoreName, true);
        return {
            ...state,
            users: action.payload.user,
            token: action.payload.token,
            isLogin: true,
            isLoading: false,

            // dispatch('attempt',action)
        };
    }
    if (action.type === "USER_PROFILE") {
        localStorage.setItem(loginStoreName, true);
        return {
            ...state,
            users: action.payload.data.data,
            isLogin: true,
            isLoading: false,
        };
    }
    if (action.type === "BUSINESS_RES") {
        return {
            ...state,
            businessid: action.payload.data._id,
            business: action.payload.data,
        };
    }
    if (action.type === "BUSINESSES_RES") {
        return {
            ...state,
            wallet: action.payload.data.data.allWallets,
        };
    }
    if (action.type === "LOGOUT") {
        localStorage.removeItem(loginStoreName);
        return {
            ...state,
            wallet: [],
            user: [],
            business: [],
            businessid: "",
            isLogin: false,
        };
    }
    if (action.type === "CLOSE_ACCOUNT") {
        localStorage.removeItem(loginStoreName);
        return {
            ...state,
            wallet: [],
            user: [],
            business: [],
            businessid: "",
            isLogin: false,
        };
    }

    if (action.type === "WALLETS") {
        return {
            ...state,
            wallets: action.payload,
        };
    }

    if (action.type === "CURRENCY") {
        return {
            ...state,
            currency: action.payload,
        };
    }

    if (action.type === "BUSINESS") {
        return {
            ...state,
            business: action.payload,
        };
    }

    return state;
};

const store = createStore(AuthReducer, composeWithDevTools());

export default store;
