import { Helmet } from "react-helmet";
// import DashHeader from "../component/dashheader";
// import homefinance from '../asset/im
import { useEffect, useState } from "react";

import SerHeader from "../component/serviceheader";

import UploadInvoice from "../component/uploadinvoice";
import arrowleft from "../asset/images/arrowleftcircle.svg";

import UploadModal from "../component/tenure";
import Uploaddocumentfinance from "../component/uploadloaddocument";
import InvoiceSuccess from "../component/invoicesuccess";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Loader from "../component/loader";
import "../style/slider.css";
import Swal from "sweetalert2/dist/sweetalert2.js";
import axios from "axios";
import FailHandler from "../component/failhandler";

const Goods = () => {
  // const [selectedIndex, setselectedIndex] = useState(-1)
  const navigate = useNavigate();

  const [show, setShow] = useState(false);
  const [selecteditem, setselecteditem] = useState([]);
  const [loader, setloader] = useState(false);
  const [filename, setfilename] = useState(null);
  const [filesize, setfilesize] = useState(0);
  const [aggreement, setagrrement] = useState(null);
  const [aggreementsize, setaggreementsize] = useState(0);
  const [showModal, setshowModal] = useState(false);
  const [showModal2, setshowModal2] = useState(false);
  const [showModal3, setshowModal3] = useState(false);
  const [tenureday, settenureday] = useState("0 Days");
  const [isdisable, setisdisable] = useState(true);
  const [message, setmessage] = useState("");
  const [fail, setfail] = useState(false);
  const { state } = useLocation();

  const [goods, setgoods] = useState("");
  const [tenure, settunre] = useState([
    "7 Days",
    "14 Days",
    "30 Days",
    "40 Days",
  ]);
  const { id } = useParams();
  console.log(state);

  const handleClose = () => setShow(false);

  const submitHandler = async (e) => {
    e.preventDefault();
    setloader(true);
    const data = {
      typesOfGoods: goods,
    };
    await axios
      .put(`v1/finance/trade_credit/update/${id}`, data)
      .then((res) => {
        //  console.log(res);

        navigate("/trade_of_credit/summary", {
          state: {
            ...state,
            _id: id,
            goods: goods,
          },
        });
      })
      .catch((e) => {
        setmessage(
          e.response?.data?.message ? e.response.data.message : "An error occur"
        );
        setfail(true);
        setloader(false);
      });
  };
  useEffect(() => {
    if (goods !== "") {
      setisdisable(false);
    }
  }, [goods]);

  useEffect(() => {
    if (fail) {
      setTimeout(() => {
        setfail(false);
      }, 3000);
    }
  });

  return (
    <div className="h-100 w-100 ">
      <div
        className=""
        style={{
          maxWidth: "2000px",
          width: "100%",
          margin: "0px auto",
        }}
      >
        <Helmet>
          <title>Working Capital</title>
        </Helmet>

        <SerHeader header={"Working Capital"} width={60} />
        <InvoiceSuccess show={showModal3} setshow={setshowModal3} />
        <Uploaddocumentfinance
          show={showModal2}
          setshow={setshowModal2}
          title={"Letter of credit"}
          text={
            "This should clearly show the details of your transaction with your supplier"
          }
          filename={aggreement}
          filesize={aggreementsize}
          setfilesize={setaggreementsize}
          setfilename={setagrrement}
        />
        <UploadModal
          filesize={filesize}
          show={showModal}
          tenure={tenure}
          tenureday={tenureday}
          settenureday={settenureday}
          settunre={settunre}
          setshow={setshowModal}
          filename={filename}
          setfilesize={setfilesize}
          setfilename={setfilename}
        />
      </div>
      <div style={{ height: "100%" }}>
        <div
          className="conatiner "
          style={{
            maxWidth: "2000px",
            margin: "0px auto",
            paddingLeft: "30px",
            paddingRight: "30px",
            paddingTop: "10px",
            paddingBottom: "10px",
          }}
        >
          <div
            style={{
              height: "100%",
              width: "100%",
              display: "flex",

              justifyContent: "center",
            }}
          >
            <UploadInvoice
              show={show}
              handle={handleClose}
              selecteditem={selecteditem}
              setselecteditem={setselecteditem}
              contacts={[]}
            />
            <form
              onSubmit={submitHandler}
              className="form-general-layout"
              style={{
                position: "relative",
              }}
            >
              <FailHandler message={message} success={fail} />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "32px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "16px",
                  }}
                >
                  <div
                    onClick={() => {
                      navigate(-1);
                    }}
                  >
                    <img src={arrowleft} alt="" />
                  </div>
                  <div>
                    <div
                      style={{
                        color: "#101828",
                        fontWeight: "600",
                        fontSize: "24px",
                        lineHeight: "32.4px",
                      }}
                    >
                      Type of Goods
                    </div>
                    <div
                      style={{
                        color: "#667085",
                        fontSize: "16px",
                        lineHeight: "24px",
                        letterSpacing: "2%",
                        fontWeight: "400",
                        maxWidth: "600px",
                        width: "100%",
                      }}
                    >
                      Enter the type of goods you are importing
                    </div>
                  </div>
                </div>

                <div
                  className=""
                  style={{
                    display: "flex",
                    gap: "8px",
                    flexDirection: "column",
                  }}
                >
                  <input
                    type="text"
                    name=""
                    required
                    id=""
                    onChange={(e) => {
                      setgoods(e.target.value);
                    }}
                    value={goods}
                    style={{
                      width: "100%",
                      height: "56px",
                      borderRadius: "8px",
                      border: "none",
                      outline: "none",
                      background: "",
                      padding: "8px 16px 8px 16px",
                      backgroundColor: "#ECEFF3",
                      letterSpacing: "2%",
                      fontWeight: "400",
                      fontSize: "16px",
                    }}
                    placeholder="Deep Freezer"
                  />
                </div>
              </div>

              <div className="mb-5">
                <button
                  disabled={isdisable}
                  variant="primary"
                  style={{
                    maxWidth: "280px",
                    width: "100%",
                    minHeight: "46px",
                    background: isdisable ? "#EBE4FF" : "#6F00FF",
                    padding: "8px 12px 8px 12px",
                    border: "none",
                    outline: "none",
                    borderRadius: "8px",
                    fontSize: "16px",
                    lineHeight: "24px",
                    letterSpacing: "2%",
                    fontWeight: "600",
                    color: isdisable ? "#BFA6FF" : "#ffff",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {loader === true ? <Loader data={loader} /> : "Submit"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Goods;
