export function filterByCurrency(currency, data) {
  const account = data.find((account) => account.currency === currency);
  return account ? account.balance : undefined;
}

export function checkBalance(currency, amount, data) {
  // console.log(amount);

  if (amount <= 0) {
    return "Amount should not be greater than zero";
  } else if (amount > filterByCurrency(currency, data)) {
    return "Insufficient Balance";
  } else if (amount <= filterByCurrency(currency, data)) {
    return true;
  }
}
