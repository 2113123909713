import { Helmet } from "react-helmet";
// import DashHeader from "../component/dashheader";
// import homefinance from '../asset/im
import { useEffect, useState } from "react";

import SerHeader from "../component/serviceheader";
import arrowleft from "../asset/images/arrowleftcircle.svg";
import UploadInvoice from "../component/uploadinvoice";

//import UploadModal from "../component/uploadModal";
//import Uploaddocumentfinance from "../component/uploadloaddocument";
import InvoiceSuccess from "../component/invoicesuccess";
import uploadicon from "../asset/images/document-upload.png";
import axios from "axios";
//import Swal from "sweetalert2/dist/sweetalert2.js";

import Loaders from "../component/loader";
import { useParams } from "react-router-dom";
 import {useNavigate} from "react-router-dom";
//import { useSelector } from "react-redux";
//import FormData from 'form-data';
import Uploadagrremtfile from "../component/uploadcreateinvoicefile";
import UploadInvoiceCreateFile from "../component/uploadinvoicefilecreate";
import SuccessHodler from "../component/successholder";
import FailHandler from "../component/failhandler";


const UploadInoiceCreate = () => {
  // const [selectedIndex, setselectedIndex] = useState(-1)
   const navigate = useNavigate()

  const [show, setShow] = useState(false);

  const [selecteditem, setselecteditem] = useState([]);
  const [loader, setloader] = useState(false);
 // const selector = useSelector((state) => state.businessid);

  const [filename, setfilename] = useState(null);
  const [filesize, setfilesize] = useState(0);
  const [aggreement, setagrrement] = useState(null);
  const [aggreementsize, setaggreementsize] = useState(0);
  const [showModal, setshowModal] = useState(false);
  const [showModal2, setshowModal2] = useState(false);
  const [showModal3, setshowModal3] = useState(false);
  const [isdisable] = useState(false);
  const [total, setTotal] = useState(0);
  const [currency, setcurreny] = useState("");
 // const [file, setfile] = useState(null)
  //const [fileaggreement, setfileagrement] = useState(null)
  const [success,setsuccess] = useState(false)
  const [failhandler,setfailhandler] = useState(false)
  const [message,setmessage] = useState(" ")



  const { id } = useParams();

  const handleClose = () => setShow(false);
  useEffect(()=>{
   let timer =  setTimeout(() => {
    setfailhandler(false)
    setsuccess(false)
      
    }, 3000);
    return ()=>{
      clearTimeout(timer)
    }
  })


  useEffect(() => {
    const fetchInvoice = () => {
      axios
        .get(`/v1/invoice/${id}`)
        .then((res) => {
          setTotal(res.data.data.totalAmount);
          setcurreny(res.data.data.currency);
        })
        .catch((e) => {
          console.log(e);
        });
    };
    fetchInvoice();
  }, [id]);
  const handleSubmit = async (e) => {
    e.preventDefault();
    setloader(true);

    const formdata =  new FormData()
    formdata.append("invoice",  filename, filename?.name);
    formdata.append("agreement", aggreement, aggreement?.name);
    
   
    axios.put(`/v1/invoice/create/${id}`,formdata).then((res)=>{
      setshowModal3(true)
      setloader(false)

    }).catch((e)=>{
      console.log(e)
      
      setloader(false)
      setfailhandler(true)
      setmessage(e.response?.data.message ? e.response.data.message : 'An error occur')
     
    })
    
    /*
    fetch('https://api.tradevu.africa/v1/invoice/create/'+id, { 
      method: 'put', 
      body: formdata,
      headers: new Headers({
        "Authorization" : "Bearer "+ localStorage.getItem('token'),
        "x-business-id" : selector,
        'Content-Type' : 'multipart/form-data',
      })
    }).then((res)=>{
      console.log(res)
      setshowModal3(true);

    }).catch((e)=>{
      console.log(e)
     
    })
    */

   
  };

  return (
    <div className="h-100 w-100 "   style={{
      background: "#F6F8FA",

      paddingRight: "0px",
      maxWidth: "2000px",
      width: "100%",
      margin: "0px auto",
    }}>
      <div className="w-100">
        <Helmet>
          <title>Invoice</title>
        </Helmet>

        <SerHeader header={"Invoice Factoring"} />
        <InvoiceSuccess
        set={'finance'}
          show={showModal3}
          setshow={setshowModal3}
          currency={currency}
          amount={total}
         
        />
        <Uploadagrremtfile
          show={showModal2}
          setshow={setshowModal2}
          filename={aggreement}
          filesize={aggreementsize}
          setfilesize={setaggreementsize}
          setfilename={setagrrement}
        />
        <UploadInvoiceCreateFile
          filesize={filesize}
          show={showModal}
          setshow={setshowModal}
          filename={filename}
          setfilesize={setfilesize}
          setfilename={setfilename}
        />
       
      </div>
      <div style={{ height: "100%" }}>
        <div
          className="conatiner "
          style={{
            maxWidth: "2000px",
            margin: "0px auto",
            paddingLeft: "30px",
            paddingRight: "30px",
            paddingTop: "10px",
            paddingBottom: "10px",
          }}
        >
          <div
            style={{
              height: "100%",
              width: "100%",
              display: "flex",

              justifyContent: "center",
            }}
          >
            <UploadInvoice
              show={show}
              handle={handleClose}
              selecteditem={selecteditem}
              setselecteditem={setselecteditem}
              contacts={[]}
            />

            <form
              onSubmit={handleSubmit}
              className="form-general-layout"
              style={{
                position:'relative'
              }}
            >
              <SuccessHodler  success={success} message={message}/>
              <FailHandler success={failhandler} message={message} />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "32px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "16px",
                  }}
                >
                  <div>
                    <img src={arrowleft} alt=""  onClick={()=>{
                        navigate(-1)
                    }}/>
                  </div>
                  <div
                    style={{
                      color: "#101828",
                      fontWeight: "600",
                      fontSize: "24px",
                      lineHeight: "32.4px",
                    }}
                  >
                    Other documents
                  </div>
                </div>
                <div
                  className=""
                  style={{
                    display: "flex",
                    gap: "8px",
                    flexDirection: "column",
                  }}
                >
                  <label
                    htmlFor=""
                    style={{
                      color: "#344054",
                      fontWeight: "600",
                      lineHeight: "19.6px",
                      fontSize: "14px",
                    }}
                  >
                    Upload invoice
                  </label>
                  <div style={{ gap: "8px" }}>
                    <div
                      onClick={() => {
                        setshowModal(true);
                        setfilename(null)

                      }}
                      style={{
                        width: "100%",
                        borderRadius: "8px",
                        border: "none",
                        outline: "none",
                        background: "",
                        padding: "8px 16px 8px 16px",
                        backgroundColor: "#ECEFF3",
                        letterSpacing: "2%",
                        minHeight: "56px",
                        fontWeight: "500",
                        fontSize: "16px",
                        position: "relative",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          color: "#101828",
                          fontSize: "16px",
                          lineHeight: "24px",
                          letterSpacing: "2%",
                        }}
                      >
                        {" "}
                        {filename === null ? "Invoice.pdf " : filename.name}
                        {filesize !== 0 && (
                          <span>
                            {" "}
                            {parseFloat(filesize / 1024).toFixed(2)}{" "}
                          </span>
                        )}
                        {filesize !== 0 && (
                          <span> {filesize < 1024 ? "KB" : "MB"}</span>
                        )}{" "}
                      </div>

                      <div>
                        <img src={uploadicon} alt="" height={20} width={20} />
                      </div>
                    </div>
                  </div>
                </div>
               
                <div
                  className=""
                  style={{
                    display: "flex",
                    gap: "8px",
                    flexDirection: "column",
                  }}
                >
                  <label
                    htmlFor=""
                    style={{
                      color: "#344054",
                      fontWeight: "600",
                      lineHeight: "19.6px",
                      fontSize: "14px",
                    }}
                  >
                    Upload customer agreement
                  </label>
                  <div style={{ gap: "8px" }}>
                    <div
                      onClick={() => {
                        setshowModal2(true);
                        setagrrement(null)

                      }}
                      style={{
                        width: "100%",
                        borderRadius: "8px",
                        border: "none",
                        outline: "none",
                        background: "",
                        padding: "8px 16px 8px 16px",
                        backgroundColor: "#ECEFF3",
                        letterSpacing: "2%",
                        fontWeight: "500",
                        minHeight: "56px",
                        fontSize: "16px",
                        position: "relative",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          color: "#101828",
                          fontSize: "16px",
                          lineHeight: "24px",
                          letterSpacing: "2%",
                        }}
                      >
                        {" "}
                        {aggreement === null ? "Agreement.pdf " : aggreement.name}
                        {aggreementsize !== 0 && (
                          <span>
                            {" "}
                            {parseFloat(aggreementsize / 1024).toFixed(2)}{" "}
                          </span>
                        )}
                        {aggreementsize !== 0 && (
                          <span> {aggreementsize < 1024 ? "KB" : "MB"}</span>
                        )}{" "}
                      </div>

                      <div>
                        <img src={uploadicon} alt="" height={20} width={20} />
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <button
                    disabled={isdisable}
                    style={{
                      background: "#6F00FF",
                      color: "#fff",
                      maxWidth: "280px",
                      width: "100%",
                      border: "none",
                      borderRadius: "8px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      textAlign: "center",
                      padding: "8px 12px 8px 12px",
                      minHeight: "48px",
                    }}
                  >
                    {loader === true ? <Loaders data={loader} /> : "Submit"}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UploadInoiceCreate;
