import { Helmet } from "react-helmet";
// import DashHeader from "../component/dashheader";
// import homeTradeofCreditType from '../asset/im
import SerHeader from "../component/serviceheader";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import checkboxbase from "../asset/images/tradecredit/_Checkbox base.svg";
import shipping from "../asset/images/usertouser.svg";
import inventory from "../asset/images/usertobank.svg";
import ArrowBack from "../component/arrowback";
//import cashflow from "../asset/images/tradecredit/cashflow.svg";

const TransferType = () => {
  const navigate = useNavigate();
  const [selectedindex, setselectedindex] = useState(-1);
  //const { id } = useParams();
  const { state } = useLocation();
  const type = [
    {
      subject: "Tradevu Users",
      title: "Quick trade and zero fees.",
      image: shipping,
    },
    {
      subject: "Bank account",
      title: "Make local transfers to any bank.",
      image: inventory,
    },
  ];
  const goanotherpage = () => {
    if (selectedindex === 0) {
      navigate(`/transfer/tradevu`, {
        state: {
          ...state,
          type: "tradevuuser",
        },
      });
    } else if (selectedindex === 1 && state.currency === "NGN") {
      navigate(`/transfer/bank`, {
        state: {
          ...state,
          type: "bankuser",
        },
      });
    } else if (selectedindex === 1 && state.currency !== "NGN") {
      navigate(`/transfer/foreignbank`, {
        state: {
          ...state,
          type: "bankuser",
        },
      });
    }
  };
  return (
    <div className="h-100 w-100 ">
      <div
        className=""
        style={{
          maxWidth: "2000px",
          width: "100%",
          margin: "0px auto",
        }}
      >
        <Helmet>
          <title>Bank transfer</title>
        </Helmet>

        <SerHeader header={"Local transfer"} width={35} />
      </div>
      <div style={{ height: "100%" }}>
        <div
          className="conatiner "
          style={{
            maxWidth: "2000px",
            margin: "0px auto",
            paddingLeft: "30px",
            paddingRight: "30px",
            paddingTop: "10px",
            paddingBottom: "10px",
            width: "100%",
          }}
        >
          <div
            style={{
              height: "100%",
              width: "100%",
              display: "flex",

              justifyContent: "center",
            }}
          >
            <div className="form-general-layout">
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "16px",
                }}
              >
                <ArrowBack />
                <div className="">
                  <div
                    style={{
                      color: "#101828",
                      fontSize: "24px",
                      lineHeight: "32.4px",
                      fontWeight: "600",
                    }}
                  >
                    Send funds to
                  </div>
                  <p
                    style={{
                      color: "#667085",
                      lineHeight: "24px",
                      letterSpacing: "2%",
                      fontSize: "16px",
                      fontWeight: "500",
                    }}
                  >
                    Choose recipient
                  </p>
                </div>
              </div>
              <div className="">
                <div className="card border-0">
                  <div className="card-body p-1">
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        cursor: "pointer",
                      }}
                    >
                      {" "}
                      {type.map((item, index) => (
                        <div
                          key={index}
                          style={{
                            padding: "8px",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            position: "relative",
                            background:
                              selectedindex === index ? "#F4F0FF" : "",
                            borderRadius: "8px",
                          }}
                          onClick={() => {
                            setselectedindex(index);
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              gap: "16px",
                            }}
                          >
                            <img
                              src={item.image}
                              alt=""
                              width={48}
                              height={48}
                            />
                            <div
                              style={{
                                overflow: "hidden",
                                display: "flex",
                                flexDirection: "column",
                                gap: "4px",
                              }}
                            >
                              <div
                                style={{
                                  color: "#101828",
                                  fontSize: "16px",
                                  lineHeight: "24px",
                                  letterSpacing: "2%",
                                  fontWeight: "600",
                                }}
                              >
                                {" "}
                                {item.subject}{" "}
                              </div>
                              <p
                                style={{
                                  fontSize: "14px",
                                  fontWeight: "500",
                                  lineHeight: "19.6px",
                                  color: "#667085",
                                }}
                              >
                                {" "}
                                {item.title}{" "}
                              </p>
                            </div>
                          </div>
                          {selectedindex === index && (
                            <div>
                              <img src={checkboxbase} alt="" />
                            </div>
                          )}{" "}
                        </div>
                      ))}{" "}
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <button
                  onClick={goanotherpage}
                  style={{
                    maxWidth: "278px",
                    width: "100%",
                    background: "#6F00FF",
                    color: "#fff",
                    padding: "16px 24px 16px 24px",
                    letterSpacing: "2%",
                    fontSize: "16px",
                    fontWeight: "600",
                    lineHeight: "24px",
                    borderRadius: "8px",
                    outline: "none",
                    border: "none",
                    minHeight: "46px",
                  }}
                >
                  Continue
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TransferType;
