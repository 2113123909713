import Modal from "react-bootstrap/Modal";
import "../style/rightside.css";
import { currencies } from "../constants/currency";
// import addbvn from '../asset/images/addbvn.png'
import { useEffect, useState } from "react";
import nigeria from "../asset/images/countries/ngn.svg";
import axios from "axios";
import Loader from "./loader";
import Swal from "sweetalert2/dist/sweetalert2.js";
import FailHandler from "./failhandler";
import SuccessHodler from "./successholder";
import RecordedTransaction from "./RecordedTransaction";

const RecordPaymentform = (props) => {
    //console.log(props.record)
    // const [show, setshow] = useState(false)
    const [showdrop, setisshowdrop] = useState(false);
    const [selectedcurrency, setselectedcurrency] = useState([]);
    const [added, setadded] = useState(false);
    const [date, setdate] = useState(new Date().toDateString());
    const [amount, setamount] = useState("");
    const [selectedtransaction] = useState();

    const [isdisabled, setisdisable] = useState(true);
    const [isalldatafilledandclick] = useState(false);
    const [loader, setloader] = useState(false);
    const [message, setmessage] = useState("");
    const [fails, setfails] = useState(false);
    const [success, setsuccess] = useState(false);
    const [transaction, setTransaction] = useState("");
    const [showtranst, setshowtransact] = useState(false);
    const [eachTransaction, setEachTransaction] = useState([]);
    //console.log(props.record);
    useEffect(() => {
        if ((amount !== "" && date) || eachTransaction) {
            setisdisable(false);
        } else {
            setisdisable(true);
        }
    }, [amount, date, setisdisable]);
    useEffect(() => {
        if (fails) {
            let timer = setTimeout(() => {
                setfails(false);
            }, 3000);

            return () => {
                clearTimeout(timer);
            };
        }
        if (success) {
            let timer = setTimeout(() => {
                setsuccess(false);
                props.setshow(false);
            }, 3000);

            return () => {
                clearTimeout(timer);
            };
        }
    }, [fails, success]);
    const handlesubmit = async (e) => {
        e.preventDefault();
        setloader(true);
        if (props.selectedmethods === "Cash") {
            const data = {
                amount: amount,
                currency: selectedcurrency.length === 0 ? "NGN" : selectedcurrency.title,
                paymentMethod: props.selectedmethods,
                paymentDate: date,
            };
            await axios
                .post(`/v1/invoice/pay/${props.selectedinvoice._id}`, data)
                .then((res) => {
                    //console.log(res);
                    setloader(false);
                    setsuccess(true);
                    setmessage(res.data.message);
                    setfails(false);
                    props.setinvoices([...props.record, data]);
                })
                .catch((e) => {
                    setloader(false);
                    setsuccess(false);
                    setfails(true);

                    setmessage(e.response.data ? e.response.data.message : "An error occurred");
                });
        } else {
            const data = {
                amount: eachTransaction?.totalAmount
                    ? eachTransaction?.totalAmount?.toString()
                    : eachTransaction?.amount?.toString(),
                currency: eachTransaction?.currency,

                paymentMethod: props.selectedmethods,
                paymentDate: eachTransaction?.createdAt,
            };
            await axios
                .post(`/v1/invoice/pay/${props.selectedinvoice._id}`, data)
                .then((res) => {
                    //console.log(res);
                    setloader(false);
                    setsuccess(true);
                    setmessage(res.data.message);
                    setfails(false);
                    props.setinvoices([...props.record, data]);
                })
                .catch((e) => {
                    setloader(false);
                    setsuccess(false);
                    setfails(true);

                    setmessage(e.response.data ? e.response.data.message : "An error occurred");
                });
        }

        /*
        if (isalldatafilledandclick === true) {} else {
            setisalldatafilledandclick(true)
        }
        */
    };
    // console.log(show)
    return (
        <>
            <RecordedTransaction
                show={showtranst}
                setshow={setshowtransact}
                setEachTransaction={setEachTransaction}
                currency={props.currency}
            />
            <Modal
                show={props.show}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="addpaymentmodal"
                onHide={() => props.setshow(false)}
            >
                <Modal.Header>
                    <span
                        className="material-icons"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                            props.setshow(false);
                        }}
                    >
                        close
                    </span>
                </Modal.Header>
                <Modal.Body className="border-0 ">
                    <form onSubmit={handlesubmit}>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "32px",
                                position: "relative",
                            }}
                        >
                            <FailHandler message={message} success={fails} />
                            <SuccessHodler message={message} success={success} />
                            <h4
                                style={{
                                    color: "#101828",
                                    fontWeight: "600",
                                    fontSize: "18px",
                                    lineHeight: "24.3px",
                                }}
                            >
                                Record a new payment
                            </h4>
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: "16px",
                                }}
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        gap: "16px",
                                    }}
                                >
                                    <div
                                        style={{
                                            height: "56px",
                                            borderRadius: "8px",
                                            background: "#ECEFF3",
                                            padding: "8px 16px 8px 16px",
                                        }}
                                    >
                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                gap: "8px",
                                            }}
                                        >
                                            <label
                                                htmlFor="Method of payment"
                                                style={{
                                                    color: "#667085",
                                                    fontSize: "12px",
                                                    lineHeight: "16.2px",
                                                    fontWeight: "400",
                                                    letterSpacing: "2%",
                                                }}
                                            >
                                                Method of payment
                                            </label>
                                            <select
                                                name=""
                                                id=""
                                                style={{
                                                    outline: "none",
                                                    border: "none",
                                                    background: "#ECEFF3",
                                                    color: "#101828",
                                                    letterSpacing: "2%",
                                                    lineHeight: "24px",
                                                    fontSize: "16px",
                                                    fontWeight: "400",
                                                }}
                                            >
                                                <option value="" selected>
                                                    {" "}
                                                    {props.selectedmethods}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                    {isalldatafilledandclick === false &&
                                        props.selectedmethods === "Cash" && (
                                            <div
                                                style={{
                                                    display: "flex",
                                                    gap: "8px",
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        width: "100%",
                                                        maxWidth: "96px",
                                                        borderRadius: "8px",
                                                        border: "none",
                                                        outline: "none",
                                                        background: "",
                                                        padding: "8px 16px 8px 16px",
                                                        backgroundColor: "#ECEFF3",
                                                        letterSpacing: "2%",
                                                        fontWeight: "400",
                                                        fontSize: "16px",
                                                        display: "flex",
                                                        gap: "4px",
                                                        alignItems: "center",
                                                        position: "relative",
                                                    }}
                                                    onClick={() => {
                                                        setisshowdrop(!showdrop);
                                                    }}
                                                >
                                                    {" "}
                                                    {showdrop === true && (
                                                        <div
                                                            style={{
                                                                position: "absolute",
                                                                background: "#fff",
                                                                padding: "8px",
                                                                width: "100%",
                                                                top: "40px",
                                                                left: "-2px",
                                                                borderRadius: "4px",
                                                                zIndex: "2",
                                                            }}
                                                        >
                                                            <div
                                                                style={{
                                                                    display: "flex",
                                                                    flexDirection: "column",
                                                                    gap: "16px",
                                                                }}
                                                            >
                                                                {" "}
                                                                {currencies.map(
                                                                    (currency, index) => (
                                                                        <div
                                                                            style={{
                                                                                display: "flex",
                                                                                gap: "8px",
                                                                                lineHeight:
                                                                                    "19.6px",
                                                                                cursor: "pointer",
                                                                            }}
                                                                            key={index}
                                                                            onClick={() => {
                                                                                setselectedcurrency(
                                                                                    currency
                                                                                );
                                                                                setadded(true);
                                                                            }}
                                                                        >
                                                                            <img
                                                                                src={currency.image}
                                                                                alt=""
                                                                                height={24}
                                                                                width={24}
                                                                            />
                                                                            <span
                                                                                style={{
                                                                                    color: "#667085",
                                                                                    letterSpacing:
                                                                                        "2%",
                                                                                    lineHeight:
                                                                                        "24px",
                                                                                    fontSize:
                                                                                        "16px",
                                                                                }}
                                                                            >
                                                                                {" "}
                                                                                {currency.title}
                                                                            </span>
                                                                        </div>
                                                                    )
                                                                )}{" "}
                                                            </div>
                                                        </div>
                                                    )}
                                                    <img
                                                        src={
                                                            added ? selectedcurrency.image : nigeria
                                                        }
                                                        alt=""
                                                        height={24}
                                                        width={24}
                                                    />
                                                    <span
                                                        style={{
                                                            color: "#667085",
                                                            letterSpacing: "2%",
                                                            lineHeight: "24px",
                                                            fontSize: "16px",
                                                        }}
                                                    >
                                                        {" "}
                                                        {added === true
                                                            ? selectedcurrency.title
                                                            : "NGN"}
                                                    </span>
                                                </div>
                                                <div
                                                    style={{
                                                        height: "56px",
                                                        borderRadius: "8px",
                                                        background: "#ECEFF3",
                                                        padding: "8px 16px 8px 16px",
                                                        width: "100%",
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            flexDirection: "column",
                                                            gap: "8px",
                                                        }}
                                                    >
                                                        <label
                                                            htmlFor="Method of payment"
                                                            style={{
                                                                color: "#667085",
                                                                fontSize: "12px",
                                                                lineHeight: "16.2px",
                                                                fontWeight: "400",
                                                                letterSpacing: "2%",
                                                            }}
                                                        >
                                                            Amount
                                                        </label>
                                                        <input
                                                            name=""
                                                            id=""
                                                            onChange={(e) => {
                                                                setamount(e.target.value);
                                                            }}
                                                            style={{
                                                                outline: "none",
                                                                border: "none",
                                                                background: "#ECEFF3",
                                                                color: "#101828",
                                                                letterSpacing: "2%",
                                                                lineHeight: "24px",
                                                                fontSize: "16px",
                                                                fontWeight: "400",
                                                                marginTop: "-5px",
                                                            }}
                                                            placeholder="50000"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    {isalldatafilledandclick === false &&
                                        props.selectedmethods === "Cash" && (
                                            <div
                                                style={{
                                                    height: "56px",
                                                    borderRadius: "8px",
                                                    background: "#ECEFF3",
                                                    padding: "8px 16px 8px 16px",
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        gap: "8px",
                                                    }}
                                                >
                                                    <label
                                                        htmlFor="Method of payment"
                                                        style={{
                                                            color: "#667085",
                                                            fontSize: "12px",
                                                            lineHeight: "16.2px",
                                                            fontWeight: "400",
                                                            letterSpacing: "2%",
                                                        }}
                                                    >
                                                        Payment Date
                                                    </label>
                                                    <input
                                                        type="date"
                                                        name=""
                                                        id=""
                                                        value={date}
                                                        onChange={(e) => {
                                                            setdate(e.target.value);
                                                        }}
                                                        style={{
                                                            outline: "none",
                                                            border: "none",
                                                            background: "#ECEFF3",
                                                            color: "#101828",
                                                            letterSpacing: "2%",
                                                            lineHeight: "24px",
                                                            fontSize: "16px",
                                                            fontWeight: "400",
                                                            overflow: "hidden",
                                                            boxSizing: "border-box",
                                                            marginTop: "-5px",
                                                        }}
                                                        placeholder="May 5, 2021"
                                                    />
                                                </div>
                                            </div>
                                        )}{" "}
                                </div>
                                {isalldatafilledandclick === false &&
                                    props.selectedmethods !== "Cash" && (
                                        <div
                                            style={{
                                                height: "56px",
                                                borderRadius: "8px",
                                                background: "#ECEFF3",
                                                padding: "8px 16px 8px 16px",
                                                display: "flex",
                                                alignItems: "center",
                                            }}
                                        >
                                            <div
                                                style={{
                                                    display: "flex",
                                                    gap: "8px",
                                                    alignItems: "center",
                                                    width: "100%",
                                                }}
                                                onClick={() => setshowtransact(true)}
                                            >
                                                <input
                                                    type="text"
                                                    name=""
                                                    id=""
                                                    readOnly
                                                    value={eachTransaction?.accountName}
                                                    onChange={(e) => {
                                                        setTransaction(e.target.value);
                                                    }}
                                                    style={{
                                                        outline: "none",
                                                        border: "none",
                                                        background: "#ECEFF3",
                                                        color: "#101828",
                                                        letterSpacing: "2%",
                                                        lineHeight: "24px",
                                                        fontSize: "16px",
                                                        fontWeight: "400",
                                                        overflow: "hidden",
                                                        boxSizing: "border-box",
                                                        width: "100%",
                                                    }}
                                                    placeholder="Link with a transaction"
                                                />
                                            </div>
                                        </div>
                                    )}{" "}
                            </div>
                            <button
                                type="submit"
                                disabled={isdisabled}
                                style={{
                                    background: "#6F00FF",
                                    padding: "16px 24px 16px 24px",
                                    gap: "8px",
                                    height: "50px",
                                    outline: "none",
                                    border: "none",
                                    borderRadius: "8px",
                                    color: "#fff",
                                    textAlign: "center",
                                    fontSize: "1rem",
                                    lineHeight: "24px",
                                    letterSpacing: "2%",
                                    fontWeight: "500",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    display: "flex",
                                }}
                            >
                                {loader ? <Loader /> : " Record payment"}
                            </button>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default RecordPaymentform;
