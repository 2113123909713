const SuccessHodler = (props) => {
  return (
    props.success && (
      <div
        className="success"
        style={{
          marginTop: "10px",
          boxSizing: "border-box",
          position: "fixed",
          left: "50%",
          transform: "translateX(-50%)",
          width: "100%",
          width: "80%",
          maxWidth: "400px",
          top: "100px",
        }}
      >
        <div className="lg-success">
          <div className="ech-lg-hm">
            <div className="main-sucees">Success</div>
            <span>{props.message}</span>
          </div>
        </div>
      </div>
    )
  );
};

export default SuccessHodler;
