//import {useState} from 'react';
import Modal from "react-bootstrap/Modal";
import "../style/rightside.css";
import "../style/main.css";
import step from "../asset/images/step.svg";
import Loader from "./loader";
import { useEffect, useState } from "react";
import axios from "axios";
import SuccessHodler from "./successholder";
import FailHandler from "./failhandler";
import infocircle from "../asset/images/info-circle222.svg";
import { useNavigate } from "react-router-dom";
import ComponentLoader from "./componentloader";
import successapproved from "../asset/images/success.svg";
import currencyPairCheck from "../utilities/currencyPairCheck";
import AmountCheck from "./amountCheck";
import { useSelector } from "react-redux";
function FinanceRequestModalLoan(props) {
    const [loader, setloader] = useState(false);
    const [loaderaccept, setloaderaccept] = useState(false);
    const [success, setsuccess] = useState(false);
    const [message, setmessage] = useState("");
    const [fail, setfail] = useState(false);
    const [loans, setLoans] = useState([]);
    const [mainLoan, setmainLoan] = useState([]);
    const [mainloader, setmainloader] = useState(false);
    const navigates = useNavigate();
    const [agreement, setAgreement] = useState([]);
    const selectorbusinesss = useSelector((state) => state.business);

    const businessIdHeaderConfig = {
        headers: { "x-business-id": selectorbusinesss?._id },
    };

    useEffect(() => {
        if (fail) {
            let timer = setTimeout(() => {
                setfail(false);
            }, 3000);
            return () => {
                clearTimeout(timer);
            };
        } else if (success) {
            let timer = setTimeout(() => {
                setsuccess(false);
            }, 3000);
            return () => {
                clearTimeout(timer);
            };
        }
    }, [fail, success]);

    useEffect(() => {
        const getSingleLoan = async () => {
            setmainloader(true);
            await axios
                .get(
                    `/v1/finance/loan/finance/${props?.data?._id}?financeType=${
                        props.data.financeType === "tradeCredit"
                            ? "TradeCredit"
                            : props.data.financeType === "creditLine"
                            ? "CreditLine"
                            : "FinanceInvoice"
                    }`, businessIdHeaderConfig
                )
                .then((res) => {
                    //   console.log(res);
                    axios
                        .get(
                            `/v1/finance/agreement/fetch/${props?.data?._id}?financeType=${
                                props.data.financeType === "tradeCredit"
                                    ? "TradeCredit"
                                    : props.data.financeType === "creditLine"
                                    ? "CreditLine"
                                    : "FinanceInvoice"
                            }`, businessIdHeaderConfig
                        )
                        .then((resp) => {
                            setAgreement(resp.data.data);
                        })
                        .catch((e) => {
                            console.log(e);
                            setAgreement([]);
                        });
                    //console.log(res);
                    setmainLoan(res.data);
                    setLoans(res.data.loans);
                    setmainloader(false);
                })
                .catch((e) => {
                    console.log(e);
                });
        };
       selectorbusinesss?._id && getSingleLoan();
    }, [props?.data?._id, props.data.financeType, selectorbusinesss]);
    //console.log(agreement._id);
    function isFirstItemCompleted() {
        if (agreement.length !== 0) {
            if (agreement.signees[0].status === "completed") {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }
    // console.log(isFirstItemCompleted());
    function areBothSigneesCompleted() {
        if (agreement.length !== 0) {
            return agreement.signees.every((signee) => signee.status === "completed");
        }
    }
    const filterUnpaid = loans?.filter((item) => item.paymentStatus === "UNPAID");
    const filterPaid = loans?.filter((item) => item.paymentStatus === "REPAID");
    let firstloan = filterUnpaid?.filter((item, index) => {
        return index === 0;
    });

    {
        /** const AcceptAdminAproval = async () => {
    setloaderaccept(true);
    if (props?.data?.financeType === "creditLine") {
      await axios
        .post(`/v1/finance/accept_credit_line/${props?.data?._id}`)
        .then((res) => {
          // console.log(res);
          setloaderaccept(false);
          setmainloader(true);
          setmessage(res.data?.message);
          setsuccess(true);
          axios
            .get(
              `/v1/finance/loan/finance/${props?.data?._id}?financeType=CreditLine`
            )
            .then((resp) => {
              setmainLoan(res.data);
              setLoans(res.data.loans);
              setmainloader(false);
            })
            .catch((e) => {
              console.log(e);
            });
        })
        .catch((e) => {
          console.log(e);
          setloaderaccept(false);
          setmessage(
            e.response?.data
              ? e.response?.data?.message
              : "Sorry, Something went wrong"
          );
        });
    } else if (props?.data?.financeType === "tradeCredit") {
      await axios
        .post(`/v1/finance/accept_trade_credit/${props?.data?._id}`)
        .then((res) => {
          setloaderaccept(false);
          setmessage(res.data?.message);
          setsuccess(true);
          setfail(false);
          setmainloader(true);
          axios
            .get(
              `/v1/finance/loan/finance/${props?.data?._id}?financeType=TradeCredit`
            )
            .then((res) => {
              setmainLoan(res.data);
              setLoans(res.data.loans);
              setmainloader(false);
            })
            .catch((e) => {
              console.log(e);
            });
        })
        .catch((e) => {
          console.log(e);
          setloaderaccept(false);
          setmessage(
            e.response?.data
              ? e.response?.data?.message
              : "Sorry, Something went wrong"
          );
          setfail(true);
          setsuccess(false);
        });
    }
  };
**/
    }
    // console.log(mainLoan.length);
    const RejectAdminAproval = async () => {
        setloader(true);
        if (props?.data?.financeType === "creditLine") {
            await axios
                .post(`/v1/finance/reject_credit_line/${props?.data?._id}`, businessIdHeaderConfig)
                .then((res) => {
                    console.log(res);
                    setloader(false);

                    setmessage(res.data?.message);
                    setsuccess(true);
                    setfail(false);
                })
                .catch((e) => {
                    setfail(true);
                    setmessage(
                        e.response?.data ? e.response?.data?.message : "Sorry, Something went wrong"
                    );
                    setloader(false);
                });
        } else if (props?.data?.financeType === "tradeCredit") {
            await axios
                .post(`/v1/finance/reject_trade_credit/${props?.data?._id}`, businessIdHeaderConfig)
                .then((res) => {
                    //  console.log(res);
                    setloader(false);
                    setmessage(res.data?.message);
                    setsuccess(true);
                    setfail(false);
                })
                .catch((e) => {
                    console.log(e);
                    setloader(false);
                    setmessage(
                        e.response?.data ? e.response?.data?.message : "Sorry, Something went wrong"
                    );
                    setsuccess(false);
                    setfail(true);
                });
        }
    };
    return (
        <>
            <Modal
                show={props.show}
                backdrop="false"
                keyboard={false}
                onHide={() => props.setshow(false)}
                className="rightsidemodal"
            >
                <div
                    className=" container_line_stepper"
                    style={{
                        height: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        position: "relative",
                    }}
                >
                    <div
                        style={{
                            height: "calc(100% - 100px)",
                            width: "100%",
                            padding: "20px",
                        }}
                    >
                        <div className="w-100" style={{ height: "100%", marginLeft: "20px" }}>
                            <div
                                className="w-100 "
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: "16px",
                                    marginTop: "40px",
                                }}
                            >
                                <div className="d-flex gap-3 align-items-center">
                                    <span
                                        className="material-icons"
                                        style={{
                                            color: "#667085",
                                            cursor: "pointer",
                                            fontSize: "24px",
                                        }}
                                        onClick={() => {
                                            props.setshow(false);
                                        }}
                                    >
                                        arrow_back
                                    </span>
                                    <span
                                        style={{
                                            fontSize: "14px",
                                            color: "#667085",
                                            lineHeight: "18.9px",
                                            fontWeight: "500",
                                            textTransform: "uppercase",
                                        }}
                                    >
                                        back to finance request
                                    </span>
                                </div>
                                {props?.data?.isAdminApproved?.status === "PROCESSING" &&
                                    mainLoan.length === 0 && (
                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                gap: "16px",
                                                marginTop: "80px",
                                                marginBottom: "30px",
                                                width: "90%",
                                            }}
                                        >
                                            <img
                                                src={successapproved}
                                                alt=""
                                                style={{
                                                    height: "48px",
                                                    width: "48px",
                                                    margin: "0px auto",
                                                }}
                                            />
                                            <div
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    gap: "16px",
                                                }}
                                            >
                                                <span
                                                    style={{
                                                        fontWeight: "500",
                                                        fontSize: "20px",
                                                        lineHeight: "24px",
                                                        textAlign: "center",
                                                        color: "#101828",
                                                    }}
                                                >
                                                    You’re approved!
                                                </span>
                                                <span
                                                    style={{
                                                        textAlign: "center",
                                                        fontWeight: "400",
                                                        fontSize: "14px",
                                                        letterSpacing: "-1%",
                                                        lineHeight: "19.6%",
                                                        color: "#667085",
                                                    }}
                                                >
                                                    Almost there. Accept the terms of agreement to
                                                    get funded.
                                                </span>
                                            </div>
                                        </div>
                                    )}
                                <div
                                    className=""
                                    style={{
                                        fontSize: "32px",
                                        lineHeight: "40px",
                                        fontWeight: "500",
                                        color: "#101828",
                                        position: "relative",
                                    }}
                                >
                                    {props?.data?.financeType === "tradeCredit"
                                        ? "Working  Capital"
                                        : props?.data?.financeType === "creditLine"
                                        ? "Credit Line"
                                        : "Finance Invoice"}{" "}
                                    details
                                    <SuccessHodler success={success} message={message} />
                                    <FailHandler success={fail} message={message} />
                                </div>
                            </div>

                            <div className="w-100  d-flex">
                                <div
                                    className=""
                                    style={{
                                        marginTop: "30px",
                                        minWidth: "416px",
                                        display: "flex",
                                        flexDirection: "column",
                                        gap: "32px",
                                    }}
                                >
                                    {mainLoan?.loans && (
                                        <>
                                            <div className="repayment_loan_progress">
                                                <div className="progress_line_title">
                                                    <span>
                                                        Repayment(
                                                        {mainLoan?.length === undefined
                                                            ? filterPaid?.length
                                                            : 0}
                                                        /{" "}
                                                        {mainLoan?.length === undefined
                                                            ? loans?.length
                                                            : 0}
                                                        )
                                                    </span>
                                                    <span>
                                                        {parseFloat(
                                                            mainLoan?.length === undefined
                                                                ? (filterPaid?.length /
                                                                      loans?.length) *
                                                                      100
                                                                : 0
                                                        )}
                                                        % complete
                                                    </span>
                                                </div>
                                                <div className="progress_line_complete_progress">
                                                    <div
                                                        className="progess_color_line"
                                                        style={{
                                                            width: `${parseFloat(
                                                                mainLoan?.length === undefined
                                                                    ? (filterPaid?.length /
                                                                          loans?.length) *
                                                                          100
                                                                    : 0
                                                            )}%`,
                                                        }}
                                                    ></div>
                                                </div>
                                            </div>
                                        </>
                                    )}
                                    <div className="bankstatement-holder">
                                        <span className="repayment_progress">
                                            Repayment Details
                                        </span>

                                        <div className="list-item-details">
                                            <div
                                                className="each-list-item-details"
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    gap: "16px",
                                                    justifyContent: "flex-start",
                                                }}
                                            >
                                                <span
                                                    className="title-col-span"
                                                    style={{
                                                        minWidth: "160px",
                                                    }}
                                                >
                                                    Next payment
                                                </span>

                                                <div
                                                    style={{
                                                        display: "flex",
                                                        gap: "4px",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <span
                                                        className="amount-title-gl"
                                                        style={{
                                                            fontWeight: "500",
                                                            fontSize: "1rem",
                                                            lineHeight: "24px",
                                                            color: "#F04438",
                                                        }}
                                                    >
                                                        {currencyPairCheck(props?.data?.currency)}

                                                        {AmountCheck(
                                                            mainLoan.length === 0
                                                                ? props.data?.requestedAmount
                                                                : mainLoan?.paymentStatus !==
                                                                  "REPAID"
                                                                ? firstloan?.map(
                                                                      (item) => item.amount
                                                                  )
                                                                : props.data?.isAdminApproved &&
                                                                  mainLoan?.paymentStatus !==
                                                                      "REPAID"
                                                                ? props?.data.loanAmount
                                                                : 0
                                                        )}
                                                    </span>

                                                    {filterUnpaid?.length > 0 && (
                                                        <span className="date_next_payment">
                                                            on{" "}
                                                            {new Date(
                                                                loans?.length > 0
                                                                    ? firstloan?.map(
                                                                          (item) => item.dueDate
                                                                      )
                                                                    : mainLoan?.dueDate
                                                            ).toDateString()}
                                                        </span>
                                                    )}
                                                </div>
                                            </div>

                                            <div
                                                className="each-list-item-details"
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    gap: "16px",
                                                    justifyContent: "flex-start",
                                                }}
                                            >
                                                <div
                                                    className="title-col-span"
                                                    style={{
                                                        minWidth: "160px",
                                                        display: "flex",
                                                        alignItems: "center",
                                                        gap: "4px",
                                                    }}
                                                >
                                                    Total Amount
                                                    <img
                                                        src={infocircle}
                                                        alt=""
                                                        style={{
                                                            cursor: "pointer",
                                                        }}
                                                        onClick={() => {
                                                            props.setshownotice(true);
                                                        }}
                                                    />
                                                </div>
                                                <span className="amount-title-gl">
                                                    {currencyPairCheck(props?.data?.currency)}
                                                    {props?.data?.isAdminApproved ? (
                                                        <>
                                                            {AmountCheck(
                                                                parseFloat(props?.data.loanAmount) +
                                                                    parseFloat(
                                                                        props.data?.interestAmount
                                                                    )
                                                            )}
                                                        </>
                                                    ) : (
                                                        <>
                                                            {AmountCheck(
                                                                props?.data?.requestedAmount
                                                            )}
                                                        </>
                                                    )}
                                                </span>
                                            </div>
                                            {props?.data?.financeType !== "financeInvoice" && (
                                                <div
                                                    className="each-list-item-details"
                                                    style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        gap: "16px",
                                                        justifyContent: "flex-start",
                                                    }}
                                                >
                                                    <span
                                                        className="title-col-span"
                                                        style={{
                                                            minWidth: "160px",
                                                        }}
                                                    >
                                                        Tenure{" "}
                                                    </span>
                                                    <span className="amount-title-gl">
                                                        {props?.data.isAdminApproved
                                                            ? props?.data?.tenure
                                                            : props?.data?.initialTenure}{" "}
                                                        Days
                                                    </span>
                                                </div>
                                            )}
                                            {props?.data?.financeType === "financeInvoice" && (
                                                <div
                                                    className="each-list-item-details"
                                                    style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        gap: "16px",
                                                        justifyContent: "flex-start",
                                                    }}
                                                >
                                                    <span
                                                        className="title-col-span"
                                                        style={{
                                                            minWidth: "160px",
                                                        }}
                                                    >
                                                        End Customer{" "}
                                                    </span>
                                                    <span className="amount-title-gl">
                                                        {props?.data?.businessName}
                                                    </span>
                                                </div>
                                            )}

                                            {props?.data?.type === "purchaseOrder" && (
                                                <>
                                                    <div className="border-title-lg"></div>

                                                    <div
                                                        className="each-list-item-details"
                                                        style={{
                                                            display: "flex",
                                                            alignItems: "center",
                                                            gap: "16px",
                                                            justifyContent: "flex-start",
                                                        }}
                                                    >
                                                        <span
                                                            className="title-col-span"
                                                            style={{
                                                                minWidth: "160px",
                                                            }}
                                                        >
                                                            Repayment Structure
                                                        </span>
                                                        <span className="amount-title-gl">
                                                            {mainLoan?.length === undefined
                                                                ? mainLoan?.loanType
                                                                : props?.data?.repaymentStructure}
                                                        </span>
                                                    </div>
                                                    <div
                                                        className="each-list-item-details"
                                                        style={{
                                                            display: "flex",
                                                            alignItems: "center",
                                                            gap: "16px",
                                                            justifyContent: "flex-start",
                                                        }}
                                                    >
                                                        <span
                                                            className="title-col-span"
                                                            style={{
                                                                minWidth: "160px",
                                                            }}
                                                        >
                                                            Contract Type
                                                        </span>
                                                        <span className="amount-title-gl">
                                                            {props?.data?.contractType}
                                                        </span>
                                                    </div>
                                                    <div
                                                        className="each-list-item-details"
                                                        style={{
                                                            display: "flex",
                                                            alignItems: "center",
                                                            gap: "16px",
                                                            justifyContent: "flex-start",
                                                        }}
                                                    >
                                                        <span
                                                            className="title-col-span"
                                                            style={{
                                                                minWidth: "160px",
                                                            }}
                                                        >
                                                            End Customer
                                                        </span>
                                                        <span className="amount-title-gl">
                                                            {props?.data?.businessName}
                                                        </span>
                                                    </div>
                                                </>
                                            )}
                                        </div>

                                        {mainloader && <ComponentLoader />}

                                        {props?.data.financeType !== "financeInvoice" && (
                                            <>
                                                {mainloader === false && (
                                                    <>
                                                        <ul className="list-item-step">
                                                            <li className="step-processing-title ">
                                                                {props.data.status ===
                                                                    "PROCESSING" ||
                                                                props.data.status === "COMPLETED" ||
                                                                props.data.status === "APPROVED" ||
                                                                props.data.status === "REJECTED" ? (
                                                                    <span className="top-step-tiele">
                                                                        {new Date(
                                                                            props?.data?.isSubmitted?.date
                                                                        ).toDateString()}{" "}
                                                                        {new Date(
                                                                            props?.data?.isSubmitted?.date
                                                                        ).toLocaleTimeString()}
                                                                    </span>
                                                                ) : (
                                                                    ""
                                                                )}

                                                                <span
                                                                    className="main-request"
                                                                    style={{
                                                                        fontWeight: "600",
                                                                    }}
                                                                >
                                                                    Processing
                                                                </span>
                                                                {props.data.status ===
                                                                    "PROCESSING" ||
                                                                props.data.status === "COMPLETED" ||
                                                                props.data.status === "APPROVED" ||
                                                                props.data.status === "REJECTED" ? (
                                                                    <span className="top-step-tiele"></span>
                                                                ) : (
                                                                    <span className="top-step-tiele">
                                                                        Pending
                                                                    </span>
                                                                )}
                                                                <span className="cicle_stepper first-step">
                                                                    {props.data.status ===
                                                                        "PROCESSING" ||
                                                                    props.data.status ===
                                                                        "COMPLETED" ||
                                                                    props.data.status ===
                                                                        "APPROVED" ||
                                                                    props.data.status ===
                                                                        "REJECTED" ? (
                                                                        <img
                                                                            src={step}
                                                                            alt=""
                                                                            style={{
                                                                                boxSizing:
                                                                                    "border-box",
                                                                                width: "100%",
                                                                                height: "100%",
                                                                                overflow: "hidden",
                                                                            }}
                                                                        />
                                                                    ) : (
                                                                        ""
                                                                    )}
                                                                </span>
                                                            </li>
                                                            <li className="step-processing-title ">
                                                                <span className="top-step-tiele">
                                                                    {props.data.status ===
                                                                        "APPROVED" ||
                                                                    props.data.status ===
                                                                        "COMPLETED" ||
                                                                    props.data.status ===
                                                                        "REJECTED" ? (
                                                                        <>
                                                                            {new Date(
                                                                                props.data?.isAdminApproved.date
                                                                            ).toDateString()}{" "}
                                                                            {new Date(
                                                                                props.data?.isAdminApproved.date
                                                                            ).toLocaleTimeString()}
                                                                        </>
                                                                    ) : (
                                                                        ""
                                                                    )}
                                                                </span>
                                                                <span
                                                                    className="main-request"
                                                                    style={{
                                                                        fontWeight: "600",
                                                                    }}
                                                                >
                                                                    Approved
                                                                </span>

                                                                {areBothSigneesCompleted() ===
                                                                true ? (
                                                                    <span className="top-step-tiele">
                                                                        Agreement signed.
                                                                    </span>
                                                                ) : isFirstItemCompleted() ===
                                                                  true ? (
                                                                    <span className="top-step-tiele">
                                                                        Agreement signed. Waiting
                                                                        approval
                                                                    </span>
                                                                ) : agreement.length ===
                                                                  undefined ? (
                                                                    <span className="top-step-tiele">
                                                                        Agreement received
                                                                    </span>
                                                                ) : (
                                                                    <span className="top-step-tiele">
                                                                        Pending
                                                                    </span>
                                                                )}

                                                                <span className="cicle_stepper first-step">
                                                                    <img
                                                                        src={
                                                                            props.data.status ===
                                                                            "APPROVED"
                                                                                ? step
                                                                                : props.data
                                                                                      .status ===
                                                                                  "COMPLETED"
                                                                                ? step
                                                                                : props.data
                                                                                      .status ===
                                                                                  "REJECTED"
                                                                                ? step
                                                                                : ""
                                                                        }
                                                                        alt=""
                                                                        style={{
                                                                            boxSizing: "border-box",
                                                                            width:
                                                                                props.data
                                                                                    .status ===
                                                                                "APPROVED"
                                                                                    ? "100%"
                                                                                    : props.data
                                                                                          .status ===
                                                                                      "COMPLETED"
                                                                                    ? "100%"
                                                                                    : props.data
                                                                                          .status ===
                                                                                      "REJECTED"
                                                                                    ? "100%"
                                                                                    : "",
                                                                            height: "100%",
                                                                            overflow: "hidden",
                                                                        }}
                                                                    />
                                                                </span>
                                                            </li>
                                                            {/**
                              <li className="step-processing-title ">
                                <span className="top-step-tiele">
                                  {props.data?.isAdminApproved && (
                                    <>
                                      {new Date(
                                        props.data?.isAdminApproved.adminDate
                                      ).toDateString()}{" "}
                                      {new Date(
                                        props.data?.isAdminApproved.adminDate
                                      ).toLocaleTimeString()}
                                    </>
                                  )}
                                </span>
                                <span
                                  className="main-request"
                                  style={{
                                    fontWeight: "600",
                                  }}
                                >
                                  Due
                                </span>
                                <span className="top-step-tiele">Pending</span>
                                <span className="cicle_stepper first-step">
                                  {props.data.financeType ===
                                    "financeInvoice" &&
                                    props.data.status === "APPROVED" && (
                                      <img
                                        src={step}
                                        alt=""
                                        style={{
                                          boxSizing: "border-box",
                                          width:
                                            props.data?.status === "APPROVED" &&
                                            "100%",
                                          height: "100%",
                                          overflow: "hidden",
                                        }}
                                      />
                                    )}
                                  {props.data.financeType !==
                                    "financeInvoice" && (
                                    <img
                                      src={props.data?.isAdminApproved && step}
                                      alt=""
                                      style={{
                                        boxSizing: "border-box",
                                        width:
                                          props.data?.isAdminApproved && "100%",
                                        height: "100%",
                                        overflow: "hidden",
                                      }}
                                    />
                                  )}
                                </span>
                              </li>
                                    */}
                                                            {
                                                                <li className="step-processing-title ">
                                                                    <span className="top-step-tiele">
                                                                        {filterUnpaid?.length >
                                                                            0 && (
                                                                            <>
                                                                                {new Date(
                                                                                    firstloan?.map(
                                                                                        (item) =>
                                                                                            item.dueDate
                                                                                    )
                                                                                ).toDateString()}{" "}
                                                                                {new Date(
                                                                                    firstloan?.map(
                                                                                        (item) =>
                                                                                            item.dueDate
                                                                                    )
                                                                                ).toLocaleTimeString()}
                                                                            </>
                                                                        )}
                                                                    </span>
                                                                    <span
                                                                        className="main-request"
                                                                        style={{
                                                                            fontWeight: "600",
                                                                        }}
                                                                    >
                                                                        {/*** {mainLoan?.length === undefined
                                      ? filterPaid?.length
                                      : 0}{" "}
                                    of{" "}
                                    {mainLoan?.length === undefined
                                      ? loans?.length
                                      : 0}{" "}
                                      */}
                                                                        Due
                                                                    </span>

                                                                    <span className="top-step-tiele">
                                                                        {mainLoan.length === 0
                                                                            ? "Pending"
                                                                            : mainLoan.paymentStatus ===
                                                                              "REPAID"
                                                                            ? new Date(
                                                                                  mainLoan.dueDate
                                                                              ).toDateString()
                                                                            : mainLoan.paymentStatus !==
                                                                              "REPAID"
                                                                            ? new Date(
                                                                                  firstloan?.map(
                                                                                      (item) =>
                                                                                          item.dueDate
                                                                                  )
                                                                              ).toDateString()
                                                                            : "Pending"}
                                                                    </span>
                                                                    {/**
                                      {Math.abs(
                                        new Date(
                                          new Date(
                                            firstloan?.map(
                                              (item) => item.dueDate
                                            )
                                          ).toLocaleDateString()
                                        ) -
                                          new Date(
                                            new Date().toLocaleDateString()
                                          )
                                      ) /
                                        (1000 * 60 * 60 * 24)}{" "}
                                      Days
                                    </span>
                                  ) : (
                                    <span className="top-step-tiele">
                                      Pending
                                    </span>
                                  )}
                                  */}

                                                                    <span className="cicle_stepper first-step">
                                                                        <img
                                                                            src={
                                                                                filterUnpaid?.length ===
                                                                                0
                                                                                    ? step
                                                                                    : props.data
                                                                                          .status ===
                                                                                      "COMPLETED"
                                                                                    ? step
                                                                                    : props.data
                                                                                          .status ===
                                                                                          "REJECTED" &&
                                                                                      step
                                                                            }
                                                                            alt=""
                                                                            style={{
                                                                                boxSizing:
                                                                                    "border-box",

                                                                                overflow: "hidden",
                                                                            }}
                                                                        />
                                                                    </span>
                                                                </li>
                                                            }
                                                            <li className="step-processing-title">
                                                                {props?.data?.paymentDate && (
                                                                    <span className="top-step-tiele">
                                                                        {new Date(
                                                                            props?.data?.paymentDate
                                                                        ).toDateString()}{" "}
                                                                        {new Date(
                                                                            props?.data?.paymentDate
                                                                        ).toLocaleTimeString()}
                                                                    </span>
                                                                )}
                                                                <span
                                                                    className="main-request"
                                                                    style={{
                                                                        fontWeight: "600",
                                                                    }}
                                                                >
                                                                    Closed
                                                                </span>
                                                                <span className="cicle_stepper third-step">
                                                                    {props?.data?.status ===
                                                                        "COMPLETED" && (
                                                                        <img
                                                                            src={step}
                                                                            alt=""
                                                                            style={{
                                                                                boxSizing:
                                                                                    "border-box",
                                                                                width: "100%",
                                                                                height: "100%",
                                                                                overflow: "hidden",
                                                                            }}
                                                                        />
                                                                    )}
                                                                    {props?.data?.status ===
                                                                        "REJECTED" && (
                                                                        <img
                                                                            src={step}
                                                                            alt=""
                                                                            style={{
                                                                                boxSizing:
                                                                                    "border-box",
                                                                                width: "100%",
                                                                                height: "100%",
                                                                                overflow: "hidden",
                                                                            }}
                                                                        />
                                                                    )}
                                                                </span>
                                                                <span className="top-step-tiele">
                                                                    {mainLoan.paymentStatus ===
                                                                    "REPAID"
                                                                        ? new Date(
                                                                              mainLoan.updatedAt
                                                                          ).toDateString()
                                                                        : "Pending"}
                                                                </span>
                                                            </li>
                                                        </ul>
                                                    </>
                                                )}
                                            </>
                                        )}

                                        {props?.data.financeType === "financeInvoice" && (
                                            <ul className="list-item-step">
                                                <li className="step-processing-title ">
                                                    <span className="top-step-tiele">
                                                        {new Date(
                                                            props?.data?.createdAt
                                                        ).toDateString()}{" "}
                                                        {new Date(
                                                            props?.data?.createdAt
                                                        ).toLocaleTimeString()}
                                                    </span>
                                                    <span className="main-request">Processing</span>
                                                    {props.data.status === "PROCESSING" ? (
                                                        <span className="top-step-tiele">
                                                            Waiting for your request to be viewed
                                                        </span>
                                                    ) : (
                                                        <span className="top-step-tiele">
                                                            Pending
                                                        </span>
                                                    )}

                                                    <span className="cicle_stepper first-step">
                                                        <img
                                                            src={step}
                                                            alt=""
                                                            style={{
                                                                boxSizing: "border-box",
                                                                width: "100%",
                                                                height: "100%",
                                                                overflow: "hidden",
                                                            }}
                                                        />
                                                    </span>
                                                </li>
                                                <li className="step-processing-title">
                                                    <span
                                                        className="main-request"
                                                        style={{
                                                            color:
                                                                props?.data?.status === "PROCESSING"
                                                                    ? "#6f00f"
                                                                    : props?.data?.status ===
                                                                      "APPROVED"
                                                                    ? "#6f00f"
                                                                    : props?.data?.status ===
                                                                      "COMPLETED"
                                                                    ? "#6f00f"
                                                                    : "#667085",
                                                        }}
                                                    >
                                                        Processing
                                                    </span>
                                                    <span className="top-step-tiele">
                                                        Finance Invoice in Processing
                                                    </span>
                                                    <span className="cicle_stepper first-step">
                                                        {props?.data?.status === "PROCESSING" && (
                                                            <img
                                                                src={step}
                                                                alt=""
                                                                style={{
                                                                    boxSizing: "border-box",
                                                                    width: "100%",
                                                                    height: "100%",
                                                                    overflow: "hidden",
                                                                }}
                                                            />
                                                        )}
                                                        {props?.data?.status === "APPROVED" && (
                                                            <img
                                                                src={step}
                                                                alt=""
                                                                style={{
                                                                    boxSizing: "border-box",
                                                                    width: "100%",
                                                                    height: "100%",
                                                                    overflow: "hidden",
                                                                }}
                                                            />
                                                        )}
                                                    </span>
                                                </li>
                                                <li className="step-processing-title">
                                                    <span
                                                        className="main-request"
                                                        style={{
                                                            color:
                                                                props?.data?.status === "APPROVED"
                                                                    ? "#6f00f"
                                                                    : "#667085",
                                                        }}
                                                    >
                                                        Approved
                                                    </span>
                                                    <span className="cicle_stepper third-step">
                                                        {props?.data?.status === "APPROVED" && (
                                                            <img
                                                                src={step}
                                                                alt=""
                                                                style={{
                                                                    boxSizing: "border-box",
                                                                    width: "100%",
                                                                    height: "100%",
                                                                    overflow: "hidden",
                                                                }}
                                                            />
                                                        )}
                                                    </span>
                                                    <span className="top-step-tiele">
                                                        {props?.data?.status === "APPROVED" &&
                                                            "Finance Invoice Approved"}
                                                    </span>
                                                </li>
                                                <li className="step-processing-title">
                                                    <span
                                                        className="main-request"
                                                        style={{
                                                            color:
                                                                props?.data?.status === "COMPLETED"
                                                                    ? "#6f00f"
                                                                    : "#667085",
                                                        }}
                                                    >
                                                        Completed
                                                    </span>
                                                    <span className="cicle_stepper third-step">
                                                        {props?.data?.status === "COMPLETED" && (
                                                            <img
                                                                src={step}
                                                                alt=""
                                                                style={{
                                                                    boxSizing: "border-box",
                                                                    width: "100%",
                                                                    height: "100%",
                                                                    overflow: "hidden",
                                                                }}
                                                            />
                                                        )}
                                                    </span>
                                                    <span className="top-step-tiele">
                                                        {props?.data?.status === "COMPLETED" &&
                                                            "Payment Sent"}
                                                    </span>
                                                </li>
                                            </ul>
                                        )}
                                    </div>
                                    {filterUnpaid?.length > 0 && (
                                        <>
                                            <button
                                                type="button"
                                                className="btn-accept-list"
                                                style={{
                                                    marginBottom: "0px",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    background: "#6F00FF",
                                                    color: "#ffffff",
                                                    fontWeight: "700",
                                                    fontSize: "16px",
                                                    lineHeight: "24px",
                                                }}
                                                onClick={() =>
                                                    navigates("/loan/repayment", {
                                                        state: {
                                                            ...mainLoan,
                                                            ...filterUnpaid,
                                                        },
                                                    })
                                                }
                                            >
                                                {loaderaccept ? (
                                                    <Loader />
                                                ) : (
                                                    "Repay Next Installment"
                                                )}
                                            </button>
                                        </>
                                    )}
                                    {/**
                  {props?.data?.isAdminApproved &&
                    props?.data?.isAdminApproved.status !== "APPROVED" &&
                    props?.data?.isAdminApproved.status !== "REJECTED" &&
                    mainLoan?.length === 0 && (
                      <>
                        <button
                          className="btn-accept-list"
                          style={{
                            marginBottom: "0px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            background: "#6F00FF",
                            color: "#ffffff",
                            fontWeight: "700",
                            fontSize: "16px",
                            lineHeight: "24px",
                          }}
                          onClick={() => AcceptAdminAproval()}
                        >
                          {loaderaccept ? <Loader /> : "Accept"}
                        </button>
                        <button
                          className="btn-accept-list"
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                          onClick={() => RejectAdminAproval()}
                        >
                          {loader ? <Loader /> : "Declined"}
                        </button>
                      </>
                    )}
                    */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    );
}

export default FinanceRequestModalLoan;
