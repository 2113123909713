import { Helmet } from "react-helmet";
// import DashHeader from "../component/dashheader";
// import homeTradeofCreditType from '../asset/im
import SerHeader from "../component/serviceheader";
import { useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import checkboxbase from "../asset/images/tradecredit/_Checkbox base.svg";
import shipping from "../asset/images/tradecredit/shipping.svg";
import inventory from "../asset/images/tradecredit/inventory.svg";
import cashflow from "../asset/images/tradecredit/cashflow.svg";
import purchase from "../asset/images/purchaseorder.svg";

const TradeofCreditType = () => {
    const navigate = useNavigate();
    const [selectedindex, setselectedindex] = useState();
    const { id } = useParams();
    const { state } = useLocation();
    // console.log(state)
    const types = [
        {
            title: "Access credit with your purchase order.",
            subject: "Purchase Order Finance",
            image: purchase,
            link: `/trade_of_credit/purchase/${id}`,
        },
        {
            title: "Access credit with your shipping details.",
            subject: "Shipping Fees",
            image: shipping,
            link: `/trade_of_credit/shipping/${id}`,
        },
        {
            title: "Access credit with your inventory",
            subject: "Inventory Financing",
            image: inventory,
            link: `/trade_of_credit/inventory/${id}`,
        },
        {
            title: "Access credit with your cashflow.",
            subject: "Cashflow Support",
            image: cashflow,
            link: `/trade_of_credit/cashflow/${id}`,
        },
    ];

    return (
        <div className="h-100 w-100 ">
            <div
                className=""
                style={{
                    maxWidth: "2000px",
                    width: "100%",
                    margin: "0px auto",
                }}
            >
                <Helmet>
                    <title>Trade of Credit</title>
                </Helmet>

                <SerHeader header={"Trade Credit"} width={30} />
            </div>
            <div style={{ height: "100%" }}>
                <div
                    className="conatiner "
                    style={{
                        maxWidth: "2000px",
                        width: "100%",
                        margin: "0px auto",
                        paddingLeft: "30px",
                        paddingRight: "30px",
                        paddingTop: "10px",
                        paddingBottom: "10px",
                    }}
                >
                    <div
                        style={{
                            height: "100%",
                            width: "100%",
                            display: "flex",

                            justifyContent: "center",
                        }}
                    >
                        <div className="form-general-layout">
                            <div className="">
                                <div
                                    style={{
                                        color: "#101828",
                                        fontSize: "24px",
                                        lineHeight: "32.4px",
                                        fontWeight: "600",
                                    }}
                                >
                                    Choose request type
                                </div>
                                <p
                                    style={{
                                        color: "#667085",
                                        lineHeight: "24px",
                                        letterSpacing: "2%",
                                        fontSize: "16px",
                                        fontWeight: "500",
                                    }}
                                >
                                    Get access to working capital for your transaction. Choose an
                                    option below:
                                </p>
                            </div>
                            <div className="">
                                <div className="card border-0">
                                    <div className="card-body p-1">
                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                cursor: "pointer",
                                            }}
                                            className="!gap-3.5"
                                        >
                                            {types.map((item, index) => (
                                                <div
                                                    key={index}
                                                    style={{
                                                        padding: "8px",
                                                        display: "flex",
                                                        justifyContent: "space-between",
                                                        alignItems: "center",
                                                        position: "relative",
                                                        background:
                                                            selectedindex === index
                                                                ? "#EDFCFE"
                                                                : "",
                                                        borderRadius: "8px",
                                                    }}
                                                    className={`${
                                                        index &&
                                                        "pointer-events-none !cursor-not-allowed opacity-45"
                                                    }`}
                                                    onClick={() => {
                                                        setselectedindex(index);
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            gap: "16px",
                                                        }}
                                                    >
                                                        <img
                                                            src={item.image}
                                                            alt=""
                                                            width={48}
                                                            height={48}
                                                        />
                                                        <div
                                                            style={{
                                                                overflow: "hidden",
                                                                display: "flex",
                                                                flexDirection: "column",
                                                                gap: "4px",
                                                            }}
                                                        >
                                                            <div
                                                                style={{
                                                                    color: "#101828",
                                                                    fontSize: "16px",
                                                                    lineHeight: "24px",
                                                                    letterSpacing: "2%",
                                                                    fontWeight: "500",
                                                                }}
                                                            >
                                                                {" "}
                                                                {item.subject}{" "}
                                                                {!!index &&
                                                                    "Shipping fees (Coming soon 🚧)"}
                                                            </div>
                                                            <span
                                                                style={{
                                                                    fontSize: "14px",
                                                                    lineHeight: "19.6px",
                                                                    color: "#667085",
                                                                }}
                                                            >
                                                                {" "}
                                                                {item.title}{" "}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    {selectedindex === index && (
                                                        <div>
                                                            <img src={checkboxbase} alt="" />
                                                        </div>
                                                    )}
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <button
                                    onClick={() =>
                                        navigate(types[selectedindex].link, {
                                            state,
                                        })
                                    }
                                    style={{
                                        maxWidth: "278px",
                                        width: "100%",
                                        background: "#6F00FF",
                                        color: "#fff",
                                        padding: "8px 12px 8px 12px",
                                        letterSpacing: "2%",
                                        fontSize: "16px",
                                        fontWeight: "600",
                                        lineHeight: "24px",
                                        borderRadius: "8px",
                                        outline: "none",
                                        border: "none",
                                        minHeight: "46px",
                                    }}
                                    className={`${
                                        isNaN(selectedindex) && "pointer-events-none opacity-25"
                                    }`}
                                >
                                    Continue
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TradeofCreditType;
