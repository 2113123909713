import { Arrow2Icon, CloseIcon, DocumentTextIcon, UploadIcon } from "asset/icons";
import Input from "./Input";
import { useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import capitalizeWords from "utilities/capitalizeWords";
import { Bars } from "react-loader-spinner";

const UploadDocModal = ({ close, uploadFunc, loading }) => {
    const [name, setName] = useState();
    const [file, setFile] = useState();
    const fileTypes = ["JPEG", "JPG", "PNG", "PDF"];

    return (
        <div className="fixed left-0 top-0 h-screen w-screen bg-[#1D29394D] z-[5000] grid place-items-center">
            <div className="bg-page-bg rounded-2xl w-[95%] sm:w-[560px] !pt-1">
                <h3 className="flex justify-between text-main-dark font-semibold text-[23px] !px-6 !py-4">
                    Upload document{" "}
                    <CloseIcon
                        className={`size-6 cursor-pointer translate-x-1 ${
                            loading && "pointer-events-none *:stroke-main-grey/60"
                        }`}
                        onClick={close}
                    />
                </h3>

                <div className="px-6 -mt-1.5">
                    <Input
                        onChange={setName}
                        label="Name This Document"
                        placeholder="Describe your document"
                    />
                </div>

                <p
                    className={`font-medium text-sm text-left text-[#475467] !mb-2 !px-6 !mt-[18px]`}
                >
                    Attach Document
                </p>

                <div className="bg-[#FCFCFD] rounded-lg !border cursor-pointer overflow-hidden !border-dashed !border-[#C0CAD8] !p-4 flex !gap-4 items-center !mx-6 relative">
                    <UploadIcon className="size-10" />
                    <p className="text-main-dark font-[450]">
                        Drag or Upload Document
                        <span className="block font-[350] text-xs text-main-grey mt-[2px]">
                            {fileTypes.join(", ")}
                        </span>
                        <span className="block font-[350] text-xs text-main-grey">
                            Max. file size: 20.0MB
                        </span>
                    </p>
                    {/* Drag and drop doesn't work cause the below is layered inside the parent div. it needs to be on top */}
                    <FileUploader
                        classes={"!absolute !left-0 !top-0 !h-full !w-full !border-none !opacity-0"}
                        handleChange={setFile}
                        types={fileTypes}
                        maxSize={20}
                    />
                    {file && <Document file={file} deleteFile={() => setFile(null)} />}
                </div>

                <div className="flex justify-end items-center !gap-[21px] !border-t !border-[#E7E9FB] !py-[18px] !px-6 !mt-6">
                    <button
                        className="!px-1 text-[#475467] font-[450] disabled:text-main-grey/60"
                        onClick={close}
                        disabled={loading}
                    >
                        Cancel
                    </button>
                    <button
                        className="bg-[#6F00FF] rounded-lg h-10 w-[182.312px] text-[#FBFAFF] font-[450] disabled:bg-secondary/30"
                        disabled={!name || !file || loading}
                        onClick={() => uploadFunc(file, name)}
                    >
                        {loading ? (
                            <Bars
                                color="#fff"
                                height={23}
                                width={23}
                                wrapperClass="w-max mx-auto"
                            />
                        ) : (
                            " Upload document"
                        )}
                    </button>
                </div>
            </div>
        </div>
    );
};

const Document = ({ deleteFile, file }) => {
    const namesArr = file?.name?.split(".");
    return (
        <div className="bg-white rounded-lg !pl-3 !pr-6 !p-6 max-400:!py-4 flex items-center max-350:text-sm absolute left-0 top-0 w-full h-full">
            <DocumentTextIcon className="!mr-2 size-8" />
            <span className="font-[450] text-main-dark mr-auto">
                {capitalizeWords(namesArr[0]?.replace(/[^a-zA-Z0-9]/g, " ")?.toLowerCase() ?? "")}
            </span>
            <span className="text-main-grey">{namesArr[1].toUpperCase()}</span>
            <Arrow2Icon className="!ml-[18px]" />
            {deleteFile && (
                <CloseIcon className="absolute top-1 right-1 size-5" onClick={deleteFile} />
            )}
        </div>
    );
};

export default UploadDocModal;
