import Input from "component/form/Input";
import DropdownCheckItem from "./component/dropdownCheckItem";
import BusinessNature from "./component/businessNature";
import { useCallback, useEffect, useRef, useState } from "react";
import {
    AddIcon,
    CardRoundIcon,
    CheckCircleIcon,
    EditIcon,
    LocationOutlineIcon,
} from "asset/icons";
import BankSelect from "./component/bankSelect";
import capitalizeWords from "utilities/capitalizeWords";
import { countrycodelist } from "constants/countryList";
import { Bars } from "react-loader-spinner";
import { errorAlert } from "utilities/alerts";
import axios from "axios";
import useDebounce from "hooks/useDebounce";
import { EMAIL_REGEX } from "constants/global";
import Dropdown from "component/form/dropDown";

const AddManual = ({
    close,
    page,
    setPage,
    addNewNatureOfBiz,
    businessNature,
    setAddNewNatureOfBiz,
    setBusinessNature,
    manualFormData: data,
    setManualFormData,
    networkType,
    setNetworkType,
    paymentInfos,
    setPaymentInfos,
    fetchNGNBanks,
    NGNBanks,
    NGNBanksLoading,
    locations,
    setLocations,
    addToNetwork,
    manualUserId,
    setManualUser,
}) => {
    const [loading, setLoading] = useState(false);
    const updateData = (key, value) => setManualFormData((prev) => ({ ...prev, [key]: value }));
    const rolesArr = ["Clearing agent", "Supplier", "Buyer"];
    const { firstName, lastName, phoneNumber, businessName, email } = data;

    const enabled =
        page === 1
            ? networkType.length &&
              businessNature &&
              firstName &&
              lastName &&
              phoneNumber &&
              businessName &&
              EMAIL_REGEX.test(email)
            : page === 2
            ? true
            : true;

    const paymentInfosRequestData = paymentInfos
        .filter(
            ({ currency, bankName, accountName, accountNumber }) =>
                currency && bankName && accountName && accountNumber
        )
        .map(({ country, id, edit, ...rest }) => ({ ...rest }));

    const createUpdateUser = async (updateObj = {}, func) => {
        try {
            setLoading(true);

            if (manualUserId) {
                await axios.put(`/v1/flex/user/${manualUserId}`, {
                    ...data,
                    flexUserType: networkType,
                    ...updateObj,
                });
                func && func();
            } else {
                const res = await axios.post("/v1/flex/user", {
                    ...data,
                    flexUserType: networkType,
                    businessNature,
                });
                setManualUser(res.data.data);
                setPage(2);
            }
        } catch (error) {
            errorAlert(error);
        } finally {
            setLoading(false);
        }
    };

    const filteredLocations = locations.filter(({ place_id, location }) => place_id && location);

    return (
        <>
            <div className="!px-6 !-mt-4 max-h-[60vh] overflow-y-auto">
                {page === 1 ? (
                    <>
                        <div className="flex !gap-5">
                            <div className="w-full">
                                <Input
                                    onChange={(value) => updateData("firstName", value)}
                                    label="First name"
                                    placeholder="Enter last name"
                                    value={firstName}
                                />
                            </div>
                            <div className="w-full">
                                <Input
                                    onChange={(value) => updateData("lastName", value)}
                                    label="Last name"
                                    placeholder="Enter first name"
                                    value={lastName}
                                />
                            </div>
                        </div>
                        <Input
                            onChange={(value) => updateData("phoneNumber", value)}
                            value={phoneNumber}
                            label="Phone number"
                            placeholder="Enter phone number"
                            type="number"
                        />
                        <Input
                            onChange={(value) => updateData("businessName", value)}
                            label="Business name"
                            placeholder="Enter business name"
                            value={businessName}
                        />
                        <Input
                            onChange={(value) => updateData("email", value)}
                            label="Email address"
                            placeholder="Enter email address"
                            type="email"
                            value={email}
                        />

                        <Dropdown
                            placeholder="Select roles"
                            label="Roles"
                            options={rolesArr.map((item) => ({
                                label: (
                                    <DropdownCheckItem
                                        checked={networkType.includes(item)}
                                        text={item}
                                        onClick={() =>
                                            networkType.includes(item)
                                                ? setNetworkType((prv) => {
                                                      const prev = [...prv];
                                                      prev.splice(prev.indexOf(item), 1);
                                                      return prev;
                                                  })
                                                : setNetworkType((prev) => [...prev, item])
                                        }
                                    />
                                ),
                                value: item,
                                className: "!p-0",
                            }))}
                            value={
                                networkType.length > 1
                                    ? networkType.join(",").replace(/,/g, ", ")
                                    : networkType
                            }
                            labelClassName="text-left !text-[#475467]"
                            className="!p-0 pointer-events-auto"
                        />

                        <BusinessNature
                            addNewNatureOfBiz={addNewNatureOfBiz}
                            businessNature={businessNature}
                            setAddNewNatureOfBiz={setAddNewNatureOfBiz}
                            setBusinessNature={setBusinessNature}
                        />
                    </>
                ) : (
                    <>
                        {page === 2 ? (
                            <AddPaymentInfo
                                paymentInfos={paymentInfos}
                                setPaymentInfos={setPaymentInfos}
                                NGNBanks={NGNBanks}
                                NGNBanksLoading={NGNBanksLoading}
                                fetchNGNBanks={fetchNGNBanks}
                            />
                        ) : (
                            <>
                                {page === 3 && (
                                    <>
                                        <p
                                            className={`font-medium text-sm text-left text-[#475467] !mb-2 !mt-7`}
                                        >
                                            Add shipping information (optional)
                                        </p>

                                        {locations.map((loc, idx) => (
                                            <LocationInput
                                                key={loc.id}
                                                idx={idx}
                                                location={loc}
                                                setLocations={setLocations}
                                                id={loc.id}
                                            />
                                        ))}
                                        <AddAnotherBtn
                                            onClick={() =>
                                                setLocations((prev) => [
                                                    ...prev,
                                                    { id: Date.now() },
                                                ])
                                            }
                                        />
                                    </>
                                )}
                            </>
                        )}
                    </>
                )}
            </div>
            <div className="flex justify-end items-center !border-t !mt-6 !border-[#E7E9FB] !px-6 !py-4">
                <Stepper step={page} setStep={setPage} />
                <button
                    className="text-[#475467] font-[450] pointer-events-auto !py-2 !px-6 rounded-lg"
                    type="button"
                    onClick={close}
                >
                    Cancel
                </button>
                <button
                    className={`text-[#FBFAFF] !py-2 !px-6 rounded-lg ${
                        enabled ? "!bg-secondary pointer-events-auto" : "!bg-[#D9CDFF]"
                    } ${
                        loading &&
                        `!py-3 !pointer-events-none  ${page === 3 ? "!px-[69px] " : "!px-[46px]"}`
                    }`}
                    type="button"
                    disabled={!enabled}
                    onClick={() => {
                        if (page === 1) return createUpdateUser();
                        if (page === 2)
                            return paymentInfosRequestData?.length
                                ? createUpdateUser(
                                      {
                                          paymentInfo: paymentInfosRequestData,
                                      },
                                      () => setPage(3)
                                  )
                                : setPage(3);
                        if (page === 3) {
                            if (filteredLocations.length) {
                                createUpdateUser(
                                    {
                                        businessAddress: filteredLocations.map(
                                            ({ place_id, location }) =>
                                                `${place_id}:${location.lng}:${location.lat}`
                                        ),
                                    },
                                    addToNetwork
                                );
                            } else {
                                addToNetwork();
                            }
                        }
                    }}
                >
                    {loading ? (
                        <Bars height="20" width="20" color="#fff" ariaLabel="bars-loading" />
                    ) : (
                        <>{page === 3 ? "Add to Network" : "Continue"}</>
                    )}
                </button>
            </div>
        </>
    );
};

const Stepper = ({ step, setStep }) => (
    <div className="flex items-center !gap-2 mr-auto">
        {[1, 2, 3].map((item) => (
            <div
                className={`rounded-sm !h-2 !w-6 ${
                    step >= item ? "cursor-pointer " : "cursor-default"
                } ${step === item ? "!bg-secondary" : "!bg-[#EBE4FF]"}`}
                key={item}
                onClick={() => step >= item && setStep(item)}
            />
        ))}
    </div>
);

const LocationInput = ({ idx, location, setLocations, id }) => {
    const locationsRef = useRef();
    const [predictions, setPredictions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedLocationId, setSelectedLocationId] = useState(location?.place_id);
    const [searchValue, setSearchValue] = useState(location?.description);

    const searchLocation = useDebounce(async (searchString) => {
        if (searchString && !loading) {
            setLoading(true);
            const res = await axios.post("/v1/utility/get-address-autocomplete", {
                input: searchString,
            });
            setPredictions(res.data.data.predictions);
            setLoading(false);
        }
    }, 500);

    useEffect(() => {
        predictions.length && locationsRef?.current?.click();
    }, [predictions]);

    const setPlaceDetails = async (place_id, description) => {
        const placesRes = await axios.post("/v1/utility/get-place-detail", {
            placeId: place_id,
        });
        setLocations((prv) => {
            const prev = [...prv];
            const toReplaceIdx = prev.findIndex((locc) => locc.id === id);
            const location = placesRes.data.data.result.geometry.location;
            prev[toReplaceIdx] = {
                ...prev[toReplaceIdx],
                place_id,
                location,
                description,
            };
            return prev;
        });
    };

    return (
        <>
            <div
                className={`bg-[#EAECF0] rounded-lg flex !gap-2 items-center h-[56px] !pl-2 !pr-3 relative z-20 ${
                    idx && "!mt-4"
                }`}
            >
                <LocationOutlineIcon className="size-6" />
                <div className="text-left font-normal w-full">
                    <p className=" text-xs text-[#475467]">Address {idx + 1}</p>
                    <input
                        className="text-main-dark w-full !shadow-[inset_0_0_0_1000px_#EAECF0]"
                        onChange={(evt) => {
                            setSearchValue(evt.target.value);
                            searchLocation(evt.target.value);
                        }}
                        value={searchValue}
                    />
                </div>
            </div>

            <Dropdown
                ref={locationsRef}
                options={predictions.map(({ description, place_id }) => ({
                    label: description,
                    value: place_id,
                    className: "!text-left",
                }))}
                value={selectedLocationId}
                onChange={(value) => {
                    setSelectedLocationId(value);
                    const description = predictions.find(
                        ({ place_id }) => place_id === value
                    )?.description;
                    setSearchValue(description);
                    setPlaceDetails(value, description);
                }}
                className="!h-12 -translate-y-12 relative !z-10 -mb-12"
            />
        </>
    );
};

const AddAnotherBtn = ({ onClick }) => (
    <p
        className="flex items-center !text-secondary cursor-pointer w-max !gap-2 !mt-2.5 font-[450]"
        onClick={onClick}
    >
        <AddIcon />
        Add another
    </p>
);

const AddPaymentInfo = ({
    paymentInfos,
    setPaymentInfos,
    NGNBanks,
    fetchNGNBanks,
    NGNBanksLoading,
}) => {
    const [openBankSelectId, setOpenBankSelectId] = useState("");

    const addedPaymentsInfos = paymentInfos.filter(({ edit }) => !edit);
    const toAddPaymentsInfos = paymentInfos.filter(({ edit }) => edit);
    const currentInfoToEdit = paymentInfos.find(({ id }) => id === openBankSelectId);

    const updatePaymentInfo = (data, paymentInfoId = openBankSelectId) => {
        setPaymentInfos((prv) => {
            const prev = [...prv];
            const idxToEdit = prev.findIndex(({ id }) => id === paymentInfoId);
            prev[idxToEdit] = { ...prev[idxToEdit], ...data };
            return prev;
        });
    };

    const setBank = (bankName, bankCode) => updatePaymentInfo({ bankName, bankCode });
    const setCountry = (country) => {
        updatePaymentInfo({
            country,
            currency: country?.currency ?? "EUR",
        });
    };

    const initialCountry = countrycodelist.find(({ title }) => title === "Nigeria");

    return (
        <>
            {openBankSelectId && (
                <BankSelect
                    {...{
                        fetchNGNBanks,
                        NGNBanks,
                        NGNBanksLoading,
                        bankName: currentInfoToEdit?.bankName,
                        setBank,
                        country: currentInfoToEdit.country,
                        setCountry,
                        close: () => setOpenBankSelectId(null),
                    }}
                />
            )}

            {!!addedPaymentsInfos?.length && (
                <>
                    <p className={`font-medium text-sm text-left text-[#475467] !mb-2 !mt-7`}>
                        Added information
                    </p>
                    <div className="flex flex-col !gap-3 max-h-[200px] overflow-y-auto">
                        {addedPaymentsInfos.map(({ bankName, accountName, accountNumber, id }) => (
                            <div
                                className="flex items-center justify-between rounded-lg !px-3.5 !py-2.5 !border !border-[#E7E9FB] cursor-pointer"
                                key={id}
                                onClick={() => updatePaymentInfo({ edit: true }, id)}
                            >
                                <p className="font-[450] text-main-dark text-left">
                                    {accountName}
                                    <span className="flex items-center font-normal text-xs text-main-grey">
                                        {accountNumber}{" "}
                                        <span className="size-[3px] rounded-full bg-main-grey !mx-1" />{" "}
                                        {bankName}
                                    </span>
                                </p>
                                <EditIcon className="size-[18.5px]" />
                            </div>
                        ))}
                    </div>
                </>
            )}

            <>
                {!openBankSelectId && (
                    <>
                        <p
                            className={`font-medium text-sm text-left text-[#475467] !mb-2 ${
                                addedPaymentsInfos.length ? "!mt-6" : "!mt-7"
                            }`}
                        >
                            Add payment information {!addedPaymentsInfos.length && "(optional)"}
                        </p>

                        <div className="max-h-[270px] overflow-y-auto flex flex-col !gap-9">
                            {toAddPaymentsInfos.map(
                                ({
                                    bankName,
                                    accountName,
                                    accountNumber,
                                    id,
                                    currency,
                                    bankCode,
                                }) => (
                                    <PaymentInfoForm
                                        {...{
                                            accountName: !openBankSelectId ? accountName : null,
                                            accountNumber: !openBankSelectId ? accountNumber : null,
                                            bankName: !openBankSelectId ? bankName : null,
                                            id,
                                            setOpenBankSelectId,
                                            key: id,
                                            updatePaymentInfo,
                                            currency,
                                            bankCode,
                                        }}
                                    />
                                )
                            )}
                        </div>

                        <AddAnotherBtn
                            onClick={() =>
                                setPaymentInfos((prev) => [
                                    ...prev,
                                    {
                                        currency: initialCountry.currency,
                                        bankName: "",
                                        accountName: "",
                                        accountNumber: "",
                                        bankCode: "",
                                        id: Date.now(),
                                        edit: true,
                                        country: initialCountry,
                                    },
                                ])
                            }
                        />
                    </>
                )}
            </>
        </>
    );
};

const PaymentInfoForm = ({
    setOpenBankSelectId,
    bankName,
    accountName,
    accountNumber,
    id,
    updatePaymentInfo,
    currency,
    bankCode,
}) => {
    const [verifyLoading, setVerifyLoading] = useState(false);
    const isNGN = currency === "NGN";

    const verifyBank = useCallback(
        async (accountNum) => {
            if (verifyLoading) return;
            updatePaymentInfo({ accountNumber: accountNum }, id);
            if (accountNum.length === 10 && accountNum !== accountNumber) {
                try {
                    setVerifyLoading(true);
                    const res = await axios.post("/v1/wallets/verifyBankDetail", {
                        accountNumber: accountNum,
                        bankCode,
                    });
                    updatePaymentInfo({ accountName: res.data.data.accountName }, id);
                } catch (error) {
                    errorAlert(error);
                } finally {
                    setVerifyLoading(false);
                }
            }
        },
        [bankCode, id, accountNumber, verifyLoading, updatePaymentInfo]
    );

    return (
        <div>
            {bankName ? (
                <>
                    <p
                        className="flex items-center justify-between text-main-dark !border !border-[#E7E9FB] h-[64px] rounded-lg !px-3 font-[450] cursor-pointer"
                        onClick={() => setOpenBankSelectId(id)}
                    >
                        {capitalizeWords(bankName)}
                        <EditIcon className="size-[19px]" />
                    </p>

                    <div className={`relative`}>
                        <Input
                            onChange={(accountNumber) =>
                                isNGN
                                    ? verifyBank(accountNumber.slice(0, 10))
                                    : updatePaymentInfo({ accountNumber }, id)
                            }
                            placeholder="Account number"
                            className={`!-mt-1.5 !shadow-[inset_0_0_0_1000px_#EAECF0] ${
                                verifyLoading && "!pointer-events-none"
                            }`}
                            value={accountNumber}
                            type="number"
                        />

                        {verifyLoading && (
                            <div className="rounded-full absolute top-3 right-3">
                                <Bars
                                    height="20"
                                    width="20"
                                    color="#6f00ff"
                                    ariaLabel="bars-loading"
                                />
                            </div>
                        )}
                    </div>

                    {/* fix account name display only when in naira and only when verified - when account number is 11 */}
                    <div className={`relative`}>
                        <Input
                            onChange={(accountName) => updatePaymentInfo({ accountName }, id)}
                            placeholder="Account name"
                            className={`!-mt-1.5 !shadow-[inset_0_0_0_1000px_#EAECF0]  ${
                                isNGN && "!pointer-events-none"
                            }`}
                            value={accountName}
                        />

                        <CheckCircleIcon
                            className={`rounded-full size-7 absolute top-3 right-2 cursor-pointer ${
                                (!bankName || !accountName || !accountNumber) &&
                                "opacity-40 !pointer-events-none"
                            }`}
                            onClick={() => updatePaymentInfo({ edit: false }, id)}
                        />
                    </div>
                </>
            ) : (
                <p
                    className="!border !border-[#E7E9FB] flex items-center cursor-pointer !px-3.5 h-[64px] !gap-2 rounded-lg text-main-dark font-[450]"
                    onClick={() => setOpenBankSelectId(id)}
                >
                    <CardRoundIcon />
                    Enter payment information
                    <AddIcon className="ml-auto size-5 *:!fill-main-grey" />
                </p>
            )}
        </div>
    );
};

export default AddManual;
