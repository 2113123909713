import { Helmet } from "react-helmet";
// import DashHeader from "../component/dashheader";
// import homefinance from '../asset/im
import { useEffect, useState } from "react";

import SerHeader from "../component/serviceheader";
import arrowleft from "../asset/images/arrowleftcircle.svg";
import edit from "../asset/images/edit-2.svg";
import person from "../asset/images/person.svg";
import copy from "../asset/images/document-upload.png";
import UploadInvoice from "../component/uploadinvoice";
import nigeria from "../asset/images/countries/ngn.svg";
import UploadModal from "../component/tenure";
import Uploaddocumentfinance from "../component/uploadloaddocument";
import InvoiceSuccess from "../component/invoicesuccess";
import letterofcredit from "../asset/images/letterofcredit/letterofcredit2.svg";
import { useNavigate, useParams } from "react-router-dom";
import { currencies } from "../constants/currency";
import axios from "axios";
import Loader from "../component/loader";

const TransactionDetails = () => {
    // const [selectedIndex, setselectedIndex] = useState(-1)
    const navigate = useNavigate();

    const [show, setShow] = useState(false);
    const [selecteditem, setselecteditem] = useState([]);
    const [selectedcurrency, setselectedcurrency] = useState([]);
    const [added, setadded] = useState(false);
    const [filename, setfilename] = useState(null);
    const [filesize, setfilesize] = useState(0);
    const [aggreement, setagrrement] = useState(null);
    const [aggreementsize, setaggreementsize] = useState(0);
    const [showModal, setshowModal] = useState(false);
    const [showModal2, setshowModal2] = useState(false);
    const [showModal3, setshowModal3] = useState(false);
    const [tenureday, settenureday] = useState("0");
    const [defaultcurrency] = useState("NGN");
    const { id } = useParams();

    const [isdisable, setisdisable] = useState(true);
    const [amount, setamount] = useState("");
    const [loader, setloader] = useState(false);
    const [businessname, setbusinessname] = useState("");
    const [tenure, settunre] = useState(["7 ", "14 ", "30 ", "40 "]);
    console.log(businessname, defaultcurrency);
    const [showdrop, setisshowdrop] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const addseletedcurrency = (currency) => {
        setadded(true);

        setselectedcurrency(currency);
    };
    const submitHandler = async (e) => {
        e.preventDefault();
        setloader(true);

        await axios
            .post("/v1/finance/letter_of_credit/generateId")
            .then((res) => {
                ///console.log(res)
                const data = {
                    firstName: selecteditem[0].firstName,
                    lastName: selecteditem[0].lastName,
                    phoneNumber: selecteditem[0].phoneNumber,
                    email: selecteditem[0].email,

                    userType: id.toUpperCase(),
                    requestedAmount: amount,
                    currency: selectedcurrency.length === 0 ? "NGN" : selectedcurrency.title,
                    tenure: tenureday,
                };
                axios
                    .post(`/v1/finance/credit_line/create`, data)
                    .then((response) => {
                        navigate("/letter_of_credit/bank", {
                            state: {
                                ...data,
                                id: res.data.data._id,
                            },
                        });
                    })
                    .catch((e) => {
                        setloader(false);

                        console.log(e);
                    });
            })
            .catch((e) => {
                console.log(e);
                setloader(false);
            });
    };
    useEffect(() => {
        if (tenureday && aggreement && selecteditem && amount) {
            setisdisable(false);
        } else {
            setisdisable(true);
        }
    }, [setisdisable, tenureday, aggreement, selecteditem, amount]);
    return (
        <div className="h-100 w-100 ">
            <div
                className=""
                style={{
                    maxWidth: "2000px",
                    width: "100%",
                    margin: "0px auto",
                }}
            >
                <Helmet>
                    <title>Invoice</title>
                </Helmet>

                <SerHeader header={"Letter of Credit"} width={37} />
                <InvoiceSuccess show={showModal3} setshow={setshowModal3} />
                {/*** 
        <Uploaddocumentfinance
          show={showModal2}
          setshow={setshowModal2}
          title={"Letter of credit"}
          text={
            "This should clearly show the details of your transaction with your supplier"
          }
          aggreement={aggreement}
          aggreementsize={aggreementsize}
          setaggreementsize={setaggreementsize}
          setagrrement={setagrrement}
        />
        */}
                <Uploaddocumentfinance
                    title={"Letter of credit"}
                    text={
                        "This should clearly show the details of your transaction with your supplier"
                    }
                    show={showModal2}
                    setshow={setshowModal2}
                    filename={aggreement}
                    filesize={aggreementsize}
                    setfilesize={setaggreementsize}
                    setfilename={setagrrement}
                />
                <UploadModal
                    filesize={filesize}
                    show={showModal}
                    tenure={tenure}
                    tenureday={tenureday}
                    settenureday={settenureday}
                    settunre={settunre}
                    setshow={setshowModal}
                    filename={filename}
                    setfilesize={setfilesize}
                    setfilename={setfilename}
                />
            </div>
            <div style={{ height: "100%" }}>
                <div
                    className="conatiner "
                    style={{
                        maxWidth: "2000px",
                        width: "100%",
                        margin: "0px auto",
                        paddingLeft: "30px",
                        paddingRight: "30px",
                        paddingTop: "10px",
                        paddingBottom: "10px",
                    }}
                >
                    <div
                        style={{
                            height: "100%",
                            width: "100%",
                            display: "flex",

                            justifyContent: "center",
                        }}
                    >
                        <UploadInvoice
                            show={show}
                            handle={handleClose}
                            selecteditem={selecteditem}
                            setselecteditem={setselecteditem}
                            setbusinessname={setbusinessname}
                        />
                        <form onSubmit={submitHandler} className="form-general-layout">
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: "32px",
                                }}
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        gap: "32px",
                                    }}
                                >
                                    <div
                                        onClick={() => {
                                            navigate(-1);
                                        }}
                                    >
                                        <img src={arrowleft} alt="" />
                                    </div>
                                    <div
                                        style={{
                                            color: "#101828",
                                            fontWeight: "600",
                                            fontSize: "24px",
                                            lineHeight: "32.4px",
                                        }}
                                    >
                                        Transaction details
                                    </div>

                                    <div
                                        className=""
                                        style={{
                                            display: "flex",
                                            gap: "8px",
                                            flexDirection: "column",
                                        }}
                                    >
                                        <label
                                            htmlFor=""
                                            style={{
                                                color: "#344054",
                                                fontWeight: "600",
                                                lineHeight: "19.6px",
                                                fontSize: "14px",
                                            }}
                                        >
                                            Buyer
                                        </label>
                                        {selecteditem.length === 0 && (
                                            <div
                                                style={{
                                                    background: "#fff",
                                                    display: "flex",
                                                    justifyContent: "space-between",
                                                    padding: "8px",
                                                    borderRadius: "8px",
                                                    alignItems: "center",
                                                    height: "56px",
                                                }}
                                                variant="primary"
                                                onClick={handleShow}
                                            >
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        gap: "8px",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <img src={person} alt="" />
                                                    <span
                                                        style={{
                                                            color: "#101828",
                                                            letterSpacing: "2%",
                                                            lineHeight: "24px",
                                                            fontSize: "16px",
                                                            fontWeight: 500,
                                                        }}
                                                    >
                                                        Enter buyer’s details
                                                    </span>
                                                </div>
                                                <img src={edit} alt="" />
                                            </div>
                                        )}
                                        {selecteditem.length > 0 &&
                                            selecteditem.map((items, index) => (
                                                <div
                                                    style={{
                                                        background: "#fff",
                                                        display: "flex",
                                                        justifyContent: "space-between",
                                                        padding: "8px",
                                                        borderRadius: "8px",
                                                        alignItems: "center",
                                                        minHeight: "56px",
                                                    }}
                                                    key={index}
                                                    variant="primary"
                                                    onClick={handleShow}
                                                >
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            gap: "8px",
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                width: "40px",
                                                                height: "40px",
                                                                borderRadius: "250px",
                                                                alignItems: "center",
                                                                justifyContent: "ceneter",
                                                                background: "#F4F0FF",
                                                                textAlign: "center",
                                                                display: "flex",
                                                            }}
                                                        >
                                                            <div
                                                                style={{
                                                                    color: "#7F56D9",
                                                                    fontWeight: "600",
                                                                    fontSize: "14px",
                                                                    lineHeight: "20px",
                                                                    textAlign: "center",
                                                                    textTransform: "uppercase",
                                                                    width: "100%",
                                                                }}
                                                            >
                                                                {" "}
                                                                {items.firstName.charAt(0)}
                                                                {items.lastName.charAt(0)}{" "}
                                                            </div>
                                                        </div>
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                flexDirection: "column",
                                                            }}
                                                        >
                                                            <span
                                                                style={{
                                                                    color: "#101828",
                                                                    letterSpacing: "2%",
                                                                    lineHeight: "18px",
                                                                    fontSize: "16px",
                                                                    fontWeight: 500,
                                                                }}
                                                            >
                                                                {" "}
                                                                {items.firstName}
                                                                {items.lastName}{" "}
                                                            </span>
                                                            <span
                                                                style={{
                                                                    color: "#667085",
                                                                    fontSize: "14px",
                                                                    fontWeight: "500",
                                                                    lineHeight: "19.6px",
                                                                }}
                                                            >
                                                                {" "}
                                                                {items.email}{" "}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <img src={edit} alt="" />
                                                </div>
                                            ))}{" "}
                                    </div>
                                    <div
                                        className=""
                                        style={{
                                            display: "flex",
                                            gap: "8px",
                                            flexDirection: "column",
                                        }}
                                    >
                                        <label
                                            htmlFor=""
                                            style={{
                                                color: "#344054",
                                                fontWeight: "600",
                                                lineHeight: "19.6px",
                                                fontSize: "14px",
                                            }}
                                        >
                                            Requested amount
                                        </label>
                                        <div
                                            style={{
                                                display: "flex",
                                                gap: "8px",
                                            }}
                                        >
                                            <div
                                                style={{
                                                    width: "100%",
                                                    maxWidth: "96px",
                                                    borderRadius: "8px",
                                                    border: "none",
                                                    outline: "none",
                                                    background: "",
                                                    padding: "8px 16px 8px 16px",
                                                    backgroundColor: "#ECEFF3",
                                                    letterSpacing: "2%",
                                                    fontWeight: "500",
                                                    fontSize: "16px",
                                                    display: "flex",
                                                    gap: "4px",
                                                    alignItems: "center",
                                                    position: "relative",
                                                }}
                                                onClick={() => {
                                                    setisshowdrop(!showdrop);
                                                }}
                                            >
                                                {" "}
                                                {showdrop === true && (
                                                    <div
                                                        style={{
                                                            position: "absolute",
                                                            background: "#fff",
                                                            padding: "8px",
                                                            width: "100%",
                                                            top: "40px",
                                                            left: "-2px",
                                                            borderRadius: "4px",
                                                            zIndex: "2",
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                flexDirection: "column",
                                                                gap: "16px",
                                                            }}
                                                        >
                                                            {" "}
                                                            {currencies.map((currency, index) => (
                                                                <div
                                                                    style={{
                                                                        display: "flex",
                                                                        gap: "8px",
                                                                        lineHeight: "19.6px",
                                                                        cursor: "pointer",
                                                                    }}
                                                                    key={index}
                                                                    onClick={() => {
                                                                        addseletedcurrency(
                                                                            currency
                                                                        );
                                                                    }}
                                                                >
                                                                    <img
                                                                        src={currency.image}
                                                                        alt=""
                                                                        height={24}
                                                                        width={24}
                                                                    />
                                                                    <span
                                                                        style={{
                                                                            color: "#667085",
                                                                            letterSpacing: "2%",
                                                                            lineHeight: "24px",
                                                                            fontSize: "16px",
                                                                        }}
                                                                    >
                                                                        {" "}
                                                                        {currency.title}
                                                                    </span>
                                                                </div>
                                                            ))}{" "}
                                                        </div>
                                                    </div>
                                                )}
                                                <img
                                                    src={added ? selectedcurrency.image : nigeria}
                                                    alt=""
                                                    height={24}
                                                    width={24}
                                                />
                                                <span
                                                    style={{
                                                        color: "#667085",
                                                        letterSpacing: "2%",
                                                        lineHeight: "24px",
                                                        fontSize: "16px",
                                                    }}
                                                >
                                                    {" "}
                                                    {added === true
                                                        ? selectedcurrency.title
                                                        : "NGN"}
                                                </span>
                                            </div>
                                            <input
                                                type="text"
                                                name=""
                                                id=""
                                                onChange={(e) => {
                                                    setamount(e.target.value);
                                                }}
                                                style={{
                                                    width: "100%",
                                                    borderRadius: "8px",
                                                    border: "none",
                                                    outline: "none",
                                                    background: "",
                                                    padding: "8px 16px 8px 16px",
                                                    backgroundColor: "#ECEFF3",
                                                    letterSpacing: "2%",
                                                    fontWeight: "500",
                                                    fontSize: "16px",
                                                    height: "56px",
                                                }}
                                                placeholder="Enter amount"
                                            />
                                        </div>
                                    </div>
                                    <div
                                        className=""
                                        style={{
                                            display: "flex",
                                            gap: "8px",
                                            flexDirection: "column",
                                        }}
                                    >
                                        <label
                                            htmlFor=""
                                            style={{
                                                color: "#344054",
                                                fontWeight: "600",
                                                lineHeight: "19.6px",
                                                fontSize: "14px",
                                            }}
                                        >
                                            Tenure
                                        </label>
                                        <div style={{ position: "relative" }}>
                                            <div
                                                style={{
                                                    width: "100%",
                                                    borderRadius: "8px",
                                                    border: "none",
                                                    outline: "none",
                                                    background: "",
                                                    padding: "8px 16px 8px 16px",
                                                    backgroundColor: "#ECEFF3",
                                                    letterSpacing: "2%",
                                                    fontWeight: "500",
                                                    fontSize: "16px",
                                                    position: "relative",
                                                    display: "flex",
                                                    justifyContent: "space-between",
                                                    alignItems: "center",
                                                    height: "56px",
                                                }}
                                                variant="primary"
                                                onClick={() => {
                                                    setshowModal(true);
                                                }}
                                            >
                                                <span
                                                    style={{
                                                        color: "#667085",
                                                        fontSize: "16px",
                                                        lineHeight: "24px",
                                                        letterSpacing: "2%",
                                                    }}
                                                >
                                                    {" "}
                                                    {tenureday} days
                                                </span>
                                                <img src={copy} alt="" height={20} width={20} />
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className=""
                                        style={{
                                            display: "flex",
                                            gap: "8px",
                                            flexDirection: "column",
                                        }}
                                    >
                                        <label
                                            htmlFor=""
                                            style={{
                                                color: "#344054",
                                                fontWeight: "600",
                                                lineHeight: "19.6px",
                                                fontSize: "14px",
                                            }}
                                        >
                                            Upload Letter of Credit
                                        </label>
                                        <div style={{ position: "relative" }}>
                                            <div
                                                style={{
                                                    width: "100%",
                                                    borderRadius: "8px",
                                                    border: "none",
                                                    outline: "none",
                                                    background: "",
                                                    padding: "8px 16px 8px 16px",
                                                    backgroundColor: "#fff",
                                                    letterSpacing: "2%",
                                                    fontWeight: "500",
                                                    fontSize: "16px",
                                                    position: "relative",
                                                    display: "flex",
                                                    justifyContent: "space-between",
                                                    alignItems: "center",
                                                }}
                                                variant="primary"
                                                onClick={() => {
                                                    setaggreementsize(0);
                                                    setagrrement(null);
                                                    setshowModal2(true);
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        gap: "8px",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <img
                                                        src={letterofcredit}
                                                        alt=""
                                                        style={{
                                                            width: "38px",
                                                            height: "38px",
                                                        }}
                                                    />

                                                    <span
                                                        style={{
                                                            color: "#667085",
                                                            fontSize: "16px",
                                                            lineHeight: "24px",
                                                            letterSpacing: "2%",
                                                        }}
                                                    >
                                                        {" "}
                                                        {aggreement !== null && aggreement}-{" "}
                                                        {aggreement !== null &&
                                                            parseFloat(
                                                                aggreementsize / 1024
                                                            ).toFixed(2)}
                                                        {aggreementsize !== 0 && (
                                                            <span>
                                                                {" "}
                                                                {aggreementsize < 1024
                                                                    ? "KB"
                                                                    : "MB"}
                                                            </span>
                                                        )}
                                                        {aggreement === null && "letter of credit"}{" "}
                                                    </span>
                                                </div>
                                                <img src={copy} alt="" height={20} width={20} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mb-5">
                                        <button
                                            disabled={loader}
                                            variant="primary"
                                            style={{
                                                maxWidth: "280px",
                                                width: "100%",
                                                background: isdisable ? "#EBE4FF" : "#6F00FF",
                                                border: "none",
                                                outline: "none",
                                                borderRadius: "8px",
                                                fontSize: "16px",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                display: "flex",
                                                lineHeight: "24px",
                                                letterSpacing: "2%",
                                                fontWeight: "600",
                                                color: isdisable ? "#BFA6FF" : "#ffff",
                                                padding: "16px 24px 16px 24px",
                                            }}
                                        >
                                            {loader ? <Loader /> : "Submit"}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TransactionDetails;
