import Modal from "react-bootstrap/Modal";
import { useEffect, useState } from "react";
import checkbox from "../asset/images/Checkbox.svg";

import search from "../asset/images/search-normal.svg";
import invoice from "../asset/images/receipt-item.svg";
import dot from "../asset/images/point.svg";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import currencyPairCheck from "../utilities/currencyPairCheck";
import AmountCheck from "./amountCheck";
import TransactionName from "./transactionName";
import ImageController from "./imageController";
import { useSelector } from "react-redux";

function RecordedTransaction(props) {
    const [invoiceData, setinvoiceData] = useState([]);
    const navigate = useNavigate();
    const selectorbusinesss = useSelector((state) => state.business);

    const businessIdHeaderConfig = {
        headers: { "x-business-id": selectorbusinesss?._id },
    };

    useEffect(() => {
        const fetchInvoice = async () => {
            axios
                .get(`/v1/wallets/transactions`, businessIdHeaderConfig)
                .then((res) => {
                    setinvoiceData(res.data.data);
                })
                .catch((e) => {
                    console.log(e);
                });
        };
        selectorbusinesss?._id && fetchInvoice();
    }, [selectorbusinesss]);
    //  console.log(invoiceData);

    const handleCurrencyChange = () => {
        // const currency = e.target.value;
        //setSelectedCurrency(currency);
        return invoiceData.filter((user) => user.currency === props.currency);

        //setFilteredUsers(filteredList);
    };

    const closeModal = () => {
        var datashow = props.show;
        props.setshow(!datashow);
    };
    const handleSubmit = (tenure) => {
        var dataList = props.tenureday;

        props.settenureday(
            // eslint-disable-next-line
            (dataList = tenure)
        );

        props.setshow(false);
    };
    return (
        <Modal
            show={props.show}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="border-0 middle_center_lg_vcenter "
            onHide={() => props.setshow(false)}
        >
            <Modal.Body
                style={{
                    borderRadius: "16px",
                    padding: "0px",
                    border: "none",
                    height: "calc(100vh - 100px)",
                    overflow: "auto",
                    maxHeight: "703px",
                }}
            >
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "10px",
                    }}
                >
                    <div
                        className=""
                        style={{
                            borderBottom: "1px solid #E7E9FB",
                            padding: "8px 24px 8px 24px",
                        }}
                    >
                        <span
                            className="material-icons"
                            style={{ color: "#667085" }}
                            onClick={closeModal}
                        >
                            close
                        </span>
                    </div>
                    <div style={{ padding: "8px 24px 8px 24px" }}>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "4px",
                            }}
                        >
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                }}
                            >
                                <h3
                                    style={{
                                        fontWeight: "700",
                                        color: "#101828",
                                        lineHeight: "24.3px",
                                        fontSize: "18px",
                                    }}
                                >
                                    Choose recent transactions
                                </h3>
                                {/**
                <Link
                  to={"/flex/invoice"}
                  type="button"
                  style={{
                    padding: "8px 16px 8px 16px",
                    background: "#6F00FF",
                    color: "#ffffff",
                    display: "flex",
                    gap: "4px",
                    alignItems: "center",
                    textDecoration: "none",
                    borderRadius: "6px",
                    fontSize: "12px",
                  }}
                >
                  <span
                    className="material-icons"
                    style={{
                      fontSize: "12px",
                      fontWeight: "600",
                      lineHeight: "16.2px",
                    }}
                  >
                    add
                  </span>{" "}
                  Create new
                </Link>
                */}
                            </div>
                            <div
                                className="card border-0"
                                style={{
                                    marginBottom: "20px",
                                    background: "inherit",
                                }}
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        gap: "16px",
                                    }}
                                >
                                    <div
                                        className="container_invoice_lg"
                                        style={{
                                            background: "none",
                                        }}
                                    >
                                        {handleCurrencyChange()?.map(
                                            (res, index) =>
                                                res?.accountName && (
                                                    <div
                                                        key={index}
                                                        className="each_invoice_container_lg"
                                                        onClick={() => {
                                                            props.setEachTransaction(res);
                                                            props.setshow(false);
                                                        }}
                                                    >
                                                        <img
                                                            src={ImageController(
                                                                res.TransactionType
                                                            )}
                                                            alt=""
                                                        />
                                                        <div
                                                            style={{
                                                                width: "100%",
                                                                display: "flex",
                                                                justifyContent: "space-between",
                                                                alignItems: "center",
                                                            }}
                                                        >
                                                            <div className="each_sec_item_sm">
                                                                <span className="invoice_title">
                                                                    {res?.accountName}
                                                                </span>
                                                                <span>
                                                                    <span className="mytime">
                                                                        {TransactionName(
                                                                            res?.TransactionType
                                                                        )}
                                                                    </span>{" "}
                                                                    <img src={dot} alt="" />{" "}
                                                                    <span
                                                                        className="mytime_status"
                                                                        style={{
                                                                            color:
                                                                                res.paymentStatus ===
                                                                                    "UNPAID" &&
                                                                                "rgb(247, 144, 9)",
                                                                        }}
                                                                    >
                                                                        {res.status}
                                                                    </span>
                                                                </span>
                                                            </div>
                                                            <div className="each_sec_item_sm">
                                                                <span
                                                                    className="invoice_title"
                                                                    style={{
                                                                        textAlign: "right",
                                                                    }}
                                                                >
                                                                    {currencyPairCheck(
                                                                        res.currency
                                                                    )}

                                                                    {AmountCheck(
                                                                        res.totalAmount
                                                                            ? res.totalAmount
                                                                            : res.amount
                                                                    )}
                                                                </span>
                                                                <span
                                                                    style={{
                                                                        textAlign: "right",
                                                                    }}
                                                                >
                                                                    <span className="mytime">
                                                                        {/**{res?.invoiceItems.length} Items(s) */}
                                                                    </span>{" "}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default RecordedTransaction;
