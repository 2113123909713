import { AddIcon } from "asset/icons";
import Dropdown from "component/form/dropDown";
import Input from "component/form/Input";

const BusinessNature = ({
    addNewNatureOfBiz,
    setBusinessNature,
    businessNature,
    setAddNewNatureOfBiz,
}) => {
    return (
        <>
            {addNewNatureOfBiz ? (
                <Input
                    onChange={setBusinessNature}
                    className="placeholder:text-[#98A2B3] !shadow-[inset_0_0_0_1000px_#DBDFE5]"
                    label="Nature of business"
                    placeholder="Add new"
                    value={businessNature}
                />
            ) : (
                <Dropdown
                    placeholder="Select nature of business"
                    label="Nature of business"
                    options={[
                        "Agro commodities",
                        "Equipment",
                        "Trucking & Haulage services",
                        "Shipping lines",
                        "Warehousing & Storage",
                        "Exporter & Importer",
                        "Raw materials",
                        "Manufacturing",
                        "IT Services",
                        <>
                            <AddIcon className="-translate-x-1" /> Add new
                        </>,
                    ].map((item) => {
                        if (typeof item === "string") {
                            return {
                                label: item,
                                value: item,
                                action: () => setBusinessNature(item),
                            };
                        } else {
                            return {
                                label: item,
                                value: "add",
                                className:
                                    "flex items-center !text-secondary gap-[1px] !font-[450]",
                                action: () => {
                                    setBusinessNature("");
                                    setAddNewNatureOfBiz(true);
                                },
                            };
                        }
                    })}
                    value={businessNature}
                    labelClassName="text-left !text-[#475467]"
                    className="!p-0 pointer-events-auto"
                />
            )}
        </>
    );
};

export default BusinessNature;
