import NGN from "asset/images/countries/ngn.svg";
import UK from "asset/images/countries/uk.svg";
import AL from "asset/images/countries/al.svg";
import Ald from "asset/images/countries/ald.svg";

import Arm from "asset/images/countries/arm.svg";
import Austr from "asset/images/countries/austr.svg";
import Azerb from "asset/images/countries/azerb.svg";
import Bela from "asset/images/countries/bela.svg";
import Belgi from "asset/images/countries/Belgi.svg";
import Bosnia from "asset/images/countries/Bosnia.svg";
import Bulgi from "asset/images/countries/Bulgaria.svg";
import Croatia from "asset/images/countries/Croatia.svg";
import Cyprus from "asset/images/countries/Cyprus.svg";
import CzechRepublic from "asset/images/countries/CzechRepublic.svg";
import Denmark from "asset/images/countries/Denmark.svg";
import Estonia from "asset/images/countries/Estonia.svg";
import Finland from "asset/images/countries/Finland.svg";
import France from "asset/images/countries/France.svg";
import Georgia from "asset/images/countries/Georgia.svg";
import Germany from "asset/images/countries/gm.svg";
import Greece from "asset/images/countries/Greece.svg";
import Hungary from "asset/images/countries/Hungary.svg";
import Iceland from "asset/images/countries/Iceland.svg";
import Ireland from "asset/images/countries/Ireland.svg";
import Italy from "asset/images/countries/Italy.svg";
import Kosovo from "asset/images/countries/Kosovo.svg";
import Latvia from "asset/images/countries/Latvia.svg";
import lesch from "asset/images/countries/Liechtenstein.svg";
import Lutaina from "asset/images/countries/Lithuaia.svg";
import Luxembourg from "asset/images/countries/Luxembourg.svg";
import Malta from "asset/images/countries/Malta.svg";
import Maldova from "asset/images/countries/Moldova.svg";
import Montegro from "asset/images/countries/Montenegro.svg";
import Monaco from "asset/images/countries/Monaco.svg";
import Netherland from "asset/images/countries/Netherlands.svg";
import Norway from "asset/images/countries/Norway.svg";
import Ploand from "asset/images/countries/Poland.svg";
import Spain from "asset/images/countries/Spain.svg";
import Sweden from "asset/images/countries/Sweden.svg";
import Switzerland from "asset/images/countries/Switzerland.svg";
import Turkey from "asset/images/countries/Turkey.svg";
import Ukraine from "asset/images/countries/Ukraine.svg";
import VanticaCity from "asset/images/countries/VaticanCity.svg";
import US from "asset/images/US.svg";

export const countrycodelist = [
  {
    image: NGN,
    title: "Nigeria",
    num: "+234",
    currency:'NGN'
  },
  {
    image: UK,
    title: "United Kindom",
    num: "+44",
    currency:'GBP'
  },
  {
    image: US,
    title: "United State",
    num: "+1",
    currency: 'USD'
  },
  {
    image: AL,
    title: "Albania",
    num: "+355",
  },
  {
    image: Ald,
    title: "Andora",
    num: "+376",
  },
  {
    image: Arm,
    title: "Armenia",
    num: "+374",
  },
  {
    image: Austr,
    title: "Austria",
    num: "+43",
  },
  {
    image: Azerb,
    title: "Azerbaijan",
    num: "+994",
  },
  {
    image: Bela,
    title: "Belarus",
    num: "+375",
  },
  {
    image: Belgi,
    title: "Belgium",
    num: "+32",
  },
  {
    image: Bosnia,
    title: "Bosnia and Herzegovina",
    num: "+387",
  },
  {
    image: Bulgi,
    title: "Bulgaria",
    num: "+359",
  },
  {
    image: Croatia,
    title: "Croatia",
    num: "+385",
  },
  {
    image: Cyprus,
    title: "Cyprus",
    num: "+357",
  },
  {
    image: CzechRepublic,
    title: "Czech Republic",
    num: "+420",
  },
  {
    image: Denmark,
    title: "Denmark",
    num: "+45",
  },
  {
    image: Estonia,
    title: "Estonia",
    num: "+372",
  },
  {
    image: Finland,
    title: "Finland",
    num: "+358",
  },
  {
    image: France,
    title: "France",
    num: "+33",
  },
  {
    image: Georgia,
    title: "Georgia",
    num: "+995",
  },
  {
    image: Germany,
    title: "Germany",
    num: "+49",
  },
  {
    image: Greece,
    title: "Greece",
    num: "+30",
  },
  {
    image: Hungary,
    title: "Hungary",
    num: "+36",
  },
  {
    image: Iceland,
    title: "Iceland",
    num: "+354",
  },
  {
    image: Ireland,
    title: "Ireland",
    num: "+353",
    currency: 'GBP'
  },
  {
    image: Italy,
    title: "Italy",
    num: "+39",
  },
  {
    image: Kosovo,
    title: "kosovo",
    num: "+383",
  },
  {
    image: Latvia,
    title: "Latvia",
    num: "+371",
  },
  {
    image: lesch,
    title: "Liechtenstein",
    num: "+423",
  },
  {
    image: Lutaina,
    title: "Lithuania",
    num: "+370",
  },
  {
    image: Luxembourg,
    title: "Luxembourg",
    num: "+352",
  },
  {
    image: Malta,
    title: "Malta",
    num: "+356",
  },
  {
    image: Maldova,
    title: "Moldova",
    num: "+373",
  },
  {
    image: Monaco,
    title: "Monaco",
    num: "+377",
  },
  {
    image: Montegro,
    title: "Montenegro",
    num: "+382",
  },
  {
    image: Netherland,
    title: "Netherland",
    num: "+31",
  },
  {
    image: Norway,
    title: "Norway",
    num: "+47",
  },
  {
    image: Ploand,
    title: "Poland",
    num: "+48",
  },
  {
    image: Spain,
    title: "Spain",
    num: "+34",
  },
  {
    image: Sweden,
    title: "Sweden",
    num: "+46",
  },
  {
    image: Switzerland,
    title: "Switzerland",
    num: "+41",
  },
  {
    image: Turkey,
    title: "Turkey",
    num: "+90",
  },
  {
    image: Ukraine,
    title: "Ukraine",
    num: "+380",
  },
  {
    image: VanticaCity,
    title: "Vatican City",
    num: "+379",
  },
];
