import { Helmet } from "react-helmet";
import DashHeader from "../component/dashheader";
import homefinance from "../asset/images/homefinance.svg";
import invoices from "../asset/images/samecurrency.svg";
import letterofcredit from "../asset/images/crossborder.svg";
import trade from "../asset/images/bulk.svg";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import search from "../asset/images/search-normal.svg";
import ComponentLoader from "../component/componentloader";
import longinvoice from "../asset/images/flex/longinvoice.svg";
import axios from "axios";
import invoiceimg from "../asset/images/invoice.svg";
import calendar from "../asset/images/calendar-2.svg";
import FinanceRequestModalLoan from "../component/TransactionDetail2";
import dot from "../asset/images/simpledot.png";
import Notice from "../component/notice";
import incompleteprofile from "../asset/images/incomplete_profile.svg";
import reviewprofile from "../asset/images/ReviewProgress.svg";

import { useSelector } from "react-redux";
import FlexInvoice from "../component/flexinvoice";
import CashFlow from "component/cashflow";
import Navbar from "component/layout/navbar";

const PaymentHome = () => {
  const navigate = useNavigate();
  const [selectedIndex, setselectedIndex] = useState(-1);
  const [mydate, setmydate] = useState(new Date());
  const [invoice, setinvoices] = useState([]);
  const selectorbusiness = useSelector((state) => state.business);

  const [inputsearch, setinputsearch] = useState("");
  const [loaderset, setloaderset] = useState(true);
  const [tab, settab] = useState("all");

  const [maintab, setmaintab] = useState("Instruments");
  const [searchbank, setsearchbank] = useState("");
  const [show, sethsow] = useState(false);
  const [transaction, settransaction] = useState([]);
  const [shownotice, setshownotice] = useState(false);
  const [invoices2, setinvoices2] = useState([]);
  const [summary, setSummary] = useState([]);

  const businessIdHeaderConfig = {
    headers: { "x-business-id": selectorbusiness?._id },
};


  const getfinance = (index) => {
    setselectedIndex(index);
    if (index === 0) {
      navigate(`/payment/amount`, {
        state: {
          default: localStorage.getItem("default"),
        },
      });
    } else if (index === 1) {
      navigate(`/payment/crossborder`, {
        state: {
          default: localStorage.getItem("default"),
        },
      });
    }
  };

  useEffect(() => {
    const fetchaccount = async () => {
      await axios
        .get("/v1/invoice/all?type=flex", businessIdHeaderConfig)
        .then((res) => {
          setinvoices2(res.data.data);
          setloaderset(false);
        })
        .catch((e) => {
          setinvoices2([]);
          setloaderset(false);

          console.log(e);
        });
    };
 selectorbusiness?._id &&   fetchaccount();
  }, [selectorbusiness]);

  const weekday = ["Sun", "Mon", "Tue", "Wed", "Thur", "Fri", "Sat"];
  let day = weekday[mydate.getDay()];

  const month = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "december",
  ];
  /**
  useEffect(() => {
    const fetchaccount = async () => {
      await axios
        .get("/v1/finance/all")
        .then((res) => {
          //console.log(res);
          setinvoices(res.data.slice(0, 10));
          setloaderset(false);
        })
        .catch((e) => {
          console.log(e);
          setinvoices([])
          setloaderset(false);
        });
    };
    fetchaccount();
  }, []);
*/
  {
    /**useEffect(() => {
    const fetchSummary = async () => {
      await axios
        .get("/v1/finance/summery")
        .then((res) => {
          setSummary(res.data);
          //  console.log(res);
        })
        .then((e) => {
          console.log(e);
        });
    };

    fetchSummary();
  }, []);
**/
  }
  const filterInvoice = invoice.filter(
    (item) =>
      item.paymentStatus?.match(inputsearch || searchbank) ||
      item?.status?.match(inputsearch || searchbank)
  );
  const filterInvoiceProcess = invoice.filter((item) =>
    item.status?.match("PENDING")
  );
  const filterInvoiceComplte = invoice?.filter((item) =>
    item.status?.match("COMPLETED")
  );
  const filterUnpaid = invoice?.filter((item) =>
    item.paymentStatus?.match("UNPAID")
  );
  invoice.sort((a, b) =>
    a.createdAt.localeCompare(b.createdAt, "es", { sensitivity: "base" })
  );
  let datass = filterInvoice.reduce((r, e) => {
    let alphabet = new Date(e.createdAt).toDateString();
    if (!r[alphabet])
      r[alphabet] = {
        alphabet,
        record: [],
      };

    r[alphabet].record.push(e);
    return r;
  }, {});
  let result = Object.values(datass);
  // console.log(result);

  useEffect(() => {
    const timer = setInterval(() => {
      setmydate(new Date());
    }, 60000);
    return () => {
      clearInterval(timer);
    };
  });
  let months = month[mydate.getMonth()];
  let year = mydate.getFullYear();
  let today = mydate.getDate();
  let hrs = mydate.getHours();
  let min = mydate.getMinutes();

  const finance = [
    {
      title: "Same Currency Transfer",
      opt: "Local payments with the same currency.",
      image: invoices,
    },
    {
      title: "Make FX Payments",
      opt: "Pay anyone globally, between currencies.",
      image: letterofcredit,
    },
    {
      title: "Bulk Payment",
      opt: "Coming soon.",
      image: trade,
    },
  ];

  return (
    <div className="h-100 w-100 ">
      <div
        className=""
        style={{
          maxWidth: "2000px",
          margin: "0px auto",
        }}
      > 
        <Helmet>
          <title>Tradevu — pay</title>
        </Helmet>

        <Navbar/>
        
        <FinanceRequestModalLoan
          setshownotice={setshownotice}
          show={show}
          setshow={sethsow}
          data={transaction}
        />
        <Notice show={shownotice} setshow={setshownotice} data={transaction} />
      </div>
      <div
        style={{
          height: "100%",
          maxWidth: "2000px",
          margin: "0px auto",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          gap: "48px",
          marginTop: "40px",
        }}
      >
        <div
          className=""
          style={{
            margin: "0px auto",

            width: "100%",
            padding: "0px 80px",
          }}
        >
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-6">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "column",
                  padding: "0px 32px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "8px",
                  }}
                >
                  <h1
                    style={{
                      fontSize: "32px",
                      lineHeight: "40px",
                      color: "#344054",
                      fontWeight: "500",
                      padding: "0px",
                      margin: "0px",
                    }}
                  >
                    Pay
                  </h1>
                  <span
                    style={{
                      lineHeight: "24px",
                      fontSize: "1rem",
                      fontWeight: "400",
                      letterSpacing: "2%",
                      marginRight: "5px",
                      color: "#101828",
                    }}
                  >
                    Streamline your accounts payables and receivables operations
                    here.
                  </span>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 ">
              <div
                className="d-flex"
                style={{
                  flexDirection: "column",
                  justifyContent: "end",
                  alignItems: "end",
                  paddingRight: "15px",
                }}
              >
                <span
                  style={{
                    color: "#667085",
                    lineHeight: "24px",
                    fontWeight: "400",
                    letterSpacing: "2%",
                  }}
                >
                  {" "}
                  {hrs}:{min}{" "}
                </span>
                <span
                  style={{
                    color: "#667085",
                    lineHeight: "24px",
                    fontWeight: "400",
                    letterSpacing: "2%",
                  }}
                >
                  {" "}
                  {day},<span> {today}</span>
                  <span> {months} </span>
                  {year}{" "}
                </span>
              </div>
            </div>
          </div>
        </div>
        {selectorbusiness.status === "PENDING" ||
        selectorbusiness.length === 0 ? (
          <>
            <div className="incompletebusiness">
              <div className="closure_up_absolute">
                <div className="ineer_incomplete_business">
                  <div className="inner_business_content">
                    <img src={incompleteprofile} alt="" />
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <div
                        style={{
                          color: "#fff",
                          fontSize: "16px",
                          lineHeight: "24px",
                          fontWeight: "600",
                        }}
                      >
                        Complete your profile
                      </div>
                      <span
                        style={{
                          color: "#D9CDFF",
                          fontWeight: "400",
                          fontSize: "14px",
                          letterSpacing: "-1%",
                        }}
                      >
                        Complete your profile to access trade finance
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          ""
        )}
        {selectorbusiness.status === "SUBMITTED" && (
          <>
            <div className="incompletebusiness">
              <div className="closure_up_absolute">
                <div className="ineer_incomplete_business">
                  <div
                    className="inner_business_content"
                    style={{
                      background: "#FEDF89",
                    }}
                  >
                    <img src={reviewprofile} alt="" />
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <div
                        style={{
                          color: "#000",
                          fontSize: "16px",
                          lineHeight: "24px",
                          fontWeight: "600",
                        }}
                      >
                        Your Business is under review
                      </div>
                      <span
                        style={{
                          color: "#7A2E0E",
                          fontWeight: "400",
                          fontSize: "14px",
                          letterSpacing: "-1%",
                        }}
                      >
                        Please wait while we confirm your documents
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
        {selectorbusiness.status === "PROCESSING" && (
          <>
            <div className="incompletebusiness">
              <div className="closure_up_absolute">
                <div className="ineer_incomplete_business">
                  <div className="inner_business_content">
                    <img src={incompleteprofile} alt="" />
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <div
                        style={{
                          color: "#fff",
                          fontSize: "16px",
                          lineHeight: "24px",
                          fontWeight: "600",
                        }}
                      >
                        Your Business is under processing
                      </div>
                      <span
                        style={{
                          color: "#D9CDFF",
                          fontWeight: "400",
                          fontSize: "14px",
                          letterSpacing: "-1%",
                        }}
                      >
                        Your business is under processing.
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
        {selectorbusiness.status === "DEACTIVATE" && (
          <>
            <div
              className="incompletebusiness"
              style={{
                background: "crimson",
              }}
            >
              <div className="closure_up_absolute">
                <div className="ineer_incomplete_business">
                  <div className="inner_business_content">
                    <img src={incompleteprofile} alt="" />
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <div
                        style={{
                          color: "#fff",
                          fontSize: "16px",
                          lineHeight: "24px",
                          fontWeight: "600",
                        }}
                      >
                        Your Business is deactivated
                      </div>
                      <span
                        style={{
                          color: "#D9CDFF",
                          fontWeight: "400",
                          fontSize: "14px",
                          letterSpacing: "-1%",
                        }}
                      >
                        Your business is deactivated.
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
        <div
          className=" "
          style={{
            margin: "0px auto",
            paddingBottom: "10px",
            width: "100%",
            marginTop:
              selectorbusiness.length === 0
                ? "70px"
                : selectorbusiness.status === "PENDING"
                ? "70px"
                : selectorbusiness.status === "SUBMITTED"
                ? "70px"
                : selectorbusiness.status === "PROCESSING"
                ? "70px"
                : selectorbusiness.status === "DEACTIVATE"
                ? "70px"
                : "",
            padding: "0px 80px 0px 80px",
          }}
        >
          <ul className="list_usage">
            <li
              style={{
                borderBottom: maintab === "Instruments" && "3px solid #6F00FF",
                fontWeight: maintab === "Instruments" && "700",
              }}
              onClick={() => setmaintab("Instruments")}
            >
              Payments
            </li>
            <li
              onClick={() => setmaintab("History")}
              style={{
                borderBottom: maintab === "History" && "3px solid #6F00FF",
                color: maintab === "History" && "#101828",
                fontWeight: maintab === "History" && "700",
              }}
            >
              Invoices
            </li>
            <li
              onClick={() => setmaintab("Cashflow")}
              style={{
                borderBottom: maintab === "Cashflow" && "3px solid #6F00FF",
                color: maintab === "Cashflow" && "#101828",
                fontWeight: maintab === "Cashflow" && "700",
              }}
            >
              Cashflow
            </li>
          </ul>
          {maintab === "Instruments" && (
            <div
              className="card border-0"
              style={{
                minHeight: "704px",
                borderRadius: "16px",
              }}
            >
              <div
                className="card-body"
                style={{
                  padding: "30px",
                  display: "flex",
                  flexDirection: "column",
                  gap: "64px",
                }}
              >
                <div className="row mycolumn">
                  {" "}
                  {finance.map((finance, index) => (
                    <div
                      className="col-md-4 col-lg-4 "
                      key={index}
                      style={{
                        position: "relative",
                      }}
                    >
                      {index === 2 && (
                        <div
                          style={{
                            position: "absolute",
                            background: "#ccc",
                            width: "94%",
                            height: "100%",
                            zIndex: "1",
                            opacity: "0.2",
                            boxSizing: "border-box",
                            borderRadius: "8px",
                          }}
                        ></div>
                      )}
                      <div
                        className="card"
                        onClick={() => getfinance(index)}
                        style={{
                          cursor: "pointer",
                          padding: "8px",
                          borderRadius: "8px",
                          border:
                            selectedIndex === index
                              ? "1px solid #6F00FF"
                              : "1px solid #E7E9FB",
                        }}
                      >
                        <div
                          className="card-body "
                          style={{
                            padding: "10px",
                            background:
                              selectedIndex === index ? "#F4F0FF" : "#fff",
                            borderRadius: "8px",
                          }}
                        >
                          <div className=" d-flex " style={{ gap: "16px" }}>
                            <img src={finance.image} alt="" />
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "space-between",
                                gap: "8px",
                                overflow: "hidden",
                              }}
                            >
                              <h1
                                style={{
                                  fontWeight: "700",
                                  fontSize: "18px",
                                  lineHeight: "24.3px",
                                  padding: "0px",
                                  margin: "0px",
                                  color: "#101828",
                                }}
                              >
                                {" "}
                                {finance.title}{" "}
                              </h1>
                              <span
                                style={{
                                  fontSize: "14px",
                                  lineHeight: "19.6px",
                                  fontWeight: "400",
                                  color: "#667085",
                                  letterSpacing: "-1%",
                                }}
                              >
                                {" "}
                                {finance.opt}{" "}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}{" "}
                </div>
                <div>
                  {/**
                  {result.length === 0 && (
                    <div className="no_invoice">
                      <div className="no_mid_invoice">
                        <h4 className="text">No finance requests yet</h4>
                        <span className="material-icons">chevron_right</span>
                      </div>
                    </div>
                  )}
                  */}
                  {/**
                  {result.length > 0 && (
                    <div
                      className="no_invoice"
                      onClick={() => setmaintab("History")}
                      style={{ cursor: "pointer" }}
                    >
                      <div className="no_mid_invoice_lg">
                        <div className="md-invoice-lg">
                          <h4 className="text">{invoice?.length} Request(s)</h4>
                          <span>
                            {summary?.totalRequests} Received,{" "}
                            {summary?.processingRequests} Pending,{" "}
                            {summary?.dueLoans} Due Loan
                          </span>
                        </div>
                        <span
                          className="material-icons"
                          style={{
                            color: "#0FBBE6",
                            fontSize: "14px",
                          }}
                        >
                          chevron_right
                        </span>
                      </div>
                      <div
                        className="no_mid_invoice_lg"
                        style={{
                          background: "#EDFCFE",
                          borderRadius: "6px",
                          margin: "0px 5px",
                          display: "flex",
                          justifyContent: "start",
                          gap: "8px",
                        }}
                      >
                        <img src={calendar} alt="" />
                        <div className="md-invoice-lg">
                          <h4 className="text" style={{ color: "#0E84AC" }}>
                            {summary?.paidRequests} Unpaid
                          </h4>
                        </div>
                      </div>
                    </div>
                  )}{" "}
                  */}
                </div>
              </div>
            </div>
          )}
          {maintab === "History" && (
            <div
              className="card-body"
              style={{
                minHeight: "977px",
                display: "flex",
                flexDirection: "column",
                gap: "16px",
                borderRadius: "16px",
                overflowY: "auto",
                padding: "0px",
                background: "rgb(252, 252, 253)",
              }}
            >
              <FlexInvoice
                invoice={invoices2}
                setinvoices={setinvoices2}
                loaderset={loaderset}
              />
            </div>
          )}
          {maintab === "Cashflow" && (
            <div
              className="card-body"
              style={{
                minHeight: "977px",
                display: "flex",
                flexDirection: "column",
                gap: "16px",
                borderRadius: "16px",
                overflowY: "auto",
                padding: "0px",
                background: "rgb(252, 252, 253)",
              }}
            >
              <CashFlow />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PaymentHome;
