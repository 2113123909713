import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import mark from "../asset/images/Checkbox.svg";
import axios from "axios";
import Loader from "./loader";
import Swal from "sweetalert2/dist/sweetalert2.js";

const ReasonModal = (props) => {
  const data = ["Delayed", "Processing", "Completed"];
  const [selected, setselected] = useState(0);
  const [loader, setloader] = useState(false);
  const updateorderstatus = async (e) => {
    e.preventDefault();

    setloader(true);
    const datas = {
      orderStatus: data[selected].toLocaleUpperCase(),
    };
    await axios
      .patch(`/v1/flex/order/${props.orderdetails._id}`, datas)
      .then((res) => {
        setloader(false);
        //   console.log(res)
        Swal.fire({
          title: "Success",
          text: res.data.message,
          icon: "success",
          confirmButtonText: "OK",
          width: "280px",
          confirmButtonColor: "rgb(111, 0, 255)",
        });
      })
      .catch((e) => {
        setloader(false);

        Swal.fire({
          title: "Error",
          text: e.response.data.message,
          icon: "error",
          confirmButtonText: "OK",
          width: "280px",
          confirmButtonColor: "rgb(111, 0, 255)",
        }).then((res) => {
          if (res.value) {
          }
        });
      });
  };
  return (
    <Modal
      show={props.updateshow}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="myBilltype"
      onHide={() => props.setupdateshow(false)}
    >
      <span
        className="material-icons"
        style={{
          padding: "10px 20px ",
          cursor: "pointer",
        }}
        onClick={() => props.setupdateshow(false)}
      >
        close
      </span>
      <Modal.Body>
        <form onSubmit={(e) => props.updateorderstatus(e)}>
          <h6>Give reasons for delay</h6>
          <textarea
            name=""
            style={{
              border: "none",
              width: "100%",
              borderRadius: "8px",
              padding: "8px 16px",
              outline: "none",
              background: "#ECEFF3",
            }}
            id=""
            cols="20"
            rows="5"
            placeholder="State reason"
            onChange={(e) => props.setreason(e.target.value)}
          ></textarea>
          <button
            disabled={loader}
            className="btn-send-request"
            type="submit"
            style={{
              width: "100%",
              marginTop: "20px",
              maxWidth: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {props.loader ? <Loader /> : "Update"}
          </button>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default ReasonModal;
