import { Helmet } from "react-helmet";
// import DashHeader from "../component/dashheader";
// import homefinance from '../asset/im
import { useEffect, useState } from "react";

import SerHeader from "../component/serviceheader";

//import UploadInvoice from "../component/uploadinvoice";
import nigeria from "../asset/images/countries/ngn.svg";
import { currencies } from "../constants/currency";
import UploadModal from "../component/tenure";
import Uploaddocumentfinance from "../component/uploadloaddocument";
import InvoiceSuccess from "../component/invoicesuccess";
import Loader from "../component/loader";
import { useLocation, useNavigate } from "react-router-dom";
import "../style/slider.css";
//import axios from "axios";
//import Swal from "sweetalert2/dist/sweetalert2.js";
import info from "../asset/images/iconinfo.svg";
import edit from "../asset/images/marksuccess.svg";

const Highlight = () => {
    const navigate = useNavigate();

    const { state } = useLocation();
    console.log(state);
    const [filename, setfilename] = useState(null);
    const [filesize, setfilesize] = useState(0);
    const [aggreement, setagrrement] = useState(null);
    const [aggreementsize, setaggreementsize] = useState(0);
    const [showModal, setshowModal] = useState(false);
    const [showModal2, setshowModal2] = useState(false);
    const [showModal3, setshowModal3] = useState(false);
    const [tenureday, settenureday] = useState("0 Days");
    const [loader] = useState(false);
    const [isdisable, setisdisable] = useState(true);
    const [amount, setamount] = useState(state.amount);
    const [tenure, settunre] = useState(["7 Days", "14 Days", "30 Days", "40 Days"]);
    // console.log(state);
    const [showdrop, setisshowdrop] = useState(false);
    useEffect(() => {
        if (amount !== "") {
            setisdisable(false);
        } else {
            setisdisable(true);
        }
    }, [setisdisable, amount]);
    const submitHandler = async (e) => {
        e.preventDefault();
        if (state.type) {
            navigate("/transfer/summary", {
                state: {
                    ...state,
                },
            });
        } else {
            if (state.currency === "NGN") {
                navigate("/transfer/summary", {
                    state: {
                        bankName: state.name,
                        accountName: state.accountName,
                        accountNumber: state.accountNumber,
                        bankCode: state.bankCode,
                        currency: state.currency,
                        amount: amount,
                        fess: 0,
                        naration: state.narration,
                        sesid: state.sesid,
                    },
                });
            } else {
                navigate("/transfer/summary", {
                    state: {
                        ...state,
                    },
                });
            }
        }
    };

    return (
        <div className="h-100 w-100 ">
            <div
                className=""
                style={{
                    maxWidth: "2000px",
                    width: "100%",
                    margin: "0px auto",
                }}
            >
                <Helmet>
                    <title>Local transfer</title>
                </Helmet>

                <SerHeader header={"Local Transfers"} width={90} />
                <InvoiceSuccess show={showModal3} setshow={setshowModal3} />
                <Uploaddocumentfinance
                    show={showModal2}
                    setshow={setshowModal2}
                    title={"Letter of credit"}
                    text={
                        "This should clearly show the details of your transaction with your supplier"
                    }
                    filename={aggreement}
                    filesize={aggreementsize}
                    setfilesize={setaggreementsize}
                    setfilename={setagrrement}
                />
                <UploadModal
                    filesize={filesize}
                    show={showModal}
                    tenure={tenure}
                    tenureday={tenureday}
                    settenureday={settenureday}
                    settunre={settunre}
                    setshow={setshowModal}
                    filename={filename}
                    setfilesize={setfilesize}
                    setfilename={setfilename}
                />
            </div>
            <div style={{ height: "100%" }}>
                <div
                    className=" "
                    style={{
                        maxWidth: "2000px",
                        width: "100%",
                        margin: "0px auto",
                        paddingLeft: "30px",
                        paddingRight: "30px",
                        paddingTop: "10px",
                        paddingBottom: "10px",
                    }}
                >
                    <div
                        style={{
                            height: "100%",
                            width: "100%",
                            display: "flex",

                            justifyContent: "center",
                        }}
                    >
                        <form onSubmit={submitHandler} className="form-general-layout">
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: "32px",
                                }}
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        gap: "8px",
                                    }}
                                >
                                    <div
                                        style={{
                                            color: "#101828",
                                            fontWeight: "600",
                                            fontSize: "24px",
                                            lineHeight: "32.4px",
                                        }}
                                    >
                                        Send funds
                                    </div>
                                    <span
                                        style={{
                                            color: "#667085",
                                            fontWeight: "400",
                                            lineHeight: "24px",
                                            fontSize: "1rem",
                                            letterSpacing: "2%",
                                        }}
                                    >
                                        Enter amount below
                                    </span>
                                </div>
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        gap: "32px",
                                    }}
                                >
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            gap: "8px",
                                        }}
                                    >
                                        <label
                                            htmlFor=""
                                            style={{
                                                fontSize: "14px",
                                                lineHeight: "19.6px",
                                                fontWeight: "600",
                                                color: "#101828",
                                            }}
                                        >
                                            Amount
                                        </label>
                                        <div
                                            className=""
                                            style={{
                                                display: "flex",
                                                gap: "8px",
                                                flexDirection: "column",
                                            }}
                                        >
                                            <div
                                                style={{
                                                    display: "flex",
                                                    gap: "8px",
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        width: "100%",
                                                        maxWidth: "110px",
                                                        borderRadius: "8px",
                                                        border: "none",
                                                        outline: "none",
                                                        background: "",
                                                        padding: "8px 16px 8px 16px",
                                                        backgroundColor: "#ECEFF3",
                                                        letterSpacing: "2%",
                                                        fontWeight: "500",
                                                        fontSize: "16px",
                                                        display: "flex",
                                                        gap: "4px",
                                                        alignItems: "center",
                                                        position: "relative",
                                                    }}
                                                    onClick={() => {
                                                        setisshowdrop(!showdrop);
                                                    }}
                                                >
                                                    <img
                                                        src={
                                                            state.currency === "NGN"
                                                                ? nigeria
                                                                : state.currency === "EUR"
                                                                ? currencies[1].image
                                                                : currencies[2].image
                                                        }
                                                        alt=""
                                                        height={24}
                                                        width={24}
                                                    />
                                                    <span
                                                        style={{
                                                            color: "#667085",
                                                            letterSpacing: "2%",
                                                            lineHeight: "24px",
                                                            fontSize: "16px",
                                                            fontWeight: "500",
                                                        }}
                                                    >
                                                        {" "}
                                                        {state.currency}
                                                    </span>
                                                </div>
                                                <input
                                                    type="number"
                                                    name=""
                                                    id=""
                                                    step={0.5}
                                                    min={1}
                                                    value={amount}
                                                    onChange={(e) => {
                                                        setamount(e.target.value);
                                                    }}
                                                    style={{
                                                        width: "100%",
                                                        borderRadius: "8px",
                                                        border: "none",
                                                        height: "56px",
                                                        outline: "none",
                                                        background: "",
                                                        padding: "8px 16px 8px 16px",
                                                        backgroundColor: "#ECEFF3",
                                                        letterSpacing: "2%",
                                                        fontSize: "16px",
                                                        fontWeight: "500",
                                                    }}
                                                    placeholder="Enter amount"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className="white-bh-lg"
                                        style={{
                                            minHeight: "56px",
                                        }}
                                    >
                                        <div className="transfer-bank-logo">
                                            <div className="intial">
                                                {state.currency === "NGN"
                                                    ? state.accountName
                                                          .match(/(\b\S)?/g)
                                                          .join("")
                                                          .match(/(^\S|\S$)?/g)
                                                          .join("")
                                                          .toUpperCase()
                                                    : state.AccountName.match(/(\b\S)?/g)
                                                          .join("")
                                                          .match(/(^\S|\S$)?/g)
                                                          .join("")
                                                          .toUpperCase()}
                                            </div>
                                            <div className="name-bank">
                                                <span
                                                    className="name-link"
                                                    style={{
                                                        fontWeight: "600",
                                                    }}
                                                >
                                                    {state.currency === "NGN"
                                                        ? state.accountName
                                                        : state.AccountName}
                                                </span>
                                                <span
                                                    className="bank-link"
                                                    style={{
                                                        fontWeight: "400",
                                                    }}
                                                >
                                                    {state.currency === "NGN"
                                                        ? state.name
                                                        : state.BankName}{" "}
                                                    .{" "}
                                                    {state.currency === "NGN"
                                                        ? state.accountNumber
                                                        : state.AccountNumber}
                                                </span>
                                            </div>
                                        </div>
                                        <img src={edit} alt="" />
                                    </div>
                                    <div
                                        style={{
                                            padding: "16px",
                                            borderRadius: "8px",
                                            background: "#fff",
                                        }}
                                    >
                                        <div
                                            style={{
                                                display: "flex",
                                                gap: "8px",
                                                alignItems: "center",
                                            }}
                                        >
                                            <img src={info} alt="" />
                                            <span
                                                style={{
                                                    color: "#667085",
                                                    fontWeight: "500",
                                                    fontSize: "14px",
                                                    lineHeight: "19.6px",
                                                }}
                                            >
                                                These are regular and instant transfers within the
                                                same currency as your country of residence.
                                            </span>
                                        </div>
                                    </div>

                                    <div className="">
                                        <button
                                            disabled={isdisable}
                                            style={{
                                                maxWidth: "280px",
                                                width: "100%",
                                                background: isdisable ? "#EBE4FF" : "#6F00FF",
                                                padding: "16px 24px 16px 24px",
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                border: "none",
                                                outline: "none",
                                                minHeight: "46px",
                                                borderRadius: "8px",
                                                fontSize: "16px",
                                                lineHeight: "24px",
                                                letterSpacing: "2%",
                                                fontWeight: "600",
                                                color: isdisable ? "#BFA6FF" : "#ffff",
                                            }}
                                        >
                                            {loader === true ? (
                                                <Loader data={loader} />
                                            ) : (
                                                "Continue"
                                            )}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Highlight;
