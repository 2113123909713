import {
    CloseIcon,
    HomeIcon,
    HomeTrendIcon,
    MenuIcon,
    NotificationIcon,
    SwapIcon,
    TradeIcon,
} from "asset/icons";
import tradevuLogo from "asset/images/tradevulogo.svg";
import ComponentLoader from "component/componentloader";
import ListEmpty from "component/listEmpty";
import Toggler from "component/toggler";
import dayjs from "dayjs";
import useGetRequest from "hooks/useGetRequest";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import capitalizeWords from "utilities/capitalizeWords";
import relativeTime from "dayjs/plugin/relativeTime";
import sortListByDate from "utilities/sortListByDate";
import useScreenSize from "hooks/useScreenSize";
import ProfileDropdown from "./profileDropdown";
import { NavLink, useLocation } from "react-router-dom";
import FlexNav from "./flexNav";
dayjs.extend(relativeTime);

// Todo - business change should allows allow load even for unverified ones
const Navbar = ({ tourStep, takenTour }) => {
    const [readOnly, setReadOnly] = useState(false);
    const [mobileNav, setMobileNav] = useState(false);
    const [openNotifications, setOpenNotifications] = useState(false);
    const { users: user, business } = useSelector((state) => state);
    const [
        fetchNotifications,
        _notifications,
        notificationsLoading,
        _,
        __,
        setNotificationsLoading,
    ] = useGetRequest();
    const { screenWidth } = useScreenSize();
    const notificationsTimer = useRef(); // notifications timer - every 7 minutes

    const notifications = sortListByDate(_notifications ?? [])
        .reverse()
        .map(({ record }) => record)
        .flat();

    useEffect(() => {
        if (business?._id && openNotifications) {
            fetchNotifications("/v1/notification/all");
        } else {
            setNotificationsLoading(false);
        }
    }, [business?._id, openNotifications]);

    const accountName = capitalizeWords(
        `${business?.name ?? `${user.firstName ?? ""} ${user.lastName ?? ""}`}`
    );
    const accountNameArr = accountName?.split(" ");
    const largeScreen = screenWidth > 1023;
    const accountImg =
        accountName === capitalizeWords(business?.name ?? "")
            ? business?.businessImage?.url
            : user?.profileImage?.url;

    useEffect(() => {
        largeScreen && mobileNav && setMobileNav(false);
    }, [largeScreen, mobileNav]);

    const openLastTourForMobile = tourStep === 6 && screenWidth < 1024 && !takenTour;
    useEffect(() => {
        if (openLastTourForMobile) {
            setMobileNav(true);
        } else {
            setMobileNav(false);
        }
    }, [openLastTourForMobile]);

    return (
        <>
            <nav className={`h-20 !m-0 !px-6 sm:!px-10 md:!px-20 !py-0 flex items-center bg-white ${mobileNav && 'overflow-y-auto'} navbar-gradient`}>
                <img src={tradevuLogo} alt="Tradevu" className="h-8 mr-auto" />

                {largeScreen && <NavLinks />}

                <div
                    className="relative ml-auto cursor-pointer *:cursor-default rounded-full"
                    onClick={() => setOpenNotifications((prev) => !prev)}
                >
                    <NotificationIcon className="size-8 pointer-events-none" />
                    <div className="absolute left-5 top-1 rounded-full size-2 pointer-events-none bg-[rgb(17,168,17)]" />

                    {/* notifications */}
                    <div
                        className={`absolute z-[100] -right-20 520:-right-4 transition-all duration-300 ${
                            openNotifications
                                ? "top-[58px] opacity-100"
                                : "top-[50%] opacity-0 invisible"
                        } bg-white 520:rounded-lg w-screen 520:w-[450px] overflow-hidden h-[496px] shadow-dropdown`}
                        onClick={(evt) => evt.stopPropagation()}
                    >
                        <div className="flex items-center p-4 border-b border-[#E6E8F9]">
                            <h4 className="text-base text-main-dark font-normal mr-auto">
                                Notifications
                            </h4>

                            {false && (
                                <>
                                    <span className="text-sm text-main-grey mr-2.5">
                                        Unread Only
                                    </span>
                                    <Toggler toggle={readOnly} setToggle={setReadOnly} />
                                </>
                            )}
                        </div>
                        <div
                            className={`overflow-y-auto mt-2 mb-4 px-6 h-[calc(100%-97px)] ${
                                !notifications?.length && "grid place-items-center"
                            }`}
                        >
                            {notificationsLoading ? (
                                <ComponentLoader free className="-translate-y-10" />
                            ) : (
                                <>
                                    {notifications?.length ? (
                                        <>
                                            {notifications.map((not, idx) => (
                                                <Notification
                                                    key={not._id}
                                                    text={not.body}
                                                    date={dayjs(not.createdAt).fromNow()}
                                                    idx={idx}
                                                />
                                            ))}
                                        </>
                                    ) : (
                                        <ListEmpty
                                            title="There are no notifications yet"
                                            text="Start trading with Tradevu to see your notifications invoice here"
                                            className="!my-0 -translate-y-10"
                                        />
                                    )}
                                </>
                            )}
                        </div>
                    </div>
                </div>

                {largeScreen ? (
                    <ProfileDropdown
                        hasBusinesses={!!business}
                        accountImg={accountImg}
                        accountName={accountName}
                        accountNameArr={accountNameArr}
                        tourStep={tourStep}
                    />
                ) : (
                    <MenuIcon className="ml-6 cursor-pointer" onClick={() => setMobileNav(true)} />
                )}
            </nav>

            {!largeScreen && (
                <MobileNav
                    mobileNav={mobileNav}
                    setMobileNav={setMobileNav}
                    hasBusinesses={!!business}
                    accountImg={accountImg}
                    accountName={accountName}
                    accountNameArr={accountNameArr}
                    tourStep={tourStep}
                    openLastTourForMobile={openLastTourForMobile}
                />
            )}
        </>
    );
};

const Notification = ({ idx, read, text, date }) => (
    <div className={`py-4 grid grid-cols-[8px_1fr] gap-3 ${idx && "border-t border-[#EAECF0]"}`}>
        <div
            className={`rounded-full !size-2 translate-y-[11px] ${
                read ? "bg-transparent" : "bg-[rgb(17,168,17)]"
            }`}
        />
        <div>
            <p className={` ${read ? "text-main-grey" : "text-main-dark"}`}>{text}</p>
            <p className="capitalize mt-2 flex items-center text-sm text-main-grey">
                {date}
                {false && (
                    <>
                        <div className="rounded-full size-1 bg-main-grey mx-[9px]" />
                        <span className="text-[#7D14FF] cursor-pointer">Review</span>
                    </>
                )}
            </p>
        </div>
    </div>
);

const MobileNav = ({
    mobileNav,
    hasBusinesses,
    setMobileNav,
    tourStep,
    accountNameArr,
    accountName,
    accountImg,
    openLastTourForMobile,
}) => {
    const { pathname } = useLocation();
    return (
        <>
            {mobileNav && (
                <div className="fixed left-0 top-0 z-[195] h-screen w-screen bg-[#0000004D] backdrop-blur-[0.6px]" />
            )}

            <nav
                className={`${
                    openLastTourForMobile ? "bg-[#0000004D] backdrop-blur-3xl" : "bg-white"
                } !p-0 !m-0 fixed right-0 top-0 z-[200] w-screen 475:w-4/5 sm:w-7/12 md:w-1/2 h-screen block lg:hidden transition-all duration-[400ms] ${
                    mobileNav ? "translate-x-0" : "translate-x-[110%]"
                }`}
            >
                <div className="flex items-center h-20 px-6">
                    <img src={tradevuLogo} alt="Tradevu" className="h-8 mr-auto" />
                    <CloseIcon
                        className="size-8 cursor-pointer rounded-full overflow-hidden"
                        onClick={() => setMobileNav(false)}
                    />
                </div>

                <NavLinks vertical />

                <div className="-translate-y-3.5 !mb-3 sm:hidden">
                    <FlexNav pathname={pathname} inNav />
                </div>

                <ProfileDropdown
                    hasBusinesses={hasBusinesses}
                    accountImg={accountImg}
                    accountName={accountName}
                    accountNameArr={accountNameArr}
                    tourStep={tourStep}
                />
            </nav>
        </>
    );
};

const NavLinks = ({ vertical }) => (
    <div className={`${vertical ? "flex flex-col items-end px-10 !gap-8 !mt-14 !mb-11" : "flex"}`}>
        {[
            { name: "Dashboard", link: "/dashboard", Icon: HomeIcon },
            { name: "Pay", link: "/payment/home", Icon: SwapIcon },
            { name: "Finance", link: "/finance/home", Icon: TradeIcon },
            { name: "Flex", link: "/flex/home", Icon: HomeTrendIcon },
        ].map(({ link, name, Icon }) => (
            <NavLink
                className={({ isActive }) =>
                    `flex items-center !transition-all !duration-500 ${
                        isActive && "font-medium !text-secondary"
                    }`
                }
                key={name}
                to={link}
            >
                {({ isActive }) => (
                    <>
                        <Icon
                            className={`size-5 ${vertical ? "mr-2.5" : "mr-2"} ${
                                isActive ? "opacity-100 ml-9" : "opacity-0 ml-3"
                            }`}
                        />
                        {name}
                    </>
                )}
            </NavLink>
        ))}
    </div>
);

export default Navbar;
