import { ArrowIcon, CopyIcon } from "asset/icons";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import add from "asset/images/add.svg";

const Analytics = ({
    title = "",
    text = "",
    decimal = "",
    subTextValue = "",
    subText,
    hideAmount,
    fundWallet,
    defaults,
}) => {
    const navigate = useNavigate();
    const balanceType = title.includes("balance");
    const invoiceType = title.includes("invoices");
    const loanType = title.includes("loans");

    return (
        <div
            className={`rounded-lg bg-white border !border-[#F4F0FF] p-[16px] tradevu-tour-step-1`}
        >
            <div className="flex justify-between items-center">
                <p className="text-main-grey font-medium text-sm">{title}</p>
                {fundWallet && (
                    <button
                        className="flex text-[#6F00FF] justify-center items-center text-sm"
                        onClick={() => {
                            navigate("/topup/fund", {
                                state: {
                                    default: defaults,
                                },
                            });
                        }}
                    >
                        <img
                            src={add}
                            alt=""
                            style={{
                                marginRight: "10px",
                            }}
                        />{" "}
                        Fund Wallet
                    </button>
                )}
                {invoiceType && (
                    <p className="flex items-center font-medium text-[13px] cursor-pointer !hidden">
                        All time <ArrowIcon className="translate-y-[1px] ml-[6px]" />
                    </p>
                )}
            </div>

            <h3 className={`flex mt-3 text-xl text-main-grey`}>
                {hideAmount && balanceType ? (
                    <Ellipses className={"mt-2 mb-1 *:size-2"} />
                ) : (
                    <>
                        <span className="text-[32px] text-main tracking-wide">{text}</span>
                        {decimal && <span className="translate-y-[3px] ml-0.5">.{decimal}</span>}
                    </>
                )}
            </h3>

            {balanceType ? (
                <p
                    className={`flex items-center mt-3 text-sm text-main-grey ${
                        !subText && !subTextValue && "opacity-0"
                    }`}
                >
                    <span
                        className="flex items-center cursor-pointer"
                        onClick={() => {
                            navigator.clipboard.writeText(subTextValue + " " + (subText ?? ""));
                            Swal.fire({
                                text: "Account number copied!",
                                icon: "success",
                                width: "280px",
                            });
                        }}
                    >
                        {subTextValue} <CopyIcon className="ml-[3px] size-4" />
                    </span>

                    {subText && (
                        <>
                            <div className="rounded-full size-1 bg-main-grey mx-[9px]" />
                            {subText}
                        </>
                    )}
                </p>
            ) : (
                <div
                    className={`flex items-center justify-between mt-3 ${
                        (!(subTextValue !== undefined) || subTextValue === "") && "opacity-0"
                    }`}
                >
                    <p className={`${hideAmount && !loanType && "flex items-center"}`}>
                        {loanType ? (
                            subTextValue
                        ) : (
                            <>
                                {hideAmount ? (
                                    <Ellipses className={"*:size-1.5 mr-2.5"} />
                                ) : (
                                    subTextValue
                                )}
                            </>
                        )}{" "}
                        <span className="text-sm text-main-grey">
                            {loanType ? "pending payment" : "overdue"}
                        </span>
                    </p>
                </div>
            )}
        </div>
    );
};

const Ellipses = ({ className }) => (
    <div className={`flex items-center gap-1 translate-y-[1px] ${className}`}>
        {[2, 3, 4, 5, 6].map((item) => (
            <div className="bg-main-dark rounded-full" key={item} />
        ))}
    </div>
);

export default Analytics;
