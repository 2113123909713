import { Helmet } from "react-helmet";
import { useEffect, useState } from "react";
import SerHeader from "../component/serviceheader";
import nigeria from "../asset/images/countries/ngn.svg";
import Loader from "../component/loader";
import { useLocation, useNavigate } from "react-router-dom";
import "../style/slider.css";
import "../style/main.css";
import info from "../asset/images/iconinfo.svg";
import currencys from "../asset/images/GB.svg";
import currencyss from "../asset/images/EU.svg";
import US from "../asset/images/US.png";
import FailHandler from "../component/failhandler";
import { checkBalance } from "../component/checkBalance";
import { useSelector } from "react-redux";
import ArrowBack from "../component/arrowback";

const PaymentAmount = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [loader] = useState(false);
  const [isdisable, setisdisable] = useState(true);
  const [amount, setamount] = useState("");
  const [fails, setfail] = useState(false);
  const [message, setmessage] = useState("");
  const selectorwallet = useSelector((state) => state.wallet);

  //console.log(checkBalance(state.default, amount, selectorwallet));
  //console.log();

  useEffect(() => {
    if (amount !== "") {
      setisdisable(false);
    } else {
      setisdisable(true);
    }
  }, [setisdisable, amount]);

  useEffect(() => {
    if (fails == true) {
      let timer = setTimeout(() => {
        setfail(false);
      }, 3000);

      // clearTimeout(timer);
    }
  }, [fails]);
  const submitHandler = async (e) => {
    e.preventDefault();

    if (checkBalance(state.default, amount, selectorwallet) === true) {
      navigate(`/payment/transfer`, {
        state: {
          ...state,
          type: "bankuser",
          amount: amount,
          payType: "sameCurrency",
          quoteCurrency: state.default,
        },
      });
    } else {
      setfail(true);
      setmessage(checkBalance(state.default, amount, selectorwallet));
    }
  };

  return (
    <div className="h-100 w-100 ">
      <div
        className=""
        style={{
          maxWidth: "2000px",
          width: "100%",
          margin: "0px auto",
        }}
      >
        <Helmet>
          <title>Domestic Payment</title>
        </Helmet>

        <SerHeader header={"Domestic Payment"} width={25} />
      </div>
      <div
        style={{
          height: "100%",
          maxWidth: "2000px",
          margin: "0px auto",
          width: "100%",
        }}
      >
        <div
          className=" "
          style={{
            margin: "0px auto",
            paddingLeft: "30px",
            paddingRight: "30px",
            paddingTop: "10px",
            paddingBottom: "10px",
            width: "100%",
          }}
        >
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",

              justifyContent: "center",
            }}
          >
            <form
              onSubmit={submitHandler}
              className="form-general-layout"
              style={{
                position: "relative",
              }}
            >
              <ArrowBack />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "32px",
                  position: "relative",
                }}
              >
                <FailHandler success={fails} message={message} />

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "8px",
                  }}
                >
                  <div
                    style={{
                      color: "#101828",
                      fontWeight: "600",
                      fontSize: "24px",
                      lineHeight: "32.4px",
                    }}
                  >
                    How much?
                  </div>
                  <span
                    style={{
                      color: "#667085",
                      fontWeight: "500",
                      lineHeight: "24px",
                      fontSize: "1rem",
                      letterSpacing: "2%",
                    }}
                  >
                    Enter amount below
                  </span>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "32px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "8px",
                      width: "100%",
                    }}
                  >
                    <label
                      htmlFor=""
                      style={{
                        fontSize: "14px",
                        lineHeight: "19.6px",
                        fontWeight: "500",
                        color: "#101828",
                      }}
                    >
                      Amount
                    </label>
                    <div
                      className=""
                      style={{
                        display: "flex",
                        gap: "8px",
                        flexDirection: "column",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          gap: "8px",
                        }}
                      >
                        <div
                          style={{
                            width: "100%",
                            maxWidth: "110px",
                            borderRadius: "8px",
                            border: "none",
                            outline: "none",
                            background: "",
                            padding: "8px 16px 8px 16px",
                            backgroundColor: "#ECEFF3",
                            letterSpacing: "2%",
                            fontWeight: "500",
                            fontSize: "16px",
                            display: "flex",
                            gap: "4px",
                            alignItems: "center",
                            position: "relative",
                          }}
                        >
                          {" "}
                          <img
                            style={{
                              width: "24px",
                              height: "24px",
                            }}
                            src={
                              state.default === "EUR"
                                ? currencyss
                                : state.default === "USD"
                                ? US
                                : state.default === "NGN"
                                ? nigeria
                                : currencys
                            }
                            alt=""
                          />
                          {state.default}
                        </div>
                        <input
                          type="number"
                          name=""
                          id=""
                          onChange={(e) => {
                            setamount(e.target.value);
                          }}
                          style={{
                            width: "100%",
                            borderRadius: "8px",
                            border: "none",
                            height: "56px",
                            outline: "none",
                            background: "",
                            padding: "8px 16px 8px 16px",
                            backgroundColor: "#ECEFF3",
                            letterSpacing: "2%",
                            fontWeight: "500",
                            fontSize: "16px",
                          }}
                          placeholder="Enter amount"
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      padding: "16px",
                      borderRadius: "8px",
                      background: "#fff",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        gap: "8px",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <img src={info} alt="" />
                      <span
                        style={{
                          color: "#667085",
                          fontWeight: "500",
                          fontSize: "14px",
                          lineHeight: "19.6px",
                        }}
                      >
                        These are regular and instant transfers between the same
                        currencies.
                      </span>
                    </div>
                  </div>

                  <div className="">
                    <button
                      disabled={isdisable}
                      style={{
                        maxWidth: "280px",
                        width: "100%",
                        background: isdisable ? "#EBE4FF" : "#6F00FF",
                        padding: "16px 24px 16px 24px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        border: "none",
                        outline: "none",
                        minHeight: "46px",
                        borderRadius: "8px",
                        fontSize: "16px",
                        lineHeight: "24px",
                        letterSpacing: "2%",
                        fontWeight: "600",
                        color: isdisable ? "#BFA6FF" : "#ffff",
                      }}
                    >
                      {loader === true ? <Loader data={loader} /> : "Continue"}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentAmount;
