import getInitials from "utilities/getInitials";

const Avatar = ({ img, fullName, className }) => {
    return (
        <>
            {img ? (
                <img
                    src={img}
                    alt={getInitials(fullName)}
                    className={`rounded-full object-cover size-9 !mr-2 !border !border-[rgb(217,205,255)] ${className}`}
                />
            ) : (
                <span
                    className={`rounded-full size-9 !mr-2 !text-[#7f56d9] !bg-[#f4f0ff] grid place-items-center font-medium ${className}`}
                >
                    {getInitials(fullName)}
                </span>
            )}
        </>
    );
};

export default Avatar;
