import Modal from "react-bootstrap/Modal";
import success from "../asset/images/realwarning.png";
import "../style/main.css";
import "../style/rightside.css";
import Loader from "./loader";
import { useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import FailHandler from "./failhandler";
import { useEffect } from "react";
import RepaySuccess from "./RepaySuccess";

function ConfirmLoanRepay(props) {
  const [wallet, setwallet] = useState("");
  const closeModal = () => {
    var datashow = props.show;
    props.setshow(!datashow);
  };
  const [loader, setloader] = useState(false);
  const [message, setmessage] = useState("");
  const [fail, setfail] = useState(false);
  const selectorbusiness = useSelector((state) => state.wallet);

  const filterwallet = selectorbusiness?.filter(
    (item) => item.currency === props?.data.currency
  );
  useEffect(() => {
    let timer = setTimeout(() => {
      setfail(false);
    }, 3000);
    return () => {
      clearTimeout(timer);
    };
  }, [fail]);

  const payLoan = async () => {
    setloader(true);
    await axios
      .post(`/v1/finance/repay_loan/${props?.data?._id}/${props.data[0]?._id}`)
      .then((res) => {
        console.log(res);
        //  closeModal();
        props.setshow(false);
        props.setshowsuccess(true);
        setloader(false);
      })
      .catch((e) => {
        console.log(e);
        setfail(true);
        setloader(false);
        setmessage("An error occur");
      });
  };

  return (
    <Modal
      show={props.show}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="border-0 "
      onHide={() => props.setshow(false)}
    >
      {fail && (
        <>
          <div
            className="success"
            style={{
              background: "#FFFBFA",
              border: " 1px solid #FDA29B",
              width: "100%",
              position: "absolute",
              top: "-80px",
            }}
          >
            <div className="lg-success">
              <div
                className="ech-lg-hm"
                style={{
                  background: "#F9FAFB",
                }}
              >
                <div
                  className="main-sucees"
                  style={{
                    color: "#B42318",
                  }}
                >
                  Failed
                </div>
                <span
                  style={{
                    color: "#B42318",
                    width: "100%",
                  }}
                >
                  {message}
                </span>
              </div>
            </div>
          </div>
        </>
      )}
      <Modal.Body
        style={{
          borderRadius: "16px",
          padding: "0px",
          border: "none",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
          }}
        >
          <div
            className=""
            style={{
              borderBottom: "1px solid #E7E9FB",
              padding: "8px 24px 8px 24px",
            }}
          >
            <span
              className="material-icons"
              style={{ color: "#667085" }}
              onClick={closeModal}
            >
              close
            </span>
          </div>
          <div style={{ padding: "8px 24px 8px 24px" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "32px",
                marginBottom: "10px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  gap: "24px",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <div>
                  <img src={success} alt="" />
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "8px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <h3
                      style={{
                        color: "#101828",
                        fontWeight: "700",
                        lineHeight: "24.3px",
                        fontSize: "18px",
                        textAlign: "center",
                      }}
                    >
                      Confirm repayment
                    </h3>
                    <span
                      style={{
                        textAlign: "center",
                        color: "#667085",
                        fontWeight: "400",
                        letterSpacing: "2%",
                        fontSize: "16px",
                        lineHeight: "24px",
                      }}
                    >
                      You’re about to repay{" "}
                      {props.data.currency === "NGN"
                        ? "₦"
                        : props.data.currency === "EUR"
                        ? "€"
                        : props.data.currency === "USD"
                        ? "$"
                        : "£"}
                      {parseFloat(props?.data[0]?.amount)} for your finance
                      request.
                    </span>
                  </div>
                </div>
                <div className="wallet_balance">
                  Wallet Balance:{" "}
                  {props.data?.currency === "NGN"
                    ? "₦"
                    : props.data === "EUR"
                    ? "€"
                    : props.data === "USD"
                    ? "$"
                    : "£"}
                  {parseFloat(
                    filterwallet?.map((item) => item.balance)
                  ).toLocaleString()}
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "8px",
                }}
              >
                <button
                  type="submit"
                  onClick={() => payLoan()}
                  disabled={loader}
                  style={{
                    background: "#6F00FF",
                    color: "#fff",
                    padding: "16px 24px 16px 24px",
                    fontWeight: "600",
                    border: "none",
                    outline: "none",
                    borderRadius: "8px",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  {loader ? (
                    <Loader />
                  ) : (
                    `Pay ${
                      props.data.currency === "NGN"
                        ? "₦"
                        : props.data.currency === "EUR"
                        ? "€"
                        : props.data.currency === "USD"
                        ? "$"
                        : "£"
                    }${parseFloat(props?.data[0]?.amount)}`
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default ConfirmLoanRepay;
