import LoginHeader from "../component/loginheader";
import "../style/index.css";
import { useState } from "react";
import ArrowBack from "../component/arrowback";
import { useLocation, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import checkbox from "../asset/images/Checkbox.svg";

// import {useNavigate} from "react-router-dom";

const BusinessType = () => {
  // const history = useNavigate()
  const { state } = useLocation();
  const [selectedData, setSelectedData] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();

  const data = [
    {
      name: "LLC",
      option: "CAC registered corporations",
      id: 1,
      title: "LLC",
    },
    {
      name: "Sole Proprietorship",
      option: "CAC registered businesses or partnerships",
      id: 2,
      title: "SoleProprietorship",
    },
  ];
  const getSelectedData = (data) => {
    setSelectedData(data);
    setSelectedIndex(data.id);
  };
  const goNextPage = () => {
    navigate("/business/document", {
      state: {
        ...selectedData,
      },
    });
  };
  return (
    <div
      className="container-xxl"
      style={{
        background: "#F6F8FA",
        paddingRight: "0px",
        maxWidth: "2000px",
        width: "100%",
        margin: "0px auto",
      }}
    >
      <Helmet>
        <title>Business Type</title>
      </Helmet>

      <LoginHeader width={45} />

      <div
        className="p-2  align-items-center d-flex"
        style={{
          height: "calc(100% - 150px)",
          justifySelf: "center",
          display: "flex",
        }}
      >
        <div className="form-general-layout">
          <div
            className="card-body"
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "32px",
            }}
          >
            <div
              className="d-flex justify-content-between"
              style={{ alignItems: "center" }}
            >
              <ArrowBack />
              <span
                style={{
                  fontSize: "14px",
                  fontWeight: "600",
                  color: "#344054",
                  cursor: "pointer",
                  lineHeight: "19.6px",
                }}
                onClick={() => {
                  navigate("/business/documents");
                }}
              >
                Skip
              </span>
            </div>
            {error === true ? (
              <div
                className=""
                style={{
                  textAlign: "center",
                  fontSize: "1rem",
                  color: "crimson",
                  marginBottom: "10px",
                }}
              >
                {errorMessage}
              </div>
            ) : (
              ""
            )}

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "4px",
              }}
            >
              <div
                style={{
                  fontSize: "2rem",
                  fontWeight: "600",
                  lineHeight: "40px",
                  color: "#101828",
                }}
              >
                Select business type
              </div>
              <span
                style={{
                  color: "#667085",
                  fontSize: "1rem",
                  lineHeight: "24px",
                  fontWeight: "500",
                  letterSpacing: "2%",
                }}
              >
                What type of business do you operate?
              </span>
            </div>

            <div
              className="card p-0 bg-white  border-0 "
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "8px",
                borderRadius: "8px",
              }}
            >
              {data.map((datas) => (
                <div
                  style={{
                    borderRadius: "8px",
                    padding: "8px",
                    background: selectedIndex === datas.id ? "#F4F0FF" : "",
                    margin: selectedIndex === datas.id ? "5px" : "",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    getSelectedData(datas);
                  }}
                >
                  <div
                    className="d-flex justify-content-between p-0"
                    style={{ alignItems: "center" }}
                  >
                    <div
                      className="d-grid"
                      style={{ marginBottom: "0px !important" }}
                      key={datas.id}
                    >
                      <div
                        style={{
                          fontSize: "16px",
                          lineHeight: "24px",
                          letterSpacing: "2%",
                          fontWeight: "600",
                        }}
                      >
                        {datas.name}
                      </div>
                      <span
                        style={{
                          fontSize: "12px",
                          lineHeight: "19.6px",
                          fontWeight: "500",
                          color: "#667085",
                        }}
                      >
                        {datas.option}{" "}
                      </span>
                    </div>
                    {selectedIndex === datas.id && (
                      <img src={checkbox} alt="" />
                    )}
                  </div>
                </div>
              ))}
            </div>

            <button
              className="btn"
              onClick={goNextPage}
              style={{
                background: "#6F00FF",
                color: "#fff",
                lineHeight: "24px",
                letterSpacing: "2%",
                fontSize: "1rem",
                fontWeight: "600",
                maxWidth: "279px",
                width: "100%",
                height: "48px",
                padding: "16px 24px 16px 24px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              Continue
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BusinessType;
