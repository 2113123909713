import Modal from "react-bootstrap/Modal";

import { useState } from "react";
import BuyerDetail from "../component/buyerdetail";
import axios from "axios";
import Loader from "../component/loader";
import { useEffect } from "react";

function EditBuyerDetails(props) {
    const [modalShow, setModalShow] = useState(false);
    const [buyerdetailsinfo, setbuyerdetailsinfo] = useState([]);
    const [firstName, setfirstName] = useState("");
    const [lasttName, setlastName] = useState("");
    const [businessName, setBusinesssName] = useState("");
    const [email, setEmail] = useState("");
    const [phoneNumber, setphoneNumber] = useState("");
    const [loader, setloader] = useState(false);
    const submitBuyerDetails = async (e) => {
        e.preventDefault();
        setloader(true);
        const data = {
            firstName: firstName,
            lastName: lasttName,
            phoneNumber: phoneNumber,
            email: email,
            businessName: businessName,
            flexUserType: "buyer",
        };

        await axios
            .put(`/v1/flex/user/${props.selectedbuyers._id}`, data)
            .then((res) => {
                console.log(res);
                window.location.reload();
            })
            .catch((e) => {
                console.log(e);
            });
        setloader(false);
        props.setsuccess(true);
        props.setshowedit(false);
    };

    useEffect(() => {
        setBusinesssName(props.selectedbuyers.businessName);
        setfirstName(props.selectedbuyers.firstName);
        setlastName(props.selectedbuyers.lastName);
        setEmail(props.selectedbuyers.email);
        setphoneNumber(props.selectedbuyers.phoneNumber);
    }, [
        props.selectedbuyers.businessName,
        props.selectedbuyers.firstName,
        props.selectedbuyers.lastName,
        props.selectedbuyers.email,
        props.selectedbuyers.phoneNumber,
    ]);
    return (
        <>
            <BuyerDetail
                show={modalShow}
                onHide={() => setModalShow(false)}
                buyerdetail={buyerdetailsinfo}
                setbuyerdetailsinfo={setbuyerdetailsinfo}
                selecteditem={props.selecteditem}
                setselecteditem={props.setselecteditem}
            />

            <Modal
                show={props.showedit}
                backdrop="false"
                keyboard={false}
                style={{
                    border: "none",
                }}
                onHide={() => props.setshow(false)}
            >
                <Modal.Body
                    className="p-0"
                    style={{
                        height: "100vh",
                        maxHeight: "600px",
                        minHeight: "600px",
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "24px",
                        }}
                    >
                        <div
                            className=""
                            style={{
                                borderBottom: "1px solid #E7E9FB",
                                padding: "8px 24px 8px 24px",
                            }}
                        >
                            <span
                                className="material-icons"
                                style={{
                                    color: "#667085",
                                    cursor: "pointer",
                                }}
                                onClick={() => {
                                    props.setshowedit(false);
                                }}
                            >
                                close
                            </span>
                        </div>
                        <div
                            style={{
                                padding: "8px 24px 8px 24px",
                            }}
                        >
                            <form
                                onSubmit={submitBuyerDetails}
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: "16px",
                                }}
                            >
                                <div className="top-buyer-add">
                                    <h3 className="my-titile-add-new-buyer"> Edit Partner</h3>
                                    <span>
                                        Start receiving orders from partner directly on Tradevu.
                                    </span>
                                </div>
                                <div className="form-select-group">
                                    <input
                                        type="text"
                                        style={{
                                            textTransform: "none",
                                        }}
                                        className="each-form-group"
                                        placeholder="First name"
                                        value={firstName}
                                        required
                                        onChange={(e) => {
                                            setfirstName(e.target.value);
                                        }}
                                    />
                                    <input
                                        style={{
                                            textTransform: "none",
                                        }}
                                        type="text"
                                        className="each-form-group"
                                        placeholder="Last name"
                                        value={lasttName}
                                        required
                                        onChange={(e) => {
                                            setlastName(e.target.value);
                                        }}
                                    />

                                    <input
                                        style={{
                                            textTransform: "none",
                                        }}
                                        type="text"
                                        className="each-form-group"
                                        placeholder="Business name"
                                        value={businessName}
                                        required
                                        onChange={(e) => {
                                            setBusinesssName(e.target.value);
                                        }}
                                    />
                                    <input
                                        style={{
                                            textTransform: "none",
                                        }}
                                        type="email"
                                        className="each-form-group"
                                        placeholder="Email address"
                                        value={email}
                                        required
                                        onChange={(e) => {
                                            setEmail(e.target.value);
                                        }}
                                    />
                                    <input
                                        style={{
                                            textTransform: "none",
                                        }}
                                        type="tel"
                                        className="each-form-group"
                                        placeholder="Phone Number"
                                        value={phoneNumber}
                                        required
                                        onChange={(e) => {
                                            setphoneNumber(e.target.value);
                                        }}
                                    />
                                </div>
                                <button
                                    type="submit"
                                    className="btn-send-request"
                                    disabled={loader}
                                    style={{
                                        width: "100%",
                                        maxWidth: "100%",
                                        justifyContent: "center",
                                        display: "flex",
                                        alignItems: "center",
                                    }}
                                >
                                    {loader ? <Loader /> : "Save Partner"}
                                </button>
                            </form>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default EditBuyerDetails;
