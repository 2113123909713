import { Helmet } from "react-helmet";

// import {useState} from 'react';
import SerHeader from "../component/serviceheader";
import arrowleft from "../asset/images/arrowleftcircle.svg";
import a1 from "../asset/images/letterofcredit/bankstatement.svg";
import a3 from "../asset/images/letterofcredit/managementaccout.png";
import a4 from "../asset/images/letterofcredit/scheduling.png";
import a5 from "../asset/images/letterofcredit/boardresolution.png";
import a6 from "../asset/images/letterofcredit/contractagreement.png";
import a8 from "../asset/images/tradecredit/a8.svg";
import InvoiceSuccess from "../component/invoicesuccess";
import Loader from "../component/loader";
import mark from "../asset/images/marksuccess.svg";
import edit from "../asset/images/marksuccess.svg";

import { useEffect, useState } from "react";
import WayBill from "../component/trade/Waybill";
import FoerWaringDocument from "../component/trade/FoerwadingDocument";
import InsuranceCertificates from "../component/trade/InsuranceCertificate";
import Export from "../component/trade/Export";
import CertOrigin from "../component/trade/CertiOrigin";
import Packing from "../component/trade/packing";
import Inspection from "../component/trade/InspectionCertificate";

import { useLocation, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import SuccessHodler from "../component/successholder";
import FailHandler from "../component/failhandler";
import { INTEREST } from "../utilities/ut";

const CreditLineDocument = () => {
  // const [selectedIndex, setselectedIndex] = useState(-1)
  const navigate = useNavigate();
  const [isdisable, setisdisable] = useState(true);
  useEffect(() => {
    setisdisable(false);
  }, [setisdisable]);
  const { id } = useParams();
  const [showModal, setshowModal] = useState(false);
  const [showModal2, setshowModal2] = useState(false);
  const [showModal3, setshowModal3] = useState(false);
  const [showModal4, setshowModal4] = useState(false);
  const [showModal5, setshowModal5] = useState(false);
  const [showModal6, setshowModal6] = useState(false);
  const [showModal7, setshowModal7] = useState(false);
  const today = new Date();
  const [tab, settab] = useState("trade");
  const [waybill, setWaybill] = useState([]);
  const [waybillSize, setWaybillSize] = useState(0);

  const [ForwardingDocument, setForwardingDocument] = useState([]);
  const [forwardingDocumentSize, setForwardingDocumentSize] = useState(0);
  const [InsuranceCertificate, setInsuranceCertificate] = useState([]);
  const [InsuranceCertificateSize, setInsuranceCertificateSize] = useState(0);

  const [ExportImport, setExportInport] = useState([]);
  const [ExportImportSize, setExportInportSize] = useState(0);

  const [CertificateOrigin, setCertificateOrgin] = useState([]);
  const [CertificateOriginSize, setCertificateOrginSize] = useState(0);

  const [PackingList, setPackingList] = useState([]);
  const [PackingListSize, setPackingListSize] = useState(0);

  const [InspectCertificate, setInspectCertificate] = useState([]);
  const [InspectCertificateSize, setInspectCertificateSize] = useState(0);
  const [loader, setloader] = useState(false);
  const [message, setmessage] = useState(false);
  const [success, setsuccess] = useState(false);
  const [failhandler, setfailhandler] = useState(false);
  const [tfess, settess] = useState(0);

  const { state } = useLocation();

  useState(() => {
    if (failhandler) {
      setTimeout(() => {
        setfailhandler(false);
      }, 3000);
    }
  }, [failhandler]);
  useEffect(() => {
    if (
      localStorage.getItem("tabdefault") === "summary" &&
      PackingList.length !== 0 &&
      ExportImport.length !== 0 &&
      CertificateOrigin.length !== 0
    ) {
      settab("summary");
    } else {
      settab("trade");
    }
  }, [settab]);

  INTEREST()
    .then((res) => {
      settess(res.data.data.financeCreditLineInterest);
    })
    .catch((e) => {
      console.log(e);
    });
  const HandleSubmit = async (e) => {
    e.preventDefault();

    setloader(true);
    const formdata = new FormData();
    formdata.append("bankStatement", waybill, waybill.name);

    formdata.append(
      "managementAccounts",
      ForwardingDocument,
      ForwardingDocument.name
    );

    formdata.append(
      "schedulingExistingLoan",
      InsuranceCertificate,
      InsuranceCertificate.name
    );

    formdata.append("boardResolution", ExportImport, ExportImport.name);

    formdata.append(
      "contractAgreement",
      CertificateOrigin,
      CertificateOrigin.name
    );

    await axios
      .put(`/v1/finance/credit_line/upload/${state._id}`, formdata)
      .then(() => {
        axios
          .post(`/v1/finance/request_credit_line/verification/${state._id}`)
          .then(() => {
            setshowModal3(true);
            setloader(false);
          })
          .catch((e) => {
            setloader(false);
            setmessage(
              e.response?.data.message
                ? e.response?.data.message
                : "An error occur"
            );
            setfailhandler(true);
          });
      })
      .catch((e) => {
        setloader(false);
        setmessage(
          e.response?.data.message
            ? e.response?.data.message
            : "An error occurred"
        );
        setfailhandler(true);
      });
  };

  return (
    <>
      {tab === "trade" && (
        <div
          className="h-100 w-100 "
          style={{
            background: "#f6f6f6",
          }}
        >
          <div
            className=""
            style={{
              maxWidth: "2000px",
              width: "100%",
              margin: "0px auto",
            }}
          >
            <Helmet>
              <title>Credit Line</title>
            </Helmet>

            <SerHeader header={"Credit Line"} width={80} />
            <WayBill
              filesize={waybillSize}
              show={showModal}
              setshow={setshowModal}
              filename={waybill}
              setfilesize={setWaybillSize}
              setfilename={setWaybill}
              title={"Bank Statement"}
            />

            <FoerWaringDocument
              filesize={forwardingDocumentSize}
              show={showModal2}
              setshow={setshowModal2}
              filename={ForwardingDocument}
              setfilesize={setForwardingDocumentSize}
              setfilename={setForwardingDocument}
              title={"Management accounts"}
            />
            <InsuranceCertificates
              filesize={InsuranceCertificateSize}
              show={showModal3}
              setshow={setshowModal3}
              filename={InsuranceCertificate}
              setfilesize={setInsuranceCertificateSize}
              setfilename={setInsuranceCertificate}
              title={"Scheduling of existing loans"}
            />
            <Export
              filesize={ExportImportSize}
              show={showModal4}
              setshow={setshowModal4}
              filename={ExportImport}
              setfilesize={setExportInportSize}
              setfilename={setExportInport}
              title={"Board resolution"}
            />
            <CertOrigin
              filesize={CertificateOriginSize}
              show={showModal5}
              setshow={setshowModal5}
              filename={CertificateOrigin}
              setfilesize={setCertificateOrginSize}
              setfilename={setCertificateOrgin}
              title={"Contract Agreement"}
            />
            <Packing
              filesize={PackingListSize}
              show={showModal6}
              setshow={setshowModal6}
              filename={PackingList}
              setfilesize={setPackingListSize}
              setfilename={setPackingList}
              title={"Board Resolution"}
            />
            <Inspection
              filesize={InspectCertificateSize}
              show={showModal7}
              setshow={setshowModal7}
              filename={InspectCertificate}
              setfilesize={setInspectCertificateSize}
              setfilename={setInspectCertificate}
            />
          </div>
          <div style={{ height: "100%" }}>
            <div
              className="conatiner "
              style={{
                maxWidth: "2000",
                width: "100%",
                margin: "0px auto",
                paddingLeft: "30px",
                paddingRight: "30px",
                paddingTop: "10px",
                paddingBottom: "10px",
              }}
            >
              <div
                style={{
                  height: "100%",
                  width: "100%",
                  display: "flex",

                  justifyContent: "center",
                }}
              >
                <div className="form-general-layout mb-5">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "32px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "16px",
                      }}
                    >
                      <div
                        onClick={() => {
                          navigate(-1);
                        }}
                      >
                        <img src={arrowleft} alt="" />
                      </div>
                      <div>
                        <div
                          style={{
                            color: "#101828",
                            fontWeight: "600",
                            fontSize: "24px",
                            lineHeight: "32.4px",
                          }}
                        >
                          Upload Documents
                        </div>
                        <span
                          style={{
                            color: "#667085",
                            fontSize: "16px",
                            lineHeight: "24px",
                            letterSpacing: "2%",
                            fontWeight: "500",
                            maxWidth: "600px",
                            width: "100%",
                          }}
                        >
                          Provide one of each of the following documents so we
                          can verify your transaction.
                        </span>
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "8px",
                      }}
                    >
                      <label
                        style={{
                          color: "#667085",
                          fontWeight: "500",
                          lineHeight: "19.6px",
                          fontSize: "14px",
                        }}
                      >
                        Required documents
                      </label>
                      <div
                        style={{
                          background: "#fff",
                          borderRadius: "8px",
                          flexDirection: "column",
                          display: "flex",
                          borderBottomRightRadius: "0px",
                          borderBottomLeftRadius: "0px",
                        }}
                      >
                        <div
                          style={{
                            padding: "16px",
                            borderBottom: "1px solid #E7E9FB",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                          onClick={() => {
                            setshowModal(true);
                            setWaybill([]);
                          }}
                        >
                          <div
                            style={{
                              gap: "8px",
                              display: "flex",
                            }}
                          >
                            <img src={a3} alt="" />
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <span
                                style={{
                                  color: "#101828",
                                  letterSpacing: "2%",
                                  fontWeight: "600",
                                  fontSize: "16px",
                                  lineHeight: "24px",
                                }}
                              >
                                Bank Statement
                              </span>
                              <span
                                style={{
                                  color: "#667085",
                                  lineHeight: "16.2px",
                                  fontSize: "12px",
                                  fontWeight: "500",
                                  letterSpacing: "2%",
                                }}
                              >
                                For 6 months
                              </span>
                            </div>
                          </div>
                          {waybill.length !== 0 ? (
                            <img src={mark} alt="" />
                          ) : (
                            <span className="material-icons">
                              chevron_right
                            </span>
                          )}
                        </div>
                        <div
                          style={{
                            padding: "16px",
                            borderBottom: "1px solid #E7E9FB",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                          onClick={() => {
                            setForwardingDocument([]);
                            setshowModal2(true);
                          }}
                        >
                          <div
                            style={{
                              gap: "8px",
                              display: "flex",
                            }}
                          >
                            <img src={a1} alt="" />
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <span
                                style={{
                                  color: "#101828",
                                  letterSpacing: "2%",
                                  fontWeight: "600",
                                  fontSize: "16px",
                                  lineHeight: "24px",
                                }}
                              >
                                Management accounts
                              </span>
                              <span
                                style={{
                                  color: "#667085",
                                  lineHeight: "16.2px",
                                  fontSize: "12px",
                                  fontWeight: "500",
                                  letterSpacing: "2%",
                                }}
                              >
                                Please upload your company's most recent
                                management accounts
                              </span>
                            </div>
                          </div>
                          {ForwardingDocument.length !== 0 ? (
                            <img src={mark} alt="" />
                          ) : (
                            <span className="material-icons">
                              chevron_right
                            </span>
                          )}
                        </div>
                        <div
                          style={{
                            padding: "16px",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            borderBottom: "1px solid #E7E9FB",
                          }}
                          onClick={() => {
                            setInsuranceCertificate([]);
                            setshowModal3(true);
                          }}
                        >
                          <div
                            style={{
                              gap: "8px",
                              display: "flex",
                            }}
                          >
                            <img src={a4} alt="" />
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <span
                                style={{
                                  color: "#101828",
                                  letterSpacing: "2%",
                                  fontWeight: "600",
                                  fontSize: "16px",
                                  lineHeight: "24px",
                                }}
                              >
                                Scheduling of existing loans
                              </span>
                              <span
                                style={{
                                  color: "#667085",
                                  lineHeight: "16.2px",
                                  fontSize: "12px",
                                  fontWeight: "500",
                                  letterSpacing: "2%",
                                }}
                              >
                                Plase upload a list of yor company's debts
                              </span>
                            </div>
                          </div>
                          {InsuranceCertificate.length !== 0 ? (
                            <img src={mark} alt="" />
                          ) : (
                            <span className="material-icons">
                              chevron_right
                            </span>
                          )}
                        </div>
                        <div
                          style={{
                            padding: "16px",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            borderBottom: "1px solid #E7E9FB",
                          }}
                          onClick={() => {
                            setshowModal4(true);
                            setExportInport([]);
                          }}
                        >
                          <div
                            style={{
                              gap: "8px",
                              display: "flex",
                            }}
                          >
                            <img src={a8} alt="" />
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <span
                                style={{
                                  color: "#101828",
                                  letterSpacing: "2%",
                                  fontWeight: "600",
                                  fontSize: "16px",
                                  lineHeight: "24px",
                                }}
                              >
                                Board resolution
                              </span>
                              <span
                                style={{
                                  color: "#667085",
                                  lineHeight: "16.2px",
                                  fontSize: "12px",
                                  fontWeight: "500",
                                  letterSpacing: "2%",
                                }}
                              >
                                Please upload Board Resolution for this request
                              </span>
                            </div>
                          </div>
                          {ExportImport.length !== 0 ? (
                            <img src={mark} alt="" />
                          ) : (
                            <span className="material-icons">
                              chevron_right
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "0px",
                      }}
                    >
                      <div
                        style={{
                          background: "#fff",
                          borderRadius: "8px",
                          borderTopRightRadius: "0px",
                          borderTopLeftRadius: "0px",
                          flexDirection: "column",
                          display: "flex",
                        }}
                      >
                        <div
                          style={{
                            padding: "16px",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                          onClick={() => {
                            setshowModal5(true);
                            setCertificateOrgin([]);
                          }}
                        >
                          <div
                            style={{
                              gap: "8px",
                              display: "flex",
                            }}
                          >
                            <img src={a5} alt="" />
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <span
                                style={{
                                  color: "#101828",
                                  letterSpacing: "2%",
                                  fontWeight: "600",
                                  fontSize: "16px",
                                  lineHeight: "24px",
                                }}
                              >
                                Contract Aggreement
                              </span>
                              <span
                                style={{
                                  color: "#667085",
                                  lineHeight: "16.2px",
                                  fontSize: "12px",
                                  fontWeight: "500",
                                  letterSpacing: "2%",
                                }}
                              >
                                Please upload contract agreement this capital
                                will be used for.
                              </span>
                            </div>
                          </div>
                          {CertificateOrigin.length !== 0 ? (
                            <img src={mark} alt="" />
                          ) : (
                            <span className="material-icons">
                              chevron_right
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                    <button
                      disabled={isdisable}
                      variant="primary"
                      style={{
                        maxWidth: "280px",
                        width: "100%",
                        marginTop: "30px",
                        background: isdisable ? "#EBE4FF" : "#6F00FF",
                        padding: "16px 24px 16px 24px",
                        border: "none",
                        outline: "none",
                        borderRadius: "8px",
                        fontSize: "16px",
                        lineHeight: "24px",
                        letterSpacing: "2%",
                        fontWeight: "600",
                        minHeight: "46px",
                        color: isdisable ? "#BFA6FF" : "#ffff",
                      }}
                      onClick={() => {
                        if (
                          waybill.length !== 0 &&
                          ForwardingDocument.length !== 0 &&
                          InsuranceCertificate.length !== 0 &&
                          CertificateOrigin.length !== 0 &&
                          ExportImport.length !== 0
                        ) {
                          localStorage.setItem("tabdefault", "summary");
                          settab("summary");
                        }
                        //  navigate("/trade_of_credit/summary");
                      }}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {/***Summary */}

      {tab === "summary" && (
        <div className="h-100 w-100 ">
          <div
            className=""
            style={{
              maxWidth: "2000px",
              margin: "0px auto",
              width: "100%",
            }}
          >
            <Helmet>
              <title>Summary</title>
            </Helmet>

            <SerHeader header={"Trade Credit"} width={100} />
            <InvoiceSuccess
              show={showModal3}
              set={"finance"}
              currency={state.currency}
              amount={state.requestedAmount}
              setshow={setshowModal3}
              subject={"Application received! Your request is under review"}
              title={`We’ll review your request of     ${
                state.currency === "GPB"
                  ? "£"
                  : state.currency === "NGN"
                  ? "₦"
                  : "€"
              }${
                state.requestedAmount
              }  in less than 48 hours. You’ll be notified on how to proceed from here.`}
            />
          </div>
          <div style={{ height: "100%" }}>
            <div
              className="conatiner "
              style={{
                maxWidth: "2000px",
                margin: "0px auto",
                paddingLeft: "30px",
                paddingRight: "30px",
                paddingTop: "10px",
                paddingBottom: "10px",
              }}
            >
              <div
                style={{
                  height: "100%",
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <div
                  className="form-general-layout mb-5"
                  style={{
                    position: "relative",

                    maxWidth: "384px",
                    width: "100%",
                  }}
                >
                  <SuccessHodler success={success} message={message} />
                  <FailHandler success={failhandler} message={message} />
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "32px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "16px",
                      }}
                    >
                      <div
                        onClick={() => {
                          settab("trade");
                        }}
                      >
                        <img src={arrowleft} alt="" />
                      </div>
                      <div>
                        <div
                          style={{
                            color: "#101828",
                            fontWeight: "600",
                            fontSize: "24px",
                            lineHeight: "32.4px",
                          }}
                        >
                          Summary
                        </div>
                        <div
                          style={{
                            color: "#667085",
                            fontSize: "16px",
                            lineHeight: "24px",
                            letterSpacing: "2%",
                            fontWeight: "500",
                            maxWidth: "600px",
                            width: "100%",
                          }}
                        >
                          Please review your details before you continue.
                        </div>
                      </div>
                    </div>
                  </div>
                  <form
                    onSubmit={HandleSubmit}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "16px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "8px",
                      }}
                    >
                      <label
                        htmlfor=""
                        style={{
                          color: "#344054",
                          fontWeight: "600",
                          lineHeight: "19.6px",
                          fontSize: "14px",
                        }}
                      >
                        Details
                      </label>
                      <div
                        style={{
                          background: "#fff",
                          borderRadius: "8px",
                          flexDirection: "column",
                          display: "flex",
                        }}
                      >
                        <div style={{ padding: "16px" }}>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              gap: "4px",
                            }}
                          >
                            <span
                              style={{
                                color: "#6F00FF",
                                fontWeight: "500",
                                fontSize: "14px",
                                lineHeight: "16.2px",
                                letterSpacing: "-1%",
                              }}
                            >
                              Loan amount
                            </span>
                            <span
                              style={{
                                color: "#344054",
                                fontSize: "20px",
                                lineHeight: "24px",
                                fontWeight: "600",
                              }}
                            >
                              {state.currency === "NGN"
                                ? "₦"
                                : state.currency === "EUR"
                                ? "€"
                                : state.currency === "USD"
                                ? "$"
                                : "£"}
                              {parseFloat(state.requestedAmount)
                                .toLocaleString()
                                .replace(/,/, " ")}
                            </span>
                          </div>
                        </div>

                        <div
                          style={{
                            padding: "16px",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <div style={{ gap: "8px", display: "flex" }}>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "4px",
                              }}
                            >
                              <span
                                style={{
                                  style: "#667085",
                                  fontWeight: "500",
                                  fontSize: "14px",
                                  lineHeight: "19.6px",
                                  letterSpacing: "-1%",
                                }}
                              >
                                Interest ({tfess * 100}%)
                              </span>
                              <span
                                style={{
                                  color: "#344054",
                                  fontSize: "14px",
                                  lineHeight: "19.6px",
                                  fontWeight: "600",
                                }}
                              >
                                {state.currency === "NGN"
                                  ? "₦"
                                  : state.currency === "EUR"
                                  ? "€"
                                  : state.currency === "USD"
                                  ? "$"
                                  : "£"}
                                {parseFloat(
                                  parseInt(state.requestedAmount) * tfess
                                )
                                  .toLocaleString()
                                  .replace(/,/, " ")}
                              </span>
                            </div>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              gap: "4px",
                            }}
                          >
                            <span
                              style={{
                                color: "#667085",
                                fontWeight: "500",
                                fontSize: "14px",
                                lineHeight: "19.6px",
                                letterSpacing: "-1%",
                              }}
                            >
                              Repay date
                            </span>
                            <span
                              style={{
                                color: "#101828",
                                fontSize: "14px",
                                lineHeight: "19.6px",
                                fontWeight: "600",
                              }}
                            >
                              {new Date(
                                today.setDate(
                                  today.getDate() + parseInt(state.tenure)
                                )
                              ).toDateString()}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/**
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "8px",
                      }}
                    >
                      <label htmlFor="" className="mysummarytitle">
                        Buyer/Supplier Details
                      </label>
                      <div
                        className="white-bh-lg"
                        style={{
                          height: "56px",
                        }}
                      >
                        <div className="transfer-bank-logo">
                          <div className="intial">
                            {state?.firstName?.slice(0, 1)}
                            {state?.lastName?.slice(0, 1)}
                          </div>
                          <div className="name-bank">
                            <span
                              className="name-link"
                              style={{
                                fontWeight: "600",
                              }}
                            >
                              {state.firstName} {state.lastName}
                            </span>
                            <span
                              className="bank-link"
                              style={{
                                fontWeight: "500",
                              }}
                            >
                              {state.email}
                            </span>
                          </div>
                        </div>
                        <img src={edit} alt="" />
                      </div>
                    </div>
                    */}

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "8px",
                      }}
                    >
                      <label
                        htmlFor=""
                        className="mysummarytitle"
                        style={{
                          color: "#344054",
                        }}
                      >
                        Other details
                      </label>
                      <div
                        className="white-bh-lg"
                        style={{
                          height: "56px",
                          borderBottomLeftRadius: "0px",
                          borderBottomRightRadius: "0px",
                        }}
                      >
                        <div
                          className="transfer-bank-logo"
                          style={{
                            width: "100%",
                            display: "flex",
                          }}
                        >
                          <div
                            className="name-bank"
                            style={{
                              width: "100%",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              flexDirection: "row",
                            }}
                          >
                            <span
                              className="name-link"
                              style={{
                                fontWeight: "500",
                              }}
                            >
                              Request Title
                            </span>
                            <span
                              className="bank-link"
                              style={{
                                fontWeight: "600",
                                color: "#6F00FF",
                                fontSize: "16px",
                                textAlign: "right",
                              }}
                            >
                              {state.requestTitle}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div
                        className="white-bh-lg"
                        style={{
                          height: "56px",
                          borderBottomLeftRadius: "0px",
                          borderBottomRightRadius: "0px",
                          marginTop: "-10px",
                        }}
                      >
                        <div
                          className="transfer-bank-logo"
                          style={{
                            width: "100%",
                            display: "flex",
                          }}
                        >
                          <div
                            className="name-bank"
                            style={{
                              width: "100%",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              flexDirection: "row",
                            }}
                          >
                            <span
                              className="name-link"
                              style={{
                                fontWeight: "500",
                              }}
                            >
                              Repayment Structure
                            </span>
                            <span
                              className="bank-link"
                              style={{
                                fontWeight: "600",
                                color: "#6F00FF",
                                fontSize: "16px",
                                textAlign: "right",
                              }}
                            >
                              {state.repaymentStructure}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div
                        className="white-bh-lg"
                        style={{
                          height: "56px",
                          marginTop: "-10px",
                          borderTopLeftRadius: "0px",
                          borderTopRightRadius: "0px",
                        }}
                      >
                        <div
                          className="transfer-bank-logo"
                          style={{
                            width: "100%",
                            display: "flex",
                          }}
                        >
                          <div
                            className="name-bank"
                            style={{
                              width: "100%",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              flexDirection: "row",
                            }}
                          >
                            <span
                              className="name-link"
                              style={{
                                fontWeight: "500",
                              }}
                            >
                              Annual sales revenue
                            </span>
                            <span
                              className="bank-link"
                              style={{
                                fontWeight: "600",
                                fontSize: "16px",
                                color: "#6F00FF",
                                textAlign: "right",
                              }}
                            >
                              {state.currency === "NGN"
                                ? "₦"
                                : state.currency === "EUR"
                                ? "€"
                                : "£"}
                              {state.annualRevenue}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="white-bh-lg"
                      style={{
                        height: "56px",
                        marginTop: "-20px",
                        borderTopLeftRadius: "0px",
                        borderTopRightRadius: "0px",
                      }}
                    >
                      <div
                        className="transfer-bank-logo"
                        style={{
                          width: "100%",
                          display: "flex",
                        }}
                      >
                        <div
                          className="name-bank"
                          style={{
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            flexDirection: "row",
                          }}
                        >
                          <span
                            className="name-link"
                            style={{
                              fontWeight: "500",
                            }}
                          >
                            Annual turnover
                          </span>
                          <span
                            className="bank-link"
                            style={{
                              fontWeight: "600",
                              fontSize: "16px",
                              color: "#6F00FF",
                            }}
                          >
                            {state.currency === "NGN"
                              ? "₦"
                              : state.currency === "EUR"
                              ? "€"
                              : "£"}
                            {state.annualTurnOver}
                          </span>
                        </div>
                      </div>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "8px",
                      }}
                    >
                      <label
                        htmlfor=""
                        style={{
                          color: "#344054",
                          fontWeight: "600",
                          lineHeight: "19.6px",
                          fontSize: "14px",
                        }}
                      >
                        Documents
                      </label>
                      <div
                        style={{
                          background: "#fff",
                          borderRadius: "8px",
                          flexDirection: "column",
                          display: "flex",
                        }}
                      >
                        <div
                          style={{
                            padding: "16px",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <div style={{ gap: "8px", display: "flex" }}>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <span
                                style={{
                                  style: "#667085",
                                  fontWeight: "500",
                                  fontSize: "14px",
                                  lineHeight: "19.6px",
                                }}
                              >
                                Bank Statement
                              </span>
                            </div>
                          </div>
                          <div
                            style={{
                              color: "#6F00FF",
                              letterSpacing: "2%",
                              fontWeight: "600",
                              fontSize: "16px",
                              lineHeight: "24px",
                              display: "flex",
                              alignItems: "center",
                              textAlign: "right",
                              gap: "8px",
                              textAlign: "right",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                              maxWidth: "180px",
                              overflow: "hidden",
                            }}
                          >
                            {waybill.name} -{" "}
                          </div>
                        </div>
                        <div
                          style={{
                            padding: "16px",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <div style={{ gap: "8px", display: "flex" }}>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <span
                                style={{
                                  style: "#667085",
                                  fontWeight: "500",
                                  fontSize: "14px",
                                  lineHeight: "19.6px",
                                }}
                              >
                                Management Accounts
                              </span>
                            </div>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              gap: "4px",
                            }}
                          >
                            <span
                              style={{
                                color: "#6F00FF",
                                letterSpacing: "2%",
                                fontWeight: "600",
                                fontSize: "16px",
                                lineHeight: "24px",
                                display: "flex",
                                alignItems: "center",
                                textAlign: "right",
                                gap: "8px",
                                textAlign: "right",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                                maxWidth: "180px",
                                overflow: "hidden",
                              }}
                            >
                              {ForwardingDocument.name}
                            </span>
                          </div>
                        </div>
                        <div
                          style={{
                            padding: "16px",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <div style={{ gap: "8px", display: "flex" }}>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <span
                                style={{
                                  style: "#667085",
                                  fontWeight: "500",
                                  fontSize: "14px",
                                  lineHeight: "19.6px",
                                }}
                              >
                                Scheduling of existing loans
                              </span>
                            </div>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              gap: "4px",
                            }}
                          >
                            <span
                              style={{
                                color: "#6F00FF",
                                letterSpacing: "2%",
                                fontWeight: "600",
                                fontSize: "16px",
                                lineHeight: "24px",
                                display: "flex",
                                alignItems: "center",
                                textAlign: "right",
                                gap: "8px",
                                textAlign: "right",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                                maxWidth: "180px",
                                overflow: "hidden",
                              }}
                            >
                              {InsuranceCertificate.name}
                            </span>
                          </div>
                        </div>
                        <div
                          style={{
                            padding: "16px",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <div style={{ gap: "8px", display: "flex" }}>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <span
                                style={{
                                  style: "#667085",
                                  fontWeight: "500",
                                  fontSize: "14px",
                                  lineHeight: "19.6px",
                                }}
                              >
                                Board Resolution
                              </span>
                            </div>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              gap: "4px",
                            }}
                          >
                            <span
                              style={{
                                color: "#6F00FF",
                                letterSpacing: "2%",
                                fontWeight: "600",
                                fontSize: "16px",
                                lineHeight: "24px",
                                display: "flex",
                                alignItems: "center",
                                textAlign: "right",
                                gap: "8px",
                                textAlign: "right",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                                maxWidth: "180px",
                                overflow: "hidden",
                              }}
                            >
                              {ExportImport.name}
                            </span>
                          </div>
                        </div>
                        <div
                          style={{
                            padding: "16px",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <div style={{ gap: "8px", display: "flex" }}>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <span
                                style={{
                                  style: "#667085",
                                  fontWeight: "500",
                                  fontSize: "14px",
                                  lineHeight: "19.6px",
                                }}
                              >
                                Contract Agreement
                              </span>
                            </div>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              gap: "4px",
                            }}
                          >
                            <span
                              style={{
                                color: "#6F00FF",
                                letterSpacing: "2%",
                                fontWeight: "600",
                                fontSize: "16px",
                                lineHeight: "24px",
                                display: "flex",
                                alignItems: "center",
                                gap: "8px",
                                textAlign: "right",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                                maxWidth: "180px",
                                overflow: "hidden",
                              }}
                            >
                              {CertificateOrigin.name}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <button
                      disabled={isdisable}
                      onclick={() => {
                        setshowModal3(true);
                      }}
                      variant="primary"
                      style={{
                        maxWidth: "280px",
                        width: "100%",
                        marginTop: "20px",
                        background: isdisable ? "#EBE4FF" : "#6F00FF",
                        padding: "16px 24px 16px 24px",
                        border: "none",
                        outline: "none",
                        borderRadius: "8px",
                        fontSize: "16px",
                        lineHeight: "24px",
                        letterSpacing: "2%",
                        fontWeight: "600",
                        color: isdisable ? "#BFA6FF" : "#ffff",
                        marginBottom: "100px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {loader === true ? <Loader data={loader} /> : "Submit"}
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default CreditLineDocument;
