import { useState } from "react";
import axios from "axios";

const useGetRequest = (loadingState) => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(loadingState ?? true);
    const [error, setError] = useState(null);

    /**
     * @param {string} url - The URL to send the GET request to.
     * @param {import('axios').AxiosRequestConfig} [config] - Optional Axios configuration object.
     */
    const fetchData = async (url, config, nestedData = true) => {
        setLoading(true);
        setError(null);
        try {
            const response = await axios.get(url, config);
            setData(nestedData ? response.data.data : response.data);
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false);
        }
    };

    return [fetchData, data, loading, error, setData, setLoading];
};

export default useGetRequest;
