import useScreenSize from "hooks/useScreenSize";
import OtpInput from "react18-input-otp";

const OTPInput = ({ otp, setOTP, numInputs = 4 }) => {
    const { screenWidth } = useScreenSize();
    const below600 = screenWidth < 600;
    const below530 = screenWidth < 530;
    const below480 = screenWidth < 480;
    const below400 = screenWidth < 400;
    const below360 = screenWidth < 360;

    return (
        <OtpInput
            id="otpInput"
            containerStyle={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
                margin: "0 auto",
                gap: "8px",
            }}
            inputStyle={{
                width: below360
                    ? "60px"
                    : below400
                    ? "70px"
                    : below480
                    ? "80px"
                    : below530
                    ? "100px"
                    : below600
                    ? "110px"
                    : "122px",
                height: "56px",
                borderRadius: "8px",
                fontSize: "16px",
                color: "#475467",
                fontWeight: "600",
                textTransform: "uppercase",
                overflow: "hidden",
            }}
            placeholder="----"
            className="*:!shadow-[inset_0_0_0_1000px_#EAECF0] *:placeholder:text-main-grey"
            value={otp}
            onChange={setOTP}
            numInputs={numInputs}
        />
    );
};

export default OTPInput;
