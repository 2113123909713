import {
    Arrow2Icon,
    ArrowBackRoundIcon,
    CheckCircleIcon,
    ClockIcon,
    DocumentTextIcon,
    DocumentUploadIcon,
} from "asset/icons";
import Avatar from "component/avatar";
import useGetRequest from "hooks/useGetRequest";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Navigate, useNavigate, useSearchParams } from "react-router-dom";
import currencyPairCheck from "utilities/currencyPairCheck";
import capitalizeWords from "utilities/capitalizeWords";
import Status from "./component/status";
import { formatMoney } from "utilities/formatMoney";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import capitalizeFirstLetter from "utilities/capitalizeFirstLetter";
import { businessToUse } from "flex/utils";
import MoreActions from "./component/moreActions";
import { Bars } from "react-loader-spinner";
import { errorAlert, successAlert } from "utilities/alerts";
import axios from "axios";
import EmptyList from "component/emptyList";
import UploadDocModal from "component/form/uploadDocModal";
import Loading from "component/loading";
import UpdateStatusModal from "./component/updateStatusModal";
dayjs.extend(advancedFormat);

const ManagePo = () => {
    const [searchParams] = useSearchParams();
    const orderId = searchParams.get("orderId");
    const type = searchParams.get("type");
    const { business } = useSelector((state) => state);
    const navigate = useNavigate();
    const [tabIdx, setTabIdx] = useState(0);
    const [addDocModal, setAddDocModal] = useState(false);
    const [updateStatusLoading, setUpdateStatusLoading] = useState(null);
    const [uploadDocumentLoading, setUploadDocumentLoading] = useState(false);
    const [updateStatusModal, setUpdateStatusModal] = useState(false);
    const [fetchOrder, order, orderLoading, _, setOrder] = useGetRequest();
    const [fetchActivities, activities, activitiesLoading] = useGetRequest();
    const [fetchWallets, wallets] = useGetRequest();
    const paymentPercentRef = useRef();
    const bizInfo = businessToUse(order, business, type);
    const typesTitle = { inbound: "Buyer", outbound: "Supplier", completed: "Partner" };

    const acceptOrRejectOrder = async (type) => {
        try {
            setUpdateStatusLoading(type);
            const res = await axios.put(`/v1/flex/order/${orderId}`, { status: type });
            successAlert(`Order ${type === "decline" ? "declined" : type}.`);
            console.log(res);
        } catch (error) {
            errorAlert(error);
        } finally {
            setUpdateStatusLoading(null);
        }
    };

    const uploadDocument = async (file, name) => {
        try {
            setUploadDocumentLoading(true);
            const formData = new FormData();
            formData.append("file", file, name);
            formData.append("name", name);
            const res = await axios.post(`/v1/flex/order/uploadDocument/${orderId}`, formData);
            setOrder(res.data.data);
            setUploadDocumentLoading(false);
            successAlert(`${name} uploaded successfully.`);
            setAddDocModal(false);
        } catch (error) {
            errorAlert(error);
            setUploadDocumentLoading(false);
        }
    };

    useEffect(() => {
        business?._id && fetchWallets("/v1/wallets/accounts");
    }, [business?._id]);

    useEffect(() => {
        !orderId && navigate("/flex/home");
        if (business?._id && orderId) {
            fetchOrder(`/v1/flex/order/${orderId}`);
            fetchActivities(`/v1/activity/${orderId}`, {}, false);
        }
    }, [business?._id, orderId]);
    let percentPaid = ((86 / order?.totalAmount) * 100).toFixed(2);
    // percentPaid = percentPaid.length > 1 ? percentPaid : `0${percentPaid}`;
    // order?.paidAmount
    console.log(order, "the order");

    if (!orderLoading && !order) {
        errorAlert("Order does not exist.");
        return <Navigate to="/flex/home" replace />;
    }

    return (
        <>
            {orderLoading && (
                <div className="fixed left-0 top-0 h-screen w-screen z-[1000000] opacity-65">
                    <Loading />
                </div>
            )}

            <div className="!px-7 !py-8">
                <h5 className="flex !gap-3.5 font-medium text-sm text-main-grey items-center">
                    <ArrowBackRoundIcon
                        className="bg-white rounded-full size-6 !p-1 cursor-pointer"
                        onClick={() => navigate("/flex/home")}
                    />{" "}
                    Back to orders
                </h5>

                <div className="flex justify-between !mt-5">
                    <h2 className="font-[450] text-2xl text-main-dark basis-1/3 truncate">
                        {order?.title}
                        <Status
                            status={order?.orderStatus?.toLowerCase()}
                            className={`!mt-2 transition-opacity ${!order?.status && "opacity-0"} ${
                                order?.orderStatus === "PENDING" && "flex items-center !gap-2"
                            }`}
                            preview
                        >
                            {order?.orderStatus === "PENDING" ? (
                                <>
                                    <ClockIcon /> Pending acceptance
                                </>
                            ) : (
                                capitalizeWords(order?.orderStatus)
                            )}
                        </Status>
                    </h2>

                    <div
                        className={`flex items-center self-end !gap-5 ${
                            orderLoading && "opacity-0"
                        }`}
                    >
                        {order?.status === "pending" && type === "inbound" ? (
                            <>
                                <button
                                    className="rounded-lg !py-2 !px-4 flex items-center gap-3 !bg-secondary text-[#FBFAFF] text-[15px]"
                                    onClick={() => acceptOrRejectOrder("accepted")}
                                    disabled={updateStatusLoading}
                                >
                                    Accept order{" "}
                                    {updateStatusLoading === "accepted" && (
                                        <Bars color="#fff" height={16} width={16} />
                                    )}
                                </button>
                                <span
                                    className="rounded-lg !py-2 !px-2 !bg-transparent text-[15px] cursor-pointer flex items-center gap-3"
                                    onClick={() => acceptOrRejectOrder("decline")}
                                    disabled={updateStatusLoading}
                                >
                                    Reject order{" "}
                                    {updateStatusLoading === "decline" && (
                                        <Bars color="#6f00ff" height={16} width={16} />
                                    )}
                                </span>
                            </>
                        ) : (
                            <>
                                <button
                                    className="rounded-lg !py-2 !px-4 !bg-secondary text-[#FBFAFF] text-[15px]"
                                    onClick={() => setUpdateStatusModal(true)}
                                >
                                    Update status
                                </button>
                                <MoreActions
                                    dropClassName="!top-[121%]"
                                    className="rotate-90 !size-[30px]"
                                    orderId={orderId}
                                    tab={type}
                                    updateOrder={(status) =>
                                        setOrder((prev) => ({ ...prev, orderStatus: status }))
                                    }
                                    status={order?.orderStatus?.toLowerCase()}
                                    disableCancel={
                                        order?.orderStatus === "CANCELLED" || type !== "outbound"
                                    }
                                    currency={order?.currency}
                                    wallets={wallets}
                                    totalAmount={order?.totalAmount}
                                />
                            </>
                        )}
                    </div>
                </div>

                <section className="grid grid-cols-2 !gap-6 !mt-6">
                    <div>
                        <p className="font-[460] text-sm text-main-grey !mb-2">
                            {typesTitle[type]}
                        </p>
                        <div className="bg-white rounded-lg flex items-center !px-3.5 !py-3">
                            <Avatar
                                fullName={bizInfo.bizName}
                                className={`!bg-[#F6F6F6] border-white ${
                                    bizInfo.bizName > 3 && "text-xs overflow-hidden"
                                }`}
                            />
                            <h3 className="font-[450] text-base text-main-dark">
                                {capitalizeWords(bizInfo.bizName)}
                                <span className="block text-main-grey font-normal text-xs mt-[1px]">
                                    {capitalizeFirstLetter(bizInfo.email)}
                                </span>
                            </h3>
                        </div>

                        <>
                            <p className={`font-[460] text-sm text-main-grey !mb-2 !mt-8`}>
                                Order details
                            </p>
                            <div className="bg-white rounded-lg items-center !p-4">
                                {[
                                    {
                                        key: "Order value",
                                        value: `${currencyPairCheck(
                                            order?.currency ?? ""
                                        )} ${formatMoney(order?.totalAmount ?? 0)}`,
                                    },
                                    { key: "Items", value: order?.orderItems?.length },
                                    {
                                        key: "Due date",
                                        value: dayjs(order?.deliveryDate).format(
                                            "Do of MMMM, YYYY"
                                        ),
                                    },
                                    {
                                        key: "Created on",
                                        value: dayjs(order?.createdAt).format("Do of MMMM, YYYY"),
                                    },
                                ].map(({ key, value }) => (
                                    <p
                                        className={`grid grid-cols-[172px_1fr] text-sm text-[#475467] w-full !mb-4`}
                                        key={key}
                                    >
                                        {key}
                                        <span className="font-medium text-main-dark">{value}</span>
                                    </p>
                                ))}

                                <div className="!border border-[#E7E9FB] rounded-lg">
                                    <div className="!p-5 border-b border-[#E7E9FB]">
                                        <p className="font-medium text-[#475467] text-sm">
                                            Split payment{" "}
                                            <span className="!ml-2 font-normal">
                                                ({order?.paymentOne}-{order?.paymentTwo})
                                            </span>
                                        </p>
                                        <div
                                            className="bg-[#EAECF0] rounded-[20px] h-2 !my-3.5 relative"
                                            ref={paymentPercentRef}
                                        >
                                            <div
                                                className="bg-[#11A811] absolute left-0 top-0 h-full rounded-[20px] transition-[width]"
                                                style={{
                                                    width:
                                                        percentPaid >= 100
                                                            ? "100%"
                                                            : (paymentPercentRef?.current
                                                                  ?.offsetWidth ?? 0) *
                                                                  `.${percentPaid ?? 0}` +
                                                              "px",
                                                }}
                                            />
                                            {/* {console.log(
                                            (paymentPercentRef?.current?.offsetWidth ?? 0) *
                                                `.${percentPaid}`,
                                            "tester",
                                            paymentPercentRef?.current?.offsetWidth,
                                            Math.min(+percentPaid, 100)
                                        )} */}
                                        </div>
                                        <p className="font-medium text-sm flex text-[#475467]">
                                            {currencyPairCheck(order?.currency)}
                                            {formatMoney(order?.paidAmount ?? 0)} /{" "}
                                            {formatMoney(order?.totalAmount ?? 0)}
                                            <span className="ml-auto font-normal text-main-grey">
                                                {isNaN(percentPaid) ? 0 : Math.min(+percentPaid, 0)}
                                                % complete
                                            </span>
                                        </p>
                                    </div>
                                    <div className="grid grid-cols-3 !p-5">
                                        {[
                                            {
                                                key: "Payment method",
                                                value: capitalizeWords(order?.paymentMethod),
                                            },
                                            {
                                                key: "Next payment due",
                                                value: ``,
                                            },
                                            {
                                                key: "Total paid",
                                                value: `${currencyPairCheck(
                                                    order?.currency
                                                )}${formatMoney(order?.paidAmount)}`,
                                            },
                                        ].map(({ key, value }) => (
                                            <p className="text-sm text-main-grey" key={key}>
                                                {key}
                                                <span className="block !mt-2 font-medium text-main-dark">
                                                    {value}
                                                </span>
                                            </p>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </>
                        <>
                            <div className="flex !mt-7 !gap-8 !px-6">
                                {["Order items", "Messages", "Documents"].map((tab, idx) => (
                                    <span
                                        className={`!pb-2 border-b-2 cursor-pointer transition-all duration-200 ${
                                            idx === 1 && "flex items-center !gap-2"
                                        } ${
                                            idx === tabIdx
                                                ? "font-[450] !border-secondary text-main-dark"
                                                : "!border-transparent text-main-grey"
                                        } ${idx === 1 && "!pointer-events-none !text-[#BCC2CC]"}`}
                                        key={tab}
                                        onClick={() => setTabIdx(idx)}
                                    >
                                        {tab}
                                        {/* {idx === 1 && (
                                        <span className="size-4 rounded-full bg-[#11A811] text-xs text-[#FCFCFD] grid place-items-center">
                                            4
                                        </span>
                                    )} */}
                                    </span>
                                ))}
                                <button
                                    className="flex !gap-2 text-sm !text-secondary items-center ml-auto -translate-y-[1px]"
                                    onClick={() => {
                                        setTabIdx(2);
                                        setAddDocModal(true);
                                    }}
                                >
                                    <DocumentUploadIcon className="size-5" /> Add document
                                </button>
                            </div>
                            {!tabIdx && (
                                <div className="bg-white rounded-lg !p-6 flex flex-col !gap-2">
                                    {order?.orderItems?.map(
                                        ({ itemName, quantity, pricePerUnit, ...rest }) => (
                                            <div
                                                className="!border rounded-lg justify-between !border-[#E7E9FB] !p-4 flex"
                                                key={itemName + pricePerUnit + quantity}
                                            >
                                                <p className="font-[450] text-main-dark">
                                                    {capitalizeWords(itemName)}
                                                    <span className="block font-normal text-xs text-main-grey mt-[1px]">
                                                        {currencyPairCheck(order.currency)}{" "}
                                                        {formatMoney(pricePerUnit)}
                                                    </span>
                                                </p>
                                                <p className="font-[450] text-main-dark text-right">
                                                    {currencyPairCheck(order.currency)}{" "}
                                                    {formatMoney(pricePerUnit * quantity)}
                                                    <span className="block font-normal text-xs text-main-grey mt-[1px]">
                                                        {quantity} unit{+quantity > 1 && "s"}
                                                    </span>
                                                </p>
                                            </div>
                                        )
                                    )}
                                </div>
                            )}

                            {tabIdx === 1 && <>Messages</>}
                            {tabIdx === 2 && (
                                <div className="bg-white rounded-lg !p-6 flex flex-col">
                                    {!order?.document?.length ? (
                                        <EmptyList message="No documents available" />
                                    ) : (
                                        <>
                                            {order.document.map((doc, idx) => (
                                                <span
                                                    className={`flex items-center !p-5 border-x border-b !border-[#E7E9FB] text-main-dark font-[450] ${
                                                        !idx && "border-t rounded-t-lg"
                                                    }`}
                                                >
                                                    <DocumentTextIcon className="size-6 !mr-2" />{" "}
                                                    {doc.name}{" "}
                                                    <span className="font-[350] text-main-grey ml-auto !mr-[22px]">
                                                        {doc?.ext}
                                                    </span>
                                                    <Arrow2Icon />
                                                </span>
                                            ))}
                                        </>
                                    )}
                                </div>
                            )}
                        </>
                    </div>
                    <div>
                        {!!activities?.length && (
                            <>
                                <p className="font-[460] text-sm text-main-grey !mb-2.5">
                                    Activity log
                                </p>

                                <div className="bg-white rounded-lg !px-3.5 !py-3 flex flex-col gap-y-2">
                                    {activities.map((activity) => (
                                        <div className="!gap-3.5 flex" key={activity._id}>
                                            <CheckCircleIcon className="*:fill-[#11A811]" />
                                            <h3 className="font-medium text-sm text-[#475467]">
                                                {activity.description}
                                                <span className="flex items-center text-main-grey font-normal text-xs mt-[1px]">
                                                    {false && (
                                                        <span className="!text-secondary">
                                                            Transaction #23098
                                                        </span>
                                                    )}
                                                    <span className="size-1 rounded-full bg-main-grey !mx-2" />{" "}
                                                    {dayjs(activity.createdAt).format(
                                                        "Do of MMMM, YYYY"
                                                    )}
                                                </span>
                                            </h3>
                                        </div>
                                    ))}
                                </div>
                            </>
                        )}
                    </div>
                </section>
            </div>
            {addDocModal && (
                <UploadDocModal
                    close={() => setAddDocModal(false)}
                    loading={uploadDocumentLoading}
                    uploadFunc={uploadDocument}
                />
            )}
            {updateStatusModal && (
                <UpdateStatusModal
                    close={(status) => {
                        setUpdateStatusModal(false);
                        typeof status === "string" &&
                            setOrder((prev) => ({ ...prev, orderStatus: status }));
                    }}
                    currentStatus={order.orderStatus}
                    orderId={orderId}
                />
            )}
        </>
    );
};

export default ManagePo;
