const ListEmpty = ({ img, title, text, className }) => (
    <div className={`my-[70px] grid place-items-center text-center ${className}`}>
        {img && <img src={img} alt="" />}
        <h4 className="text-main-dark font-bold text-lg leading-[24.3px] max-w-[350px] my-2.5">
            {title}
        </h4>
        {text && (
            <p className="max-w-[250px] text-main-grey text-sm leading-[19.6px] font-normal">
                {text}
            </p>
        )}
    </div>
);

export default ListEmpty;
