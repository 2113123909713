import Navbar from "component/layout/navbar";
import Procurement from "./procurement";
import Network from "./network";
import { useLocation, useNavigate } from "react-router-dom";
import FlexNav from "component/layout/flexNav";
import { useEffect, useRef } from "react";
import ManagePo from "./procurement/managePo";
import { Helmet } from "react-helmet";

const Flex = () => {
    const { pathname } = useLocation();
    const mainRef = useRef();
    const loadedPoDataRef = useRef({});
    const navigate = useNavigate();
    const routes = ["/flex/home", "/flex/home/network", "/flex/home/manage-po"];

    useEffect(() => {
        mainRef.current.scroll({ top: 0, behavior: "smooth" });
    });
    useEffect(() => {
        !routes.includes(pathname) && navigate("/flex/home");
    }, [pathname]);

    return (
        <>
            <Helmet>
                <title>Tradevu — flex</title>
            </Helmet>
            <div className="bg-[#F2F4F7] h-screen mb-2">
                <Navbar />
                <div className="flex h-[calc(100%-80px)]">
                    <aside className="max-sm:hidden bg-white !pl-6 sm:!pl-8 md:!pl-16 w-60 h-full !py-9 border-t">
                        <h2 className="text-main-grey text-base font-normal !mb-6">FLEX</h2>
                        <FlexNav pathname={pathname} />
                    </aside>
                    <main className="w-full overflow-y-auto" ref={mainRef}>
                        {pathname === "/flex/home" && (
                            <Procurement loadedPoDataRef={loadedPoDataRef} />
                        )}
                        {pathname === "/flex/home/network" && <Network />}
                        {pathname === "/flex/home/manage-po" && <ManagePo />}
                    </main>
                </div>
            </div>
        </>
    );
};

export default Flex;
