import { Helmet } from "react-helmet";

import { useEffect, useState } from "react";
import SerHeader from "../component/serviceheader";
import arrowleft from "../asset/images/arrowleftcircle.svg";
import edit from "../asset/images/edit-2.svg";
import bank from "../asset/images/bankicon.svg";
import InvoiceSuccess from "../component/bankmodal";
import AdvisingBank from "../component/advsingBank";
import ConfirmBank from "../component/confirmBank";

import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import Loader from "../component/loader";
import { Swal } from "sweetalert2/dist/sweetalert2";

const ChooseBank = () => {
  // const [selectedIndex, setselectedIndex] = useState(-1)
  const navigate = useNavigate();

  const [showModal3, setshowModal3] = useState(false);
  const [showModal4, setshowModal4] = useState(false);
  const { state } = useLocation();
  const [showModal5, setshowModal5] = useState(false);

  const [isdisable, setisdisable] = useState(true);
  const [selectedbank, setselectedbank] = useState([]);
  const [advisingbank, setadvisingbank] = useState("");
  const [confirmBank, setconfirmBank] = useState("");
  const [loader, setloader] = useState(false);
  const [banks, setbank] = useState([]);
  useEffect(() => {
    if (advisingbank !== "" && selectedbank.bankName !== undefined) {
      setisdisable(false);
    } else {
      setisdisable(true);
    }
  }, [advisingbank, selectedbank]);

  const handlerSubmit = async (e) => {
    e.preventDefault();
    setloader(true);
    const data = {
      issuingBank: {
        name: selectedbank.bankName,
      },
      advisingBank: {
        name: advisingbank.bankName,
      },
      confirmingBank: {
        name: confirmBank.bankName,
      },
    };
    console.log(data);
    await axios
      .post(`/v1/finance/letter_of_credit/addBank/${state.id}`, data)
      .then((res) => {
        console.log(res);
        navigate("/letter_of_credit/document", {
          state: {
            ...data,
            ...state,
          },
        });
      })
      .catch((e)=>{
        setloader(false)
        Swal.fire({
            title: "Error",
            text: e.response.data.message,
            icon: "error",
            confirmButtonText: "OK",
            width: "280px",
            confirmButtonColor: "rgb(111, 0, 255)",
          }).then((res) => {
            if (res.value) {
              setloader(false);
            }
          });
      });
  };
  useEffect(() => {
    const getBusinessId = async () => {
      await axios
        .get("/v1/wallets/get-banks")
        .then((res) => {
          setbank(res.data.data);
        })
        .catch((e) => {
          console.log(e);
        });
    };

    getBusinessId();
  }, []);

  return (
    <div className="h-100 w-100 ">
      <div className="" style={{
           maxWidth: "2000",
           width:'100%',
           margin: "0px auto",
      }}>
        <Helmet>
          <title>Letter of credit</title>
        </Helmet>

        <SerHeader header={"Letter of Credit"} width={57} />
        <InvoiceSuccess
          show={showModal3}
          setshow={setshowModal3}
          banks={banks}
          bank={true}
          selectedbank={selectedbank}
          setselectedbank={setselectedbank}
        />
        <AdvisingBank
          show={showModal4}
          setshow={setshowModal4}
          advisingBank={advisingbank}
          banks={banks}
          setadvisingbank={setadvisingbank}
        />
        <ConfirmBank
          show={showModal5}
          setshow={setshowModal5}
          banks={banks}
          selectedbank={confirmBank}
          setselectedbank={setconfirmBank}
        />
      </div>
      <div style={{ height: "100%" }}>
        <div
          className=" "
          style={{
            maxWidth: "2000",
            width:'100%',
            margin: "0px auto",
            paddingLeft: "30px",
            paddingRight: "30px",
            paddingTop: "10px",
            paddingBottom: "10px",
          }}
        >
          <div
            style={{
              height: "100%",
              width: "100%",
              display: "flex",

              justifyContent: "center",
            }}
          >
            <form
              onSubmit={handlerSubmit}
              className="form-general-layout mb-5"

            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "32px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "16px",
                  }}
                >
                  <div
                    onClick={() => {
                      navigate(-1);
                    }}
                  >
                    <img src={arrowleft} alt="" />
                  </div>
                  <div>
                    <div
                      style={{
                        color: "#101828",
                        fontWeight: "600",
                        fontSize: "24px",
                        lineHeight: "32.4px",
                      }}
                    >
                      Choose Banks
                    </div>
                    <span
                      style={{
                        color: "#667085",
                        fontSize: "16px",
                        lineHeight: "24px",
                        letterSpacing: "2%",
                        fontWeight: "600",
                      }}
                    >
                      Select your preferred banks to facilitate this request.
                    </span>
                  </div>
                  <div
                    className=""
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "16px",
                    }}
                  >
                    <div
                      style={{
                        background: "#fff",
                        borderRadius: "8px",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        padding: "16px 24px 16px 24px",
                        cursor: "pointer",
                      }}
                      onClick={() => setshowModal3(true)}
                    >
                      <span
                        style={{
                          color: "#344054",
                          lineHeight: "24px",
                          fontSize: "18px",
                          fontWeight: "600",
                          letterSpacing: "2%",
                        }}
                      >
                        Issuing Bank
                      </span>

                      {selectedbank.bankName !== undefined && (
                        <div
                          style={{
                            color: "#6F00FF",
                            fontWeight: "600",
                            lineHeight: "24px",
                            letterSpacing: "2%",
                            fontSize: "16px",
                            display: "flex",
                            gap: "8px",
                            alignItems: "center",
                          }}
                        >
                          {" "}
                          <img
                            src={bank}
                            alt=""
                            style={{
                              height: "20px",
                              width: "20px",
                            }}
                          />{" "}
                          {selectedbank.bankName}
                        </div>
                      )}
                      {selectedbank.bankName === undefined && (
                        <img src={edit} alt="" />
                      )}
                    </div>
                    <div
                      style={{
                        background: "#fff",
                        borderRadius: "8px",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        cursor: "pointer",
                        padding: "16px 24px 16px 24px",
                      }}
                      onClick={() => setshowModal4(true)}
                    >
                      <span
                        style={{
                          color: "#344054",
                          lineHeight: "24px",
                          fontSize: "18px",
                          fontWeight: "600",
                          letterSpacing: "2%",
                        }}
                      >
                        Advising Bank
                      </span>
                      {advisingbank.bankName !== undefined ? (
                        <div
                          style={{
                            color: "#6F00FF",
                            fontWeight: "600",
                            lineHeight: "24px",
                            letterSpacing: "2%",
                            fontSize: "16px",
                            display: "flex",
                            gap: "8px",
                            alignItems: "center",
                          }}
                        >
                          <img src={bank} alt="" height={20} width={20} />{" "}
                          {advisingbank.bankName}{" "}
                        </div>
                      ) : (
                        <div>
                          <img src={edit} alt="" />
                        </div>
                      )}{" "}
                    </div>
                    <div
                      style={{
                        background: "#fff",
                        borderRadius: "8px",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        cursor: "pointer",
                        padding: "16px 24px 16px 24px",
                      }}
                      onClick={() => setshowModal5(true)}
                    >
                      <span
                        style={{
                          color: "#344054",
                          lineHeight: "24px",
                          fontSize: "18px",
                          fontWeight: "600",
                          letterSpacing: "2%",
                        }}
                      >
                        Confirming Bank (Optional)
                      </span>
                      {confirmBank.length !== 0 ? (
                        <div
                          style={{
                            color: "#6F00FF",
                            fontWeight: "600",
                            lineHeight: "24px",
                            letterSpacing: "2%",
                            fontSize: "16px",
                            display: "flex",
                            gap: "8px",
                            alignItems: "center",
                          }}
                        >
                          <img src={bank} alt="" height={20} width={20} />{" "}
                          {confirmBank.bankName}{" "}
                        </div>
                      ) : (
                        <div>
                          <img src={edit} alt="" />
                        </div>
                      )}{" "}
                    </div>
                  </div>

                  <div className="mb-5">
                    <button
                      disabled={loader}
                      variant="primary"
                      style={{
                        maxWidth: "280px",
                        width: "100%",
                        background: isdisable ? "#EBE4FF" : "#6F00FF",
                        padding: "16px 24px 16px 24px",
                        border: "none",
                        outline: "none",
                        borderRadius: "8px",
                        fontSize: "16px",
                        lineHeight: "24px",
                        letterSpacing: "2%",
                        fontWeight: "600",
                        color: isdisable ? "#BFA6FF" : "#ffff",
                        justifyContent: "center",
                        alignItems: "center",
                        display: "flex",
                      }}
                    >
                      {loader ? <Loader /> : "Continue"}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ChooseBank;
