import { Helmet } from "react-helmet";

import { useEffect, useState } from "react";

import SerHeader from "../component/serviceheader";

import UploadModal from "../component/tenure";
import Uploaddocumentfinance from "../component/uploadloaddocument";
import InvoiceSuccess from "../component/invoicesuccess";
import Loader from "../component/loader";
import { useLocation } from "react-router-dom";

import "../style/slider.css";

import edit from "../asset/images/marksuccess.svg";
//import axios from "axios";
import ConfrimPin from "../component/confirmModal";
import axios from "axios";
import FailedTransaction from "../component/failedTransaction";
import currencyPairCheck from "../utilities/currencyPairCheck";
import AmountCheck from "../component/amountCheck";

const TransferSummary = () => {
    const { state } = useLocation();
    // console.log(state);

    const [filename, setfilename] = useState(null);
    const [filesize, setfilesize] = useState(0);
    const [aggreement, setagrrement] = useState(null);
    const [aggreementsize, setaggreementsize] = useState(0);
    const [showModal, setshowModal] = useState(false);
    const [showModal2, setshowModal2] = useState(false);
    const [showModal3, setshowModal3] = useState(false);
    const [showModal4, setshowModal4] = useState(false);
    const [title, settitle] = useState("");
    const [message, setmessage] = useState("");
    const [pincode, setpincode] = useState(["", "", "", ""]);

    const [tenureday, settenureday] = useState("0 Days");
    const [loader, setloader] = useState(false);
    const [isdisable] = useState(true);
    const [showpin, setshowpin] = useState(false);
    const [pins, setPins] = useState();
    const [tfees, settfees] = useState(0);

    const [tenure, settunre] = useState(["7 Days", "14 Days", "30 Days", "40 Days"]);
    useEffect(() => {
        const fetchTransferFess = async () => {
            await axios
                .get("/v1/wallets/transferFees")
                .then((res) => {
                    // console.log(res);
                    if (state.currency === "NGN") {
                        settfees(res.data.data.NGNTransferFee);
                    } else if (state.currency === "USD") {
                        settfees(res.data.data.USDTransferFee);
                    } else if (state.currency === "EUR") {
                        settfees(res.data.data.EURTransferFee);
                    } else if (state.currency === "USD") {
                        settfees(res.data.data.USDTransferFee);
                    }
                })
                .catch((e) => {
                    console.error(e);
                });
        };
        fetchTransferFess();
    }, []);

    const submitHandler = async (pinsss) => {
        const [pin1, pin2, pin3, pin4] = pinsss;

        const numericPin = pin1 + "" + pin2 + "" + pin3 + "" + pin4;

        setloader(true);
        setshowpin(true);
        const pinsdata = {
            pin: numericPin,
        };

        console.log(state);
        
        const data = {
            bankname: state.bankName,
            accountname: state.accountName,
            accountnumber: state.accountNumber,
            accountcode: state.bankCode,
            // currency: state.currency,
            amount: state.amount,
            naration: state.naration,
            sesid: state.sesid,
        };
        // console.log(data)
        await axios
            .post("/v1/users/verify-pin", pinsdata)
            .then(() => {
                if (state.type === "InlineUsers") {
                    axios
                        .post(`/v1/wallets/transfer_to_wallet/${state.business}`, {
                            currency: state.currency,
                            amount: state.amount,
                            naration: "dibd",
                        })
                        .then(() => {
                            setshowpin(false);
                            setshowModal3(true);
                        })
                        .catch((e) => {
                            console.log(e);
                            setloader(false);
                            setmessage(e.response.data.message);
                            setshowModal4(true);
                            settitle(e.response.data.message);
                            setloader(false);
                            setshowpin(false);
                            setpincode(["", "", "", ""]);
                        });
                } else {
                    if (state.currency === "NGN") {
                        axios
                            .post("/v1/wallets/transfer", data)
                            .then(() => {
                                setshowpin(false);
                                setshowModal3(true);
                            })
                            .catch((e) => {
                                //console.log(e);
                                setloader(false);
                                setmessage(e.response.data.message);
                                setshowModal4(true);
                                settitle("Your wallet is low");
                                setloader(false);
                                setshowpin(false);
                                setpincode(["", "", "", ""]);
                            });
                    } else {
                        axios
                            .post("/v1/wallets/transfer_request", { ...state })
                            .then(() => {
                                setshowpin(false);
                                setshowModal3(true);
                            })
                            .catch((e) => {
                                // console.log(e);
                                setloader(false);
                                setmessage(
                                    e.response?.data ? e.response?.data.message : "An error occur"
                                );
                                setshowModal4(true);
                                settitle(
                                    e.response?.data ? e.response?.data.message : "An error occur"
                                );
                                setloader(false);
                                setshowpin(false);
                                setpincode(["", "", "", ""]);
                            });
                    }
                }
            })
            .catch((e) => {
                setshowModal4(true);
                setmessage("Invalid pin");
                setshowpin(false);
                settitle("You have entered an invalid");
                setloader(false);
                setpincode(["", "", "", ""]);
            });
    };

    return (
        <div className="h-100 w-100 ">
            <div
                className=""
                style={{
                    maxWidth: "2000px",
                    width: "100%",
                    margin: "0px auto",
                }}
            >
                <Helmet>
                    <title>Local transfer</title>
                </Helmet>

                <SerHeader header={"Local Transfers"} width={100} />
                <InvoiceSuccess
                    method={"transfer"}
                    show={showModal3}
                    setshow={setshowModal3}
                    set={"payment"}
                    subject={"Funds sent"}
                    title={`You sent${" "}${currencyPairCheck(state.currency)}${AmountCheck(
                        state.amount
                    )} to ${state.currency === "NGN" ? state.accountName : state.AccountName}`}
                />
                <FailedTransaction
                    show={showModal4}
                    setshow={setshowModal4}
                    subject={message}
                    submitHandler={submitHandler}
                    title={title}
                />
                <ConfrimPin
                    show={showpin}
                    setshow={setshowpin}
                    pins={pins}
                    pincode={pincode}
                    setPins={setPins}
                    sendRequest={submitHandler}
                    loader={loader}
                />
                <Uploaddocumentfinance
                    show={showModal2}
                    setshow={setshowModal2}
                    title={"Letter of credit"}
                    text={
                        "This should clearly show the details of your transaction with your supplier"
                    }
                    filename={aggreement}
                    filesize={aggreementsize}
                    setfilesize={setaggreementsize}
                    setfilename={setagrrement}
                />
                <UploadModal
                    filesize={filesize}
                    show={showModal}
                    tenure={tenure}
                    tenureday={tenureday}
                    settenureday={settenureday}
                    settunre={settunre}
                    setshow={setshowModal}
                    filename={filename}
                    setfilesize={setfilesize}
                    setfilename={setfilename}
                />
            </div>
            <div style={{ height: "100%" }}>
                <div
                    className=" "
                    style={{
                        maxWidth: "2000px",
                        width: "100%",
                        margin: "0px auto",
                        paddingLeft: "30px",
                        paddingRight: "30px",
                        paddingTop: "10px",
                        paddingBottom: "10px",
                    }}
                >
                    <div
                        style={{
                            height: "100%",
                            width: "100%",
                            display: "flex",

                            justifyContent: "center",
                        }}
                    >
                        <form className="form-general-layout">
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: "32px",
                                    maxWidth: "523px",
                                }}
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        gap: "8px",
                                    }}
                                >
                                    <div
                                        style={{
                                            color: "#101828",
                                            fontWeight: "600",
                                            fontSize: "24px",
                                            lineHeight: "32.4px",
                                        }}
                                    >
                                        Summary
                                    </div>
                                    <span
                                        style={{
                                            color: "#667085",
                                            fontWeight: "400",
                                            lineHeight: "24px",
                                            fontSize: "1rem",
                                            letterSpacing: "2%",
                                        }}
                                    >
                                        Please review your details before you continue
                                    </span>
                                </div>
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        gap: "32px",
                                    }}
                                >
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            gap: "8px",
                                        }}
                                    >
                                        <label
                                            htmlFor=""
                                            className="mysummarytitle"
                                            style={{
                                                fontWeight: "500",
                                            }}
                                        >
                                            Transaction Details
                                        </label>
                                        <div
                                            style={{
                                                padding: "16px",
                                                borderRadius: "8px",
                                                background: "#fff",
                                                display: "flex",
                                                flexDirection: "column",
                                                gap: "16px",
                                            }}
                                        >
                                            <div
                                                style={{
                                                    display: "flex",
                                                    gap: "8px",
                                                    alignItems: "center",
                                                    justifyContent: "space-between",
                                                    height: "24px",
                                                }}
                                            >
                                                <span
                                                    className="title-re-lg"
                                                    style={{
                                                        fontWeight: "400",
                                                    }}
                                                >
                                                    Recipient receives
                                                </span>
                                                <span className="mytitle-answer">
                                                    {currencyPairCheck(state.currency)}

                                                    {AmountCheck(state.amount)}
                                                </span>
                                            </div>
                                            <div
                                                style={{
                                                    display: "flex",
                                                    gap: "8px",
                                                    alignItems: "center",
                                                    justifyContent: "space-between",
                                                    height: "24px",
                                                }}
                                            >
                                                <span
                                                    className="title-re-lg"
                                                    style={{ fontWeight: "400" }}
                                                >
                                                    Transfer fee
                                                </span>
                                                <span className="mytitle-answer">
                                                    {state.currency === "NGN"
                                                        ? "₦"
                                                        : state.currency === "EUR"
                                                        ? "€"
                                                        : state.currency === "USD"
                                                        ? "$"
                                                        : "£"}
                                                    {!state?.type && (
                                                        <>
                                                            {state.currency === "NGN"
                                                                ? parseFloat(tfees).toLocaleString()
                                                                : parseFloat(
                                                                      parseFloat(tfees) *
                                                                          parseFloat(
                                                                              state.amount
                                                                          ).toLocaleString()
                                                                  )}
                                                        </>
                                                    )}
                                                    {state?.type === "InlineUsers" && (
                                                        <>
                                                            {parseFloat(
                                                                parseFloat(0)
                                                                    .toLocaleString()
                                                                    .replace(/,/, " ")
                                                            )}
                                                        </>
                                                    )}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            gap: "8px",
                                        }}
                                    >
                                        <label
                                            htmlFor=""
                                            className="mysummarytitle"
                                            style={{
                                                fontWeight: "400",
                                            }}
                                        >
                                            Recipient’s Details
                                        </label>
                                        <div
                                            className="white-bh-lg"
                                            style={{
                                                minHeight: "56px",
                                            }}
                                        >
                                            <div className="transfer-bank-logo">
                                                <div className="intial">
                                                    {state.currency === "NGN"
                                                        ? state.accountName
                                                              .match(/(\b\S)?/g)
                                                              .join("")
                                                              .match(/(^\S|\S$)?/g)
                                                              .join("")
                                                              .toUpperCase()
                                                        : state.AccountName.match(/(\b\S)?/g)
                                                              .join("")
                                                              .match(/(^\S|\S$)?/g)
                                                              .join("")
                                                              .toUpperCase()}
                                                </div>
                                                <div className="name-bank">
                                                    <span
                                                        className="name-link"
                                                        style={{
                                                            fontWeight: "500",
                                                        }}
                                                    >
                                                        {state.currency === "NGN"
                                                            ? state.accountName
                                                            : state.AccountName}
                                                    </span>
                                                    <span
                                                        className="bank-link"
                                                        style={{
                                                            fontWeight: "400",
                                                        }}
                                                    >
                                                        {state.currency === "NGN"
                                                            ? state.bankName
                                                            : state.BankName}{" "}
                                                        .{" "}
                                                        {state.currency === "NGN"
                                                            ? state.accountNumber
                                                            : state.AccountNumber}
                                                    </span>
                                                </div>
                                            </div>
                                            <img src={edit} alt="" />
                                        </div>
                                    </div>

                                    <div className="">
                                        <button
                                            type="button"
                                            onClick={() => setshowpin(true)}
                                            disabled={loader}
                                            style={{
                                                width: "100%",
                                                background: loader ? "#EBE4FF" : "#6F00FF",
                                                padding: "16px 24px 16px 24px",
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                border: "none",
                                                outline: "none",
                                                minHeight: "46px",
                                                borderRadius: "8px",
                                                fontSize: "16px",
                                                lineHeight: "24px",
                                                letterSpacing: "2%",
                                                fontWeight: "600",
                                                color: loader ? "#BFA6FF" : "#fff",
                                            }}
                                        >
                                            {loader === true ? (
                                                <Loader data={loader} />
                                            ) : (
                                                "Continue"
                                            )}
                                            {loader === false && (
                                                <>
                                                    {" "}
                                                    {currencyPairCheck(state.currency)}
                                                    {AmountCheck(state.amount)}
                                                </>
                                            )}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TransferSummary;
