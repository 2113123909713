import world from "../asset/images/worldtradevu.svg";
import info from "../asset/images/info-circle.svg";
import logotr from "../asset/images/tr.svg";

import { Link, useNavigate } from "react-router-dom";
import "../style/index.css";

import { Helmet } from "react-helmet";
import { useEffect, useState } from "react";
import axios from "axios";
import Swal from "sweetalert2/dist/sweetalert2.js";
import Loaders from "../component/loader";
import { useDispatch, useSelector } from "react-redux";
import { LOGOUT_USER } from "../store/action";
const Registration = () => {
  const [showEmailLabel, setShowlabelEmail] = useState(false);
  const [showPasswordLabel, setShowlabelPassword] = useState(false);
  const [showdefaultcoloremail, setshowdefaultcolor] = useState(false);
  const [email, setemail] = useState("");
  const [password, setpassword] = useState("");
  const [loader, setLoader] = useState(false);
  const [isNumber, setisNumber] = useState(false);
  const [isUppercase, setisuppercase] = useState(false);
  const [isLowercase, setislowercase] = useState(false);
  const [isupTo8, setIsupto8] = useState(false);
  const [isSpecialCharacter, setIssepecialCharactes] = useState(false);
  const selector = useSelector((state) => state.users);
  const selectorbusiness = useSelector((state) => state.business);

  const [message, setmessage] = useState("");
  const [error, seterror] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        seterror(false);
      }, 3000);
    }
  });
  const navigate = useNavigate();
  const SubmitHandler = async (e) => {
    setLoader(true);
    e.preventDefault();
    if (
      isLowercase &&
      isUppercase &&
      isNumber &&
      isSpecialCharacter &&
      isupTo8
    ) {
      const data = {
        email: email,
      };
      if (selector?.length !== 0 || selectorbusiness?.length !== 0) {
        LOGOUT_USER("", dispatch);
      }
      await axios
        .post(`/v1/users/check-email`, data)
        .then((res) => {
          seterror(true);
          setLoader(false);
          setmessage("Email already exists");
        })
        .catch((e) => {
          console.log(e);

          if (e.response?.data.statusCode === 400) {
            navigate("/register/details", {
              state: {
                email: email,
                password: password,
              },
            });
          } else {
            seterror(true);
            setLoader(false);

            setmessage("Couldnt check email, an error occur");
          }
        });
    } else {
      setLoader(false);

      setmessage(message);
    }
    // console.log(email, password)
  };
  const checkPassword = (e) => {
    const password = e.target.value;
    let specialCharRegex = /[!@#$%^&*\-><|!)(#+_`~]/;

    // let uppercase = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
    // let lowercase = 'abcdefghijklmnopqrstuvwxyz'

    // let special_character = /[`!@#$%^&*()_+/-=[\]{};]/;
    if (password.match(/[A-Z]/) !== null) {
      setisuppercase(true);
    } else {
      setLoader(false);

      setisuppercase(false);
      setmessage("password must contain Uppercase");
    }
    if (password.match(/[a-z]/) !== null) {
      setislowercase(true);
    } else {
      setLoader(false);

      setislowercase(false);
      setmessage("password must contain Lowercase");
    }
    if (password.match(/[0-9]/) !== null) {
      setisNumber(true);
    } else {
      setLoader(false);

      setisNumber(false);
      setmessage("password must contain Numbers");
    }
    if (specialCharRegex.test(password)) {
      setIssepecialCharactes(true);
    } else {
      setLoader(false);

      setIssepecialCharactes(false);
      setmessage("password must contain Special characters");
    }
    if (password.length >= 8) {
      setIsupto8(true);
    } else {
      setLoader(false);

      setIsupto8(false);
      setmessage("password must upto 8");
    }
  };
  /*
    const changeEmailValue = (e) => {
        setEmail(e.target.value)
        setonchangeemail(true)
    }*/

  return (
    <div className="main-login">
      <Helmet>
        {" "}
        <title>Tradevu — register</title>
      </Helmet>
      <div
        className="containers-lo"
        style={{
          height: "100%",
          position: "relative",
        }}
      >
        <header
          style={{
            height: "76px",
          }}
        >
          <nav
            style={{
              justifyContent: "flex-start",
              alignItems: "center",
              display: "flex",
            }}
          >
            <img src={logotr} alt="" style={{ marginLeft: "40px" }} />
          </nav>
        </header>
        <div className="world-containers">
          {error && (
            <div
              style={{
                maxWidth: "624px",
                padding: "16px",
                borderRadius: "8px",
                border: "1px solid #FDA29B",
                display: "flex",
                gap: "12px",
                alignItems: "center",
                marginLeft: "35px",
                background: "#FFFBFA",
              }}
            >
              <div
                style={{
                  padding: "4px 10px 4px 4px",
                  borderRadius: "16px",
                  display: "flex",
                  gap: "12px",
                  mixBlendMode: "multiply",
                  background: "#FEF3F2",
                }}
              >
                <div
                  style={{
                    padding: "2px 10px 2px 10px",
                    borderRadius: "16px",
                    color: " #B42318",
                    background: "#FFFFFF",
                    fontWeight: "600",
                    fontSize: "14px",
                  }}
                >
                  Error
                </div>
                <div
                  style={{
                    padding: "2px 10px 2px 10px",
                    borderRadius: "16px",
                    color: " #B42318",
                    fontWeight: "600",
                    fontSize: "14px",
                  }}
                >
                  {message}
                </div>
              </div>
            </div>
          )}
          <div
            className="la-card"
            style={{
              zIndex: "9999",
              marginLeft: "40px",
              marginTop: "20px",
              position: "relative",
            }}
          >
            <form className="form-con" onSubmit={SubmitHandler}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "16px",
                }}
              >
                <h1
                  className="text-welcome"
                  style={{
                    padding: "0px",
                    margin: "0px",
                    letterSpacing: "0px",
                  }}
                >
                  You only need one supply chain platform.
                </h1>
                <span
                  className="text-lgs"
                  style={{
                    zIndex: "999",
                    fontSize: "16px",
                    fontWeight: "500",
                    marginTop: "0px",
                  }}
                >
                  Manage the finance and operations stack of your supply chain
                  with Tradevu.
                </span>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "16px",
                }}
              >
                <div className="input-form">
                  <div
                    className="each-form-input"
                  >
                    {showEmailLabel && (
                      <label
                        htmlFor="Email"
                        style={{
                          color: showEmailLabel
                            ? "#6F00FF "
                            : showdefaultcoloremail
                            ? "#667085"
                            : "#667085",
                        }}
                      >
                        Email Address
                      </label>
                    )}

                    <input
                      type="email"
                      style={{
                        border: "1px solid inherit",
                      }}
                      placeholder="Email Address"
                      value={email}
                      onBlur={() => {
                        if (email !== "") {
                          setshowdefaultcolor(true);
                          //setShowlabelEmail(false);
                        } else {
                          setshowdefaultcolor(false);
                          setShowlabelEmail(false);
                        }
                      }}
                      onFocus={() => setShowlabelEmail(true)}
                      onChange={(e) => {
                        setemail(e.target.value);
                      }}
                    />
                  </div>
                  <div
                    className="each-form-input"
                    
                  >
                    {showPasswordLabel && (
                      <label
                        htmlFor="Email"
                        style={{
                          color: showPasswordLabel ? "#6F00FF " : "#667085",
                        }}
                      >
                        Password
                      </label>
                    )}
                    <input
                      style={{
                        background: "inherit",
                        border: "1px solid inherit",
                      }}
                      type="password"
                      placeholder="Password"
                      value={password}
                      onFocus={() => setShowlabelPassword(true)}
                      onKeyUp={(e) => {
                        checkPassword(e);
                        setShowlabelPassword(true);
                      }}
                      onChange={(e) => {
                        setpassword(e.target.value);
                      }}
                      autoComplete="off"
                    />
                  </div>

                  <div
                    className=""
                    style={{
                      display: showPasswordLabel ? "flex" : "none ",
                      gap: "8px",
                    }}
                  >
                    <img
                      src={info}
                      alt=""
                      style={{
                        height: "16px",
                        width: "16px",
                      }}
                    />
                    <div
                      className="d-flex"
                      style={{
                        gap: "8px",
                      }}
                    >
                      {" "}
                      {isLowercase && (
                        <div
                          className="align-items-center d-flex"
                          style={{
                            background: "#A173FF",
                            fontSize: "10px",
                            borderRadius: "10px",
                            color: "#FCFCFD",
                            padding: "2px 8px 2px 8px",
                          }}
                        >
                          <span
                            className="material-icons"
                            style={{ fontSize: "12px" }}
                          >
                            done
                          </span>
                          <span className="sting-validate">Lowercase</span>
                        </div>
                      )}
                      {isLowercase === false && (
                        <div
                          className="align-items-center d-flex"
                          style={{
                            background: "#ECEFF3",
                            fontSize: "10px",
                            borderRadius: "10px",
                            color: "#344054",
                            padding: "2px 8px 2px 8px",
                          }}
                        >
                          <span className="sting-validate">Lowercase</span>
                        </div>
                      )}
                      {isUppercase && (
                        <div
                          className="align-items-center d-flex"
                          style={{
                            background: "#A173FF",
                            fontSize: "10px",
                            borderRadius: "10px",
                            color: "#FCFCFD",
                            padding: "2px 8px 2px 8px",
                          }}
                        >
                          <span
                            className="material-icons"
                            style={{ fontSize: "12px" }}
                          >
                            done
                          </span>
                          <span className="sting-validate">Uppercase</span>
                        </div>
                      )}
                      {isUppercase === false && (
                        <div
                          className="align-items-center d-flex"
                          style={{
                            background: "#ECEFF3",
                            fontSize: "10px",
                            borderRadius: "10px",
                            color: "#344054",
                            padding: "2px 8px 2px 8px",
                          }}
                        >
                          <span className="sting-validate">Uppercase</span>
                        </div>
                      )}
                      {isNumber && (
                        <div
                          className="align-items-center d-flex"
                          style={{
                            background: "#A173FF",
                            fontSize: "10px",
                            borderRadius: "10px",
                            color: "#FCFCFD",
                            padding: "2px 8px 2px 8px",
                          }}
                        >
                          <span
                            className="material-icons"
                            style={{ fontSize: "12px" }}
                          >
                            done
                          </span>
                          <span className="sting-validate">Number</span>
                        </div>
                      )}
                      {isNumber === false && (
                        <div
                          className="align-items-center d-flex"
                          style={{
                            background: "#ECEFF3",
                            fontSize: "10px",
                            borderRadius: "10px",
                            color: "#344054",
                            padding: "2px 8px 2px 8px",
                          }}
                        >
                          <span className="sting-validate">Number</span>
                        </div>
                      )}
                      {isSpecialCharacter && (
                        <div
                          className="align-items-center d-flex"
                          style={{
                            background: "#A173FF",
                            fontSize: "10px",
                            borderRadius: "10px",
                            color: "#FCFCFD",
                            padding: "2px 8px 2px 8px",
                          }}
                        >
                          <span
                            className="material-icons"
                            style={{ fontSize: "12px" }}
                          >
                            done
                          </span>
                          <span className="sting-validate">
                            Special character
                          </span>
                        </div>
                      )}
                      {isSpecialCharacter === false && (
                        <div
                          className="align-items-center d-flex"
                          style={{
                            background: "#ECEFF3",
                            fontSize: "10px",
                            borderRadius: "10px",
                            color: "#344054",
                            padding: "2px 8px 2px 8px",
                          }}
                        >
                          <span className="sting-validate">
                            Special characters
                          </span>
                        </div>
                      )}
                      {isupTo8 && (
                        <div
                          className="align-items-center d-flex"
                          style={{
                            background: "#A173FF",
                            fontSize: "10px",
                            borderRadius: "10px",
                            color: "#FCFCFD",
                            padding: "2px 8px 2px 8px",
                          }}
                        >
                          <span
                            className="material-icons"
                            style={{ fontSize: "12px" }}
                          >
                            done
                          </span>
                          <span className="sting-validate">8 characters</span>
                        </div>
                      )}
                      {isupTo8 === false && (
                        <div
                          className="align-items-center d-flex"
                          style={{
                            background: "#ECEFF3",
                            fontSize: "10px",
                            borderRadius: "10px",
                            color: "#344054",
                            padding: "2px 8px 2px 8px",
                          }}
                        >
                          <span className="sting-validate">8 Characters</span>
                        </div>
                      )}{" "}
                    </div>
                  </div>
                </div>
                <div className="confirm_term">
                  By signing up, I agree to Tradevu’s{" "}
                  <Link to={"/"} className="tems-privacy">
                    Terms of Service
                  </Link>{" "}
                  and{" "}
                  <Link to={"/"} className="tems-privacy">
                    Privacy Policy
                  </Link>
                </div>
              </div>
              <div className="lgn-btn-lg">
                <button
                  className="login-btn"
                  type="submit"
                  disabled={loader}
                  style={{
                    minWidth: "151px",
                    fontWeight: "700",
                  }}
                >
                  {loader ? <Loaders /> : "Sign up"}
                </button>
                <span className="dont-have">
                  I already have an account, <Link to={"/"}> Sign In</Link>{" "}
                </span>
              </div>
            </form>
          </div>
          <img
            src={world}
            alt=""
            className="world"
            style={{
              zIndex: "0",
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Registration;
