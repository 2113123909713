import { useEffect, useState, useRef } from "react";
import ArrowBack from "../component/arrowback";
import LoginHeader from "../component/loginheader";

import Example from "../component/bluecontinue";
//import { useSelector } from "react-redux";

const SetPin = () => {
  //const selector = useSelector((state) => state.users);
  const [pins, setPins] = useState(["", "", "", ""]);

  const inputs = useRef([]);

  useEffect(() => {
    inputs.current[0].focus();
  }, []);

  const handleInputChange = (event, index) => {
    const currentInput = event.target.value.trim();

    if (/^\d$/.test(currentInput)) {
      const newPins = [...pins];
      newPins[index] = currentInput;

      setPins(newPins);

      if (index < inputs.current.length - 1 && currentInput) {
        inputs.current[index + 1].focus();
      }
    }
  };

  const handleInputBackspace = (event, index) => {
    const currentInput = event.target.value.trim();
    console.log(currentInput);

    if (event.key === "Backspace" && !currentInput && index > 0) {
      const newPins = [...pins];
      newPins[index - 1] = "";

      setPins(newPins);
      inputs.current[index - 1].focus();
    }
  };
  return (
    <div
      className=""
      style={{
        background: "#F6F8FA",
        paddingRight: "0px",
        maxWidth: "2000px",
        width: "100%",
        margin: "0px auto",
      }}
    >
      <LoginHeader width={105} />
      <div
        className="p-2 justify-self-center align-items-center d-flex"
        style={{ height: "calc(100% - 200px)" }}
      >
        <div
          className="form-general-layout"
          style={{
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
          }}
        >
          <div className="card-body">
            <ArrowBack />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "4px",
              }}
            >
              <div
                style={{
                  fontWeight: "600",
                  color: "#101828",
                  fontSize: "32px",
                  lineHeight: "40px",
                }}
                className="mt-3 "
              >
                Create your PIN
              </div>
              <div
                style={{
                  fontSize: "16px",
                  color: "#667085",
                  letterSpacing: "2%",
                  lineHeight: "24px",
                  fontWeight: "500",
                }}
              >
                Enter the 4 digits pin
              </div>
            </div>

            <form
              action=""
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "32px",
              }}
            >
              <div
                className="row mt-4"
                style={{
                  maxWidth: "350px",
                  width: "100%",
                }}
              >
                {pins.map((pin, index) => (
                  <div className="col" key={index}>
                    <div className="form-group">
                      <input
                        key={index}
                        type="tel"
                        className="form-control border-0"
                        style={{
                          background: "#ECEFF3",
                          color: "#667085",
                          padding: "8px 16px 8px 16px",
                          textAlign: "center",
                          height: "53px",
                          minWidth: "56px",
                          fontWeight: "500",
                        }}
                        placeholder="-"
                        maxLength="1"
                        value={pin}
                        required
                        ref={(element) => (inputs.current[index] = element)}
                        onChange={(event) => handleInputChange(event, index)}
                        onKeyDown={(event) =>
                          handleInputBackspace(event, index)
                        }
                      />
                    </div>
                  </div>
                ))}{" "}
              </div>

              <Example code={pins} />
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SetPin;
