import {Helmet} from "react-helmet";
// import DashHeader from "../component/dashheader";
// import homefinance from '../asset/im
import {useEffect, useState} from 'react';

import SerHeader from "../component/serviceheader";
import arrowleft from '../asset/images/arrowleftcircle.svg'
import edit from '../asset/images/edit-2.svg'
import person from '../asset/images/person.svg'
import UploadInvoice from "../component/uploadinvoice";

import UploadModal from "../component/tenure";
import Uploaddocumentfinance from "../component/uploadloaddocument";
import InvoiceSuccess from "../component/invoicesuccess";
import {useNavigate} from "react-router-dom";


const CustomerDetails = () => { // const [selectedIndex, setselectedIndex] = useState(-1)
    const navigate = useNavigate()
    
    const [show, setShow] = useState(false);
    const [selecteditem, setselecteditem] = useState([])
    const [filename, setfilename] = useState(null)
    const [filesize, setfilesize] = useState(0)
    const [aggreement, setagrrement] = useState(null)
    const [aggreementsize, setaggreementsize] = useState(0)
    const [showModal, setshowModal] = useState(false)
    const [showModal2, setshowModal2] = useState(false)
    const [showModal3, setshowModal3] = useState(false)
    const [tenureday, settenureday] = useState('0 Days')
const [businessname, setbusinessname] = useState("")
    const [isdisable, setisdisable] = useState(true)
    const [tenure, settunre] = useState(['7 Days', "14 Days", '30 Days', "40 Days"])
console.log(businessname)

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true)
    
    const submitHandler = (e) => {
        e.preventDefault();
        navigate('/trade_of_credit/summary')

    }
    useEffect(() => {
        if ( selecteditem.length > 0) {
            setisdisable(false)
        } else {
            setisdisable(true)
        }
    }, [
        setisdisable,
       
        selecteditem,
        
    ])
    return (<div className="h-100 w-100 ">
        <div className="" style={{
            maxWidth:'2000px',
            width:'100%',
            margin:'0px auto'
          }}>
            <Helmet>
                <title>Working Capital</title>
            </Helmet>

            <SerHeader header={'Working Capital'}
                width={70}/>
            <InvoiceSuccess show={showModal3}
                setshow={setshowModal3}/>
            <Uploaddocumentfinance show={showModal2}
                setshow={setshowModal2}
                title={'Letter of credit'}
                text={'This should clearly show the details of your transaction with your supplier'}
                filename={aggreement}
                filesize={aggreementsize}
                setfilesize={setaggreementsize}
                setfilename={setagrrement}/>
            <UploadModal filesize={filesize}
                show={showModal}
                tenure={tenure}
                tenureday={tenureday}
                settenureday={settenureday}

                settunre={settunre}
                setshow={setshowModal}
                filename={filename}
                setfilesize={setfilesize}
                setfilename={setfilename}/>
        </div>
        <div style={
            {height: '100%'}
        }>
            <div className="conatiner "
                style={
                    {
                        maxWidth: "2000px",
                        width:'100%',
                        paddingLeft: "30px",
                        paddingRight: "30px",
                        paddingTop: "10px",
                        paddingBottom: "10px",
                        margin:'0px auto'
                    }
            }>

                <div style={
                    {
                        height: '100%',
                        width: '100%',
                        display: 'flex',

                        justifyContent: 'center'

                    }
                }>
                    <UploadInvoice show={show}
                        handle={handleClose}
                        selecteditem={selecteditem}
                        setbusinessname={setbusinessname}
                        setselecteditem={setselecteditem}/>
                    <form onSubmit={submitHandler}
                                     className="form-general-layout"
                                     >
                        <div style={
                            {
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '32px'

                            }
                        }>
                            <div style={
                                {
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: '16px'
                                }
                            }>
                                <div onClick={
                                    ()=>{
                                        navigate(-1)
                                    }
                                }>
                                    <img src={arrowleft}
                                        alt=""/>
                                </div>
                                <div>
                                    <div style={
                                        {
                                            color: '#101828',
                                            fontWeight: '600',
                                            fontSize: '24px',
                                            lineHeight: '32.4px'
                                        }
                                    }>
                                        Customer's details
                                    </div>
                                    <div style={
                                        {
                                            color: '#667085',
                                            fontSize: '16px',
                                            lineHeight: '24px',
                                            letterSpacing: '2%',
                                            fontWeight: '500',
                                            width: '100%'
                                        }
                                    }>
                                     Who are you transacting with?
                                    </div>
                                </div>


                                <div className=""
                                    style={
                                        {
                                            display: 'flex',
                                            gap: '8px',
                                            flexDirection: 'column'
                                        }
                                }>
                                   
                                    {
                                    selecteditem.length === 0 && <div style={
                                            {
                                                background: '#fff',
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                padding: '8px',
                                                borderRadius: '8px',
                                                alignItems: 'center',
                                                minHeight:'56px'
                                            }
                                        }
                                        variant="primary"
                                        onClick={handleShow}>
                                        <div style={
                                            {
                                                display: 'flex',
                                                gap: '8px',
                                                alignItems:'center'
                                            }
                                        }>
                                            <img src={person}
                                                alt=""/>
                                            <span style={
                                                {
                                                    color: '#101828',
                                                    letterSpacing: '2%',
                                                    lineHeight: '24px',
                                                    fontSize: '16px',
                                                    fontWeight: 500
                                                }
                                            }>Enter customer's details</span>

                                        </div>
                                        <img src={edit}
                                            alt=""/>

                                    </div>
                                }
                                    {
                                    selecteditem.length > 0 && selecteditem.map((items, index) => (<div style={
                                            {
                                                background: '#fff',
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                padding: '8px',
                                                borderRadius: '8px',
                                                alignItems: 'center'
                                            }
                                        }
                                        key={index}
                                        variant="primary"
                                        onClick={handleShow}>
                                        <div style={
                                            {
                                                display: 'flex',
                                                gap: '8px'
                                            }
                                        }>
                                            <div style={
                                                {
                                                    width: '40px',
                                                    height: '40px',
                                                    borderRadius: '250px',
                                                    alignItems: 'center',
                                                    justifyContent: 'ceneter',
                                                    background: '#F4F0FF',
                                                    textAlign: 'center',
                                                    display: 'flex'
                                                }
                                            }>
                                                <div style={
                                                    {
                                                        color: '#7F56D9',
                                                        fontWeight: '600',
                                                        fontSize: '14px',
                                                        lineHeight: '20px',
                                                        textAlign: 'center',
                                                        textTransform: 'uppercase',
                                                        width: '100%'

                                                    }
                                                }> {
                                                    items.firstName.charAt(0)
                                                }
                                                    {
                                                    items.lastName.charAt(0)
                                                } </div>
                                            </div>
                                            <div style={
                                                {
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                }
                                            }>
                                                <span style={
                                                    {
                                                        color: '#101828',
                                                        letterSpacing: '2%',
                                                        lineHeight: '18px',
                                                        fontSize: '16px',
                                                        fontWeight: 500
                                                    }
                                                }> {
                                                    items.firstName
                                                }
                                                    {
                                                    items.lastName
                                                } </span>
                                                <span style={
                                                    {
                                                        color: '#667085',
                                                        fontSize: '14px',
                                                        fontWeight: '500',
                                                        lineHeight: '19.6px'
                                                    }
                                                }> {
                                                    items.email
                                                } </span>
                                            </div>


                                        </div>
                                        <img src={edit}
                                            alt=""/>

                                    </div>))
                                } </div>


                                <div className="mb-5">
                                    <button disabled={isdisable}

                                        variant="primary"
                                        onClick={
                                            () => {
                                                setshowModal3(true)
                                            }
                                        }
                                        style={
                                            {
                                                maxWidth: '280px',
                                                width: '100%',
                                                background: isdisable ? '#EBE4FF' : '#6F00FF',
                                                padding: '16px 24px 16px 24px',
                                                border: 'none',
                                                outline: 'none',
                                                borderRadius: '8px',
                                                fontSize: '16px',
                                                lineHeight: '24px',
                                                letterSpacing: '2%',
                                                fontWeight: '600',
                                                color: isdisable ? '#BFA6FF' : '#ffff'

                                            }
                                    }>
                                        Submit
                                    </button>

                                </div>


                            </div>


                        </div>


                    </form>

                </div>
            </div>
        </div>
    </div>);
}

export default CustomerDetails;
