import Modal from "react-bootstrap/Modal";
import success from "../asset/images/warning.svg";
import currencyPairCheck from "../utilities/currencyPairCheck";
import AmountCheck from "../component/amountCheck";

function ReasonModalOrder(props) {
    const closeModal = () => {
        var datashow = props.show;
        props.setshow(!datashow);
    };
    // console.log(props.data);

    return (
        <Modal
            show={props.show}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            onHide={() => props.setshow(false)}
            className="border-0 "
            border="0"
            style={{
                border: "none",
            }}
        >
            <Modal.Body
                style={{
                    padding: "0px",
                    border: "none",
                    background: "#fff",
                    borderRadius: "inherit",
                }}
                border="0"
            >
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "10px",
                    }}
                >
                    <div
                        className=""
                        style={{
                            padding: "8px 24px 8px 24px",
                            display: "flex",
                            alignItems: "center",
                        }}
                    >
                        <span
                            className="material-icons"
                            style={{ color: "#667085" }}
                            onClick={closeModal}
                        >
                            close
                        </span>
                        <div
                            style={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "center",
                            }}
                        >
                            {/**
              <span
                style={{
                  color: "#667085",
                  fontSize: "14px",
                  fontWeight: "400",
                  lineHeight: "19.6px",
                }}
              >
                INFO
              </span>
              */}
                        </div>
                    </div>
                    <div style={{ padding: "8px 24px 8px 24px" }}>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "32px",
                                marginBottom: "10px",
                            }}
                        >
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    gap: "24px",
                                    flexDirection: "column",
                                    alignItems: "center",
                                }}
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        gap: "8px",
                                    }}
                                >
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            flexDirection: "column",
                                            alignItems: "center",
                                            gap: "16px",
                                        }}
                                    >
                                        <span
                                            style={{
                                                fontWeight: "700",
                                                fontSize: "16px",
                                                lineHeight: "20px",
                                                letterSpacing: "1%",
                                                textAlign: "center",
                                                color: "#101828",
                                            }}
                                        >
                                            Reason for delay
                                        </span>
                                        <span
                                            style={{
                                                textAlign: "center",
                                                color: "#667085",
                                                fontWeight: "400",
                                                letterSpacing: "2%",
                                                fontSize: "16px",
                                                lineHeight: "24px",
                                            }}
                                        >
                                            {props.reason}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: "8px",
                                }}
                            >
                                <button
                                    onClick={() => {
                                        props.setshow(false);
                                    }}
                                    style={{
                                        background: "inherit",
                                        color: "#6F00FF",
                                        padding: "8px 12px 8px 12px",
                                        border: "none",
                                        outline: "none",
                                        borderRadius: "8px",
                                        fontWeight: "500",
                                    }}
                                >
                                    Close
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default ReasonModalOrder;
