import { CloseIcon } from "asset/icons";
import axios from "axios";
import Loader from "component/loader";
import useScreenSize from "hooks/useScreenSize";
import { useEffect, useState } from "react";
import { errorAlert, successAlert } from "utilities/alerts";

const Rating = ({ closeRating }) => {
    const [rate, setRate] = useState(0);
    const [reason, setReason] = useState("");
    const [loading, setLoading] = useState(false);
    const [receiveMoreFeedback, setReceiveMoreFeedback] = useState(false);
    const { screenWidth } = useScreenSize();

    const sendRating = async () => {
        try {
            setLoading(true);
            await axios.post("/v1/reviews", {
                rating: rate + "",
                reason,
                isFeedback: receiveMoreFeedback,
                version: "1",
            });
            successAlert("Feedback Sent. Thank you!");
            closeRating();
        } catch (error) {
            setLoading(false);
            errorAlert(error);
        }
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        if (screenWidth > 650) {
            document.body.style.overflowY = "hidden";
            return () => {
                document.body.style.overflowY = "auto";
            };
        }
    }, [screenWidth]);

    return (
        <>
            <div className="fixed z-[2000] w-screen h-screen left-0 top-0 bg-[#1D29394D]" />
            <div className="bg-white rounded-2xl !pt-[30px] !pb-4 fixed max-sm:w-[calc(100%-32px)] right-4 sm:right-5 bottom-4 sm:bottom-5 z-[2100] shadow-[0px_8px_5px_0px_#00000014,0px_20px_13px_0px_#00000008]">
                <div className="flex !px-6 justify-between">
                    <h3 className="font-semibold text-[22px] text-[#1F2937] !mr-3 580:!mr-12">
                        How do you like your new dashboard?
                        <span className="block !mt-[7px] font-normal text-sm text-[#6B7280]">
                            We’d like to know how satisfied you are with our recent updates
                        </span>
                    </h3>
                    <CloseIcon
                        className="size-10 375:size-8 475:size-6 *:stroke-main-grey -translate-y-1 cursor-pointer rounded-full"
                        onClick={closeRating}
                    />
                </div>
                <div className="!px-6 grid grid-cols-5 max-400:text-sm !gap-1 400:!gap-2 !mt-[22px]">
                    {[1, 2, 3, 4, 5].map((rating) => (
                        <div
                            className={`bg-page-bg sm:w-[95px] h-[46px] 400:h-[56px] rounded-lg  hover:bg-[#EBE4FF] !border cursor-pointer grid place-items-center transition-all duration-200 ${
                                rate === rating
                                    ? "!border-[#A173FF] !bg-[#EBE4FF]"
                                    : "!border-transparent"
                            }`}
                            key={rating}
                            onClick={() => setRate(rating)}
                        >
                            {rating}
                        </div>
                    ))}
                </div>
                <p className="flex items-center justify-between !px-6 mt-2.5 font-medium text-sm text-main-grey">
                    I don't like it
                    <span>It's great</span>
                </p>
                <div className={`!px-6 !pt-7 transition-all ${!rate && "h-0 overflow-y-hidden"}`}>
                    <>
                        <p className="text-[#475467] font-medium text-sm !mb-1">
                            Tell us how you really feel
                        </p>
                        <textarea
                            className="rounded-lg resize-none h-[96px] w-full !p-4 placeholder:font-normal placeholder:text-main-grey !shadow-[inset_0_0_0_1000px_#DBDFE5]"
                            placeholder="Describe your experience..."
                            onChange={(evt) => setReason(evt.target.value)}
                        />
                    </>
                    <div className="flex justify-between items-center font-[450] !mt-11 text-main !mb-7 gap-8 max-400:text-sm">
                        I’d like to give more feedback in the future
                        <Toggle setToggle={setReceiveMoreFeedback} toggle={receiveMoreFeedback} />
                    </div>
                </div>
                <div className="flex justify-end !px-6 !pt-4 !pb-3 !border-t !mt-4 !border-[#E7E9FB]">
                    <button
                        className={`${
                            loading ? "!py-2.5 !px-[41px] pointer-events-none" : "!py-2 !px-6"
                        } rounded-lg font-[450] text-[#FBFAFF] ${
                            rate && reason ? "!bg-secondary" : "!bg-[#D9CDFF] pointer-events-none"
                        }`}
                        onClick={sendRating}
                    >
                        {loading ? <Loader /> : "Submit"}
                    </button>
                </div>
            </div>
        </>
    );
};

const Toggle = ({ toggle, setToggle }) => (
    <div
        onClick={() => setToggle((prev) => !prev)}
        className={`w-[58px] 375:w-[50px] 420:w-[42.03px] h-[22px] rounded-[33.33px] cursor-pointer transition-colors ease-in-out duration-200 ${
            toggle ? "!bg-secondary" : "bg-[#D9DBE9]"
        }`}
    >
        <div
            className={`size-6 rounded-full border transition-all ease-in-out duration-200 !border-[#E7E9FB] bg-[#F6F8FA] ${
                toggle ? "translate-x-5" : "-translate-x-[1px]"
            } -translate-y-[1px] !shadow-[0px_3.33px_5px_-3.33px_rgba(16,24,40,0.1),0px_0px_16.67px_-2.5px_rgba(16,24,40,0.38)]`}
        />
    </div>
);
export default Rating;
