//import {useState} from 'react';
import Modal from "react-bootstrap/Modal";
import "../style/rightside.css";
import "../style/main.css";
import lock from "../asset/images/icon-lock.png";
import reciept from "../asset/images/receipt-add.png";
import document from "../asset/images/receipt-edit.png";
import upload from "../asset/images/document-upload.png";
import Loader from "../component/loader";
import { useEffect, useState } from "react";
import axios from "axios";
import UpdateStatus from "../component/updatestatusdetails";
import UploadOrderDocument from "../component/uploadorderdocument";
import { useSelector } from "react-redux";
import { RWebShare } from "react-web-share";
import ConfirmOrder from "../component/confirmOrder";
import ComponentLoader from "../component/componentloader";
import InvoiceDetails from "../component/invoicedetails";
import Warning from "../asset/images/warning-2.svg";
import arrowwarning from "../asset/images/arrow-right-warning.svg";
import currencyPairCheck from "../utilities/currencyPairCheck";
import AmountCheck from "../component/amountCheck";

function OrderDetails(props) {
    const [orderstatus, setorderstatus] = useState("Pending");
    const [loaderaccept, setloaderaccept] = useState(false);
    const [loaderreject, setloaderreject] = useState(false);
    const [success, setsuccess] = useState(false);
    const [deletes, setdelete] = useState(false);
    const [updateshow, setupdateshow] = useState();
    const [filename, setfilename] = useState(null);
    const [filesize, setfilesize] = useState(0);
    const [showModal, setshowModal] = useState(false);
    const selectorbusiness = useSelector((state) => state.businessid);
    const [order, setorder] = useState([]);
    const [showconfirm, setshowconfirm] = useState(false);
    const [loaderorder2, setloaderorder2] = useState(true);
    const [show5, setshow5] = useState(false);
    const [showModal3, setshowModal3] = useState(false);
    const [selectedinvoice, setselectedinvoice] = useState([]);
    const [mainStatus, setMainstatus] = useState("");

    useEffect(() => {
        const fetchOrders = async () => {
            if (props?.orderdetails) {
                await axios
                    .get(`/v1/flex/order/${props.orderdetails._id}`)
                    .then((res) => {
                        setorderstatus(res.data.data.orderStatus);
                        setMainstatus(res.data.data.status);
                        ///console.log(res);

                        setorder(res.data.data);
                        props.setreason(res.data.data.reason);

                        setloaderorder2(false);
                    })
                    .catch((e) => {
                        console.log(e);
                    });
            }
        };
        if (props.orderdetails.length !== 0) fetchOrders();
    }, [props.orderdetails._id]);
    const restartOrder = async () => {
        setloaderorder2(true);

        await axios
            .get(`/v1/flex/order/${props.orderdetails._id}`)
            .then((res) => {
                setorderstatus(res.data.data.orderStatus);
                setorder(res.data.data);
                setloaderorder2(false);
            })
            .catch((e) => {
                console.log(e);
            });
    };
    const myFunction = async (value) => {
        // Select the text field
        // copyText.select();

        await navigator.clipboard
            .writeText(value)
            .then((res) => {
                props.setsuccess(true);
                props.sett("Success");
                props.setmessage("Copied successfully");
                props.setfail(false);
            })
            .catch((e) => {
                props.setsuccess(false);
                props.sett("Failed");
                props.setmessage("Failed to copy");
                props.setfail(true);
            });

        // Alert the copied text
    };
    const ChangeStatus = async (value) => {
        setloaderorder2(true);
        if (value === "accepted") {
            setloaderaccept(true);
            const data = {
                status: value,
            };
            await axios
                .put(`/v1/flex/order/${props.orderdetails._id}`, data)
                .then((res) => {
                    setorderstatus("Accept");
                    props.setfail(false);
                    props.setsuccess(true);
                    setloaderaccept(false);
                    setshowconfirm(false);
                    props.setmessage(res.data.message);
                    axios
                        .get(`/v1/flex/order/${props.orderdetails._id}`)
                        .then((res) => {
                            setorderstatus(res.data.data.orderStatus);
                            setorder(res.data.data);
                            setloaderorder2(false);
                        })
                        .catch((e) => {
                            console.log(e);
                            props.setsuccess(false);

                            setshowconfirm(false);
                            props.setfail(true);
                            props.setmessage(
                                e.response?.data ? e.response.data.message : "An error occr"
                            );

                            setloaderaccept(false);
                            setshowconfirm(false);
                        });
                })
                .catch((e) => {
                    console.log(e);
                });
        } else {
            setloaderreject(true);
            const data = {
                status: value,
            };
            await axios
                .put(`/v1/flex/order/${props.orderdetails._id}`, data)
                .then((res) => {
                    setloaderreject(false);

                    setorderstatus("Declined");
                    props.setfail(false);
                    props.setsuccess(true);
                    props.setmessage(res.data.message);

                    axios
                        .get(`/v1/flex/order/${props.orderdetails._id}`)
                        .then((res) => {
                            setorderstatus(res.data.data.status);
                            setorder(res.data.data);
                            setloaderorder2(false);
                        })
                        .catch((e) => {
                            console.log(e);
                        });
                })
                .catch((e) => {
                    console.log(e);
                    props.setfail(true);
                    props.setsuccess(false);
                    props.setmessage(e.response?.data ? e.response.data.message : "An error occr");
                });
        }
    };
    useEffect(() => {
        if (props.fail) {
            let timer = setTimeout(() => {
                props?.setfail(false);
            }, 3000);
            clearTimeout(timer);
        }
        if (props.success) {
            let timer = setTimeout(() => {
                props.setsuccess(false);
            }, 3000);
            clearTimeout(timer);
        }
    }, []);

    return (
        <>
            <UpdateStatus
                updateshow={updateshow}
                setupdateshow={setupdateshow}
                orderdetails={props.orderdetails}
                setfail={props?.setfail}
                t={props.sett}
                setsuccess={props?.setsuccess}
                setmessage={props.setmessage}
                setorderstatus={setorderstatus}
                restartOrder={restartOrder}
            />
            <UploadOrderDocument
                filesize={filesize}
                show={showModal}
                setshow={setshowModal}
                filename={filename}
                setfilesize={setfilesize}
                setfilename={setfilename}
                orderdetails={props.orderdetails}
                selectedorderdocument={props?.selectedorderdocument}
                orderDocument={props.orderDocument}
                fail={props?.fail}
                success={props?.success}
                setfail={props?.setfail}
                setsuccess={props?.setsuccess}
                restartOrder={restartOrder}
                t={props.sett}
                setmessage={props.setmessage}
            />
            <ConfirmOrder
                show={showconfirm}
                setshow={setshowconfirm}
                ChangeStatus={ChangeStatus}
                orderdetails={order}
                loaderaccept={loaderaccept}
                setloaderaccept={setloaderaccept}
            />
            <InvoiceDetails
                show={show5}
                setshow={setshow5}
                selecteditem={selectedinvoice}
                setshow3={setshowModal3}
                deletes={deletes}
            />

            <Modal
                show={props.show}
                keyboard={false}
                className="rightsidemodal"
                onHide={() => props.setshow(false)}
                backdrop="false"
            >
                <div
                    className=""
                    style={{
                        height: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <div
                        style={{
                            height: "calc(100% - 100px)",
                            width: "100%",
                            padding: "20px",
                        }}
                    >
                        <div className="w-100" style={{ height: "100%" }}>
                            <div
                                className="w-100 "
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: "16px",
                                }}
                            >
                                <div
                                    className="d-flex gap-3 align-items-center"
                                    style={{
                                        marginTop: "40px",
                                        position: "relative",
                                    }}
                                >
                                    {props.success && (
                                        <div
                                            className="success"
                                            style={{
                                                boxSizing: "border-box",
                                                width: "90%",
                                            }}
                                        >
                                            <div className="lg-success">
                                                <div className="ech-lg-hm">
                                                    <div className="main-sucees">{props?.t}</div>
                                                    <span>{props.message}</span>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    {props.fail && (
                                        <div
                                            className="success"
                                            style={{
                                                background: "#FCFCFD",
                                                border: " 1px solid #D0D5DD",
                                                boxSizing: "border-box",
                                                width: "90%",
                                            }}
                                        >
                                            <div className="lg-success">
                                                <div
                                                    className="ech-lg-hm"
                                                    style={{
                                                        background: "#F9FAFB",
                                                    }}
                                                >
                                                    <div
                                                        className="main-sucees"
                                                        style={{
                                                            color: "#344054",
                                                        }}
                                                    >
                                                        {props?.t}
                                                    </div>
                                                    <span
                                                        style={{
                                                            color: "#344054",
                                                        }}
                                                    >
                                                        {props.message}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    <span
                                        className="material-icons"
                                        style={{
                                            color: "#667085",
                                            cursor: "pointer",
                                        }}
                                        onClick={() => {
                                            props.setshow(false);
                                        }}
                                    >
                                        arrow_back
                                    </span>
                                    <span
                                        style={{
                                            fontSize: "14px",
                                            color: "#667085",
                                            lineHeight: "18.9px",
                                            fontWeight: "500",
                                            textTransform: "uppercase",
                                        }}
                                    >
                                        back to orders
                                    </span>
                                </div>
                                {orderstatus === "DELAYED" && (
                                    <div
                                        style={{
                                            border: "1px solid #FEC84B",
                                            borderRadius: "8px",
                                            background: "#FEF0C7",
                                            padding: "16px",
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                            maxWidth: "90%",
                                            width: "100%",
                                        }}
                                    >
                                        <div
                                            style={{
                                                display: "flex",
                                                gap: "8px",
                                                width: "100%",
                                            }}
                                        >
                                            <img src={Warning} alt="" />

                                            <div
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "space-between",
                                                    alignItems: "center",
                                                    width: "100%",
                                                }}
                                            >
                                                <span
                                                    style={{
                                                        color: "#101828",
                                                        fontSize: "14px",
                                                        fontWeight: "500",
                                                        lineHeight: "19.6px",
                                                    }}
                                                >
                                                    This order is delayed
                                                </span>
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        gap: "8px",
                                                        alignItems: "center",
                                                        color: "#DC6803",
                                                        cursor: "pointer",
                                                    }}
                                                    onClick={() => {
                                                        props.setshowordereason(true);
                                                    }}
                                                >
                                                    See reasons
                                                    <img src={arrowwarning} alt="" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                <div
                                    className=""
                                    style={{
                                        fontSize: "32px",
                                        lineHeight: "40px",
                                        fontWeight: "500",
                                        color: "#101828",
                                    }}
                                ></div>
                            </div>
                            <div className="buyer-details-lg-col">
                                <div className="order-id-lg">
                                    <span>{props.orderdetails.title}</span>
                                    {/* <span
                                        style={{
                                            color:
                                                mainStatus === "decline"
                                                    ? "crimson"
                                                    : orderstatus === "DELAYED"
                                                    ? "#DC6803"
                                                    : orderstatus === "COMPLETED"
                                                    ? "#12B76A"
                                                    : orderstatus === "DECLINED"
                                                    ? "#F04438"
                                                    : orderstatus === "PROCESSING"
                                                    ? "#6F00FF"
                                                    : "#F79009",
                                        }}
                                    >
                                        {mainStatus === "decline" ? "DECLINED" : orderstatus}
                                    </span> */}
                                </div>
                                <div
                                    className="tag-details-lg"
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        gap: "24px",
                                    }}
                                >
                                    <div
                                        className="lg-sie-avater-lg"
                                        style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                        }}
                                    >
                                        <div className="top-tier-lg">
                                            <div
                                                className="lg-bm-yh"
                                                style={{
                                                    color: "#6F00FF",
                                                }}
                                            >
                                                Price
                                            </div>
                                            <h4
                                                style={{
                                                    fontSize: "24px",
                                                    fontWeight: "500",
                                                    lineHeight: "32.4px",
                                                }}
                                            >
                                                {" "}
                                                {currencyPairCheck(props.orderdetails?.currency)}
                                                {AmountCheck(props.orderdetails?.totalAmount)}
                                            </h4>
                                        </div>
                                        <div className="top-tier-lg">
                                            <div
                                                className="lg-bm-yh"
                                                style={{
                                                    textAlign: "right",
                                                }}
                                            >
                                                Payment method
                                            </div>
                                            <h4
                                                style={{
                                                    textAlign: "right",
                                                    fontWeight: "500",
                                                }}
                                            >
                                                {" "}
                                                {props.orderdetails.paymentMethod}
                                            </h4>
                                        </div>
                                    </div>
                                    <div
                                        className="lg-sie-avater-lg"
                                        style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                        }}
                                    >
                                        <div className="top-tier-lg">
                                            <div
                                                className="lg-bm-yh"
                                                style={{
                                                    fontWeight: "500",
                                                }}
                                            >
                                                Delivery date
                                            </div>
                                            <h4
                                                style={{
                                                    fontSize: "14px",
                                                    fontWeight: "500",
                                                    lineHeight: "32.4px",
                                                }}
                                            >
                                                {new Date(
                                                    props.orderdetails.deliveryDate
                                                ).toDateString()}
                                            </h4>
                                        </div>
                                        <div className="top-tier-lg">
                                            <div
                                                className="lg-bm-yh"
                                                style={{
                                                    textAlign: "right",
                                                }}
                                            >
                                                Payment terms
                                            </div>
                                            <h4
                                                style={{
                                                    fontSize: "14px",
                                                    fontWeight: "500",
                                                    lineHeight: "32.4px",
                                                    textAlign: "right",
                                                }}
                                            >
                                                {" "}
                                                {props.orderdetails.paymentTerms}
                                            </h4>
                                        </div>
                                    </div>
                                </div>
                                {order?.business?._id !== selectorbusiness &&
                                    mainStatus !== "decline" && (
                                        <div
                                            className=""
                                            style={{
                                                borderTop: "1px solid #E7E9FB",
                                                width: "90%",
                                                display: "flex",
                                                padding: "8px 0px 8px 0px",
                                                gap: "16px",
                                                alignItems: "center",
                                                borderBottom: "1px solid #E7E9FB",
                                            }}
                                        >
                                            {/* <div
                                                style={{
                                                    display: "flex",
                                                    gap: "4px",
                                                    padding: "8px",
                                                }}
                                                onClick={() => setupdateshow(true)}
                                            >
                                                <img src={lock} alt="" />
                                                <span
                                                    style={{
                                                        color: "#6F00FF",
                                                        fontWeight: "500",
                                                        fontSize: "14px",
                                                        lineHeight: "19.6px",
                                                        cursor: "pointer",
                                                    }}
                                                >
                                                    Update status
                                                </span>
                                            </div> */}

                                            <div
                                                style={{
                                                    display: "flex",
                                                    gap: "4px",
                                                    padding: "8px",
                                                }}
                                                onClick={() => props.setshowModalInvoice(true)}
                                            >
                                                <img src={reciept} alt="" />
                                                <span
                                                    style={{
                                                        color: "#6F00FF",
                                                        fontWeight: "500",
                                                        fontSize: "14px",
                                                        lineHeight: "19.6px",
                                                        cursor: "pointer",
                                                    }}
                                                >
                                                    Add invoice
                                                </span>
                                            </div>

                                            <div
                                                style={{
                                                    display: "flex",
                                                    gap: "4px",
                                                    padding: "8px",
                                                }}
                                                onClick={() => {
                                                    setshowModal(true);
                                                    setfilename(null);
                                                    setfilesize(0);
                                                }}
                                            >
                                                <img src={document} alt="" />
                                                <span
                                                    style={{
                                                        color: "#6F00FF",
                                                        fontWeight: "500",
                                                        fontSize: "14px",
                                                        lineHeight: "19.6px",
                                                        cursor: "pointer",
                                                    }}
                                                >
                                                    Upload document
                                                </span>
                                            </div>
                                        </div>
                                    )}
                                {loaderorder2 ? (
                                    <ComponentLoader />
                                ) : (
                                    <>
                                        {props?.orderdetails?.invoice?.length !== 0 && (
                                            <>
                                                {props?.orderdetails?.invoice?.map(
                                                    (value, index) => (
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                flexDirection: "column",
                                                                gap: "8px",
                                                                maxWidth: "90%",
                                                                width: "100%",
                                                            }}
                                                            key={index}
                                                        >
                                                            <label
                                                                htmlFor="Invoice"
                                                                style={{
                                                                    color: "#344054",
                                                                    fontWeight: "500",
                                                                    fontSize: "14px",
                                                                    lineHeight: "19.6px",
                                                                }}
                                                            >
                                                                Payment Link
                                                            </label>
                                                            <div
                                                                style={{
                                                                    gap: "8px",
                                                                    display: "flex",
                                                                    alignItems: "center",
                                                                    background: "#F4F0FF",
                                                                    padding: "8px 8px 8px 16px",
                                                                    borderRadius: "6px",
                                                                    position: "relative",
                                                                    minHeight: "44px",
                                                                }}
                                                            >
                                                                <span
                                                                    style={{
                                                                        color: "#101828",
                                                                        fontSize: "14px",
                                                                        lineHeight: "19.6px",
                                                                        fontWeight: "400",
                                                                        color: "#6F00FF",
                                                                        cursor: "pointer",
                                                                    }}
                                                                >
                                                                    <a
                                                                        style={{
                                                                            color: "#101828",
                                                                            fontSize: "14px",
                                                                            lineHeight: "19.6px",
                                                                            fontWeight: "500",
                                                                            color: "#6F00FF",
                                                                            cursor: "pointer",
                                                                            textDecoration: "none",
                                                                        }}
                                                                        id="myInput"
                                                                        href={value.paymentLink}
                                                                        target="_blank"
                                                                    >
                                                                        {value.paymentLink}
                                                                    </a>
                                                                </span>
                                                                {order?.business?._id ===
                                                                selectorbusiness ? (
                                                                    <RWebShare
                                                                        data={{
                                                                            url: `${value.paymentLink}`,
                                                                            title: "Payment Link",
                                                                        }}
                                                                        onClick={() =>
                                                                            console.log(
                                                                                "shared successfully!"
                                                                            )
                                                                        }
                                                                    >
                                                                        <span
                                                                            style={{
                                                                                color: "#6F00FF",
                                                                                fontSize: "14px",
                                                                                borderRadius: "8px",
                                                                                padding: "4px 8px",
                                                                                background:
                                                                                    "#D9CDFF",
                                                                                borderRadius: "4px",
                                                                                color: "#5E01D6",
                                                                                fontWeight: "500",
                                                                                position:
                                                                                    "absolute",
                                                                                right: "8px",
                                                                                zIndex: "9999",
                                                                                cursor: "pointer",
                                                                            }}
                                                                        >
                                                                            share
                                                                        </span>
                                                                    </RWebShare>
                                                                ) : (
                                                                    <span
                                                                        style={{
                                                                            color: "#6F00FF",
                                                                            fontSize: "14px",
                                                                            borderRadius: "8px",
                                                                            padding: "4px 8px",
                                                                            background: "#D9CDFF",
                                                                            borderRadius: "4px",
                                                                            color: "#5E01D6",
                                                                            fontWeight: "500",
                                                                            position: "absolute",
                                                                            right: "8px",
                                                                            zIndex: "9999",
                                                                            cursor: "pointer",
                                                                        }}
                                                                        onClick={() =>
                                                                            myFunction(
                                                                                value.paymentLink
                                                                            )
                                                                        }
                                                                    >
                                                                        Copy
                                                                    </span>
                                                                )}
                                                            </div>
                                                        </div>
                                                    )
                                                )}
                                            </>
                                        )}
                                        {order?.business?._id !== selectorbusiness ? (
                                            <>
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        gap: "8px",
                                                        maxWidth: "90%",
                                                        width: "100%",
                                                    }}
                                                >
                                                    <label
                                                        htmlFor="Invoice"
                                                        style={{
                                                            color: "#344054",
                                                            fontWeight: "500",
                                                            fontSize: "14px",
                                                            lineHeight: "19.6px",
                                                        }}
                                                    >
                                                        Order From
                                                    </label>
                                                    <div
                                                        style={{
                                                            gap: "8px",
                                                            display: "flex",
                                                            flexDirection: "column",
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                border: "1px solid #E7E9FB",
                                                                borderRadius: "8px",
                                                                padding: "16px",
                                                                display: "flex",
                                                                alignItems: "center",
                                                                justifyContent: "space-between",
                                                                cursor: "pointer",
                                                            }}
                                                        >
                                                            <div
                                                                style={{
                                                                    display: "flex",
                                                                    gap: "8px",
                                                                }}
                                                            >
                                                                <div className="intial">
                                                                    {order?.business?.name?.slice(
                                                                        0,
                                                                        2
                                                                    )}
                                                                </div>
                                                                <div
                                                                    style={{
                                                                        display: "flex",
                                                                        flexDirection: "column",
                                                                        gap: "4px",
                                                                    }}
                                                                >
                                                                    <span
                                                                        style={{
                                                                            color: "#101828",
                                                                            fontWeight: "500",
                                                                            fontSize: "14px",
                                                                            lineHeight: "19.6px",
                                                                        }}
                                                                    >
                                                                        {order?.business?.name}
                                                                    </span>
                                                                    <span
                                                                        style={{
                                                                            color: "#667085",
                                                                            letterSpacing: "2%",
                                                                            fontWeight: "500",
                                                                            lineHeight: "16.2px",
                                                                        }}
                                                                    >
                                                                        {order?.business?.email}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        ) : (
                                            <>
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        gap: "8px",
                                                        maxWidth: "90%",
                                                        width: "100%",
                                                    }}
                                                >
                                                    <label
                                                        htmlFor="Invoice"
                                                        style={{
                                                            color: "#344054",
                                                            fontWeight: "500",
                                                            fontSize: "14px",
                                                            lineHeight: "19.6px",
                                                        }}
                                                    >
                                                        Order To
                                                    </label>
                                                    <div
                                                        style={{
                                                            gap: "8px",
                                                            display: "flex",
                                                            flexDirection: "column",
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                border: "1px solid #E7E9FB",
                                                                borderRadius: "8px",
                                                                padding: "16px",
                                                                display: "flex",
                                                                alignItems: "center",
                                                                justifyContent: "space-between",
                                                                cursor: "pointer",
                                                            }}
                                                        >
                                                            <div
                                                                style={{
                                                                    display: "flex",
                                                                    gap: "8px",
                                                                }}
                                                            >
                                                                <div className="intial">
                                                                    {order?.flexUser?.businessName?.slice(
                                                                        0,
                                                                        2
                                                                    )}
                                                                </div>
                                                                <div
                                                                    style={{
                                                                        display: "flex",
                                                                        flexDirection: "column",
                                                                        gap: "4px",
                                                                    }}
                                                                >
                                                                    <span
                                                                        style={{
                                                                            color: "#101828",
                                                                            fontWeight: "500",
                                                                            fontSize: "14px",
                                                                            lineHeight: "19.6px",
                                                                        }}
                                                                    >
                                                                        {
                                                                            order?.flexUser
                                                                                ?.businessName
                                                                        }
                                                                    </span>
                                                                    <span
                                                                        style={{
                                                                            color: "#667085",
                                                                            letterSpacing: "2%",
                                                                            fontWeight: "500",
                                                                            lineHeight: "16.2px",
                                                                        }}
                                                                    >
                                                                        {order?.flexUser?.email}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        )}

                                        <div className="ech-form-lg-hl">
                                            <label
                                                htmlFor=""
                                                style={{
                                                    fontWeight: "500",
                                                }}
                                            >
                                                Order Breakdown
                                            </label>
                                            {props?.orderdetails?.orderItems?.map((res, index) => (
                                                <div
                                                    className="main-each-lg"
                                                    style={{
                                                        background: "#fff",
                                                        border: "1px solid #E7E9FB",
                                                    }}
                                                    key={index}
                                                >
                                                    <div className="lg-sie-avater-lg">
                                                        <div className="top-tier-lg">
                                                            <h4
                                                                style={{
                                                                    fontWeight: "400",
                                                                }}
                                                            >
                                                                {res?.itemName}
                                                            </h4>
                                                            <span>
                                                                Quantity {res?.quantity}, Unit Price{" "}
                                                                {currencyPairCheck(
                                                                    props.orderdetails?.currency
                                                                )}
                                                                {res?.pricePerUnit?.toLocaleString()}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="top-tier-lg">
                                                        <h4
                                                            style={{
                                                                fontWeight: "500",
                                                            }}
                                                        >
                                                            {currencyPairCheck(
                                                                props.orderdetails?.currency
                                                            )}

                                                            {parseFloat(
                                                                res.pricePerUnit * res.quantity
                                                            )?.toLocaleString()}
                                                        </h4>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>

                                        {props?.orderdetails?.invoice?.length > 0 && (
                                            <>
                                                <div className="ech-form-lg-hl">
                                                    <label
                                                        htmlFor=""
                                                        style={{
                                                            fontWeight: "500",
                                                        }}
                                                    >
                                                        Invoice
                                                    </label>
                                                    {props?.orderdetails?.invoice?.map(
                                                        (res, index) => (
                                                            <div
                                                                className="main-each-lg"
                                                                style={{
                                                                    background: "#fff",
                                                                    border: "1px solid #E7E9FB",
                                                                }}
                                                                key={index}
                                                                onClick={() => {
                                                                    setshow5(true);

                                                                    setselectedinvoice(res);
                                                                }}
                                                            >
                                                                <div className="lg-sie-avater-lg">
                                                                    <div className="top-tier-lg">
                                                                        <h4
                                                                            style={{
                                                                                fontWeight: "400",
                                                                            }}
                                                                        >
                                                                            {res?.invoiceTitle}
                                                                        </h4>
                                                                        <span>
                                                                            {new Date(
                                                                                res.createdAt
                                                                            ).toDateString()}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <div className="top-tier-lg">
                                                                    <h4
                                                                        style={{
                                                                            fontWeight: "500",
                                                                        }}
                                                                    >
                                                                        {currencyPairCheck(
                                                                            res?.currency
                                                                        )}

                                                                        {res?.totalAmount?.toLocaleString()}
                                                                    </h4>
                                                                    <span>{res.paymentStatus}</span>
                                                                </div>
                                                            </div>
                                                        )
                                                    )}
                                                </div>
                                            </>
                                        )}
                                        {props?.orderDocument?.length > 0 && (
                                            <div className="ech-form-lg-hl">
                                                <label
                                                    htmlFor=""
                                                    style={{
                                                        fontWeight: "500",
                                                    }}
                                                >
                                                    Docs
                                                </label>
                                                {props?.orderDocument.map((res, index) => (
                                                    <a
                                                        className="order-items-col"
                                                        style={{
                                                            background: "#ECEFF3",
                                                            display: "flex",
                                                            justifyContent: "space-between",
                                                            alignItems: "center",
                                                            textDecoration: "none",
                                                        }}
                                                        key={index}
                                                        href={res.url}
                                                        target="_blank"
                                                    >
                                                        <span
                                                            className="uploadpurchaseorder"
                                                            style={{
                                                                fontWeight: "400",
                                                                color: "#101828",
                                                                textOverflow: "ellipsis",
                                                                whiteSpace: "nowrap",
                                                                overflow: "hidden",
                                                                width: "100%",
                                                            }}
                                                        >
                                                            {res.url}
                                                        </span>

                                                        <img
                                                            src={upload}
                                                            alt=""
                                                            style={{ width: "25px" }}
                                                        />
                                                    </a>
                                                ))}
                                            </div>
                                        )}
                                        <div className="ech-form-lg-hl">
                                            <label
                                                htmlFor=""
                                                style={{
                                                    fontWeight: "500",
                                                }}
                                            >
                                                Purchase order
                                            </label>
                                            <a
                                                className="order-items-col"
                                                style={{
                                                    background: "#ECEFF3",
                                                    display: "flex",
                                                    justifyContent: "space-between",
                                                    alignItems: "center",
                                                    textDecoration: "none",
                                                }}
                                                href={props?.orderdetails?.purchaseOrder?.url}
                                                target="_blank"
                                            >
                                                <span
                                                    className="uploadpurchaseorder"
                                                    style={{
                                                        fontWeight: "400",
                                                        color: "#101828",
                                                        textOverflow: "ellipsis",
                                                        whiteSpace: "nowrap",
                                                        overflow: "hidden",
                                                        width: "100%",
                                                    }}
                                                >
                                                    {props?.orderdetails?.purchaseOrder
                                                        ? props?.orderdetails?.purchaseOrder?.url
                                                        : "No Uploaded file"}
                                                </span>

                                                <img
                                                    src={upload}
                                                    alt=""
                                                    style={{ width: "25px" }}
                                                />
                                            </a>
                                            <div className="top-tier-lg">
                                                <div className="lg-bm-yh">Notes</div>
                                                <textarea
                                                    className="each-form-group"
                                                    style={{ height: "92px" }}
                                                    value={props.orderdetails.note}
                                                    readOnly
                                                >
                                                    {" "}
                                                </textarea>
                                            </div>
                                            {selectorbusiness ===
                                                props?.orderdetails?.receiverBusiness &&
                                                order.status === "pending" && (
                                                    <div className="button-lg-con">
                                                        <button
                                                            className="btn-accept-decline decline"
                                                            type="button"
                                                            onClick={() => ChangeStatus("decline")}
                                                        >
                                                            {loaderreject ? <Loader /> : "Decline"}
                                                        </button>
                                                        <button
                                                            className="btn-accept-decline accept"
                                                            type="button"
                                                            onClick={() => setshowconfirm(true)}
                                                        >
                                                            {loaderaccept ? <Loader /> : "Accept"}
                                                        </button>
                                                    </div>
                                                )}
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    );
}

export default OrderDetails;
