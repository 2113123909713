import { Helmet } from "react-helmet";
import { useEffect, useState } from "react";
import SerHeader from "../component/serviceheader";
import nigeria from "../asset/images/countries/ngn.svg";
import Loader from "../component/loader";
import { useLocation, useNavigate } from "react-router-dom";
import "../style/slider.css";
import "../style/main.css";
import info from "../asset/images/iconinfo.svg";
import currencys from "../asset/images/GB.svg";
import pay from "../asset/images/Sendpay.svg";
import currencyss from "../asset/images/EU.svg";
import US from "../asset/images/US.png";
import FailHandler from "../component/failhandler";
import PayCurrencyModal from "../component/payCurrencyModal";
import { currencies } from "../constants/currency";
import axios from "axios";
import currencyPairCheck from "../utilities/currencyPairCheck";
import { useSelector } from "react-redux";
import { checkBalance } from "../component/checkBalance";

const CrossBorder = () => {
    const navigate = useNavigate();
    const { state } = useLocation();
    const [loader] = useState(false);
    const [isdisable, setisdisable] = useState(true);
    const selectorwallet = useSelector((state) => state.wallet);

    const [amount, setamount] = useState(0);
    const [fails, setfail] = useState(false);
    const [showModal, setshowModal] = useState(false);
    const [selectedCurrency, setseletedCurrency] = useState("GBP");
    const [message, setmessage] = useState("");
    const [pairs, setPairs] = useState([]);
    const [fee, setfee] = useState(0);
    const [isFocuse, setisFocuse] = useState(false);
    //  console.log(selectorwallet);
    function filterWaltetBalance() {
        return selectorwallet.filter((item) => item.currency === state.default);
    }
    //console.log(filterWaltetBalance()[0].balance);
    useEffect(() => {
        if (fails) {
            setTimeout(() => {
                setfail(false);
            }, 3000);
        }
    });
    useEffect(() => {
        if (amount !== "") {
            setisdisable(false);
        } else {
            setisdisable(true);
        }
    }, [setisdisable, amount]);
    useEffect(() => {
        const fetchTransfee = async () => {
            await axios
                .get(`/v1/wallets/transferFees`)
                .then((res) => {
                    // console.log(res);
                    setfee(res.data.data.crossBorderTransferFee);
                })
                .catch((e) => {
                    console.log(e);
                });
        };
        fetchTransfee();
    }, []);
    //  console.log(selectedCurrency);
    useEffect(() => {
        const fetchPairs = async () => {
            await axios
                .post(`/v1/wallets/currency/single_pair`, {
                    baseCurrency: state.default,
                    quoteCurrency: selectedCurrency,
                })
                .then((res) => {
                    //  console.log(res);
                    setPairs(res.data.data);
                })
                .catch((e) => {
                    console.log(e);
                });
        };
        fetchPairs();
    }, [selectedCurrency, state.default]);

    useEffect(() => {
        let timer = setTimeout(() => {
            setfail(false);
        }, 3000);
        clearTimeout(timer);
    }, []);
    const submitHandler = async (e) => {
        e.preventDefault();
        if (checkBalance(state.default, amount, selectorwallet) === true) {
            navigate(`/payment/transfer`, {
                state: {
                    ...state,
                    type: "bankuser",
                    amount: parseFloat(amount),
                    payType: "crossBorderFx",
                    quoteCurrency: selectedCurrency,
                    currency: state.default,
                    fee: fee,

                    rate:
                        state.default === pairs.baseCurrency
                            ? pairs.quoteCurrencyRate
                            : pairs.baseCurrencyRate,
                },
            });
        } else {
            setfail(true);
            setmessage(checkBalance(state.default, amount, selectorwallet));
        }
    };

    return (
        <div className="h-100 w-100 ">
            <div
                className=""
                style={{
                    maxWidth: "2000px",
                    width: "100%",
                    margin: "0px auto",
                }}
            >
                <Helmet>
                    <title>Cross-border Payment</title>
                </Helmet>

                <SerHeader header={"Cross-border Payment"} width={25} />
                <PayCurrencyModal
                    show={showModal}
                    setshow={setshowModal}
                    onHide={() => setshowModal(false)}
                    wallet={[]}
                    default={state.default}
                    setseletedCurrency={setseletedCurrency}
                    // setdefault={setdefault}
                    // getWallet={getWallet}
                />
            </div>
            <div
                style={{
                    height: "100%",
                    maxWidth: "2000px",
                    margin: "0px auto",
                    width: "100%",
                }}
            >
                <div
                    className=" "
                    style={{
                        margin: "0px auto",
                        paddingLeft: "30px",
                        paddingRight: "30px",
                        paddingTop: "10px",
                        paddingBottom: "10px",
                        width: "100%",
                    }}
                >
                    <div
                        style={{
                            width: "100%",
                            height: "100%",
                            display: "flex",

                            justifyContent: "center",
                        }}
                    >
                        <form
                            onSubmit={submitHandler}
                            className="form-general-layout"
                            style={{
                                position: "relative",
                            }}
                        >
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: "32px",
                                    position: "relative",
                                }}
                            >
                                <FailHandler success={fails} message={message} />

                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        gap: "8px",
                                    }}
                                >
                                    <div
                                        style={{
                                            color: "#101828",
                                            fontWeight: "600",
                                            fontSize: "24px",
                                            lineHeight: "32.4px",
                                        }}
                                    >
                                        How much?
                                    </div>
                                    <span
                                        style={{
                                            color: "#667085",
                                            fontWeight: "500",
                                            lineHeight: "24px",
                                            fontSize: "1rem",
                                            letterSpacing: "2%",
                                        }}
                                    >
                                        Enter amount below
                                    </span>
                                </div>
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        gap: "32px",
                                    }}
                                >
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            gap: "8px",
                                            width: "100%",
                                        }}
                                    >
                                        <div
                                            className=""
                                            style={{
                                                display: "flex",
                                                gap: "8px",
                                                flexDirection: "column",
                                                borderRadius: "8px",
                                                background: "#fff",
                                            }}
                                        >
                                            <div
                                                style={{
                                                    display: "flex",
                                                    gap: "8px",
                                                    justifyContent: "space-between",
                                                    alignItems: "center",
                                                    padding: "8px 16px",
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        color: "#667085",
                                                        fontWeight: "500",
                                                        fontSize: "12px",
                                                        lineHeight: "16.2px",
                                                    }}
                                                >
                                                    SEND
                                                </div>
                                                <div
                                                    style={{
                                                        borderRadius: "8px",
                                                        border: "none",
                                                        outline: "none",
                                                        letterSpacing: "2%",
                                                        fontWeight: "500",
                                                        fontSize: "14px",
                                                        display: "flex",
                                                        gap: "4px",
                                                        alignItems: "center",
                                                        color: "#101828",
                                                        position: "relative",
                                                    }}
                                                >
                                                    {" "}
                                                    <img
                                                        style={{
                                                            width: "20px",
                                                            height: "20px",
                                                        }}
                                                        src={
                                                            state.default === "EUR"
                                                                ? currencyss
                                                                : state.default === "USD"
                                                                ? US
                                                                : state.default === "NGN"
                                                                ? nigeria
                                                                : currencys
                                                        }
                                                        alt=""
                                                    />
                                                    {state.default}
                                                </div>
                                            </div>
                                            <div
                                                className="input_sign_code"
                                                style={{
                                                    background: isFocuse ? "#EAECF0" : "#fff",
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        position: "relative",
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            alignItems: "center",
                                                        }}
                                                    >
                                                        <span
                                                            className="balance_number"
                                                            style={{
                                                                color: "rgb(52,64,84)",
                                                                fontSize: "14px",
                                                            }}
                                                        >
                                                            {currencyPairCheck(state.default)}
                                                        </span>
                                                        <input
                                                            type="number"
                                                            className="input_form_number"
                                                            placeholder="0"
                                                            onChange={(e) =>
                                                                setamount(e.target.value)
                                                            }
                                                            required
                                                            step={0.5}
                                                            onBlur={() => {
                                                                setisFocuse(false);
                                                            }}
                                                            onFocus={() => {
                                                                setisFocuse(true);
                                                            }}
                                                            value={amount}
                                                        />
                                                    </div>
                                                </div>
                                                <span className="balance_number">
                                                    Balance: {currencyPairCheck(state.default)}
                                                    {parseFloat(
                                                        filterWaltetBalance()[0]?.balance
                                                    ).toLocaleString()}
                                                </span>
                                            </div>
                                        </div>
                                        <div
                                            style={{
                                                position: "relative",
                                            }}
                                        >
                                            <img
                                                src={pay}
                                                alt=""
                                                height={40}
                                                style={{
                                                    position: "absolute",
                                                    bottom: "0px",
                                                    top: "0px",
                                                    left: "50%",
                                                    transform: "translate(-50%,-50%)",
                                                }}
                                            />
                                        </div>

                                        <div
                                            className=""
                                            style={{
                                                display: "flex",
                                                gap: "8px",
                                                flexDirection: "column",
                                                borderRadius: "8px",
                                                background: "#fff",
                                            }}
                                        >
                                            <div
                                                style={{
                                                    display: "flex",
                                                    gap: "8px",
                                                    justifyContent: "space-between",
                                                    alignItems: "center",
                                                    padding: "8px 16px",
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        color: "#667085",
                                                        fontWeight: "500",
                                                        fontSize: "12px",
                                                        lineHeight: "16.2px",
                                                    }}
                                                >
                                                    RECEIVE
                                                </div>
                                                <div
                                                    style={{
                                                        borderRadius: "8px",
                                                        border: "none",
                                                        outline: "none",
                                                        background: "#fff",
                                                        letterSpacing: "2%",
                                                        fontWeight: "500",
                                                        fontSize: "14px",
                                                        display: "flex",
                                                        gap: "4px",
                                                        alignItems: "center",
                                                        color: "#101828",
                                                        position: "relative",
                                                        cursor: "pointer",
                                                    }}
                                                    onClick={() => setshowModal(true)}
                                                >
                                                    {" "}
                                                    <img
                                                        style={{
                                                            width: "20px",
                                                            height: "20px",
                                                        }}
                                                        src={
                                                            selectedCurrency === "EUR"
                                                                ? currencyss
                                                                : selectedCurrency === "USD"
                                                                ? US
                                                                : selectedCurrency === "NGN"
                                                                ? nigeria
                                                                : currencys
                                                        }
                                                        alt=""
                                                    />
                                                    {selectedCurrency}
                                                    <span
                                                        className="material-icons"
                                                        style={{
                                                            color: "#475467",
                                                        }}
                                                    >
                                                        expand_more
                                                    </span>
                                                </div>
                                            </div>
                                            <div
                                                className="input_sign_code"
                                                style={{
                                                    background: "#fff",
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        position: "relative",
                                                    }}
                                                >
                                                    <div
                                                        type="number"
                                                        className="input_form_number"
                                                    >
                                                        {currencyPairCheck(selectedCurrency)}
                                                        {amount > 0
                                                            ? parseFloat(
                                                                  parseFloat(
                                                                      state.default ===
                                                                          pairs.baseCurrency
                                                                          ? pairs.quoteCurrencyRate
                                                                          : pairs.baseCurrencyRate
                                                                  ) * parseFloat(amount)
                                                              ).toFixed(3)
                                                            : "0"}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        style={{
                                            padding: "16px",
                                            borderRadius: "8px",
                                            background: "#fff",
                                        }}
                                    >
                                        <div
                                            style={{
                                                display: "flex",
                                                gap: "8px",
                                                alignItems: "center",
                                                width: "100%",
                                                justifyContent: "space-between",
                                            }}
                                        >
                                            <div
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    gap: "4px",
                                                }}
                                            >
                                                <span
                                                    style={{
                                                        fontSize: "14px",
                                                        lineHeight: "19.6px",
                                                        fontWeight: "500",
                                                        letterSpacing: "-1%",
                                                        color: "#667085",
                                                    }}
                                                >
                                                    Fee
                                                </span>

                                                <span
                                                    style={{
                                                        color: "#344054",
                                                        fontWeight: "600",
                                                        fontSize: "16px",
                                                        lineHeight: "24px",
                                                        color: "#344054",
                                                    }}
                                                >
                                                    {currencyPairCheck(selectedCurrency)}
                                                    {parseFloat(amount * parseFloat(fee)).toFixed(
                                                        3
                                                    )}
                                                </span>
                                            </div>
                                            <div
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    gap: "4px",
                                                }}
                                            >
                                                <span
                                                    style={{
                                                        fontSize: "14px",
                                                        lineHeight: "19.6px",
                                                        fontWeight: "500",
                                                        letterSpacing: "-1%",
                                                        color: "#667085",
                                                    }}
                                                >
                                                    Rate
                                                </span>

                                                <span
                                                    style={{
                                                        color: "#344054",
                                                        fontWeight: "600",
                                                        fontSize: "16px",
                                                        lineHeight: "24px",
                                                        color: "#6F00FF",
                                                    }}
                                                >
                                                    1{currencyPairCheck(state.default)} ={" "}
                                                    {currencyPairCheck(selectedCurrency)}
                                                    {parseFloat(
                                                        state.default === pairs.baseCurrency
                                                            ? pairs.quoteCurrencyRate
                                                            : pairs.baseCurrencyRate
                                                    ) === NaN
                                                        ? 0
                                                        : parseFloat(
                                                              state.default === pairs.baseCurrency
                                                                  ? pairs.quoteCurrencyRate
                                                                  : pairs.baseCurrencyRate
                                                          ).toFixed(6)}
                                                </span>
                                            </div>{" "}
                                            <div
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    gap: "4px",
                                                }}
                                            >
                                                <span
                                                    style={{
                                                        fontSize: "14px",
                                                        lineHeight: "19.6px",
                                                        fontWeight: "500",
                                                        letterSpacing: "-1%",
                                                        color: "#667085",
                                                    }}
                                                >
                                                    Arrive
                                                </span>

                                                <span
                                                    style={{
                                                        color: "#344054",
                                                        fontWeight: "600",
                                                        fontSize: "16px",
                                                        lineHeight: "24px",
                                                        color: "#344054",
                                                    }}
                                                >
                                                    In Minutes
                                                </span>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="">
                                        <button
                                            disabled={isdisable}
                                            style={{
                                                maxWidth: "280px",
                                                width: "100%",
                                                background: isdisable ? "#EBE4FF" : "#6F00FF",
                                                padding: "16px 24px 16px 24px",
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                border: "none",
                                                outline: "none",
                                                minHeight: "46px",
                                                borderRadius: "8px",
                                                fontSize: "16px",
                                                lineHeight: "24px",
                                                letterSpacing: "2%",
                                                fontWeight: "600",
                                                color: isdisable ? "#BFA6FF" : "#ffff",
                                            }}
                                        >
                                            {loader === true ? (
                                                <Loader data={loader} />
                                            ) : (
                                                "Continue"
                                            )}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CrossBorder;
