import { useState } from "react";

import Modal from "react-bootstrap/Modal";
// import {useNavigate} from 'react-router-dom/dist';
import axios from "axios";
import Swal from "sweetalert2/dist/sweetalert2.js";
import Loaders from "../component/loader";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import yay from "../asset/images/yay.svg";
import "../style/main.css";
import "../style/rightside.css";

function Congratulations(props) {
  //const [show, setShow] = useState(false);

  // const dispatch = useDispatch()
  // const selector = useSelector(state => state.user)
  // const navigate = useNavigate();
  const selector = useSelector((state) => state.users);
  // const [loader, setLoader] = useState(false);
  //console.log(props);

  return (
    <>
      <Modal
        className="primaryModal"
        show={props.show}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Body style={{ border: "none !important" }}>
          <div className="w-100 ">
            <img src={yay} alt="" />
          </div>
          <div
            className="w-100  "
            style={{
              boxSizing: "border-box",
              overflow: "hidden",
              left: "0",
              padding: "24px",
            }}
          >
            <div className="p-2 ">
              <div
                style={{
                  color: "#D9CDFF",
                  fontSize: "14px",
                  fontWeight: "500",
                  lineHeight: "19.6px",
                }}
              >
                <span>Well done, {selector.firstName}</span>
              </div>
              <h4
                style={{
                  color: "#FFFFFF",
                  fontSize: "24px",
                  lineHeight: "32.4px",
                }}
              >
                Congratulations
              </h4>

              <p
                style={{
                  fontSize: "14px",
                  color: "#EBE4FF",
                  fontWeight: "500",
                  lineHeight: "19.6px",
                }}
              >
                You are one step closer to powering your supply chain with
                Tradevu.
              </p>
              <button
                onClick={() => props.goNextPage()}
                className="btn  w-100  "
                type="button"
                style={{
                  color: "#6F00FF",
                  background: "#F4F0FF",
                  fontSize: "16px",
                  fontWeight: "700",
                  padding: "16px 24px 16px 24px",
                  border: "none",
                  marginTop: "100px",
                }}
              >
                Launch Tradevu
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Congratulations;
