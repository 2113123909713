import Modal from "react-bootstrap/Modal";
import person from "../asset/images/person.svg";
import edit from "../asset/images/edit-2.svg";
import { useState } from "react";
import BuyerDetail from "./buyerdetail";
import axios from "axios";
import { useEffect } from "react";
import PartnerSelect from "./form/PartnerSelect";
import AddNetwork from "./addNetwork";

function UploadInvoice(props) {
    const [contacts, setContact] = useState([]);
    const [search, setSearch] = useState("");
    const [modalShow, setModalShow] = useState(false);
    const [addNetWorkModal, setAddNetworkModal] = useState(false);
    const [buyerdetailsinfo, setbuyerdetailsinfo] = useState([]);
    const [loadersetting, setloadersetting] = useState(true);
    const [message, setmessage] = useState("");
    const [partners, setPartners] = useState();

    useEffect(() => {
        const fetchUser = async () => {
            await axios
                .get("/v1/business/all")
                .then((res) => {
                    setContact(res.data);
                    setloadersetting(false);
                })
                .catch((e) => {
                    console.log(e);
                    setloadersetting(false);
                    setmessage("Please try again.");
                });
        };
        fetchUser();
    }, []);

    const filtercontant = contacts?.filter((user) =>
        user.name?.toLowerCase()?.includes(search?.toLowerCase())
    );

    filtercontant.sort((a, b) => a.name.localeCompare(b.name, "es", { sensitivity: "base" }));
    let data = filtercontant.reduce((r, e) => {
        let alphabet = e.name[0];

        if (!r[alphabet])
            r[alphabet] = {
                alphabet,
                record: [],
            };
        r[alphabet].record.push(e);
        return r;
    }, {});

    let result = Object.values(data);
    // console.log(result);
    return (
        <>
            <BuyerDetail
                show={modalShow}
                setModalShow={setModalShow}
                buyerdetail={buyerdetailsinfo}
                setbuyerdetailsinfo={setbuyerdetailsinfo}
                selecteditem={props.selecteditem}
                setNewHandle={props.handle}
                setselecteditem={props.setselecteditem}
            />

            <Modal
                show={props.show}
                backdrop="false"
                keyboard={false}
                onHide={() => props.handle(false)}
            >
                <Modal.Body className="p-0">
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "24px",
                        }}
                    >
                        <div
                            className=""
                            style={{
                                borderBottom: "1px solid #E7E9FB",
                                padding: "8px 24px 8px 24px",
                            }}
                        >
                            <span
                                className="material-icons"
                                style={{
                                    color: "#667085",
                                    cursor: "pointer",
                                }}
                                onClick={() => {
                                    props.handle(false);
                                }}
                            >
                                close
                            </span>
                        </div>
                        <div
                            style={{
                                padding: "8px 24px 8px 24px",
                            }}
                        >
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: "16px",
                                }}
                            >
                                <h3
                                    style={{
                                        color: "#101828",
                                        fontWeight: "500",
                                        fontSize: "20px",
                                        lineHeight: "24px",
                                    }}
                                >
                                    Choose buyer
                                </h3>
                                {buyerdetailsinfo.length > 0 &&
                                    buyerdetailsinfo.map((info, index) => (
                                        <div
                                            style={{
                                                background: "#fff",
                                                borderRadius: "8px",
                                                display: "flex",
                                                gap: "16px",
                                                justifyContent: "space-between",
                                                padding: "8px",
                                                alignItems: "center",
                                            }}
                                            key={index}
                                            onClick={() => setModalShow(true)}
                                        >
                                            <div
                                                style={{
                                                    display: "flex",
                                                    gap: "8px",
                                                    alignItems: "center",
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        width: "40px",
                                                        height: "40px",
                                                        borderRadius: "250px",
                                                        alignItems: "center",
                                                        justifyContent: "ceneter",
                                                        background: "#F4F0FF",
                                                        textAlign: "center",
                                                        display: "flex",
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            color: "#7F56D9",
                                                            fontWeight: "500",
                                                            fontSize: "14px",
                                                            lineHeight: "20px",
                                                            textAlign: "center",
                                                            textTransform: "uppercase",
                                                            width: "100%",
                                                        }}
                                                    >
                                                        {" "}
                                                        {info.firstName.charAt(0)}
                                                        {info.lastName.charAt(0)}{" "}
                                                    </div>
                                                </div>
                                                <div
                                                    style={{
                                                        color: "#101828",
                                                        fontSize: "16px",
                                                        fontWeight: "500",
                                                        lineHeight: "24px",
                                                        letterSpacing: "2%",
                                                        display: "flex",
                                                        flexDirection: "column",
                                                    }}
                                                >
                                                    <span
                                                        style={{
                                                            color: "#101828",
                                                            lineHeight: "24px",
                                                            letterSpacing: "2%",
                                                            fontSize: "16px",
                                                            fontWeight: "500",
                                                        }}
                                                    >
                                                        {" "}
                                                        {info.firstName} {info.lastName}
                                                    </span>
                                                    <span
                                                        style={{
                                                            color: "#667085",
                                                            lineHeight: "16.2px",
                                                            letterSpacing: "2%",
                                                            fontSize: "12px",
                                                            fontWeight: "400",
                                                        }}
                                                    >
                                                        {" "}
                                                        {info.email}
                                                    </span>
                                                </div>
                                            </div>
                                            <img src={edit} alt="" />
                                        </div>
                                    ))}
                                {buyerdetailsinfo.length === 0 && (
                                    <div
                                        style={{
                                            background: "#fff",
                                            borderRadius: "8px",
                                            display: "flex",
                                            gap: "16px",
                                            justifyContent: "space-between",
                                            padding: "8px",
                                            alignItems: "center",
                                            height: "56px",
                                            cursor: "pointer",
                                        }}
                                        variant="primary"
                                        onClick={() => {
                                            props.handle(false);
                                            setAddNetworkModal(true);
                                        }}
                                    >
                                        <div
                                            style={{
                                                display: "flex",
                                                gap: "8px",
                                                alignItems: "center",
                                            }}
                                        >
                                            <img src={person} alt="" />
                                            <div
                                                style={{
                                                    color: "#101828",
                                                    fontSize: "16px",
                                                    fontWeight: "500",
                                                    lineHeight: "24px",
                                                    letterSpacing: "2%",
                                                }}
                                            >
                                                Someone new
                                            </div>
                                        </div>
                                        <span className="material-icons">chevron_right</span>
                                    </div>
                                )}
                                <PartnerSelect
                                    {...{
                                        partners,
                                        setPartners,
                                        selectedPartner: props.selecteditem,
                                        selectPartner: (user) => {
                                            props.setselecteditem(user);
                                            props.handle(false);
                                        },
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            <AddNetwork
                link="#"
                show={addNetWorkModal}
                close={(manualAddedUser) => {
                    if (manualAddedUser?.business && manualAddedUser?.user) {
                        props.setselecteditem(manualAddedUser);
                        setPartners((prev) =>
                            prev ? [manualAddedUser, ...prev] : [manualAddedUser]
                        );
                    }
                    setAddNetworkModal(false);
                }}
            />
        </>
    );
}

export default UploadInvoice;
