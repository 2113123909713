import { Helmet } from "react-helmet";
import { useEffect, useState } from "react";
import SerHeader from "../component/serviceheader";
import Loader from "../component/loader";
import { useLocation } from "react-router-dom";
import "../style/slider.css";
import "../style/main.css";
import axios from "axios";
import Swal from "sweetalert2";
import copy from "../asset/images/copy.svg";
import currencys from "../asset/images/GB.svg";
import currencyss from "../asset/images/EU.svg";
import NGNcurrency from "../asset/images/Currency.svg";
import US from "../asset/images/US.png";
const TopUp = () => {
  // const navigate = useNavigate();
  const { state } = useLocation();
  const [loader, setloader] = useState(false);
  const [isdisable, setisdisable] = useState(true);
  const [amount, setamount] = useState(state.amount);
  const [url, seturl] = useState("");
  const [deletes, setdelete] = useState(false);
  const [success, setsuccess] = useState(false);
  const [message, setmessage] = useState("");
  // console.log(state);
  useEffect(() => {
    if (amount !== "") {
      setisdisable(false);
    } else {
      setisdisable(true);
    }
  }, [setisdisable, state.amount]);
  const copyURL1 = async () => {
    try {
      await navigator.clipboard.writeText(url);
      setsuccess(true);
      setmessage("Copied to clipboard");

      setTimeout(() => {
        setsuccess(false);
        setdelete(false);
      }, 3000);
    } catch ($e) {
      console.log($e);
      setmessage("Failed to copy.");
      setTimeout(() => {
        setdelete(false);
        setsuccess(false);
      }, 3000);
    }
  };
  const submitHandler = async (e) => {
    e.preventDefault();
    setloader(true);
    const data = {
      currency: state.default,
      amount: amount,
      payment_type: "WalletCredit",
    };
    //  console.log(data)
    await axios
      .post("/v1/wallets/create-payment-link", data)
      .then((res) => {
        setloader(false);
        seturl(res.data.data.url);
        setsuccess(true);
        setmessage("Payment link created");
        setTimeout(() => {
          setdelete(false);
          setsuccess(false);
        }, 3000);
      })
      .catch((e) => {
        // console.log(e);
        setdelete(true);
        setloader(false);
        setmessage(e.response.data.message);

        setTimeout(() => {
          setdelete(false);
          setsuccess(false);
        }, 3000);
      });
    /*

    navigate('/transfer/type', {
        state:{


        }
    })
    */
  };

  return (
    <div className="h-100 w-100 ">
      <div
        className=""
        style={{
          maxWidth: "2000px",
          width: "100%",
          margin: "0px auto",
        }}
      >
        <Helmet>
          <title>Tradevu-Topup</title>
        </Helmet>

        <SerHeader header={"Top up"} width={25} />
      </div>
      <div
        style={{
          height: "100%",
          maxWidth: "2000px",
          margin: "0px auto",
          width: "100%",
        }}
      >
        <div
          className=" "
          style={{
            margin: "0px auto",
            paddingLeft: "30px",
            paddingRight: "30px",
            paddingTop: "10px",
            paddingBottom: "10px",
            width: "100%",
          }}
        >
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",

              justifyContent: "center",
            }}
          >
            <form
              onSubmit={submitHandler}
              className="form-general-layout"
              style={{
                position: "relative",
              }}
            >
              {success && (
                <div
                  className="success"
                  style={{
                    maxWidth: "500px",
                    margin: "0px auto",
                    width: "90%",
                    boxSizing: "border-box",
                  }}
                >
                  <div className="lg-success">
                    <div className="ech-lg-hm">
                      <div className="main-sucees">Success</div>
                      <span>{message}</span>
                    </div>
                  </div>
                </div>
              )}
              {deletes && (
                <div
                  className="success"
                  style={{
                    background: "#FCFCFD",
                    border: " 1px solid #D0D5DD",
                    maxWidth: "500px",
                    margin: "0px auto",
                    width: "90%",
                    boxSizing: "border-box",
                  }}
                >
                  <div className="lg-success">
                    <div
                      className="ech-lg-hm"
                      style={{
                        background: "#F9FAFB",
                      }}
                    >
                      <div
                        className="main-sucees"
                        style={{
                          color: "#344054",
                        }}
                      >
                        Failed
                      </div>
                      <span
                        style={{
                          color: "#344054",
                        }}
                      >
                        {message}{" "}
                      </span>
                    </div>
                  </div>
                </div>
              )}

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "32px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "8px",
                  }}
                >
                  <div
                    style={{
                      color: "#101828",
                      fontWeight: "600",
                      fontSize: "24px",
                      lineHeight: "32.4px",
                    }}
                  >
                    Recieve funds via link
                  </div>
                  <span
                    style={{
                      color: "#667085",
                      fontWeight: "500",
                      lineHeight: "24px",
                      fontSize: "1rem",
                      letterSpacing: "2%",
                    }}
                  >
                    Share a unique payment link to receive funds from anyone for
                    your business
                  </span>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "32px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "8px",
                      width: "100%",
                    }}
                  >
                    <label
                      htmlFor=""
                      style={{
                        fontSize: "14px",
                        lineHeight: "19.6px",
                        fontWeight: "500",
                        color: "#101828",
                      }}
                    >
                      Amount
                    </label>
                    <div
                      className=""
                      style={{
                        display: "flex",
                        gap: "8px",
                        flexDirection: "column",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          gap: "8px",
                        }}
                      >
                        <div
                          style={{
                            width: "100%",
                            maxWidth: "110px",
                            borderRadius: "8px",
                            border: "none",
                            outline: "none",
                            background: "",
                            padding: "8px 16px 8px 16px",
                            backgroundColor: "#ECEFF3",
                            letterSpacing: "2%",
                            fontWeight: "500",
                            fontSize: "16px",
                            display: "flex",
                            gap: "4px",
                            alignItems: "center",
                            position: "relative",
                          }}
                        >
                          {" "}
                          <img
                            style={{
                              height: "25px",
                            }}
                            src={
                              state.default === "GBP"
                                ? currencys
                                : state.default === "EUR"
                                ? currencyss
                                : state.default === "USD"
                                ? US
                                : NGNcurrency
                            }
                            alt=""
                          />{" "}
                          {state.default}
                        </div>
                        <input
                          type="number"
                          name=""
                          id=""
                          value={amount}
                          onChange={(e) => {
                            setamount(e.target.value);
                          }}
                          style={{
                            width: "100%",
                            borderRadius: "8px",
                            border: "none",
                            height: "56px",
                            outline: "none",
                            background: "",
                            padding: "8px 16px 8px 16px",
                            backgroundColor: "#ECEFF3",
                            letterSpacing: "2%",
                            fontWeight: "500",
                            fontSize: "16px",
                          }}
                          placeholder="Enter amount"
                        />
                      </div>
                    </div>
                  </div>
                  {url !== "" && (
                    <div
                      style={{
                        padding: "8px",
                        borderRadius: "8px",
                        background: "#EBE4FF",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          gap: "8px",
                          alignItems: "center",
                          width: "100%",
                          justifyContent: "space-between",
                        }}
                      >
                        <span
                          style={{
                            fontWeight: "500",
                            fontSize: "14px",
                            lineHeight: "19.6px",
                            color: "#6F00FF",
                          }}
                        >
                          {url}
                        </span>
                        <div
                          style={{
                            display: "flex",
                            gap: "8px",
                            alignItems: "center",
                          }}
                        >
                          <img
                            src={copy}
                            alt=""
                            onClick={copyURL1}
                            style={{ cursor: "pointer" }}
                          />
                        </div>
                      </div>
                    </div>
                  )}

                  <div className="">
                    <button
                      disabled={isdisable}
                      style={{
                        maxWidth: "280px",
                        width: "100%",
                        background: isdisable ? "#EBE4FF" : "#6F00FF",
                        padding: "16px 24px 16px 24px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        border: "none",
                        outline: "none",
                        minHeight: "46px",
                        borderRadius: "8px",
                        fontSize: "16px",
                        lineHeight: "24px",
                        letterSpacing: "2%",
                        fontWeight: "600",
                        color: isdisable ? "#BFA6FF" : "#fff",
                      }}
                    >
                      {loader === true ? <Loader data={loader} /> : "Generate"}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopUp;
