const sortListByDate = (list) => {
    let listReducedArr = list?.reduce((r, e) => {
        let alphabet = new Date(e.createdAt).toDateString();
        if (!r[alphabet])
            r[alphabet] = {
                alphabet,
                record: [],
            };
        r[alphabet].record.push(e);
        return r;
    }, {});

    return Object.values(listReducedArr ?? {});
};

export default sortListByDate;
