import { AddIcon, Arrow2Icon, DeleteIcon, DocumentTextIcon } from "asset/icons";
import { useRef } from "react";
import capitalizeFirstLetter from "utilities/capitalizeFirstLetter";

const SupportDocs = ({ addDocs, setAddDocs, file, setFile, showDelete }) => {
    const uploadInputRef = useRef();
    const namesArr = file?.name?.split(".");

    return (
        <>
            <p
                className="flex items-center font-[450] !mt-3 cursor-pointer"
                onClick={() => setAddDocs((prev) => !prev)}
            >
                <span
                    className={`rounded-full !mr-2 size-5 !border-[1.5px] grid place-items-center ${
                        addDocs ? "!border-secondary" : "bg-white"
                    }`}
                >
                    <span
                        className={`!bg-secondary rounded-full transition-all ease-in-out ${
                            addDocs ? "size-2" : "size-0"
                        }`}
                    />
                </span>
                Add Supporting Documents
            </p>

            {addDocs && (
                <div className="!pl-6 !mt-[9px] relative">
                    <div className="flex items-center">
                        {file && (
                            <>
                                <Document
                                    name={namesArr[0]?.replace(/[^a-zA-Z ]/g, " ").toLowerCase()}
                                    extension={namesArr[1]}
                                    deleteFile={() => setFile(null)}
                                    showDelete={showDelete}
                                />
                                {showDelete && (
                                    <DeleteIcon
                                        className="ml-1.5 350:ml-3.5 950:hidden w-[25px] h-6"
                                        onClick={() => setFile(null)}
                                    />
                                )}
                            </>
                        )}
                    </div>

                    <button
                        className="flex items-center gap-2 !py-2 text-[#1D2939] !mt-1"
                        onClick={(evt) => {
                            evt.preventDefault();
                            uploadInputRef.current?.click();
                        }}
                    >
                        <AddIcon className="*:fill-[#1D2939]" /> {file ? "Change" : "Choose"}{" "}
                        document
                    </button>
                    <input
                        type="file"
                        accept=".png,.jpeg,.jpg,.pdf"
                        ref={uploadInputRef}
                        size={10485760}
                        required={addDocs && !file}
                        className="absolute top-[20%] -left-11 -z-30 opacity-0"
                        onChange={(evt) => evt.target.files && setFile(evt.target.files[0])}
                        key={JSON.stringify(file) + "random_word"}
                    />
                </div>
            )}
        </>
    );
};

const Document = ({ name, extension, deleteFile, showDelete }) => {
    return (
        <div className="bg-white rounded-lg !p-6 max-400:!py-4 flex items-center relative w-full max-350:text-sm">
            <DocumentTextIcon className="!mr-2" />
            <span className="font-[450] text-main-dark mr-auto">
                {capitalizeFirstLetter(name ?? "")}
            </span>
            <span className="text-main-grey">{extension}</span>
            <Arrow2Icon className="!ml-[18px]" />
            {showDelete && (
                <DeleteIcon
                    className="absolute -right-10 cursor-pointer max-950:hidden"
                    onClick={deleteFile}
                />
            )}
        </div>
    );
};

export default SupportDocs;
