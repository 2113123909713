import { Helmet } from "react-helmet";
// import DashHeader from "../component/dashheader";
// import homefinance from '../asset/im
import { useEffect, useState } from "react";

import SerHeader from "../component/serviceheader";
import arrowleft from "../asset/images/arrowleftcircle.svg";
import edit from "../asset/images/edit-2.svg";
import person from "../asset/images/person.svg";
import copy from "../asset/images/document-upload.png";
import UploadInvoice from "../component/uploadinvoice";
import nigeria from "../asset/images/circular_nig.png";
import { currencies } from "../constants/currency";
import UploadModal from "../component/uploadModal";
import Uploaddocumentfinance from "../component/uploadloaddocument";
import InvoiceSuccess from "../component/invoicesuccess";
import { useNavigate } from "react-router-dom";
//import Swal from "sweetalert2/dist/sweetalert2.js";
import SuccessHodler from "../component/successholder";
import FailHandler from "../component/failhandler";

import axios from "axios";
import Loaders from "../component/loader";
import UploadInvoiceCreateFile from "../component/uploadinvoicefilecreate";
import Uploadagrremtfile from "../component/uploadcreateinvoicefile";
import CurrencyModal from "../component/currencyModal";
import PartnerSelectBtn from "component/form/partnerSelectBtn";
import capitalizeFirstLetter from "utilities/capitalizeFirstLetter";

const CreateInvoice = () => {
    // const [selectedIndex, setselectedIndex] = useState(-1)
    const navigate = useNavigate();

    const [show, setShow] = useState(false);
    const [loader, setloader] = useState(false);
    const [selecteditem, setselecteditem] = useState();
    const [selectedcurrency, setselectedcurrency] = useState([]);
    const [added, setadded] = useState(false);
    const [filename, setfilename] = useState([]);
    const [filesize, setfilesize] = useState(0);
    const [aggreement, setagrrement] = useState([]);
    const [aggreementsize, setaggreementsize] = useState(0);
    const [showModal, setshowModal] = useState(false);
    const [showModal2, setshowModal2] = useState(false);
    const [showModal3, setshowModal3] = useState(false);
    const [defaultcurrency] = useState("NGN");
    const [businessname, setbusinessname] = useState("");
    const [buyer, setbuyers] = useState([]);
    const [supplier, setsuppliers] = useState([]);
    const [amount, setamount] = useState("");
    const [tab, settab] = useState("buyer");
    const [success, setsuccess] = useState(false);
    const [failhandler, setfailhandler] = useState(false);
    const [message, setmessage] = useState(" ");
    const [showcurrency, setshowcurrency] = useState(false);

    const [showdrop, setisshowdrop] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const addseletedcurrency = (currency) => {
        setadded(true);

        setselectedcurrency(currency);
    };
    useEffect(() => {
        const fetchId = async () => {};
        fetchId();
    }, []);

    useEffect(() => {
        let timer = setTimeout(() => {
            setfailhandler(false);
            setsuccess(false);
        }, 3000);
        return () => {
            clearTimeout(timer);
        };
    });

    const submitHandler = async (e) => {
        e.preventDefault();

        setloader(true);
        const formdata = new FormData();
        formdata.append("agreement", aggreement, aggreement.name);
        formdata.append("invoiceDocument", filename, filename.name);
        await axios
            .post(`/v1/finance/finance_invoice/upload`, formdata)
            .then((res) => {
                const data = {
                    firstName: selecteditem.firstName,
                    lastName: selecteditem.lastName,
                    email: selecteditem.email,
                    requestedAmount: amount,
                    phoneNumber: selecteditem.phoneNumber,
                    userType: tab.toUpperCase(),
                    currency:
                        selectedcurrency.length === 0 ? defaultcurrency : selectedcurrency.name,
                };

                axios
                    .post(`/v1/finance/finance_invoice/create/${res.data.data._id}`, {
                        ...data,
                        businessName: businessname,
                    })
                    .then(() => {
                        axios.post(`/v1/finance/request_invoice/verification/${res.data.data._id}`);
                        setloader(false);
                        setshowModal3(true);
                    })
                    .catch((e) => {
                        setloader(false);
                        setmessage(
                            e.response?.data.message ? e.response?.data.message : "An error ocuur"
                        );
                        setfailhandler(true);
                    })

                    .catch((e) => {
                        setloader(false);
                        setloader(false);
                        setmessage(
                            e.response?.data.message ? e.response?.data.message : "An error ocuur"
                        );
                        setfailhandler(true);
                    });
            })
            .catch((e) => {
                setloader(false);

                setmessage(e.response?.data.message ? e.response?.data.message : "An error ocuur");
                setfailhandler(true);
            });
    };

    const isdisable =
        !selecteditem ||
        !amount ||
        !selectedcurrency ||
        aggreement.length === 0 ||
        filename.length === 0;

    return (
        <div
            className="h-100 w-100 "
            style={{
                background: "#F6F6F6",
            }}
        >
            <div
                className=""
                style={{
                    maxWidth: "2000px",
                    width: "100%",
                    margin: "0px auto",
                }}
            >
                <Helmet>
                    <title>Invoice</title>
                </Helmet>

                <SerHeader header={"Invoice Factoring"} />
                <InvoiceSuccess
                    set={"finance"}
                    show={showModal3}
                    setshow={setshowModal3}
                    amount={amount}
                    currency={selectedcurrency.length === 0 ? "NGN" : selectedcurrency.name}
                />
                <Uploadagrremtfile
                    show={showModal2}
                    setshow={setshowModal2}
                    filename={aggreement}
                    filesize={aggreementsize}
                    setfilesize={setaggreementsize}
                    setfilename={setagrrement}
                />
                <UploadInvoiceCreateFile
                    filesize={filesize}
                    show={showModal}
                    setshow={setshowModal}
                    filename={filename}
                    setfilesize={setfilesize}
                    setfilename={setfilename}
                />
                <UploadInvoice
                    show={show}
                    handle={handleClose}
                    selecteditem={selecteditem}
                    setbusinessname={setbusinessname}
                    setselecteditem={setselecteditem}
                />
                <CurrencyModal
                    addseletedcurrency={addseletedcurrency}
                    show={showcurrency}
                    setshow={setshowcurrency}
                    setcurrency={setselectedcurrency}
                />
            </div>
            <div style={{ height: "100%" }}>
                <form
                    className="conatiner "
                    onSubmit={submitHandler}
                    style={{
                        maxWidth: "2000px",
                        margin: "0px auto",
                        paddingLeft: "30px",
                        paddingRight: "30px",
                        paddingTop: "10px",
                        paddingBottom: "10px",
                        width: "100%",
                    }}
                >
                    <div
                        style={{
                            height: "100%",
                            width: "100%",
                            display: "flex",

                            justifyContent: "center",
                        }}
                    >
                        <div
                            className="form-general-layout"
                            style={{
                                position: "relative",
                            }}
                        >
                            <SuccessHodler success={success} message={message} />
                            <FailHandler success={failhandler} message={message} />
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: "32px",
                                }}
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        gap: "16px",
                                    }}
                                >
                                    <div
                                        onClick={() => {
                                            navigate(-1);
                                        }}
                                    >
                                        <img src={arrowleft} alt="" />
                                    </div>
                                    <div
                                        style={{
                                            color: "#101828",
                                            fontWeight: "500",
                                            fontSize: "24px",
                                            lineHeight: "32.4px",
                                        }}
                                    >
                                        Upload Invoice
                                    </div>
                                </div>
                                <div
                                    className=""
                                    style={{
                                        display: "flex",
                                        gap: "24px",
                                        flexDirection: "column",
                                    }}
                                >
                                    <div
                                        style={{
                                            background: "#ECEFF3",
                                            borderRadius: "8px",
                                            padding: "4px",
                                            display: "flex",
                                            gap: "8px",
                                            justifyContent: "space-between",
                                            height: "56px",
                                        }}
                                    >
                                        <div
                                            style={{
                                                padding: "6px 8px 6px 8px",
                                                background: tab === "buyer" ? "#fff" : "",
                                                textAlign: "center",
                                                borderRadius: "4px",
                                                width: "50%",
                                                cursor: "pointer",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                display: "flex",
                                            }}
                                            onClick={() => settab("buyer")}
                                        >
                                            To Buyer
                                        </div>
                                        <div
                                            style={{
                                                background: tab === "supplier" ? "#fff" : "",

                                                padding: "6px 8px 6px 8px",
                                                textAlign: "center",
                                                borderRadius: "4px",
                                                width: "50%",
                                                cursor: "pointer",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                display: "flex",
                                            }}
                                            onClick={() => settab("supplier")}
                                        >
                                            From Supplier
                                        </div>
                                    </div>
                                    <>
                                        <div
                                            className=""
                                            style={{
                                                display: "flex",
                                                gap: "8px",
                                                flexDirection: "column",
                                            }}
                                        >
                                            <PartnerSelectBtn
                                                handleShow={handleShow}
                                                label={capitalizeFirstLetter(tab)}
                                                partner={selecteditem}
                                                subLabel={`Enter ${tab}’s details`}
                                            />
                                        </div>
                                        <div
                                            className=""
                                            style={{
                                                display: "flex",
                                                gap: "8px",
                                                flexDirection: "column",
                                            }}
                                        >
                                            <label
                                                htmlFor=""
                                                style={{
                                                    color: "#344054",
                                                    fontWeight: "600",
                                                    lineHeight: "19.6px",
                                                    fontSize: "14px",
                                                }}
                                            >
                                                Requested amount
                                            </label>
                                            <div
                                                style={{
                                                    display: "flex",
                                                    gap: "8px",
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        width: "100%",
                                                        maxWidth: "96px",
                                                        borderRadius: "8px",
                                                        border: "none",
                                                        outline: "none",
                                                        background: "",
                                                        padding: "8px 16px 8px 16px",
                                                        backgroundColor: "#ECEFF3",
                                                        letterSpacing: "2%",
                                                        fontWeight: "400",
                                                        fontSize: "16px",
                                                        display: "flex",
                                                        gap: "4px",
                                                        alignItems: "center",
                                                        position: "relative",
                                                    }}
                                                    onClick={() => {
                                                        // setisshowdrop(!showdrop);
                                                        setshowcurrency(true);
                                                    }}
                                                >
                                                    {" "}
                                                    {showdrop === true && (
                                                        <div
                                                            style={{
                                                                position: "absolute",
                                                                background: "#fff",
                                                                padding: "8px",
                                                                width: "100%",
                                                                top: "40px",
                                                                left: "-2px",
                                                                borderRadius: "4px",
                                                                zIndex: "2",
                                                            }}
                                                        >
                                                            <div
                                                                style={{
                                                                    display: "flex",
                                                                    flexDirection: "column",
                                                                    gap: "16px",
                                                                }}
                                                            >
                                                                {" "}
                                                                {currencies.map(
                                                                    (currency, index) => (
                                                                        <div
                                                                            style={{
                                                                                display: "flex",
                                                                                gap: "8px",
                                                                                lineHeight:
                                                                                    "19.6px",
                                                                                cursor: "pointer",
                                                                            }}
                                                                            key={index}
                                                                            onClick={() => {
                                                                                addseletedcurrency(
                                                                                    currency
                                                                                );
                                                                            }}
                                                                        >
                                                                            <img
                                                                                src={currency.img}
                                                                                alt=""
                                                                                height={24}
                                                                                width={24}
                                                                            />
                                                                            <span
                                                                                style={{
                                                                                    color: "#667085",
                                                                                    letterSpacing:
                                                                                        "2%",
                                                                                    lineHeight:
                                                                                        "24px",
                                                                                    fontSize:
                                                                                        "16px",
                                                                                }}
                                                                            >
                                                                                {" "}
                                                                                {currency.name}
                                                                            </span>
                                                                        </div>
                                                                    )
                                                                )}{" "}
                                                            </div>
                                                        </div>
                                                    )}
                                                    <img
                                                        src={added ? selectedcurrency.img : nigeria}
                                                        alt=""
                                                        height={24}
                                                        width={24}
                                                    />
                                                    <span
                                                        style={{
                                                            color: "#667085",
                                                            letterSpacing: "2%",
                                                            lineHeight: "24px",
                                                            fontSize: "16px",
                                                        }}
                                                    >
                                                        {" "}
                                                        {added === true
                                                            ? selectedcurrency.name
                                                            : "NGN"}
                                                    </span>
                                                </div>
                                                <input
                                                    type="number"
                                                    name=""
                                                    id=""
                                                    step={0.5}
                                                    onChange={(e) => {
                                                        setamount(e.target.value);
                                                    }}
                                                    style={{
                                                        width: "100%",
                                                        borderRadius: "8px",
                                                        border: "none",
                                                        outline: "none",
                                                        height: "56px",
                                                        background: "",
                                                        padding: "8px 16px 8px 16px",
                                                        backgroundColor: "#ECEFF3",
                                                        letterSpacing: "2%",
                                                        fontWeight: "400",
                                                        fontSize: "16px",
                                                    }}
                                                    placeholder="Enter amount"
                                                />
                                            </div>
                                        </div>
                                        <div
                                            className=""
                                            style={{
                                                display: "flex",
                                                gap: "8px",
                                                flexDirection: "column",
                                            }}
                                        >
                                            <label
                                                htmlFor=""
                                                style={{
                                                    color: "#344054",
                                                    fontWeight: "600",
                                                    lineHeight: "19.6px",
                                                    fontSize: "14px",
                                                }}
                                            >
                                                Upload invoice
                                            </label>
                                            <div style={{ position: "relative" }}>
                                                <div
                                                    style={{
                                                        width: "100%",
                                                        borderRadius: "8px",
                                                        border: "none",
                                                        outline: "none",
                                                        background: "",
                                                        minHeight: "56px",
                                                        padding: "8px 16px 8px 16px",
                                                        backgroundColor: "#ECEFF3",
                                                        letterSpacing: "2%",
                                                        fontWeight: "400",
                                                        fontSize: "16px",
                                                        position: "relative",
                                                        display: "flex",
                                                        justifyContent: "space-between",
                                                        alignItems: "center",
                                                    }}
                                                    variant="primary"
                                                    onClick={() => {
                                                        setfilename([]);
                                                        setfilesize(0);
                                                        setshowModal(true);
                                                    }}
                                                >
                                                    <span
                                                        style={{
                                                            color: "#667085",
                                                            fontSize: "16px",
                                                            lineHeight: "24px",
                                                            letterSpacing: "2%",
                                                        }}
                                                    >
                                                        {" "}
                                                        {filename?.length !== 0 &&
                                                            filename?.name}{" "}
                                                        {filename?.length !== 0 &&
                                                            parseFloat(filesize / 1024).toFixed(2)}
                                                        {filename?.length !== 0 && (
                                                            <>
                                                                {filesize !== 0 &&
                                                                parseFloat(filesize) / 1024 >= 1024
                                                                    ? "MB"
                                                                    : "KB"}
                                                            </>
                                                        )}
                                                        {filename?.length === 0 &&
                                                            "Tap to upload invoice"}{" "}
                                                    </span>
                                                    <img src={copy} alt="" height={20} width={20} />
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className=""
                                            style={{
                                                display: "flex",
                                                gap: "8px",
                                                flexDirection: "column",
                                            }}
                                        >
                                            <label
                                                htmlFor=""
                                                style={{
                                                    color: "#344054",
                                                    fontWeight: "600",
                                                    lineHeight: "19.6px",
                                                    fontSize: "14px",
                                                }}
                                            >
                                                Upload customer agreement
                                            </label>
                                            <div style={{ position: "relative" }}>
                                                <div
                                                    style={{
                                                        width: "100%",
                                                        borderRadius: "8px",
                                                        border: "none",
                                                        outline: "none",
                                                        background: "",
                                                        minHeight: "56px",
                                                        padding: "8px 16px 8px 16px",
                                                        backgroundColor: "#ECEFF3",
                                                        letterSpacing: "2%",
                                                        fontWeight: "400",
                                                        fontSize: "16px",
                                                        position: "relative",
                                                        display: "flex",
                                                        justifyContent: "space-between",
                                                        alignItems: "center",
                                                    }}
                                                    variant="primary"
                                                    onClick={() => {
                                                        setaggreementsize(0);
                                                        setagrrement([]);

                                                        setshowModal2(true);
                                                    }}
                                                >
                                                    <span
                                                        style={{
                                                            color: "#667085",
                                                            fontSize: "16px",
                                                            lineHeight: "24px",
                                                            letterSpacing: "2%",
                                                        }}
                                                    >
                                                        {" "}
                                                        {aggreement.length !== 0 && aggreement.name}
                                                        {aggreement.length !== 0 &&
                                                            parseFloat(
                                                                aggreementsize / 1024
                                                            ).toFixed(2)}
                                                        {aggreement.length !== 0 && (
                                                            <>
                                                                {aggreementsize !== 0 &&
                                                                parseFloat(aggreementsize) / 1024 >=
                                                                    1024
                                                                    ? "MB"
                                                                    : "KB"}
                                                            </>
                                                        )}
                                                        {aggreement.length === 0 &&
                                                            "Upload document"}{" "}
                                                    </span>
                                                    <img src={copy} alt="" height={20} width={20} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mb-5">
                                            <button
                                                disabled={isdisable}
                                                type="submit"
                                                style={{
                                                    width: "280px",
                                                    background: isdisable ? "#EBE4FF" : "#6F00FF",
                                                    height: "48px",
                                                    padding: "16px 24px 16px 24px",

                                                    border: "none",
                                                    outline: "none",
                                                    borderRadius: "8px",
                                                    fontSize: "16px",
                                                    lineHeight: "24px",
                                                    letterSpacing: "2%",
                                                    fontWeight: "500",
                                                    color: isdisable ? "#BFA6FF" : "#ffff",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                }}
                                            >
                                                {loader ? <Loaders data={loader} /> : "Submit"}
                                            </button>
                                        </div>
                                    </>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default CreateInvoice;
