import Modal from "react-bootstrap/Modal";
import "../style/main.css";
import "../style/rightside.css";
import axios from "axios";
function AddFlexInvoice(props) {
  const filterBuyer =  props.invoice.filter((item) =>
  item?.currency?.match(props?.s?.currency)
);
  const closeModal = () => {
    var datashow = props.show;
    props.setshow(!datashow);
  };
  filterBuyer.sort((a, b) =>
    a.createdAt.localeCompare(b.createdAt, "es", { sensitivity: "base" })
  );
  let data = filterBuyer.reduce((r, e) => {
    let alphabet = new Date(e.createdAt).toDateString();

    if (!r[alphabet])
      r[alphabet] = {
        alphabet,
        record: [],
      };

    r[alphabet].record.push(e);
    return r;
  }, {});
  let result = Object.values(data);
  const addInvoiceToOrder = (value)=>{
    axios.post(`/v1/flex/order/invoice/${props?.s?._id}`,{invoiceId : value?._id}).then((res)=>{
      axios.get(`/v1/flex/order/${props.s._id}`).then((res)=>{
        console.log(res)
        console.log(value)
        //props.getsingleflexInvoce()
        props.restartOrder()
    //  props.selectedorderinvoice([...props.orderInvoice,res.data.data])

    }).catch((e)=>{
      console.log(e)
    })
      props.setmessage(res.data?.message)
      props.setshow(false)
      props.sett('Success')
      props.setsuccess(true)
      props?.setfail(false)


    }).catch((e)=>{
      console.log(e)
      props.setmessage(e.response?.data  ? e.response?.data?.message : 'An error occur')
      props.sett('Failed')
      props.setsuccess(false)
      props.setshow(false)
      props?.setfail(true)

    })
  }
  return (
    <Modal
      show={props.show}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="border-0 "
    >
      <Modal.Body
        className="border-0"
        style={{
          borderRadius: "16px",
          padding: "0px",
          border: "none",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
          }}
        >
          <div
            className=""
            style={{
              borderBottom: "1px solid #E7E9FB",
              padding: "8px 24px 8px 24px",
            }}
          >
            <span
              className="material-icons"
              style={{ color: "#667085" }}
              onClick={closeModal}
            >
              close
            </span>
          </div>
          <div className="invoice_add">
            <div
              className="body-invoice-a"
              style={{
                marginBottom: "20px",
              }}
            >
              <h4 style={{ fontWeight: "500" }}>Add Invoice</h4>
              {
                result.length === 0 && <div style={{
                  minHeight:'150px',
                  justifyContent:'center',
                  alignItems:'center',
                  padding:'10px',
                  display:'flex'
                }}>No Flex Invoice </div>
                
                 
              }
              {result.reverse().map((res, index) => (
                <div
                  className="top-lg-mk"
                  style={{
                    padding: "0px",
                    border: "none",
                    cursor: "pointer",
                  }}
                  key={index}
                  
                >
                  <h5
                    style={{
                      fontWeight: "400",
                    }}
                  >
                    {res.alphabet}
                  </h5>

                  <div
                    className="tp-lg-mk-fl"
                    style={{
                      background: "#fff",
                      padding: "0px",
                    }}
                  >
                    {res.record.reverse().map((response, index) => (
                      <div
                        className="main-each-lg"
                        style={{
                          border: "none",
                          overflow:''
                        }}

                        key={index}
                        onClick={()=>{
                          addInvoiceToOrder(response)
                        }}
                      >
                        <div className="lg-sie-avater-lg" >
                          <div className="intial">
                            {response?.invoiceTitle?.slice(0, 2)}
                          </div>

                          <div className="top-tier-lg">
                            <h4>{response?.invoiceTitle}</h4>
                            <span>
                              {new Date(response.createdAt).toDateString()}
                            </span>
                          </div>
                        </div>
                        <div className="top-tier-lg">
                          <h4>
                            {response.currency === "GPB"
                              ? "£"
                              : response.currency === "NGN"
                              ? "₦"
                              : "€"}
                            {parseFloat(response?.totalAmount).toLocaleString()}
                          </h4>
                          <span
                            style={{
                              textAlign: "right",
                            }}
                          >
                            {response?.invoiceItems?.length} Item(s)
                          </span>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default AddFlexInvoice;
