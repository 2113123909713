import { useState } from "react";

import Modal from "react-bootstrap/Modal";
// import {useNavigate} from 'react-router-dom/dist';
import axios from "axios";
import Swal from "sweetalert2/dist/sweetalert2.js";
import Loaders from "../component/loader";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import yay from "../asset/images/yay.svg";
import "../style/main.css";
import "../style/rightside.css";

function Example(props) {
  const [show, setShow] = useState(false);

  // const dispatch = useDispatch()
  // const selector = useSelector(state => state.user)
  const navigate = useNavigate();
  const selector = useSelector((state) => state.users);
  const [loader, setLoader] = useState(false);
  const reDirect = () => {
    navigate("/dashboard");
  };
  const reDirectBusiness = () => {
    navigate("/business/document");
  };

  const handleShow = async (e) => {
    setLoader(true);
    e.preventDefault();
    // const pin = ["1", "2", "3", "4"];
    const [pin1, pin2, pin3, pin4] = props.code;

    const numericPin = pin1 + "" + pin2 + "" + pin3 + "" + pin4;

    const data = {
      pin: numericPin,
    };
    await axios
      .post("/v1/users/create-pin", data)
      .then(() => {
        // setShow(true)
        setShow(true);
        setLoader(false);
      })
      .catch((e) => {
        console.log(e);
        Swal.fire({
          title: "Error !",
          text: e.response.data.message,
          icon: "error",
          confirmButtonText: "OK",
          width: "280px",
          confirmButtonColor: "rgb(111, 0, 255)",
        }).then(() => {
          setShow(true);
          setLoader(false);
        });
      });
  };

  return (
    <>
      <button
        disabled={loader}
        onClick={handleShow}
        type="submit"
        className="btn   mr-2"
        style={{
          background: "#6F00FF",
          color: "#fff",
          fontSize: "1rem",
          borderRadius: "8px",
          fontWeight: "500px",
          maxWidth: "200px",
          width: "100%",
          lineHeight: "24px",
          letterSpacing: "2%",
          padding: "16px 24px 16px 24px",
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
        }}
      >
        {loader === true ? <Loaders data={loader} /> : "Set pin"}
      </button>

      <Modal
        className="primaryModal"
        show={show}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Body style={{ border: "none !important" }}>
          <div className="w-100 ">
            <img src={yay} alt="" />
          </div>
          <div
            className="w-100  "
            style={{
              boxSizing: "border-box",
              overflow: "hidden",
              left: "0",
              padding: "24px",
            }}
          >
            <div className="p-2 ">
              <div
                style={{
                  color: "#D9CDFF",
                  fontSize: "14px",
                  fontWeight: "500",
                  lineHeight: "19.6px",
                }}
              >
                <span>Hi {selector.firstName},</span>
              </div>
              <h4
                style={{
                  color: "#FFFFFF",
                  fontSize: "24px",
                  lineHeight: "32.4px",
                }}
              >
                Welcome to Tradevu.
              </h4>

              <p
                style={{
                  fontSize: "14px",
                  color: "#EBE4FF",
                  fontWeight: "500",
                  lineHeight: "19.6px",
                }}
              >
                Before you continue, we need you to complete your KYC
                information
              </p>
              <button
                className="btn  w-100 mt-2 "
                type="button"
                style={{
                  color: "#6F00FF",
                  background: "#F4F0FF",
                  fontSize: "1rem",
                  fontWeight: "700",
                  padding: "16px 24px 16px 24px",
                  border: "none",
                }}
                onClick={reDirectBusiness}
              >
                Continue
              </button>

              <button
                className="btn  w-100 mt-2 "
                type="button"
                style={{
                  color: "#EBE4FF",
                  background: "#5E01D6",
                  fontSize: "1rem",
                  fontWeight: "700",
                  padding: "16px 24px 16px 24px",
                }}
                onClick={reDirect}
              >
                Enter Tradevu
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Example;
