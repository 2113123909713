import arrow from "asset/images/arrowbackright.svg";
import { useNavigate } from "react-router-dom";

const ArrowBack = ({ className }) => {
    const navigate = useNavigate();

    return (
        <img
            src={arrow}
            alt="to back"
            onClick={() => navigate(-1)}
            className={"size-8 cursor-pointer " + className}
        />
    );
};

export default ArrowBack;
