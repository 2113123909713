import Modal from "react-bootstrap/Modal";
//import success from "../asset/images/success.svg";
import { useRef, useState } from "react";
import Loader from "./loader";
import "../style/main.css";
import "../style/rightside.css";

function ConfrimPin(props) {
  const [pins, setPins] = useState(props.pincode);
  const [loader, setloader] = useState(false);
  const inputs = useRef([]);

  const handleInputChange = (event, index) => {
    const currentInput = event.target.value.trim();

    if (/^\d$/.test(currentInput)) {
      const newPins = [...pins];
      newPins[index] = currentInput;
      setPins(newPins);
      if (index < inputs.current.length - 1 && currentInput) {
        inputs.current[index + 1].focus();
      }
    }
  };

  const handleInputBackspace = (event, index) => {
    const currentInput = event.target.value.trim();
    console.log(currentInput);

    if (event.key === "Backspace" && !currentInput && index > 0) {
      const newPins = [...pins];
      newPins[index - 1] = "";

      setPins(newPins);
      inputs.current[index - 1].focus();
    }
  };
  return (
    <Modal
      show={props.show}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="border-0 "
      onHide={() => props.setshow(false)}
    >
      <Modal.Body
        style={{
          borderRadius: "16px",
          padding: "0px",
          border: "none",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "16px",
          }}
        >
          <div
            style={{ padding: "8px 24px 8px 24px" }}
            className="material-icons myclose-pin"
            onClick={() => props.setshow(false)}
          >
            keyboard_backspace
          </div>
          <div className="mybody-pin-lg">
            <div className="box-lg-ain">
              <div className="mybody-lgh-th">
                <div className="title-header-lg">
                  <h4
                    style={{
                      fontWeight: "500",
                    }}
                  >
                    Enter transaction PIN{" "}
                  </h4>
                  <span
                    style={{
                      fontWeight: "400",
                    }}
                  >
                    Enter your 4-digit PIN to confirm transaction.
                  </span>
                </div>
                <form action="">
                  <div
                    className="row"
                    style={{
                      maxWidth: "350px",
                      width: "100%",
                    }}
                  >
                    {pins.map((pin, index) => (
                      <div className="col" key={index}>
                        <div className="form-group">
                          <input
                            key={index}
                            type="password"
                            className="form-control border-0"
                            style={{
                              background: "#ECEFF3",
                              color: "#667085",
                              padding: "8px 16px 8px 16px",
                              textAlign: "center",
                              height: "53px",
                              minWidth: "56px",
                            }}
                            placeholder="-"
                            maxLength="1"
                            value={pin}
                            ref={(element) => (inputs.current[index] = element)}
                            onChange={(event) =>
                              handleInputChange(event, index)
                            }
                            onKeyDown={(event) =>
                              handleInputBackspace(event, index)
                            }
                          />
                        </div>
                      </div>
                    ))}{" "}
                    <div className="btn-con-dil">
                      <button
                        disabled={loader}
                        className="btn  w-100 mt-2 "
                        type="button"
                        style={{
                          color: "#EBE4FF",
                          background: "#5E01D6",
                          fontSize: "1rem",
                          fontWeight: "700",
                          padding: "16px 24px 16px 24px",
                          justifyContent: "center",
                          alignItems: "center",
                          display: "flex",
                        }}
                        onClick={() => {
                          props.setPins(pins);
                          props.sendRequest(pins);
                          setloader(true);
                        }}
                      >
                        {props.loader === true ? <Loader /> : "Continue"}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default ConfrimPin;
