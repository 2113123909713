//import {useState} from 'react';
import Modal from "react-bootstrap/Modal";
import "../style/rightside.css";
import "../style/main.css";
import SuccessHodler from "./successholder";
import FailHandler from "./failhandler";
import Loader from "./loader";

function ProfileUpdateModal(props) {
  return (
    <>
      <Modal
        show={props.show}
        backdrop="false"
        keyboard={false}
        onHide={() => props.setshow(false)}
        className="rightsidemodal"
      >
        <div
          className=""
          style={{
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              height: "calc(100% - 100px)",
              width: "100%",
              padding: "20px",
            }}
          >
            <SuccessHodler
              success={props?.successholder}
              message={props?.message}
            />
            <FailHandler
              success={props?.failhandler}
              message={props?.message}
            />
            <div
              className="w-100"
              style={{ height: "100%", marginTop: "40px" }}
            >
              <div
                className="w-100 "
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "16px",
                }}
              >
                <div className="d-flex gap-3 align-items-center">
                  <span
                    className="material-icons"
                    style={{
                      color: "#667085",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      props.setshow(false);
                    }}
                  >
                    arrow_back
                  </span>
                  <span
                    style={{
                      fontSize: "14px",
                      color: "#667085",
                      lineHeight: "18.9px",
                      fontWeight: "500",
                      textTransform: "uppercase",
                    }}
                  >
                    back to profile
                  </span>
                </div>
                <div
                  className=""
                  style={{
                    fontSize: "32px",
                    lineHeight: "40px",
                    fontWeight: "500",
                    color: "#101828",
                  }}
                >
                  Update Profile
                </div>
              </div>
              <div className="change-profile" style={{ marginTop: "20px" }}>
                <div className="intial">
                  {props?.user?.businessImage ? (
                    <img
                      src={`${props?.user?.businessImage?.url}`}
                      alt=""
                      style={{
                        height: "100%",
                        width: "100%",
                        borderRadius: "50%",
                      }}
                    />
                  ) : (
                    <>{props?.user?.firstName?.slice(0, 2)}</>
                  )}
                </div>
                <span>
                  <input
                    type="file"
                    name=""
                    id=""
                    onChange={(e) => props?.uploadImage(e.target.files[0])}
                    style={{
                      opacity: "0",
                      visibility: "0",
                      position: "absolute",
                    }}
                  />
                  Change
                </span>
              </div>
              <div className="w-100  d-flex">
                <form
                  className=""
                  style={{
                    marginTop: "30px",
                    minWidth: "416px",
                    display: "flex",
                    flexDirection: "column",
                    gap: "64px",
                  }}
                  onSubmit={(e) => props?.update(e)}
                >
                  <div className="bankstatement-holder">
                    <div className="form-head-statement">
                      <div className="each-mainsecgemntt">
                        <label htmlFor="">state</label>
                        <input
                          type="text"
                          required
                          value={props?.state}
                          onChange={(e) => props?.setstate(e.target.value)}
                        />
                      </div>
                      <div className="each-mainsecgemntt">
                        <label htmlFor="">Address</label>
                        <input
                          type="text"
                          required
                          value={props?.address}
                          onChange={(e) => props?.setaddress(e.target.value)}
                        />
                      </div>
                      {/**
                      <div className="each-mainsecgemntt">
                        <label htmlFor="">Email</label>
                        <input
                          type="email"
                          required
                          placeholder="Enter your email"
                          value={props?.user?.email}
                        />
                      </div>
                      <div className="each-mainsecgemntt">
                        <label htmlFor="">Phone Number</label>
                        <input
                          type="email"
                          required
                          placeholder="Enter your email"
                          value={props?.user?.phoneNumber}
                        />
                      </div>
                      */}
                    </div>
                    <div className="each-profile-input">
                      <label htmlFor="">Select a valid ID</label>
                      <select
                        type="text"
                        onChange={(e) => {
                          props?.setmeans(e.target.value);
                        }}
                      >
                        {props?.documenttype.map((document, index) => (
                          <option value={document} index={index}>
                            {document}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div
                      className="uploadfile-each"
                      style={{
                        position: "relative",
                        marginTop: "5px",
                      }}
                      onClick={() => props?.setdocument([])}
                    >
                      {props?.document.length === 0 && (
                        <input
                          type="file"
                          name=""
                          accept=".pdf, .jpg, .jpeg, .png"
                          id=""
                          style={{
                            position: "absolute",
                            opacity: "0",
                            visibility: "0",
                            width: "100%",
                            height: "100%",
                          }}
                          onChange={(e) =>
                            props?.setdocument(e.target.files[0])
                          }
                        />
                      )}

                      <img src={props?.upload} alt="" />
                      {props?.document.length !== 0 && (
                        <div
                          style={{
                            color: "#6F00FF",
                            fontSize: "16px",
                            fontWeight: "400",
                            lineHeight: "19.6px",
                          }}
                        >
                          {props?.document.name}
                        </div>
                      )}
                      {props?.document.length === 0 && (
                        <div className="text-img-lg">
                          <span className="head-tag-gl">Upload Document</span>
                          <span className="small-text-lg-pt">
                            JPEG, PNG, JPG, PDF
                          </span>
                          <span className="small-text-lg-pt">
                            Max. file size: 20.0 MB
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                  <button
                    className="btn-send-request"
                    style={{
                      width: "100%",
                      minWidth: "416px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    type="submit"
                  >
                    {props?.loaderupdate ? <Loader /> : "Save Changes"}
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default ProfileUpdateModal;
