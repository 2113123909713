import LoginHeader from "../component/loginheader";
import "../style/index.css";
// import {useState} from 'react';
import ArrowBack from "../component/arrowback";
import { Helmet } from "react-helmet";
import { useLocation, useNavigate } from "react-router-dom";
import document from "../asset/images/document.png";
import { useEffect, useState } from "react";
import DirectorModalBvn from "../component/directorbvn";
import axios from "axios";
import marksuccess from "../asset/images/marksuccess.svg";
import Loader from "../component/loader";
import FailHandler from "../component/failhandler";
import { Circles } from "react-loader-spinner";
import Congratulations from "../component/congratulation";

// import {useNavigate} from "react-router-dom";

const ManyOtherDocument = () => {
  const navigate = useNavigate();

  const [documentOne, setDocumentOne] = useState(null);
  const [documentTwo, setDocumentTwo] = useState(null);
  const [documentThree, setDocumentThree] = useState(null);
  const [doucumentFour, setDocumentFour] = useState(null);
  const [doucumentFive, setDocumentFive] = useState(null);
  const [doucumentSix, setDocumentSix] = useState(null);
  const [doucumentEight, setDocumentEight] = useState(null);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [fails, setfails] = useState("");

  const [fileUploadedOne, setFileUpladedOne] = useState(false);
  const [fileUploadedTwo, setFileUpladedTwo] = useState(false);
  const [fileUploadedThree, setFileUploadedThree] = useState(false);
  const [fileUploadedFour, setFileUploadedFour] = useState(false);
  const [fileUploadedFive, setFileUploadedFive] = useState(false);
  const [fileUploadedSix, setFileUploadedSix] = useState(false);
  const [fileUploadedEight, setFileUploadedEight] = useState(false);
  const [fileUploadedBvn, setfileUploadedBvn] = useState(false);
  const [director, setDirector] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [loader, setloader] = useState(false);
  const [loader1, setloader1] = useState(false);
  const [loader2, setloader2] = useState(false);
  const [loader3, setloader3] = useState(false);
  const [loader4, setloader4] = useState(false);
  const [loader5, setloader5] = useState(false);
  const [loader6, setloader6] = useState(false);
  const [loader8, setloader8] = useState(false);
  const [showcongrats, setshowcongrats] = useState(false);
  const [isUploaded1, setisUploaded1] = useState(false);
  const [isUploaded2, setisUploaded2] = useState(false);
  const [isUploaded3, setisUploaded3] = useState(false);

  const { state } = useLocation();
  const formData = new FormData();
  useEffect(() => {
    if (fails) {
      let timerr = setTimeout(() => {
        setfails(false);
      }, 3000);

      return () => {
        clearTimeout(timerr);
      };
    }
  });

  const showModalHandle = () => {
    setShowModal(true);
  };

  const handleFileOne = async (e) => {
    setDocumentOne(e.target.files[0]);
    setError(true);
    // setErrorMessage("Uploading")
    setloader1(true);

    // console.log(documentOne);

    formData.append("CAC_FORM_2", e.target.files[0], e.target.files[0].name);
    await axios
      .post(
        `v1/business/upload-business-documents/${state.businessId}?businessType=${state.businessType}`,
        formData
      )
      .then((res) => {
        setError(false);
        setDocumentOne(true);
        setFileUpladedOne(true);
        setError(false);
        setloader(false);
        setloader1(false);
      })
      .catch((e) => {
        setError(true);
        setfails(true);
        // console.log(e);
        setErrorMessage(
          e?.response?.data?.message
            ? e?.response?.data.message
            : "An error occurred"
        );
      });
  };
  const handleFileTwo = async (e) => {
    setDocumentTwo(e.target.files[0]);

    setError(true);
    setErrorMessage("Uploading");
    setloader2(true);

    //console.log(documentTwo);

    formData.append("CAC_FORM_2_1", e.target.files[0], e.target.files[0].name);
    await axios
      .post(
        `v1/business/upload-business-documents/${state.businessId}?businessType=${state.businessType}`,
        formData
      )
      .then((res) => {
        setFileUpladedTwo(true);
        setError(false);
        setloader2(false);
      })
      .catch((e) => {
        setError(true);
        setfails(true);
        // console.log(e);
        setErrorMessage(
          e?.response?.data?.message
            ? e?.response?.data.message
            : "An error occurred"
        );
        console.log(e);
      });
  };
  const handleFileThree = async (e) => {
    setDocumentThree(e.target.files[0]);

    setError(true);
    setErrorMessage("Uploading");
    setFileUploadedThree(true);
    setloader3(true);
    // console.log(documentThree);

    formData.append("CAC_FORM_3", e.target.files[0], e.target.files[0].name);
    await axios
      .post(
        `v1/business/upload-business-documents/${state.businessId}?businessType=${state.businessType}`,
        formData
      )
      .then((res) => {
        //console.log(res);
        setFileUploadedThree(true);
        setError(false);
        setloader3(false);
      })
      .catch((e) => {
        setError(true);
        setfails(true);
        // console.log(e);
        setErrorMessage(
          e?.response?.data?.message
            ? e?.response?.data.message
            : "An error occurred"
        );
      });
  };
  const handleFileFour = async (e) => {
    setDocumentFour(e.target.files[0]);
    setError(true);
    setErrorMessage("Uploading");
    //console.log(doucumentFour);
    setloader4(true);

    formData.append("CAC_FORM_4", e.target.files[0], e.target.files[0].name);
    await axios
      .post(
        `v1/business/upload-business-documents/${state.businessId}?businessType=${state.businessType}`,
        formData
      )
      .then((res) => {
        console.log(res);
        setFileUploadedFour(true);
        setError(false);
        setloader4(false);
      })
      .catch((e) => {
        setError(true);
        // setErrorMessage("Error uploading files");
        setloader4(false);
        setfails(true);
        // console.log(e);
        setErrorMessage(
          e?.response?.data?.message
            ? e?.response?.data.message
            : "An error occurred"
        );
      });
  };
  const handleFileFive = async (e) => {
    setDocumentFive(e.target.files[0]);
    setError(true);
    setErrorMessage("Uploading");
    setloader5(true);
    // setDocumentOne('kfkf')
    //  console.log(doucumentFive);

    formData.append("CAC_FORM_7", e.target.files[0], e.target.files[0].name);
    await axios
      .post(
        `v1/business/upload-business-documents/${state.businessId}?businessType=${state.businessType}`,
        formData
      )
      .then((res) => {
        //  console.log(res);
        setFileUploadedFive(true);
        setError(false);
        setloader5(false);
      })
      .catch((e) => {
        setError(true);
        // setErrorMessage("Error uploading files");
        setloader5(false);
        setfails(true);
        // console.log(e);
        setErrorMessage(
          e?.response?.data?.message
            ? e?.response?.data.message
            : "An error occurred"
        );
      });
  };
  const handleFileSix = async (e) => {
    setDocumentSix(e.target.files[0]);
    setError(true);
    setErrorMessage("Uploading");
    setloader6(true);
    // setDocumentOne('kfkf')
    //   console.log(doucumentSix);

    formData.append(
      "MEMORANDUM_AND_ARTICLE_OF_ASSOCIATION",
      e.target.files[0],
      e.target.files[0].name
    );
    await axios
      .post(
        `v1/business/upload-business-documents/${state.businessId}?businessType=${state.businessType}`,
        formData
      )
      .then((res) => {
        console.log(res);
        setFileUploadedSix(true);
        setError(false);
        setloader6(false);
        setisUploaded1(true);
      })
      .catch((e) => {
        setError(true);
        setfails(true);
        // console.log(e);
        setErrorMessage(
          e?.response?.data?.message
            ? e?.response?.data.message
            : "An error occurred"
        );
        setloader6(false);
        console.log(e);
      });
  };
  const handleFileEight = async (e) => {
    setDocumentEight(e.target.files[0]);

    setError(true);
    setErrorMessage("Uploading");
    //  console.log(doucumentEight);
    setloader8(true);

    formData.append(
      "BOARD_RESOLUTION",
      e.target.files[0],
      e.target.files[0].name
    );
    await axios
      .post(
        `v1/business/upload-business-documents/${state.businessId}?businessType=${state.businessType}`,
        formData
      )
      .then(() => {
        setError(false);
        setloader8(false);
        setisUploaded2(true);

        setFileUploadedEight(true);
      })
      .catch((e) => {
        setError(true);
        setfails(true);
        // console.log(e);
        setErrorMessage(
          e?.response?.data?.message
            ? e?.response?.data.message
            : "An error occurred"
        );

        console.log(e);
      });
  };
  const gotoNextPage = () => {
    window.location.replace("/dashboard");
  };
  const goNextPage = async () => {
    if (isUploaded1 && isUploaded2 && isUploaded3) {
      setloader(true);
      await axios
        .put(`/v1/business/current/${state.businessId}`)
        .then((res) => {
          // console.log(res)
          axios.defaults.headers.common["x-business-id"] =
            res.data.business._id;
          axios
            .post(`/v1/business/request-verification`)
            .then((res) => {
              // console.log(res);
              if (localStorage.getItem("default") !== null) {
                localStorage.removeItem("default");
              }
              localStorage.setItem("hide", false);
              setshowcongrats(true);
            })
            .catch((e) => {
              console.log(e);
            });
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      setfails(true);
      setErrorMessage("Upload all the required documents");
    }
  };

  return (
    <div
      className=""
      style={{
        background: "#F6F8FA",

        overflowX: "hidden",
        paddingRight: "0px",
        maxWidth: "2000px",
        width: "100%",
        margin: "0px auto",
      }}
    >
      <Helmet>
        <title>Business Document</title>
      </Helmet>

      <LoginHeader width={106} />
      <DirectorModalBvn
        state={state}
        show={showModal}
        setShowModal={setShowModal}
        director={director}
        setfileUploadedBvn={setfileUploadedBvn}
        setDirector={setDirector}
        type={state.businessType}
        setisUploaded3={setisUploaded3}
      />
      <Congratulations
        show={showcongrats}
        setshow={setshowcongrats}
        goNextPage={gotoNextPage}
      />

      <div
        className="align-items-center d-flex "
        style={{
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          className="form-general-layout"
          style={{
            position: "relative",
            maxWidth: "591px",
          }}
        >
          <FailHandler success={fails} message={errorMessage} />
          <div
            className="card-body"
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "32px",
            }}
          >
            <div
              className="d-flex justify-content-between"
              style={{ alignItems: "center" }}
            >
              <ArrowBack />
              <span
                style={{
                  fontSize: "14px",
                  fontWeight: "600",
                  color: "#344054",
                  cursor: "pointer",
                  lineHeight: "19.6px",
                }}
                onClick={gotoNextPage}
              >
                Skip
              </span>
            </div>
            <span
              className="d-block"
              style={{
                fontSize: "14px",
                color: "#667085",
                lineHeight: "19.6px",
                fontWeight: "500",
              }}
            >
              2 of 2
            </span>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "4px",
              }}
            >
              <div
                style={{
                  fontSize: "32px",
                  fontWeight: "600",
                  color: "#101828",
                  lineHeight: "40px",
                }}
              >
                Upload business documents
              </div>

              <span
                style={{
                  color: "#667085",
                  fontSize: "16px",
                  lineHeight: "24px",
                  fontWeight: "500",
                  letterSpacing: "2%",
                }}
              >
                Provide your LLC documents
              </span>
            </div>

            <div
              className=" bg-white "
              style={{
                borderRadius: "8px",
              }}
            >
              <div
                className="position-relative   "
                style={{
                  borderTop: "none",
                  padding: "16px",
                  borderRight: "none",
                  borderLeft: "none",
                  borderBottom: "1px solid #E7E9FB ",
                  borderBottomLeftRadius: "0px",
                  borderBottomRightRadius: "0px",
                }}
              >
                <input
                  type="file"
                  name="file1"
                  id="file1"
                  onChange={handleFileOne}
                  style={{
                    position: "absolute",
                    width: "100%",
                    height: "50px",
                    zIndex: 2,
                    opacity: 0,
                  }}
                />
                <div
                  className="d-flex  "
                  style={{
                    alignItems: "center",
                    gap: "8px",
                    cursor: "pointer",
                    top: 0,
                    width: "100%",
                  }}
                >
                  <img src={document} alt="" />
                  <div
                    className="d-flex justify-content-between  w-100"
                    style={{ alignItems: "center" }}
                  >
                    <div className="flex">
                      <div
                        style={{
                          fontWeight: "600",
                          fontSize: "16px",
                          color: "#101828",
                          letterSpacing: "2%",
                          lineHeight: "24px",
                        }}
                      >
                        CAC Form 2 (Optional)
                      </div>
                      <span
                        style={{
                          display: "block",
                          fontSize: "12px",
                          color: "#667085 ",
                          lineHeight: "16.2px",
                          fontWeight: "500",
                          marginTop: "2px",
                        }}
                      >
                        Statement of share capital and return of allotment of
                        shares
                      </span>
                    </div>
                    {loader1 === false ? (
                      <span
                        className="material-icons"
                        style={{ color: "#98A2B3" }}
                      >
                        {fileUploadedOne ? (
                          <img src={marksuccess} alt="" />
                        ) : (
                          "chevron_right"
                        )}
                      </span>
                    ) : (
                      <Circles
                        height="15"
                        width="15"
                        color="rgb(111, 0, 255)"
                        ariaLabel="circles-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                      />
                    )}
                  </div>
                </div>
              </div>
              <div
                className="position-relative"
                style={{
                  borderTop: "none",
                  borderRight: "none",
                  borderLeft: "none",
                  borderBottom: "1px solid #E7E9FB ",
                  borderBottomLeftRadius: "0px",
                  borderBottomRightRadius: "0px",

                  padding: "16px",
                }}
              >
                <input
                  type="file"
                  name="file1"
                  id="file1"
                  onChange={handleFileTwo}
                  style={{
                    position: "absolute",
                    width: "100%",
                    height: "50px",
                    zIndex: 2,
                    opacity: 0,
                  }}
                />
                <div
                  className="d-flex   "
                  style={{
                    alignItems: "center",
                    cursor: "pointer",
                    gap: "8px",
                    top: 0,
                    width: "100%",
                  }}
                >
                  <img src={document} alt="" />
                  <div
                    className="d-flex justify-content-between  w-100"
                    style={{ alignItems: "center" }}
                  >
                    <div className="flex">
                      <div
                        style={{
                          fontWeight: "600",
                          fontSize: "16px",
                          color: "#101828",
                          letterSpacing: "2%",
                          lineHeight: "24px",
                        }}
                      >
                        CAC Form 2.1 (Optional)
                      </div>
                      <span
                        style={{
                          display: "block",
                          fontSize: "12px",
                          color: "#667085 ",
                          lineHeight: "16.2px",
                          fontWeight: "500",
                          marginTop: "2px",
                        }}
                        className="text-muted"
                      >
                        Particulars of secretary
                      </span>
                    </div>
                    {loader2 === false ? (
                      <span
                        className="material-icons"
                        style={{
                          color: "#98A2B3",
                        }}
                      >
                        {fileUploadedTwo ? (
                          <img src={marksuccess} alt="" />
                        ) : (
                          "chevron_right"
                        )}
                      </span>
                    ) : (
                      <Circles
                        height="15"
                        width="15"
                        color="rgb(111, 0, 255)"
                        ariaLabel="circles-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                      />
                    )}
                  </div>
                </div>
              </div>
              <div
                className="position-relative "
                style={{
                  borderTop: "none",
                  padding: "16px",
                  borderRight: "none",
                  borderLeft: "none",
                  borderBottom: "1px solid #E7E9FB ",
                  borderBottomLeftRadius: "0px",
                  borderBottomRightRadius: "0px",
                }}
              >
                <input
                  type="file"
                  name="file1"
                  id="file1"
                  onChange={handleFileThree}
                  style={{
                    position: "absolute",
                    width: "100%",
                    height: "50px",
                    zIndex: 2,
                    opacity: 0,
                  }}
                />
                <div
                  className="d-flex "
                  style={{
                    alignItems: "center",
                    cursor: "pointer",
                    top: 0,
                    width: "100%",
                    gap: "8px",
                  }}
                >
                  <img src={document} alt="" />
                  <div
                    className="d-flex justify-content-between  w-100"
                    style={{ alignItems: "center" }}
                  >
                    <div className="flex">
                      <div
                        style={{
                          fontWeight: "600",
                          fontSize: "16px",
                          color: "#101828",
                          letterSpacing: "2%",
                          lineHeight: "24px",
                        }}
                      >
                        CAC Form 3 (Optional)
                      </div>
                      <span
                        style={{
                          display: "block",
                          fontSize: "12px",
                          color: "#667085 ",
                          lineHeight: "16.2px",
                          fontWeight: "500",
                          marginTop: "2px",
                        }}
                      >
                        Notice of registered addresses
                      </span>
                    </div>
                    {loader3 === false ? (
                      <span
                        className="material-icons"
                        style={{
                          color: "#98A2B3",
                        }}
                      >
                        {fileUploadedThree ? (
                          <img src={marksuccess} alt="" />
                        ) : (
                          "chevron_right"
                        )}
                      </span>
                    ) : (
                      <Circles
                        height="15"
                        width="15"
                        color="rgb(111, 0, 255)"
                        ariaLabel="circles-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                      />
                    )}
                  </div>
                </div>
              </div>
              <div
                className="position-relative"
                style={{
                  borderTop: "none",
                  padding: "16px",
                  borderRight: "none",
                  borderLeft: "none",
                  borderBottom: "1px solid #E7E9FB ",
                  borderBottomLeftRadius: "0px",
                  borderBottomRightRadius: "0px",
                }}
              >
                <input
                  type="file"
                  name="file1"
                  id="file1"
                  onChange={handleFileFour}
                  style={{
                    position: "absolute",
                    width: "100%",
                    height: "50px",
                    zIndex: 2,
                    opacity: 0,
                  }}
                />
                <div
                  className="d-flex "
                  style={{
                    alignItems: "center",
                    cursor: "pointer",
                    gap: "8px",
                    top: 0,
                    width: "100%",
                  }}
                >
                  <img src={document} alt="" />
                  <div
                    className="d-flex justify-content-between  w-100"
                    style={{ alignItems: "center" }}
                  >
                    <div className="flex">
                      <div
                        style={{
                          fontWeight: "600",
                          fontSize: "16px",
                          color: "#101828",
                          letterSpacing: "2%",
                          lineHeight: "24px",
                        }}
                      >
                        CAC Form 4 (Optional)
                      </div>
                      <span
                        style={{
                          display: "block",
                          fontSize: "12px",
                          color: "#667085 ",
                          lineHeight: "16.2px",
                          fontWeight: "500",
                        }}
                      >
                        Declaration of compliance
                      </span>
                    </div>
                    {loader4 === false ? (
                      <span
                        className="material-icons"
                        style={{
                          color: "#98A2B3",
                        }}
                      >
                        {fileUploadedFour ? (
                          <img src={marksuccess} alt="" />
                        ) : (
                          "chevron_right"
                        )}
                      </span>
                    ) : (
                      <Circles
                        height="15"
                        width="15"
                        color="rgb(111, 0, 255)"
                        ariaLabel="circles-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                      />
                    )}
                  </div>
                </div>
              </div>
              <div
                className="position-relative"
                style={{
                  borderTop: "none",
                  padding: "16px",
                  borderRight: "none",
                  borderLeft: "none",
                  borderBottom: "1px solid #E7E9FB ",
                  borderBottomLeftRadius: "0px",
                  borderBottomRightRadius: "0px",
                }}
              >
                <input
                  type="file"
                  name="file1"
                  id="file1"
                  onChange={handleFileFive}
                  style={{
                    position: "absolute",
                    width: "100%",
                    height: "50px",
                    zIndex: 2,
                    opacity: 0,
                  }}
                />
                <div
                  className="d-flex  "
                  style={{
                    alignItems: "center",
                    cursor: "pointer",
                    top: 0,
                    width: "100%",
                    gap: "8px",
                  }}
                >
                  <img src={document} alt="" />
                  <div
                    className="d-flex justify-content-between  w-100"
                    style={{ alignItems: "center" }}
                  >
                    <div className="flex">
                      <div
                        style={{
                          fontWeight: "600",
                          fontSize: "16px",
                          color: "#101828",
                          letterSpacing: "2%",
                          lineHeight: "24px",
                          marginTop: "-5px",
                        }}
                      >
                        CAC Form 7 (Optional)
                      </div>
                      <span
                        style={{
                          display: "block",
                          fontSize: "12px",
                          color: "#667085 ",
                          lineHeight: "16.2px",
                          fontWeight: "500",
                          marginTop: "2px",
                        }}
                      >
                        Particulars of directors
                      </span>
                    </div>
                    {loader5 === false ? (
                      <span
                        className="material-icons"
                        style={{
                          color: "#98A2B3",
                        }}
                      >
                        {fileUploadedFive ? (
                          <img src={marksuccess} alt="" />
                        ) : (
                          "chevron_right"
                        )}
                      </span>
                    ) : (
                      <Circles
                        height="15"
                        width="15"
                        color="rgb(111, 0, 255)"
                        ariaLabel="circles-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                      />
                    )}
                  </div>
                </div>
              </div>

              <div
                className="position-relative "
                style={{
                  borderTop: "none",
                  padding: "16px",
                  borderRight: "none",
                  borderLeft: "none",
                  borderBottom: "1px solid #E7E9FB ",
                  borderBottomLeftRadius: "0px",
                  borderBottomRightRadius: "0px",
                  minHeight: "70px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <input
                  type="file"
                  name="file1"
                  id="file1"
                  onChange={handleFileSix}
                  style={{
                    position: "absolute",
                    width: "100%",
                    height: "50px",
                    zIndex: 2,
                    opacity: 0,
                  }}
                />

                <div
                  className="d-flex   "
                  style={{
                    alignItems: "center",
                    cursor: "pointer",
                    top: 0,
                    width: "100%",
                    gap: "8px",
                  }}
                >
                  <img src={document} alt="" />
                  <div
                    className="d-flex justify-content-between  w-100"
                    style={{ alignItems: "center" }}
                  >
                    <div className="flex">
                      <div
                        style={{
                          fontWeight: "600",
                          fontSize: "16px",
                          color: "#101828",
                          letterSpacing: "2%",
                          lineHeight: "24px",
                        }}
                      >
                        Memorandum & articles of association
                      </div>
                    </div>
                    {loader6 === false ? (
                      <span
                        className="material-icons"
                        style={{ color: "#98A2B3" }}
                      >
                        {fileUploadedSix ? (
                          <img src={marksuccess} alt="" />
                        ) : (
                          "chevron_right"
                        )}
                      </span>
                    ) : (
                      <Circles
                        height="15"
                        width="15"
                        color="rgb(111, 0, 255)"
                        ariaLabel="circles-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                      />
                    )}
                  </div>
                </div>
              </div>
              <div
                className="position-relative "
                style={{
                  borderTop: "none",
                  padding: "16px",
                  borderRight: "none",
                  borderLeft: "none",
                  borderBottom: "1px solid #E7E9FB ",
                  borderBottomLeftRadius: "0px",
                  borderBottomRightRadius: "0px",
                  minHeight: "70px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                onClick={showModalHandle}
              >
                <div
                  className="d-flex "
                  style={{
                    alignItems: "center",
                    cursor: "pointer",
                    top: 0,
                    width: "100%",
                    gap: "8px",
                  }}
                >
                  <img src={document} alt="" />
                  <div
                    className="d-flex justify-content-between  w-100"
                    style={{ alignItems: "center" }}
                  >
                    <div className="flex">
                      <div
                        style={{
                          fontWeight: "600",
                          fontSize: "16px",
                          color: "#101828",
                          letterSpacing: "2%",
                          lineHeight: "24px",
                        }}
                      >
                        BVN of the Directors of the Company
                      </div>
                    </div>

                    <span
                      className="material-icons text-muted"
                      style={{ color: "#98A2B3" }}
                    >
                      {fileUploadedBvn ? (
                        <img src={marksuccess} alt="" />
                      ) : (
                        "chevron_right"
                      )}
                    </span>
                  </div>
                </div>
              </div>
              <div
                className="position-relative "
                style={{
                  borderTop: "none",
                  borderRight: "none",
                  borderLeft: "none",
                  borderBottom: "none",
                  padding: "16px ",
                  minHeight: "70px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <input
                  type="file"
                  name="file1"
                  id="file1"
                  onChange={handleFileEight}
                  style={{
                    position: "absolute",
                    width: "100%",
                    height: "50px",
                    zIndex: 2,
                    opacity: 0,
                  }}
                />
                <div
                  className="d-flex "
                  style={{
                    alignItems: "center",
                    cursor: "pointer",

                    gap: "8px",
                    top: 0,
                    width: "100%",
                  }}
                >
                  <img src={document} alt="" />
                  <div
                    className="d-flex justify-content-between  w-100"
                    style={{ alignItems: "center" }}
                  >
                    <div className="flex">
                      <div
                        style={{
                          fontWeight: "600",
                          fontSize: "16px",
                          color: "#101828",
                          letterSpacing: "2%",
                          lineHeight: "24px",
                        }}
                      >
                        Board resolution to open an account with Tradevu
                      </div>
                    </div>
                    {loader8 === false ? (
                      <span
                        className="material-icons"
                        style={{
                          color: "#98A2B3",
                        }}
                      >
                        {fileUploadedEight ? (
                          <img src={marksuccess} alt="" />
                        ) : (
                          "chevron_right"
                        )}
                      </span>
                    ) : (
                      <Circles
                        height="15"
                        width="15"
                        color="rgb(111, 0, 255)"
                        ariaLabel="circles-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>

            <button
              disabled={loader}
              className="btn mt-3"
              onClick={() => goNextPage()}
              style={{
                background: "#6F00FF",
                color: "#fff",
                fontSize: "16px",
                lineHeight: "24px",
                fontWeight: "600",
                letterSpacing: "2%",
                padding: "16px 24px 16px 24px",
                height: "48px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                maxWidth: "279px",
                width: "100%",
              }}
            >
              {loader ? <Loader /> : "Continue"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManyOtherDocument;
