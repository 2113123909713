import { Helmet } from "react-helmet";
// import DashHeader from "../component/dashheader";
// import homefinance from '../asset/im
import { useEffect, useState } from "react";
import trash from "../asset/images/trash.png";

import SerHeader from "../component/serviceheader";
import arrowleft from "../asset/images/arrowleftcircle.svg";
import edit from "../asset/images/edit-2.svg";
import person from "../asset/images/person.svg";
import UploadInvoice from "../component/uploadinvoice";
import { currencies } from "../constants/currency";

import Loaders from "../component/loader";
import InvoiceSuccess from "../component/invoicesuccess";
import Swal from "sweetalert2/dist/sweetalert2.js";

import { useNavigate } from "react-router-dom";
import axios from "axios";

const SetupInvoice = () => {
    // const [selectedIndex, setselectedIndex] = useState(-1)
    const navigate = useNavigate();

    const [show, setShow] = useState(false);
    const [closemodal, closeModal] = useState(false);
    const [closemodaldue, closeModaldue] = useState(false);

    const [selecteditem, setselecteditem] = useState([]);
    const [selectedcurrency, setselectedcurrency] = useState([]);

    const [showModal3, setshowModal3] = useState(false);
    const [ys, setys] = useState([]);
    const [months] = useState([]);
    const [m, getm] = useState(new Date().getMonth());
    const [mdue, getmdue] = useState(new Date().getMonth());
    const [buyer, setbuyers] = useState([]);
    const [supplier, setsuppliers] = useState([]);
    const [y] = useState(new Date().getFullYear());
    const [days, setdays] = useState("");
    const [years, setyear] = useState();

    const [daysdue, setdaysdue] = useState("");
    const [yearsdue, setyeardue] = useState();
    const [showcurrency, setshowcurrency] = useState(false);
    const [selecteddayindex, setselecteddayindex] = useState(-1);
    const [selectedmonindex, setselectmonindex] = useState(-1);
    const [selectedyrindex, setselectedyrindex] = useState(-1);

    const [selecteddayindexdue, setselecteddayindexdue] = useState(-1);
    const [selectedmonindexdue, setselectmonindexdue] = useState(-1);
    const [selectedyrindexdue, setselectedyrindexdue] = useState(-1);
    const [subtotal, setsubtotal] = useState(0);
    const [invoicetitle, setinvoicetitle] = useState("");
    const [currency, setcurrency] = useState("");
    const [issuedate, setissuedate] = useState("");
    const [duedate, setduedate] = useState("");
    const [loader, setloader] = useState(false);
    const [note, setnote] = useState("");
    const [mainduedate, setmainduedate] = useState("");
    const [mainissuedate, setmainissuedate] = useState("");
    const [businessname, setbusinessname] = useState([]);
    console.log(businessname, buyer, supplier);

    const [items, setitems] = useState([
        {
            itemName: "",

            quantity: 0,

            pricePerUnit: 0,

            //amount: 0,
            //currency: "",
        },
    ]);
    useEffect(() => {
        const fetchId = async () => {};
        fetchId();
    }, []);

    const [daysofms] = useState(["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"]);
    const [monthName] = useState([
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
    ]);
    const [isdisable, setisdisable] = useState(true);
    const getmymonth = (days, index) => {
        getm(days);
        setselecteddayindex(index);
    };
    const getmymonthdue = (days, index) => {
        getmdue(days);
        setselecteddayindexdue(index);
    };

    const getmydays = (month, index) => {
        setdays(month);
        setselectmonindex(index);
    };
    const getmydaysdue = (month, index) => {
        setdaysdue(month);
        setselectmonindexdue(index);
    };
    const getmyyear = (lls, index) => {
        setyear(lls);
        setselectedyrindex(index);
    };
    const getmyyeardue = (ll, index) => {
        setyeardue(ll);
        setselectedyrindexdue(index);
    };

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const addseletedcurrency = (currency) => {
        const newState = items.map((obj) => {
            return {
                ...obj,
                //  currency: currency.title,
            };
        });
        setitems(newState);

        setshowcurrency(!showcurrency);

        setselectedcurrency(currency);
    };
    useEffect(() => {
        if (selecteditem && currency && items && issuedate && duedate && note && invoicetitle) {
            setisdisable(false);
        } else {
            setisdisable(true);
        }
    }, [setisdisable, currency, items, selecteditem, issuedate, duedate, note, invoicetitle]);
    const setdateissu = () => {
        setissuedate(monthName[m - 1] + " " + days + "," + years);
        setmainissuedate(y + "-" + m + "-" + days);
    };
    const setdatedue = () => {
        setduedate(monthName[mdue - 1] + " " + daysdue + "," + yearsdue);
        setmainduedate(yearsdue + "-" + mdue + "-" + daysdue);
    };
    useEffect(() => {
        const fetchbuyer = async () => {
            await axios
                .get("v1/flex/buyers")

                .then((res) => {
                    console.log(res);
                    setbuyers(res.data.data);
                })
                .catch((e) => {
                    console.log(e);
                });
        };
        fetchbuyer();
    }, []);
    useEffect(() => {
        const fetchsupplier = async () => {
            await axios
                .get("v1/flex/suppliers")

                .then((res) => {
                    console.log(res);
                    setsuppliers(res.data.data);
                })
                .catch((e) => {
                    console.log(e);
                });
        };
        fetchsupplier();
    }, []);
    //console.log(selecteditem.length)
    const updateitemitems = (e, indexs) => {
        // setitems([...items.indexOf()])

        const newState = items.map((obj, index) => {
            if (index === indexs) {
                return {
                    ...obj,
                    itemName: e.target.value,
                };
            }
            return obj;
        });

        setitems(newState);
    };
    const updateitemquantity = (e, indexs, p) => {
        // setitems([...items.indexOf()])

        const newState = items.map((obj, index) => {
            if (index === indexs) {
                return {
                    ...obj,
                    quantity: e.target.value,
                    // amount: e.target.value * p,
                };
            }
            return obj;
        });

        setitems(newState);
    };

    const updateitemprice = (e, indexs, q) => {
        // setitems([...items.indexOf()])

        const newState = items.map((obj, index) => {
            if (index === indexs) {
                return {
                    ...obj,
                    pricePerUnit: e.target.value,
                    // amount: q * e.target.value,
                };
            }
            return obj;
        });

        setitems(newState);
    };
    const addanothersection = () => {
        const data = {
            itemName: "",
            quantity: 0,
            pricePerUnit: 0,
            amount: 0,
            currency: selectedcurrency.title,
        };
        setitems((current) => [...current, data]);
    };
    const delitems = (index) => {
        setitems([...items.slice(0, index)]);
    };
    //const navigation = useNavigate();

    const handleSubmitInvoic = async (e) => {
        e.preventDefault();
        setloader(true);
        await axios
            .post(`v1/invoice/generateId?type=finance`)
            .then((resp) => {
                const data = {
                    firstName: selecteditem[0].firstName,
                    lastName: selecteditem[0].lastName,
                    email: selecteditem[0].email,
                    phoneNumber: selecteditem[0].phoneNumber,
                    invoiceItems: [...items],
                    userType: "SUPPLIER",
                    currency: selectedcurrency.title,
                    issueDate: new Date(mainissuedate).toISOString(),
                    dueDate: new Date(mainduedate).toISOString(),
                    invoiceTitle: invoicetitle,
                    // businessName: businessname,
                    // businessid: resp.data.data._id,
                };
                axios
                    .post(`/v1/invoice/create/${resp.data.data._id}`, data)
                    .then((res) => {
                        Swal.fire({
                            text: res.data.message,
                            icon: "success",
                            confirmButtonText: "Continue",
                            width: "280px",
                            confirmButtonColor: "rgb(111, 0, 255)",
                        }).then(() => {
                            navigate(`/finance/uploadinvoice/${res.data.data._id}`);
                        });
                    })
                    .catch((e) => {
                        console.log(e);

                        Swal.fire({
                            title: "Error",
                            text: e.response.data.message,
                            icon: "error",
                            confirmButtonText: "Continue",
                            width: "280px",
                            confirmButtonColor: "rgb(111, 0, 255)",
                        }).then((res) => {
                            console.log(res);
                        });
                        setloader(false);
                    });
            })
            .catch((e) => {
                Swal.fire({
                    title: "Error",
                    text: e.response.data.message,
                    icon: "error",
                    confirmButtonText: "Continue",
                    width: "280px",
                    confirmButtonColor: "rgb(111, 0, 255)",
                }).then((res) => {
                    console.log(res);
                });
                setloader(false);
            });
    };

    useEffect(() => {
        setsubtotal(items.reduce((a, v) => (a = a + v.pricePerUnit * v.quantity), 0));
        const daysInMonth = new Date(y, m, 0).getDate();
        console.log(daysInMonth);
        for (let i = 1; i <= daysInMonth; i++) {
            months.push(i);
        }
        const range = (start, stop, step) =>
            Array.from(
                {
                    length: (stop - start) / step + 1,
                },
                (_, i) => start + i * step
            );
        setys(range(y, y - 50, -1));
    }, [setys, m, y, months, daysofms, items]);
    return (
        <div className="h-100 w-100 ">
            <div
                className=""
                style={{
                    maxWidth: "2000px",
                    width: "100%",
                    margin: "0px auto",
                }}
            >
                <Helmet>
                    <title>Invoice</title>
                </Helmet>

                <SerHeader header={"Invoice Factoring"} />
                <InvoiceSuccess show={showModal3} setshow={setshowModal3} set={"finance"} />
            </div>
            <div style={{ height: "100%" }}>
                <div
                    className="conatiner "
                    style={{
                        maxWidth: "1250px",
                        margin: "0px auto",
                        paddingLeft: "30px",
                        paddingRight: "30px",
                        paddingTop: "10px",
                        paddingBottom: "10px",
                    }}
                >
                    <div
                        style={{
                            height: "100%",
                            width: "100%",
                            display: "flex",

                            justifyContent: "center",
                        }}
                    >
                        <UploadInvoice
                            show={show}
                            handle={handleClose}
                            selecteditem={selecteditem}
                            setselecteditem={setselecteditem}
                            setbusinessname={setbusinessname}
                        />
                        <form
                            onSubmit={handleSubmitInvoic}
                            style={{
                                maxWidth: "800px",
                                width: "100%",
                            }}
                        >
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: "32px",
                                }}
                            >
                                <div
                                    style={{
                                        maxWidth: "592px",
                                        width: "100%",
                                        marginTop: "50px",
                                        display: "flex",
                                        justifyContent: "space-between",
                                        flexDirection: "column",
                                        gap: "32px",
                                    }}
                                >
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            gap: "32px",
                                        }}
                                    >
                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                gap: "16px",
                                            }}
                                        >
                                            <div
                                                onClick={() => {
                                                    navigate(-1);
                                                }}
                                            >
                                                <img src={arrowleft} alt="" />
                                            </div>
                                            <div
                                                style={{
                                                    color: "#101828",
                                                    fontWeight: "600",
                                                    fontSize: "24px",
                                                    lineHeight: "32.4px",
                                                }}
                                            >
                                                Create Invoice
                                            </div>
                                        </div>
                                        <div
                                            className=""
                                            style={{
                                                display: "flex",
                                                gap: "24px",
                                                flexDirection: "column",
                                            }}
                                        >
                                            <div
                                                className=""
                                                style={{
                                                    display: "flex",
                                                    gap: "8px",
                                                    flexDirection: "column",
                                                }}
                                            >
                                                <label
                                                    htmlFor=""
                                                    style={{
                                                        color: "#344054",
                                                        fontWeight: "600",
                                                        lineHeight: "19.6px",
                                                        fontSize: "14px",
                                                    }}
                                                >
                                                    Buyer
                                                </label>
                                                {selecteditem.length === 0 && (
                                                    <div
                                                        style={{
                                                            background: "#fff",
                                                            display: "flex",
                                                            justifyContent: "space-between",
                                                            padding: "8px",
                                                            borderRadius: "8px",
                                                            alignItems: "center",
                                                            minHeight: "56px",
                                                        }}
                                                        variant="primary"
                                                        onClick={handleShow}
                                                    >
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                gap: "8px",
                                                                alignItems: "center",
                                                            }}
                                                        >
                                                            <img src={person} alt="" />
                                                            <span
                                                                style={{
                                                                    color: "#101828",
                                                                    letterSpacing: "2%",
                                                                    lineHeight: "24px",
                                                                    fontSize: "16px",
                                                                    fontWeight: 600,
                                                                }}
                                                            >
                                                                Enter buyer’s details
                                                            </span>
                                                        </div>
                                                        <img src={edit} alt="" />
                                                    </div>
                                                )}
                                                {selecteditem.length > 0 &&
                                                    selecteditem.map((items, index) => (
                                                        <div
                                                            style={{
                                                                background: "#fff",
                                                                display: "flex",
                                                                justifyContent: "space-between",
                                                                padding: "8px",
                                                                borderRadius: "8px",
                                                                alignItems: "center",
                                                                minHeight: "56px",
                                                            }}
                                                            key={index}
                                                            variant="primary"
                                                            onClick={handleShow}
                                                        >
                                                            <div
                                                                style={{
                                                                    display: "flex",
                                                                    gap: "8px",
                                                                }}
                                                            >
                                                                <div
                                                                    style={{
                                                                        width: "40px",
                                                                        height: "40px",
                                                                        borderRadius: "250px",
                                                                        alignItems: "center",
                                                                        justifyContent: "ceneter",
                                                                        background: "#F4F0FF",
                                                                        textAlign: "center",
                                                                        display: "flex",
                                                                    }}
                                                                >
                                                                    <div
                                                                        style={{
                                                                            color: "#7F56D9",
                                                                            fontWeight: "600",
                                                                            fontSize: "14px",
                                                                            lineHeight: "20px",
                                                                            textAlign: "center",
                                                                            textTransform:
                                                                                "uppercase",
                                                                            width: "100%",
                                                                        }}
                                                                    >
                                                                        {" "}
                                                                        {items.firstName.charAt(0)}
                                                                        {items.lastName.charAt(
                                                                            0
                                                                        )}{" "}
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    style={{
                                                                        display: "flex",
                                                                        flexDirection: "column",
                                                                    }}
                                                                >
                                                                    <span
                                                                        style={{
                                                                            color: "#101828",
                                                                            letterSpacing: "2%",
                                                                            lineHeight: "18px",
                                                                            fontSize: "16px",
                                                                            fontWeight: 600,
                                                                        }}
                                                                    >
                                                                        {" "}
                                                                        {items.firstName}{" "}
                                                                        {items.lastName}{" "}
                                                                    </span>
                                                                    <span
                                                                        style={{
                                                                            color: "#667085",
                                                                            fontSize: "14px",
                                                                            fontWeight: "500",
                                                                            lineHeight: "19.6px",
                                                                        }}
                                                                    >
                                                                        {" "}
                                                                        {items.email}{" "}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <img src={edit} alt="" />
                                                        </div>
                                                    ))}{" "}
                                            </div>
                                            <div
                                                className=""
                                                style={{
                                                    display: "flex",
                                                    gap: "8px",
                                                    flexDirection: "column",
                                                }}
                                            >
                                                <label
                                                    htmlFor=""
                                                    style={{
                                                        color: "#344054",
                                                        fontWeight: "600",
                                                        lineHeight: "19.6px",
                                                        fontSize: "14px",
                                                    }}
                                                >
                                                    Invoice title
                                                </label>
                                                <div style={{ gap: "8px" }}>
                                                    <input
                                                        type="text"
                                                        name=""
                                                        id=""
                                                        onChange={(e) => {
                                                            setinvoicetitle(e.target.value);
                                                        }}
                                                        value={invoicetitle}
                                                        style={{
                                                            width: "100%",
                                                            borderRadius: "8px",
                                                            border: "none",
                                                            outline: "none",
                                                            background: "",
                                                            height: "56px",
                                                            padding: "8px 16px 8px 16px",
                                                            backgroundColor: "#ECEFF3",
                                                            letterSpacing: "2%",
                                                            fontWeight: "600",
                                                            fontSize: "16px",
                                                        }}
                                                        placeholder="Invoice title"
                                                    />
                                                </div>
                                            </div>
                                            <div
                                                className=""
                                                style={{
                                                    display: "flex",
                                                    gap: "8px",
                                                    flexDirection: "column",
                                                }}
                                            >
                                                <label
                                                    htmlFor=""
                                                    style={{
                                                        color: "#344054",
                                                        fontWeight: "600",
                                                        lineHeight: "19.6px",
                                                        fontSize: "14px",
                                                    }}
                                                >
                                                    Currency
                                                </label>

                                                <div style={{ gap: "8px" }}>
                                                    <div
                                                        onClick={() => {
                                                            setshowcurrency(!showcurrency);
                                                        }}
                                                        style={{
                                                            width: "100%",
                                                            maxWidth: "239px",
                                                            borderRadius: "8px",

                                                            border: "none",
                                                            outline: "none",
                                                            background: "",
                                                            padding: "8px 16px 8px 16px",
                                                            display: "flex",
                                                            backgroundColor: "#ECEFF3",
                                                            letterSpacing: "2%",
                                                            fontWeight: "600",
                                                            fontSize: "16px",
                                                            height: "56px",
                                                            justifyContent: "space-between",
                                                            position: "relative",
                                                            alignItems: "center",
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                gap: "4px",
                                                                alignItems: "center",
                                                            }}
                                                        >
                                                            <img
                                                                src={selectedcurrency.image}
                                                                alt=""
                                                            />
                                                            <span
                                                                style={{
                                                                    color: "#667085",
                                                                    fontSize: "16px",
                                                                    letterSpacing: "2%",
                                                                    lineHeight: "24px",
                                                                }}
                                                            >
                                                                {" "}
                                                                {selectedcurrency.title}{" "}
                                                            </span>
                                                        </div>
                                                        <span
                                                            className="material-icons"
                                                            style={{ color: "#101828" }}
                                                        >
                                                            keyboard_arrow_down
                                                        </span>
                                                    </div>
                                                    {showcurrency && (
                                                        <div
                                                            style={{
                                                                background: "#fff",
                                                                borderRadius: "8px",
                                                                padding: "10px",
                                                                maxWidth: "239px",
                                                                position: "absolute",
                                                                zIndex: "2",
                                                            }}
                                                        >
                                                            {" "}
                                                            {currencies.map((currency) => (
                                                                <div
                                                                    style={{
                                                                        display: "flex",
                                                                        gap: "4px",
                                                                        padding: "10px",
                                                                        cursor: "pointer",
                                                                    }}
                                                                    onClick={() => {
                                                                        addseletedcurrency(
                                                                            currency
                                                                        );
                                                                        setcurrency(currency);
                                                                    }}
                                                                >
                                                                    <img
                                                                        src={currency.image}
                                                                        alt=""
                                                                    />
                                                                    <div> {currency.title} </div>
                                                                </div>
                                                            ))}{" "}
                                                        </div>
                                                    )}{" "}
                                                </div>
                                            </div>
                                            <div
                                                className=""
                                                style={{
                                                    display: "flex",
                                                    gap: "8px",
                                                    flexDirection: "column",
                                                }}
                                            >
                                                <label
                                                    htmlFor=""
                                                    style={{
                                                        color: "#344054",
                                                        fontWeight: "600",
                                                        lineHeight: "19.6px",
                                                        fontSize: "14px",
                                                    }}
                                                >
                                                    Date
                                                </label>
                                                <div
                                                    style={{
                                                        gap: "16px",
                                                        display: "flex",
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            position: "relative",
                                                            width: "100%",
                                                        }}
                                                    >
                                                        <input
                                                            type="text"
                                                            name=""
                                                            id=""
                                                            onChange={(e) => {
                                                                setissuedate(e.target.value);
                                                            }}
                                                            value={issuedate}
                                                            onFocus={() => {
                                                                closeModal(!closemodal);
                                                            }}
                                                            style={{
                                                                width: "100%",
                                                                borderRadius: "8px",
                                                                border: "none",
                                                                outline: "none",
                                                                height: "56px",
                                                                background: "",
                                                                padding: "8px 16px 8px 16px",
                                                                backgroundColor: "#ECEFF3",
                                                                letterSpacing: "2%",
                                                                fontWeight: "600",
                                                                fontSize: "16px",
                                                            }}
                                                            placeholder="Issue date"
                                                        />{" "}
                                                        {closemodal && (
                                                            <div
                                                                className="shadow-3xl"
                                                                style={{
                                                                    position: "absolute",
                                                                    background: "#fff",
                                                                    width: "375px",
                                                                    borderRadius: "16px",
                                                                    display: "flex",
                                                                    flexDirection: "column",
                                                                    gap: "24px",
                                                                }}
                                                            >
                                                                <div
                                                                    style={{
                                                                        padding:
                                                                            "8px 24px 8px 24px",
                                                                        borderBottom:
                                                                            "1px solid #E7E9FB",
                                                                    }}
                                                                >
                                                                    <span
                                                                        className="material-icons"
                                                                        style={{ color: "#667085" }}
                                                                        onClick={() => {
                                                                            closeModal(false);
                                                                        }}
                                                                    >
                                                                        close
                                                                    </span>
                                                                </div>
                                                                <div
                                                                    style={{
                                                                        padding:
                                                                            "8px 24px 8px 24px",
                                                                        display: "flex",
                                                                        justifyContent:
                                                                            "space-between",
                                                                    }}
                                                                >
                                                                    <span
                                                                        style={{
                                                                            color: "#C3C4C7",
                                                                            fontWeight: "600",
                                                                            fontSize: "16px",
                                                                            lineHeight: "24px",
                                                                            cursor: "pointer",
                                                                        }}
                                                                        onClick={() => {
                                                                            closeModal(false);
                                                                        }}
                                                                    >
                                                                        Cancel
                                                                    </span>
                                                                    <span
                                                                        style={{
                                                                            color: "#383B46",
                                                                            fontSize: "16px",
                                                                            letterSpacing: "2%",
                                                                            lineHeight: "24px",
                                                                            fontWeight: "600",
                                                                        }}
                                                                    >
                                                                        Issue Date
                                                                    </span>
                                                                    <span
                                                                        style={{
                                                                            color: "#6F00FF",
                                                                            letterSpacing: "2%",
                                                                            fontWeight: "600",
                                                                            lineHeight: "24px",
                                                                            fontSize: "16px",
                                                                            cursor: "pointer",
                                                                        }}
                                                                        onClick={() => {
                                                                            setdateissu();
                                                                            closeModal(false);
                                                                        }}
                                                                    >
                                                                        Confirm
                                                                    </span>
                                                                </div>
                                                                <div
                                                                    style={{
                                                                        height: "280px",
                                                                        padding:
                                                                            "8px 24px 8px 24px",
                                                                    }}
                                                                >
                                                                    <div
                                                                        style={{
                                                                            padding:
                                                                                "16px 0px 16px 0px",
                                                                            display: "flex",
                                                                            justifyContent:
                                                                                "space-between",
                                                                            height: "100%",
                                                                        }}
                                                                    >
                                                                        <div
                                                                            className="overflow_xx"
                                                                            style={{
                                                                                overflow: "auto",
                                                                                width: "100%",
                                                                                display: "flex",
                                                                                flexDirection:
                                                                                    "column",
                                                                            }}
                                                                        >
                                                                            {" "}
                                                                            {daysofms.map(
                                                                                (days, index) => (
                                                                                    <div
                                                                                        className="myhover"
                                                                                        id="myclassadd"
                                                                                        onClick={() => {
                                                                                            getmymonth(
                                                                                                days,
                                                                                                index
                                                                                            );
                                                                                        }}
                                                                                        key={index}
                                                                                        style={{
                                                                                            color:
                                                                                                selecteddayindex ===
                                                                                                index
                                                                                                    ? "#101828"
                                                                                                    : "#D7DDEA",

                                                                                            fontWeight:
                                                                                                "600",
                                                                                            padding:
                                                                                                "16px 0px 16px 0px",
                                                                                            borderRadius:
                                                                                                "8px",
                                                                                            fontSize:
                                                                                                "16px",
                                                                                            lineHeight:
                                                                                                "24px",
                                                                                            letterSpacing:
                                                                                                "2%",
                                                                                            textAlign:
                                                                                                "center",
                                                                                            background:
                                                                                                selecteddayindex ===
                                                                                                index
                                                                                                    ? "#F4F0FF"
                                                                                                    : "",
                                                                                        }}
                                                                                    >
                                                                                        {" "}
                                                                                        {days}{" "}
                                                                                    </div>
                                                                                )
                                                                            )}{" "}
                                                                        </div>
                                                                        <div
                                                                            className="overflow_xx"
                                                                            style={{
                                                                                overflow: "auto",
                                                                                width: "100%",
                                                                                display: "flex",
                                                                                flexDirection:
                                                                                    "column",
                                                                            }}
                                                                        >
                                                                            {" "}
                                                                            {months.map(
                                                                                (month, index) => (
                                                                                    <div
                                                                                        className="myhover"
                                                                                        onClick={() => {
                                                                                            getmydays(
                                                                                                month,
                                                                                                index
                                                                                            );
                                                                                        }}
                                                                                        key={index}
                                                                                        style={{
                                                                                            color:
                                                                                                selectedmonindex ===
                                                                                                index
                                                                                                    ? "#101828"
                                                                                                    : "#D7DDEA",

                                                                                            fontWeight:
                                                                                                "600",
                                                                                            fontSize:
                                                                                                "16px",
                                                                                            padding:
                                                                                                "16px 0px 16px 0px",
                                                                                            borderRadius:
                                                                                                "8px",
                                                                                            lineHeight:
                                                                                                "24px",
                                                                                            letterSpacing:
                                                                                                "2%",
                                                                                            textAlign:
                                                                                                "center",
                                                                                            background:
                                                                                                selectedmonindex ===
                                                                                                index
                                                                                                    ? "#F4F0FF"
                                                                                                    : "",
                                                                                        }}
                                                                                    >
                                                                                        {" "}
                                                                                        {month}{" "}
                                                                                    </div>
                                                                                )
                                                                            )}{" "}
                                                                        </div>
                                                                        <div
                                                                            className="overflow_xx"
                                                                            style={{
                                                                                overflow: "auto",
                                                                                width: "100%",
                                                                                display: "flex",
                                                                                flexDirection:
                                                                                    "column",
                                                                            }}
                                                                        >
                                                                            {" "}
                                                                            {ys.map(
                                                                                (lls, index) => (
                                                                                    <div
                                                                                        className="myhover"
                                                                                        style={{
                                                                                            color:
                                                                                                selectedyrindex ===
                                                                                                index
                                                                                                    ? "#101828"
                                                                                                    : "#D7DDEA",

                                                                                            fontWeight:
                                                                                                "600",
                                                                                            fontSize:
                                                                                                "16px",
                                                                                            padding:
                                                                                                "16px 0px 16px 0px",
                                                                                            borderRadius:
                                                                                                "8px",
                                                                                            lineHeight:
                                                                                                "24px",
                                                                                            letterSpacing:
                                                                                                "2%",
                                                                                            textAlign:
                                                                                                "center",
                                                                                            background:
                                                                                                selectedyrindex ===
                                                                                                index
                                                                                                    ? "#F4F0FF"
                                                                                                    : "",
                                                                                        }}
                                                                                        key={index}
                                                                                        onClick={() => {
                                                                                            getmyyear(
                                                                                                lls,
                                                                                                index
                                                                                            );
                                                                                        }}
                                                                                    >
                                                                                        {" "}
                                                                                        {lls}{" "}
                                                                                    </div>
                                                                                )
                                                                            )}{" "}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}{" "}
                                                    </div>
                                                    <div
                                                        style={{
                                                            position: "relative",
                                                            width: "100%",
                                                        }}
                                                    >
                                                        <input
                                                            type="text"
                                                            name=""
                                                            id=""
                                                            onChange={(e) => {
                                                                setduedate(e.target.value);
                                                            }}
                                                            value={duedate}
                                                            style={{
                                                                width: "100%",
                                                                borderRadius: "8px",
                                                                border: "none",
                                                                outline: "none",
                                                                background: "",
                                                                padding: "8px 16px 8px 16px",
                                                                backgroundColor: "#ECEFF3",
                                                                letterSpacing: "2%",
                                                                fontWeight: "600",
                                                                fontSize: "16px",
                                                                height: "56px",
                                                            }}
                                                            onFocus={() => {
                                                                closeModaldue(true);
                                                            }}
                                                            placeholder="Due date"
                                                        />{" "}
                                                        {closemodaldue && (
                                                            <div
                                                                className="shadow-3xl"
                                                                style={{
                                                                    position: "absolute",
                                                                    background: "#fff",
                                                                    width: "375px",
                                                                    borderRadius: "16px",
                                                                    display: "flex",
                                                                    flexDirection: "column",
                                                                    gap: "24px",
                                                                }}
                                                            >
                                                                <div
                                                                    style={{
                                                                        padding:
                                                                            "8px 24px 8px 24px",
                                                                        borderBottom:
                                                                            "1px solid #E7E9FB",
                                                                    }}
                                                                >
                                                                    <span
                                                                        className="material-icons"
                                                                        style={{ color: "#667085" }}
                                                                        onClick={() => {
                                                                            closeModaldue(false);
                                                                        }}
                                                                    >
                                                                        close
                                                                    </span>
                                                                </div>
                                                                <div
                                                                    style={{
                                                                        padding:
                                                                            "8px 24px 8px 24px",
                                                                        display: "flex",
                                                                        justifyContent:
                                                                            "space-between",
                                                                    }}
                                                                >
                                                                    <span
                                                                        style={{
                                                                            color: "#C3C4C7",
                                                                            fontWeight: "600",
                                                                            fontSize: "16px",
                                                                            lineHeight: "24px",
                                                                            cursor: "pointer",
                                                                        }}
                                                                        onClick={() => {
                                                                            closeModaldue(false);
                                                                        }}
                                                                    >
                                                                        Cancel
                                                                    </span>
                                                                    <span
                                                                        style={{
                                                                            color: "#383B46",
                                                                            fontSize: "16px",
                                                                            letterSpacing: "2%",
                                                                            lineHeight: "24px",
                                                                            fontWeight: "600",
                                                                        }}
                                                                    >
                                                                        Due Date
                                                                    </span>
                                                                    <span
                                                                        style={{
                                                                            color: "#6F00FF",
                                                                            letterSpacing: "2%",
                                                                            fontWeight: "600",
                                                                            lineHeight: "24px",
                                                                            fontSize: "16px",
                                                                            cursor: "pointer",
                                                                        }}
                                                                        onClick={() => {
                                                                            setdatedue();
                                                                            closeModaldue(false);
                                                                        }}
                                                                    >
                                                                        Confirm
                                                                    </span>
                                                                </div>
                                                                <div
                                                                    style={{
                                                                        height: "280px",
                                                                        padding:
                                                                            "8px 24px 8px 24px",
                                                                    }}
                                                                >
                                                                    <div
                                                                        style={{
                                                                            padding:
                                                                                "16px 0px 16px 0px",
                                                                            display: "flex",
                                                                            justifyContent:
                                                                                "space-between",
                                                                            height: "100%",
                                                                        }}
                                                                    >
                                                                        <div
                                                                            className="overflow_xx"
                                                                            style={{
                                                                                overflow: "auto",
                                                                                width: "100%",
                                                                                display: "flex",
                                                                                flexDirection:
                                                                                    "column",
                                                                            }}
                                                                        >
                                                                            {" "}
                                                                            {daysofms.map(
                                                                                (days, index) => (
                                                                                    <div
                                                                                        className="myhover"
                                                                                        id="myclassadd"
                                                                                        onClick={() => {
                                                                                            getmymonthdue(
                                                                                                days,
                                                                                                index
                                                                                            );
                                                                                        }}
                                                                                        key={index}
                                                                                        style={{
                                                                                            color:
                                                                                                selecteddayindexdue ===
                                                                                                index
                                                                                                    ? "#101828"
                                                                                                    : "#D7DDEA",

                                                                                            fontWeight:
                                                                                                "600",
                                                                                            fontSize:
                                                                                                "16px",
                                                                                            lineHeight:
                                                                                                "24px",
                                                                                            padding:
                                                                                                "16px 0px 16px 0px",
                                                                                            borderRadius:
                                                                                                "8px",
                                                                                            letterSpacing:
                                                                                                "2%",
                                                                                            textAlign:
                                                                                                "center",
                                                                                            background:
                                                                                                selecteddayindexdue ===
                                                                                                index
                                                                                                    ? "#F4F0FF"
                                                                                                    : "",
                                                                                        }}
                                                                                    >
                                                                                        {" "}
                                                                                        {days}{" "}
                                                                                    </div>
                                                                                )
                                                                            )}{" "}
                                                                        </div>
                                                                        <div
                                                                            className="overflow_xx"
                                                                            style={{
                                                                                overflow: "auto",
                                                                                width: "100%",
                                                                                display: "flex",
                                                                                flexDirection:
                                                                                    "column",
                                                                            }}
                                                                        >
                                                                            {" "}
                                                                            {months.map(
                                                                                (month, index) => (
                                                                                    <div
                                                                                        className="myhover"
                                                                                        onClick={() => {
                                                                                            getmydaysdue(
                                                                                                month,
                                                                                                index
                                                                                            );
                                                                                        }}
                                                                                        key={index}
                                                                                        style={{
                                                                                            color:
                                                                                                selectedmonindexdue ===
                                                                                                index
                                                                                                    ? "#101828"
                                                                                                    : "#D7DDEA",

                                                                                            fontWeight:
                                                                                                "600",
                                                                                            fontSize:
                                                                                                "16px",
                                                                                            padding:
                                                                                                "16px 0px 16px 0px",
                                                                                            borderRadius:
                                                                                                "8px",
                                                                                            lineHeight:
                                                                                                "24px",
                                                                                            letterSpacing:
                                                                                                "2%",
                                                                                            textAlign:
                                                                                                "center",
                                                                                            background:
                                                                                                selectedmonindexdue ===
                                                                                                index
                                                                                                    ? "#F4F0FF"
                                                                                                    : "",
                                                                                        }}
                                                                                    >
                                                                                        {" "}
                                                                                        {month}{" "}
                                                                                    </div>
                                                                                )
                                                                            )}{" "}
                                                                        </div>
                                                                        <div
                                                                            className="overflow_xx"
                                                                            style={{
                                                                                overflow: "auto",
                                                                                width: "100%",
                                                                                display: "flex",
                                                                                flexDirection:
                                                                                    "column",
                                                                            }}
                                                                        >
                                                                            {" "}
                                                                            {ys.map((ll, index) => (
                                                                                <div
                                                                                    className="myhover"
                                                                                    style={{
                                                                                        color:
                                                                                            selectedyrindexdue ===
                                                                                            index
                                                                                                ? "#101828"
                                                                                                : "#D7DDEA",

                                                                                        fontWeight:
                                                                                            "600",
                                                                                        fontSize:
                                                                                            "16px",
                                                                                        lineHeight:
                                                                                            "24px",
                                                                                        padding:
                                                                                            "16px 0px 16px 0px",
                                                                                        borderRadius:
                                                                                            "8px",
                                                                                        letterSpacing:
                                                                                            "2%",
                                                                                        textAlign:
                                                                                            "center",
                                                                                        background:
                                                                                            selectedyrindexdue ===
                                                                                            index
                                                                                                ? "#F4F0FF"
                                                                                                : "",
                                                                                    }}
                                                                                    key={index}
                                                                                    onClick={() => {
                                                                                        getmyyeardue(
                                                                                            ll,
                                                                                            index
                                                                                        );
                                                                                    }}
                                                                                >
                                                                                    {" "}
                                                                                    {ll}{" "}
                                                                                </div>
                                                                            ))}{" "}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}{" "}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {items.map((item, index) => (
                                    <div
                                        key={index}
                                        style={{
                                            display: "flex",
                                            gap: "32px",
                                        }}
                                    >
                                        <div
                                            className=""
                                            style={{
                                                display: "flex",
                                                gap: "8px",
                                                flexDirection: "column",
                                                maxWidth: "296px",
                                                width: "100%",
                                            }}
                                        >
                                            <label
                                                htmlFor=""
                                                style={{
                                                    color: "#344054",
                                                    fontWeight: "600",
                                                    lineHeight: "19.6px",
                                                    fontSize: "14px",
                                                }}
                                            >
                                                Items
                                            </label>
                                            <div style={{ gap: "8px" }}>
                                                <input
                                                    type="text"
                                                    name=""
                                                    id=""
                                                    required
                                                    onChange={(e) => updateitemitems(e, index)}
                                                    style={{
                                                        width: "100%",
                                                        borderRadius: "8px",
                                                        border: "none",
                                                        outline: "none",
                                                        height: "56px",
                                                        background: "",
                                                        padding: "8px 16px 8px 16px",
                                                        backgroundColor: "#ECEFF3",
                                                        letterSpacing: "2%",
                                                        fontWeight: "600",
                                                        fontSize: "16px",
                                                    }}
                                                    placeholder="Add item name"
                                                />
                                            </div>
                                        </div>
                                        <div
                                            className=""
                                            style={{
                                                display: "flex",
                                                gap: "8px",
                                                flexDirection: "column",
                                                maxWidth: "120px",
                                                width: "100%",
                                            }}
                                        >
                                            <label
                                                htmlFor=""
                                                style={{
                                                    color: "#344054",
                                                    fontWeight: "600",
                                                    lineHeight: "19.6px",
                                                    fontSize: "14px",
                                                }}
                                            >
                                                Quantity
                                            </label>
                                            <div style={{ gap: "8px" }}>
                                                <input
                                                    type="number"
                                                    name=""
                                                    id=""
                                                    required
                                                    onChange={(e) =>
                                                        updateitemquantity(
                                                            e,
                                                            index,
                                                            item.pricePerUnit
                                                        )
                                                    }
                                                    style={{
                                                        width: "100%",
                                                        borderRadius: "8px",
                                                        border: "none",
                                                        height: "56px",
                                                        outline: "none",
                                                        background: "",
                                                        padding: "8px 16px 8px 16px",
                                                        backgroundColor: "#ECEFF3",
                                                        letterSpacing: "2%",
                                                        fontWeight: "600",
                                                        fontSize: "16px",
                                                    }}
                                                    placeholder="quantity"
                                                />
                                            </div>
                                        </div>
                                        <div
                                            className=""
                                            style={{
                                                display: "flex",
                                                gap: "8px",
                                                flexDirection: "column",
                                                maxWidth: "120px",
                                                width: "100%",
                                            }}
                                        >
                                            <label
                                                htmlFor=""
                                                style={{
                                                    color: "#344054",
                                                    fontWeight: "600",
                                                    lineHeight: "19.6px",
                                                    fontSize: "14px",
                                                }}
                                            >
                                                Unit price
                                            </label>
                                            <div style={{ gap: "8px" }}>
                                                <input
                                                    type="number"
                                                    name=""
                                                    id=""
                                                    onChange={(e) =>
                                                        updateitemprice(e, index, item.quantity)
                                                    }
                                                    style={{
                                                        width: "100%",
                                                        borderRadius: "8px",
                                                        border: "none",
                                                        height: "56px",
                                                        outline: "none",
                                                        background: "",
                                                        padding: "8px 16px 8px 16px",
                                                        backgroundColor: "#ECEFF3",
                                                        letterSpacing: "2%",
                                                        fontWeight: "600",
                                                        fontSize: "16px",
                                                    }}
                                                    required
                                                    placeholder="Unit price"
                                                />
                                            </div>
                                        </div>
                                        <div
                                            className=""
                                            style={{
                                                display: "flex",
                                                gap: "8px",
                                                flexDirection: "column",
                                                maxWidth: "120px",
                                                width: "100%",
                                            }}
                                        >
                                            <label
                                                htmlFor=""
                                                style={{
                                                    color: "#344054",
                                                    fontWeight: "600",
                                                    lineHeight: "19.6px",
                                                    fontSize: "14px",
                                                }}
                                            >
                                                Amount
                                            </label>
                                            <div
                                                style={{
                                                    gap: "8px",
                                                    display: "flex",
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        width: "100%",
                                                        borderRadius: "8px",
                                                        border: "none",
                                                        outline: "none",
                                                        background: "",
                                                        padding: "8px 0px 8px 0px",
                                                        letterSpacing: "2%",
                                                        fontWeight: "600",
                                                        fontSize: "16px",
                                                    }}
                                                >
                                                    {selectedcurrency.title === "GPB"
                                                        ? "£"
                                                        : selectedcurrency.title === "NGN"
                                                        ? "₦"
                                                        : "€"}
                                                    {item.pricePerUnit * item.quantity}
                                                </div>
                                                {items.length > 1 && (
                                                    <div style={{ padding: "10px 0px 10px 0px" }}>
                                                        <img
                                                            src={trash}
                                                            alt=""
                                                            style={{ cursor: "pointer" }}
                                                            onClick={() => {
                                                                delitems(index);
                                                            }}
                                                        />
                                                    </div>
                                                )}{" "}
                                            </div>
                                            <div></div>
                                        </div>
                                    </div>
                                ))}

                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        gap: "16px",
                                    }}
                                >
                                    <div
                                        style={{
                                            display: "flex",
                                            color: "#6F00FF",
                                            fontWeight: "700",
                                            lineHeight: "19.6px",
                                            fontSize: "14px",
                                            letterSpacing: "2%",
                                            alignItems: "center",
                                            gap: "8px",
                                            cursor: "pointer",
                                        }}
                                        onClick={addanothersection}
                                    >
                                        <span className="material-icons">add</span>
                                        Add another item
                                    </div>
                                    <div
                                        style={{
                                            padding: "8px 12px 8px 12px",
                                            borderBottom: "1px dashed #D7DDEA",
                                            borderTop: "1px dashed #D7DDEA",
                                            display: "flex",
                                            justifyContent: "end",
                                        }}
                                    >
                                        <div
                                            style={{
                                                maxWidth: "384px",
                                                width: "100%",
                                                display: "flex",
                                                flexDirection: "column",
                                                gap: "16px",
                                            }}
                                        >
                                            <div
                                                style={{
                                                    padding: "0px 0px 0px 60px",
                                                    display: "flex",
                                                    gap: "16px",
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        color: "#667085",
                                                        lineHeight: "24px",
                                                        fontSize: "16px",
                                                        fontWeight: "600",
                                                        textAlign: "center",
                                                        width: "100%",
                                                    }}
                                                >
                                                    Subtotal:
                                                </div>
                                                <div
                                                    style={{
                                                        color: "#101828",
                                                        fontSize: "16px",
                                                        letterSpacing: "2%",
                                                        fontWeight: "600",
                                                        lineHeight: "24px",
                                                        width: "100%",
                                                    }}
                                                >
                                                    {selectedcurrency.title === "GPB"
                                                        ? "£"
                                                        : selectedcurrency.title === "NGN"
                                                        ? "₦"
                                                        : "€"}{" "}
                                                    {subtotal}{" "}
                                                </div>
                                            </div>
                                            <div
                                                style={{
                                                    padding: "0px 0px 0px 60px",
                                                    display: "flex",
                                                    gap: "16px",
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        color: "#667085",
                                                        lineHeight: "24px",
                                                        fontSize: "16px",
                                                        fontWeight: "600",
                                                        width: "100%",
                                                        textAlign: "center",
                                                    }}
                                                >
                                                    Surcharge:
                                                </div>
                                                <div
                                                    style={{
                                                        color: "#101828",
                                                        fontSize: "16px",
                                                        letterSpacing: "2%",
                                                        fontWeight: "600",
                                                        lineHeight: "24px",
                                                        width: "100%",
                                                    }}
                                                >
                                                    {selectedcurrency.title === "GPB"
                                                        ? "£"
                                                        : selectedcurrency.title === "NGN"
                                                        ? "₦"
                                                        : "€"}
                                                    0
                                                </div>
                                            </div>
                                            <div
                                                style={{
                                                    padding: "0px 0px 0px 30px",
                                                    display: "flex",
                                                    gap: "8px",
                                                }}
                                            >
                                                <div>
                                                    <input
                                                        type="checkbox"
                                                        name=""
                                                        id=""
                                                        style={{
                                                            accentColor: "#6F00FF",
                                                            borderColor: "#6F00FF",
                                                            borderRadius: "16px",
                                                            clipPath: "circle(100% at 50% 50%)",
                                                        }}
                                                    />
                                                </div>
                                                <div
                                                    style={{
                                                        color: "#667085",
                                                        lineHeight: "24px",
                                                        fontSize: "16px",
                                                        fontWeight: "600",
                                                        width: "100%",
                                                        display: "flex",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <div>Add Tax (7.5%):</div>
                                                </div>
                                                <div
                                                    style={{
                                                        color: "#101828",
                                                        fontSize: "16px",
                                                        letterSpacing: "2%",
                                                        fontWeight: "600",
                                                        lineHeight: "24px",
                                                        width: "100%",
                                                    }}
                                                >
                                                    <span style={{ marginLeft: "10px" }}>
                                                        {" "}
                                                        {selectedcurrency.title === "GPB"
                                                            ? "£"
                                                            : selectedcurrency.title === "NGN"
                                                            ? "₦"
                                                            : "€"}
                                                        0
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "end",
                                        marginTop: "-10px",
                                    }}
                                >
                                    <div
                                        style={{
                                            maxWidth: "384px",
                                            width: "100%",
                                            padding: "0px 0px 0px 60px",
                                            display: "flex",
                                        }}
                                    >
                                        <div
                                            style={{
                                                color: "#101828",
                                                lineHeight: "24.3px",
                                                fontSize: "18px",
                                                fontWeight: "700",
                                                textAlign: "center",
                                                width: "100%",
                                            }}
                                        >
                                            Total:
                                        </div>
                                        <div
                                            style={{
                                                color: "#101828",
                                                lineHeight: "24.3px",
                                                fontSize: "18px",
                                                fontWeight: "700",
                                                width: "100%",
                                            }}
                                        >
                                            {selectedcurrency.title === "GPB"
                                                ? "£"
                                                : selectedcurrency.title === "NGN"
                                                ? "₦"
                                                : "€"}{" "}
                                            {subtotal}{" "}
                                        </div>
                                    </div>
                                </div>

                                <div
                                    style={{
                                        maxWidth: "592px",
                                        width: "100%",
                                    }}
                                >
                                    <div
                                        className=""
                                        style={{
                                            display: "flex",
                                            gap: "8px",
                                            flexDirection: "column",
                                        }}
                                    >
                                        <label
                                            htmlFor=""
                                            style={{
                                                color: "#344054",
                                                fontWeight: "600",
                                                lineHeight: "19.6px",
                                                fontSize: "14px",
                                            }}
                                        >
                                            Notes
                                        </label>
                                        <div style={{ gap: "8px" }}>
                                            <textarea
                                                type="text"
                                                name=""
                                                id=""
                                                onChange={(e) => {
                                                    setnote(e.target.value);
                                                }}
                                                value={note}
                                                style={{
                                                    width: "100%",
                                                    height: "83px",
                                                    borderRadius: "8px",
                                                    border: "none",
                                                    outline: "none",
                                                    background: "",
                                                    padding: "8px 16px 8px 16px",
                                                    backgroundColor: "#ECEFF3",
                                                    letterSpacing: "2%",
                                                    fontWeight: "600",
                                                    fontSize: "16px",
                                                }}
                                                placeholder="Write a note here"
                                            ></textarea>
                                        </div>
                                    </div>
                                </div>
                                <div className="mb-5">
                                    <button
                                        disabled={isdisable}
                                        style={{
                                            width: "280px",
                                            background: isdisable ? "#EBE4FF" : "#6F00FF",
                                            padding: "16px 24px 16px 24px",
                                            border: "none",
                                            outline: "none",
                                            borderRadius: "8px",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            fontSize: "16px",
                                            lineHeight: "24px",
                                            letterSpacing: "2%",
                                            fontWeight: "600",
                                            color: isdisable ? "#BFA6FF" : "#ffff",
                                        }}
                                    >
                                        {loader === true ? <Loaders data={loader} /> : "Submit"}
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SetupInvoice;
