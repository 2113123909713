import LoginHeader from "../component/loginheader";
import "../style/index.css";
import { useEffect, useState } from "react";
import ArrowBack from "../component/arrowback";
import { useNavigate } from "react-router-dom/dist";
import { Helmet } from "react-helmet";
import Loaders from "../component/loader";
import axios from "axios";
import { countrycodelist } from "../constants/countryList";
import Swal from "sweetalert2";

const SubmitBusinessDocument = () => {
    const [businessCountry, setBusinessCountry] = useState("United Kingdom");
    const [rcNumber, setRcNumber] = useState("");
    //const [businessName] = useState("");
    const [businessType, setBusinesssType] = useState("");

    const [address, setAddress] = useState("");
    const [legalname, setlegalname] = useState("");
    const [tradename, settradename] = useState("");

    const [loader, setLoader] = useState(false);
    // const [value, setValue] = useState('')
    // const options = useMemo(() => countryList().getData(), [])
    const [showfirstnamelabe, setshowFirstnamelabel] = useState(false);
    // const [rclabel, setRclabel] = useState(false)
    const [addresslabel, setaddreesslabel] = useState(false);
    const [countrylabel, setcountrylabel] = useState(false);
    const [showlegalnamelabel, setshowlegalname] = useState(false);
    const [showtradenamelabel, setshowtradename] = useState(false);

    const [showhouselabel, setshowhousenumber] = useState(false);
    const [id, setid] = useState("");

    const [changecolorforlabellegalname, setchangecolorforlabellegalname] = useState(false);
    const [changecolorfortrade, setchangecolorfortrade] = useState(false);

    // const [changecolorforlabelpassword, setchangecolorforlabelpassword] = useState(false)
    const [changecolorforlabelemail, setchangecolorforlabelemail] = useState(false);
    // const [showPasswordLabel, setShowlabelPassword] = useState(false)
    const [changecolorforlabeladdress, setchangecolorforlabeladdress] = useState(false);
    const [changecolorforlabelhousenumebr, setchangecolorforlabelhousenumber] = useState(false);

    const navigate = useNavigate();
    const business = ["LLC", "LLP", "SoleProprietorship"];
    useEffect(() => {
        const getBusinessId = async () => {
            await axios
                .post("v1/business/generateBusinessId")
                .then((res) => {
                    setid(res.data._id);
                })
                .catch((e) => {
                    console.log(e);
                });
        };
        getBusinessId();
    }, [id]);
    const submitDocument = async (e) => {
        e.preventDefault();
        setLoader(true);

        const data = {
            country: businessCountry,
            registrationNumber: rcNumber,
            name: tradename,
            address: address,
            legalName: legalname,
            businessType: businessType,
            businessId: id,
        };
        await axios
            .post("/v1/business/create", data)
            .then((resp) => {
                Swal.fire({
                    title: "Success",
                    text: resp.data.message,
                    icon: "success",
                    confirmButtonText: "OK",
                    confirmButtonColor: "rgb(111, 0, 255)",
                    width: "280px",
                }).then((res) => {
                    if (res.value) {
                        setLoader(false);
                        navigate("/business/vatnumber", {
                            state: {
                                ...data,
                            },
                        });
                    }
                });
            })
            .catch((e) => {
                Swal.fire({
                    title: "Errror",
                    text: e.response.data.message,
                    icon: "error",
                    confirmButtonText: "OK",
                    confirmButtonColor: "rgb(111, 0, 255)",
                    width: "280px",
                }).then((res) => {
                    if (res.value) {
                        setLoader(false);
                    }
                });
            });
    };

    return (
        <div
            className="container-xxl"
            style={{
                background: "#F6F8FA",
                paddingRight: "0px",
            }}
        >
            <Helmet>
                <title>Business Registration</title>
            </Helmet>

            <LoginHeader width={12} />

            <div
                className="p-2 justify-self-center align-items-center d-flex"
                style={{ height: "calc(100% - 200px)" }}
            >
                <div
                    className="card border-0"
                    style={{
                        maxWidth: "591px",
                        width: "100%",
                        margin: " 20px auto",
                        background: "inherit",
                    }}
                >
                    <div
                        className="card-body"
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "32px",
                        }}
                    >
                        <div
                            className="d-flex justify-content-between mb-5"
                            style={{ alignItems: "center" }}
                        >
                            <ArrowBack />
                            <span
                                style={{
                                    fontSize: "14px",
                                    fontWeight: "500",
                                    color: "#344054",
                                    cursor: "pointer",
                                    lineHeight: "19.6px",
                                }}
                                onClick={() => {
                                    navigate("/dashboard");
                                }}
                            >
                                Skip
                            </span>
                        </div>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "4px",
                            }}
                        >
                            <h3
                                style={{
                                    fontSize: "32px",
                                    fontWeight: "500",
                                    color: "#101828",
                                    lineHeight: "40px",
                                    margin: "0px",
                                }}
                            >
                                Tell us about your business
                            </h3>
                            <span
                                style={{
                                    color: "#667085",
                                    fontSize: "16px",
                                    lineHeight: "24px",
                                    letterSpacing: "2%",
                                }}
                            >
                                Enter your business information
                            </span>
                        </div>

                        <form
                            onSubmit={submitDocument}
                            className=" d-flex "
                            style={{
                                justifyContent: "space-between",
                                flexDirection: "column",
                                gap: "32px",
                            }}
                        >
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: "16px",
                                }}
                            >
                                <div className="form-group position-relative">
                                    {countrylabel && (
                                        <small
                                            htmlFor="firstname "
                                            className="mylabel"
                                            style={{
                                                fontSize: "12px",
                                                marginLeft: "12px",
                                                fontWeight: "400",
                                                marginTop: "5px",
                                                marginBottom: "10px",
                                                color: "#667085",
                                                lineHeight: "16.2px",
                                            }}
                                        ></small>
                                    )}
                                    <select
                                        type="text"
                                        className="form-control py-2 border-0"
                                        aria-describedby="emailHelp"
                                        placeholder="Select Country"
                                        style={{
                                            background: "#ECEFF3",
                                            height: "56px",
                                            lineHeight: "24px",
                                        }}
                                        value={businessCountry}
                                        onFocus={() => {
                                            setcountrylabel(true);
                                        }}
                                        onChange={(e) => {
                                            setBusinessCountry(e.target.value);
                                        }}
                                    >
                                        {countrycodelist.map((countrycode, index) => (
                                            <option key={index}>{countrycode.title}</option>
                                        ))}{" "}
                                    </select>
                                </div>
                                <div
                                    className="w-100  position-relative  "
                                    style={{
                                        borderRadius: "8px",
                                        height: showlegalnamelabel ? "56px" : "",
                                        display: "flex",
                                        justifyContent: "space-between",
                                        flexDirection: "column",
                                        background: changecolorforlabellegalname
                                            ? "#D7DDEA"
                                            : "#ECEFF3",
                                    }}
                                >
                                    {showlegalnamelabel && (
                                        <small
                                            htmlFor="Email"
                                            className="small_text "
                                            style={{
                                                fontSize: "12px",
                                                marginLeft: "12px",
                                                fontWeight: "400",
                                                marginTop: "5px",
                                                color: changecolorforlabellegalname
                                                    ? "#6F00FF"
                                                    : "#667085",
                                                lineHeight: "16.2px",
                                            }}
                                        >
                                            Legal name
                                        </small>
                                    )}

                                    <input
                                        type="text"
                                        className="w-100 border-0"
                                        onKeyPress={() => {
                                            setchangecolorforlabellegalname(true);
                                        }}
                                        onBlur={() => {
                                            setchangecolorforlabellegalname(false);
                                        }}
                                        style={{
                                            outline: "none",
                                            borderRadius: "8px",
                                            color: "#667085",
                                            fontSize: "16px",
                                            background: changecolorforlabellegalname
                                                ? "#D7DDEA"
                                                : "#ECEFF3",
                                            padding: "12px",
                                            height: "50px",
                                            overflow: "hidden",
                                        }}
                                        value={legalname}
                                        required
                                        onChange={(e) => {
                                            setlegalname(e.target.value);
                                        }}
                                        onFocus={() => {
                                            setshowlegalname(true);
                                        }}
                                        placeholder="Legal name"
                                    />
                                </div>
                                <div
                                    className="w-100  position-relative  "
                                    style={{
                                        borderRadius: "8px",
                                        height: showhouselabel ? "56px" : "",
                                        display: "flex",
                                        justifyContent: "space-between",
                                        flexDirection: "column",
                                        background: changecolorforlabelhousenumebr
                                            ? "#D7DDEA"
                                            : "#ECEFF3",
                                    }}
                                >
                                    {showhouselabel && (
                                        <small
                                            htmlFor="Email"
                                            className="small_text "
                                            style={{
                                                fontSize: "12px",
                                                marginLeft: "12px",
                                                fontWeight: "400",
                                                marginTop: "5px",
                                                color: changecolorforlabelhousenumebr
                                                    ? "#6F00FF"
                                                    : "#667085",
                                                lineHeight: "16.2px",
                                            }}
                                        >
                                            House regsitration number
                                        </small>
                                    )}

                                    <input
                                        type="text"
                                        className="w-100 border-0"
                                        onKeyPress={() => {
                                            setchangecolorforlabelhousenumber(true);
                                        }}
                                        onBlur={() => {
                                            setchangecolorforlabelhousenumber(false);
                                        }}
                                        style={{
                                            outline: "none",
                                            borderRadius: "8px",
                                            color: "#667085",
                                            fontSize: "16px",
                                            background: changecolorforlabelhousenumebr
                                                ? "#D7DDEA"
                                                : "#ECEFF3",
                                            padding: "12px",
                                            height: "50px",
                                            overflow: "hidden",
                                        }}
                                        value={rcNumber}
                                        required
                                        onChange={(e) => {
                                            setRcNumber(e.target.value);
                                        }}
                                        onFocus={() => {
                                            setshowhousenumber(true);
                                        }}
                                        placeholder="House registration number"
                                    />
                                </div>
                                <div
                                    className="w-100  position-relative  "
                                    style={{
                                        borderRadius: "8px",
                                        height: showtradenamelabel ? "56px" : "",
                                        display: "flex",
                                        justifyContent: "space-between",
                                        flexDirection: "column",
                                        background: changecolorfortrade ? "#D7DDEA" : "#ECEFF3",
                                    }}
                                >
                                    {showtradenamelabel && (
                                        <small
                                            htmlFor="Email"
                                            className="small_text "
                                            style={{
                                                fontSize: "12px",
                                                marginLeft: "12px",
                                                fontWeight: "400",
                                                marginTop: "5px",
                                                color: changecolorfortrade ? "#6F00FF" : "#667085",
                                                lineHeight: "16.2px",
                                            }}
                                        >
                                            Trading name
                                        </small>
                                    )}

                                    <input
                                        type="text"
                                        className="w-100 border-0"
                                        onKeyPress={() => {
                                            setchangecolorfortrade(true);
                                        }}
                                        onBlur={() => {
                                            setchangecolorfortrade(false);
                                        }}
                                        style={{
                                            outline: "none",
                                            borderRadius: "8px",
                                            color: "#667085",
                                            fontSize: "16px",
                                            background: changecolorfortrade ? "#D7DDEA" : "#ECEFF3",
                                            padding: "12px",
                                            height: "50px",
                                            overflow: "hidden",
                                        }}
                                        value={tradename}
                                        required
                                        onChange={(e) => {
                                            settradename(e.target.value);
                                        }}
                                        onFocus={() => {
                                            setshowtradename(true);
                                        }}
                                        placeholder="Trading name"
                                    />
                                </div>
                                <div
                                    className="w-100  position-relative  "
                                    style={{
                                        borderRadius: "8px",
                                        height: showfirstnamelabe ? "56px" : "",
                                        display: "flex",
                                        justifyContent: "space-between",
                                        flexDirection: "column",
                                        background: changecolorforlabelemail
                                            ? "#D7DDEA"
                                            : "#ECEFF3",
                                    }}
                                >
                                    {showfirstnamelabe && (
                                        <small
                                            htmlFor="Email"
                                            className="small_text "
                                            style={{
                                                fontSize: "12px",
                                                marginLeft: "12px",
                                                fontWeight: "400",
                                                marginTop: "5px",
                                                color: changecolorforlabelemail
                                                    ? "#6F00FF"
                                                    : "#667085",
                                                lineHeight: "16.2px",
                                            }}
                                        >
                                            Business Type
                                        </small>
                                    )}
                                    <select
                                        type="text"
                                        className="w-100 border-0"
                                        onKeyPress={() => {
                                            setchangecolorforlabelemail(true);
                                        }}
                                        onBlur={() => {
                                            setchangecolorforlabelemail(false);
                                        }}
                                        style={{
                                            outline: "none",
                                            borderRadius: "8px",
                                            color: "#667085",
                                            fontSize: "16px",
                                            background: changecolorforlabelemail
                                                ? "#D7DDEA"
                                                : "#ECEFF3",
                                            height: "50px",
                                            overflow: "hidden",
                                            paddingLeft: "12px",
                                        }}
                                        value={businessType}
                                        required
                                        onChange={(e) => {
                                            setBusinesssType(e.target.value);
                                        }}
                                        onFocus={() => {
                                            setshowFirstnamelabel(true);
                                        }}
                                        placeholder="Business Type"
                                    >
                                        {business.map((busines, index) => (
                                            <option value={busines} key={index}>
                                                {busines}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div
                                    className="w-100  position-relative  "
                                    style={{
                                        borderRadius: "8px",
                                        height: addresslabel ? "56px" : "",
                                        display: "flex",
                                        justifyContent: "space-between",
                                        flexDirection: "column",
                                        background: changecolorforlabeladdress
                                            ? "#D7DDEA"
                                            : "#ECEFF3",
                                    }}
                                >
                                    {addresslabel && (
                                        <small
                                            htmlFor="Email"
                                            className="small_text "
                                            style={{
                                                fontSize: "12px",
                                                marginLeft: "12px",
                                                fontWeight: "400",
                                                marginTop: "5px",
                                                color: changecolorforlabeladdress
                                                    ? "#6F00FF"
                                                    : "#667085",
                                                lineHeight: "16.2px",
                                            }}
                                        >
                                            Address
                                        </small>
                                    )}
                                    <input
                                        type="text"
                                        className="w-100 border-0"
                                        onKeyPress={() => {
                                            setchangecolorforlabeladdress(true);
                                        }}
                                        onBlur={() => {
                                            setchangecolorforlabeladdress(false);
                                        }}
                                        style={{
                                            outline: "none",
                                            borderRadius: "8px",
                                            color: "#667085",
                                            fontSize: "16px",
                                            background: changecolorforlabeladdress
                                                ? "#D7DDEA"
                                                : "#ECEFF3",
                                            padding: "12px",
                                            height: "50px",
                                            overflow: "hidden",
                                        }}
                                        value={address}
                                        required
                                        onChange={(e) => {
                                            setAddress(e.target.value);
                                        }}
                                        onFocus={() => {
                                            setaddreesslabel(true);
                                        }}
                                        placeholder="Address"
                                    />
                                </div>
                                <div className=""></div>

                                <button
                                    disabled={loader}
                                    type="submit"
                                    className="btn  mt-3 "
                                    style={{
                                        background: "#6F00FF",
                                        color: "#fff",
                                        fontSize: "16px",
                                        letterSpacing: "2%",
                                        padding: "16px 24px 16px 24px",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        display: "flex",
                                    }}
                                >
                                    {loader === true ? <Loaders data={loader} /> : "Continue"}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SubmitBusinessDocument;
