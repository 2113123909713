import { Helmet } from "react-helmet";

// import {useState} from 'react';
import SerHeader from "../component/serviceheader";
import arrowleft from "../asset/images/arrowleftcircle.svg";
import bank from "../asset/images/bankicon.svg";
import { useEffect, useState } from "react";
import UploadModal from "../component/uploadModal";
import calendar from "../asset/images/letterofcredit/calendar-tick.svg";
import InvoiceSuccess from "../component/invoicesuccess";
import { useLocation } from "react-router-dom";


const Summary = () => {
  const [isdisable, setisdisable] = useState(true);
  const {state} = useLocation()
  useEffect(() => {
    setisdisable(false);
  }, [setisdisable]);
  const [filename, setfilename] = useState(null);
  const [filesize, setfilesize] = useState(0);
  const [showModal, setshowModal] = useState(false);
  const [showModal3, setshowModal3] = useState(false);

  return (
    <div className="h-100 w-100 ">
      <div className="" 
       style={{
        maxWidth:'2000px',
        width:'100%',
        margin:'0px auto'
      }}
      >
        <Helmet>
          <title>Letter of credit</title>
        </Helmet>
       
        <SerHeader header={"Letter of Credit"} width={100} />
        <UploadModal
          filesize={filesize}
          show={showModal}
          setshow={setshowModal}
          filename={filename}
          setfilesize={setfilesize}
          setfilename={setfilename}
        />
        <InvoiceSuccess
              show={showModal3}
              set={'finance'}
              setshow={setshowModal3}
              subject={"Application received! Your request is under review"}
              title={
                `We’ll review your request of  ${state.currency === "NGN"  ? '₦' : state.currency === 'EUR' ? '€' : '£'}${state.requestedAmount} in less than 48 hours. You’ll be notified on how to proceed from here.`
              }
            />
      </div>
      <div style={{ height: "100%" }}>
        <div
          className=" "
          style={{
            maxWidth: "2000px",
            margin: "0px auto",
            paddingLeft: "30px",
            paddingRight: "30px",
            paddingTop: "10px",
            paddingBottom: "10px",
          }}
        >
          <div
            style={{
              height: "100%",
              width: "100%",
              display: "flex",

              justifyContent: "center",
            }}
          >
            <div
                className="form-general-layout mb-5"

            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "32px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "16px",
                  }}
                >
                  <div>
                    <img src={arrowleft} alt="" />
                  </div>
                  <div>
                    <div
                      style={{
                        color: "#101828",
                        fontWeight: "600",
                        fontSize: "24px",
                        lineHeight: "32.4px",
                      }}
                    >
                      Summary
                    </div>
                    <div
                      style={{
                        color: "#667085",
                        fontSize: "16px",
                        lineHeight: "24px",
                        letterSpacing: "2%",
                        fontWeight: "500",
                        maxWidth: "600px",
                        width: "100%",
                      }}
                    >
                      Please review your details before you continue.
                    </div>
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "16px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "8px",
                  }}
                >
                  <label
                    htmlFor=""
                    style={{
                      color: "#667085",
                      fontWeight: "500",
                      lineHeight: "19.6px",
                      fontSize: "14px",
                    }}
                  >
                    Details
                  </label>
                  <div
                    style={{
                      background: "#fff",
                      borderRadius: "8px",
                      flexDirection: "column",
                      display: "flex",
                    }}
                  >
                    <div
                      style={{
                        padding: "16px",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <span
                        style={{
                          style: "#667085",
                          fontWeight: "500",
                          fontSize: "14px",
                          lineHeight: "19.6px",
                        }}
                      >
                        Issuing Bank
                      </span>
                      <div
                        style={{
                          gap: "8px",
                          display: "flex",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "4px",
                          }}
                        >
                          <div
                            style={{
                              color: "#6F00FF",
                              letterSpacing: "2%",
                              fontWeight: "600",
                              fontSize: "16px",
                              lineHeight: "24px",
                              display: "flex",
                              alignItems: "center",
                              gap: "8px",
                            }}
                          >
                            <img src={bank} alt="" height={20} width={20} />
                            { state.issuingBank.name}
                          </div>
                          <div
                            style={{
                              textAlign: "center",
                              fontSize: "12px",
                              lineHeight: "16.2px",
                              letterSpacing: "2%",
                              fontWeight: "500",
                              color: "#667085",
                              textAlign:'right'
                            }}
                          >
                            Quote: 0.05%
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        padding: "16px",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          gap: "8px",
                          display: "flex",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <span
                            style={{
                              style: "#667085",
                              fontWeight: "500",
                              fontSize: "14px",
                              lineHeight: "19.6px",
                            }}
                          >
                            Tenure
                          </span>
                        </div>
                      </div>
                      <div
                        style={{
                          color: "#6F00FF",
                          letterSpacing: "2%",
                          fontWeight: "600",
                          fontSize: "16px",
                          lineHeight: "24px",
                          display: "flex",
                          alignItems: "center",
                          gap: "8px",
                        }}
                      >
                        <img src={calendar} alt="" height={20} width={20} />
                        {state.tenure} Days
                      </div>
                    </div>
                    <div
                      style={{
                        padding: "16px",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          gap: "8px",
                          display: "flex",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <span
                            style={{
                              style: "#667085",
                              fontWeight: "500",
                              fontSize: "14px",
                              lineHeight: "19.6px",
                            }}
                          >
                            Amount
                          </span>
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "4px",
                        }}
                      >
                        <span
                          style={{
                            color: "#344054",
                            fontWeight: "600",
                            fontSize: "20px",
                            lineHeight: "24px",
                          }}
                        >
                          {state.currency === "NGN"  ? '₦' : state.currency === 'EUR' ? '€' : '£'} {state.requestedAmount}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    background: "#fff",
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "8px",
                    borderRadius: "8px",
                    alignItems: "center",
                  }}
                  variant="primary"
                >
                  <div
                    style={{
                      display: "flex",
                      gap: "8px",
                      alignItems:'center'
                    }}
                  >
                    <div
                      style={{
                        width: "40px",
                        height: "40px",
                        borderRadius: "250px",
                        alignItems: "center",
                        justifyContent: "ceneter",
                        background: "#F4F0FF",
                        textAlign: "center",
                        display: "flex",
                      }}
                    >
                      <div
                        style={{
                          color: "#7F56D9",
                          fontWeight: "600",
                          fontSize: "14px",
                          lineHeight: "20px",
                          textAlign: "center",
                          textTransform: "uppercase",
                          width: "100%",
                        }}
                      >
                        {state.firstName.slice(0,1)}{state.lastName.slice(0,1)}
                      </div>
                    </div>
                    <div>
                        <div style={{
                            display:'flex',
                            flexDirection: 'column'
                        }}>

                      
                      <span
                        style={{
                          color: "#101828",
                          letterSpacing: "2%",
                          lineHeight: "18px",
                          fontSize: "16px",
                          fontWeight: 500,
                        }}
                      >
                      {state.firstName} {" "}  { state.lastName}
                      </span>
                      <span>
                        {state.businessname}
                      </span>
                      </div>
                    </div>
                  </div>
                 
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "8px",
                  }}
                >
                  <label
                    htmlFor=""
                    style={{
                      color: "#667085",
                      fontWeight: "500",
                      lineHeight: "19.6px",
                      fontSize: "14px",
                    }}
                  >
                    Documents
                  </label>
                  <div
                    style={{
                      background: "#fff",
                      borderRadius: "8px",
                      flexDirection: "column",
                      display: "flex",
                    }}
                  >
                    <div
                      style={{
                        padding: "16px",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <span
                        style={{
                          style: "#667085",
                          fontWeight: "500",
                          fontSize: "14px",
                          lineHeight: "19.6px",
                        }}
                      >
                        Inspect Certificate
                      </span>
                      <div
                        style={{
                          gap: "8px",
                          display: "flex",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "4px",
                          }}
                        >
                          <div
                            style={{
                              color: "#6F00FF",
                              letterSpacing: "2%",
                              fontWeight: "600",
                              fontSize: "16px",
                              lineHeight: "24px",
                              display: "flex",
                              alignItems: "center",
                              gap: "8px",
                            }}
                          >
                                                   {state.InspectCertificate.name} - {(parseInt(state.InspectCertificateSize) / 1024).toFixed(2)} {parseInt(state.InspectCertificateSize) /1024  >= 1 ? 'MB' :  'KB'}

                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        padding: "16px",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          gap: "8px",
                          display: "flex",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <span
                            style={{
                              style: "#667085",
                              fontWeight: "500",
                              fontSize: "14px",
                              lineHeight: "19.6px",
                            }}
                          >
                            Forwarding  document
                          </span>
                        </div>
                      </div>
                      <div
                        style={{
                          color: "#6F00FF",
                          letterSpacing: "2%",
                          fontWeight: "600",
                          fontSize: "16px",
                          lineHeight: "24px",
                          display: "flex",
                          alignItems: "center",
                          gap: "8px",
                        }}
                      >
                    {state.ForwardingDocument.name} - {(parseInt(state.forwardingDocumentSize) / 1024).toFixed(2)} {parseInt(state.forwardingDocumentSize) /1024  >= 1 ? 'MB' :  'KB'}

                      </div>
                    </div>
                    <div
                      style={{
                        padding: "16px",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          gap: "8px",
                          display: "flex",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <span
                            style={{
                              style: "#667085",
                              fontWeight: "500",
                              fontSize: "14px",
                              lineHeight: "19.6px",
                            }}
                          >
                            Airway Bill
                          </span>
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "4px",
                        }}
                      >
                        <span
                          style={{
                            color: "#6F00FF",
                            letterSpacing: "2%",
                            fontWeight: "600",
                            fontSize: "16px",
                            lineHeight: "24px",
                            display: "flex",
                            alignItems: "center",
                            gap: "8px",
                          }}
                        >
                         {state.waybill.name} - {(parseInt(state.waybillSize) / 1024).toFixed(2)} {parseInt(state.waybillSize) /1024  >= 1 ? 'MB' :  'KB'}
                        </span>
                      </div>
                    </div>
                    <div
                      style={{
                        padding: "16px",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          gap: "8px",
                          display: "flex",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <span
                            style={{
                              style: "#667085",
                              fontWeight: "500",
                              fontSize: "14px",
                              lineHeight: "19.6px",
                            }}
                          >
                            Insurance ceritifcate
                          </span>
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "4px",
                        }}
                      >
                        <span
                          style={{
                            color: "#6F00FF",
                            letterSpacing: "2%",
                            fontWeight: "600",
                            fontSize: "16px",
                            lineHeight: "24px",
                            display: "flex",
                            alignItems: "center",
                            gap: "8px",
                          }}
                        >
                    {state.InsuranceCertificate.name} - {(parseInt(state.InsuranceCertificateSize) / 1024).toFixed(2)} {parseInt(state.InsuranceCertificateSize) /1024  >= 1 ? 'MB' :  'KB'}

                        </span>
                      </div>
                    </div>
                    <div
                      style={{
                        padding: "16px",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          gap: "8px",
                          display: "flex",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <span
                            style={{
                              style: "#667085",
                              fontWeight: "500",
                              fontSize: "14px",
                              lineHeight: "19.6px",
                            }}
                          >
                            Packing List
                          </span>
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "4px",
                        }}
                      >
                        <span
                          style={{
                            color: "#6F00FF",
                            letterSpacing: "2%",
                            fontWeight: "600",
                            fontSize: "16px",
                            lineHeight: "24px",
                            display: "flex",
                            alignItems: "center",
                            gap: "8px",
                          }}
                        >
                    {state.packing.name} - {(parseInt(state.PackingListSize) / 1024).toFixed(2)} {parseInt(state.PackingListSize) /1024  >= 1 ? 'MB' :  'KB'}

                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <button
                disabled={isdisable}
                onClick={() => {
                  setshowModal3(true);
                }}
                variant="primary"
                style={{
                  maxWidth: "280px",
                  width: "100%",
                  background: isdisable ? "#EBE4FF" : "#6F00FF",
                  padding: "16px 24px 16px 24px",
                  border: "none",
                  outline: "none",
                  borderRadius: "8px",
                  fontSize: "16px",
                  lineHeight: "24px",
                  letterSpacing: "2%",
                  fontWeight: "600",
                  color: isdisable ? "#BFA6FF" : "#ffff",
                }}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Summary;
