import useScreenSize from "hooks/useScreenSize";
import { forwardRef, useEffect, useMemo, useRef, useState } from "react";
import { getFromStorage, removeFromStorage, saveToStorage } from "utilities/localStorageUtils";

const Walkthrough = ({ step, setStep, takenTour, setTakenTour }) => {
    const [elmDetails, setElmDetails] = useState();
    const popCardRef = useRef();
    const { screenHeight: height, screenWidth: width } = useScreenSize();
    const lessThan475 = width < 475;

    const popperStyles = useMemo(() => {
        const popperHeight = popCardRef?.current?.offsetHeight ?? 188;
        const popperWidth = popCardRef?.current?.offsetWidth ?? 414;

        /**
         * @type {Array<React.CSSProperties>}
         */
        const styles = [
            {
                transform: lessThan475
                    ? "translate(0, 24vh)"
                    : `translate(calc(50vw - ${popperWidth / 2}px), 27vh)`,
            },
            {
                transform: lessThan475
                    ? `translate(0, ${elmDetails?.bottom + 15}px)`
                    : `translate(calc(50vw - ${popperWidth / 2}px), ${
                          width < 640
                              ? elmDetails?.top + (popperHeight - 30)
                              : elmDetails?.top - (popperHeight + 15)
                      }px)`,
            },
            {
                transform:
                    width < 780
                        ? `translate(${
                              width < 680
                                  ? lessThan475
                                      ? 0
                                      : `calc(50vw - ${popperWidth / 2}px)`
                                  : `${elmDetails?.left}px`
                          }, ${elmDetails?.bottom + 15}px)`
                        : `translate(${elmDetails?.right + 19}px, ${elmDetails?.top + 1}px)`,
            },
            {
                transform:
                    width < 800
                        ? `translate(${lessThan475 ? 0 : elmDetails?.left}px, ${
                              elmDetails?.bottom + 16
                          }px)`
                        : `translate(${
                              width < 830
                                  ? elmDetails?.right - (popperWidth - elmDetails?.width - 6)
                                  : elmDetails?.left - popperWidth - 19
                          }px, ${width < 830 ? elmDetails?.bottom + 16 : elmDetails?.top + 1}px)`,
            },
            {
                transform:
                    width < 820
                        ? `translate(${lessThan475 ? 0 : `calc(50vw - ${popperWidth / 2}px)`}, ${
                              elmDetails?.top - (popperHeight + 23)
                          }px)`
                        : `translate(${
                              width < 1250 ? elmDetails?.left : (elmDetails?.width + 70) / 4
                          }px, ${elmDetails?.top - (popperHeight + 23)}px)`,
            },
            {
                transform:
                    width < 820
                        ? `translate(${lessThan475 ? 0 : `calc(50vw - ${popperWidth / 2}px)`}, ${
                              elmDetails?.top - (popperHeight + 23)
                          }px)`
                        : `translate(${
                              width < 1250
                                  ? elmDetails?.right - popperWidth
                                  : elmDetails?.left + popperWidth / 2 - 70
                          }px, ${elmDetails?.top - (popperHeight + 23)}px)`,
            },
            {
                transform: `translate(${
                    lessThan475
                        ? "0"
                        : `${
                              width < 1024
                                  ? `calc(100vw - ${popperWidth + 24}px)`
                                  : `${elmDetails?.right - popperWidth}px`
                          }`
                }, ${elmDetails?.bottom + 20}px)`,
            },
        ];

        return styles[step];
    }, [step, elmDetails, height, width]);

    const updateTour = (bool = true) => {
        setTakenTour(bool);
        removeFromStorage("tdv-postponed-time");
    };

    const postponeTour = () => {
        setTakenTour(true);

        const futureTime = new Date();
        futureTime.setHours(futureTime.getHours() + 4);

        saveToStorage("tdv-postponed-time", futureTime);
    };

    useEffect(() => {
        const postponedTime = getFromStorage("tdv-postponed-time");
        const takenTour = getFromStorage("tdv-takenTour");

        if (postponedTime && new Date().getTime() >= new Date(postponedTime).getTime()) {
            setTimeout(() => updateTour(false), 1500);
        } else {
            setTimeout(() => setTakenTour(takenTour), 1500);
        }
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
        if (takenTour) {
            document.body.style.overflowY = "auto";
        } else {
            document.body.style.overflowY = "hidden";
        }
    }, [takenTour]);

    useEffect(() => {
        const { class: class_ } = elementsData[step];
        const { class: prevClass } = elementsData[step - 1] ?? {};

        const elements = document.querySelectorAll(`.${class_}`);
        const prevElements = document.querySelectorAll(`.${prevClass}`);

        prevElements.forEach((element) => {
            element.style.zIndex = "unset";
        });

        elements.forEach((element, idx) => {
            !idx && setElmDetails(element.getBoundingClientRect());
            element.style.zIndex = "2100";
        });
    }, [step]);

    const allowScrollDown = step === 5 && width < 820;
    useEffect(() => {
        if (allowScrollDown) {
            window.scroll(0, 750);
        }
    }, [allowScrollDown]);

    return (
        <>
            <div
                className={`bg-[#0000004D] transition-colors duration-300 fixed top-0 left-0 w-screen h-screen ${
                    takenTour || (step === 6 && width < 1024)
                        ? "opacity-0 -z-30 invisible"
                        : "opacity-100 z-[2000]"
                }`}
            />
            <div
                className={`${
                    allowScrollDown ? "absolute" : "fixed"
                } left-0 top-0 w-screen h-screen max-475:px-6 ${
                    takenTour ? "opacity-0 -z-30 invisible" : "opacity-100 z-[2200]"
                }`}
            >
                <PopCard
                    {...elementsData[step]}
                    step={step}
                    onClick={() => {
                        if (step === 6) return updateTour();
                        setStep((prev) => prev + 1);
                    }}
                    className={`${
                        takenTour ? "opacity-0 -z-30 invisible" : "opacity-100 z-[2000]"
                    } `}
                    leftBtnFunc={postponeTour}
                    ref={popCardRef}
                    style={popperStyles}
                />
            </div>
        </>
    );
};

const PopCard = forwardRef(
    ({ btn1Text, btn2Text, title, text, step, onClick, leftBtnFunc, className, style }, ref) => {
        return (
            <div
                className={`bg-white rounded-lg p-[24px] w-full 475:w-[414px] transition-all duration-300 ease-out ${className}`}
                ref={ref}
                style={style}
            >
                <h2 className="font-medium !text-lg 400:text-xl text-main-dark">{title}</h2>
                <p className="text-sm !mt-2 text-[#475467]">{text}</p>
                <div className="mt-[24px] flex justify-between">
                    <button
                        className={`rounded-lg !py-2 !px-6 max-320:text-[13px] max-350:text-sm ${
                            step ? "text-left pointer-events-none text-sm !pl-0" : "font-medium "
                        }`}
                        onFocus={leftBtnFunc}
                    >
                        {btn1Text}
                    </button>
                    <button
                        className="text-white bg-[#5E01D6] rounded-lg !py-2 !px-6 font-medium max-320:text-[13px] max-350:text-sm"
                        onClick={onClick}
                    >
                        {btn2Text}
                    </button>
                </div>
            </div>
        );
    }
);

const elementsData = [
    {
        title: "Welcome to your new dashboard 🎉",
        text: `We’ve made some changes to improve your experience. But not to worry, we left all the things you love the most.`,
        btn1Text: "Maybe later",
        btn2Text: "Take tour",
        class: "tradevu-tour-step-0",
    },
    {
        title: "Everything, everywhere, all at once",
        text: `Insights into your activity from across the platform allowing you less time to click and more to think.`,
        btn1Text: "1 of 6",
        btn2Text: "Next",
        class: "tradevu-tour-step-1",
    },
    {
        title: "Master currency control",
        text: `View all dashboard activity and analytics in any currency you have an account.`,
        btn1Text: "2 of 6",
        btn2Text: "Next",
        class: "tradevu-tour-step-2",
    },
    {
        title: "Take the shot",
        text: `We’ve brought all your favourite actions right to you. Make and collect payments in an instant.`,
        btn1Text: "3 of 6",
        btn2Text: "Next",
        class: "tradevu-tour-step-3",
    },
    {
        title: "Money in, money out",
        text: `Preview recent transactions on all your accounts and print statements on-the-go.`,
        btn1Text: "4 of 6",
        btn2Text: "Next",
        class: "tradevu-tour-step-4",
    },
    {
        title: "Call of duty",
        text: `We’ll collect and inform you of everything that needs your urgent attention.`,
        btn1Text: "5 of 6",
        btn2Text: "Next",
        class: "tradevu-tour-step-5",
    },
    {
        title: "The Office",
        text: `Tidily manage your account, biodata, businesses, and general preferences on the platform.`,
        btn1Text: "6 of 6",
        btn2Text: "Got it!",
        class: "tradevu-tour-step-6",
    },
];

export default Walkthrough;
