import { Helmet } from "react-helmet";

// import {useState} from 'react';
import SerHeader from "../component/serviceheader";
import arrowleft from "../asset/images/arrowleftcircle.svg";
import a1 from "../asset/images/letterofcredit/a1.svg";
import mark from "../asset/images/marksuccess.svg";
import a8 from "../asset/images/tradecredit/a8.svg";
//import Uploaddocumentfinance from "../component/uploadloaddocument";

import { useEffect, useState } from "react";
import UploadModal from "../component/uploadModal";
import Loader from "../component/loader";
import Swal from "sweetalert2/dist/sweetalert2.js";

import { useLocation, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import InventoryAgreement from "../component/InventoryAgreement";
import InventoryInvoice from "../component/inventoryInvoice";
import FailHandler from "../component/failhandler";

const TradeInventory = () => {
  // const [selectedIndex, setselectedIndex] = useState(-1)
  const navigate = useNavigate();
  const { state } = useLocation();
  const [isdisable, setisdisable] = useState(true);
  const [message, setmessage] = useState("");
  const [failhandler, setfailhandler] = useState(false);
  useEffect(() => {
    setisdisable(false);
  }, [setisdisable]);
  const [filename, setfilename] = useState([]);
  const [filesize, setfilesize] = useState(0);
  const [showModal, setshowModal] = useState(false);
  const [loader, setloader] = useState(false);
  const [aggreement, setagrrement] = useState([]);
  const [aggreementsize, setaggreementsize] = useState(0);
  const [showModal2, setshowModal2] = useState(false);
  const { id } = useParams();
  const HandleSubmit = async (e) => {
    setloader(true);
    e.preventDefault();
    const formdata = new FormData();
    formdata.append("agreement", aggreement, aggreement.name);
    formdata.append("invoice", filename, filename.name);

    await axios
      .put(
        `/v1/finance/trade_credit/create/${id}?type=inventoryDocuments`,
        formdata
      )
      .then(() => {
        setloader(false);
        navigate(`/trade_of_credit/goods/${id}`, {
          state: {
            ...state,
            invoice: filename,
            aggreement: aggreement,
          },
        });
      })
      .catch((e) => {
        //  console.log(e);
        if (!e.response) {
          // Network error
          setmessage("Network error: Please check your internet connection.");
        } else {
          // Server error
          setmessage(e.response.data.message);
        }

        setfailhandler(true);
      });
  };

  return (
    <div className="h-100 w-100 ">
      <div
        className=""
        style={{
          maxWidth: "2000px",
          width: "100%",
          margin: "0px auto",
        }}
      >
        <Helmet>
          <title>Working Capital</title>
        </Helmet>

        <SerHeader header={"Working Capital"} width={80} />
        <InventoryInvoice
          filesize={filesize}
          show={showModal}
          setshow={setshowModal}
          filename={filename}
          setfilesize={setfilesize}
          setfilename={setfilename}
        />
        <InventoryAgreement
          show={showModal2}
          setshow={setshowModal2}
          filename={aggreement}
          filesize={aggreementsize}
          setfilesize={setaggreementsize}
          setfilename={setagrrement}
        />
      </div>
      <div style={{ height: "100%" }}>
        <div
          className="conatiner "
          style={{
            maxWidth: "2000px",
            width: "100%",
            margin: "0px auto",
            paddingLeft: "30px",
            paddingRight: "30px",
            paddingTop: "10px",
            paddingBottom: "10px",
          }}
        >
          <div
            style={{
              height: "100%",
              width: "100%",
              display: "flex",

              justifyContent: "center",
            }}
          >
            <div className="form-general-layout mb-5">
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "32px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "16px",
                  }}
                >
                  <div
                    onClick={() => {
                      navigate(-1);
                    }}
                  >
                    <img src={arrowleft} alt="" />
                  </div>
                  <div>
                    <div
                      style={{
                        color: "#101828",
                        fontWeight: "500",
                        fontSize: "24px",
                        lineHeight: "32.4px",
                      }}
                    >
                      Upload documents
                    </div>
                    <div
                      style={{
                        color: "#667085",
                        fontSize: "16px",
                        lineHeight: "24px",
                        letterSpacing: "2%",
                        fontWeight: "400",
                        maxWidth: "500px",
                        width: "100%",
                      }}
                    >
                      Provide one of each of the following documents to get your
                      loan.
                    </div>
                  </div>
                </div>
              </div>
              <form
                onSubmit={HandleSubmit}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "16px",
                }}
              >
                <FailHandler success={failhandler} message={message} />

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "8px",
                  }}
                >
                  <label
                    htmlFor=""
                    style={{
                      color: "#667085",
                      fontWeight: "400",
                      lineHeight: "19.6px",
                      fontSize: "14px",
                    }}
                  >
                    Recommended documents
                  </label>
                  <div
                    style={{
                      background: "#fff",
                      borderRadius: "8px",
                      flexDirection: "column",
                      display: "flex",
                    }}
                  >
                    <div
                      onClick={() => {
                        setshowModal(true);
                        setfilename([]);
                        setfilesize(0);
                      }}
                      style={{
                        padding: "16px",
                        borderBottom: "1px solid #E7E9FB",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          gap: "8px",
                          display: "flex",
                        }}
                      >
                        <img src={a1} alt="" />
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <span
                            style={{
                              color: "#101828",
                              letterSpacing: "2%",
                              fontWeight: "600",
                              fontSize: "16px",
                              lineHeight: "24px",
                            }}
                          >
                            Invoice
                          </span>
                          <span
                            style={{
                              color: "#667085",
                              lineHeight: "16.2px",
                              fontSize: "12px",
                              fontWeight: "400",
                              letterSpacing: "2%",
                            }}
                          >
                            From supplier
                          </span>
                        </div>
                      </div>
                      {filename.length !== 0 ? (
                        <img src={mark} alt="" />
                      ) : (
                        <span className="material-icons">chevron_right</span>
                      )}
                    </div>
                    <div
                      style={{
                        padding: "16px",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                      onClick={() => {
                        setshowModal2(true);
                        setagrrement([]);
                        setaggreementsize(0);
                      }}
                    >
                      <div
                        style={{
                          gap: "8px",
                          display: "flex",
                        }}
                      >
                        <img src={a8} alt="" />
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <span
                            style={{
                              color: "#101828",
                              letterSpacing: "2%",
                              fontWeight: "600",
                              fontSize: "16px",
                              lineHeight: "24px",
                            }}
                          >
                            Supplier agreement
                          </span>
                          <span
                            style={{
                              color: "#667085",
                              lineHeight: "16.2px",
                              fontSize: "12px",
                              fontWeight: "400",
                              letterSpacing: "2%",
                            }}
                          >
                            From your country
                          </span>
                        </div>
                      </div>
                      {aggreement.length !== 0 ? (
                        <img src={mark} alt="" />
                      ) : (
                        <span className="material-icons">chevron_right</span>
                      )}
                    </div>
                  </div>
                </div>
                <button
                  disabled={isdisable}
                  variant="primary"
                  style={{
                    maxWidth: "280px",
                    marginTop: "10px",
                    width: "100%",
                    background: isdisable ? "#EBE4FF" : "#6F00FF",
                    padding: "16px 24px 16px 24px",
                    border: "none",
                    outline: "none",
                    borderRadius: "8px",
                    fontSize: "16px",
                    lineHeight: "24px",
                    letterSpacing: "2%",
                    fontWeight: "500",
                    color: isdisable ? "#BFA6FF" : "#ffff",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {loader === true ? <Loader data={loader} /> : "Submit"}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default TradeInventory;
