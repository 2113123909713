import logotr from "../asset/images/tr.svg";
import world from "../asset/images/worldtradevu.svg";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { LOGIN_USER } from "../store/action";
import { Link } from "react-router-dom";
import Loader from "../component/loader";
//import Swal from "sweetalert2/dist/sweetalert2.js";
import { Helmet } from "react-helmet";
import "../style/index.css";
import FailHandler from "../component/failhandler";
import axios from "axios";

const Login = () => {
  const dispatch = useDispatch();
  const [email, setemail] = useState("");
  const [password, setpassword] = useState("");
  const [loader, setloader] = useState(false);
  const [showEmailLabel, setShowlabelEmail] = useState(false);
  const [showPasswordLabel, setShowlabelPassword] = useState(false);
  const [sendAlert, setsendAlert] = useState(false);
  const [deviceType, setDeviceType] = useState("");
  const [address, setAddress] = useState(null);

  //const [success, setsuccess] = useState(false);
  const [message, setmessage] = useState("");
  const [fails, setfails] = useState(false);
  useEffect(() => {
    let timer = setTimeout(() => {
      setfails(false);
    }, 3000);
    return () => clearTimeout(timer);
  }, [fails]);
  // Check if the device has logged in within the last month
  function hasLoggedInWithinLastMonth() {
    // Retrieve the last login timestamp from sessionStorage
    const lastLoginTimestamp = sessionStorage.getItem("lastLoginTimestamp");

    if (lastLoginTimestamp) {
      // Convert the stored timestamp to a Date object
      const lastLoginDate = new Date(lastLoginTimestamp);

      // Calculate the difference in milliseconds between the current date and the last login date
      const timeDifference = new Date() - lastLoginDate;

      // Define the time threshold for considering a login as within the last month (30 days * 24 hours * 60 minutes * 60 seconds * 1000 milliseconds)
      const oneMonthInMillis = 30 * 24 * 60 * 60 * 1000;

      // Check if the last login is within the last month
      return timeDifference <= oneMonthInMillis;
    }

    // No previous login found
    return false;
  }
  useEffect(() => {
    // Check if the browser supports geolocation
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          try {
            // Use Google Maps Geocoding API to get the address
            const response = await axios.get(
              `https://maps.googleapis.com/maps/api/geocode/json?latlng=${position.coords.latitude},${position.coords.longitude}&key=AIzaSyANCcAa09J_M6N_tRRd6_K8cF8mIthUPBw`
            );

            // Extract the formatted address from the response
            const formattedAddress = response.data.results[0].formatted_address;
            setAddress(formattedAddress);
          } catch (error) {
            console.error("Error getting address:", error.message);
          }
        },
        (error) => {
          console.error("Error getting geolocation:", error.message);
        }
      );
    } else {
      console.error("Geolocation is not supported by your browser");
    }
  }, []);
  useEffect(() => {
    if (hasLoggedInWithinLastMonth()) {
      // console.log('User has logged in within the last month.');
      setsendAlert(false);
    } else {
      // console.log('User needs to log in.');
      setsendAlert(true);
    }
  }, []);
  useEffect(() => {
    const userAgent = navigator.userAgent;
    //console.log(userAgent);

    setDeviceType(userAgent);
  }, []);
  // console.log(deviceType);

  const SubmitHandler = (e) => {
    setloader(true);
    e.preventDefault();

    const data = {
      email: email,
      password: password,
      sendAlert: sendAlert.toString(),
      device: deviceType,
      location: address,
    };
    LOGIN_USER(data, dispatch)
      .then(() => {
        //  navigate("/dashboard");
        const currentTimestamp = new Date().getTime();
        sessionStorage.setItem("lastLoginTimestamp", currentTimestamp);
        window.location.replace("/dashboard");
      })
      .catch((e) => {
        console.log(e);
        setloader(false);
        setfails(true);
        //setsuccess(false);
        setmessage(
          e.response?.data.message
            ? e.response.data.message
            : "An error occurred , please try again"
        );
      });
  };
  return (
    <div className="main-login">
      <Helmet>
        <title>Tradevu — login</title>
      </Helmet>

      <div
        className="containers-lo"
        style={{
          position: "relative",
        }}
      >
        <header
          style={{
            height: "76px",
          }}
        >
          <nav
            style={{
              justifyContent: "flex-start",
              alignItems: "center",
              display: "flex",
            }}
          >
            <img
              src={logotr}
              alt=""
              style={{
                marginLeft: "30px",
              }}
            />
          </nav>
        </header>
        <div className="world-containers">
          <div
            className="la-card"
            style={{
              zIndex: "9999",
              position: "relative",
              marginLeft: "30px",
            }}
          >
            <FailHandler success={fails} message={message} />

            <form className="form-con" onSubmit={SubmitHandler}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "16px",
                }}
              >
                <div className="text-welcome">Welcome back</div>
                <div
                  className="text-lgs"
                  style={{
                    zIndex: "999",
                    fontWeight: "400",
                    fontSize: "16px",
                    lineHeight: "24px",
                    marginTop: "0px",
                  }}
                >
                  Manage the finance and operations stack of your supply chain
                  with Tradevu.
                </div>
              </div>

              <div className="input-form">
                <div
                  className="each-form-input"
                >
                  {showEmailLabel && (
                    <label
                      htmlFor="Email"
                      style={{
                        color: showEmailLabel ? "#6F00FF " : "#667085",
                      }}
                    >
                      Email Address
                    </label>
                  )}
                  <input
                    type="email"
                    placeholder="Email Address"
                    value={email}
                    onChange={(e) => {
                      setemail(e.target.value);
                    }}
                    onFocus={() => setShowlabelEmail(true)}
                  />
                </div>
                <div
                  className="each-form-input"
                 
                >
                  {showPasswordLabel && (
                    <label
                      htmlFor="Email"
                      style={{
                        color: showEmailLabel ? "#6F00FF " : "#667085",
                      }}
                    >
                      Password
                    </label>
                  )}
                  <input
                    onFocus={() => setShowlabelPassword(true)}
                    style={{
                      background: "inherit",
                      border: "1px solid inherit",
                    }}
                    type="password"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => {
                      setpassword(e.target.value);
                    }}
                    autoComplete="off"
                  />
                </div>

                <div className="keep_me_in_lg">
                  <div className="box_sign_checkbox">
                    <input
                      type="checkbox"
                      name=""
                      id=""
                      className="isChecked"
                    />
                    <span>Keep me signed in</span>
                  </div>
                  <Link
                    to={"/recovery/email_verify"}
                    className="forgotten_passord"
                    style={{
                      fontWeight: "600",
                    }}
                  >
                    Forget password?
                  </Link>
                </div>
              </div>
              <div className="lgn-btn-lg">
                <button
                  className="login-btn"
                  type="submit"
                  disabled={loader}
                  style={{
                    fontWeight: "700",
                  }}
                >
                  {loader ? <Loader /> : "Sign In"}
                </button>
                <span className="dont-have">
                  I don’t have an account,{" "}
                  <Link
                    to={"/register"}
                    style={{
                      fontWeight: "600",
                    }}
                  >
                    {" "}
                    Create one
                  </Link>{" "}
                </span>
              </div>
            </form>
          </div>

          <img
            src={world}
            alt=""
            className="world"
            style={{
              zIndex: "0",
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Login;
