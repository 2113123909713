import {useState} from 'react';
import Modal from 'react-bootstrap/Modal';
import copy from '../asset/images/flex/card/copy.svg'
import vervecard from '../asset/images/flex/card/vervecard.svg'
import '../style/rightside.css'
import withdraw from '../asset/images/flex/withdraw.svg'
import fund from '../asset/images/flex/fund.svg'
import eye from '../asset/images/flex/eyes.svg'
function CardDetails(props) {
    const [showsuccess, setshowsuccess] = useState(false)

    const copyURL1 = async (mytext) => {
        try {
            await navigator.clipboard.writeText(mytext);
            setshowsuccess(true)
            setTimeout(() => {
                setshowsuccess(false)
            }, 2000)

        } catch ($e) {
            console.log($e)
        }
    }
    const copyURL2 = async (mytext) => {
        try {
            await navigator.clipboard.writeText(mytext);
            setshowsuccess(true)
            setTimeout(() => {
                setshowsuccess(false)
            }, 2000)

        } catch ($e) {
            console.log($e)
        }
    }
    const copyURL3 = async (mytext) => {
        try {
            await navigator.clipboard.writeText(mytext);
            setshowsuccess(true)
            setTimeout(() => {
                setshowsuccess(false)
            }, 2000)

        } catch ($e) {
            console.log($e)
        }
    }
    return (<>


        <Modal show={
                props.show
            }
            //  onHide={handleClose}
            backdrop="static"
            keyboard={false}
            className='rightsidemodal'> {
            showsuccess && <div className="w-100 poistion-relative "
                style={
                    {height: '40px'}
            }>
                <div className="position-absolute  p-1"
                    style={
                        {
                            fontSize: '14px',
                            color: '#667085',
                            fontWeight: '500',
                            textAlign: 'center',
                            borderBottomLeftRadius: '8px',
                            borderBottomRightRadius: '8px',
                            background: 'lightgreen',
                            right: '0px',
                            left: '0px',
                            maxWidth: '200px',
                            margin: '0px auto'
                        }
                }>
                    Copy to clipboard
                </div>
            </div>
        }

            <div className=''
                style={
                    {
                        height: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }
            }>
                <div style={
                    {
                        height: 'calc(100% - 150px)',
                        width: '100%',
                        padding: '20px'
                    }
                }>
                    <div className="w-100"
                        style={
                            {height: '100%'}
                    }>
                        <div className="w-100 "
                            style={
                                {
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: '16px'
                                }
                        }>
                            <div className="d-flex gap-3 align-items-center"
                                onClick={
                                    () => {

                                        props.setshow(false)
                                    }
                            }>
                                <span className='material-icons'
                                    style={
                                        {
                                            color: '#667085',
                                            cursor: 'pointer'
                                        }
                                }>arrow_back</span>
                                <span style={
                                    {
                                        fontSize: '14px',
                                        color: '#667085',
                                        lineHeight: '18.9px',
                                        fontWeight: '500',
                                        textTransform: 'uppercase'
                                    }
                                }>
                                    back to Flex
                                </span>

                            </div>
                            <div className=""
                                style={
                                    {
                                        fontSize: '2rem',
                                        lineHeight: '40px',
                                        fontWeight: '500',
                                        color: '#101828'
                                    }
                            }>
                                Card details
                            </div>
                        </div>
                        <div className=""
                            style={
                                {
                                    border: '1px solid #E7E9FB',
                                    marginTop: '20px',
                                    width: '384px',
                                    borderRadius: '8px',
                                    padding: '16px',
                                    display: 'flex',
                                    gap: '10px',
                                    alignItems: 'center'

                                }
                        }>
                            <div style={
                                {
                                    display: 'flex',
                                    gap: '16px',
                                    alignItems: 'center'
                                }
                            }>
                                <img src={vervecard}
                                    alt=""/>
                                <div style={
                                    {
                                        display: 'flex',
                                        flexDirection: 'column',
                                        gap: '4px'
                                    }
                                }>
                                    <h4 style={
                                        {
                                            color: '#101828',
                                            fontWeight: '700',
                                            fontSize: '18px',
                                            lineHeight: '24.3px'
                                        }
                                    }>
                                        <sub>₦</sub>
                                        <span>500 000.</span>
                                        <sub style={
                                            {
                                                color: '#101828',
                                                fontSize: '14px',
                                                fontWeight: '400',
                                                lineHeight: '19.6px'
                                            }
                                        }>22</sub>
                                    </h4>
                                    <span style={
                                        {
                                            color: '#667085',
                                            fontWeight: '400',
                                            fontSize: '14px',
                                            lineHeight: '19.6px'
                                        }
                                    }>
                                        Verve card
                                    </span>
                                </div>

                            </div>
                        </div>
                        <div className=""
                            style={
                                {
                                    borderTop: '1px solid #E7E9FB',
                                    marginTop: '20px',
                                    width: '384px',
                                    display: 'flex',
                                    padding: '8px 0px 8px 0px',
                                    gap: '16px',
                                    alignItems: 'center',
                                    borderBottom: '1px solid #E7E9FB'


                                }
                        }>
                            <div style={
                                {
                                    display: 'flex',
                                    gap: '4px',
                                    padding: '8px'
                                }
                            }>
                                <img src={withdraw}
                                    alt=""/>
                                <span style={
                                    {
                                        color: '#6F00FF',
                                        fontWeight: '500',
                                        fontSize: '14px',
                                        lineHeight: '19.6px'

                                    }
                                }>Withdraw</span>

                            </div>
                            <div style={
                                {
                                    display: 'flex',
                                    gap: '4px',
                                    padding: '8px'
                                }
                            }>
                                <img src={fund}
                                    alt=""/>
                                <span style={
                                    {
                                        color: '#6F00FF',
                                        fontWeight: '500',
                                        fontSize: '14px',
                                        lineHeight: '19.6px'

                                    }
                                }>Fund</span>

                            </div>
                            <div style={
                                {
                                    display: 'flex',
                                    gap: '4px',
                                    padding: '8px'
                                }
                            }>
                                <img src={eye}
                                    alt=""/>
                                <span style={
                                    {
                                        color: '#6F00FF',
                                        fontWeight: '500',
                                        fontSize: '14px',
                                        lineHeight: '19.6px'

                                    }
                                }>Hide details</span>

                            </div>
                        </div>
                        <div className="w-100  d-flex">
                            <div className=""
                                style={
                                    {
                                        border: '1px solid #E7E9FB',
                                        marginTop: '20px',
                                        width: '384px',
                                        borderRadius: '8px',
                                        padding: '16px',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'space-between',
                                        gap: '16px'

                                    }
                            }>
                                <div className="d-flex justify-content-between">
                                    <span style={
                                        {
                                            color: '#667085',
                                            fontSize: '14px',
                                            fontWeight: '400',
                                            lineHeight: '19.6px'
                                        }
                                    }>Card name</span>
                                    <span style={
                                        {
                                            color: '#101828',
                                            fontSize: '16px',
                                            fontWeight: '500',
                                            lineHeight: '24px',
                                            letterSpacing: '2%'
                                        }
                                    }>Nikru Emina</span>
                                </div>
                                <div className="d-flex justify-content-between">
                                    <span style={
                                        {
                                            color: '#667085',
                                            fontSize: '14px',
                                            fontWeight: '400',
                                            lineHeight: '19.6px'
                                        }
                                    }>Card Number</span>
                                    <span style={
                                        {
                                            color: '#101828',
                                            fontSize: '16px',
                                            fontWeight: '500',
                                            lineHeight: '24px',
                                            letterSpacing: '2%'
                                        }
                                    }>1234 12345 1234 1234</span>
                                </div>
                                <div className="d-flex justify-content-between">
                                    <span style={
                                        {
                                            color: '#667085',
                                            fontSize: '14px',
                                            fontWeight: '400',
                                            lineHeight: '19.6px'
                                        }
                                    }>Expiry Date</span>
                                    <span style={
                                        {
                                            color: '#101828',
                                            fontSize: '16px',
                                            fontWeight: '500',
                                            lineHeight: '24px',
                                            letterSpacing: '2%'
                                        }
                                    }>22/24</span>
                                </div>
                                <div className="d-flex justify-content-between">
                                    <span style={
                                        {
                                            color: '#667085',
                                            fontSize: '14px',
                                            fontWeight: '400',
                                            lineHeight: '19.6px'
                                        }
                                    }>CVV</span>
                                    <span style={
                                        {
                                            color: '#101828',
                                            fontSize: '16px',
                                            fontWeight: '500',
                                            lineHeight: '24px',
                                            letterSpacing: '2%'
                                        }
                                    }>364</span>
                                </div>
                                <div className="d-flex justify-content-between">
                                    <span style={
                                        {
                                            color: '#667085',
                                            fontSize: '14px',
                                            fontWeight: '400',
                                            lineHeight: '19.6px'
                                        }
                                    }>Billing  Address</span>
                                    <span style={
                                        {
                                            color: '#101828',
                                            fontSize: '16px',
                                            fontWeight: '500',
                                            lineHeight: '24px',
                                            letterSpacing: '2%'
                                        }
                                    }>5, HemmingsWay, Your street</span>
                                </div>
                                <div className="d-flex justify-content-between">
                                    <span style={
                                        {
                                            color: '#667085',
                                            fontSize: '14px',
                                            fontWeight: '400',
                                            lineHeight: '19.6px'
                                        }
                                    }>ZIP code</span>
                                    <span style={
                                        {
                                            color: '#101828',
                                            fontSize: '16px',
                                            fontWeight: '500',
                                            lineHeight: '24px',
                                            letterSpacing: '2%'
                                        }
                                    }>10010</span>
                                </div>
                                <div className="d-flex justify-content-between">
                                    <span style={
                                        {
                                            color: '#667085',
                                            fontSize: '14px',
                                            fontWeight: '400',
                                            lineHeight: '19.6px'
                                        }
                                    }>City</span>
                                    <span style={
                                        {
                                            color: '#101828',
                                            fontSize: '16px',
                                            fontWeight: '500',
                                            lineHeight: '24px',
                                            letterSpacing: '2%'
                                        }
                                    }>Lagos</span>
                                </div>
                                <div className="d-flex justify-content-between">
                                    <span style={
                                        {
                                            color: '#667085',
                                            fontSize: '14px',
                                            fontWeight: '400',
                                            lineHeight: '19.6px'
                                        }
                                    }>State</span>
                                    <span style={
                                        {
                                            color: '#101828',
                                            fontSize: '16px',
                                            fontWeight: '500',
                                            lineHeight: '24px',
                                            letterSpacing: '2%'
                                        }
                                    }>Lagos</span>
                                </div>


                            </div>
                            <div className=""
                                style={
                                    {
                                        marginTop: '20px',
                                        gap: '16px',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'space-between',
                                        padding: '16px'

                                    }
                            }>

                                <div onClick={
                                        () => {
                                            copyURL2('Access bank')
                                        }
                                    }
                                    style={
                                        {cursor: 'pointer'}
                                }>
                                    <img src={copy}
                                        alt=""/>
                                </div>
                                <div onClick={
                                        () => {
                                            copyURL3('234567890')
                                        }
                                    }
                                    style={
                                        {cursor: 'pointer'}
                                }>
                                    <img src={copy}
                                        alt=""/>
                                </div>
                                <div onClick={
                                        () => {
                                            copyURL3('234567890')
                                        }
                                    }
                                    style={
                                        {cursor: 'pointer'}
                                }>
                                    <img src={copy}
                                        alt=""/>
                                </div>
                                <div onClick={
                                        () => {
                                            copyURL1('234567890')
                                        }
                                    }
                                    style={
                                        {cursor: 'pointer'}
                                }>
                                    <img src={copy}
                                        alt=""/>
                                </div>
                                <div onClick={
                                        () => {
                                            copyURL3('234567890')
                                        }
                                    }
                                    style={
                                        {cursor: 'pointer'}
                                }>
                                    <img src={copy}
                                        alt=""/>
                                </div>
                                <div onClick={
                                        () => {
                                            copyURL3('234567890')
                                        }
                                    }
                                    style={
                                        {cursor: 'pointer'}
                                }>
                                    <img src={copy}
                                        alt=""/>
                                </div>
                                <div onClick={
                                        () => {
                                            copyURL3('234567890')
                                        }
                                    }
                                    style={
                                        {cursor: 'pointer'}
                                }>
                                    <img src={copy}
                                        alt=""/>
                                </div>
                                <div onClick={
                                        () => {
                                            copyURL3('234567890')
                                        }
                                    }
                                    style={
                                        {cursor: 'pointer'}
                                }>
                                    <img src={copy}
                                        alt=""/>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </Modal>
    </>);
}

export default CardDetails;
