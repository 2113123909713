import Modal from "react-bootstrap/Modal";
import { useState } from "react";
import { useEffect } from "react";
import upload from "../../asset/images/colorupload.svg";

function WayBill(props) {
  const [isdisabled, setisDisable] = useState(true);
  // console.log(props.filename);
  useEffect(() => {
    if (props.filename.length !== 0) {
      setisDisable(false);
    }
  }, [props.filename.length]);
  const closeModal = () => {
    var datashow = props.show;
    props.setshow(!datashow);
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    props.setshow(false);
  };
  return (
    <Modal
      show={props.show}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="border-0 "
      onHide={() => props.setshow(false)}
    >
      <Modal.Body
        style={{
          borderRadius: "16px",
          padding: "0px",
          border: "none",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
          }}
        >
          <div
            className=""
            style={{
              borderBottom: "1px solid #E7E9FB",
              padding: "8px 24px 8px 24px",
            }}
          >
            <span
              className="material-icons"
              style={{ color: "#667085" }}
              onClick={closeModal}
            >
              close
            </span>
          </div>
          <div style={{ padding: "8px 24px 8px 24px" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "4px",
              }}
            >
              <h3
                style={{
                  fontWeight: "700",
                  color: "#101828",
                  lineHeight: "24.3px",
                  fontSize: "18px",
                }}
              >
                {" "}
                {props.title ? props.title : "WayBill"}
              </h3>
              <p
                style={{
                  color: "#667085",
                  fontSize: "16px",
                  lineHeight: "24px",
                  letterSpacing: "2%",
                  fontWeight: "500",
                }}
              >
                {" "}
                {props.text
                  ? props.text
                  : " Your invoice should clearly indicate your name, and items on sale"}{" "}
              </p>
            </div>
            <form
              className=""
              onSubmit={handleSubmit}
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "8px",
                height: "266px",
                justifyContent: "space-between",
              }}
            >
              <div>
                {" "}
                {props.filename.length === 0 && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "32px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        gap: "4px",
                        color: "#6F00FF",
                        fontSize: "14px",
                        lineHeight: "19.6px",
                        alignItems: "center",
                      }}
                    ></div>

                    <div
                      style={{
                        borderRadius: "8px",
                        padding: "24px 16px 24px 16px",
                        display: "flex",
                        flexDirection: "column",
                        gap: "16px",
                        background: "#ECEFF3",
                        justifyContent: "center",
                        position: "relative",
                      }}
                    >
                      <input
                        type="file"
                        style={{
                          position: "absolute",
                          height: "100%",
                          width: "100%",
                          left: "-5px",
                          opacity: "0",
                        }}
                        onChange={(e) => {
                          // setfiles(e.target.files[0]);
                          props.setfilename(e.target.files[0]);
                          props.setfilesize(e.target.files[0]?.size);
                        }}
                      />
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <img
                          src={upload}
                          alt=""
                          width={40}
                          height={40}
                          style={{ margin: "0px auto" }}
                        />
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <span
                            style={{
                              color: "#101828",
                              lineHeight: "24px",
                              fontWeight: "600",
                              letterSpacing: "2%",
                              fontSize: "16px",
                              textAlign: "center",
                            }}
                          >
                            Drag or Upload invoice here
                          </span>
                          <span
                            style={{
                              lineHeight: "16.2px",
                              fontSize: "12px",
                              letterSpacing: "2%",
                              fontWeight: "500",
                              textAlign: "center",
                              color: "#667085",
                            }}
                          >
                            JPEG, PNG, JPG, PDF
                          </span>
                          <span
                            style={{
                              lineHeight: "16.2px",
                              fontSize: "12px",
                              letterSpacing: "2%",
                              fontWeight: "500",
                              textAlign: "center",
                              color: "#667085",
                            }}
                          >
                            Max. file size: 20.0 MB
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {props.filename.length !== 0 && (
                  <div
                    style={{
                      background: "#C0CAD8",

                      gap: "8px",
                      borderRadius: "8px",
                      padding: "16px",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <span
                      style={{
                        textAlign: "center",
                        color: "#6F00FF",
                        letterSpacing: "2%",
                        fontWeight: "600",
                        lineHeight: "24px",
                      }}
                    >
                      {" "}
                      {props.filename.name}-{" "}
                      {parseFloat(props.filesize / 1024).toFixed(2)}{" "}
                    </span>
                    <span style={{ color: "#6F00FF" }}>
                      {props.filesize / 1024 >= 1024 ? "MB" : "KB"}{" "}
                    </span>
                  </div>
                )}{" "}
              </div>
              <div className="mb-2">
                <button
                  style={{
                    width: "100%",
                    background: isdisabled ? "#EBE4FF" : "#6F00FF",
                    padding: "16px 24px 16px 24px",
                    border: "none",
                    outline: "none",
                    borderRadius: "8px",
                    fontSize: "16px",
                    lineHeight: "24px",
                    letterSpacing: "2%",

                    fontWeight: "600",
                    color: isdisabled ? "#BFA6FF" : "#fff",
                  }}
                  disabled={isdisabled}
                >
                  Continue
                </button>
              </div>
            </form>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default WayBill;
