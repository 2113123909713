import capitalizeWords from "utilities/capitalizeWords";

const Status = ({ status, preview, children, className }) => {
    const statusClass = {
        pending: `bg-[#DBDFE5] ${preview ? "text-main-grey border-[#C0CAD8]" : "text-[#475467]"}`,
        confirmed: `bg-[#DCFDDB] ${preview ? "border-[#83F382] text-[#11A811]" : "text-[#107C10]"}`,
        approved: `bg-[#DCFDDB] ${preview ? "border-[#83F382] text-[#11A811]" : "text-[#107C10]"}`,
        accepted: `bg-[#DCFDDB] ${preview ? "border-[#83F382] text-[#11A811]" : "text-[#107C10]"}`,
        completed: `bg-[#DCFDDB] ${preview ? "border-[#BBF9B9] text-[#11A811]" : "text-[#107C10]"}`,
        "in transit": `bg-[#FFEFC6] ${
            preview ? "border-[#FEC74B] text-[#DB6704]" : "text-[#B64607]"
        }`,
        rejected: `bg-[#FFE1E1] ${preview ? "border-[#FFA1A1] text-[#F73C3C]" : "text-[#C01515]"}`,
        cancelled: `bg-[#FFE1E1] ${preview ? "border-[#FFA1A1] text-[#F73C3C]" : "text-[#C01515]"}`,
        delayed: `bg-[#FFE1E1] ${preview ? "border-[#FFA1A1] text-[#F73C3C]" : "text-[#C01515]"}`,
        decline: `bg-[#FFE1E1] ${preview ? "border-[#FFA1A1] text-[#F73C3C]" : "text-[#C01515]"}`,
    };
    return (
        <span
            className={`text-sm font-normal !px-2 ${
                preview ? "block w-max !border rounded-lg py-0.5" : "rounded-[13px] py-[1px]"
            } ${statusClass[status] ?? statusClass.pending} ${className}`}
        >
            {children ?? capitalizeWords(status)}
        </span>
    );
};

export default Status;
