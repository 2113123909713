import { Helmet } from "react-helmet";
// import DashHeader from "../component/dashheader";
// import homefinance from '../asset/im
import { useEffect, useState } from "react";

import SerHeader from "../component/serviceheader";

//import UploadInvoice from "../component/uploadinvoice";
import nigeria from "../asset/images/countries/ngn.svg";
import { currencies } from "../constants/currency";
import UploadModal from "../component/tenure";
import Uploaddocumentfinance from "../component/uploadloaddocument";
import InvoiceSuccess from "../component/invoicesuccess";
import Loader from "../component/loader";
import { useLocation, useNavigate } from "react-router-dom";
import arrowright from "../asset/images/chevron-right.svg";
import document from "../asset/images/invoice_document.svg";
import document2 from "../asset/images/payment_support.svg";
import "../style/slider.css";
//import axios from "axios";
//import Swal from "sweetalert2/dist/sweetalert2.js";
import info from "../asset/images/iconinfo.svg";
import edit from "../asset/images/marksuccess.svg";
import InvoiceType from "../component/invoiceType";
import FinanceInvoice from "../component/FinanceInvoice";
import UploadInvoiceCreateFile from "../component/uploadinvoicefilecreate";

const DocumentAdded = () => {
    const navigate = useNavigate();

    const { state } = useLocation();
    const [filename, setfilename] = useState([]);
    const [memo, setmemo] = useState("");
    const [id, setid] = useState("");
    const [filesize, setfilesize] = useState(0);
    const [aggreement, setagrrement] = useState([]);
    const [aggreementsize, setaggreementsize] = useState(0);
    const [showModal, setshowModal] = useState(false);
    const [showModal2, setshowModal2] = useState(false);
    const [showModal3, setshowModal3] = useState(false);
    const [tenureday, settenureday] = useState("0 Days");
    const [loader] = useState(false);
    const [isdisable, setisdisable] = useState(true);
    const [amount, setamount] = useState(state?.amount);
    const [showinvoice, setshowinvoice] = useState(false);

    const [documentFile, setDocumentFile] = useState([]);

    const [documenttype, setdocumenttype] = useState("invoice");
    const [savedinvoice, setsavedinvoice] = useState(false);

    const [tenure, settunre] = useState(["7 Days", "14 Days", "30 Days", "40 Days"]);
    console.log(state);
    const [showdrop, setisshowdrop] = useState(false);
    useEffect(() => {
        if (amount !== "") {
            setisdisable(false);
        } else {
            setisdisable(true);
        }
    }, [setisdisable, amount]);
    const submitHandler = async (e) => {
        e.preventDefault();
        if (documentFile.length !== 0 && aggreement.length !== 0) {
            navigate("/payment/summary", {
                state: {
                    bankName: state.bankName,
                    accountName: state.accountName,
                    accountNumber: state.accountNumber,
                    bankCode: state?.bankCode,
                    currency: state.currency,
                    amount: amount,
                    invoice: aggreement,
                    supportingDocument: documentFile,
                    fess: 0,
                    naration: memo,
                    sesid: state.sesid,
                    ...state,
                    invoiceId: id,
                },
            });
        }
    };

    //console.log(id);

    return (
        <div className="h-100 w-100 ">
            <div
                className=""
                style={{
                    maxWidth: "2000px",
                    width: "100%",
                    margin: "0px auto",
                }}
            >
                <Helmet>
                    <title>
                        {" "}
                        {state.payType === "crossBorderFx"
                            ? "Cross-border Payment"
                            : "Domestic Payment"}
                    </title>
                </Helmet>

                <SerHeader
                    header={`${
                        state.payType === "crossBorderFx"
                            ? "Cross-border Payment"
                            : "Domestic Payment"
                    }`}
                    width={50}
                />
                <InvoiceSuccess show={showModal3} setshow={setshowModal3} />
                <UploadInvoiceCreateFile
                    show={showModal2}
                    setshow={setshowModal2}
                    title={documenttype === "invoice" ? "Invoice" : "Supporting Document"}
                    text={
                        "Your document should clearly show important details such as names and dates."
                    }
                    filename={documenttype === "invoice" ? aggreement : documentFile}
                    filesize={aggreementsize}
                    setfilesize={setaggreementsize}
                    setfilename={documenttype === "invoice" ? setagrrement : setDocumentFile}
                    type={"payment"}
                    setshowinvoice={setshowinvoice}
                />
                <UploadModal
                    filesize={filesize}
                    show={showModal}
                    tenure={tenure}
                    tenureday={tenureday}
                    settenureday={settenureday}
                    settunre={settunre}
                    setshow={setshowModal}
                    filename={filename}
                    setfilesize={setfilesize}
                    setfilename={setfilename}
                />
                <InvoiceType
                    show={showinvoice}
                    setshow={setshowinvoice}
                    tenure={tenure}
                    setshowModal2={setshowModal2}
                    setsavedinvoice={setsavedinvoice}
                />
                <FinanceInvoice
                    setshow={setsavedinvoice}
                    show={savedinvoice}
                    type={"payment"}
                    currency={state?.currency}
                    setid={setid}
                    id={id}
                    setshowModal2={setshowinvoice}
                />
            </div>
            <div style={{ height: "100%" }}>
                <div
                    className=" "
                    style={{
                        maxWidth: "2000px",
                        width: "100%",
                        margin: "0px auto",
                        paddingLeft: "30px",
                        paddingRight: "30px",
                        paddingTop: "10px",
                        paddingBottom: "10px",
                    }}
                >
                    <div
                        style={{
                            height: "100%",
                            width: "100%",
                            display: "flex",

                            justifyContent: "center",
                        }}
                    >
                        <form onSubmit={submitHandler} className="form-general-layout">
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: "32px",
                                }}
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        gap: "8px",
                                    }}
                                >
                                    <div
                                        style={{
                                            color: "#101828",
                                            fontWeight: "600",
                                            fontSize: "24px",
                                            lineHeight: "32.4px",
                                        }}
                                    >
                                        How much?
                                    </div>
                                    <span
                                        style={{
                                            color: "#667085",
                                            fontWeight: "400",
                                            lineHeight: "24px",
                                            fontSize: "1rem",
                                            letterSpacing: "2%",
                                        }}
                                    >
                                        Enter amount below
                                    </span>
                                </div>
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        gap: "32px",
                                    }}
                                >
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            gap: "8px",
                                        }}
                                    >
                                        <label
                                            htmlFor=""
                                            style={{
                                                fontSize: "14px",
                                                lineHeight: "19.6px",
                                                fontWeight: "600",
                                                color: "#101828",
                                            }}
                                        >
                                            Amount
                                        </label>
                                        <div
                                            className=""
                                            style={{
                                                display: "flex",
                                                gap: "8px",
                                                flexDirection: "column",
                                            }}
                                        >
                                            <div
                                                style={{
                                                    display: "flex",
                                                    gap: "8px",
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        width: "100%",
                                                        maxWidth: "110px",
                                                        borderRadius: "8px",
                                                        border: "none",
                                                        outline: "none",
                                                        background: "",
                                                        padding: "8px 16px 8px 16px",
                                                        backgroundColor: "#ECEFF3",
                                                        letterSpacing: "2%",
                                                        fontWeight: "500",
                                                        fontSize: "16px",
                                                        display: "flex",
                                                        gap: "4px",
                                                        alignItems: "center",
                                                        position: "relative",
                                                    }}
                                                    onClick={() => {
                                                        setisshowdrop(!showdrop);
                                                    }}
                                                >
                                                    <img
                                                        src={
                                                            state.currency === "NGN"
                                                                ? nigeria
                                                                : state.currency === "EUR"
                                                                ? currencies[1].image
                                                                : currencies[2].image
                                                        }
                                                        alt=""
                                                        height={24}
                                                        width={24}
                                                    />
                                                    <span
                                                        style={{
                                                            color: "#667085",
                                                            letterSpacing: "2%",
                                                            lineHeight: "24px",
                                                            fontSize: "16px",
                                                            fontWeight: "500",
                                                        }}
                                                    >
                                                        {state.currency}
                                                    </span>
                                                </div>
                                                <input
                                                    type="number"
                                                    name=""
                                                    id=""
                                                    step={0.5}
                                                    min={1}
                                                    value={amount}
                                                    onChange={(e) => {
                                                        setamount(e.target.value);
                                                    }}
                                                    style={{
                                                        width: "100%",
                                                        borderRadius: "8px",
                                                        border: "none",
                                                        height: "56px",
                                                        outline: "none",
                                                        background: "",
                                                        padding: "8px 16px 8px 16px",
                                                        backgroundColor: "#ECEFF3",
                                                        letterSpacing: "2%",
                                                        fontSize: "16px",
                                                        fontWeight: "500",
                                                    }}
                                                    placeholder="Enter amount"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className="white-bh-lg"
                                        style={{
                                            minHeight: "56px",
                                        }}
                                    >
                                        <div className="transfer-bank-logo">
                                            <div className="intial">
                                                {/***
                        {state.currency === "NGN"
                          ? state.accountName
                              .match(/(\b\S)?/g)
                              .join("")
                              .match(/(^\S|\S$)?/g)
                              .join("")
                              .toUpperCase()
                          : ""}
                          */}
                                                {state?.name?.slice(0, 2)}
                                            </div>
                                            <div className="name-bank">
                                                <span
                                                    className="name-link"
                                                    style={{
                                                        fontWeight: "600",
                                                    }}
                                                >
                                                    {state.name}
                                                </span>
                                                <span
                                                    className="bank-link"
                                                    style={{
                                                        fontWeight: "400",
                                                    }}
                                                >
                                                    {state.currency === "NGN"
                                                        ? state.accountName
                                                        : state.accountName}{" "}
                                                    .{" "}
                                                    {state.currency === "NGN"
                                                        ? state.accountNumber
                                                        : state.accountNumber}
                                                </span>
                                            </div>
                                        </div>
                                        <img src={edit} alt="" />
                                    </div>
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            gap: "16px",
                                        }}
                                    >
                                        <label
                                            htmlFor=""
                                            style={{
                                                fontSize: "14px",
                                                lineHeight: "19.6px",
                                                fontWeight: "600",
                                                color: "#344054",
                                            }}
                                        >
                                            Add Payment Information
                                        </label>
                                        <div
                                            style={{
                                                padding: "16px",
                                                borderRadius: "8px",
                                                background: "#fff",
                                                display: "flex",
                                                flexDirection: "column",
                                                gap: "8px",
                                            }}
                                        >
                                            <div
                                                style={{
                                                    width: "100%",
                                                    display: "flex",
                                                    justifyContent: "space-between",
                                                    alignItems: "center",
                                                    cursor: "pointer",
                                                }}
                                                onClick={() => {
                                                    setagrrement([]);
                                                    setshowinvoice(true);
                                                    setdocumenttype("invoice");
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        gap: "8px",
                                                    }}
                                                >
                                                    <img src={document} alt="" />
                                                    <span className="attach_invoice">
                                                        Attach Invoice
                                                    </span>
                                                </div>
                                                {aggreement.length !== 0 || id !== "" ? (
                                                    <img src={edit} alt="" />
                                                ) : (
                                                    <img src={arrowright} alt="" />
                                                )}
                                            </div>
                                            <div
                                                style={{
                                                    width: "100%",
                                                    display: "flex",
                                                    justifyContent: "space-between",
                                                    alignItems: "center",
                                                }}
                                                onClick={() => {
                                                    setDocumentFile([]);
                                                    //  setshowinvoice(true);
                                                    setshowModal2(true);
                                                    setdocumenttype("supportdocument");
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        gap: "8px",

                                                        marginTop: "10px",
                                                    }}
                                                >
                                                    <img src={document2} alt="" />
                                                    <span className="attach_invoice">
                                                        Supporting Document
                                                    </span>
                                                </div>
                                                {documentFile.length !== 0 ? (
                                                    <img src={edit} alt="" />
                                                ) : (
                                                    <img src={arrowright} alt="" />
                                                )}{" "}
                                            </div>
                                        </div>
                                        <label
                                            htmlFor=""
                                            style={{
                                                fontSize: "14px",
                                                lineHeight: "19.6px",
                                                fontWeight: "600",
                                                color: "#344054",
                                            }}
                                        >
                                            Add Memo
                                        </label>
                                        <textarea
                                            value={memo}
                                            onChange={(e) => setmemo(e.target.value)}
                                            name=""
                                            id=""
                                            cols="10"
                                            rows="3"
                                            className="textarealg"
                                            placeholder="Write a note here..."
                                        ></textarea>
                                    </div>

                                    <div className="">
                                        <button
                                            disabled={isdisable}
                                            style={{
                                                maxWidth: "280px",
                                                width: "100%",
                                                background: isdisable ? "#EBE4FF" : "#6F00FF",
                                                padding: "16px 24px 16px 24px",
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                border: "none",
                                                outline: "none",
                                                minHeight: "46px",
                                                borderRadius: "8px",
                                                fontSize: "16px",
                                                lineHeight: "24px",
                                                letterSpacing: "2%",
                                                fontWeight: "600",
                                                color: isdisable ? "#BFA6FF" : "#ffff",
                                            }}
                                        >
                                            {loader === true ? (
                                                <Loader data={loader} />
                                            ) : (
                                                "Continue"
                                            )}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DocumentAdded;
