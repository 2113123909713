import { Helmet } from "react-helmet";

import SerHeader from "../component/serviceheader";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import ArrowBack from "../component/arrowback";
import "../style/main.css";
import edit from "../asset/images/edit-2.svg";
import searchicon from "../asset/images/search-normal.svg";
import BankModal from "../component/bankmodal";
import bankicon from "../asset/images/bankicon.svg";
import axios from "axios";
import { useLocation } from "react-router-dom";
const BankTransfer = () => {
  const navigate = useNavigate();
  const [showbank, setshowbank] = useState(false);
  const [bank, setbank] = useState([]);
  const [bankbeneficiarys, setbankbeneficiary] = useState([]);
  const { state } = useLocation();

  const [selectedbank, setselectedbank] = useState([]);
  const [search, setsearch] = useState("");
  useEffect(() => {
    const getBusinessId = async () => {
      await axios
        .get("/v1/wallets/get-banks")
        .then((res) => {
          //  console.log(res.data.data);
          setbank(res.data.data);
        })
        .catch((e) => {
          console.log(e);
        });
    };

    getBusinessId();
  }, []);
  useEffect(() => {
    let ignore = false;

    const getBeneficiary = async () => {
      await axios
        .get("/v1/beneficiary")
        .then((res) => {
          //  console.log(res);
          if (!ignore) setbankbeneficiary(res.data);
        })
        .catch((e) => {
          console.log(e);
        });
    };

    getBeneficiary();
    return () => {
      ignore = true;
    };
  }, []);
  const filtercontant = bankbeneficiarys?.filter((countrycodelists) =>
    countrycodelists.name?.match(search)
  );

  return (
    <div className="h-100 w-100 ">
      <div
        className=""
        style={{
          maxWidth: "2000px",
          width: "100%",
          margin: "0px auto",
        }}
      >
        <Helmet>
          <title>Bank transfer</title>
        </Helmet>

        <SerHeader header={"Local transfer"} width={50} />
        <BankModal
          show={showbank}
          currency={state.currency}
          setshow={setshowbank}
          banks={bank}
          bank={true}
          selectedbank={selectedbank}
          setselectedbank={setselectedbank}
        />
      </div>
      <div style={{ height: "100%" }}>
        <div
          className=" "
          style={{
            maxWidth: "2000px",
            margin: "0px auto",
            paddingLeft: "30px",
            paddingRight: "30px",
            paddingTop: "10px",
            paddingBottom: "10px",
            width: "100%",
          }}
        >
          <div
            style={{
              height: "100%",
              width: "100%",
              display: "flex",

              justifyContent: "center",
            }}
          >
            <div className="form-general-layout">
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "16px",
                }}
              >
                <ArrowBack />
                <div className="">
                  <div
                    style={{
                      color: "#101828",
                      fontSize: "24px",
                      lineHeight: "32.4px",
                      fontWeight: "600",
                    }}
                  >
                    Send funds
                  </div>
                  <p
                    style={{
                      color: "#667085",
                      lineHeight: "24px",
                      letterSpacing: "2%",
                      fontSize: "16px",
                      fontWeight: "500",
                    }}
                  >
                    Enter recipient details
                  </p>
                </div>
              </div>
              <div className="">
                <div className="">
                  <div className="">
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "32px",
                      }}
                    >
                      <div
                        className="white-bh-lg"
                        style={{
                          minHeight: "56px",
                        }}
                        onClick={() => setshowbank(true)}
                      >
                        <div className="transfer-bank-logo">
                          <img
                            src={bankicon}
                            alt=""
                            style={{
                              width: "20px",
                              height: "20px",
                            }}
                          />
                          <span
                            className="choose_bank"
                            style={{
                              fontWeight: "500",
                            }}
                          >
                            {" "}
                            {selectedbank.length === 0
                              ? "Choose bank"
                              : selectedbank.bankName}
                          </span>
                        </div>
                        <img src={edit} alt="" />
                      </div>
                      <div className="search-bank-lg-mf">
                        <label
                          htmlFor=""
                          className="beneficiary-label"
                          style={{
                            fontWeight: "500",
                          }}
                        >
                          Saved Beneficiaries{" "}
                        </label>
                        <div
                          className="search-dl-lg"
                          style={{
                            height: "56px",
                          }}
                        >
                          <img src={searchicon} alt="" />
                          <input
                            type="search"
                            className="mybene-search"
                            placeholder="Search beneficiaries"
                            value={search}
                            onChange={(e) => setsearch(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="bene-list">
                        {filtercontant.map((ben, index) => (
                          <div
                            className="each-list-ben"
                            key={index}
                            onClick={() => setselectedbank(ben)}
                          >
                            <div className="each-name-initial">
                              <div className="intial">
                                {ben?.name?.slice(0, 2)}
                              </div>
                              <div className="name-bank">
                                <span
                                  className="name-link"
                                  style={{
                                    fontWeight: "500",
                                  }}
                                >
                                  {ben.name}
                                </span>
                                <span
                                  className="bank-link"
                                  style={{
                                    fontWeight: "400",
                                  }}
                                >
                                  {ben.bankName} . {ben.accountNumber}
                                </span>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <button
                  onClick={() => {
                    console.log(selectedbank);
                    navigate("/transfer/bankdetails", {
                      state: {
                        ...state,
                        selectedbank,
                      },
                    });
                  }}
                  style={{
                    maxWidth: "278px",
                    width: "100%",
                    background: "#6F00FF",
                    color: "#fff",
                    padding: "16px 24px 16px 24px",
                    letterSpacing: "2%",
                    fontSize: "16px",
                    fontWeight: "600",
                    lineHeight: "24px",
                    borderRadius: "8px",
                    outline: "none",
                    border: "none",
                    minHeight: "46px",
                  }}
                >
                  Continue
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BankTransfer;
