import Modal from "react-bootstrap/Modal";
import success from "../asset/images/warning.svg";
import currencyPairCheck from "../utilities/currencyPairCheck";
import AmountCheck from "./amountCheck";

function Notice(props) {
    const closeModal = () => {
        var datashow = props.show;
        props.setshow(!datashow);
    };
    // console.log(props.data);

    return (
        <Modal
            show={props.show}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            onHide={() => props.setshow(false)}
            className="border-0 "
            border="0"
        >
            <Modal.Body
                style={{
                    borderRadius: "16px",
                    padding: "0px",
                    border: "none",
                }}
                border="0"
            >
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "10px",
                    }}
                >
                    <div
                        className=""
                        style={{
                            padding: "8px 24px 8px 24px",
                            display: "flex",
                            alignItems: "center",
                        }}
                    >
                        <span
                            className="material-icons"
                            style={{ color: "#667085" }}
                            onClick={closeModal}
                        >
                            close
                        </span>
                        <div
                            style={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "center",
                            }}
                        >
                            <span
                                style={{
                                    color: "#667085",
                                    fontSize: "14px",
                                    fontWeight: "400",
                                    lineHeight: "19.6px",
                                }}
                            >
                                INFO
                            </span>
                        </div>
                    </div>
                    <div style={{ padding: "8px 24px 8px 24px" }}>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "32px",
                                marginBottom: "10px",
                            }}
                        >
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    gap: "24px",
                                    flexDirection: "column",
                                    alignItems: "center",
                                }}
                            >
                                <div>
                                    <img src={success} alt="" />
                                </div>

                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        gap: "8px",
                                    }}
                                >
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            flexDirection: "column",
                                            alignItems: "center",
                                            gap: "16px",
                                        }}
                                    >
                                        <span
                                            style={{
                                                textAlign: "center",
                                                color: "#667085",
                                                fontWeight: "400",
                                                letterSpacing: "2%",
                                                fontSize: "16px",
                                                lineHeight: "24px",
                                            }}
                                        >
                                            This includes the principal amount you borrowed, plus
                                            the interest and any overdue fees that may apply.
                                        </span>
                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                gap: "8px",
                                                justifyContent: "center",
                                                width: "80%",
                                            }}
                                        >
                                            <div
                                                style={{
                                                    display: "flex",
                                                    gap: "16px",
                                                    width: "100%",
                                                }}
                                            >
                                                <span
                                                    style={{
                                                        fontWeight: "400",
                                                        fontSize: "14px",
                                                        lineHeight: "19.6px",
                                                        letterSpacing: "-1%",
                                                        color: "#667085",
                                                        textAlign: "right",
                                                        textAlign: "right",
                                                        width: "100%",
                                                    }}
                                                >
                                                    Principal Amount
                                                </span>
                                                <span
                                                    style={{
                                                        fontWeight: "500",
                                                        fontSize: "14px",
                                                        lineHeight: "19.6px",
                                                        textAlign: "left",
                                                        width: "100%",
                                                        color: "#344054",
                                                    }}
                                                >
                                                    {currencyPairCheck(props?.data?.currency)}

                                                    {AmountCheck(props.data?.requestedAmount)}
                                                </span>
                                            </div>
                                            <div
                                                style={{
                                                    display: "flex",
                                                    gap: "16px",
                                                    width: "100%",
                                                    textAlign: "right",
                                                }}
                                            >
                                                <span
                                                    style={{
                                                        fontWeight: "400",
                                                        fontSize: "14px",
                                                        lineHeight: "19.6px",
                                                        letterSpacing: "-1%",
                                                        color: "#667085",
                                                        textAlign: "right",
                                                        width: "100%",
                                                    }}
                                                >
                                                    Interest
                                                </span>
                                                <span
                                                    style={{
                                                        fontWeight: "500",
                                                        fontSize: "14px",
                                                        lineHeight: "19.6px",
                                                        color: "#344054",
                                                        width: "100%",
                                                        textAlign: "left",
                                                    }}
                                                >
                                                    {currencyPairCheck(props?.data?.currency)}

                                                    {AmountCheck(
                                                        props.data?.interestAmount
                                                            ? props.data?.interestAmount
                                                            : 0
                                                    )}
                                                </span>
                                            </div>
                                            <div
                                                style={{
                                                    display: "flex",
                                                    gap: "16px",
                                                    width: "100%",
                                                }}
                                            >
                                                <span
                                                    style={{
                                                        fontWeight: "400",
                                                        fontSize: "14px",
                                                        lineHeight: "19.6px",
                                                        letterSpacing: "-1%",
                                                        textAlign: "right",
                                                        width: "100%",
                                                        color: "#667085",
                                                        textAlign: "right",
                                                    }}
                                                >
                                                    Total Amount
                                                </span>
                                                <span
                                                    style={{
                                                        fontWeight: "500",
                                                        fontSize: "14px",
                                                        lineHeight: "19.6px",
                                                        color: "#344054",
                                                        textAlign: "left",
                                                        width: "100%",
                                                    }}
                                                >
                                                    {currencyPairCheck(props?.data?.currency)}{" "}
                                                    {AmountCheck(
                                                        parseFloat(props.data?.requestedAmount) +
                                                            parseFloat(
                                                                props.data?.interestAmount
                                                                    ? props.data?.interestAmount
                                                                    : 0
                                                            )
                                                    )}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: "8px",
                                }}
                            >
                                <button
                                    onClick={() => {
                                        props.setshow(false);
                                    }}
                                    style={{
                                        background: "inherit",
                                        color: "#6F00FF",
                                        padding: "8px 12px 8px 12px",
                                        border: "none",
                                        outline: "none",
                                        borderRadius: "8px",
                                    }}
                                >
                                    I understand
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default Notice;
