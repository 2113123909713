import Loaders from "../asset/images/l.gif";
const Loading = () => {
  return (
    <div
      className=""
      style={{
        height: "100vh",
        width: "100%",
        background: "#f6f6f6",
      }}
    >
      <div
        className="w-100 h-100"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img src={Loaders} alt="" />
      </div>
    </div>
  );
};

export default Loading;
