import { Helmet } from "react-helmet";
// import DashHeader from "../component/dashheader";
// import homeLetterOfcreditType from '../asset/im
import SerHeader from "../component/serviceheader";
import seller from "../asset/images/letterofcredit/buyer.png";
import buyer from "../asset/images/letterofcredit/seller.svg";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const LetterOfcreditType = () => {
  // const [selectedIndex, setselectedIndex] = useState(-1)
  const navigate = useNavigate();
  const [selectedindex, setselectedindex] = useState(-1);
  const type = [
    {
      title: "Use a written commitment from your supplier",
      subject: "Buyer",
      image: buyer,
    },
    {
      title: "Use a written commitment from your buyer",
      subject: "Seller",
      image: seller,
    },
  ];
  const goanotherpage = () => {
    console.log(selectedindex);
    if (selectedindex === 0) {
      navigate("/letter_of_credit/credit/buyer");
    } else {
      navigate("/letter_of_credit/credit/supplier");
    }
  };
  return (
    <div
      className="h-100 w-100 "
      style={{
        background: "#f6f6f6",
      }}
    >
      <div
        className=""
        style={{
          maxWidth: "2000px",
          width: "100%",
          margin: "0px auto",
          background: "",
        }}
      >
        <Helmet>
          <title>Credit Line</title>
        </Helmet>

        <SerHeader header={"Letter of Credit"} width={11} />
      </div>
      <div style={{ height: "100%" }}>
        <div
          className="conatiner "
          style={{
            maxWidth: "2000px",
            width: "100%",
            margin: "0px auto",
            paddingLeft: "30px",
            paddingRight: "30px",
            paddingTop: "10px",
            paddingBottom: "10px",
          }}
        >
          <div
            style={{
              height: "100%",
              width: "100%",
              display: "flex",

              justifyContent: "center",
            }}
          >
            <div className="form-general-layout">
              <div className="">
                <div
                  style={{
                    color: "#101828",
                    fontSize: "24px",
                    lineHeight: "32.4px",
                    fontWeight: "600",
                  }}
                >
                  I am a....
                </div>
              </div>
              <div className="">
                <div className="card border-0">
                  <div className="card-body p-1">
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        cursor: "pointer",
                      }}
                    >
                      {" "}
                      {type.map((item, index) => (
                        <div
                          style={{
                            padding: "8px",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            position: "relative",
                            background:
                              selectedindex === index ? "#EDFCFE" : "",
                            borderRadius: "8px",
                          }}
                          onClick={() => {
                            setselectedindex(index);
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              gap: "16px",
                            }}
                          >
                            <img
                              src={item.image}
                              alt=""
                              width={48}
                              height={48}
                            />
                            <div
                              style={{
                                overflow: "hidden",
                                display: "flex",
                                flexDirection: "column",
                                gap: "4px",
                              }}
                            >
                              <div
                                style={{
                                  color: "#101828",
                                  fontSize: "16px",
                                  lineHeight: "24px",
                                  letterSpacing: "2%",
                                  fontWeight: "600",
                                }}
                              >
                                {" "}
                                {item.subject}{" "}
                              </div>
                              <p
                                style={{
                                  fontSize: "14px",
                                  fontWeight: "500",
                                  lineHeight: "19.6px",
                                  color: "#667085",
                                }}
                              >
                                {" "}
                                {item.title}{" "}
                              </p>
                            </div>
                          </div>
                          <span
                            className="material-icons"
                            style={{ color: "#667085" }}
                          >
                            chevron_right
                          </span>
                        </div>
                      ))}{" "}
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <button
                  onClick={goanotherpage}
                  style={{
                    maxWidth: "278px",
                    width: "100%",
                    background: "#6F00FF",
                    color: "#fff",
                    padding: "16px 24px 16px 24px",
                    letterSpacing: "2%",
                    fontSize: "16px",
                    fontWeight: "600",
                    lineHeight: "24px",
                    borderRadius: "8px",
                    outline: "none",
                    border: "none",
                  }}
                >
                  Continue
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LetterOfcreditType;
