const getInitials = (str, len, space) => {
    const newStr = `${str}`
        .replace(/[^a-zA-Z ]/g, "")
        .split(" ")
        .map((word) => word.charAt(0).toUpperCase())
        .join(space ? " " : "");

    if (len) {
        if (newStr.length > len) newStr.length = len;
    }

    return newStr;
};

export default getInitials;
