const FailHandler = (props) => {
  return (
    props.success && (
      <div
        className="success"
        style={{
          background: "#FCFCFD",
          border: " 1px solid #D0D5DD",
          marginTop: "10px",
          boxSizing: "border-box",
          position: "fixed",
          left: "50%",
          transform: "translateX(-50%)",
          width: "100%",
          width: "80%",
          maxWidth: "400px",
          top: "100px",
        }}
      >
        <div className="lg-success">
          <div
            className="ech-lg-hm"
            style={{
              background: "#F9FAFB",
            }}
          >
            <div
              className="main-sucees"
              style={{
                color: "#344054",
              }}
            >
              Failed
            </div>
            <span
              style={{
                color: "#344054",
              }}
            >
              {props.message}
            </span>
          </div>
        </div>
      </div>
    )
  );
};

export default FailHandler;
