export const saveToStorage = (name, data) => {
    localStorage.setItem(name, JSON.stringify(data));
};

export const getFromStorage = (name) => {
    const data = localStorage.getItem(name);
    const noData = !data || data === "undefined";
    return noData ? null : JSON.parse(data);
};

export const removeFromStorage = (name) => {
    localStorage.removeItem(name);
};
