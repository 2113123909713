import Modal from "react-bootstrap/Modal";
import success from "../asset/images/warningperson.png";
//import { useNavigate } from "react-router-dom";
import Loader from "../component/loader";

function DeleteModal(props) {
    const closeModal = () => {
        var datashow = props.show;
        props.setshow(!datashow);
    };
    // const navigate = useNavigate()

    return (
        <Modal
            show={props.show}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="border-0 "
            onHide={() => props.setshow(false)}
        >
            <Modal.Body
                style={{
                    borderRadius: "16px",
                    padding: "0px",
                    border: "none",
                }}
            >
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "10px",
                    }}
                >
                    <div
                        className=""
                        style={{
                            borderBottom: "1px solid #E7E9FB",
                            padding: "8px 24px 8px 24px",
                        }}
                    >
                        <span
                            className="material-icons"
                            style={{ color: "#667085" }}
                            onClick={closeModal}
                        >
                            close
                        </span>
                    </div>
                    <div style={{ padding: "8px 24px 8px 24px" }}>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "32px",
                                marginBottom: "10px",
                            }}
                        >
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    gap: "24px",
                                    flexDirection: "column",
                                    alignItems: "center",
                                }}
                            >
                                <div>
                                    <img src={success} alt="" />
                                </div>

                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        gap: "8px",
                                    }}
                                >
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            flexDirection: "column",
                                            alignItems: "center",
                                        }}
                                    >
                                        <h3
                                            style={{
                                                color: "#101828",
                                                fontWeight: "700",
                                                lineHeight: "24.3px",
                                                fontSize: "18px",
                                                textAlign: "center",
                                            }}
                                        >
                                            {props.subject ? props.subject : "Funds sent"}
                                        </h3>
                                        <p
                                            style={{
                                                textAlign: "center",
                                                color: "#667085",
                                                fontWeight: "600",
                                                letterSpacing: "2%",
                                                fontSize: "16px",
                                                lineHeight: "24px",
                                            }}
                                        >
                                            {props.title
                                                ? props.title
                                                : "We’ll review your request of"}

                                            <span
                                                style={{
                                                    fontWeight: "600",
                                                    color: "#6F00FF",
                                                    marginRight: "4px",
                                                    marginLeft: "4px",
                                                }}
                                            >
                                                {props.currency}
                                                {props.amount}
                                            </span>
                                            {props.title
                                                ? ""
                                                : "  in less than 48 hours. You’ll be notified on how to proceed. Tradevu assumes the credit risk on your buyers."}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: "8px",
                                }}
                            >
                                <button
                                    disabled={props.loader}
                                    type="button"
                                    onClick={props.deletedBuyer}
                                    style={{
                                        background: "#F04438",
                                        color: "#FFFBFA",
                                        padding: "16px 24px 16px 24px",
                                        border: "none",
                                        outline: "none",
                                        borderRadius: "8px",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        fontWeight: "600",
                                    }}
                                >
                                    {props.loader ? <Loader /> : `Yes, Delete ${props.mode}`}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default DeleteModal;
