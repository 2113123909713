import React, { isValidElement, useEffect, useRef, useState } from "react";

/**
 * @typedef {Object} Option
 * @property {React.ReactNode} label
 * @property {string | number} value
 * @property {boolean} [disabled]
 * @property {boolean} [closeAfterClick]
 * @property {Function} [action]
 * @property {string} [className]
 */

/**
 * @param {Object} props
 * @param {string} props.dropClassName
 * @param {string} props.className
 * @param {Option[]} props.options
 * @param {React.ReactNode} props.element
 */

const ActionDropdown = ({ options, element, dropClassName, className }) => {
    const [show, setShow] = useState(false);
    const wrapperRef = useRef();

    useEffect(() => {
        const outsideClickClose = (evt) =>
            wrapperRef.current && !wrapperRef.current.contains(evt.target) && setShow(false);

        document.addEventListener("click", outsideClickClose);
        return () => {
            document.removeEventListener("click", outsideClickClose);
        };
    }, []);

    return (
        <div className={`relative ${className}`} ref={wrapperRef}>
            {React.cloneElement(element, {
                onClick: (evt) => {
                    evt.stopPropagation();
                    setShow((prev) => !prev);
                },
            })}

            {/* Dropdown */}
            <div
                className={`absolute right-0 top-[108%] z-10 bg-white max-h-[180px] min-w-[240px] w-full overflow-y-auto !border !border-[#E6E8F9] rounded-xl shadow-[-16px_-16px_16px_-4px_#10182808,16px_16px_32px_-4px_#10182814] transition-all duration-200 ${dropClassName} ${
                    show
                        ? "translate-y-0 opacity-100 visible z-20"
                        : "translate-y-[15%] opacity-0 invisible"
                }`}
            >
                {options.map(({ label, value, disabled, action, className, closeAfterClick }) => (
                    <p
                        //bg-[#EBE4FF]
                        className={`font-medium text-sm py-[12px] px-[16px] cursor-pointer flex items-center gap-[16px] text-main rounded-lg transition-colors duration-300 ${
                            disabled && "cursor-not-allowed !text-[#BCC2CC]"
                        } ${className}`}
                        onClick={(evt) => {
                            evt.stopPropagation();
                            if (!disabled) {
                                action();
                                closeAfterClick && setShow(false);
                            }
                        }}
                        key={value}
                    >
                        {isValidElement(label) ? label : <>{label}</>}
                    </p>
                ))}
            </div>
        </div>
    );
};

export default ActionDropdown;
