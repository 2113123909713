import { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import currencys from "../asset/images/GB.svg";
import currencyss from "../asset/images/EU.svg";
import copy from "../asset/images/copy.svg";
//import '../style/rightside.css'
import iconbank1 from "../asset/images/avaterbank.svg";
import iconbank3 from "../asset/images/copy2.svg";
import iconbank2 from "../asset/images/iconinfo.svg";
import NGNcurrency from "../asset/images/Currency.svg";
import { useSelector } from "react-redux";
import US from "../asset/images/US.png";
import SuccessHodler from "./successholder";
import currencyPairCheck from "../utilities/currencyPairCheck";
import axios from "axios";
import { useLocation } from "react-router-dom";
import Loader from "./loader";
import FailHandler from "./failhandler";

function TopUpTransfer(props) {
    const [showsuccess, setshowsuccess] = useState(false);
    const [fail, setfail] = useState(false);

    const selectorbusiness = useSelector((state) => state.business);
    const [alldatawallet, setalldatawallet] = useState([]);
    const [message, setmessage] = useState("");
    const { state } = useLocation();
    const [loader, setloader] = useState(false);
    //  console.log(state);
    const transferData = [
        {
            currency: "EUR",
            bankDetails: {
                "Sort code": "30-99-50",
                "Bank name": "Lloyds Bank",
                "Account number": "85539068",
            },
        },
        {
            currency: "GBP",
            bankDetails: {
                IBAN: "GB88 REVO 0099 6977 6973 19",
                "Bank name": "Revolut",
                BIC: "REVOGB21",
            },
        },
        {
            currency: "USD",
            bankDetails: {
                "Bank name": "Brex",
                "Account number": "0065749302",
            },
        },
        {
            currency: "NGN",
            bankDetails: {
                "Bank name": `${alldatawallet.bankName}`,
                "Account number": `${alldatawallet.accountNumber}`,
            },
        },
    ];
    const filteredTransfers = transferData.filter(
        (transfer) => transfer.currency === localStorage.getItem("default")
    );
    // console.log(filteredTransfers);
    useEffect(() => {
        const getWallet = async (value) => {
            await axios
                .get(`v1/wallets/account?currency=${localStorage.getItem("default")}`)
                .then((res) => {
                    // console.log(res);
                    setalldatawallet(res.data.data);
                    // setbalance(res.data.data.balance);
                })
                .catch((e) => {
                    console.log(e);
                });
        };
        getWallet();
    }, [localStorage.getItem("default")]);
    const submitHandler = async () => {
        setloader(true);
        await axios
            .post(`/v1/wallets/fund_request`, {
                amount: state.amount,
                currency: state.default,
            })
            .then((res) => {
                //console.log(res);
                setshowsuccess(true);
                props.setshowsuccess(true);

                setmessage(res.data.message);
                props.setmessage(res.data.message);

                setTimeout(() => {
                    setshowsuccess(false);

                    setloader(false);
                    props.setshow(false);
                }, 2000);
            })
            .catch((e) => {
                setshowsuccess(true);
                setmessage("Sorry, Something went wrong");
                setfail(true);
                props.setmessage("Sorry, Something went wrong");

                props.setfail(true);

                setloader(false);

                setTimeout(() => {
                    setshowsuccess(false);
                }, 2000);
            });
    };

    const TransferDetails = ({ bankDetails }) => (
        <>
            {Object.entries(bankDetails).map(([key, value]) => (
                <div className="d-flex justify-content-between" key={key}>
                    <span
                        style={{
                            color: "#667085",
                            fontSize: "14px",
                            fontWeight: "400",
                            lineHeight: "19.6px",
                        }}
                    >
                        {key}
                    </span>
                    <span
                        style={{
                            color: "#6F00FF",
                            fontSize: "16px",
                            fontWeight: "600",
                            lineHeight: "24px",
                            letterSpacing: "2%",
                            textTransform: "capitalize",
                            textAlign: "right",
                        }}
                    >
                        {value}
                    </span>
                </div>
            ))}
        </>
    );

    const CheckBoxes = ({ bankDetails }) => (
        <>
            {Object.entries(bankDetails).map(([key, value]) => (
                <div
                    onClick={() => {
                        copyURL1(`${value}`, key);
                    }}
                    style={{ cursor: "pointer" }}
                >
                    <img src={copy} alt="" />
                </div>
            ))}
        </>
    );

    const copyURL1 = async (mytext, key) => {
        try {
            await navigator.clipboard.writeText(mytext);
            setshowsuccess(true);
            setmessage(`${key} Copied successfully`);

            setTimeout(() => {
                setshowsuccess(false);
            }, 2000);
        } catch ($e) {
            console.log($e);
        }
    };
    const copyURL2 = async (mytext) => {
        try {
            await navigator.clipboard.writeText(mytext);
            setshowsuccess(true);
            setmessage(" Bank Name Copied successfully");

            setTimeout(() => {
                setshowsuccess(false);
            }, 2000);
        } catch ($e) {
            console.log($e);
        }
    };
    const copyURL3 = async (mytext) => {
        try {
            await navigator.clipboard.writeText(mytext);
            setshowsuccess(true);
            setmessage(" Account Number Copied successfully");
            setTimeout(() => {
                setshowsuccess(false);
            }, 2000);
        } catch ($e) {
            console.log($e);
        }
    };
    return (
        <>
            <Modal
                show={props.show}
                // onHide={handleClose}
                backdrop="false"
                keyboard={false}
                onHide={() => props.setshow(false)}
                className="rightsidemodal"
                style={{
                    minHeight: "700px",
                }}
            >
                <div
                    className=""
                    style={{
                        height: "100%",
                    }}
                >
                    <div
                        style={{
                            height: "100vh",
                            width: "100%",
                            padding: "20px",
                        }}
                    >
                        <SuccessHodler success={showsuccess} message={message} />
                        <FailHandler success={fail} message={message} />
                        <div className="w-100" style={{ marginTop: "50px" }}>
                            <div
                                className="w-100 "
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: "16px",
                                }}
                            >
                                <div
                                    className="d-flex gap-3 align-items-center"
                                    onClick={() => {
                                        props.setshow(false);
                                    }}
                                >
                                    <span
                                        className="material-icons"
                                        style={{
                                            color: "#667085",
                                            cursor: "pointer",
                                            fontSize: "16px",
                                        }}
                                    >
                                        arrow_back
                                    </span>
                                    <span
                                        style={{
                                            fontSize: "14px",
                                            color: "#667085",
                                            lineHeight: "18.9px",
                                            fontWeight: "500",
                                            textTransform: "uppercase",
                                        }}
                                    >
                                        back to Fund
                                    </span>
                                </div>
                                <h1
                                    className=""
                                    style={{
                                        fontSize: "32px",
                                        lineHeight: "40px",
                                        fontWeight: "600",
                                        color: "#101828",
                                        padding: "0px",
                                        margin: "0px",
                                    }}
                                >
                                    Account details
                                </h1>
                            </div>

                            <div className="w-100  d-flex">
                                <div
                                    className=""
                                    style={{
                                        border: "1px solid #E7E9FB",
                                        marginTop: "20px",
                                        width: "384px",
                                        borderRadius: "8px",
                                        padding: "16px",
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "space-between",
                                        gap: "24px",
                                    }}
                                >
                                    <div className="d-flex justify-content-between">
                                        <span
                                            style={{
                                                color: "#667085",
                                                fontSize: "14px",
                                                fontWeight: "400",
                                                lineHeight: "19.6px",
                                            }}
                                        >
                                            Account name
                                        </span>
                                        <span
                                            style={{
                                                color: "#6F00FF",
                                                fontSize: "16px",
                                                fontWeight: "600",
                                                lineHeight: "24px",
                                                letterSpacing: "2%",
                                                textTransform: "capitalize",
                                                textAlign: "right",
                                                maxWidth: "300px",
                                                whiteSpace: "nowrap",
                                                textOverflow: "ellipsis",
                                                overflow: "hidden",
                                            }}
                                        >
                                            {state.default === "NGN"
                                                ? `Tradevu-${selectorbusiness.name}`
                                                : "Tradevu Technology Limited"}
                                        </span>
                                    </div>
                                    {filteredTransfers.map((transfer, index) => (
                                        <TransferDetails
                                            key={"EUR"}
                                            currency={"EUR"}
                                            bankDetails={transfer.bankDetails}
                                        />
                                    ))}
                                </div>

                                <div
                                    className=""
                                    style={{
                                        marginTop: "20px",
                                        gap: "28px",
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "space-between",
                                        padding: "16px",
                                    }}
                                >
                                    <div
                                        onClick={() => {
                                            copyURL1(
                                                `${
                                                    state.default === "NGN"
                                                        ? `Tradevu-${selectorbusiness.name}`
                                                        : "Tradevu Technology Limited"
                                                }`,
                                                "Account name"
                                            );
                                        }}
                                        style={{ cursor: "pointer" }}
                                    >
                                        <img src={copy} alt="" />
                                    </div>

                                    {filteredTransfers.map((transfer) => (
                                        <CheckBoxes bankDetails={transfer.bankDetails} />
                                    ))}
                                </div>
                            </div>
                            {state.default !== "NGN" && (
                                <div
                                    className="my_account_details"
                                    style={{
                                        alignItems: "start",
                                    }}
                                >
                                    <img src={iconbank2} alt="" />
                                    <div className="text_details_check">
                                        <div className="info_imp">Important Info</div>
                                        <span>
                                            Transfer only{" "}
                                            {currencyPairCheck(localStorage.getItem("default"))}5000
                                            to the account above. Please include the note below in
                                            your payment <br /> description.{" "}
                                        </span>
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                alignItems: "center",
                                            }}
                                        >
                                            <div className="info_impl">{`"FFC: ${alldatawallet?.business?.name} ${alldatawallet.accountNumber}"`}</div>
                                            <img
                                                src={iconbank3}
                                                alt=""
                                                style={{
                                                    cursor: "pointer",
                                                }}
                                                onClick={() => {
                                                    copyURL1(
                                                        `"FFC: ${alldatawallet?.business?.name} ${alldatawallet.accountNumber}"`,
                                                        "Account Details"
                                                    );
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                        {state.default !== "NGN" && (
                            <div
                                style={{
                                    marginTop: "80px",
                                }}
                            >
                                <button
                                    onClick={() => submitHandler()}
                                    style={{
                                        maxWidth: "278px",
                                        width: "100%",
                                        background: "#6F00FF",
                                        color: "#fff",
                                        padding: "16px 24px 16px 24px",
                                        letterSpacing: "2%",
                                        fontSize: "16px",
                                        fontWeight: "500",
                                        lineHeight: "24px",
                                        borderRadius: "8px",
                                        outline: "none",
                                        border: "none",
                                        minHeight: "46px",
                                        display: "flex",
                                        justifyContent: "center",
                                    }}
                                >
                                    {loader === true ? <Loader data={loader} /> : " I have paid"}
                                </button>
                            </div>
                        )}
                    </div>
                </div>
            </Modal>
        </>
    );
}

export default TopUpTransfer;
