import { Helmet } from "react-helmet";
// import DashHeader from "../component/dashheader";
// import homefinance from '../asset/im
import { useEffect, useState } from "react";

import SerHeader from "../component/serviceheader";
import arrowleft from "../asset/images/arrowleftcircle.svg";
import edit from "../asset/images/edit-2.svg";
import person from "../asset/images/person.svg";
import copy from "../asset/images/document-upload.png";
import UploadInvoice from "../component/uploadinvoice";
import nigeria from "../asset/images/circular_nig.png";
import { currencies } from "../constants/currency";
import UploadModal from "../component/uploadModal";
import Uploaddocumentfinance from "../component/uploadloaddocument";
import InvoiceSuccess from "../component/invoicesuccess";
import { useLocation, useNavigate, useParams } from "react-router-dom";
//import Swal from "sweetalert2/dist/sweetalert2.js";
import SuccessHodler from "../component/successholder";
import FailHandler from "../component/failhandler";

import axios from "axios";
import Loaders from "../component/loader";
import TenureModal from "../component/tenure";
import ContractType from "../component/Contracttype";
import RepaymentStructure from "../component/Repayment";
import CurrencyModal from "../component/currencyModal";

const CreditLine = () => {
    // const [selectedIndex, setselectedIndex] = useState(-1)
    const navigate = useNavigate();
    const { state } = useLocation();

    const [show, setShow] = useState(false);
    const [loader, setloader] = useState(false);
    const [selecteditem, setselecteditem] = useState([]);
    const [selectedcurrency, setselectedcurrency] = useState([]);
    const [added, setadded] = useState(false);
    const [filename, setfilename] = useState(null);
    const [filesize, setfilesize] = useState(0);
    const [aggreement, setagrrement] = useState(null);
    const [aggreementsize, setaggreementsize] = useState(0);
    const [showModal, setshowModal] = useState(false);
    const [showModal2, setshowModal2] = useState(false);
    const [showModal3, setshowModal3] = useState(false);
    const [defaultcurrency] = useState("NGN");
    const [businessname, setbusinessname] = useState("");
    const [buyer, setbuyers] = useState([]);
    const [supplier, setsuppliers] = useState([]);
    const [isdisable, setisdisable] = useState(true);
    const [amount, setamount] = useState("");
    const [tab, settab] = useState("buyer");
    const [success, setsuccess] = useState(false);
    const [failhandler, setfailhandler] = useState(false);
    const [message, setmessage] = useState(" ");

    const [showdrop, setisshowdrop] = useState(false);
    const [showdrop2, setisshowdrop2] = useState(false);
    const [typeshow, setshowtype] = useState(false);
    const [repayshow, setrepayshow] = useState(false);
    const [loan, setloan] = useState(0);

    const [tenureshow, settenureshow] = useState(false);
    const [tenureday, settenureday] = useState("30");
    const [type, settype] = useState("Short Term");
    const [selectedstructure, setstructure] = useState("Bullet");
    const { id } = useParams();
    const [title, settitle] = useState("");
    const [turnover, setturnover] = useState("");
    const [revenue, setrevenue] = useState("");

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [showcurrency, setshowcurrency] = useState(false);

    const addseletedcurrency = (currency) => {
        setadded(true);
        setselectedcurrency(currency);
    };
    const tenures = ["30", "60", "90"];
    const contract = ["Short Term", "Long Term"];
    const structure = ["Bullet", "Interest First", "Full Repayment"];
    useEffect(() => {
        const fetchId = async () => {};
        fetchId();
    }, []);
    /*
  useEffect(() => {
    const fetchbuyer = async () => {
      await axios
        .get("v1/flex/buyers")

        .then((res) => {
          setbuyers(res.data.data);
        })
        .catch((e) => {
          console.log(e);
        });
    };
    fetchbuyer();
  }, []);

  useEffect(() => {
    const fetchsupplier = async () => {
      await axios
        .get("v1/flex/suppliers")

        .then((res) => {
          setsuppliers(res.data.data);
        })
        .catch((e) => {
          console.log(e);
        });
    };
    fetchsupplier();
  }, []);
  */
    useEffect(() => {
        let timer = setTimeout(() => {
            setfailhandler(false);
            setsuccess(false);
        }, 3000);
        return () => {
            clearTimeout(timer);
        };
    });
    useEffect(() => {
        if (selectedcurrency && loan && selectedstructure && title && revenue && turnover) {
            setisdisable(false);
        } else {
            setisdisable(true);
        }
    }, [
        setisdisable,

        selectedcurrency,
        selecteditem,
        selectedstructure,
        turnover,
        revenue,
        title,
        loan,
    ]);
    const submitHandler = async (e) => {
        e.preventDefault();
        setloader(true);
        // console.log(selecteditem);

        const data = {
            // firstName: selecteditem[0]?.firstName,
            // lastName: selecteditem[0]?.lastName,
            // email: selecteditem[0]?.email,
            // contractCost: amount,
            repaymentStructure: selectedstructure,
            // phoneNumber: selecteditem[0].phoneNumber,
            //userType:id.toUpperCase(),
            requestedAmount: loan,
            tenure: tenureday,
            requestTitle: title,
            repaymentStructure:
                selectedstructure === "Full Repayment"
                    ? "FullPayment"
                    : selectedstructure === "Interest First"
                    ? "InterestFirst"
                    : "Bullet",
            annualRevenue: revenue,
            annualTurnOver: turnover,

            currency: selectedcurrency.length === 0 ? defaultcurrency : selectedcurrency.name,
        };
        await axios
            .post(`/v1/finance/credit_line/create`, data)
            .then((res) => {
                console.log(res);
                setloader(false);
                setsuccess(true);
                setmessage(res.data.message);

                navigate("/letter_of_credit/document", {
                    state: {
                        ...res.data.data,
                    },
                });
            })
            .catch((e) => {
                console.log(e);
                setfailhandler(true);
                setmessage(e.response?.data ? e.response?.data.message : "An error occur");

                setloader(false);
            });
    };

    return (
        <div
            className="h-100 w-100 "
            style={{
                background: "#f6f6f6",
            }}
        >
            <div
                className=""
                style={{
                    maxWidth: "2000px",
                    width: "100%",
                    margin: "0px auto",
                }}
            >
                <Helmet>
                    <title>Credit Line</title>
                </Helmet>

                <SerHeader header={"Credit Line"} />
                <InvoiceSuccess
                    set={"finance"}
                    show={showModal3}
                    setshow={setshowModal3}
                    amount={amount}
                    currency={selectedcurrency.length === 0 ? "NGN" : selectedcurrency.name}
                />
                <TenureModal
                    show={tenureshow}
                    setshow={settenureshow}
                    tenure={tenures}
                    settenureday={settenureday}
                />
                <ContractType
                    tenure={contract}
                    setshow={setshowtype}
                    show={typeshow}
                    settenureday={settype}
                />
                <RepaymentStructure
                    tenure={structure}
                    setshow={setrepayshow}
                    show={repayshow}
                    settenureday={setstructure}
                />
                <Uploaddocumentfinance
                    show={showModal2}
                    setshow={setshowModal2}
                    filename={aggreement}
                    filesize={aggreementsize}
                    setfilesize={setaggreementsize}
                    setfilename={setagrrement}
                />
                <UploadModal
                    filesize={filesize}
                    show={showModal}
                    setshow={setshowModal}
                    filename={filename}
                    setfilesize={setfilesize}
                    setfilename={setfilename}
                />
                <UploadInvoice
                    show={show}
                    handle={handleClose}
                    selecteditem={selecteditem}
                    setbusinessname={setbusinessname}
                    setselecteditem={setselecteditem}
                />
                <CurrencyModal
                    addseletedcurrency={addseletedcurrency}
                    show={showcurrency}
                    setshow={setshowcurrency}
                    setcurrency={setselectedcurrency}
                />
            </div>
            <div style={{ height: "100%" }}>
                <form
                    className="conatiner "
                    onSubmit={submitHandler}
                    style={{
                        maxWidth: "2000px",
                        margin: "0px auto",
                        paddingLeft: "30px",
                        paddingRight: "30px",
                        paddingTop: "10px",
                        paddingBottom: "10px",
                        width: "100%",
                    }}
                >
                    <div
                        style={{
                            height: "100%",
                            width: "100%",
                            display: "flex",

                            justifyContent: "center",
                        }}
                    >
                        <div
                            className="form-general-layout"
                            style={{
                                position: "relative",
                            }}
                        >
                            <SuccessHodler success={success} message={message} />
                            <FailHandler success={failhandler} message={message} />
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: "32px",
                                }}
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        gap: "16px",
                                    }}
                                >
                                    <div
                                        onClick={() => {
                                            navigate(-1);
                                        }}
                                    >
                                        <img src={arrowleft} alt="" />
                                    </div>
                                    <div
                                        style={{
                                            color: "#101828",
                                            fontWeight: "600",
                                            fontSize: "24px",
                                            lineHeight: "32.4px",
                                        }}
                                    >
                                        Enter Request Details
                                    </div>
                                </div>
                                <div
                                    className=""
                                    style={{
                                        display: "flex",
                                        gap: "24px",
                                        flexDirection: "column",
                                    }}
                                >
                                    {tab === "buyer" && (
                                        <>
                                            {/**
                    <div
                        className=""
                        style={{
                          display: "flex",
                          gap: "8px",
                          flexDirection: "column",
                        }}
                      >
                        <label
                          htmlFor=""
                          style={{
                            color: "#344054",
                            fontWeight: "400",
                            lineHeight: "19.6px",
                            fontSize: "14px",
                          }}
                        >
                          Contract employer
                        </label>
                        {selecteditem.length === 0 && (
                          <div
                            style={{
                              background: "#fff",
                              display: "flex",
                              justifyContent: "space-between",
                              padding: "8px",
                              borderRadius: "8px",
                              alignItems: "center",
                              height: "56px",
                            }}
                            variant="primary"
                            onClick={handleShow}
                          >
                            <div
                              style={{
                                display: "flex",
                                gap: "8px",
                                alignItems: "center",
                              }}
                            >
                              <img src={person} alt="" />
                              <span
                                style={{
                                  color: "#101828",
                                  letterSpacing: "2%",
                                  lineHeight: "24px",
                                  fontSize: "16px",
                                  fontWeight: 400,
                                }}
                              >
                                Enter name of contract employer
                              </span>
                            </div>
                            <img src={edit} alt="" />
                          </div>
                        )}
                        {selecteditem.length > 0 &&
                          selecteditem.map((items, index) => (
                            <div
                              style={{
                                background: "#fff",
                                display: "flex",
                                justifyContent: "space-between",
                                padding: "8px",
                                borderRadius: "8px",
                                alignItems: "center",
                                minHeight: "56px",
                              }}
                              key={index}
                              variant="primary"
                              onClick={handleShow}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  gap: "8px",
                                }}
                              >
                                <div
                                  style={{
                                    width: "40px",
                                    height: "40px",
                                    borderRadius: "250px",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    background: "#F4F0FF",
                                    textAlign: "center",
                                    display: "flex",
                                  }}
                                >
                                  <div
                                    style={{
                                      color: "#7F56D9",
                                      fontWeight: "400",
                                      fontSize: "14px",
                                      lineHeight: "20px",
                                      textAlign: "center",
                                      textTransform: "uppercase",
                                      width: "100%",
                                    }}
                                  >
                                    {" "}
                                    {items?.firstName.charAt(0)}
                                    {items?.lastName.charAt(0)}{" "}
                                  </div>
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                  }}
                                >
                                  <span
                                    style={{
                                      color: "#101828",
                                      letterSpacing: "2%",
                                      lineHeight: "18px",
                                      fontSize: "16px",
                                      fontWeight: 400,
                                    }}
                                  >
                                    {" "}
                                    {items?.firstName} {items?.lastName}{" "}
                                  </span>
                                  <span
                                    style={{
                                      color: "#667085",
                                      fontSize: "14px",
                                      fontWeight: "400",
                                      lineHeight: "19.6px",
                                    }}
                                  >
                                    {" "}
                                    {items?.email}{" "}
                                  </span>
                                </div>
                              </div>
                              <img src={edit} alt="" />
                            </div>
                          ))}{" "}
                      </div>
                      */}
                                            <div
                                                className=""
                                                style={{
                                                    display: "flex",
                                                    gap: "8px",
                                                    flexDirection: "column",
                                                }}
                                            >
                                                <label
                                                    htmlFor=""
                                                    style={{
                                                        color: "#344054",
                                                        fontWeight: "600",
                                                        lineHeight: "19.6px",
                                                        fontSize: "14px",
                                                    }}
                                                >
                                                    Loan Amount
                                                </label>
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        gap: "8px",
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            width: "100%",
                                                            maxWidth: "96px",
                                                            borderRadius: "8px",
                                                            border: "none",
                                                            outline: "none",
                                                            background: "",
                                                            padding: "8px 16px 8px 16px",
                                                            backgroundColor: "#ECEFF3",
                                                            letterSpacing: "2%",
                                                            fontWeight: "400",
                                                            fontSize: "16px",
                                                            display: "flex",
                                                            gap: "4px",
                                                            alignItems: "center",
                                                            position: "relative",
                                                        }}
                                                        onClick={() => {
                                                            //  setisshowdrop2(!showdrop2);
                                                            setshowcurrency(true);
                                                        }}
                                                    >
                                                        {" "}
                                                        {showdrop2 === true && (
                                                            <div
                                                                style={{
                                                                    position: "absolute",
                                                                    background: "#fff",
                                                                    padding: "8px",
                                                                    width: "100%",
                                                                    top: "40px",
                                                                    left: "-2px",
                                                                    borderRadius: "4px",
                                                                    zIndex: "2",
                                                                }}
                                                            >
                                                                <div
                                                                    style={{
                                                                        display: "flex",
                                                                        flexDirection: "column",
                                                                        gap: "16px",
                                                                    }}
                                                                >
                                                                    {" "}
                                                                    {currencies.map(
                                                                        (currency, index) => (
                                                                            <div
                                                                                style={{
                                                                                    display: "flex",
                                                                                    gap: "8px",
                                                                                    lineHeight:
                                                                                        "19.6px",
                                                                                    cursor: "pointer",
                                                                                }}
                                                                                key={index}
                                                                                onClick={() => {
                                                                                    addseletedcurrency(
                                                                                        currency
                                                                                    );
                                                                                }}
                                                                            >
                                                                                <img
                                                                                    src={
                                                                                        currency.image
                                                                                    }
                                                                                    alt=""
                                                                                    height={24}
                                                                                    width={24}
                                                                                />
                                                                                <span
                                                                                    style={{
                                                                                        color: "#667085",
                                                                                        letterSpacing:
                                                                                            "2%",
                                                                                        lineHeight:
                                                                                            "24px",
                                                                                        fontSize:
                                                                                            "16px",
                                                                                    }}
                                                                                >
                                                                                    {" "}
                                                                                    {currency.name}
                                                                                </span>
                                                                            </div>
                                                                        )
                                                                    )}{" "}
                                                                </div>
                                                            </div>
                                                        )}
                                                        <img
                                                            src={
                                                                added
                                                                    ? selectedcurrency.img
                                                                    : nigeria
                                                            }
                                                            alt=""
                                                            height={24}
                                                            width={24}
                                                        />
                                                        <span
                                                            style={{
                                                                color: "#667085",
                                                                letterSpacing: "2%",
                                                                lineHeight: "24px",
                                                                fontSize: "16px",
                                                            }}
                                                        >
                                                            {" "}
                                                            {added === true
                                                                ? selectedcurrency.name
                                                                : "NGN"}
                                                        </span>
                                                    </div>
                                                    <input
                                                        type="number"
                                                        name=""
                                                        id=""
                                                        step={0.5}
                                                        onChange={(e) => {
                                                            setloan(e.target.value);
                                                        }}
                                                        style={{
                                                            width: "100%",
                                                            borderRadius: "8px",
                                                            border: "none",
                                                            outline: "none",
                                                            height: "56px",
                                                            background: "",
                                                            padding: "8px 16px 8px 16px",
                                                            backgroundColor: "#ECEFF3",
                                                            letterSpacing: "2%",
                                                            fontWeight: "400",
                                                            fontSize: "16px",
                                                        }}
                                                        placeholder="Enter amount"
                                                    />
                                                </div>
                                            </div>
                                            <div
                                                className=""
                                                style={{
                                                    display: "flex",
                                                    gap: "8px",
                                                    flexDirection: "column",
                                                }}
                                            >
                                                <label
                                                    htmlFor=""
                                                    style={{
                                                        color: "#344054",
                                                        fontWeight: "600",
                                                        lineHeight: "19.6px",
                                                        fontSize: "14px",
                                                    }}
                                                >
                                                    Request title
                                                </label>
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        gap: "8px",
                                                    }}
                                                >
                                                    <input
                                                        style={{
                                                            width: "100%",
                                                            borderRadius: "8px",
                                                            border: "none",
                                                            outline: "none",
                                                            height: "56px",
                                                            background: "",
                                                            padding: "8px 16px 8px 16px",
                                                            backgroundColor: "#ECEFF3",
                                                            letterSpacing: "2%",
                                                            fontWeight: "400",
                                                            fontSize: "16px",
                                                        }}
                                                        onChange={(e) => {
                                                            settitle(e.target.value);
                                                        }}
                                                        placeholder="E.g. New export business"
                                                    />
                                                </div>
                                            </div>

                                            <div
                                                className=""
                                                style={{
                                                    display: "flex",
                                                    gap: "8px",
                                                    flexDirection: "column",
                                                }}
                                                onClick={() => {
                                                    settenureshow(true);
                                                }}
                                            >
                                                <label
                                                    htmlFor=""
                                                    style={{
                                                        color: "#344054",
                                                        fontWeight: "600",
                                                        lineHeight: "19.6px",
                                                        fontSize: "14px",
                                                    }}
                                                >
                                                    Tenure
                                                </label>
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        gap: "8px",
                                                    }}
                                                >
                                                    <input
                                                        readOnly
                                                        style={{
                                                            width: "100%",
                                                            borderRadius: "8px",
                                                            border: "none",
                                                            outline: "none",
                                                            height: "56px",
                                                            background: "",
                                                            padding: "8px 16px 8px 16px",
                                                            backgroundColor: "#ECEFF3",
                                                            letterSpacing: "2%",
                                                            fontWeight: "400",
                                                            fontSize: "16px",
                                                        }}
                                                        value={tenureday + " " + "Days"}
                                                        placeholder="Choose tenure"
                                                    />
                                                </div>
                                            </div>

                                            <div
                                                className=""
                                                style={{
                                                    display: "flex",
                                                    gap: "8px",
                                                    flexDirection: "column",
                                                }}
                                            >
                                                <label
                                                    htmlFor=""
                                                    style={{
                                                        color: "#344054",
                                                        fontWeight: "600",
                                                        lineHeight: "19.6px",
                                                        fontSize: "14px",
                                                    }}
                                                >
                                                    Repayment Structure
                                                </label>
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        gap: "8px",
                                                    }}
                                                >
                                                    <input
                                                        readOnly
                                                        onClick={() => {
                                                            setrepayshow(true);
                                                        }}
                                                        style={{
                                                            width: "100%",
                                                            borderRadius: "8px",
                                                            border: "none",
                                                            outline: "none",
                                                            height: "56px",
                                                            background: "",
                                                            padding: "8px 16px 8px 16px",
                                                            backgroundColor: "#ECEFF3",
                                                            letterSpacing: "2%",
                                                            fontWeight: "400",
                                                            fontSize: "16px",
                                                        }}
                                                        value={selectedstructure}
                                                        placeholder="Choose contract type"
                                                    />
                                                </div>
                                            </div>

                                            <div
                                                className=""
                                                style={{
                                                    display: "flex",
                                                    gap: "8px",
                                                    flexDirection: "column",
                                                }}
                                            >
                                                <label
                                                    htmlFor=""
                                                    style={{
                                                        color: "#344054",
                                                        fontWeight: "600",
                                                        lineHeight: "19.6px",
                                                        fontSize: "14px",
                                                    }}
                                                >
                                                    What’s your annual sales revenue?
                                                </label>
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        gap: "8px",
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            width: "100%",
                                                            maxWidth: "96px",
                                                            borderRadius: "8px",
                                                            border: "none",
                                                            outline: "none",
                                                            background: "",
                                                            padding: "8px 16px 8px 16px",
                                                            backgroundColor: "#ECEFF3",
                                                            letterSpacing: "2%",
                                                            fontWeight: "400",
                                                            fontSize: "16px",
                                                            display: "flex",
                                                            gap: "4px",
                                                            alignItems: "center",
                                                            position: "relative",
                                                        }}
                                                        onClick={() => {
                                                            //  setisshowdrop2(!showdrop2);
                                                            setshowcurrency(true);
                                                        }}
                                                    >
                                                        {" "}
                                                        {showdrop2 === true && (
                                                            <div
                                                                style={{
                                                                    position: "absolute",
                                                                    background: "#fff",
                                                                    padding: "8px",
                                                                    width: "100%",
                                                                    top: "40px",
                                                                    left: "-2px",
                                                                    borderRadius: "4px",
                                                                    zIndex: "2",
                                                                }}
                                                            >
                                                                <div
                                                                    style={{
                                                                        display: "flex",
                                                                        flexDirection: "column",
                                                                        gap: "16px",
                                                                    }}
                                                                >
                                                                    {" "}
                                                                    {currencies.map(
                                                                        (currency, index) => (
                                                                            <div
                                                                                style={{
                                                                                    display: "flex",
                                                                                    gap: "8px",
                                                                                    lineHeight:
                                                                                        "19.6px",
                                                                                    cursor: "pointer",
                                                                                }}
                                                                                key={index}
                                                                                onClick={() => {
                                                                                    addseletedcurrency(
                                                                                        currency
                                                                                    );
                                                                                }}
                                                                            >
                                                                                <img
                                                                                    src={
                                                                                        currency.image
                                                                                    }
                                                                                    alt=""
                                                                                    height={24}
                                                                                    width={24}
                                                                                />
                                                                                <span
                                                                                    style={{
                                                                                        color: "#667085",
                                                                                        letterSpacing:
                                                                                            "2%",
                                                                                        lineHeight:
                                                                                            "24px",
                                                                                        fontSize:
                                                                                            "16px",
                                                                                    }}
                                                                                >
                                                                                    {" "}
                                                                                    {currency.name}
                                                                                </span>
                                                                            </div>
                                                                        )
                                                                    )}{" "}
                                                                </div>
                                                            </div>
                                                        )}
                                                        <img
                                                            src={
                                                                added
                                                                    ? selectedcurrency.img
                                                                    : nigeria
                                                            }
                                                            alt=""
                                                            height={24}
                                                            width={24}
                                                        />
                                                        <span
                                                            style={{
                                                                color: "#667085",
                                                                letterSpacing: "2%",
                                                                lineHeight: "24px",
                                                                fontSize: "16px",
                                                            }}
                                                        >
                                                            {" "}
                                                            {added === true
                                                                ? selectedcurrency.name
                                                                : "NGN"}
                                                        </span>
                                                    </div>
                                                    <input
                                                        type="number"
                                                        name=""
                                                        step={0.5}
                                                        id=""
                                                        onChange={(e) => {
                                                            setrevenue(e.target.value);
                                                        }}
                                                        style={{
                                                            width: "100%",
                                                            borderRadius: "8px",
                                                            border: "none",
                                                            outline: "none",
                                                            height: "56px",
                                                            background: "",
                                                            padding: "8px 16px 8px 16px",
                                                            backgroundColor: "#ECEFF3",
                                                            letterSpacing: "2%",
                                                            fontWeight: "400",
                                                            fontSize: "16px",
                                                        }}
                                                        placeholder="Enter amount"
                                                    />
                                                </div>
                                            </div>

                                            <div
                                                className=""
                                                style={{
                                                    display: "flex",
                                                    gap: "8px",
                                                    flexDirection: "column",
                                                }}
                                            >
                                                <label
                                                    htmlFor=""
                                                    style={{
                                                        color: "#344054",
                                                        fontWeight: "600",
                                                        lineHeight: "19.6px",
                                                        fontSize: "14px",
                                                    }}
                                                >
                                                    What’s your annual turnover?
                                                </label>
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        gap: "8px",
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            width: "100%",
                                                            maxWidth: "96px",
                                                            borderRadius: "8px",
                                                            border: "none",
                                                            outline: "none",
                                                            background: "",
                                                            padding: "8px 16px 8px 16px",
                                                            backgroundColor: "#ECEFF3",
                                                            letterSpacing: "2%",
                                                            fontWeight: "400",
                                                            fontSize: "16px",
                                                            display: "flex",
                                                            gap: "4px",
                                                            alignItems: "center",
                                                            position: "relative",
                                                        }}
                                                        onClick={() => {
                                                            // setisshowdrop(!showdrop);
                                                            setshowcurrency(true);
                                                        }}
                                                    >
                                                        {" "}
                                                        {showdrop === true && (
                                                            <div
                                                                style={{
                                                                    position: "absolute",
                                                                    background: "#fff",
                                                                    padding: "8px",
                                                                    width: "100%",
                                                                    top: "40px",
                                                                    left: "-2px",
                                                                    borderRadius: "4px",
                                                                    zIndex: "2",
                                                                }}
                                                            >
                                                                <div
                                                                    style={{
                                                                        display: "flex",
                                                                        flexDirection: "column",
                                                                        gap: "16px",
                                                                    }}
                                                                >
                                                                    {" "}
                                                                    {currencies.map(
                                                                        (currency, index) => (
                                                                            <div
                                                                                style={{
                                                                                    display: "flex",
                                                                                    gap: "8px",
                                                                                    lineHeight:
                                                                                        "19.6px",
                                                                                    cursor: "pointer",
                                                                                }}
                                                                                key={index}
                                                                                onClick={() => {
                                                                                    addseletedcurrency(
                                                                                        currency
                                                                                    );
                                                                                }}
                                                                            >
                                                                                <img
                                                                                    src={
                                                                                        currency.image
                                                                                    }
                                                                                    alt=""
                                                                                    height={24}
                                                                                    width={24}
                                                                                />
                                                                                <span
                                                                                    style={{
                                                                                        color: "#667085",
                                                                                        letterSpacing:
                                                                                            "2%",
                                                                                        lineHeight:
                                                                                            "24px",
                                                                                        fontSize:
                                                                                            "16px",
                                                                                    }}
                                                                                >
                                                                                    {" "}
                                                                                    {currency.name}
                                                                                </span>
                                                                            </div>
                                                                        )
                                                                    )}{" "}
                                                                </div>
                                                            </div>
                                                        )}
                                                        <img
                                                            src={
                                                                added
                                                                    ? selectedcurrency.img
                                                                    : nigeria
                                                            }
                                                            alt=""
                                                            height={24}
                                                            width={24}
                                                        />
                                                        <span
                                                            style={{
                                                                color: "#667085",
                                                                letterSpacing: "2%",
                                                                lineHeight: "24px",
                                                                fontSize: "16px",
                                                            }}
                                                        >
                                                            {" "}
                                                            {added === true
                                                                ? selectedcurrency.name
                                                                : "NGN"}
                                                        </span>
                                                    </div>
                                                    <input
                                                        type="number"
                                                        name=""
                                                        id=""
                                                        step={0.5}
                                                        value={turnover}
                                                        onChange={(e) => {
                                                            setturnover(e.target.value);
                                                        }}
                                                        style={{
                                                            width: "100%",
                                                            borderRadius: "8px",
                                                            border: "none",
                                                            outline: "none",
                                                            height: "56px",
                                                            background: "",
                                                            padding: "8px 16px 8px 16px",
                                                            backgroundColor: "#ECEFF3",
                                                            letterSpacing: "2%",
                                                            fontWeight: "400",
                                                            fontSize: "16px",
                                                        }}
                                                        placeholder="Enter amount"
                                                    />
                                                </div>
                                            </div>

                                            {/**
                      <div
                        className=""
                        style={{
                          display: "flex",
                          gap: "8px",
                          flexDirection: "column",
                        }}
                      >
                        <label
                          htmlFor=""
                          style={{
                            color: "#344054",
                            fontWeight: "400",
                            lineHeight: "19.6px",
                            fontSize: "14px",
                          }}
                        >
                          Upload invoice
                        </label>
                        <div style={{ position: "relative" }}>
                          <div
                            style={{
                              width: "100%",
                              borderRadius: "8px",
                              border: "none",
                              outline: "none",
                              background: "",
                              minHeight: "56px",
                              padding: "8px 16px 8px 16px",
                              backgroundColor: "#ECEFF3",
                              letterSpacing: "2%",
                              fontWeight: "400",
                              fontSize: "16px",
                              position: "relative",
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                            variant="primary"
                            onClick={() => {
                              setfilename(null);
                              setfilesize(0);
                              setshowModal(true);
                            }}
                          >
                            <span
                              style={{
                                color: "#667085",
                                fontSize: "16px",
                                lineHeight: "24px",
                                letterSpacing: "2%",
                              }}
                            >
                              {" "}
                              {filename !== null && filename}{" "}
                              {filename !== null &&
                                parseFloat(filesize / 1024).toFixed(2)}
                              {filename !== null && (
                                <>
                                  {filesize !== 0 && filesize >= 1024
                                    ? "MB"
                                    : "KB"}
                                </>
                              )}
                              {filename === null && "Tap to upload invoice"}{" "}
                            </span>
                            <img src={copy} alt="" height={20} width={20} />
                          </div>
                        </div>
                      </div>

                      <div
                        className=""
                        style={{
                          display: "flex",
                          gap: "8px",
                          flexDirection: "column",
                        }}
                      >
                        <label
                          htmlFor=""
                          style={{
                            color: "#344054",
                            fontWeight: "400",
                            lineHeight: "19.6px",
                            fontSize: "14px",
                          }}
                        >
                          Upload customer agreement
                        </label>
                        <div style={{ position: "relative" }}>
                          <div
                            style={{
                              width: "100%",
                              borderRadius: "8px",
                              border: "none",
                              outline: "none",
                              background: "",
                              minHeight: "56px",
                              padding: "8px 16px 8px 16px",
                              backgroundColor: "#ECEFF3",
                              letterSpacing: "2%",
                              fontWeight: "400",
                              fontSize: "16px",
                              position: "relative",
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                            variant="primary"
                            onClick={() => {
                              setaggreementsize(0);
                              setagrrement(null);

                              setshowModal2(true);
                            }}
                          >
                            <span
                              style={{
                                color: "#667085",
                                fontSize: "16px",
                                lineHeight: "24px",
                                letterSpacing: "2%",
                              }}
                            >
                              {" "}
                              {aggreement !== null && aggreement}
                              {aggreement !== null &&
                                parseFloat(aggreementsize / 1024).toFixed(2)}
                              {aggreement !== null && (
                                <>
                                  {aggreementsize !== 0 &&
                                  aggreementsize >= 1024
                                    ? "MB"
                                    : "KB"}
                                </>
                              )}
                              {aggreement === null && "Upload document"}{" "}
                            </span>
                            <img src={copy} alt="" height={20} width={20} />
                          </div>
                        </div>
                      </div>
                      */}
                                            <div className="mb-5">
                                                <button
                                                    disabled={isdisable}
                                                    type="submit"
                                                    style={{
                                                        width: "280px",
                                                        background: isdisable
                                                            ? "#EBE4FF"
                                                            : "#6F00FF",
                                                        height: "48px",
                                                        padding: "16px 24px 16px 24px",

                                                        border: "none",
                                                        outline: "none",
                                                        borderRadius: "8px",
                                                        fontSize: "16px",
                                                        lineHeight: "24px",
                                                        letterSpacing: "2%",
                                                        fontWeight: "400",
                                                        color: isdisable ? "#BFA6FF" : "#ffff",
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    {loader === true ? (
                                                        <Loaders data={loader} />
                                                    ) : (
                                                        "Submit"
                                                    )}
                                                </button>
                                            </div>
                                        </>
                                    )}
                                    {tab === "supplier" && (
                                        <>
                                            <>
                                                <div
                                                    className=""
                                                    style={{
                                                        display: "flex",
                                                        gap: "8px",
                                                        flexDirection: "column",
                                                    }}
                                                >
                                                    <label
                                                        htmlFor=""
                                                        style={{
                                                            color: "#344054",
                                                            fontWeight: "600",
                                                            lineHeight: "19.6px",
                                                            fontSize: "14px",
                                                        }}
                                                    >
                                                        Supplier
                                                    </label>
                                                    {selecteditem.length === 0 && (
                                                        <div
                                                            style={{
                                                                background: "#fff",
                                                                display: "flex",
                                                                justifyContent: "space-between",
                                                                padding: "8px",
                                                                borderRadius: "8px",
                                                                alignItems: "center",
                                                                minHeight: "56px",
                                                            }}
                                                            variant="primary"
                                                            onClick={handleShow}
                                                        >
                                                            <div
                                                                style={{
                                                                    display: "flex",
                                                                    gap: "8px",
                                                                    alignItems: "center",
                                                                }}
                                                            >
                                                                <img src={person} alt="" />
                                                                <span
                                                                    style={{
                                                                        color: "#101828",
                                                                        letterSpacing: "2%",
                                                                        lineHeight: "24px",
                                                                        fontSize: "16px",
                                                                        fontWeight: 400,
                                                                    }}
                                                                >
                                                                    Enter supplier's details
                                                                </span>
                                                            </div>
                                                            <img src={edit} alt="" />
                                                        </div>
                                                    )}
                                                    {selecteditem.length > 0 &&
                                                        selecteditem.map((items, index) => (
                                                            <div
                                                                style={{
                                                                    background: "#fff",
                                                                    display: "flex",
                                                                    justifyContent: "space-between",
                                                                    padding: "8px",
                                                                    borderRadius: "8px",
                                                                    alignItems: "center",
                                                                }}
                                                                key={index}
                                                                variant="primary"
                                                                onClick={handleShow}
                                                            >
                                                                <div
                                                                    style={{
                                                                        display: "flex",
                                                                        gap: "8px",
                                                                    }}
                                                                >
                                                                    <div
                                                                        style={{
                                                                            width: "40px",
                                                                            height: "40px",
                                                                            borderRadius: "250px",
                                                                            alignItems: "center",
                                                                            justifyContent:
                                                                                "ceneter",
                                                                            background: "#F4F0FF",
                                                                            textAlign: "center",
                                                                            display: "flex",
                                                                        }}
                                                                    >
                                                                        <div
                                                                            style={{
                                                                                color: "#7F56D9",
                                                                                fontWeight: "400",
                                                                                fontSize: "14px",
                                                                                lineHeight: "20px",
                                                                                textAlign: "center",
                                                                                textTransform:
                                                                                    "uppercase",
                                                                                width: "100%",
                                                                            }}
                                                                        >
                                                                            {" "}
                                                                            {items?.firstName.charAt(
                                                                                0
                                                                            )}
                                                                            {items?.lastName.charAt(
                                                                                0
                                                                            )}{" "}
                                                                        </div>
                                                                    </div>
                                                                    <div
                                                                        style={{
                                                                            display: "flex",
                                                                            flexDirection: "column",
                                                                        }}
                                                                    >
                                                                        <span
                                                                            style={{
                                                                                color: "#101828",
                                                                                letterSpacing: "2%",
                                                                                lineHeight: "18px",
                                                                                fontSize: "16px",
                                                                                fontWeight: 400,
                                                                            }}
                                                                        >
                                                                            {" "}
                                                                            {items?.firstName}
                                                                            {items?.lastName}{" "}
                                                                        </span>
                                                                        <span
                                                                            style={{
                                                                                color: "#667085",
                                                                                fontSize: "14px",
                                                                                fontWeight: "400",
                                                                                lineHeight:
                                                                                    "19.6px",
                                                                            }}
                                                                        >
                                                                            {" "}
                                                                            {items?.email}{" "}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <img src={edit} alt="" />
                                                            </div>
                                                        ))}{" "}
                                                </div>
                                                <div
                                                    className=""
                                                    style={{
                                                        display: "flex",
                                                        gap: "8px",
                                                        flexDirection: "column",
                                                    }}
                                                >
                                                    <label
                                                        htmlFor=""
                                                        style={{
                                                            color: "#344054",
                                                            fontWeight: "600",
                                                            lineHeight: "19.6px",
                                                            fontSize: "14px",
                                                        }}
                                                    >
                                                        Requested amount
                                                    </label>
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            gap: "8px",
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                maxWidth: "96px",
                                                                width: "100%",
                                                                borderRadius: "8px",
                                                                border: "none",
                                                                outline: "none",
                                                                background: "",
                                                                padding: "8px 16px 8px 16px",
                                                                backgroundColor: "#ECEFF3",
                                                                letterSpacing: "2%",
                                                                fontWeight: "400",
                                                                fontSize: "16px",
                                                                display: "flex",
                                                                gap: "4px",
                                                                alignItems: "center",
                                                                position: "relative",
                                                            }}
                                                            onClick={() => {
                                                                setshowcurrency(true);
                                                                // setisshowdrop(!showdrop);
                                                            }}
                                                        >
                                                            {" "}
                                                            {showdrop === true && (
                                                                <div
                                                                    style={{
                                                                        position: "absolute",
                                                                        background: "#fff",
                                                                        padding: "8px",
                                                                        width: "100%",
                                                                        top: "40px",
                                                                        left: "-2px",
                                                                        borderRadius: "4px",
                                                                        zIndex: "2",
                                                                    }}
                                                                >
                                                                    <div
                                                                        style={{
                                                                            display: "flex",
                                                                            flexDirection: "column",
                                                                            gap: "16px",
                                                                        }}
                                                                    >
                                                                        {" "}
                                                                        {currencies.map(
                                                                            (currency, index) => (
                                                                                <div
                                                                                    style={{
                                                                                        display:
                                                                                            "flex",
                                                                                        gap: "8px",
                                                                                        lineHeight:
                                                                                            "19.6px",
                                                                                        cursor: "pointer",
                                                                                    }}
                                                                                    key={index}
                                                                                    onClick={() => {
                                                                                        addseletedcurrency(
                                                                                            currency
                                                                                        );
                                                                                    }}
                                                                                >
                                                                                    <img
                                                                                        src={
                                                                                            currency.image
                                                                                        }
                                                                                        alt=""
                                                                                        height={24}
                                                                                        width={24}
                                                                                    />
                                                                                    <span
                                                                                        style={{
                                                                                            color: "#667085",
                                                                                            letterSpacing:
                                                                                                "2%",
                                                                                            lineHeight:
                                                                                                "24px",
                                                                                            fontSize:
                                                                                                "16px",
                                                                                        }}
                                                                                    >
                                                                                        {
                                                                                            currency.name
                                                                                        }
                                                                                    </span>
                                                                                </div>
                                                                            )
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            )}
                                                            <img
                                                                src={
                                                                    added
                                                                        ? selectedcurrency.image
                                                                        : nigeria
                                                                }
                                                                alt=""
                                                                height={24}
                                                                width={24}
                                                            />
                                                            <span
                                                                style={{
                                                                    color: "#667085",
                                                                    letterSpacing: "2%",
                                                                    lineHeight: "24px",
                                                                    fontSize: "16px",
                                                                }}
                                                            >
                                                                {added === true
                                                                    ? selectedcurrency.name
                                                                    : "NGN"}
                                                            </span>
                                                        </div>
                                                        <input
                                                            type="number"
                                                            name=""
                                                            id=""
                                                            step={0.5}
                                                            onChange={(e) => {
                                                                setamount(e.target.value);
                                                            }}
                                                            style={{
                                                                width: "100%",
                                                                borderRadius: "8px",
                                                                border: "none",
                                                                outline: "none",
                                                                height: "56px",
                                                                background: "",
                                                                padding: "8px 16px 8px 16px",
                                                                backgroundColor: "#ECEFF3",
                                                                letterSpacing: "2%",
                                                                fontWeight: "400",
                                                                fontSize: "16px",
                                                            }}
                                                            placeholder="Enter amount"
                                                        />
                                                    </div>
                                                </div>
                                                <div
                                                    className=""
                                                    style={{
                                                        display: "flex",
                                                        gap: "8px",
                                                        flexDirection: "column",
                                                    }}
                                                >
                                                    <label
                                                        htmlFor=""
                                                        style={{
                                                            color: "#344054",
                                                            fontWeight: "600",
                                                            lineHeight: "19.6px",
                                                            fontSize: "14px",
                                                        }}
                                                    >
                                                        Upload invoice
                                                    </label>
                                                    <div style={{ position: "relative" }}>
                                                        <div
                                                            style={{
                                                                width: "100%",
                                                                borderRadius: "8px",
                                                                border: "none",
                                                                outline: "none",
                                                                background: "",
                                                                minHeight: "56px",
                                                                padding: "8px 16px 8px 16px",
                                                                backgroundColor: "#ECEFF3",
                                                                letterSpacing: "2%",
                                                                fontWeight: "400",
                                                                fontSize: "16px",
                                                                position: "relative",
                                                                display: "flex",
                                                                justifyContent: "space-between",
                                                                alignItems: "center",
                                                            }}
                                                            variant="primary"
                                                            onClick={() => {
                                                                setfilename(null);
                                                                setfilesize(0);

                                                                setshowModal(true);
                                                            }}
                                                        >
                                                            <span
                                                                style={{
                                                                    color: "#667085",
                                                                    fontSize: "16px",
                                                                    lineHeight: "24px",
                                                                    letterSpacing: "2%",
                                                                }}
                                                            >
                                                                {" "}
                                                                {filename !== null &&
                                                                    filename}-{" "}
                                                                {filename !== null &&
                                                                    parseFloat(
                                                                        filesize / 1024
                                                                    ).toFixed(2)}
                                                                {filesize !== 0 && filesize >= 1024
                                                                    ? "MB"
                                                                    : "KB"}
                                                                {filename === null &&
                                                                    "Tap to upload invoice"}{" "}
                                                            </span>
                                                            <img
                                                                src={copy}
                                                                alt=""
                                                                height={20}
                                                                width={20}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                    className=""
                                                    style={{
                                                        display: "flex",
                                                        gap: "8px",
                                                        flexDirection: "column",
                                                    }}
                                                >
                                                    <label
                                                        htmlFor=""
                                                        style={{
                                                            color: "#344054",
                                                            fontWeight: "600",
                                                            lineHeight: "19.6px",
                                                            fontSize: "14px",
                                                        }}
                                                    >
                                                        Upload customer agreement
                                                    </label>
                                                    <div style={{ position: "relative" }}>
                                                        <div
                                                            style={{
                                                                width: "100%",
                                                                borderRadius: "8px",
                                                                border: "none",
                                                                outline: "none",
                                                                background: "",
                                                                minHeight: "56px",
                                                                padding: "8px 16px 8px 16px",
                                                                backgroundColor: "#ECEFF3",
                                                                letterSpacing: "2%",
                                                                fontWeight: "400",
                                                                fontSize: "16px",
                                                                position: "relative",
                                                                display: "flex",
                                                                justifyContent: "space-between",
                                                                alignItems: "center",
                                                            }}
                                                            variant="primary"
                                                            onClick={() => {
                                                                setaggreementsize(0);
                                                                setagrrement(null);
                                                                setshowModal2(true);
                                                            }}
                                                        >
                                                            <span
                                                                style={{
                                                                    color: "#667085",
                                                                    fontSize: "16px",
                                                                    lineHeight: "24px",
                                                                    letterSpacing: "2%",
                                                                }}
                                                            >
                                                                {aggreement !== null && aggreement}
                                                                {aggreement !== null &&
                                                                    parseFloat(
                                                                        aggreementsize / 1024
                                                                    ).toFixed(2)}
                                                                {aggreementsize !== 0 &&
                                                                aggreementsize >= 1024
                                                                    ? "MB"
                                                                    : "KB"}
                                                                {aggreement === null &&
                                                                    "Upload document"}
                                                            </span>
                                                            <img
                                                                src={copy}
                                                                alt=""
                                                                height={20}
                                                                width={20}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="mb-5">
                                                    <button
                                                        disabled={isdisable}
                                                        type="submit"
                                                        style={{
                                                            width: "280px",
                                                            background: isdisable
                                                                ? "#EBE4FF"
                                                                : "#6F00FF",
                                                            height: "48px",
                                                            padding: "16px 24px 16px 24px",
                                                            border: "none",
                                                            outline: "none",
                                                            borderRadius: "8px",
                                                            fontSize: "16px",
                                                            lineHeight: "24px",
                                                            letterSpacing: "2%",
                                                            fontWeight: "400",
                                                            color: isdisable ? "#BFA6FF" : "#ffff",
                                                            justifyContent: "center",
                                                            display: "flex",
                                                            alignItems: "center",
                                                        }}
                                                    >
                                                        {loader === true ? (
                                                            <Loaders data={loader} />
                                                        ) : (
                                                            "Submit"
                                                        )}
                                                    </button>
                                                </div>
                                            </>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default CreditLine;
