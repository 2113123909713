import Logo from '../asset/images/tradevulogo.svg'
const LoginHeader = (props) => {
    return (
        <>
            <div className="myborder-slixe "
                style={{
        
                    height: "70px",
                    padding: "10px 30px 10px 30px",
                    width:'100%',
                    margin:'0px auto'
                    
                  }}>
                <div className="brand">
                    <div className="d-flex"
                        style={
                            {alignItems: 'center'}
                    }>
                        <div className="brand">
                            <img src={Logo}
                                alt=""
                                style={
                                    {marginRight: '8px'}
                                }
                                className='img-fluid'/>
                        </div>


                    </div>
                </div>
                <div className=''
                    style={
                        {
                            height: '3px',
                            width: props.width + '%',
                            background: '#6F00FF',
                            marginLeft: '-55px',
                            overflow: 'hidden',
                            marginTop:'22px'
                        }
                }></div>

            </div>

        </>
    );
}

export default LoginHeader;
